import React, { useContext } from "react"
import classNames from "classnames"
import { Accordion, AccordionContext } from "react-bootstrap"
import { string } from "prop-types"
import { styled } from "@linaria/react"

import { FONT_SIZES } from "src/styles/sizes"

const AccordionToggle = ({ eventKey, className, children }) => {
  const currentEventKey = useContext(AccordionContext)

  return (
    <Accordion.Toggle as="div" eventKey={eventKey} className="position-relative">
      <span
        className={classNames(
          className,
          "position-absolute",
          currentEventKey === eventKey ? "up" : "down"
        )}
      />
      {children}
    </Accordion.Toggle>
  )
}

AccordionToggle.propTypes = {
  eventKey: string.isRequired
}

export default styled(AccordionToggle)`
  left: -${FONT_SIZES.large};
  font-size: ${FONT_SIZES.large};

  &::before {
    content: "";
    border-right: 0.3em solid transparent;
    border-left: 0.3em solid transparent;
  }

  &.down {
    top: 0;
    bottom: auto;

    &::before {
      border-top: 0.3em solid;
      border-bottom: 0;
    }
  }

  &.up {
    top: auto;
    bottom: 0;

    &::before {
      border-top: 0;
      border-bottom: 0.3em solid;
    }
  }
`
