import { createSelector } from "@reduxjs/toolkit"
import { sortBy } from "lodash"

import { getTagOption } from "./helpers"

export const getTagsAllIds = (state) => state.tags.allIds
export const getTagsById = (state) => state.tags.byId

export const selectTagOptions = createSelector(
  [getTagsAllIds, getTagsById],
  (allIds, byId) =>
    Object.values(
      sortBy(
        allIds.map((id) => getTagOption(byId[id])),
        "label"
      )
    )
)
