import { createContext } from "react"

import { ACTION_TYPES } from "./constants"

const NewMessageActionContext = createContext({
  message: { actionType: ACTION_TYPES.must_read },
  optionConfirmed: false,
  optionAttributes: null,
  confirmOption: () => {},
  actionsModalOpened: false,
  openActionsModal: () => {},
  closeActionsModal: () => {}
})

export default NewMessageActionContext
