class @ChatListComponent
  constructor: (params) ->
    @afterUpdate = params.afterUpdate
    @listContainer = $be('chat-list')
    @listFetchPath = @listContainer.data('fetch-path')

    @listUpdateChannel = AblyRealtimeClient.channels.get(
      "chats:updates"
    )

    @channelData = [
      {
        channel: @listUpdateChannel,
        subscriber: @handleListUpdateChannelMessage
      }
    ]

  setup: =>
    $setupChannelSubscriptions(@channelData)

  teardown: =>
    $teardownChannelSubscriptions(@channelData)

  handleListUpdateChannelMessage: (channelMessage) =>
    threadModel = new ThreadModel(channelMessage.data.thread)
    return unless threadModel.isMemberId($currentUserId())

    if !@skipListUpdate(channelMessage.data.chatId)
      $.getScript @listFetchPath

  update: (listContent) =>
    @listContainer.html(listContent)
    @afterUpdate() if @afterUpdate

  skipListUpdate: (chatId) =>
    @listContainer.find(
      "[data-behavior=chat-card][data-id=#{chatId}]"
    ).length > 0
