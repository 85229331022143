import { csrfToken } from "src/helpers/ajax"

export default class LayoutHandler {
  constructor() {
    this.setup = this.setup.bind(this)
    this.teardown = this.teardown.bind(this)
    this.setAvatarImages = this.setAvatarImages.bind(this)
    this.showCurrentUserLabel = this.showCurrentUserLabel.bind(this)
    this.handleMeetingPacksChannelMessage = this.handleMeetingPacksChannelMessage.bind(
      this
    )
    this.handleStarredItemsChannelMessage = this.handleStarredItemsChannelMessage.bind(
      this
    )
    this.handleDiscussionsChannelMessage = this.handleDiscussionsChannelMessage.bind(this)
    this.handleChatsChannelMessage = this.handleChatsChannelMessage.bind(this)

    this.unreadBadgeClass = "unread"
    this.mentionBadgeClass = "unread mention"

    this.discussionId = $be("discussion-page").data("id")
    this.groupId = window.groupId

    this.sidebarComponent = new SidebarComponent()
    this.contactDetailsComponent = new ContactDetailsComponent()

    this.meetingPacksChannel = AblyRealtimeClient.channels.get("meeting-packs")
    this.discussionsUpdatesChannel = AblyRealtimeClient.channels.get(
      "discussions:updates"
    )
    this.starredItemsUpdateChannel = AblyRealtimeClient.channels.get(
      "users:starred-items-status"
    )
    this.chatsUpdateChannel = AblyRealtimeClient.channels.get("chats:updates")

    this.channelData = [
      {
        channel: this.meetingPacksChannel,
        subscriber: this.handleMeetingPacksChannelMessage
      },
      {
        channel: this.discussionsUpdatesChannel,
        subscriber: this.handleDiscussionsChannelMessage
      },
      {
        channel: this.chatsUpdateChannel,
        subscriber: this.handleChatsChannelMessage
      },
      {
        channel: this.starredItemsUpdateChannel,
        subscriber: this.handleStarredItemsChannelMessage
      }
    ]

    this.analyticsComponent = new AnalyticsComponent()
  }

  setup() {
    this.sidebarComponent.setup()
    this.contactDetailsComponent.setup()
    this.analyticsComponent.setup()

    $setupChannelSubscriptions(this.channelData)

    this.setAvatarImages($be("app-layout"))

    $.ajaxSetup({
      beforeSend: (xhr) => {
        xhr.setRequestHeader("X-CSRF-Token", csrfToken())
      }
    })
  }

  teardown() {
    return $teardownChannelSubscriptions(this.channelData)
  }

  handleMeetingPacksChannelMessage(channelMessage) {
    const currentUserId = $currentUserId()
    const { userIds, totalCounts, meetingPackId } = channelMessage.data

    if (userIds && !userIds.includes(currentUserId)) return

    if (totalCounts[currentUserId]) {
      $be("home-navbar-link").addClass(this.mentionBadgeClass)
      $be("home-meetings-menu-link").addClass(this.mentionBadgeClass)
      $beById("meeting-pack-menu-link", meetingPackId).addClass(this.mentionBadgeClass)
      $beById("meeting-pack-view-link", meetingPackId).addClass(this.mentionBadgeClass)
    } else {
      $be("home-navbar-link").removeClass(this.mentionBadgeClass)
      $be("home-meetings-menu-link").removeClass(this.mentionBadgeClass)
      $beById("meeting-pack-menu-link", meetingPackId).removeClass(this.mentionBadgeClass)
      $beById("meeting-pack-view-link", meetingPackId).removeClass(this.mentionBadgeClass)
    }
  }

  handleStarredItemsChannelMessage(channelMessage) {
    const { userId, status } = channelMessage.data

    if (userId !== $currentUserId()) return

    if (status === "present") {
      $be("home-navbar-link-icon")
        .removeClass("icon-home-hollow")
        .addClass("icon-home-filled")
      $be("home-starred-menu-link-icon")
        .removeClass("icon-star-hollow")
        .addClass("icon-star-filled")
    } else {
      $be("home-navbar-link-icon")
        .removeClass("icon-home-filled")
        .addClass("icon-home-hollow")
      $be("home-starred-menu-link-icon")
        .removeClass("icon-star-filled")
        .addClass("icon-star-hollow")
    }
  }

  handleDiscussionsChannelMessage(channelMessage) {
    const { unreadUserIds, discussionId } = channelMessage.data
    const userOnSamePage = this.onSameDiscussionPage(discussionId)

    if (userOnSamePage || !unreadUserIds) return

    if (unreadUserIds.includes($currentUserId())) {
      $be("home-navbar-link").addClass(this.mentionBadgeClass)
      $be("home-mentions-menu-link").addClass(this.mentionBadgeClass)
    } else {
      $be("home-navbar-link").removeClass(this.mentionBadgeClass)
      $be("home-mentions-menu-link").removeClass(this.mentionBadgeClass)
    }
  }

  handleChatsChannelMessage(channelMessage) {
    const { unreadUserIds, userIds, chatId } = channelMessage.data
    const userOnSamePage = this.onSameChatPage(chatId)

    if (userOnSamePage || !unreadUserIds) return

    const isMention = userIds?.includes($currentUserId())
    const badgeClass = isMention ? this.mentionBadgeClass : this.unreadBadgeClass

    if (unreadUserIds.includes($currentUserId())) {
      if (isMention) $be("home-mentions-menu-link").addClass(badgeClass)
      $be("home-navbar-link").addClass(badgeClass)
      $be("home-chats-menu-link").addClass(this.unreadBadgeClass)
    } else {
      if (isMention) $be("home-mentions-menu-link").removeClass(badgeClass)
      $be("home-navbar-link").removeClass(badgeClass)
      $be("home-chats-menu-link").removeClass(this.unreadBadgeClass)
    }
  }

  onSameDiscussionPage(discussionId) {
    return discussionId && $beById("discussion-page", discussionId).length > 0
  }

  onSameChatPage(chatId) {
    return $beById("chat-page", chatId).length > 0
  }

  setAvatarImages(container) {
    return container.find("[data-behavior=avatar-image]").each((idx, image) => {
      const imageUrl = $(image).data("url")
      if (imageUrl) {
        return $(image).css("background-image", `url(${imageUrl})`)
      }
    })
  }

  showCurrentUserLabel(container) {
    return container
      .find(`[data-behavior=current-user-label][data-id=${$currentUserId()}]`)
      .show()
  }
}
