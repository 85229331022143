import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"

import { showCaughtErrorMessage } from "src/helpers/errors"
import messages from "src/resources/messages/api"

const fetchMessageActions = ({ messageId }) => () => {
  return messages
    .fetchActions({ messageId })
    .then((response) => response.data)
    .catch((error) => {
      showCaughtErrorMessage(error)
    })
}

const useFetchMessageActions = ({ id }) => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const [messageActions, setMessageActions] = useState([])

  useEffect(() => {
    setIsLoading(true)

    dispatch(fetchMessageActions({ messageId: id }))
      .then(setMessageActions)
      .finally(() => setIsLoading(false))
  }, [dispatch, id])

  return { isLoading, messageActions }
}

export default useFetchMessageActions
