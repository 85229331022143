import React from "react"
import { func } from "prop-types"

import Icon from "src/styles/components/Icon"

import { SelectedValueContainer } from "./styles"

const SelectedValue = ({ deselect, children }) => {
  return (
    <SelectedValueContainer>
      <span>{children}</span>
      <Icon type="cancel-circled" onClick={deselect} />
    </SelectedValueContainer>
  )
}

SelectedValue.propTypes = {
  deselect: func
}

export default SelectedValue
