import React from "react"
import { Dropdown } from "react-bootstrap"
import { shape, number } from "prop-types"

import Icon from "src/styles/components/Icon"
import useIsOpened from "src/hooks/useIsOpened"

import ConfirmModal from "./ConfirmModal"

const Delete = ({ group }) => {
  const { isOpened, open, close } = useIsOpened()

  return (
    <>
      <Dropdown.Item className="text-red" onClick={open}>
        <div className="dropdown-icon">
          <Icon className="icon-small" type="cancel-circled" />
        </div>
        Delete
      </Dropdown.Item>
      <ConfirmModal group={group} isOpened={isOpened} close={close} />
    </>
  )
}

Delete.propTypes = {
  group: shape({
    id: number.isRequired
  }).isRequired
}

export default Delete
