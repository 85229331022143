import React from "react"
import { number, shape, string } from "prop-types"

import UserAvatar from "src/components/UsersContacts/UserAvatar"
import { formatTimestamp } from "src/helpers/datetime"

import { TitleContainer, TitleDatetime, TitleText } from "./styles"

const MessageTitle = ({ datetime, author }) => {
  return (
    <TitleContainer>
      <div className="d-flex align-items-center text-truncate">
        <UserAvatar user={author} />
        <TitleText class="text-truncate user-full-name">
          {author?.fullName || "(user deleted)"}
        </TitleText>
      </div>

      <TitleDatetime>{formatTimestamp(datetime)}</TitleDatetime>
    </TitleContainer>
  )
}

MessageTitle.propTypes = {
  datetime: string,
  author: shape({
    id: number.isRequired,
    fullName: string.isRequired,
    avatarColorId: number,
    avatarUrl: string,
    initials: string
  })
}

export default MessageTitle
