import { styled } from "@linaria/react"

import { TEXT_COLORS } from "src/styles/colors"
import { BREAKPOINTS, SPACES, FONT_SIZES, FONT_WEIGHTS } from "src/styles/sizes"
import UnreadBadge from "src/styles/components/UnreadBadge"

const Text = styled.div`
  background-color: transparent;
  border: none;
  color: inherit;
  white-space: nowrap;
  word-break: break-all;
  padding: 0;
`

export const ContentsCol = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  padding-right: ${SPACES.small};
  padding-left: ${SPACES.small};
`

export const PrefixCol = styled(ContentsCol)`
  justify-content: flex-end;
  flex: 0 0 17.5%;
  max-width: 17.5%;

  @media (max-width: ${BREAKPOINTS.small}) {
    flex: 0 0 10%;
    max-width: 10%;
  }
`

export const SuffixCol = styled(ContentsCol)`
  justify-content: flex-end;
  flex: 0 0 17.5%;
  max-width: 17.5%;
  position: relative;
  padding-left: ${SPACES.xxSmall};

  ${UnreadBadge} {
    position: absolute;
    right: 3px;
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    flex: 0 0 15%;
    max-width: 15%;
  }
`

export const ItemName = styled(Text)``

export const ItemTitle = styled(ContentsCol)`
  display: flex;
  align-items: center;
  padding: 0;
  flex: 0 0 65%;
  max-width: 65%;

  @media (max-width: ${BREAKPOINTS.small}) {
    flex: 2 0 75%;
    max-width: 75%;
  }

  ${ItemName} {
    width: 100%;
  }
`

export const Index = styled(Text)`
  text-align: right;
`

export const BreakName = styled(Text)`
  text-transform: uppercase;
`

export const Duration = styled(Text)`
  font-size: ${FONT_SIZES.xSmall};
  color: ${TEXT_COLORS.heading};
`

export const NotAttendingStatus = styled(Text)`
  font-size: ${FONT_SIZES.xSmall};
  color: ${TEXT_COLORS.muted};
  font-weight: ${FONT_WEIGHTS.semiBold};
`

export const AccessOnlyStatus = styled(Text)`
  font-size: ${FONT_SIZES.xSmall};
  font-weight: ${FONT_WEIGHTS.semiBold};
`

export const BreakLine = styled.hr`
  width: 100%;
  border-color: ${TEXT_COLORS.heading};
  margin-left: ${SPACES.small};
`
