import { styled } from "@linaria/react"

import {
  BASE_COLORS,
  BORDER_COLORS,
  BACKGROUND_COLORS,
  TEXT_COLORS,
  linkHoverColor
} from "src/styles/colors"
import { BREAKPOINTS } from "src/styles/sizes"

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  .btn {
    width: 30%;
    min-width: fit-content;
  }

  .btn-primary {
    background-color: ${BASE_COLORS.lightBlue} !important;
    border-color: ${BORDER_COLORS.button} !important;
    &:active,
    &:focus,
    &:hover {
      background-color: ${BORDER_COLORS.button} !important;
      border-color: ${BORDER_COLORS.button} !important;
    }
  }

  .btn-outline-primary {
    color: ${BASE_COLORS.lightBlue} !important;
    border-color: ${BASE_COLORS.lightBlue} !important;

    &:active,
    &:focus,
    &:hover {
      background-color: ${BACKGROUND_COLORS.focused} !important;
    }
  }
`

export const OptionContainer = styled.div`
  margin-left: 2rem;

  @media (max-width: calc(${BREAKPOINTS.xSmall} - 1px)) {
    margin-left: 1rem;
  }
`

export const NewMessageActionTextContainer = styled.div`
  font-size: 0.8rem;
`

export const NewMessageActionTextButton = styled.button`
  display: inline-block;
  color: ${TEXT_COLORS.link};
  cursor: pointer;
  text-decoration: none;
  background-color: transparent;
  border: none;
  &:focus,
  &:active,
  &:hover {
    color: ${linkHoverColor};
    outline: none;
  }

  .required {
    color: ${BASE_COLORS.red};

    &::before {
      content: "\\00a0";
    }
  }
`

export const DateInputSectionContainer = styled.div`
  margin-left: 2.5rem;

  > input.flatpickr-input {
    line-height: 2;
    margin: 0 10px;

    & + i.fa {
      position: relative;
      left: -2.5rem;
      cursor: pointer;
    }

    &.flatpickr-mobile + i.fa {
      display: none;
    }
  }
`

export const NumberInputSectionContainer = styled.div`
  margin-left: 2.5rem;

  input {
    display: inline-block;
    width: 75px;
    line-height: 2;
    margin: 0px 10px;
  }
`
