import apiClient from "src/app/apiClient"

export const resendCode = () => apiClient.post("/users/two_factor/resending")

export const sendNumber = ({ number }) =>
  apiClient.post("/users/profile/two_factor/number_submission", { number })

export const sendCode = ({ code, byApp }) =>
  apiClient.post("/users/profile/two_factor/code_verification", {
    otpAttempt: code,
    byApp
  })

export const disable = () => apiClient.post("/users/profile/two_factor/disabling")
