import React from "react"
import { func, number } from "prop-types"

import { useGroupId } from "../GroupIdContext"
import { useCurrentFolderId } from "../CurrentFolderIdContext"
import { useFileId } from "../FileIdContext"
import { usePermissionsContext } from "../PermissionsContext"

import Button from "./Button"
import CopyLink from "./CopyLink"

const GeneralActions = ({
  folderItemsCount,
  onNewFolder,
  onUploadFiles,
  onUploadFolders,
  onDownload
}) => {
  const permissions = usePermissionsContext()
  const { canCreateFolder, canUploadFiles, canDownloadFilesAndFolders } = permissions

  const groupId = useGroupId()
  const currentFolderId = useCurrentFolderId()
  const fileId = useFileId()

  return (
    <>
      {canCreateFolder && (
        <Button iconType="folder-plus" onClick={onNewFolder}>
          New Folder
        </Button>
      )}
      {canUploadFiles && (
        <Button iconType="upload-file" onClick={onUploadFiles}>
          Upload Files
        </Button>
      )}
      {canCreateFolder && canUploadFiles && (
        <Button iconType="folder-upload" onClick={onUploadFolders}>
          Upload Folder
        </Button>
      )}
      {canDownloadFilesAndFolders && folderItemsCount > 0 && (
        <Button iconType="arrow-down-hollow" onClick={onDownload}>
          Download
        </Button>
      )}
      <CopyLink groupId={groupId} folderId={currentFolderId} fileId={fileId} />
    </>
  )
}

GeneralActions.propTypes = {
  folderItemsCount: number.isRequired,
  onNewFolder: func.isRequired,
  onUploadFiles: func.isRequired,
  onUploadFolders: func.isRequired,
  onDownload: func.isRequired
}

export default GeneralActions
