import React, { useEffect } from "react"

import BoxFailedState from "src/components/BoxFailedState"
import useBoxFilePicker from "src/hooks/box/useBoxFilePicker"
import { BOX_COMPONENTS, BOX_ROOT_FOLDER_ID } from "src/constants"
import { renameChooseButton } from "src/helpers/box"

import { FilePickerContainer } from "./styles"

const BoxFilePicker = ({
  handleChoose,
  handleCancel,
  extensions,
  chooseButtonLabel = "Upload",
  container = BOX_COMPONENTS.filePicker
}) => {
  try {
    const { open, hide } = useBoxFilePicker({
      folderId: BOX_ROOT_FOLDER_ID,
      handleCancel,
      handleChoose,
      extensions,
      container: `[data-behavior=${container}]`
    })

    useEffect(() => {
      open()
      renameChooseButton(chooseButtonLabel)

      return hide
    }, [])

    return <FilePickerContainer data-behavior={container} />
  } catch {
    return <BoxFailedState />
  }
}

BoxFilePicker.propTypes = {}

export default BoxFilePicker
