import React from "react"
import { arrayOf, shape, string } from "prop-types"
import { startCase, capitalize, sortBy } from "lodash"

import UsersContacts from "src/components/UsersContacts"
import { MEMBERSHIP_TYPES } from "src/features/meetingPacks/MeetingPack/constants"

const AttendeesContacts = ({
  attendees,
  currentUser,
  allowedMembershipTypes = MEMBERSHIP_TYPES
}) => {
  return (
    <div>
      {allowedMembershipTypes.map((membershipType) => {
        const typeAttendees = attendees.filter(
          (attendee) => attendee.membershipType === membershipType
        )

        return (
          !!typeAttendees?.length && (
            <div
              className="mb-4"
              data-testid="attendees-type-section"
              key={membershipType}
            >
              <h6>{capitalize(startCase(membershipType))}</h6>
              <UsersContacts
                attendees={sortBy(typeAttendees, "lastName")}
                currentUser={currentUser}
              />
            </div>
          )
        )
      })}
    </div>
  )
}

AttendeesContacts.propTypes = {
  attendees: arrayOf(
    shape({
      membershipType: string.isRequired,
      lastName: string.isRequired
    })
  ).isRequired,
  currentUser: shape({}).isRequired,
  allowedMembershipTypes: arrayOf(string)
}

export default AttendeesContacts
