import React from "react"
import { Button } from "react-bootstrap"
import { styled } from "@linaria/react"

import { withVariations } from "src/helpers/linaria"
import { BACKGROUND_COLORS } from "src/styles/colors"

const Secondary = (props) => {
  return <Button {...props} variant="outline-secondary" color="secondary" />
}

export default styled(withVariations(Secondary, ["width"]))`
  &.btn {
    border-color: ${BACKGROUND_COLORS.button};
    color: ${BACKGROUND_COLORS.button};
    letter-spacing: normal;
    margin: 0;
  }

  &--width-md {
    width: 100px;
  }
`
