import { createSlice } from "@reduxjs/toolkit"

import * as reducers from "./reducers"
import initialState from "./initialState"

const slice = createSlice({
  name: "documentPreview",
  initialState,
  reducers
})

const { actions, reducer } = slice

export { actions }
export default reducer
