const FILTERS_EXPIRATION_TIME = 20 * 60 * 1000 // 20 mins in milliseconds

export const configureStoredFilters = (storageKey, defaults) => {
  const getStoredFilters = () => {
    const storedFilters = JSON.parse(localStorage.getItem(storageKey))
    const currentTimestamp = new Date().getTime()

    if (storedFilters && storedFilters.expiresAt > currentTimestamp) {
      return storedFilters
    }

    return defaults
  }

  const storeFilters = (filters) => {
    const filtersStorageValue = JSON.stringify({
      ...filters,
      expiresAt: new Date().getTime() + FILTERS_EXPIRATION_TIME
    })

    localStorage.setItem(storageKey, filtersStorageValue)
  }

  return { storedFilters: getStoredFilters(), storeFilters }
}
