import { useDocumentPreview } from "src/features/DocumentPreview"

const useFilePreview = () => {
  const { open } = useDocumentPreview()

  const preview = (file) => {
    open({
      document: {
        boxFileId: file.id,
        filename: file.name,
        contentCloudFile: file.contentCloudFile
      }
    })
  }

  return preview
}

export default useFilePreview
