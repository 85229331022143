import { useQuery } from "@apollo/client"
import { sortBy } from "lodash"

import { MEMBERSHIP_TYPES_FOR } from "src/features/meetingPacks/MeetingPack/constants"

import meetingPackQuery from "./meetingPack.gql"

export default function useMemberships({ meetingPackId }) {
  const { data } = useQuery(meetingPackQuery, { variables: { id: meetingPackId } })

  return sortBy(data?.meetingPack?.memberships || [], ({ membershipType }) =>
    MEMBERSHIP_TYPES_FOR.meetingPackExport.indexOf(membershipType)
  )
}
