import React from "react"
import { styled } from "@linaria/react"
import { string, func } from "prop-types"

import LinkButton from "src/styles/components/Button/Link"

const CheckboxColumnTitle = ({ className, title, onSelectAll, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <h6>{title}</h6>
      <LinkButton className="text-small text-center" onClick={onSelectAll}>
        Select All
      </LinkButton>
    </div>
  )
}

CheckboxColumnTitle.propTypes = {
  title: string.isRequired,
  onSelectAll: func.isRequired
}

export default styled(CheckboxColumnTitle)`
  display: flex;
  flex-direction: column;
  text-align: center;

  button {
    display: block;
    font-size: 0.75rem;
    text-align: center;
  }
`
