import React from "react"
import { Dropdown } from "react-bootstrap"
import { number, string } from "prop-types"

import IconAI from "src/styles/components/IconAI"
import IconPencilPlus from "src/styles/components/IconPencilPlus"
import { OBJECT_TYPES } from "src/features/UniversalAi/constants"
import { useUniversalAi } from "src/features/UniversalAi"

const OpenaiActionsDiscussion = ({ discussionId, title }) => {
  const { summariseAsTextInSidebar, queryInSidebar } = useUniversalAi()

  const summariseAsText = () =>
    summariseAsTextInSidebar({
      objectId: discussionId,
      objectType: OBJECT_TYPES.discussion
    })

  const queryDiscussion = () =>
    queryInSidebar({
      objectId: discussionId,
      objectType: OBJECT_TYPES.discussion,
      objectTitle: title
    })

  return (
    <>
      <Dropdown.Item onClick={summariseAsText}>
        <div className="dropdown-icon">
          <IconAI />
        </div>
        Summarise as text
      </Dropdown.Item>
      <Dropdown.Item onClick={queryDiscussion}>
        <div className="dropdown-icon">
          <IconPencilPlus />
        </div>
        Query discussion
      </Dropdown.Item>
    </>
  )
}

OpenaiActionsDiscussion.propTypes = {
  discussionId: number.isRequired,
  title: string.isRequired
}

export default OpenaiActionsDiscussion
