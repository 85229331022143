import { styled } from "@linaria/react"

import { BASE_COLORS, successColor } from "src/styles/colors"

export const CreatedMessage = styled.span``

export const UploadedMessage = styled.span`
  color: ${successColor};
`

export const FailedMessage = styled.span`
  color: ${BASE_COLORS.red};
`
