class @AvatarUploadComponent
  constructor: ->
    @avatarUploadInput = $be('avatar-upload-input')
    @avatarUploadPreview = $('[data-behavior=avatar-image][data-upload-preview]')
    @avatarUploadError = $be('avatar-upload-error')

  setup: =>
    @avatarUploadInput.on 'change', =>
      input = @avatarUploadInput[0]
      if input.files && input.files[0]
        file = input.files[0]
        if !file.type.match(/image.*/)
          @avatarUploadError.html('Only image files can be uploaded.')
          resetFileInput(input)
        else if file.size > 5000000
          @avatarUploadError.html('Selected image is bigger then 5mb.')
          resetFileInput(input)
        else
          # Fix for images with orientation in EXIF
          # https://github.com/blueimp/JavaScript-Load-Image
          loadImage(file, orientation: true).then((image) =>
            canvas = document.createElement('canvas');
            ctx = canvas.getContext('2d');
            canvas.height = image.image.naturalHeight;
            canvas.width = image.image.naturalWidth;
            ctx.drawImage(image.image, 0, 0);
            dataURL = canvas.toDataURL();

            @avatarUploadPreview.css('background-image', "url(#{dataURL})")
            @avatarUploadPreview.hide()
            @avatarUploadPreview.fadeIn(650)
            @avatarUploadPreview.removeClass('avatar-stub')
            @avatarUploadPreview.html('')
            @avatarUploadError.html('')
          )

  resetFileInput = (input) ->
    input.value = ''
    if(!/safari/i.test(navigator.userAgent))
      input.type = ''
      input.type = 'file'

