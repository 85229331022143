import { Button } from "react-bootstrap"
import { styled } from "@linaria/react"

import { withVariations } from "src/helpers/linaria"
import { BREAKPOINTS, FONT_SIZES, SPACES } from "src/styles/sizes"
import { accountAdminColor, accountGuestColor, TEXT_COLORS } from "src/styles/colors"

export const ManageUsersButton = styled(Button)`
  display: flex;
  margin: 0 0 0 0.25rem;
  padding: 0.375rem 0.75rem;
  font-weight: 500;

  color: #4f4f57;
  border: none;

  &:not(.form-field.is-disabled):not(:disabled):not(.disabled):active {
    box-shadow: none;
    background-color: #a5a5ad;
  }

  &:not(:disabled):not(.form-field.is-disabled):not(.disabled):active:focus {
    box-shadow: none;
  }

  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):hover {
    box-shadow: none;
    background-color: unset;
    color: #4f4f57;
  }

  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    span:not([class^="icon-"]) {
      display: none;
    }

    span[class^="icon-"] {
      margin-left: 0;
    }
  }
`

export const AccountRoleBadge = styled(withVariations("div", ["role"]))`
  align-items: center;
  border-radius: 5px;
  color: ${TEXT_COLORS.heading};
  display: flex;
  font-size: ${FONT_SIZES.small};
  padding: 4px 6px;
  margin-right: ${SPACES.small};

  &--role-admin {
    background-color: ${accountAdminColor};
  }

  &--role-guest {
    background-color: ${accountGuestColor};
  }
`
