import apiClient from "src/app/apiClient"

const show = ({ documentId }) => {
  return apiClient.get(`/documents/${documentId}`)
}

const upload = ({ agendaItemId, file, position }) => {
  const data = new FormData()
  data.append("file", file, file.name)
  data.append("position", position)

  return apiClient.postForm(`/agenda_items/${agendaItemId}/documents`, data)
}

const uploadFromBox = ({ agendaItemId, boxFileId, filename, position }) => {
  return apiClient.post(`/agenda_items/${agendaItemId}/documents/upload_from_box`, {
    boxFileId,
    filename,
    position
  })
}

const destroy = ({ documentId }) => {
  return apiClient.delete(`/documents/${documentId}`)
}

const update = ({ documentId, attributes }) => {
  return apiClient.put(`/documents/${documentId}`, { document: attributes })
}

const updatePosition = ({ documentId, position }) => {
  return update({ documentId, attributes: { position } })
}

export default {
  show,
  upload,
  uploadFromBox,
  update,
  updatePosition,
  destroy
}
