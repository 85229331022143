class @RiskAnalysis
  constructor: (params) ->
    @data = params.data
    @riskBreakdownChartId = 'risk_analysis-risk_breakdown_chart'
    @sensitivityChartId = 'risk_analysis-deficit_sensitivity_chart'

  setup: () ->
    riskBreakdownChart = new RiskBreakdown({
      data: @data.risk_breakdown,
      elementId: @riskBreakdownChartId
    })
    riskBreakdownChart.setup()

    sensitivityChart = new Sensitivity({
      data: @data.sensitivity,
      elementId: @sensitivityChartId
    })
    sensitivityChart.setup()

    @addDatesToChartHeaders()

  addDatesToChartHeaders: =>
    $be('risk-analysis-date').html(
      moment(@data.risk_breakdown[0].date).format("DD-MMM-YYYY")
    )

    $be('sensitivity-date').html(
      moment(@data.sensitivity[0].date).format("DD-MMM-YYYY")
    )
