import React from "react"
import classNames from "classnames"
import { string } from "prop-types"

import { isSidebarVisible, toggleSidebar } from "src/helpers/document"

import { SidebarToggleButton } from "./styles"

const SidebarToggle = ({ className, badgeClass = "" }) => {
  return (
    <SidebarToggleButton
      onClick={toggleSidebar}
      className={classNames("sidebar-toggle", className, badgeClass, {
        "show-sidebar": isSidebarVisible()
      })}
    >
      <span className="icon-hamburger" />
    </SidebarToggleButton>
  )
}

SidebarToggle.propTypes = {
  badgeClass: string
}

export default SidebarToggle
