import AppsInstancesShowHandler from "./apps_instances_show_handler"

export default class AppsInstancesShowRecordHandler {
  constructor() {
    this.appComponent = new AppsInstancesShowHandler()
    this.groupMemberListComponent = this.appComponent.groupMemberListComponent
  }

  setup() {
    this.appComponent.setup()
  }

  teardown() {
    this.appComponent.teardown()
  }
}
