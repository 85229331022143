import { useLazyQuery } from "@apollo/client"

import downloadUrlQuery from "./downloadUrlQuery.gql"

const useRequestDownloadUrl = () => {
  const [executeRequest] = useLazyQuery(downloadUrlQuery)

  const requestDownloadUrl = ({ groupId, folderItems }) => {
    const folderItemsInput = folderItems.map(({ id, type }) => ({ id, type }))

    return executeRequest({ variables: { groupId, folderItems: folderItemsInput } }).then(
      (response) => {
        if (response.error) throw response.error

        return response.data?.groupFolderItemsDownloadUrl?.downloadUrl || null
      }
    )
  }

  return requestDownloadUrl
}

export default useRequestDownloadUrl
