import React from "react"
import { bool, string } from "prop-types"
import { capitalize, startCase } from "lodash"

import { Column } from "src/components/AttandeesTable"
import { getMembershipTypeAffix } from "src/features/meetingPacks/helpers"

const MembershipTypeColumn = ({ membershipType, hasLimitedVisibilityItems }) => {
  const membershipTypeAffix = getMembershipTypeAffix({
    membershipType,
    hasLimitedVisibilityItems
  })

  return (
    <Column>
      {capitalize(startCase(membershipType))}
      {membershipTypeAffix}
    </Column>
  )
}

MembershipTypeColumn.propTypes = {
  membershipType: string.isRequired,
  hasLimitedVisibilityItems: bool.isRequired
}

export default MembershipTypeColumn
