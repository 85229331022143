import { useRef } from "react"
import { useDrag, useDrop } from "react-dnd"

import dropSort from "src/helpers/dropSort"

const useDragDrop = ({ id, index, itemType, setItems }) => {
  const ref = useRef(null)
  const [{ handlerId }, drop] = useDrop({
    accept: itemType,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      dropSort({ ref, item, index, setItems, monitor })
    }
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: itemType,
    item: () => ({ id, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  })

  drag(drop(ref))

  return { ref, preview, handlerId, style: { opacity: isDragging ? 0 : 1 } }
}

export default useDragDrop
