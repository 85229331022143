import { createSelector } from "@reduxjs/toolkit"
import { sortBy } from "lodash"

import { getDiscussionOption } from "./helpers"

export const getDiscussionsAllIds = (state) => state.discussions.allIds
export const getDiscussionsById = (state) => state.discussions.byId

export const selectDiscussionOptions = createSelector(
  [getDiscussionsAllIds, getDiscussionsById],
  (allIds, byId) =>
    Object.values(
      sortBy(
        allIds.map((id) => getDiscussionOption(byId[id])),
        "label"
      )
    )
)
