import { formatDateTime } from "src/helpers/datetime"
import { SEARCH_DATE_FORMAT } from "src/constants/datetimeFormats"

import useSearchPersistence from "./useSearchPersistence"
import { filterCategory, sortResults } from "./helpers"
import useUrlSync from "./useUrlSync"
import useSearchResults from "./useSearchResults"

export default function useSearch({
  query,
  order,
  category,
  dateFrom,
  dateTo,
  whereFilter,
  whoFilter,
  showAiAnswer,
  skip,
  aiAnswerSortedLinks
}) {
  const dateFromString = dateFrom
    ? formatDateTime({
        datetime: dateFrom,
        format: SEARCH_DATE_FORMAT
      })
    : null
  const dateToString = dateTo
    ? formatDateTime({
        datetime: dateTo,
        format: SEARCH_DATE_FORMAT
      })
    : null

  useUrlSync({
    query,
    order,
    category,
    whereIds: whereFilter,
    userIds: whoFilter,
    dateFrom: dateFromString,
    dateTo: dateToString
  })

  useSearchPersistence({
    query,
    order,
    category,
    whereIds: whereFilter,
    userIds: whoFilter,
    dateFrom: dateFromString,
    dateTo: dateToString
  })

  const { loading, results } = useSearchResults({
    query,
    whereIds: whereFilter,
    dateFrom: dateFromString,
    dateTo: dateToString,
    whoIds: whoFilter,
    showAiAnswer,
    skip
  })

  const categoryResults = filterCategory({
    results,
    category
  })

  const orderedResults = sortResults({
    results: categoryResults,
    order,
    aiAnswerSortedLinks
  })

  return {
    loading,
    results,
    orderedResults
  }
}
