import React from "react"
import { arrayOf, shape, bool, number } from "prop-types"
import { useQuery } from "@apollo/client"

import AttendeesContacts from "src/components/AttendeesContacts"
import { MEMBERSHIP_TYPES_FOR } from "src/features/meetingPacks/MeetingPack/constants"

import { AttendeesContainer, CardTitle, CardSubtitle } from "../styles"

import agendaItemAttendeesChangesQuery from "./agendaItemAttendeesChanges.gql"

const leavingUsersSentence = (leavingUsersCount, isSubItem) => {
  const severalLeavingUsers = leavingUsersCount > 1
  const lastPart = isSubItem ? "not attending this sub-item" : "left the meeting"
  const verb = severalLeavingUsers ? "are" : "is"

  return [isSubItem ? verb : "", lastPart].join(" ")
}

const Attendees = ({ currentUser, activeItem, attendees, isGuest }) => {
  const { data, loading } = useQuery(agendaItemAttendeesChangesQuery, {
    variables: { id: activeItem?.id },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    skip: !activeItem?.id
  })

  const { leavingUsersNames, joiningUsersNames, leavingUsersCount } =
    data?.agendaItemAttendeesChanges || {}

  if (!(attendees.length || leavingUsersNames || joiningUsersNames) || loading) {
    return null
  }

  const isSubItem = !!activeItem?.agendaItemId

  const shouldShowAttendeesChanges = !isGuest && (leavingUsersNames || joiningUsersNames)

  return (
    <AttendeesContainer>
      <CardTitle>Attendees</CardTitle>
      {shouldShowAttendeesChanges && (
        <>
          {leavingUsersNames && (
            <CardSubtitle className="m-0" data-testid="leaving-users">
              <span className="font-weight-bold">{leavingUsersNames} </span>
              {leavingUsersSentence(leavingUsersCount, isSubItem)}
            </CardSubtitle>
          )}
          {joiningUsersNames && (
            <CardSubtitle className="m-0" data-testid="joining-users">
              <span className="font-weight-bold">{joiningUsersNames} </span>
              joined the meeting
            </CardSubtitle>
          )}
          <div className="mb-3" />
        </>
      )}
      {!!attendees.length && (
        <AttendeesContacts
          attendees={attendees}
          currentUser={currentUser}
          allowedMembershipTypes={MEMBERSHIP_TYPES_FOR.agendaItemSummary}
        />
      )}
    </AttendeesContainer>
  )
}

Attendees.propTypes = {
  attendees: arrayOf(shape({})).isRequired,
  activeItem: shape({ id: number.isRequired, agendaItemId: number }),
  currentUser: shape({
    id: number.isRequired
  }).isRequired,
  isGuest: bool.isRequired
}

export default Attendees
