import { styled } from "@linaria/react"
import React from "react"

import Icon from "src/styles/components/Icon"
import { TEXT_COLORS, BASE_COLORS } from "src/styles/colors"
import { BREAKPOINTS, FONT_SIZES, SPACES } from "src/styles/sizes"
import { withVariations } from "src/helpers/linaria"
import IconDoneBase from "src/styles/components/IconDone"

export const IconDone = styled(withVariations(IconDoneBase, ["status"]))`
  & {
    color: #fff !important;
  }

  &--status-syncing {
    background-color: ${BASE_COLORS.lightOrange} !important;
  }

  &--status-download {
    color: ${TEXT_COLORS.heading} !important;
    background-color: transparent !important;
  }
`

export const IconSyncing = ({ ...props }) => {
  return <IconDone type="cloud-empty" status="syncing" {...props} />
}

export const IconDownload = ({ ...props }) => {
  return <IconDone type="cloud-download" status="download" {...props} />
}

export const OfflineStatusText = styled.span`
  @media (max-width: ${BREAKPOINTS.medium}) {
    display: none;
  }
`

export const OfflineToggleButton = styled(withVariations("button", ["savedOffline"]))`
  border: none;
  background: transparent;
  color: ${TEXT_COLORS.heading};
  font-size: ${FONT_SIZES.small};
  margin-left: ${SPACES.xSmall};
  margin-top: 0.25rem;

  &:hover {
    opacity: 0.75;
  }

  &:focus,
  &:active {
    color: ${TEXT_COLORS.heading};
    outline: none;
  }

  ${Icon} {
    font-size: ${FONT_SIZES.small};
    margin-left: ${SPACES.xSmall};
    vertical-align: baseline;

    &[class*="status-download"] {
      font-size: ${FONT_SIZES.medium};
    }
  }

  @media (max-width: ${BREAKPOINTS.medium}) {
    margin-left: 0;
    padding-left: 0;
  }
`
