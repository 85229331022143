import { bool, shape, string, arrayOf, func } from "prop-types"

const Document = shape({
  boxFileId: string.isRequired,
  filename: string.isRequired
}).isRequired

export const PreviewComponentPropTypes = {
  document: Document,
  collection: arrayOf(Document),
  onClose: func,
  sidebarEnabled: bool,
  withinModal: bool,
  toolbarButtonsFactory: func
}
