import React, { Fragment } from "react"
import { styled } from "@linaria/react"
import { arrayOf, number, shape, string } from "prop-types"

import { cardBorderRadius, SPACES } from "src/styles/sizes"
import MessageActorCard from "src/components/MessageActorCard"

import MustSignLabel from "./MustSignLabel"

const AssignedUsers = ({ className, assignedUsers }) => {
  return (
    <div className={className}>
      <MustSignLabel />
      {assignedUsers.map((user) => (
        <Fragment key={user.id}>
          <MessageActorCard {...user} />
          <input
            type="hidden"
            name="message[document_assigned_users][]"
            value={user.id}
          />
        </Fragment>
      ))}
    </div>
  )
}

AssignedUsers.propTypes = {
  assignedUsers: arrayOf(
    shape({
      id: number,
      fullName: string
    })
  )
}

export default styled(AssignedUsers)`
  border-radius: ${cardBorderRadius};
  margin-bottom: ${SPACES.xSmall};
  padding: ${SPACES.xSmall};
  padding-bottom: 0;
  background-color: #fff;
  font-size: 13px;
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin-right: ${SPACES.xSmall};
    margin-bottom: ${SPACES.xSmall};
  }
`
