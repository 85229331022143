import { useState } from "react"
import { useDispatch } from "react-redux"

import { updateMemberships } from "src/features/meetingPacks/MeetingPack/thunks/agendaItem"
import { attendeesSortFunction } from "src/features/meetingPacks/MeetingPack/Attendees/helpers"
import {
  MEMBERSHIP_TYPES,
  MEMBERSHIP_TYPES_FOR
} from "src/features/meetingPacks/MeetingPack/constants"

import useParentAgendaItemMemberships from "./useParentAgendaItemMemberships"
import useTeamMembershipsUpdate from "./useTeamMembershipsUpdate"

export default ({
  agendaItem,
  agendaItemAttendees,
  possibleAttendees,
  teams,
  onSuccess,
  onError
}) => {
  const [areMembershipsUpdating, setAreMembershipsUpdating] = useState(false)

  const initialSelectedMemberships = Object.fromEntries(
    possibleAttendees.map(({ id }) => [id, null])
  )
  agendaItemAttendees.forEach(({ id, membershipType, agendaItemMembershipType }) => {
    initialSelectedMemberships[id] = MEMBERSHIP_TYPES_FOR.limitedVisibilityItems.includes(
      membershipType
    )
      ? MEMBERSHIP_TYPES.accessOnly // show "access_only" whatever type is written in agenda item membership
      : agendaItemMembershipType
  })
  const [selectedMemberships, setSelectedMemberships] = useState(
    initialSelectedMemberships
  )

  const agendaItemAttendeesIds = agendaItemAttendees.map(({ id }) => id)
  const agendaItemNonAttendees = possibleAttendees
    .filter(({ id }) => !agendaItemAttendeesIds.includes(id))
    .sort(attendeesSortFunction)

  const toggleUserItemMembership = ({ userId, membershipType }) => {
    setSelectedMemberships((currentSelectedMemberships) => {
      return {
        ...currentSelectedMemberships,
        [userId]:
          currentSelectedMemberships[userId] === membershipType ? null : membershipType
      }
    })
  }

  const parentMemberships = useParentAgendaItemMemberships(agendaItem)

  const parentAgendaItemMembershipType = (user) =>
    parentMemberships.find((membership) => membership.userId === user.id)?.membershipType

  const isAccessOnlyInParent = (user) =>
    MEMBERSHIP_TYPES_FOR.limitedVisibilityItems.includes(
      parentAgendaItemMembershipType(user)
    )

  const disablePresentMembership = (user) =>
    isAccessOnlyInParent(user) ||
    MEMBERSHIP_TYPES_FOR.limitedVisibilityItems.includes(user.membershipType)

  const checkPresentForNonAttendees = () => {
    setSelectedMemberships((currentSelectedMemberships) => {
      const newSelectedMemberships = { ...currentSelectedMemberships }
      agendaItemNonAttendees.forEach((nonAttendee) => {
        if (
          MEMBERSHIP_TYPES_FOR.limitedVisibilityItems.includes(nonAttendee.membershipType)
        ) {
          newSelectedMemberships[nonAttendee.id] = MEMBERSHIP_TYPES.accessOnly
        } else {
          newSelectedMemberships[nonAttendee.id] = MEMBERSHIP_TYPES.present
        }
      })
      return newSelectedMemberships
    })
  }

  const checkAccessOnlyForNonAttendees = () => {
    setSelectedMemberships((currentSelectedMemberships) => {
      const newSelectedMemberships = { ...currentSelectedMemberships }
      agendaItemNonAttendees.forEach((nonAttendee) => {
        newSelectedMemberships[nonAttendee.id] = MEMBERSHIP_TYPES.accessOnly
      })
      return newSelectedMemberships
    })
  }

  const dispatch = useDispatch()
  const setMemberships = () => {
    setAreMembershipsUpdating(true)
    return dispatch(
      updateMemberships(agendaItem.id, selectedMemberships, onSuccess, onError)
    ).then(() => setAreMembershipsUpdating(false))
  }

  const { addedTeams, possibleTeamsToAdd, toggleTeam, disablePresentTeam } =
    useTeamMembershipsUpdate({
      setSelectedMemberships,
      selectedMemberships,
      initialSelectedMemberships,
      disablePresentMembership,
      possibleAttendees,
      teams
    })

  return {
    setMemberships,
    selectedMemberships,
    areMembershipsUpdating,
    addedTeams,
    possibleTeamsToAdd,
    toggleUserItemMembership,
    toggleTeam,
    disablePresentMembership,
    disablePresentTeam,
    checkPresentForNonAttendees,
    checkAccessOnlyForNonAttendees,
    agendaItemNonAttendees
  }
}
