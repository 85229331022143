import { showCaughtErrorMessage } from "src/helpers/errors"

import mentions from "./api"
import { fetchMentionsFailure, fetchMentionsRequest, fetchMentionsSuccess } from "./slice"

export const fetchMentions = (params) => (dispatch) => {
  dispatch(fetchMentionsRequest())

  mentions
    .index(params)
    .then((response) => dispatch(fetchMentionsSuccess(response.data)))
    .catch((error) => {
      showCaughtErrorMessage(error)
      dispatch(fetchMentionsFailure(error))
    })
}
