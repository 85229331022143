import React from "react"
import { shape, string } from "prop-types"

import DoubleLineHeader from "src/styles/components/DoubleLineHeader"
import Tag from "src/styles/components/Tag"
import useWindowSize from "src/hooks/useWindowSize"
import { optionType } from "src/constants/propTypes"
import { BREAKPOINTS } from "src/styles/sizes"

import { TagName } from "./styles"

const LeftSide = ({ meetingPack, tagOption }) => {
  const { width } = useWindowSize()

  const hideTag =
    width <= parseInt(BREAKPOINTS.xSmall, 10) ||
    (width >= parseInt(BREAKPOINTS.small, 10) &&
      width <= parseInt(BREAKPOINTS.semiLarge, 10))

  return (
    <>
      <DoubleLineHeader mainText={meetingPack.name} subText={meetingPack.accountName} />

      {!hideTag && tagOption && (
        <Tag className="mhx" color={tagOption.colorId}>
          <TagName text={tagOption.label} maxLength={15} />
        </Tag>
      )}
    </>
  )
}

LeftSide.propTypes = {
  meetingPack: shape({
    name: string.isRequired,
    accountName: string.isRequired
  }).isRequired,
  tagOption: optionType
}

export default LeftSide
