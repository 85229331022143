import React, { useContext } from "react"

import useFetchMessageActions from "src/features/Messages/ActionDetails/useFetchMessageActions"
import ModalContext from "src/features/Messages/ActionDetails/ModalContext"
import ContentLoader from "src/styles/components/ContentLoader"

import ActionGroup from "./ActionGroup"

const Body = () => {
  const { message } = useContext(ModalContext)
  const { isLoading, messageActions } = useFetchMessageActions(message)

  return (
    <div className="modal-body people-list people-list-actionable allow-scroll phm pbm">
      <div className="card-list">
        {isLoading && <ContentLoader />}

        {messageActions.map((group) => (
          <ActionGroup
            key={group.accountName}
            group={group}
            actionType={message.actionType}
          />
        ))}
      </div>
    </div>
  )
}

export default Body
