import moment from "moment/moment"

import annotationAuthorAvatarRenderer from "./annotationAuthorAvatarRenderer"

const annotationAuthorRenderer = ({ annotation, users, currentUser }) => {
  if (
    !annotation?.customData?.userId ||
    annotation?.customData?.userId === currentUser.id
  )
    return null

  const node = document.createElement("div")
  node.dataset.annotationId = annotation.id
  node.style.cssText = `
              width: 100%;
              height: 100%;
              pointer-events: all;
              cursor: pointer;
            `

  const user = users[annotation?.customData?.userId.toString()]
  if (!user) return null

  const createdAt = moment.utc(annotation.createdAt).local().format("D MMM, h:mm a")

  const element = document.createElement("div")
  element.className = "PSPDFKit-annotation-author-container"

  const nameAndCreatedAtElement = document.createElement("div")

  const authorNameElement = document.createElement("div")
  authorNameElement.className = "PSPDFKit-annotation-author"
  authorNameElement.appendChild(document.createTextNode(user.fullName))

  const createdAtElement = document.createElement("div")
  createdAtElement.className = "PSPDFKit-annotation-created-at"
  createdAtElement.appendChild(document.createTextNode(createdAt))

  nameAndCreatedAtElement.appendChild(authorNameElement)
  nameAndCreatedAtElement.appendChild(createdAtElement)

  const avatarElement = annotationAuthorAvatarRenderer(user)

  element.appendChild(nameAndCreatedAtElement)
  element.appendChild(avatarElement)

  element.style.display = "none"
  node.appendChild(element)

  return node
}

export default annotationAuthorRenderer
