export const ITEM_TYPE = "message"

export const ACTION_TYPE_OPTIONS = [
  {
    value: "must_read",
    label: "Must Read"
  },
  {
    value: "voting",
    label: "Vote"
  },
  {
    value: "mention",
    label: "Mention"
  },
  {
    value: "sign",
    label: "Sign"
  }
]

export const STARRED_ACTION_TYPE_OPTIONS = [
  ...ACTION_TYPE_OPTIONS,
  {
    value: "other",
    label: "Other"
  }
]

export const DEFAULT_ACTION_TYPE_FILTER = {
  value: "all",
  label: "All",
  isDefault: true
}
