import React from "react"
import { styled } from "@linaria/react"
import { arrayOf, shape, string } from "prop-types"
import pluralize from "pluralize"

import { TEXT_COLORS } from "src/styles/colors"
import { formatDate } from "src/helpers/datetime"

const SignedPeopleMessage = ({ users, className, signedAt: documentSignedAt }) => {
  const signatoriesCount = users.length
  const signedUsersCount = users.filter(({ signedAt }) => signedAt).length

  return (
    <span className={className}>
      {documentSignedAt
        ? `Document signed by ${pluralize("person", signedUsersCount, true)} ${formatDate(
            documentSignedAt
          )}`
        : `${signedUsersCount} of ${signatoriesCount} people have signed.`}
    </span>
  )
}

SignedPeopleMessage.propTypes = {
  users: arrayOf(
    shape({
      signedAt: string
    })
  ).isRequired,
  signedAt: string
}

export default styled(SignedPeopleMessage)`
  color: ${TEXT_COLORS.muted};
`
