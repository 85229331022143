import isOfflineEnabled from "src/features/offline/isOfflineEnabled"

export const hideButtonsInOfflineMode = ({ instance }) => {
  instance.setToolbarItems((items) => items.filter((item) => !item.disabledOffline))

  if (!isOfflineEnabled()) {
    instance.setViewState((viewState) => viewState.set("readOnly", true))
  }
}

export const showButtonsInOnlineMode = ({ instance, customToolbarButtons }) => {
  instance.setToolbarItems((items) => {
    const customToolbarButtonsIds = customToolbarButtons.map(({ id }) => id)
    const itemsWithoutCustomButtons = items.filter(
      (item) => !item.id || !customToolbarButtonsIds.includes(item.id)
    )

    return [...customToolbarButtons, ...itemsWithoutCustomButtons]
  })

  if (!isOfflineEnabled()) {
    instance.setViewState((viewState) => viewState.set("readOnly", false))
  }
}

export const createOfflineHandler =
  ({ customToolbarButtons }) =>
  (instance) => {
    window.addEventListener("offline", () => {
      hideButtonsInOfflineMode({ instance })
    })

    window.addEventListener("online", () => {
      showButtonsInOnlineMode({ instance, customToolbarButtons })
    })

    return instance
  }
