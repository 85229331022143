class @AutosavedMessageComponent
  constructor: (contentId) ->
    @contentId = "autosave-#{contentId}"
    @documentsId = "#{@contentId}-documents"
    @autosaveChangeEvent = new Event('autosaveChange')

  get: =>
    localStorage.getItem(@contentId)

  set: (content) =>
     localStorage.setItem(@contentId, content)
     window.dispatchEvent(@autosaveChangeEvent)

  reset: =>
    localStorage.removeItem(@contentId)
    localStorage.removeItem(@documentsId)
    window.dispatchEvent(@autosaveChangeEvent)

  addDocument: (docData, cardHtml) =>
    docItem = {
      id:       docData.id,
      filename: docData.filename,
      html:     cardHtml
    }
    savedDocs = localStorage.getItem(@documentsId)
    if savedDocs
      items = JSON.parse(savedDocs)
      @appendDocumentItem(items, docItem)
    else
      @appendDocumentItem([], docItem)

  appendDocumentItem: (items, docItem) =>
    alreadyAddedDoc = items.find((d) => d.id is docItem.id)
    return if alreadyAddedDoc

    localStorage.setItem(
      @documentsId,
      JSON.stringify(
        items.concat(docItem)
      )
    )

  removeDocument: (docId) =>
    localStorage.setItem(
      @documentsId,
      JSON.stringify(
        @getDocuments().filter((d) => !(d.id is docId))
      )
    )

  savedContentIsPresent: =>
    localStorage.getItem(@contentId) || localStorage.getItem(@documentsId)

  getDocumentsDataJson: =>
    result = []
    for docItem in @getDocuments()
      result.push({
        id:       docItem.id,
        filename: docItem.filename
      })
    return JSON.stringify(result)

  getDocumentCards: =>
    result = []
    for docItem in @getDocuments()
      result.push(docItem.html)
    return result

  getDocuments: =>
    savedDocs = localStorage.getItem(@documentsId)
    return [] unless savedDocs
    JSON.parse(savedDocs)
