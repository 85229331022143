import React, { useCallback, useEffect } from "react"
import useInfiniteScroll from "react-infinite-scroll-hook"
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import { string, number } from "prop-types"

import Message from "src/features/Home/Messages/Message"
import ContentLoader from "src/styles/components/ContentLoader"
import ContentDivider from "src/styles/components/ContentDivider"
import EmptyContent from "src/styles/components/EmptyContent"
import useAblyChannel from "src/hooks/useAblyChannel"
import { resetState } from "src/resources/mentions/slice"
import { fetchMentions } from "src/resources/mentions/thunks"
import { getMentions, selectUnreadMentions } from "src/resources/mentions/selectors"
import { PER_PAGE_LIMIT } from "src/constants"
import { CHANNELS } from "src/constants/ably"

import { useMessageChangeHandlers } from "../Messages/handlers"

const MentionsList = ({ actionType, accountId, schemeId }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetState())
  }, [actionType, accountId, schemeId, dispatch])

  const {
    allIds: mentionIds,
    hasNextPage,
    isLoading
  } = useSelector(getMentions, shallowEqual)
  const [unreadMentions, oldMentions] = useSelector(selectUnreadMentions, shallowEqual)
  const handleLoadMore = useCallback(() => {
    dispatch(
      fetchMentions({
        page: { offset: mentionIds.length, limit: PER_PAGE_LIMIT },
        filter: { actionType, accountId, schemeId }
      })
    )
  }, [mentionIds, dispatch])

  const [infiniteRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage,
    onLoadMore: handleLoadMore
  })

  const { handleMessageChange, handleMessageStatusChange, handleReadMessage } =
    useMessageChangeHandlers(mentionIds)

  useAblyChannel(CHANNELS.users.starredItemsStatus, {
    onMessage: handleMessageStatusChange
  })
  useAblyChannel(CHANNELS.discussions.updates, { onMessage: handleMessageChange })
  useAblyChannel(CHANNELS.chats.updates, { onMessage: handleMessageChange })
  useAblyChannel(CHANNELS.messages, { onMessage: handleReadMessage })

  if (!isLoading && !hasNextPage) {
    if (!mentionIds.length) {
      return <EmptyContent>You have not been mentioned yet.</EmptyContent>
    }
  }

  return (
    <div>
      {unreadMentions.map((mention) => (
        <Message key={mention.id} message={mention} />
      ))}
      {!!oldMentions.length && <ContentDivider>Old Messages</ContentDivider>}
      {oldMentions.map((mention) => (
        <Message key={mention.id} message={mention} />
      ))}
      <div ref={infiniteRef} />
      {isLoading && <ContentLoader />}
    </div>
  )
}

MentionsList.propTypes = {
  actionType: string,
  accountId: number,
  schemeId: number
}

export default MentionsList
