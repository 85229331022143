import React from "react"
import { styled } from "@linaria/react"
import { oneOf } from "prop-types"

import { composeVariationsClasses } from "src/helpers/linaria"
import { avatarColor, BACKGROUND_COLORS } from "src/styles/colors"

const AvatarImageContainer = ({ className, size = "", variant = "", ...props }) => {
  const classes = composeVariationsClasses(className, { size, variant })

  return <div className={classes} {...props} />
}

AvatarImageContainer.propTypes = {
  size: oneOf(["", "large"]),
  variant: oneOf(["", "removed", "added"])
}

export default styled(AvatarImageContainer)`
  display: flex;
  align-items: center;
  width: 2.2rem;
  height: 2.2rem;
  background-color: ${avatarColor};
  border: solid 1px ${avatarColor};
  border-radius: 50%;
  overflow: hidden;

  &--size-large {
    width: 6.6rem;
    height: 6.6rem;
  }

  &--variant-removed {
    border: solid 2px ${BACKGROUND_COLORS.danger};
  }

  &--variant-added {
    border: solid 2px ${BACKGROUND_COLORS.success};
  }
`
