import React from "react"
import { any, arrayOf, string } from "prop-types"

import { SuggestionsContainer, JumpToCaption, SuggestionGroup } from "./styles"
import Suggestion from "./Suggestion"

const defaultCaption = "Jump to..."

const Suggestions = ({ suggestions, caption = defaultCaption }) => {
  if (!suggestions?.length) return null

  return (
    <SuggestionsContainer>
      <JumpToCaption>{caption}</JumpToCaption>

      {suggestions.map(([type, typeSuggestions]) => (
        <SuggestionGroup key={type}>
          {typeSuggestions.map((suggestion) => (
            <Suggestion
              key={suggestion.path}
              type={suggestion.type}
              title={suggestion.title}
              parent={suggestion.parent}
              path={suggestion.path}
              className={suggestion.active ? "active" : ""}
            />
          ))}
        </SuggestionGroup>
      ))}
    </SuggestionsContainer>
  )
}

Suggestions.propTypes = {
  suggestions: arrayOf(arrayOf(any)),
  caption: string
}

export default Suggestions
