import { useEffect, useState } from "react"

import { getFullName } from "src/helpers/user"

import { AUTHOR_BLANK_OPTION } from "../constants"

const useFilters = ({ participants, setFilters, otherFilterObj = {} }) => {
  const [filterText, setFilterText] = useState("")
  const [filterAuthor, setFilterAuthor] = useState(null)
  const [dateFrom, setDateFrom] = useState(null)
  const [dateTo, setDateTo] = useState(null)

  const filterObj = {
    ...otherFilterObj,
    ...{
      text: filterText,
      authorId: filterAuthor,
      fromDate: dateFrom,
      toDate: dateTo
    }
  }

  useEffect(() => {
    setFilters(filterObj)
  }, Object.values(filterObj))

  const clearCommonFilters = () => {
    setFilterText("")
    setFilterAuthor(null)
    setDateFrom(null)
    setDateTo(null)
  }

  const authorOptions = participants.map((user) => {
    return {
      value: user.id,
      label: getFullName(user)
    }
  })
  authorOptions.unshift(AUTHOR_BLANK_OPTION)

  return {
    filterText,
    filterAuthor,
    dateFrom,
    dateTo,
    setFilterText,
    setFilterAuthor,
    setDateFrom,
    setDateTo,
    clearCommonFilters,
    authorOptions
  }
}

export default useFilters
