import React from "react"
import { string } from "prop-types"

const { createContext, useContext } = require("react")

const Context = createContext(null)

export const useCurrentFolderId = () => useContext(Context)

const CurrentFolderIdContext = ({ folderId, children }) => {
  return <Context.Provider value={folderId}>{children}</Context.Provider>
}

CurrentFolderIdContext.propTypes = {
  folderId: string.isRequired
}

export default CurrentFolderIdContext
