import { captureException } from "@sentry/browser"

import useIsOpened from "src/hooks/useIsOpened"
import { showFlashMessage } from "src/helpers/flash"

import useCreateFolder from "./useCreateFolder/index"

const useCreateFolderModal = ({ groupId, folderId }) => {
  const { open, close, isOpened } = useIsOpened()

  const { createFolder, loading: submitting } = useCreateFolder()

  const onSubmit = (values, helpers) => {
    const { name } = values
    const { setFieldError } = helpers

    const handleSuccess = ({ successful, message }) => {
      if (successful) {
        showFlashMessage("success", `Folder "${name}" has been created successfully`)
        close()
      } else {
        setFieldError("name", message)
      }
    }

    const handleError = (error) => {
      captureException(error)
      showFlashMessage("danger", "Something went wrong, please try again later")
      close()
    }

    createFolder({ folderId, name, groupId }).then(handleSuccess).catch(handleError)
  }

  return { open, close, isOpened, onSubmit, submitting }
}

export default useCreateFolderModal
