import React from "react"
import { shape, number, string, func, bool } from "prop-types"
import { cx } from "@linaria/core"

import RoleSelect from "./RoleSelect"
import MembershipTypeSelect from "./MembershipTypeSelect"

const TeamCard = ({
  team,
  onRoleChange,
  onMembershipTypeChange,
  onDeselect,
  selected
}) => {
  return (
    <div className={cx("card horizontal", selected ? "is-selected" : null)}>
      <div className="card-mascot">
        <div className="user-avatar">
          <span className="icon-user-supervisor text-black-50 team-avatar" />
        </div>
      </div>
      <div className="card-title">
        <h6 className="card-title-primary">{team.name}</h6>
      </div>
      <div className="card-controls">
        <RoleSelect
          onChange={onRoleChange}
          value={team.role}
          data-type="team"
          data-id={team.id}
          id={`teams_${team.id}_role`}
        />
        {selected && (
          <>
            <MembershipTypeSelect
              value={team.membershipType}
              onChange={onMembershipTypeChange}
              data-type="team"
              data-id={team.id}
              id={`teams_${team.id}_membership_type`}
            />
            <div className="checkbox">
              <input
                type="checkbox"
                id={`member-pick-checkbox-team-${team.id}`}
                defaultChecked={selected}
                onClick={onDeselect}
                data-behavior="member-pick-checkbox"
                data-type="team"
                data-id={team.id}
              />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor={`member-pick-checkbox-team-${team.id}`} />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

TeamCard.propTypes = {
  team: shape({ id: number.isRequired, name: string.isRequired }),
  onRoleChange: func.isRequired,
  onMembershipTypeChange: func,
  onDeselect: func,
  selected: bool
}

export default TeamCard
