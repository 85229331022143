import React from "react"
import { Modal } from "react-bootstrap"
import { arrayOf, bool, func, number, shape } from "prop-types"

import LinkButton from "src/styles/components/Button/Link"
import {
  AttendeeTitle,
  MeetingPackMembershipsColumn
} from "src/styles/components/Card/List"
import AttendeesNotification from "src/components/AttendeesNotification"
import Primary from "src/styles/components/Button/Primary"
import Secondary from "src/styles/components/Button/Secondary"
import { WideModal } from "src/styles/components/Modal"
import { AgendaItem } from "src/features/meetingPacks/MeetingPack/Attendees/List/types"
import useAgendaItemAttendeesChanges from "src/features/meetingPacks/MeetingPack/Attendees/List/useAgendaItemAttendeesChanges"

import AgendaItemName from "../AgendaItemName"

import UserCard from "./UserCard"
import useMembershipsUpdate from "./useMembershipsUpdate"
import TeamCard from "./TeamCard"

const ManageAttendeesModal = ({
  isOpened = false,
  openViewAttendees,
  onHide,
  currentUserId,
  agendaItem,
  teams,
  isMeetingPackPublished,
  possibleAttendees = [],
  agendaItemAttendees = []
}) => {
  const {
    setMemberships,
    areMembershipsUpdating,
    selectedMemberships,
    agendaItemNonAttendees,
    addedTeams,
    possibleTeamsToAdd,
    checkPresentForNonAttendees,
    checkAccessOnlyForNonAttendees,
    toggleUserItemMembership,
    toggleTeam,
    disablePresentMembership,
    disablePresentTeam
  } = useMembershipsUpdate({
    agendaItem,
    possibleAttendees,
    agendaItemAttendees,
    teams,
    onSuccess: onHide,
    onError: onHide
  })

  const { joiningUsers } = useAgendaItemAttendeesChanges(agendaItem.id)

  const isSubItem = agendaItem.agendaItemId

  const manageAttendeesTitle = isSubItem
    ? "Manage Sub-item Attendees"
    : "Manage Item Attendees"

  const itemAttendeesLabel = isSubItem ? "Sub-item attendees" : "Item attendees"
  const notAttendingLabel = isSubItem ? "Not Attending sub-item" : "Not Attending"

  const isUserAdded = (user) =>
    !!joiningUsers.find((joiningUser) => joiningUser.id === user.id)

  return (
    <WideModal show={isOpened} onHide={onHide}>
      <Modal.Header closeButton>
        <div>
          <AgendaItemName agendaItem={agendaItem} />
          <Modal.Title as="h5" className="d-inline text-center">
            {manageAttendeesTitle}
          </Modal.Title>
          <LinkButton
            onClick={openViewAttendees}
            className="d-block d-sm-inline ml-0 ml-sm-2"
          >
            View Attendees
          </LinkButton>
        </div>
      </Modal.Header>
      <Modal.Body className="p-0 allow-scroll">
        <AttendeeTitle className="mb-3">
          <div>{itemAttendeesLabel}</div>
          <MeetingPackMembershipsColumn>
            <div>Attending</div> <div>Access Only</div>
          </MeetingPackMembershipsColumn>
        </AttendeeTitle>
        {addedTeams.map((team) => (
          <TeamCard
            key={team.id}
            team={team}
            disablePresentMembership={disablePresentTeam(team)}
            selectedMemberships={selectedMemberships}
            toggleTeam={toggleTeam}
          />
        ))}
        {!!addedTeams.length && <hr className="mx-4" />}
        {agendaItemAttendees.map((user) => (
          <UserCard
            key={user.id}
            user={user}
            toggleUserItemMembership={toggleUserItemMembership}
            checkedMembership={selectedMemberships[user.id]}
            isCurrentUser={user.id === currentUserId}
            deletedFromAccount={user.deletedFromAccount}
            added={isUserAdded(user)}
            disablePresentMembership={disablePresentMembership(user)}
          />
        ))}
        {!!possibleTeamsToAdd.length && (
          <AttendeeTitle className="mb-3">
            <div>{notAttendingLabel}</div>
            <MeetingPackMembershipsColumn>
              <div>Attending</div> <div>Access Only</div>
            </MeetingPackMembershipsColumn>
          </AttendeeTitle>
        )}
        {possibleTeamsToAdd.map((team) => (
          <TeamCard
            key={team.id}
            team={team}
            disablePresentMembership={disablePresentTeam(team)}
            selectedMemberships={selectedMemberships}
            toggleTeam={toggleTeam}
          />
        ))}
        <AttendeeTitle className="mt-3">
          {possibleTeamsToAdd.length ? <div /> : <div>{notAttendingLabel}</div>}
          <MeetingPackMembershipsColumn>
            <LinkButton onClick={checkPresentForNonAttendees}>Select all</LinkButton>
            <LinkButton onClick={checkAccessOnlyForNonAttendees}>Select all</LinkButton>
          </MeetingPackMembershipsColumn>
        </AttendeeTitle>
        {agendaItemNonAttendees.map((user) => (
          <UserCard
            key={user.id}
            user={user}
            toggleUserItemMembership={toggleUserItemMembership}
            checkedMembership={selectedMemberships[user.id]}
            isCurrentUser={user.id === currentUserId}
            deletedFromAccount={user.deletedFromAccount}
            disablePresentMembership={disablePresentMembership(user)}
          />
        ))}
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100">
          <AttendeesNotification published={isMeetingPackPublished} />

          <div className="d-flex align-horizontally-center">
            <Primary
              className="mr-2"
              onClick={setMemberships}
              disabled={areMembershipsUpdating}
            >
              {areMembershipsUpdating ? "Updating..." : "Confirm"}
            </Primary>
            <Secondary variant="outline-secondary" onClick={onHide}>
              Cancel
            </Secondary>
          </div>
        </div>
      </Modal.Footer>
    </WideModal>
  )
}

ManageAttendeesModal.propTypes = {
  agendaItem: AgendaItem.isRequired,
  currentUserId: number.isRequired,
  isOpened: bool.isRequired,
  onHide: func.isRequired,
  openViewAttendees: func.isRequired,
  isMeetingPackPublished: bool,
  possibleAttendees: arrayOf(shape),
  teams: arrayOf(shape),
  agendaItemAttendees: arrayOf(shape)
}

export default ManageAttendeesModal
