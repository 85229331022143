import { useEffect } from "react"

/**
 * Hook that alerts clicks outside of the passed ref
 */
const useOutsideClick = ({ ref, onClick }) => {
  useEffect(() => {
    /**
     * if clicked on outside of element
     */
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClick()
      }
    }

    // Bind the event listener
    document.addEventListener("mouseup", handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mouseup", handleClickOutside)
    }
  }, [ref, onClick])
}

export default useOutsideClick
