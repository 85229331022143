import { merge } from "lodash"
import { mapValues } from "lodash/object"
import { isPlainObject } from "lodash/lang"

export const annotationPresetsChangesStorageKey = "annotationPresetsChanges"

const getAnnotationPresetsChanges = () => {
  const annotationPresetsChanges = localStorage.getItem(
    annotationPresetsChangesStorageKey
  )

  if (!annotationPresetsChanges) {
    return {}
  }

  try {
    return mapValues(JSON.parse(annotationPresetsChanges), (preset) =>
      mapValues(preset, (presetValue, presetKey) => {
        if (isPlainObject(presetValue) && presetKey !== "text") {
          return new window.PSPDFKit.Color(presetValue)
        }

        if (presetKey === "text" && !presetValue.format)
          return { format: "plain", value: "" }

        return presetValue
      })
    )
  } catch {
    return {}
  }
}

const saveAnnotationPresetsChanges = (newAnnotationPresets) => {
  localStorage.setItem(
    annotationPresetsChangesStorageKey,
    JSON.stringify(
      window.PSPDFKit.AnnotationPresets.toSerializableObject(
        merge(getAnnotationPresetsChanges(), newAnnotationPresets)
      )
    )
  )
}

const setupAnnotationPresetsSaving = (instance) => {
  instance.setAnnotationPresets((annotationPresets) => {
    return {
      ...annotationPresets,
      ...getAnnotationPresetsChanges()
    }
  })

  instance.addEventListener(
    "annotationPresets.update",
    ({ currentPreset, newPresetProperties, currentPresetProperties }) => {
      saveAnnotationPresetsChanges({
        [currentPreset]: { ...currentPresetProperties, ...newPresetProperties }
      })
    }
  )

  return instance
}

export default setupAnnotationPresetsSaving
