import { createSelector } from "@reduxjs/toolkit"

import {
  findPreviousNavigationResource,
  findNextNavigationResource
} from "src/features/meetingPacks/MeetingPack/helpers"
import { getFullName, getInitials } from "src/helpers/user"

import {
  ADMIN_ROLES,
  GUEST_ROLES,
  AGENDA_ITEM_TYPE,
  DOCUMENT_TYPE,
  MEMBERSHIP_TYPES_FOR
} from "./constants"

export const getCurrentUser = (state) => state.meetingPack.currentUser
export const getMeetingPack = (state) => state.meetingPack.meetingPack
export const getAgendaItems = (state) => state.meetingPack.agendaItems
export const getActiveItemId = (state) => state.meetingPack.activeItemId
export const getActiveDocumentId = (state) => state.meetingPack.activeDocument.id
export const getActiveDocumentPage = (state) => state.meetingPack.currentPage
export const getActiveDocumentLoadingState = (state) =>
  state.meetingPack.activeDocument.loadingState
export const getAttendees = (state) => state.meetingPack.attendees
export const getMemberships = (state) => state.meetingPack.memberships
export const getUnreadItems = (state) => state.meetingPack.unreadItems
export const getPage = (state) => state.meetingPack.page
export const getHighlighted = (state) => state.meetingPack.highlighted
export const getTeams = (state) => state.meetingPack.teams

export const getMeetingPackId = createSelector(
  getMeetingPack,
  (meetingPack) => meetingPack?.id
)

export const getCurrentUserId = createSelector(
  getCurrentUser,
  (currentUser) => currentUser?.id
)

export const getMeetingPackAttendees = createSelector(
  getAttendees,
  getMemberships,
  (attendees, memberships) =>
    attendees.map((props) => {
      const membership = memberships.find(({ userId }) => userId === props.id) || {}

      return {
        ...props,
        role: membership.role,
        membershipType: membership.membershipType,
        deletedFromAccount: membership.deletedFromAccount,
        fullName: getFullName(props),
        initials: getInitials(props)
      }
    })
)

export const getVisibleMeetingPackAttendees = createSelector(
  getMeetingPackAttendees,
  (attendees) =>
    attendees.filter(({ membershipType }) =>
      MEMBERSHIP_TYPES_FOR.meetingPackAttendees.includes(membershipType)
    )
)

export const getActiveItem = createSelector(
  getAgendaItems,
  getActiveItemId,
  (agendaItems, activeItemId) => agendaItems.find(({ id }) => id === activeItemId)
)

export const getActiveItemAttendees = createSelector(
  getActiveItem,
  getVisibleMeetingPackAttendees,
  (activeItem, meetingPackAttendees) => {
    const agendaItemUserIds = activeItem.memberships
      .filter(({ membershipType }) =>
        MEMBERSHIP_TYPES_FOR.agendaItemSummary.includes(membershipType)
      )
      .map(({ userId }) => userId)

    return meetingPackAttendees.filter((attendee) =>
      agendaItemUserIds.includes(attendee.id)
    )
  }
)

export const getActiveDocument = createSelector(
  getAgendaItems,
  getActiveDocumentId,
  (agendaItems, activeDocumentId) =>
    agendaItems.reduce((activeDocument, agendaItem) => {
      if (activeDocument) {
        return activeDocument
      }

      return agendaItem.documents.find(({ id }) => id === activeDocumentId)
    }, null)
)

export const getDocuments = createSelector(getAgendaItems, (agendaItems) =>
  agendaItems.flatMap(({ documents }) => documents)
)

const createButtonDisabledSelector = (findResource) =>
  createSelector(
    getAgendaItems,
    getActiveItemId,
    getActiveDocumentId,
    (agendaItems, activeItemId, activeDocumentId) => {
      const resource = findResource({
        agendaItems,
        activeItemId,
        activeDocumentId
      })

      return !(resource.document || resource.agendaItem)
    }
  )

export const isPrevButtonDisabled = createButtonDisabledSelector(
  findPreviousNavigationResource
)

export const isNextButtonDisabled = createButtonDisabledSelector(
  findNextNavigationResource
)

export const getIsCurrentUserAdmin = createSelector(
  getCurrentUser,
  getMemberships,
  (currentUser, memberships) =>
    ADMIN_ROLES.includes(
      memberships?.find(({ userId }) => userId === currentUser.id)?.role
    )
)

export const getIsCurrentUserGuest = createSelector(
  getCurrentUser,
  getMemberships,
  (currentUser, memberships) =>
    GUEST_ROLES.includes(
      memberships?.find(({ userId }) => userId === currentUser.id)?.role
    )
)

export const getUnreadAgendaItemIds = createSelector(getUnreadItems, (unreadItems) =>
  unreadItems
    .filter(({ itemType }) => itemType === AGENDA_ITEM_TYPE)
    .map(({ itemId }) => itemId)
)

export const getUnreadDocumentIds = createSelector(getUnreadItems, (unreadItems) =>
  unreadItems
    .filter(({ itemType }) => itemType === DOCUMENT_TYPE)
    .map(({ itemId }) => itemId)
)
