import React, { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import { number, string, bool, func, arrayOf, shape } from "prop-types"
import { styled } from "@linaria/react"

import { StyledModal } from "src/styles/components/Modal"
import ModalHeader from "src/styles/components/Modal/Header"
import ModalTitle from "src/styles/components/Modal/Title"
import ModalFooter from "src/styles/components/Modal/Footer"
import PrimaryButton from "src/styles/components/Button/Primary"
import { acceptCustomTerms } from "src/api/customTerms"
import { TEXT_COLORS } from "src/styles/colors"
import { FONT_SIZES, FONT_WEIGHTS } from "src/styles/sizes"
import { handlePSPDFKitInitError } from "src/helpers/pspdfkit"

const CustomTermsModal = ({
  accountId,
  customTerms,
  invitationToken,
  isOpened,
  close,
  onAccept,
  className
}) => {
  const [loading, setLoading] = useState(false)
  const [customTermsToReview, setCustomTermsToReview] = useState(customTerms[0])
  const [index, setIndex] = useState(0)
  const { title, pspdfkitId, jwt, id } = customTermsToReview

  useEffect(() => {
    if (isOpened && window.PSPDFKit && window.pspdfkitServerUrl) {
      window.PSPDFKit.load({
        toolbarItems: [],
        serverUrl: window.pspdfkitServerUrl,
        styleSheets: [window.pspdfkitCustomTermsStylesheetPath],
        container: `#custom-terms-pspdfkit`,
        documentId: pspdfkitId,
        authPayload: { jwt },
        instant: false
      }).catch(handlePSPDFKitInitError)
    }
  }, [isOpened])

  const handleAccept = () => {
    setLoading(true)
    acceptCustomTerms({
      accountId,
      customTermsInstanceId: id,
      invitationToken
    })
      .then(({ data = {} }) => {
        setLoading(false)

        if (customTerms.length > index + 1) {
          setCustomTermsToReview(customTerms[index + 1])
          setIndex(index + 1)
        } else if (data.successful) onAccept(data)
      })
      .catch(() => setLoading(false))
  }

  return (
    <StyledModal size="lg" show={isOpened} onHide={close} className={className}>
      <ModalHeader className="pt-4 pl-4 pb-0">
        {title && <ModalTitle dangerouslySetInnerHTML={{ __html: title }} />}
      </ModalHeader>
      <Modal.Body className="text-center pt-0">
        <div id="custom-terms-pspdfkit" />
      </Modal.Body>
      <ModalFooter>
        <PrimaryButton
          className="btn"
          width="md"
          onClick={handleAccept}
          disabled={loading}
        >
          Accept
        </PrimaryButton>
      </ModalFooter>
    </StyledModal>
  )
}

CustomTermsModal.propTypes = {
  accountId: number.isRequired,
  customTerms: arrayOf(
    shape({
      title: string,
      pspdfkitId: string.isRequired,
      jwt: string.isRequired,
      id: number.isRequired,
      acceptedDate: string
    })
  ).isRequired,
  close: func.isRequired,
  onAccept: func.isRequired,
  invitationToken: string,
  isOpened: bool
}

export default styled(CustomTermsModal)`
  .modal-content,
  #custom-terms-pspdfkit {
    height: 100%;
    max-height: none;
  }

  .modal-dialog {
    height: calc(100% - 60px);
  }

  .modal-title {
    color: ${TEXT_COLORS.muted};
    font-size: ${FONT_SIZES.small};
    font-weight: ${FONT_WEIGHTS.semiBold};
  }

  .modal-header {
    border-bottom: none;
    flex: 0 1 content;
  }
`
