import React from "react"
import { number, string } from "prop-types"

import IconPencilPlus from "src/styles/components/IconPencilPlus"
import { OBJECT_TYPES } from "src/features/UniversalAi/constants"
import { useUniversalAi } from "src/features/UniversalAi"

const QueryIframeContent = ({ appContentId, appTitle }) => {
  const { queryInSidebar } = useUniversalAi()
  const queryIframeContent = () =>
    queryInSidebar({
      objectId: appContentId,
      objectType: OBJECT_TYPES.iframeContent,
      objectTitle: appTitle
    })

  return <IconPencilPlus onClick={queryIframeContent} variant="without-background" />
}

QueryIframeContent.propTypes = {
  appContentId: number.isRequired,
  appTitle: string.isRequired
}

export default QueryIframeContent
