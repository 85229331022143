export const FILTER_TYPES = {
  equal: "is",
  contain: "contains"
}

export const EXTENDED_FILTER_TYPES = {
  ...FILTER_TYPES,
  not_equal: "is not",
  empty: "empty",
  not_empty: "is not empty"
}

export const FILTER_TYPES_WITHOUT_VALUES = ["empty", "not_empty"]

export const DEFAULT_FILTER_TYPE = "equal"

export const SORT_DIRECTIONS = {
  asc: "Ascending",
  desc: "Descending"
}

export const DEFAULT_SORT_DIRECTION = "asc"
