import React, { useRef } from "react"
import classNames from "classnames"
import { shape, string, number } from "prop-types"
import { styled } from "@linaria/react"

import { FONT_WEIGHTS } from "src/styles/sizes"
import { useDrag } from "src/features/meetingPacks/MeetingPack/Transcript/View/useDragDrop"

const AgendaItem = ({ agendaItem, className }) => {
  const ref = useRef(null)

  const {
    drag,
    preview,
    style: dragAndDropStyle
  } = useDrag({
    item: {
      agendaItemId: agendaItem.id,
      name: agendaItem.name,
      agendaItemsListIndex: agendaItem.index
    },
    index: null
  })

  drag(ref)

  return (
    <h6
      className={classNames(
        "py-2 px-3 mb-3",
        agendaItem.deepLevel > 1 && "sub-agenda-item",
        className
      )}
      ref={ref}
      style={dragAndDropStyle}
    >
      <div ref={preview}>
        {agendaItem.index}. {agendaItem.name}
      </div>
    </h6>
  )
}

AgendaItem.propTypes = {
  agendaItem: shape({
    name: string.isRequired,
    index: string.isRequired,
    deepLevel: number.isRequired
  })
}

export default styled(AgendaItem)`
  background-color: #fbfbfb;
  border-radius: 3px;
  font-weight: ${FONT_WEIGHTS.semiBold};

  &.sub-agenda-item {
    margin-left: 10px;
  }
`
