class @MemberActionsComponent
  setup: () =>
    document.body.addEventListener('ajax:before', @setupConfirmationWindow)

  setupConfirmationWindow: (event) =>
    return unless event.target?.dataset?.behavior == 'revoke-permission'

    text = event.target?.dataset?.confirm or "Are you sure?"

    if not window.confirm(text)
      event.preventDefault()

