import moment from "moment-timezone"
import humanizeDuration from "humanize-duration"

import {
  DATETIME_FORMAT,
  DURATIONS_SEPARATOR,
  ALLOWED_UNITS,
  DATE_FORMAT
} from "src/constants/datetimeFormats"

export const formatDateTime = ({
  datetime,
  timeZone = null,
  format = DATETIME_FORMAT
}) => {
  if (!timeZone) {
    return moment(datetime)?.format(format)
  }

  return moment.utc(datetime)?.tz(timeZone)?.format(format)
}

export const formatDate = (date, format = DATE_FORMAT) => moment(date).format(format)

export const formatDuration = ({ duration }) => {
  return humanizeDuration(moment.duration(duration, "seconds"), {
    delimiter: DURATIONS_SEPARATOR,
    units: ALLOWED_UNITS
  })
}

export const validateDuration = ({ duration }) => {
  return Boolean(duration && moment.duration(duration).asMilliseconds() !== 0)
}

export const isBeforeToday = (datetime) => {
  const today = moment()

  return moment(datetime).isBefore(today, "day")
}

export const formatTimestamp = (datetime) => {
  const today = moment().startOf("day")
  const yesterday = moment().startOf("day").subtract(1, "day")
  const messageTime = moment.utc(datetime).local()
  const shortTimestamp = messageTime.format("h:mma")

  let timestamp = messageTime.format("MMM Do Y, h:mma")

  if (messageTime.format("L") === today.format("L")) {
    timestamp = shortTimestamp
  } else if (messageTime.format("L") === yesterday.format("L")) {
    timestamp = `yesterday, ${shortTimestamp}`
  } else if (messageTime.format("Y") === yesterday.format("Y")) {
    timestamp = messageTime.format("MMM Do, h:mma")
  }

  return timestamp
}

export const millisecondsToMinutes = (ms) => {
  const seconds = Math.floor(ms / 1000)
  return Math.floor(seconds / 60)
}
