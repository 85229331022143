import React from "react"
import { func, string, bool, oneOfType, arrayOf } from "prop-types"

import { airtableFieldType } from "src/resources/accounts/airtablePropTypes"

import Checkbox from "./Checkbox"
import MultipleSelect from "./MultipleSelect"
import RichTextInput from "./RichTextInput"
import SingleSelect from "./SingleSelect"
import Textarea from "./Textarea"
import TextInput from "./TextInput"
import { FIELD_TYPES } from "./constants"

const FieldInput = ({ field, value, invalid, setRecord }) => {
  const inputProps = { field, value, setRecord, invalid }

  if (field.type === FIELD_TYPES.string) return <TextInput {...inputProps} />
  if (field.type === FIELD_TYPES.text) return <Textarea {...inputProps} />
  if (field.type === FIELD_TYPES.select) return <SingleSelect {...inputProps} />
  if (field.type === FIELD_TYPES.multiselect) return <MultipleSelect {...inputProps} />
  if (field.type === FIELD_TYPES.checkbox) return <Checkbox {...inputProps} />
  if (field.type === FIELD_TYPES.richText) return <RichTextInput {...inputProps} />
}

FieldInput.propTypes = {
  field: airtableFieldType.isRequired,
  setRecord: func.isRequired,
  value: oneOfType([string, bool, arrayOf(string)]),
  invalid: bool
}

export default FieldInput
