import isOfflineEnabled from "src/features/offline/isOfflineEnabled"
import { getCurrentUser, getCurrentUserId } from "src/helpers/user"

import { clearOutdatedMeetingPacks } from "./helpers/meetingPacks"
import { postSyncAllCachedMeetingPacksMessage } from "./helpers/messages"

if (isOfflineEnabled()) {
  window.addEventListener("turbo:load", () => {
    if (
      getCurrentUser() &&
      !window.location.pathname.match(new RegExp(/\/meeting_packs\/\d+\/view/))
    ) {
      setTimeout(() => {
        postSyncAllCachedMeetingPacksMessage({ userId: getCurrentUserId() })
      }, 2000)

      // timeout is set to avoid simultaneous syncing and clearing
      setTimeout(clearOutdatedMeetingPacks, 5000)
    }
  })
}
