import { useState, useEffect, useCallback, useRef } from "react"
import isEqual from "lodash/isEqual"

const useToggle = (scope = "", initialValue = false) => {
  const currentProps = useRef(scope)
  const [value, setValue] = useState(initialValue)

  const toggleValue = useCallback(() => {
    setValue((v) => !v)
  }, [])

  const resetValue = useCallback(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    currentProps.current = scope
    return resetValue
  }, [scope, resetValue])

  const resultValue = isEqual(currentProps.current, scope) ? value : initialValue

  return [resultValue, toggleValue, resetValue]
}

export default useToggle
