import React from "react"
import { useFormikContext } from "formik"
import { number } from "prop-types"

const SubmitButton = ({ timeLeftUntilNextAttempt, className }) => {
  const { handleSubmit, isSubmitting } = useFormikContext()
  const waitUntilNextAttempt = timeLeftUntilNextAttempt && timeLeftUntilNextAttempt > 0

  return (
    <button
      className={`button ${className}`}
      type="button"
      onClick={handleSubmit}
      disabled={isSubmitting || waitUntilNextAttempt}
    >
      {waitUntilNextAttempt
        ? `You can resend the code again in ${timeLeftUntilNextAttempt} seconds`
        : "Next"}
    </button>
  )
}

SubmitButton.propTypes = {
  timeLeftUntilNextAttempt: number
}

export default SubmitButton
