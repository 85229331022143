import React from "react"
import { func } from "prop-types"

import CloseButton from "src/components/CloseButton"

import { HeaderContainer } from "./styles"

const Header = ({ children, onClose }) => {
  return (
    <HeaderContainer>
      <div>
        <strong>{children}</strong>
      </div>
      <div>
        <CloseButton onClick={onClose} />
      </div>
    </HeaderContainer>
  )
}

Header.propTypes = {
  onClose: func.isRequired
}

export default Header
