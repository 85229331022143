import { useEffect, useState } from "react"
import { useInView } from "react-intersection-observer"

const usePagination = (rawEntities, setEntities, filters, page, setPage, loading) => {
  const [finishPagination, setFinishPagination] = useState(false)

  useEffect(() => {
    setEntities([])
    setPage(1)
    setFinishPagination(false)
  }, [filters])

  const [refPaginationTrigger, inView] = useInView({
    onChange: () => {
      if (inView && !finishPagination) {
        setPage(page + 1)
      }
    }
  })

  useEffect(() => {
    if (!loading && rawEntities) {
      if (rawEntities.length) {
        setEntities((prevEntities) => {
          const prevAttachmentsIds = prevEntities.map((att) => att.id)
          // Backend returns duplicates if attachments have exact same created_at so we need to remove duplicates
          const filteredNewEntities = rawEntities.filter(
            (newAttachment) => !prevAttachmentsIds.includes(newAttachment.id)
          )
          return [...prevEntities, ...filteredNewEntities]
        })
      } else {
        setFinishPagination(true)
      }
    }
  }, [rawEntities, loading])

  return {
    refPaginationTrigger
  }
}

export default usePagination
