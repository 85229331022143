class @DiscussionsShowHandler
  @EDIT_MESSAGE_REMOVED_BUTTONS = ['addDocument', 'collaborativeDocument']

  constructor: ->
    @groupMembershipWatcherComponent = new GroupMembershipWatcherComponent()

    discussionPage = $be('discussion-page')
    @discussionId = discussionPage.data('id')
    @accountId = discussionPage.data('account-id')
    @groupId = window.groupId
    @aiFeaturesEnabled = window.Knowa?.featureFlags?.aiFeaturesEnabled

    @autosaveContentId = "discussion-#{@discussionId}"
    @autosavedMessageComponent = new AutosavedMessageComponent(
      @autosaveContentId
    )

    @emailToDiscussionComponent = new EmailToDiscussionComponent()

    @userMentionOptions = window.userMentionOptions

    @messageActionStatusComponent = new MessageActionStatusComponent()

    @fieldEncodingComponent = new FieldEncodingComponent()

    @discussionMessagesComponent = new DiscussionMessagesComponent(
      thread: { type: 'Discussion', id: @discussionId, groupId: @groupId },
      editDesktopMessageConfig: @editDesktopMessageConfig(),
      editMobileMessageConfig: @editMobileMessageConfig(),
      messageChannelName: "discussions:updates",
      messagePathTemplate: "/discussions/#{@discussionId}/messages/MESSAGE_ID",
      unreadMessagePathTemplate: "/discussions/#{@discussionId}/unread_messages/MESSAGE_ID"
    )

    @messageActionComponent = new MessageActionComponent(@discussionMessagesComponent)

    @starMessageComponent = new StarMessageComponent(discussionPage)

    @autoquoteComponent = new AutoquoteComponent(
      @discussionMessagesComponent,
      @userMentionOptions
    )

    @groupMemberListComponent = new GroupMemberListComponent(
      listContainerBehavior: 'group-participants',
      manageEnabled: false,
      contactDetailsEnabled: true
    )

    @attachDocumentComponent = window.boxComponentsFactory.attachDocumentComponent({
      thread: {
        id: @discussionId,
        type: 'Discussion'
      },
      autosavedMessageComponent: @autosavedMessageComponent,
      attachCallback: @discussionMessagesComponent.scrollToBottom
    })

    @discussionsChannel = AblyRealtimeClient.channels.get('groups:discussions')
    @channelSubscriptions = [{
      channel: @discussionsChannel,
      subscriber: @handleDiscussionMessages
    }]

    @newMessageForm = $be('new-message-form')
    @newMessageFormWrapper = $be('new-message-form-wrapper')
    @showNewMessageFormButton = $be('show-new-message-form')
    @hideNewMessageForm = $be('hide-new-message-form')
    @newMessageSubmit = $be('new-message-submit')

    @mobileNewMessageForm = $be('mobile-message-form')
    @mobileNewMessageModal = $be('new-message-modal')
    @showMobileNewMessageFormButton = $be('show-mobile-new-message-form')
    @hideMobileNewMessageForm = $be('hide-mobile-new-message-form')

    @mobileNewMessageSubmit = $be('mobile-new-message-submit')

    @messageValidationComponent = new MessageValidationComponent(
      {
        attachDocumentComponent: @attachDocumentComponent
      }
    )

  setup: =>
    (new FroalaEmbedComponent).setup()
    (new FroalaCollaborativeDocumentComponent).setup()
    (new FroalaSignedDocumentComponent).setup()
    (
      new FroalaOpenaiButtonComponent({
        objectType: 'Message', discussionId: @discussionId
      })
    ).setup()

    @discussionMessagesComponent.setup()
    @starMessageComponent.setup()
    @groupMembershipWatcherComponent.setup()
    @groupMemberListComponent.setup()

    @attachDocumentComponent.setup()

    @setupNewMessageEditors()
    @bindNewMessageFormActions()
    @loadFormIfMessageSaved()

    @messageActionComponent.setup()

    @autoquoteComponent.setup(
      @newMessageEditor,
      @mobileNewMessageEditor,
      @openEditor
    )

    $setupChannelSubscriptions(@channelSubscriptions)

  teardown: =>
    @discussionMessagesComponent.teardown()
    @groupMembershipWatcherComponent.teardown()
    @groupMemberListComponent.teardown()

    @attachDocumentComponent.teardown()

    @newMessageEditor.teardown()
    @mobileNewMessageEditor.teardown()

    $teardownChannelSubscriptions(@channelSubscriptions)

  insertPage: (pageNumber, messagesPage) =>
    @discussionMessagesComponent.insertPage(pageNumber, messagesPage)

  addNewMessage: (messageId, authorId, messageHtml) =>
    @discussionMessagesComponent.addNewMessage(
      messageId, authorId, messageHtml
    )

  removeMessage: (messageId) =>
    @discussionMessagesComponent.removeMessage(messageId)

  updateMessage: (messageId, messageHtml) =>
    @discussionMessagesComponent.updateMessage(
      messageId,
      messageHtml
    )

  clearMessageForm: =>
    @newMessageEditor.clear()
    @mobileNewMessageEditor.clear()
    @autosavedMessageComponent.reset()
    @attachDocumentComponent.clearAttachedDocuments()
    window.dispatchEvent(new Event('messageFormCleared'))

  handleDiscussionMessages: (channelMessage) =>
    if channelMessage.name in ['discussion-restore', 'discussion-archive']
      Turbo.visit(window.location)

  bindNewMessageFormActions: =>
    @showNewMessageFormButton.click (e) =>
      @newMessageFormWrapper.show()
      @showNewMessageFormButton.hide()
      @discussionMessagesComponent.scrollToBottom()

      @newMessageEditor.focus()
      @newMessageEditor.refreshPlaceholder()
      @mobileNewMessageEditor.focus()

    @mobileNewMessageModal.on 'shown.bs.modal', =>
      document.activeElement.blur()
      @mobileNewMessageEditor.focus()

    @hideNewMessageForm.click (e) =>
      e.preventDefault()
      @newMessageFormWrapper.hide()
      @showNewMessageFormButton.show()

    @discussionMessagesComponent.bindSendingMessageHandler(
      @newMessageForm,
      () => @newMessageEditor.getWrappedContent()
    )

    @discussionMessagesComponent.bindSendingMessageHandler(
      @mobileNewMessageForm,
      () => @mobileNewMessageEditor.getWrappedContent()
    )

    @newMessageForm.on('ajax:before', (e) =>
      if @validate(false)
        @fieldEncodingComponent.replaceMessageBody(@newMessageForm[0])
        @hideNewMessageForm.click()
      else
        return false
    )

    @mobileNewMessageForm.on('ajax:before', (e) =>
      if @validate(true)
        @fieldEncodingComponent.replaceMessageBody(@mobileNewMessageForm[0])
        @hideMobileNewMessageForm.click()
      else
        return false
    )

  validate: (mobile) =>
    form = if mobile then @mobileNewMessageForm else @newMessageForm
    editor = if mobile then @mobileNewMessageEditor else @newMessageEditor

    @messageValidationComponent.validate(mobile, form, editor)

  editDesktopMessageConfig: =>
    toolbarButtons = @toolbarButtons()
    moreRich = toolbarButtons.moreRich
    buttonsVisible = moreRich.buttonsVisible

    # don't allow attachments and collaborative documents when editing a message
    toolbarButtons = {
      toolbarButtons...,
      moreRich: {
        moreRich...,
        buttons: moreRich.buttons.filter(
          (button) -> not (button in DiscussionsShowHandler.EDIT_MESSAGE_REMOVED_BUTTONS)
        ),
        buttonsVisible: if buttonsVisible is 0 then 0 else 3
      }
    }

    {
      toolbarButtons: toolbarButtons,
      mentions: {
        data: @userMentionOptions
      }
      params: {
        videoUploadToS3: window.videoUploadToS3
        events: {
          'video.inserted': (img) ->
            videos = this.$el.find("video[src=\"#{img[0].children[0].src}\"]")
            if videos.length > 1
              videos[0].closest('.fr-video').remove()
        }
        imageUploadToS3: window.imageUploadToS3
        imageEditButtons: [
          'imageReplace',
          'imageAlign',
          'imageSize',
          'imageRemove',
          'imageLink'
        ]
        placeholderText: 'Type a message'
        heightMin: 100
        heightMax: 300
        emoticonsUseImage: false
        emoticonsStep: 10
      }
    }

  editMobileMessageConfig: =>
    {
      toolbarButtons: {}
      mentions: {
        data: @userMentionOptions
      }
      params: {
        placeholderText: ''
        height: "16vh"
      }
    }


  toolbarButtons: =>
    width = window.innerWidth

    if width >= 1430
      return @desktopToolbarButtons()
    else if width > 1280
      return @mediumScreenToolbarButtons()

    @smallScreenToolbarButtons()

  desktopToolbarButtons: =>
    {
      moreClearformatting: {
        buttons: ['clearFormatting'],
        align: 'left',
        buttonsVisible: 2
      }

      moreText: {
        buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'superscript', 'subscript', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass'],
        align: 'left',
        buttonsVisible: 3
      },

      moreParagraph: {
        buttons: ['align', 'formatUL', 'formatOL', 'quote', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent'],
        align: 'left',
        buttonsVisible: 3
      },

      moreRich: {
        buttons: ['addDocument', 'collaborativeDocument', 'signedDocument', 'insertTable',
          'insertLink', 'insertImage', 'emoticons', 'insertVideo', 'insertHR',
          'embed'],
        align: 'left',
        buttonsVisible: if @aiFeaturesEnabled then 3 else 4
      },

      openaiButton: {
        buttons: if @aiFeaturesEnabled then ['openaiButton'] else [],
        align: 'left',
        buttonsVisible: 1
      }

      moreButtons: {
        buttons: ['fullscreen'],
        align: 'right',
        buttonsVisible: 2
      },

      moreMisc: {
        buttons: ['undo', 'redo'],
        align: 'right',
        buttonsVisible: 2
      },
    }

  mediumScreenToolbarButtons: =>
    toolbarButtons = @desktopToolbarButtons()
    moreParagraph = toolbarButtons.moreParagraph

    {
      ...toolbarButtons,
      moreParagraph: {
        ...moreParagraph,
        buttonsVisible: 0
      },
    }

  smallScreenToolbarButtons: =>
    toolbarButtons = @mediumScreenToolbarButtons()
    moreRich = toolbarButtons.moreRich
    moreText = toolbarButtons.moreText

    {
      ...toolbarButtons,
      moreText: {
        ...moreText,
        buttonsVisible: 0
      },
      moreRich: {
        ...moreRich,
        buttonsVisible: 0
      }
    }

  loadFormIfMessageSaved: =>
    if @autosavedMessageComponent.savedContentIsPresent() && !@isLinkToMessage()
      # editor's initialization is async, so we have to open editor
      setTimeout(@openEditor, 100)

    @attachDocumentComponent.loadAutosavedDocuments()

  openEditor: =>
    if @showNewMessageFormButton.is(':visible')
      @showNewMessageFormButton.click()
    else if @showMobileNewMessageFormButton.is(':visible')
      @showMobileNewMessageFormButton.click()

  setupNewMessageEditors: =>
    @newMessageEditor = new MessageEditorComponent(
      $be('new-message-input'),
      {
        mentions: {
          data: @userMentionOptions
        },
        onCtlrEnter: -> $be('new-message-submit').click()
        onAddDocument: => @attachDocumentComponent.show()
        onFullScreen: => @discussionMessagesComponent.scrollToBottom()
        toolbarButtons: @toolbarButtons(),
        quickInsertButtons: ['image', 'table', 'ul', 'ol', 'hr']
        autosaveContentId: @autosaveContentId
        params: {
          videoUploadToS3: window.videoUploadToS3
          events: {
            'video.inserted': (img) ->
              videos = this.$el.find("video[src=\"#{img[0].children[0].src}\"]")
              if videos.length > 1
                videos[0].closest('.fr-video').remove()
          }
          imageUploadToS3: window.imageUploadToS3
          imageEditButtons: [
            'imageReplace',
            'imageAlign',
            'imageSize',
            'imageRemove',
            'imageLink'
          ]
          heightMin: 200
          heightMax: 300
          emoticonsUseImage: false
          emoticonsStep: 10
        }
      }
    )
    @newMessageEditor.setup()

    @mobileNewMessageEditor = new MessageEditorComponent(
      $be('mobile-new-message-input'),
      {
        onAddDocument: => @attachDocumentComponent.show()
        onFocus: ->
          $('html, body').animate(
            scrollTop: 0
          , 400)
        mentions: {
          data: @userMentionOptions
        }
        autosaveContentId: @autosaveContentId
        params: {
          toolbarSticky: false,
          placeholderText: '',
          quickInsertEnabled: false
        }
      }
    )
    @mobileNewMessageEditor.setup()

  isLinkToMessage: ->
    window.location.search.indexOf('message_id') != -1
