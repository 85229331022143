import { be, beByEntity } from "src/helpers/document"

export class AddAccountMembersComponent {
  constructor() {
    this.modal = be("add-account-members-modal")
    this.setup = this.setup.bind(this)
    this.bindRoleSelector = this.bindRoleSelector.bind(this)
    this.bindMembershipTypeSelector = this.bindMembershipTypeSelector.bind(this)
    this.bindCheckbox = this.bindCheckbox.bind(this)
    this.bindDialogControls = this.bindDialogControls.bind(this)
    this.manageSelectedCard = this.manageSelectedCard.bind(this)
    this.appendSelectedCard = this.appendSelectedCard.bind(this)
    this.removeSelectedCard = this.removeSelectedCard.bind(this)
    this.setSubmitButtonEnabled = this.setSubmitButtonEnabled.bind(this)
    this.updateDialogControls = this.updateDialogControls.bind(this)
    this.updateLastSelectedTeam = this.updateLastSelectedTeam.bind(this)
    this.updateMembership = this.updateMembership.bind(this)
  }

  setup() {
    const filterInputComponent = new window.FilterInputComponent({
      input: "add-account-member-filter",
      items: "member-pick-card",
      emptyMessage: "users-filter-empty"
    })
    filterInputComponent.setup()

    be("back-to-add-new-users-link").click((e) => {
      e.preventDefault()
      be("add-account-members-modal").modal("hide")
      be("add-new-scheme-users-modal").modal("show")
    })

    this.bindDialogControls()
    this.updateDialogControls()
  }

  bindRoleSelector(container) {
    const { manageSelectedCard } = this

    container.find("[data-behavior=member-role-select]").change(function onChange() {
      const select = $(this)
      const id = select.data("id")
      const entityType = select.data("type")
      const card = select.parents("[data-behavior=member-pick-card]")
      manageSelectedCard(card, select.val(), id)
      if (entityType === "team") {
        const userIds = select.data("user-ids")
        userIds.forEach((userId) => {
          const userCard = beByEntity("member-pick-card", "user", userId).filter(
            ":visible"
          )
          if (
            card.is(":visible") &&
            select.val().length &&
            !userCard.hasClass("is-selected")
          ) {
            return
          }
          manageSelectedCard(userCard, select.val(), userId)
        })
      }
    })
  }

  bindMembershipTypeSelector(container) {
    const selector = container.find("[data-behavior=membership-type-select]")
    selector.each(() => {
      $(this).addClass("inactive")
      $(this).click(() => {
        $(this).removeClass("inactive")
      })
    })

    const { updateDialogControls, updateMembership } = this
    selector.change(function onChange() {
      const select = $(this)
      const id = select.data("id")
      const entity = select.data("type")

      updateMembership(select, entity, id)
      if (entity === "team") {
        select.data("user-ids").forEach((userId) => {
          const userSelect = beByEntity("membership-type-select", "user", userId)
          userSelect.val(select.val())
          updateMembership(userSelect, "user", userId)
        })
      }

      updateDialogControls()
    })
  }

  updateMembership(select, entity, id) {
    if (select.val() === "") {
      select.addClass("inactive")
    } else {
      select.removeClass("inactive")
      const relatedCard = beByEntity("member-pick-card", entity, id).not(".is-selected")
      relatedCard.find("[data-behavior=membership-type-select]").val(select.val())
    }
  }

  bindCheckbox(container) {
    const { removeSelectedCard } = this
    container.find("[data-behavior=member-pick-checkbox]").change(function onChange() {
      const checkbox = $(this)
      const card = checkbox.parents("[data-behavior=member-pick-card]")
      removeSelectedCard(card)
      if (card.data("type") === "team") {
        checkbox.data("user-ids").forEach((userId) => {
          const userCard = beByEntity("member-pick-card", "user", userId).filter(
            ":visible"
          )
          removeSelectedCard(userCard)
        })
      }
    })
  }

  bindDialogControls() {
    this.bindRoleSelector(this.modal)

    be("selected-users-count").click((e) => {
      e.preventDefault()
      be("modal-container").scrollTop(0)
    })

    be("add-account-members-submit").click((e) => {
      e.preventDefault()
      window.Rails.fire(be("add-account-members-form")[0], "submit")
      this.modal.modal("hide")
    })
  }

  manageSelectedCard(card, selectedVal, id) {
    if (selectedVal === "") {
      this.removeSelectedCard(card, id)
    } else {
      this.appendSelectedCard(card, selectedVal, id)
    }
  }

  appendSelectedCard(card, selectedRole, id) {
    card.find("[data-behavior=member-role-select]").val(selectedRole)

    if (card.hasClass("is-selected")) {
      const typeCard = card.data("type")
      const relatedCard = beByEntity("member-pick-card", typeCard, id).not(".is-selected")
      relatedCard.find("[data-behavior=member-role-select]").val(selectedRole)
      return
    }

    const selectedMemberCard = card.clone()
    selectedMemberCard.addClass("is-selected")
    selectedMemberCard.find("[data-behavior=member-role-select]").val(selectedRole)

    selectedMemberCard
      .find("[data-behavior=membership-type-select]")
      .removeClass("d-none")

    selectedMemberCard.find("[data-behavior=member-pick-checkbox]").prop("checked", true)

    this.bindRoleSelector(selectedMemberCard)
    this.bindCheckbox(selectedMemberCard)
    this.bindMembershipTypeSelector(selectedMemberCard)

    const selectedList = be("selected-list")
    if (selectedMemberCard.data("type") === "team") {
      selectedList.prepend(selectedMemberCard)
      this.updateLastSelectedTeam()
    } else {
      selectedList.append(selectedMemberCard)
    }

    card.hide()
    this.updateDialogControls()
  }

  removeSelectedCard(card) {
    if (!card.hasClass("is-selected")) return

    const id = card.data("id")
    const cardType = card.data("type")
    card.remove()
    const originalCard = beByEntity("member-pick-card", cardType, id)
    originalCard.find("[data-behavior=member-role-select]").val("")
    originalCard.find("[data-behavior=membership-type-select]").val("")
    originalCard.show()
    this.updateDialogControls()
    if (cardType === "team") this.updateLastSelectedTeam()
  }

  setSubmitButtonEnabled(enabled = true) {
    const submitButton = be("add-account-members-submit")
    if (!submitButton[0]) return
    if (enabled) {
      submitButton[0].removeAttribute("disabled")
      submitButton.removeClass("disabled")
    } else {
      submitButton[0].setAttribute("disabled", "disabled")
      submitButton.addClass("disabled")
    }
  }

  updateLastSelectedTeam() {
    const selectedList = be("selected-list")
    selectedList.children("[data-type=team]").removeClass("last-of-team")
    selectedList.children("[data-type=team]:last").addClass("last-of-team")
  }

  updateDialogControls() {
    const count = be("member-pick-checkbox").filter("[data-type=user]:checked").length
    const counter = be("selected-users-count")
    let isSubmitEnabled

    if (be("add-account-members-modal").data("id") === "add-to-scheme") {
      isSubmitEnabled = true
    } else {
      // each select is rendered twice, that"s why we divide by 2
      const membershipTypeSelectedCount =
        be("membership-type-select").filter(
          (index, el) => el.dataset.type === "user" && el.options.selectedIndex
        ).length / 2
      isSubmitEnabled = count === membershipTypeSelectedCount
    }

    switch (count) {
      case 0:
        counter.hide()
        return this.setSubmitButtonEnabled(false)
      case 1:
        counter.show()
        counter.html("1 person selected")
        return this.setSubmitButtonEnabled(isSubmitEnabled)
      default:
        counter.show()
        counter.html(`${count} people selected`)
        return this.setSubmitButtonEnabled(isSubmitEnabled)
    }
  }
}
