import { useDispatch } from "react-redux"

import { actions } from "./store"

const useUniversalAi = () => {
  const dispatch = useDispatch()

  const summariseAsTextInSidebar = ({ objectId, objectType }) =>
    dispatch(actions.summariseAsTextInSidebar({ objectId, objectType }))

  const summariseAsBulletsInSidebar = ({ objectId, objectType }) =>
    dispatch(actions.summariseAsBulletsInSidebar({ objectId, objectType }))

  const queryInSidebar = ({ objectId, objectType, objectTitle = null }) =>
    dispatch(actions.queryInSidebar({ objectId, objectType, objectTitle }))

  const generateDraftMinutesInSidebar = ({ objectId, objectType }) =>
    dispatch(actions.generateDraftMinutesInSidebar({ objectId, objectType }))

  const close = () => dispatch(actions.close())

  return {
    summariseAsTextInSidebar,
    summariseAsBulletsInSidebar,
    queryInSidebar,
    generateDraftMinutesInSidebar,
    close
  }
}

export default useUniversalAi
