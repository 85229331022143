import { useEffect, useRef } from "react"

const enableScrollOnTouchDevices = (e) => e.stopPropagation()

export default () => {
  const ref = useRef()

  useEffect(() => {
    const element = ref.current
    if (!element) return

    element.addEventListener("touchmove", enableScrollOnTouchDevices)

    return () => {
      element.removeEventListener("touchmove", enableScrollOnTouchDevices)
    }
  }, [ref.current])

  return ref
}
