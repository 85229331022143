import React from "react"
import { number, bool } from "prop-types"

const Header = ({ groupId, jointScheme }) => {
  const title = groupId
    ? "Edit group"
    : `New ${jointScheme ? "Connected" : "Private"} Group`

  return (
    <div className="subheader-nav">
      <div className="subheader-title">
        <h5>{title}</h5>
      </div>
    </div>
  )
}

Header.propTypes = {
  groupId: number,
  jointScheme: bool.isRequired
}

export default Header
