import { useState } from "react"
import { captureException } from "@sentry/browser"
import axios from "axios"

import { showFlashMessage } from "src/helpers/flash"
import useAblyChannel from "src/hooks/useAblyChannel"
import { CHANNELS } from "src/constants/ably"
import { getCurrentUserId } from "src/helpers/user"
import { formatSize } from "src/helpers/number"

import { UploadFile } from "../UploadFile"
import UploadFolder from "../UploadFolder"
import { FolderItemTypes, SYSTEM_FILES } from "../consts"

import useRequestUploadUrls from "./useRequestUploadUrls"
import useUploadFolderItems from "./useUploadFolderItems"

const DEFAULT_ERROR_MESSAGE =
  "An error occurred while uploading files, please, try again later"

const FILE_SIZE_LIMIT = 524288000 // 500 Mb
const TOTAL_UPLOAD_LIMIT = 2147483648 // 2 Gb

const extractPathParts = (file) => {
  // Files that are selected with "Upload files" or "Upload folder" button
  if (typeof file.webkitRelativePath === "string") {
    return file.webkitRelativePath.split("/")
  }

  // Files that are dropped into dropzone, they have path that starts with "/",
  // example: "/RootFolder/doc.pdf".
  if (typeof file.fullPath === "string") {
    return file.fullPath.split("/").slice(1)
  }

  throw new Error("Unknown type of file")
}

const isFileInsideFolder = (file) => {
  return extractPathParts(file).length > 1
}

const validate = (uploadFolder) => {
  const allFiles = uploadFolder.allFiles()
  const filesTotalSize = allFiles.reduce((total, file) => total + file.size, 0)

  if (filesTotalSize > TOTAL_UPLOAD_LIMIT) {
    return {
      valid: false,
      errorMessage: `Total size of files exceeds our upload limit of ${formatSize(TOTAL_UPLOAD_LIMIT)}`
    }
  }

  for (let i = 0; i < allFiles.length; i += 1) {
    const file = allFiles[i]

    if (file.size > FILE_SIZE_LIMIT) {
      return {
        valid: false,
        errorMessage: `The file ${file.name} exceeds our upload limit of ${formatSize(FILE_SIZE_LIMIT)}`
      }
    }
  }

  return { valid: true }
}

const organizeFilesIntoFolders = async (files) => {
  const rootFolder = new UploadFolder()

  const filesPromises = []

  files.forEach((file) => {
    if (SYSTEM_FILES.includes(file.name)) {
      return
    }

    const pathParts = extractPathParts(file)
    let currentFolder = rootFolder

    if (pathParts.length === 0) {
      filesPromises.push(currentFolder.addFile(file))
    }

    for (let i = 0; i < pathParts.length; i += 1) {
      const pathPart = pathParts[i]

      if (i === pathParts.length - 1) {
        filesPromises.push(currentFolder.addFile(file))
      } else {
        currentFolder = currentFolder.findOrCreateFolder(pathPart)
      }
    }
  })

  await Promise.all(filesPromises)
  return rootFolder
}

const useUploadPanel = ({ groupId, folderId, onFinished, permissions }) => {
  const currentUserId = getCurrentUserId()

  const [uploadFolder, setUploadFolder] = useState(null)
  const updateUploadFolderState = () => setUploadFolder(uploadFolder.copy())

  const uploadPanelOpened = !!uploadFolder
  const closeUploadPanel = () => setUploadFolder(null)

  const requestUploadUrls = useRequestUploadUrls()
  const uploadFolderItems = useUploadFolderItems()

  const deselectUploadItem = (uploadItem) => {
    const isFile = uploadItem instanceof UploadFile

    if (isFile) {
      uploadFolder.removeFile(uploadItem)
    } else {
      uploadFolder.removeFolder(uploadItem)
    }

    if (uploadFolder.files.length === 0 && uploadFolder.folders.length === 0) {
      closeUploadPanel()
    } else {
      updateUploadFolderState()
    }
  }

  const setFiles = async (files) => {
    const someFileInsideFolder = files.some(isFileInsideFolder)

    if (someFileInsideFolder && !permissions?.canCreateFolder) {
      showFlashMessage("danger", "You are not allowed to upload folders")
      return
    }

    setUploadFolder(await organizeFilesIntoFolders(files))
  }

  const uploadFileToStorage = (uploadUrlResult) => {
    const { name, path, uploadUrl, storagePath } = uploadUrlResult
    const file = uploadFolder.findFile({ name, path })

    return axios
      .put(uploadUrl, file.rawFile)
      .then(() => {
        file.storagePath = storagePath
      })
      .catch((error) => {
        captureException(error)
        file.markAsFailed("Something went wrong")
        updateUploadFolderState()
      })
  }

  const uploadFilesToStorage = (uploadUrls) => {
    return Promise.all(uploadUrls.map(uploadFileToStorage))
  }

  const submitFiles = () => {
    uploadFolder.markAsCreating()
    updateUploadFolderState()

    const validationStatus = validate(uploadFolder)

    if (!validationStatus.valid) {
      showFlashMessage("danger", validationStatus.errorMessage)
      uploadFolder.markAsPending()
      updateUploadFolderState()
    }

    requestUploadUrls({ groupId, uploadFolder })
      .then(uploadFilesToStorage)
      .then(() => uploadFolderItems({ groupId, folderId, uploadFolder }))
      .then(({ successful, message }) => {
        if (successful) return

        uploadFolder.markAsPending()
        updateUploadFolderState()

        showFlashMessage("danger", message || DEFAULT_ERROR_MESSAGE)
      })
      .catch((error) => {
        captureException(error)
        closeUploadPanel()
        showFlashMessage("danger", DEFAULT_ERROR_MESSAGE)
      })
  }

  const finalizeUploads = () => {
    if (uploadFolder.isUploading()) return

    onFinished()

    if (uploadFolder.isSomeUploadFailed()) {
      showFlashMessage(
        "warning",
        "Some of the files have not been uploaded. Please, review the errors below."
      )
    } else {
      closeUploadPanel()
      showFlashMessage("success", "All files have been uploaded successfully")
    }
  }

  const completeFileUpload = ({ name, path, errorMessage }) => {
    if (errorMessage) {
      uploadFolder.markFileAsFailed({ name, path, errorMessage })
    } else {
      uploadFolder.markFileAsUploaded({ name, path })
    }

    updateUploadFolderState()
    finalizeUploads()
  }

  const completeFolderUpload = ({ name, path, errorMessage }) => {
    if (errorMessage) {
      uploadFolder.markFolderAsFailed({ name, path, errorMessage })
    } else {
      uploadFolder.markFolderAsCreated({ name, path })
    }

    updateUploadFolderState()
    finalizeUploads()
  }

  useAblyChannel(CHANNELS.groups.folders, {
    onMessage: ({ data }) => {
      const { userId, folderItemType, name, path, rootBoxFolderId, errorMessage } = data
      if (userId !== currentUserId || rootBoxFolderId !== folderId) return

      if (folderItemType === FolderItemTypes.Folder) {
        completeFolderUpload({ name, path, errorMessage })
      } else {
        completeFileUpload({ name, path, errorMessage })
      }
    }
  })

  return {
    uploadFolder,
    uploadPanelOpened,
    closeUploadPanel,
    deselectUploadItem,
    setFiles,
    submitFiles
  }
}

export default useUploadPanel
