export const getRandomInt = (max) => Math.floor(Math.random() * Math.floor(max))

const SIZE_LABELS = ["Bytes", "KB", "MB", "GB"]

export const formatSize = (size) => {
  const parsedSize = parseInt(size)

  if (parsedSize === 0) return `0 ${SIZE_LABELS[0]}`

  const index = Math.floor(Math.log(parsedSize) / Math.log(1024))

  if (index === 0) {
    return `${parsedSize} ${SIZE_LABELS[index]}`
  }

  return `${(parsedSize / 1024 ** index).toFixed(1)}  ${SIZE_LABELS[index]}`
}
