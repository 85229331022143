import React from "react"
import { sortBy } from "lodash"
import { arrayOf, bool, func, number, shape, string } from "prop-types"

import Modal, { Header, Title } from "src/styles/components/Modal"

import UserRow from "./UserRow"
import { ModalBody } from "./styles"

const SignatoriesModal = ({ isOpened, users, close }) => {
  const signedFirstUsers = sortBy(users, "signedAt")

  return (
    <Modal show={isOpened} onHide={close}>
      <Header>
        <Title>Signed users</Title>
      </Header>
      <ModalBody>
        {signedFirstUsers.map((user) => (
          <UserRow key={user.id} user={user} />
        ))}
      </ModalBody>
    </Modal>
  )
}

SignatoriesModal.propTypes = {
  isOpened: bool.isRequired,
  close: func.isRequired,
  users: arrayOf(shape({ id: number, signedAt: string })).isRequired
}

export default SignatoriesModal
