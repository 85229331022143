import React, { Fragment } from "react"
import { arrayOf, elementType, func } from "prop-types"
import { styled } from "@linaria/react"

import { optionType } from "src/constants/propTypes"

import SelectedValue from "./SelectedValue"
import IconLabel from "./IconLabel"

const OrContainer = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 10px;
`

const SelectedValues = ({ selectedValues, deselect, labelComponent = IconLabel }) => {
  const Label = labelComponent

  return (
    <>
      {selectedValues.map((selectedValue, index) => (
        <Fragment key={selectedValue.value}>
          {selectedValue.value && (
            <SelectedValue
              key={selectedValue.value}
              deselect={() => deselect(selectedValue)}
            >
              <Label {...selectedValue} />
            </SelectedValue>
          )}
          {selectedValues[index + 1] && <OrContainer>OR</OrContainer>}
        </Fragment>
      ))}
    </>
  )
}

SelectedValues.propTypes = {
  selectedValues: arrayOf(optionType),
  deselect: func,
  labelComponent: elementType
}

export default SelectedValues
