import { styled } from "@linaria/react"
import { Nav, Modal } from "react-bootstrap"

import { BACKGROUND_COLORS } from "src/styles/colors"

export const FilePickerContainer = styled.div`
  height: calc(100% - 44px);
  min-height: 250px;
`

export const ModalHeader = styled(Modal.Header)`
  flex-grow: 0;
`

export const NavTabs = styled(Nav)`
  background-color: ${BACKGROUND_COLORS.main};
`
