import React from "react"
import { Modal } from "react-bootstrap"
import { styled } from "@linaria/react"

const ModalHeader = (props) => {
  return <Modal.Header closeButton {...props} />
}

export default styled(ModalHeader)`
  display: flex;
  align-items: center;
  flex-grow: 0;
`
