import React, { useContext, useMemo, useState } from "react"
import { Modal } from "react-bootstrap"
import { string, bool } from "prop-types"
import { styled } from "@linaria/react"
import moment from "moment"

import NewMessageActionContext from "../NewMessageActionContext"

import Context from "./Context"
import Header from "./Header"
import Body from "./Body"
import Footer from "./Footer"

const NewMessageActionModal = ({ show, className }) => {
  const { message, optionConfirmed, optionAttributes, closeActionsModal } = useContext(
    NewMessageActionContext
  )

  const [selectedAction, setSelectedAction] = useState(
    optionConfirmed ? optionAttributes.action : "date"
  )
  const [selectedDate, setSelectedDate] = useState(
    optionAttributes.date || moment().endOf("day").toDate()
  )
  const [selectedNumber, setSelectedNumber] = useState(
    optionAttributes.number || message.actionsCount
  )

  const context = useMemo(() => {
    return {
      selectedAction,
      setSelectedAction,
      selectedDate,
      setSelectedDate,
      selectedNumber,
      setSelectedNumber
    }
  }, [selectedAction, selectedDate, selectedNumber])

  if (!message.actionType) return null

  return (
    <Context.Provider value={context}>
      <Modal
        className={className}
        backdropClassName="new-message-action-modal-backdrop"
        show={show}
        keyboard
        onHide={closeActionsModal}
      >
        <Header />
        <Body />
        <Footer />
      </Modal>
    </Context.Provider>
  )
}

NewMessageActionModal.propTypes = {
  show: bool.isRequired,
  className: string
}

export default styled(NewMessageActionModal)`
  .modal-dialog {
    max-width: 550px;
  }
`
