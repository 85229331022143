import React from "react"
import { styled } from "@linaria/react"

import aiSearchImage from "images/ai/search.png"
import aiSearchMobileImage from "images/ai/search-mobile.png"
import { BREAKPOINTS } from "src/styles/sizes"
import useMediaQuery from "src/hooks/useMediaQuery"

const IconAI = ({ className }) => {
  const isSmallScreen = useMediaQuery(`(max-width: calc(${BREAKPOINTS.small} - 1px))`)

  return (
    <img
      src={isSmallScreen ? aiSearchMobileImage : aiSearchImage}
      alt="AI search"
      className={className}
    />
  )
}

export default styled(IconAI)`
  height: 32px;
`
