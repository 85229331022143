import React from "react"
import { func, instanceOf } from "prop-types"
import { Spinner } from "react-bootstrap"

import CloseButton from "src/components/CloseButton"
import UploadFolder from "src/features/groups/DocumentsExplorer/UploadFolder"
import TextWithTooltip from "src/styles/components/TextWithTooltip"

import { ErrorContainer, IconError } from "../styles"

import InfoBlock from "./InfoBlock"

const FolderControls = ({ folder, onRemove }) => {
  return (
    <>
      {folder.isPending() && <CloseButton onClick={() => onRemove(folder)} />}
      {folder.isCreating() && <Spinner animation="border" size="sm" variant="primary" />}
      {folder.isCreated() && <InfoBlock folder={folder} />}
      {folder.isFailed() && (
        <ErrorContainer>
          {folder.errorMessage && (
            <TextWithTooltip text={folder.errorMessage} maxLength={50} />
          )}
          <IconError type="info" />
        </ErrorContainer>
      )}
    </>
  )
}

FolderControls.propTypes = {
  folder: instanceOf(UploadFolder).isRequired,
  onRemove: func.isRequired
}

export default FolderControls
