import React from "react"
import classNames from "classnames"
import { styled } from "@linaria/react"
import { string, bool } from "prop-types"
import { Badge } from "react-bootstrap"

import { FONT_WEIGHTS } from "src/styles/sizes"
import { BASE_COLORS, BACKGROUND_COLORS } from "src/styles/colors"

const UnreadBadge = ({ className, children, pill = true, ...props }) => {
  const classes = classNames("badge-knowa", className)

  return (
    <Badge className={classes} {...props} pill={pill}>
      {children || " "}
    </Badge>
  )
}

UnreadBadge.propTypes = {
  className: string,
  pill: bool
}

export default styled(UnreadBadge)`
  &.badge-knowa {
    padding: 5px 8px;
    border-radius: 4px;
    color: ${BASE_COLORS.white};
    font-weight: ${FONT_WEIGHTS.bold};
    background-color: ${BACKGROUND_COLORS.warning};
    cursor: pointer;

    &.badge-pill {
      padding: 5px;
      border-radius: 50%;
    }
  }
`
