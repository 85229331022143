import React from "react"
import { Dropdown } from "react-bootstrap"
import { shape, number } from "prop-types"
import { useMutation } from "@apollo/client"

import Icon from "src/styles/components/Icon"

import RestoreGroupMutation from "./RestoreGroup.gql"

const Restore = ({ group }) => {
  const [mutateRestoreGroup] = useMutation(RestoreGroupMutation)

  const restoreGroup = () => {
    mutateRestoreGroup({
      variables: {
        id: group.id
      }
    }).then(() => {
      window.location.reload()
    })
  }

  return (
    <Dropdown.Item
      onClick={restoreGroup}
      data-confirm="Are you sure you want to restore this group?"
    >
      <div className="dropdown-icon">
        <Icon className="icon-small" type="archive-unpack" />
      </div>
      Restore Group
    </Dropdown.Item>
  )
}

Restore.propTypes = {
  group: shape({
    id: number.isRequired
  }).isRequired
}

export default Restore
