import React from "react"
import classNames from "classnames"
import { any, arrayOf, bool, func, string } from "prop-types"

import Checkbox from "src/styles/components/Checkbox"

import Suggestions from "./Suggestions"
import {
  Container,
  SearchButton,
  SearchIcon,
  SearchAll,
  SearchText,
  SubText
} from "./styles"

const JumpToPanel = ({
  isSearchActive,
  searchText,
  showPanel,
  suggestions,
  onSubmit,
  whereFilterChecked,
  onWhereFilterChange,
  whereFilter
}) => {
  if (showPanel === false) return null

  return (
    <Container data-testid="jump-to-panel">
      {searchText ? (
        <>
          <SearchButton
            onClick={onSubmit}
            className={classNames({ active: isSearchActive })}
            data-testid="jump-to-search-all"
          >
            <SearchIcon type="search" />
            <SearchAll>
              <SearchText className="text-truncate">{searchText}</SearchText>
              <SubText className="text-truncate">
                - search messages, meetings, documents...
              </SubText>
            </SearchAll>
          </SearchButton>
          {whereFilter && (
            <Checkbox
              id="where_filter_checkbox"
              variant="small"
              checked={whereFilterChecked}
              onChange={onWhereFilterChange}
            >
              <span className="phx">Only search in</span>
              <b>{whereFilter}</b>
            </Checkbox>
          )}
          <Suggestions suggestions={suggestions} />
        </>
      ) : (
        <Suggestions suggestions={suggestions} caption="Recent Searches" />
      )}
    </Container>
  )
}

JumpToPanel.propTypes = {
  suggestions: arrayOf(arrayOf(any)).isRequired,
  showPanel: bool,
  isSearchActive: bool,
  searchText: string,
  whereFilterChecked: bool,
  onWhereFilterChange: func,
  whereFilter: string,
  onSubmit: func
}

export default JumpToPanel
