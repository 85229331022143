import { styled } from "@linaria/react"

export const Button = styled.button`
  outline: none;
  padding: 0;
  background-color: transparent;
  border: 0;
  float: right;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  height: 24px;
  margin-top: auto;
  margin-bottom: auto;

  &:hover,
  &:focus {
    opacity: 0.75;
    color: unset;
  }
`

export const IconContainer = styled.span`
  padding-bottom: 3px;
`
