import { createSlice } from "@reduxjs/toolkit"

import { setIsLoading, setFailure, setCollection } from "src/helpers/slice"

const initialState = {
  allIds: [],
  byId: {},
  isLoading: false,
  error: null
}

const slice = createSlice({
  name: "schemes",
  initialState,
  reducers: {
    fetchSchemesRequest: setIsLoading,
    fetchSchemesFailure: setFailure,
    fetchSchemesSuccess: setCollection
  }
})

const { actions, reducer } = slice

export const { fetchSchemesFailure, fetchSchemesRequest, fetchSchemesSuccess } = actions

export default reducer
