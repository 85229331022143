import React from "react"
import { styled } from "@linaria/react"
import { Dropdown } from "react-bootstrap"
import { func, number, shape, string } from "prop-types"
import { useMutation } from "@apollo/client"

import TridotIcon from "src/components/TridotIcon"
import useConfirmationModal from "src/hooks/useConfirmationModal"
import useIsOpened from "src/hooks/useIsOpened"

import deleteTeamMutation from "./deleteTeamMutation.gql"
import EditTeamModal from "./EditTeamModal"

const ThreeDotActions = ({ className, team, accountId, openTeamMembersModal }) => {
  const [deleteTeam, { loading: deleteTeamLoading }] = useMutation(deleteTeamMutation, {
    variables: { id: team.id },
    refetchQueries: ["teams"]
  })

  const { modal: deleteTeamModal, open: openDeleteTeamModalModal } = useConfirmationModal(
    {
      title: "Delete team",
      message: (
        <>
          <div className="mb-3">
            Are you sure you want to delete the {team.name} Team?
          </div>
          <div className="mb-3">Note that deleting a team does not delete the users.</div>
        </>
      ),
      isConfirming: deleteTeamLoading,
      onConfirm: deleteTeam
    }
  )

  const {
    isOpened: isOpenedEditTeamModal,
    open: openEditTeamModal,
    close: closeEditTeamModal
  } = useIsOpened()

  return (
    <>
      <EditTeamModal
        accountId={accountId}
        id={team.id}
        name={team.name}
        isOpened={isOpenedEditTeamModal}
        close={closeEditTeamModal}
      />
      {deleteTeamModal}
      <Dropdown className={className}>
        <Dropdown.Toggle as={TridotIcon} data-testid="actions-toggle" />
        <Dropdown.Menu alignRight>
          <Dropdown.Item className="text-nowrap" onClick={openEditTeamModal}>
            Edit team name
          </Dropdown.Item>
          <Dropdown.Item className="text-nowrap" onClick={openTeamMembersModal}>
            Manage team members
          </Dropdown.Item>
          <Dropdown.Divider />

          <Dropdown.Item
            disabled={deleteTeamLoading}
            className="delete text-nowrap"
            variant="danger"
            onClick={openDeleteTeamModalModal}
          >
            Delete Team
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}

ThreeDotActions.propTypes = {
  accountId: number.isRequired,
  openTeamMembersModal: func.isRequired,
  team: shape({
    id: number.isRequired,
    name: string.isRequired
  }).isRequired
}

export default styled(ThreeDotActions)`
  .dropdown {
    position: static;
  }

  .dropdown-toggle {
    cursor: pointer;
  }

  .dropdown-menu {
    a {
      white-space: nowrap;
    }
  }
`
