import { getCurrentUser } from "src/helpers/user"
import { postPrefetchAssetsMessage } from "src/features/offline/helpers/messages"

const clearCachedAssets = (forceClear) => {
  if (forceClear)
    return navigator.serviceWorker
      .getRegistrations()
      .then((registrations) =>
        Promise.all(registrations.map((registration) => registration.unregister()))
      )
      .then(() =>
        caches.keys().then((keys) => Promise.all(keys.map((key) => caches.delete(key))))
      )

  return Promise.resolve()
}

export const registerServiceWorker = (forceClear) => {
  if (!window.isTestEnv && "serviceWorker" in navigator) {
    if (getCurrentUser()) {
      clearCachedAssets(forceClear).then(() => {
        navigator.serviceWorker
          .register("/service_worker.js")
          .then(() => {
            setTimeout(() => {
              postPrefetchAssetsMessage({
                assets: window.precachedAssets
              })
            }, 2000)
          })
          // eslint-disable-next-line no-console
          .catch(console.warn)
      })
    } else {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => registration.unregister())
      })
    }
  }
}
