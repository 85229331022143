import React from "react"
import { bool, func, string } from "prop-types"
import { styled } from "@linaria/react"

import { successColor } from "src/styles/colors"
import BaseCheckbox from "src/styles/components/Checkbox"

const StyledCheckbox = styled(BaseCheckbox)`
  label {
    &:after {
      display: none;
    }

    & .icon {
      display: block;
      width: 16px;
      height: 4px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: ${successColor};
      border-radius: 8px;
    }
  }
`

const IndeterminateCheckbox = ({ id, checked, onChange, disabled }) => (
  <StyledCheckbox id={id} checked={checked} onChange={onChange} disabled={disabled}>
    {checked && <span className="icon" />}
  </StyledCheckbox>
)

IndeterminateCheckbox.propTypes = {
  id: string,
  checked: bool.isRequired,
  onChange: func.isRequired,
  disabled: bool
}

export default IndeterminateCheckbox
