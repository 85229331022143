import { styled } from "@linaria/react"

export const CardAttendees = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  margin-right: -15px;
  margin-left: -15px;
  text-align: center;
`

export const AddNew = styled.div`
  display: flex;
  cursor: pointer;
  width: 150px;
  align-items: center;
  justify-content: space-between;
`
