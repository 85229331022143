import { styled } from "@linaria/react"

export const StatusWithIcon = styled.span`
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;

  [class^="icon-"] {
    font-size: 1.75rem;
  }
`
