import React from "react"
import { styled } from "@linaria/react"
import { string } from "prop-types"

import { composeVariationsClasses } from "src/helpers/linaria"

import Icon from "./Icon"

const IconPencilPlus = ({ className, variant, ...props }) => {
  const classNameWithVariations = composeVariationsClasses(className, { variant })

  return <Icon type="pencil-plus" className={classNameWithVariations} {...props} />
}

IconPencilPlus.propTypes = {
  variant: string
}

export default styled(IconPencilPlus)`
  color: white !important;
  background: #000000;
  border: 1px solid #000000;
  border-radius: 4px;
  padding: 4px;
  font-size: 14px !important;

  &--variant-without-background {
    color: #4f4f57 !important;
    background: none;
    border: none;
    cursor: pointer;
    padding: 6px;
    font-size: 16px !important;
  }
`
