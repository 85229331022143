import { useEffect, useRef, useState } from "react"

const getIsMinimizable = (wrapper, body) => wrapper.clientHeight < body.clientHeight

const useIsMinimizable = (defaultValue = true) => {
  const [isMinimizable, setIsMinimizable] = useState(defaultValue)

  const wrapperRef = useRef()
  const bodyRef = useRef()

  useEffect(() => {
    const wrapper = wrapperRef.current
    const body = bodyRef.current
    if (!wrapper || !body) return

    setIsMinimizable(getIsMinimizable(wrapper, body))
  }, [wrapperRef, bodyRef])

  return { wrapperRef, bodyRef, isMinimizable }
}

export default useIsMinimizable
