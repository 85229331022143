import { useQuery } from "@apollo/client"

import { ROLES } from "../../MeetingPack/constants"

import stepOneMeetingPacksQuery from "./stepOneMeetingPacksQuery.gql"

const useMeetingPacks = ({ accountId, skip = false } = {}) => {
  const { data, loading } = useQuery(stepOneMeetingPacksQuery, {
    variables: { accountId, role: ROLES.admin },
    skip: skip || !accountId
  })

  const meetingPacks = (data?.meetingPacks || []).slice().sort((a, b) => {
    return new Date(b.startDate) - new Date(a.startDate)
  })

  return { meetingPacks, loading }
}

export default useMeetingPacks
