import { isEqual } from "lodash"

import annotationsApi from "src/api/meetingPacks/annotations"
import { incrementDocumentAnnotations } from "src/features/meetingPacks/MeetingPack/meetingPackSlice"
import OfflineAnnotationsStore from "src/features/offline/helpers/OfflineAnnotationsStore"
import { CRUD } from "src/features/offline/constants"
import { getCurrentUserId } from "src/helpers/user"

import SyncCachedAnnotations from "./syncCachedAnnotations"

const isSettingAnnotation = (annotation) => isEqual(annotation.bbox, [0, 0, 0, 0])

export default function createSyncAnnotations({
  documentId,
  dispatch,
  offlineStore = new OfflineAnnotationsStore()
}) {
  const userId = getCurrentUserId()
  const syncCachedAnnotations = new SyncCachedAnnotations({ documentId, userId })

  return (instance) => {
    instance.addEventListener("annotations.create", (annotations) => {
      annotations.toArray().forEach((rawAnnotation) => {
        const annotation = window.PSPDFKit.Annotations.toSerializableObject(rawAnnotation)

        if (isSettingAnnotation(annotation)) {
          return
        }

        annotationsApi
          .create({ documentId, annotation })
          .catch((e) => {
            if (e.response && e.response.status === 400) return

            // eslint-disable-next-line no-console
            console.warn(e)
            offlineStore.pushOfflineAnnotation({
              documentId,
              userId,
              action: CRUD.CREATE,
              annotation
            })
            syncCachedAnnotations.create({ annotation })
          })
          .then(() => {
            dispatch(incrementDocumentAnnotations({ documentId }))
            syncCachedAnnotations.create({ annotation })
          })
      })
    })

    instance.addEventListener("annotations.delete", (annotations) => {
      annotations.toArray().forEach((annotation) => {
        annotationsApi
          .destroy({ documentId, annotationId: annotation.id })
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.warn(e)
            offlineStore.pushOfflineAnnotation({
              documentId,
              userId,
              action: CRUD.DELETE,
              annotation: { id: annotation.id }
            })
          })
          .then(() => dispatch(incrementDocumentAnnotations({ documentId, incBy: -1 })))
          .finally(() => syncCachedAnnotations.delete({ annotation }))
      })
    })

    instance.addEventListener("annotations.update", (annotations) => {
      annotations.toArray().forEach((rawAnnotation) => {
        const annotation = window.PSPDFKit.Annotations.toSerializableObject(rawAnnotation)
        if (!annotation.id) return

        if (isSettingAnnotation(annotation)) {
          return
        }

        annotationsApi
          .update({
            documentId,
            annotation,
            annotationId: annotation.id
          })
          .catch((e) => {
            if (e.response && e.response.status === 404) {
              syncCachedAnnotations.create({ annotation })
              return annotationsApi.create({ documentId, annotation })
            }

            // eslint-disable-next-line no-console
            console.warn(e)
            offlineStore.pushOfflineAnnotation({
              documentId,
              userId,
              action: CRUD.UPDATE,
              annotation
            })
            syncCachedAnnotations.update({ annotation })
          })
          .then(() => syncCachedAnnotations.update({ annotation }))
      })
    })

    return instance
  }
}
