import React, { useContext } from "react"
import { arrayOf, number, shape } from "prop-types"

import SidebarContext from "src/features/Sidebar/SidebarContext"
import { getCurrentThread } from "src/helpers/document"

import { addBadgeToTheThread, isCurrentThread } from "./helpers"
import Thread from "./Thread"
import HiddenThreads from "./HiddenThreads"

const shownRecentThreadsCount = 5

const RecentThreads = ({ recentThreads }) => {
  const { stats } = useContext(SidebarContext)
  const currentThread = getCurrentThread()
  const threads = recentThreads.map((thread) => addBadgeToTheThread(thread, stats))
  const shownThreads = threads.slice(0, shownRecentThreadsCount)
  const hiddenThreads = threads.slice(shownRecentThreadsCount)

  return (
    <div className="sidebar-section">
      <h5 className="sidebar-section-title">Recent</h5>

      <ul className="sidebar-menu">
        {shownThreads.map((thread) => (
          <Thread
            thread={thread}
            key={thread.path}
            current={isCurrentThread(thread, currentThread)}
          />
        ))}
      </ul>

      <HiddenThreads threads={hiddenThreads} />
    </div>
  )
}

RecentThreads.propTypes = {
  recentThreads: arrayOf(
    shape({
      id: number.isRequired
    })
  )
}

export default RecentThreads
