import apiClient from "src/app/apiClient"

export const upload = ({ file }) => {
  const data = new FormData()
  data.append("file", file, file.name)

  return apiClient.postForm("/pspdfkit_documents", data)
}

export const destroy = ({ documentId }) => {
  return apiClient.delete(`/pspdfkit_documents/${documentId}`)
}
