import { useCallback, useState } from "react"
import { debounce } from "lodash"

const minFilterLength = 3

export default function useFiltering({ items, filteredFields }) {
  const [filter, setFilter] = useState("")

  const filteredItems =
    filter.length >= minFilterLength && filteredFields.length
      ? items.filter((item) =>
          filteredFields.some((field) =>
            (item[field] || "").toLowerCase().includes(filter.toLowerCase())
          )
        )
      : items

  const setFilterFromEvent = debounce((value) => {
    setFilter(value)
  }, 300)

  const debouncedSetFilterHandler = useCallback(
    (e) => setFilterFromEvent(e.target.value),
    []
  )

  return {
    filteredItems,
    setFilter: debouncedSetFilterHandler
  }
}
