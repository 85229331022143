class @DocumentsIndexHandler
  constructor: ->
    @groupId        = window.groupId

    @groupMembershipWatcherComponent = new GroupMembershipWatcherComponent()

    @groupParticipants = $be('group-participants')
    @groupMemberListComponent = new GroupMemberListComponent(
      listContainerBehavior: 'group-participants',
      manageEnabled: @groupParticipants.data('manage'),
      contactDetailsEnabled: true
    )

    @groupLeaveLinkComponent = new GroupLeaveLinkComponent(@groupId)

  setup: =>
    @groupMembershipWatcherComponent.setup()
    @groupMemberListComponent.setup()
    @groupLeaveLinkComponent.setup()


  teardown: =>
    @groupMembershipWatcherComponent.teardown()
    @groupMemberListComponent.teardown()
    @groupLeaveLinkComponent.teardown()
