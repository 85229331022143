import { styled } from "@linaria/react"

import { SPACES } from "src/styles/sizes"
import { ICON_COLORS } from "src/styles/colors"

export const WhereOption = styled.div`
  display: flex;
  align-items: center;

  [class^="icon-"] {
    color: ${ICON_COLORS.search};
    margin-right: ${SPACES.xSmall};
  }
`
