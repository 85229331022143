import React from "react"
import { arrayOf, func, shape } from "prop-types"

import { optionType } from "src/constants/propTypes"
import { NoResults } from "src/features/Search/SearchPage/Search/messages"

import OrderSelect from "./OrderSelect"
import ResultCard from "./ResultCard"
import { ResultsContainer } from "./styles"

const Results = ({ order, setOrder, results = [] }) => {
  if (results && !results.length) {
    return <NoResults />
  }

  return (
    <ResultsContainer>
      <OrderSelect order={order} setOrder={setOrder} />
      {results.map((result) => (
        <ResultCard
          key={`${result.title}${result.id}${result.path}`}
          id={result.id}
          path={result.path}
          text={result.text}
          type={result.type}
          title={result.title}
          answer={result.answer}
          author={result.author}
          breadcrumbs={result.breadcrumbs}
          datetime={result.datetime}
        />
      ))}
    </ResultsContainer>
  )
}

Results.propTypes = {
  results: arrayOf(shape({})),
  order: optionType,
  setOrder: func.isRequired
}

export default Results
