import React, { memo, useCallback } from "react"
import { useMutation } from "@apollo/client"
import { arrayOf, oneOf, string } from "prop-types"
import { isEqual } from "lodash"

import { SUGGESTIONS, ICON_TYPES } from "src/features/Search/constants"

import saveJumpToMutation from "./saveJumpTo.gql"
import { SuggestionIcon, SuggestionLink, SuggestionTitle, SuggestionHint } from "./styles"

const hintTypeNames = {
  [SUGGESTIONS.meeting]: "Meeting Pack",
  [SUGGESTIONS.jointScheme]: "Connected Space",
  [SUGGESTIONS.privateScheme]: "Space",
  [SUGGESTIONS.group]: "Group",
  [SUGGESTIONS.discussion]: "Discussion",
  [SUGGESTIONS.folder]: "Folder",
  [SUGGESTIONS.app]: "App"
}

const Suggestion = ({ type, title, path, parent, className }) => {
  const [saveJumpTo] = useMutation(saveJumpToMutation)

  const handleClick = useCallback(() => {
    if (type !== SUGGESTIONS.search) {
      saveJumpTo({ variables: { type, title, path, parent } })
    }
  }, [type, title, path, parent])

  return (
    <SuggestionLink
      href={path}
      className={className}
      data-testid="suggestion-link"
      onClick={handleClick}
    >
      <SuggestionIcon type={ICON_TYPES[type]} />

      <span>
        <SuggestionTitle>{title}</SuggestionTitle>

        {hintTypeNames[type] && (
          <SuggestionHint>
            - View {hintTypeNames[type]}
            {parent.map((parentName) => (
              <span key={parentName}>
                {" "}
                in <strong>{parentName}</strong>
              </span>
            ))}
          </SuggestionHint>
        )}
      </span>
    </SuggestionLink>
  )
}

Suggestion.propTypes = {
  path: string.isRequired,
  title: string.isRequired,
  type: oneOf(Object.values(SUGGESTIONS)).isRequired,
  parent: arrayOf(string)
}

export default memo(Suggestion, isEqual)
