import { createSelector } from "@reduxjs/toolkit"
import { sortBy } from "lodash"

import { getGroupOption } from "./helpers"

export const getGroupsAllIds = (state) => state.groups.allIds
export const getGroupsById = (state) => state.groups.byId

export const selectGroupOptions = createSelector(
  [getGroupsAllIds, getGroupsById],
  (allIds, byId) =>
    Object.values(
      sortBy(
        allIds.map((id) => getGroupOption(byId[id])),
        "label"
      )
    )
)
