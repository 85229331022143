import { useEffect } from "react"
import { useDispatch } from "react-redux"

import useIsOpened from "src/hooks/useIsOpened"
import { fetchAccounts } from "src/resources/accounts/thunks"
import { fetchSchemeGroups } from "src/resources/groups/thunks"
import { fetchSchemes } from "src/resources/schemes/thunks"
import { SCHEME_MEMBERSHIP_ROLES } from "src/resources/schemes/constants"

const useMoveCopyModal = (group) => {
  const { open, close, isOpened } = useIsOpened()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchAccounts())
    dispatch(
      fetchSchemeGroups({
        schemeId: group.schemeId,
        filter: { membership: true }
      })
    )
    dispatch(fetchSchemes({ filter: { role: SCHEME_MEMBERSHIP_ROLES.admin } }))
  }, [dispatch])

  return { open, close, isOpened }
}

export default useMoveCopyModal
