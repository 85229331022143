import { showCaughtErrorMessage } from "src/helpers/errors"
import { showFlashMessage } from "src/helpers/flash"

import messages from "./api"
import { ITEM_TYPE } from "./constants"
import {
  fetchMessageFailure,
  fetchMessageSuccess,
  updateMessageStarredSuccess
} from "./slice"

export const fetchMessage = ({ messageId }) => (dispatch) => {
  messages
    .show({ messageId })
    .then((response) => {
      dispatch(fetchMessageSuccess(response.data))
    })
    .catch((error) => {
      showCaughtErrorMessage(error)
      dispatch(fetchMessageFailure(error))
    })
}

export const fetchMessageContent = ({ messageId }) => () => {
  return messages
    .show({ messageId })
    .then((response) => response.data)
    .catch((error) => {
      showCaughtErrorMessage(error)
    })
}

export const toggleMessageStarred = (data) => (dispatch) => {
  const toggleMessageStarredApiCall = ({ id: itemId, starred, itemType = ITEM_TYPE }) => {
    const apiCallName = starred ? "unstar" : "star"

    return messages[apiCallName]({ itemId, itemType })
  }

  return toggleMessageStarredApiCall(data)
    .then(() => dispatch(updateMessageStarredSuccess(data)))
    .catch((error) => showCaughtErrorMessage(error))
}

export const shareMessage = (data) => () => {
  showFlashMessage("warning", "We are sharing the message. Please wait.")

  return messages
    .share(data)
    .then((response) => {
      showFlashMessage("info", "Message has been shared.")
      return response.data
    })
    .catch((error) => showCaughtErrorMessage(error))
}
