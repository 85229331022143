import { styled } from "@linaria/react"

export const Extension = styled.div`
  text-transform: uppercase;
  color: white;
  font-size: 7px;
  padding: 1px 3px;
  border: white 1px solid;
  border-radius: 3px;
  position: absolute;
  top: 1.6rem;
  right: 0;
  max-width: 1.8rem;
  overflow: hidden;
  white-space: nowrap;
`

export const Container = styled.div`
  min-height: 38px;
  min-width: 38px;
  position: relative;
  margin-right: 0.3rem;
  margin-bottom: 0.3rem;
  background: transparent no-repeat center left;

  &.pdf {
    background-image: url("~images/file_icons/pdf.svg");

    ${Extension} {
      background-color: #e33d55;
    }
  }

  &.doc {
    background-image: url("~images/file_icons/doc.svg");

    ${Extension} {
      background-color: #2a5696;
    }
  }

  &.text {
    background-image: url("~images/file_icons/text.svg");

    ${Extension} {
      background-color: #2a61b9;
    }
  }

  &.spreadsheet {
    background-image: url("~images/file_icons/spreadsheet.svg");

    ${Extension} {
      background-color: #207144;
    }
  }

  &.image {
    background-image: url("~images/file_icons/image.svg");

    ${Extension} {
      background-color: #3fb87f;
    }
  }

  &.video {
    background-image: url("~images/file_icons/video.svg");

    ${Extension} {
      background-color: #22a7f0;
    }
  }

  &.audio {
    background-image: url("~images/file_icons/audio.svg");

    ${Extension} {
      background-color: #955ca5;
    }
  }

  &.default {
    background-image: url("~images/file_icons/default.svg");

    ${Extension} {
      background-color: #737f8b;
    }
  }
`
