import React from "react"
import { bool, string } from "prop-types"

const NotFound = ({ loading, entitiesPresence, text }) => {
  return (
    !entitiesPresence &&
    !loading && <div className="mbs mts text-center text-muted">{text}</div>
  )
}

NotFound.propTypes = {
  entitiesPresence: bool.isRequired,
  loading: bool.isRequired,
  text: string.isRequired
}

export default NotFound
