import React, { useState } from "react"
import { string } from "prop-types"

import SignInCard from "src/features/TwoFactor/SignInCard"
import { TWO_FACTOR_TOOLS } from "src/features/TwoFactor/constants"

import Intro from "./Intro"
import ChooseTwoFactorTool from "./ChooseTwoFactorTool"
import EnableByApp from "./ByApp/Page"
import EnableByPhone from "./ByPhone/Page"

const EnablePage = ({ qrCode, otpSecret, invitedToUrl }) => {
  const [twoFactorTool, setTwoFactorTool] = useState(null)
  const [toolSelectorEnabled, setToolSelectorEnabled] = useState(true)

  const handleClose = () => {
    window.location = invitedToUrl
  }

  const handleStepChange = (step) => {
    if (step === 1) {
      setToolSelectorEnabled(true)
    } else {
      setToolSelectorEnabled(false)
    }
  }

  return (
    <SignInCard>
      <h4 className="sign-in-header mb-4">Enable 2-factor authentication</h4>

      <Intro />

      {toolSelectorEnabled && (
        <ChooseTwoFactorTool
          twoFactorTool={twoFactorTool}
          setTwoFactorTool={setTwoFactorTool}
        />
      )}

      {twoFactorTool === TWO_FACTOR_TOOLS.app && (
        <EnableByApp
          twoFactorTool={twoFactorTool}
          setTwoFactorTool={setTwoFactorTool}
          qrCode={qrCode}
          otpSecret={otpSecret}
          close={handleClose}
        />
      )}

      {twoFactorTool === TWO_FACTOR_TOOLS.phone && (
        <EnableByPhone
          twoFactorTool={twoFactorTool}
          setTwoFactorTool={setTwoFactorTool}
          close={handleClose}
          onStepChange={handleStepChange}
        />
      )}
    </SignInCard>
  )
}

EnablePage.propTypes = {
  qrCode: string.isRequired,
  otpSecret: string.isRequired,
  invitedToUrl: string.isRequired
}

export default EnablePage
