import React, { useEffect } from "react"
import { bool, func, number } from "prop-types"

import { IconDone, IconSyncing } from "./styles"

const SyncStatus = ({
  allDocumentsAreSaved,
  allDocumentsCount,
  savedDocumentsCount,
  onSyncFinished
}) => {
  useEffect(() => {
    if (allDocumentsAreSaved && typeof onSyncFinished === "function") {
      onSyncFinished()
    }
  }, [allDocumentsAreSaved, onSyncFinished])

  if (allDocumentsAreSaved) {
    return <IconDone />
  }

  if (allDocumentsCount && savedDocumentsCount !== null) {
    return <IconSyncing />
  }

  return null
}

SyncStatus.propTypes = {
  onSyncFinished: func,
  allDocumentsAreSaved: bool,
  savedDocumentsCount: number,
  allDocumentsCount: number
}

export default SyncStatus
