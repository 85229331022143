import React from "react"

const urlRegex = /(https?:\/\/[^\s]+)/g

const recogniseOnlineMeeting = (url) => {
  if (url.includes("zoom.us")) {
    return "Join with Zoom Meeting"
  }
  if (url.includes("teams.microsoft")) {
    return "Join with Microsoft Teams"
  }
  if (url.includes("meet.google")) {
    return "Join with Google Meet"
  }
}

export const recogniseLink = ({ text, truncatedText }) => {
  const url = text.match(urlRegex)
  if (!url) return truncatedText || text

  const recognised = recogniseOnlineMeeting(text)

  return (
    <a href={text} target="_blank">
      {recognised || truncatedText || text}
    </a>
  )
}
