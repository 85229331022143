import React, { useMemo } from "react"
import { bool, func, number, shape, string } from "prop-types"
import { useMutation, useQuery } from "@apollo/client"
import { Table as ReactTable } from "react-bootstrap"
import { styled } from "@linaria/react"

import { showFlashMessage } from "src/helpers/flash"
import { WideModal, Title, Header, Footer } from "src/styles/components/Modal"
import PrimaryButton from "src/styles/components/Button/Primary"
import SecondaryButton from "src/styles/components/Button/Secondary"
import AttendeesNotification from "src/components/AttendeesNotification"
import {
  MEMBERSHIP_TYPES_FOR,
  MEMBERSHIP_TYPES
} from "src/features/meetingPacks/MeetingPack/constants"

import RoleSubheader from "./RoleSubheader"
import CheckboxColumnTitle from "./CheckboxColumnTitle"
import useAgendaMemberships from "./useAgendaMemberships"
import agendaItemsQuery from "./agendaItems.gql"
import saveUserAgendaItemsMutation from "./saveUserAgendaItems.gql"
import AgendaItemRow from "./AgendaItemRow"

const Table = styled(ReactTable)`
  table-layout: fixed;
`

const AttendingColumn = styled.td`
  width: 100px;
`

const AccessOnlyColumn = styled.td`
  width: 120px;
`

const ManageAgendaItemsModal = ({
  show,
  onHide,
  user,
  meetingPack,
  isMeetingPackPublished,
  mutateAllAccessOnlyItemsToAttending
}) => {
  const { data } = useQuery(agendaItemsQuery, {
    variables: { meetingPackId: meetingPack?.id },
    skip: !show
  })

  const agendaItems = useMemo(() => data?.agendaItems || [], [data?.agendaItems])

  const itemLimitedVisibilityAllowed =
    !MEMBERSHIP_TYPES_FOR.limitedVisibilityItems.includes(user.membershipType)

  const {
    attendingItemsIds,
    accessOnlyItemsIds,
    checkAgendaItem,
    selectAllAttendingItems,
    selectAllAccessOnlyItems
  } = useAgendaMemberships({
    userId: user.id,
    agendaItems: data?.agendaItems,
    mutateAllAccessOnlyItemsToAttending
  })

  const [mutate, { loading: savingAgendaItems }] = useMutation(
    saveUserAgendaItemsMutation,
    {
      variables: {
        meetingPackId: meetingPack?.id,
        userId: user.id,
        attendingItemsIds,
        accessOnlyItemsIds
      },
      refetchQueries: ["agendaItems", "meetingPackMemberships"]
    }
  )

  const saveUserAgendaItems = () => {
    mutate().then(() => {
      onHide()
      showFlashMessage("success", "User's agenda items have been updated")
    })
  }

  const isPartiallySelected = (item) => {
    if (item.subItems.length === 0) return false

    const membership = item.agendaItemMemberships.find(({ userId }) => userId === user.id)

    if (!membership) return false

    const accessOnlySelected = membership.membershipType === MEMBERSHIP_TYPES.accessOnly
    const attendingSelected = membership.membershipType !== MEMBERSHIP_TYPES.accessOnly

    const subItemsMemberships = item.subItems.flatMap((subItem) =>
      subItem.agendaItemMemberships.filter(({ userId }) => userId === user.id)
    )

    const accessOnlyMemberships = subItemsMemberships.filter(
      ({ membershipType }) => membershipType === MEMBERSHIP_TYPES.accessOnly
    )

    const accessOnlyPartiallySelected =
      accessOnlySelected && accessOnlyMemberships.length < item.subItems.length

    const attendingMemberships = subItemsMemberships.filter(
      ({ membershipType }) => membershipType !== MEMBERSHIP_TYPES.accessOnly
    )

    const attendingPartiallySelected =
      attendingSelected && attendingMemberships.length < item.subItems.length

    return accessOnlyPartiallySelected || attendingPartiallySelected
  }

  return (
    <WideModal show={show} onHide={onHide}>
      <Header>
        <Title>Manage {user.fullName || user.email}&apos;s Agenda items</Title>
      </Header>
      <RoleSubheader
        firstName={user.firstName}
        role={user.role}
        meetingPackName={meetingPack?.name}
      />
      <div className="modal-content-container allow-scroll phs">
        <Table striped>
          <thead>
            <tr>
              <td className="border-top-0">
                <h6>Agenda items</h6>
              </td>
              {itemLimitedVisibilityAllowed && (
                <AttendingColumn className="border-top-0">
                  <CheckboxColumnTitle
                    title="Attending"
                    data-testid="select-all-attending-items-button"
                    onSelectAll={selectAllAttendingItems}
                  />
                </AttendingColumn>
              )}
              <AccessOnlyColumn className="border-top-0">
                <CheckboxColumnTitle
                  title="Access Only"
                  data-testid="select-all-access-only-items-button"
                  onSelectAll={selectAllAccessOnlyItems}
                />
              </AccessOnlyColumn>
            </tr>
          </thead>
          <tbody>
            {agendaItems.map((item) => (
              <AgendaItemRow
                key={item.id}
                item={item}
                displayAttending={itemLimitedVisibilityAllowed}
                attendingItemsIds={attendingItemsIds}
                accessOnlyItemsIds={accessOnlyItemsIds}
                onChange={checkAgendaItem}
                expanded={isPartiallySelected(item)}
              />
            ))}
          </tbody>
        </Table>
      </div>
      <Footer>
        <AttendeesNotification
          className="mb-3"
          published={isMeetingPackPublished}
          membershipType={user.membershipType}
        />
        <div className="d-flex justify-content-center">
          <PrimaryButton
            className="mr-2"
            onClick={saveUserAgendaItems}
            disabled={savingAgendaItems}
          >
            Confirm
          </PrimaryButton>
          <SecondaryButton onClick={onHide}>Cancel</SecondaryButton>
        </div>
      </Footer>
    </WideModal>
  )
}

ManageAgendaItemsModal.propTypes = {
  meetingPack: shape({
    id: number.isRequired,
    name: string.isRequired
  }).isRequired,
  onHide: func.isRequired,
  isMeetingPackPublished: bool.isRequired,
  user: shape({
    fullName: string
  }).isRequired,
  show: bool,
  mutateAllAccessOnlyItemsToAttending: bool
}

export default ManageAgendaItemsModal
