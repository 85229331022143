import React, { useState } from "react"
import { useMutation } from "@apollo/client"
import { styled } from "@linaria/react"
import classNames from "classnames"

import { getCurrentUser } from "src/helpers/user"
import useIsOpened from "src/hooks/useIsOpened"

import { removePasskeyDevice, passkeyDeviceId } from "./storage"
import revokePasskeyDeviceMutation from "./revokePasskeyDevice.gql"
import EnableModal from "./EnableModal"

const DeviceManagement = ({ className }) => {
  const { email } = getCurrentUser()
  const [deviceAdded, setDeviceAdded] = useState(passkeyDeviceId(email))
  const [revokePasskeyDevice] = useMutation(revokePasskeyDeviceMutation)
  const { isOpened, open, close } = useIsOpened(false)

  const revokeDevice = async () => {
    const deviceId = passkeyDeviceId(email)

    revokePasskeyDevice({ variables: { deviceId } })
      .then(() => removePasskeyDevice(email))
      .then(() => setDeviceAdded(false))
  }

  return (
    <>
      {isOpened && <EnableModal close={close} setDeviceAdded={setDeviceAdded} />}
      <div className={classNames("plate-section", className)}>
        <h6 className="mbs">Passwordless sign-in with passkeys</h6>
        {deviceAdded ? (
          <>
            <p className="text-muted">You are able to use passkey login on this device</p>
            <button type="button" className="button" onClick={revokeDevice}>
              Revoke device
            </button>
          </>
        ) : (
          <>
            <p className="text-muted">
              Your device supports passkeys, a password replacement that validates your
              identity using touch, facial recognition, a device password, or a PIN.
            </p>
            <p className="text-muted">
              Passkeys can be used for sign-in as a simple and secure alternative to your
              password and two-factor credentials.
            </p>
            <button className="button is-success-light" type="button" onClick={open}>
              Enable Passkey Login
            </button>
          </>
        )}
      </div>
    </>
  )
}

export default styled(DeviceManagement)`
  .button {
    font-size: 112.5%;
  }
`
