import React from "react"
import { Spinner } from "react-bootstrap"
import { styled } from "@linaria/react"

import { SPACES } from "src/styles/sizes"

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${SPACES.small};
`

const ContentLoader = () => {
  return (
    <SpinnerContainer>
      <Spinner animation="border" variant="secondary" />
    </SpinnerContainer>
  )
}

export default ContentLoader
