class @EmailToDiscussionComponent
  constructor: ->
    @modal = $be('show-email-address-modal')
    @modalContent = $be('show-email-address-modal-content')
    @addressField = null
    @copyAddressButton = null

  setup: ->

  showModal: (modalContent) =>
    @modalContent.html(modalContent)
    @modal.modal('show')

    @addressField = $be('discussion-email-address')
    @copyAddressButton = $be('copy-email-address')

    @copyAddressButton.on 'click', (e) =>
      $copyInputText(@addressField)
      @setButtonCopied()

  setEmailAddress: (emailAddress) =>
    @addressField.val(emailAddress)

  setButtonCopied: () ->
    originalButtonContent = @copyAddressButton.html()
    @copyAddressButton.html('copied')
    setTimeout(
      () => @copyAddressButton.html(originalButtonContent)
    , 1000)

