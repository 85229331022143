import { DEFAULT_YEAR_FILTER } from "./constants"

export const getYearOption = (year) => {
  if (!year) return

  return {
    value: year,
    label: year.toString()
  }
}

export const getYear = (option) => {
  if (option.value === DEFAULT_YEAR_FILTER.value) return

  return option.value
}
