import { createContext } from "react"
import moment from "moment"

import { ACTION_CLOSED_TYPES } from "../constants"

const Context = createContext({
  selectedAction: ACTION_CLOSED_TYPES.date,
  setSelectedAction: () => {},
  selectedDate: moment().endOf("day").toDate(),
  setSelectedDate: () => {},
  selectedNumber: 1,
  setSelectedNumber: () => {}
})

export default Context
