import { arrayOf, func, number, shape, string } from "prop-types"
import React from "react"
import { difference } from "lodash"

import { getCurrentUserId } from "src/helpers/user"
import { AttendeeTitle } from "src/styles/components/Card/List"

import TeamCard from "./TeamCard"
import UserCard from "./UserCard"
import SelectAllButton from "./SelectAllButton"

const SelectTeammates = ({
  possibleTeams,
  addedTeams,
  addedUsers,
  possibleUsers,
  title,
  selectedUserIds,
  setSelectedUserIds,
  usersRoles = {}
}) => {
  const currentUserId = getCurrentUserId()

  const allMembersSelected = (team) => {
    return team.teamMemberships.every(({ userId }) => selectedUserIds.includes(userId))
  }

  const toggleUserMembership = ({ userId }) => {
    setSelectedUserIds((userIds) => {
      const index = userIds.indexOf(userId)

      if (index === -1) {
        userIds.push(userId)
      } else {
        userIds.splice(index, 1)
      }
      return [...userIds]
    })
  }

  const toggleTeam = ({ memberships, checked }) => {
    const teamMembershipIds = memberships.map(({ userId }) => userId)

    setSelectedUserIds((userIds) => {
      if (checked) {
        return [...new Set([...userIds, ...teamMembershipIds])]
      }
      return difference(userIds, teamMembershipIds)
    })
  }

  const possibleUsersIds = possibleUsers.map(({ id }) => id)
  const selectAllPossibleUsers = () => {
    setSelectedUserIds((userIds) => {
      return [...new Set([...userIds, ...possibleUsersIds])]
    })
  }

  const selectAllPossibleTeams = () => {
    possibleTeams.forEach((team) => {
      toggleTeam({ memberships: team.teamMemberships, checked: true })
    })
  }

  const roleInScheme = (user) => {
    return usersRoles[user.id] ?? null
  }

  let nonMembersSelect
  if (possibleTeams.length) {
    nonMembersSelect = (
      <SelectAllButton onClick={selectAllPossibleTeams} targetText="teams" />
    )
  } else if (possibleUsers.length) {
    nonMembersSelect = (
      <SelectAllButton onClick={selectAllPossibleUsers} targetText="people" />
    )
  }

  return (
    <>
      {!!addedUsers.length && (
        <>
          <AttendeeTitle
            className="justify-content-center mb-3"
            data-testid="attendee-title"
          >
            <div>{title}</div>
          </AttendeeTitle>
          {addedTeams.map((possibleTeam) => (
            <TeamCard
              key={`team-${possibleTeam.id}`}
              team={possibleTeam}
              toggleTeam={toggleTeam}
              checkedTeam={allMembersSelected(possibleTeam)}
            />
          ))}
          {!!addedTeams.length && <hr className="mx-4" />}
          {addedUsers.map((user) => (
            <UserCard
              key={user.id}
              user={user}
              toggleUserMembership={toggleUserMembership}
              checkedMembership={selectedUserIds.includes(user.id)}
              isCurrentUser={user.id === currentUserId}
              deletedFromAccount={user.deletedFromAccount}
              role={roleInScheme(user)}
            />
          ))}
        </>
      )}
      <AttendeeTitle
        className={
          possibleTeams.length || possibleUsers.length
            ? "justify-content-end"
            : "justify-content-center"
        }
      >
        <div className={!!(possibleTeams.length || possibleUsers.length) && "mr-5"}>
          Non Members
        </div>
        {nonMembersSelect}
      </AttendeeTitle>
      {!!possibleTeams.length && (
        <div className="my-2">
          {possibleTeams.map((possibleTeam) => (
            <TeamCard
              key={`team-${possibleTeam.id}`}
              team={possibleTeam}
              toggleTeam={toggleTeam}
              checkedTeam={allMembersSelected(possibleTeam)}
            />
          ))}
        </div>
      )}

      {!!possibleTeams.length && (
        <AttendeeTitle className="justify-content-end pb-2">
          <SelectAllButton onClick={selectAllPossibleUsers} targetText="people" />
        </AttendeeTitle>
      )}
      {possibleUsers.map((user) => (
        <UserCard
          key={user.id}
          user={user}
          toggleUserMembership={toggleUserMembership}
          checkedMembership={selectedUserIds.includes(user.id)}
          isCurrentUser={user.id === currentUserId}
          deletedFromAccount={user.deletedFromAccount}
          role={roleInScheme(user)}
        />
      ))}
    </>
  )
}

SelectTeammates.propTypes = {
  possibleUsers: arrayOf(
    shape({
      id: number.isRequired,
      fullName: string.isRequired
    })
  ),
  addedUsers: arrayOf(
    shape({
      id: number.isRequired,
      fullName: string.isRequired
    })
  ),
  title: string.isRequired,
  selectedUserIds: arrayOf(number.isRequired),
  addedTeams: arrayOf(
    shape({
      id: number.isRequired,
      name: string.isRequired
    })
  ),
  possibleTeams: arrayOf(
    shape({
      id: number.isRequired,
      name: string.isRequired
    })
  ),
  setSelectedUserIds: func.isRequired,
  usersRoles: shape({})
}

export default SelectTeammates
