class @MembershipsIndexHandler
  constructor: ->
    @userTable = $be('user-management-table')
    @membersList = $be('members-form-list')
    @editMembershipModal = $be('edit-agenda-items-modal')
    @editMembershipModalContent = $be('edit-agenda-items-modal-content')
    @filterInputComponent = new FilterInputComponent(
      input:        'meeting-pack-user-filter',
      items:        'user-management-table-row',
      emptyMessage: 'users-filter-empty'
    )
    @memberActionsComponent = new MemberActionsComponent()

  setup: () =>
    @filterInputComponent.setup()
    @memberActionsComponent.setup()

    @setupRemoteHandler()
    @setupAddMemberLink()
    @setupAccountMembersLink()
    @setupBackToUsersLink()

  teardown: () =>
    @teardownAddMemberLink()
    @teardownAccountMembersLink()
    @teardownBackToUsersLink()

  setupRemoteHandler: () ->
    # bootstrap calls e.stopPropagation() what disables handling of "remote" links
    @userTable.find('.dropdown-menu [data-remote="true"]').click((e) ->
      e.preventDefault()
      window.Rails.handleRemote.call(e.target, e)
    )

  tablesortSelector: (tbody, column) ->
    groups = []
    $.each($(tbody).find('tr'), (index, tr) ->
      td = $(tr).find('td')[column];
      sortData = $(td).data('sort')
      sortValue = if sortData? then sortData.toString() else $(td).text()

      groups.push({
        'values': [ tr ],
        'key'   : sortValue
      })
    )
    groups

  setupAccountMembersLink: () =>
    $be('account-members-link').on('click', (e) =>
      e.preventDefault()
      $be('add-members-modal').modal('hide')
    )

  setupBackToUsersLink: () =>
    $be('back-to-add-new-users-link').on('click', (e) =>
      e.preventDefault()
      $be('add-members-modal').modal('show')
    )

  setupAddMemberLink: () =>
    $be('add-another-member-form-link').on('click', (e) =>
      e.preventDefault()
      firstMemberRow = $be('members-form-list-row').first()
      newMemberRow = firstMemberRow.clone()

      rndKey = Math.random().toString(16).substring(2, 10)
      newMemberRow.attr('data-id', rndKey)

      ['first_name', 'last_name', 'email', 'membership_type', 'role'].forEach((field) ->
        newMemberRowInput = newMemberRow.find("[data-behavior=#{field}-field]")
        newMemberRowInput.attr({ name: "users[#{rndKey}][#{field}]" })
        newMemberRowInput.val('')
      )

      @membersList.append(newMemberRow)
      newMemberRow.show()
      newMemberRow.find('[data-behavior=remove-another-member-form-link]').show().click (e) ->
        e.preventDefault()
        newMemberRow.remove()
    )

  teardownAddMemberLink: () =>
    $be('add-another-member-form-link').off('click')

  teardownAccountMembersLink: () =>
    $be('account-members-link').off('click')

  teardownBackToUsersLink: () =>
    $be('back-to-add-new-users-link').off('click')

  showMembershipModal: (modalContent) =>
    @editMembershipModalContent.html(modalContent)
    @editMembershipModalContent.find('select').select2({
      theme: 'knowa',
      minimumResultsForSearch: -1
    })
    @editMembershipModal.modal('show')

  hideMembershipModal: () =>
    @editMembershipModal.modal('hide')
