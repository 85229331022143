import React, { useEffect, useState } from "react"

import AttachedDocuments from "src/components/AttachedDocuments"
import * as pspdfkitDocumentsApi from "src/api/pspdfkitDocuments"
import { MESSAGE_FORM_CLEARED } from "src/constants/events"

import CollaborativeDocumentsUploadModal from "./CollaborativeDocumentsUploadModal"

const CollaborativeDocuments = () => {
  const [attachedDocuments, setAttachedDocuments] = useState([])

  useEffect(() => {
    window.addEventListener(MESSAGE_FORM_CLEARED, () => {
      setAttachedDocuments([])
    })
  }, [])

  const addAttachedDocuments = (newDocuments) =>
    setAttachedDocuments((documents) => [...documents, ...newDocuments])

  const uploadDocument = (document) =>
    pspdfkitDocumentsApi.upload({ file: document.file })

  const detachDocument = (documentId) => {
    const document = attachedDocuments.find(({ id }) => documentId === id)

    if (!document) {
      return Promise.resolve()
    }

    return pspdfkitDocumentsApi
      .destroy({ documentId })
      .then(() =>
        setAttachedDocuments((documents) =>
          documents.filter(({ id }) => id !== documentId)
        )
      )
  }

  return (
    <>
      <CollaborativeDocumentsUploadModal
        uploadDocument={uploadDocument}
        addAttachedDocuments={addAttachedDocuments}
      />
      <AttachedDocuments
        attachedDocuments={attachedDocuments}
        detachDocument={detachDocument}
      />
    </>
  )
}

export default CollaborativeDocuments
