import apiClient from "src/app/apiClient"

const index = (params) => apiClient.get(`/api/starred_items`, { params })
const accounts = (params) => apiClient.get(`/api/starred_items/accounts`, { params })
const schemes = (params) => apiClient.get(`/api/starred_items/schemes`, { params })

const starredItems = {
  index,
  accounts,
  schemes
}

export default starredItems
