import React from "react"
import { arrayOf, shape, string } from "prop-types"

import { FooterBreadcrumbsLink } from "./styles"

const Breadcrumbs = ({ breadcrumbs }) => {
  if (!breadcrumbs.length) {
    return null
  }

  return (
    <>
      <span> in </span>
      {breadcrumbs.map(({ name, path }) => (
        <FooterBreadcrumbsLink key={path} href={path}>
          {name}
        </FooterBreadcrumbsLink>
      ))}
    </>
  )
}

Breadcrumbs.propTypes = {
  breadcrumbs: arrayOf(
    shape({
      name: string,
      path: string
    })
  )
}

export default Breadcrumbs
