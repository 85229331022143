import membershipsApi from "src/api/meetingPacks/memberships"
import meetingPacks from "src/api/meetingPacks"
import { showFlashMessage } from "src/helpers/flash"
import { showCaughtErrorMessage } from "src/helpers/errors"

import {
  setAgendaItemStatus,
  setAgendaItemMemberships,
  setAgendaItems
} from "../meetingPackSlice"

export const updateStatus = (agendaItem, status) => (dispatch) => {
  return meetingPacks.agendaItems
    .update(agendaItem, status)
    .then((response) => dispatch(setAgendaItemStatus(response.data)))
    .catch((error) => showCaughtErrorMessage(error))
}

export const updateMemberships =
  (agendaItemId, memberships, onSuccess, onError) => (dispatch) => {
    return membershipsApi
      .setMemberships({ agendaItemId, memberships })
      .then((response) => {
        dispatch(setAgendaItemMemberships(response.data))
        onSuccess()
        showFlashMessage(
          "success",
          "Agenda item membership has been successfully updated."
        )
      })
      .catch(() => {
        onError()
        showFlashMessage("danger", "Agenda item membership hasn't been updated.")
      })
  }

export const refreshAgendaItems = (meetingPackId) => (dispatch) => {
  meetingPacks.meetingPacks
    .fetchAgendaItems({ meetingPackId })
    .then((response) => dispatch(setAgendaItems(response.data)))
    .catch((error) => {
      showCaughtErrorMessage(error)
      throw error
    })
}
