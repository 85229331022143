import React, { useContext } from "react"
import { Modal, Button } from "react-bootstrap"

import { ButtonContainer } from "../styles"
import NewMessageActionContext from "../NewMessageActionContext"

import Context from "./Context"

const Footer = () => {
  const { closeActionsModal, confirmOption } = useContext(NewMessageActionContext)
  const { selectedAction, selectedDate, selectedNumber } = useContext(Context)

  const onConfirmClick = () => {
    confirmOption(selectedAction, selectedDate, selectedNumber)
    closeActionsModal()
  }

  return (
    <Modal.Footer>
      <ButtonContainer>
        <Button variant="primary" size="lg" onClick={onConfirmClick}>
          Confirm
        </Button>

        <Button variant="outline-primary" onClick={closeActionsModal}>
          Cancel
        </Button>
      </ButtonContainer>
    </Modal.Footer>
  )
}

export default Footer
