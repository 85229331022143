import React from "react"
import { number } from "prop-types"
import { styled } from "@linaria/react"
import classNames from "classnames"

import { millisecondsToMinutes } from "src/helpers/datetime"
import { BASE_COLORS } from "src/styles/colors"

const formatTimeNumber = (n) => (n < 10 ? `0${n}` : n)
const formatSeconds = (seconds) => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor(seconds / 60) - hours * 60

  return `${formatTimeNumber(hours)}:${formatTimeNumber(minutes)}:${formatTimeNumber(seconds % 60)}`
}

const Timeline = ({ utteranceStart, lastShownMinute, className }) => {
  const isNotNewMinute = millisecondsToMinutes(utteranceStart) === lastShownMinute

  return (
    <div className={classNames("justify-content-center", className)}>
      <div
        key={utteranceStart}
        className={classNames(
          "position-relative text-small",
          isNotNewMinute && "timeline-muted"
        )}
      >
        {formatSeconds(Math.floor(utteranceStart / 1000))}
      </div>
    </div>
  )
}

Timeline.propTypes = {
  utteranceStart: number.isRequired,
  lastShownMinute: number
}

export default styled(Timeline)`
  position: absolute;
  top: 0;
  right: calc(100% + 10px);
  display: flex;
  align-items: center;
  height: 100%;
  width: 75px;
  flex-direction: column;

  .timeline-muted {
    color: ${BASE_COLORS.lightGray};
  }
`
