import React from "react"
import { shape, string, bool } from "prop-types"

import PublicationBadge from "src/components/PublicationBadge"

const RightSide = ({ meetingPack, isCurrentUserAdmin }) => {
  const showBadge = isCurrentUserAdmin || meetingPack.publicationBadge === "preview"
  if (!showBadge) return null

  return (
    <PublicationBadge
      data-testid="publication-badge"
      className="mrs"
      publicationStatus={meetingPack.publicationBadge}
      meetingPackId={meetingPack.id}
    />
  )
}

RightSide.propTypes = {
  meetingPack: shape({
    publicationStatus: string.isRequired
  }).isRequired,
  isCurrentUserAdmin: bool.isRequired
}

export default RightSide
