import { styled } from "@linaria/react"
import { Form } from "react-bootstrap"

import Icon from "src/styles/components/Icon"
import {
  BACKGROUND_COLORS,
  BASE_COLORS,
  BORDER_COLORS,
  ICON_COLORS
} from "src/styles/colors"
import { BOX_SHADOWS } from "src/styles/shadows"

export const MenuIcon = styled(Icon)`
  cursor: pointer;
  color: ${ICON_COLORS.menuIcon};

  &:hover {
    color: ${ICON_COLORS.menuIconHover};
  }
`

export const FormWarning = styled(Form.Text)`
  color: ${BASE_COLORS.orange};
`

export const selectStyles = {
  option: (styles, state) => {
    let backgroundColor = null

    if (state.isSelected) backgroundColor = BACKGROUND_COLORS.selected
    if (state.isFocused) backgroundColor = BACKGROUND_COLORS.focused

    return {
      ...styles,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      color: "inherit",
      backgroundColor
    }
  },
  control: (styles, state) => ({
    ...styles,
    height: "auto",
    boxShadow: state.isFocused ? BOX_SHADOWS.outerInput : "none",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: state.isFocused ? BORDER_COLORS.inputHover : "lightgray",
    "&:hover": {
      borderColor: state.isFocused ? BORDER_COLORS.inputHover : "lightgray"
    }
  }),
  singleValue: (styles) => ({ ...styles, margin: "0" }),
  valueContainer: (styles) => ({ ...styles, padding: "0" }),
  indicatorsContainer: (styles) => ({ ...styles, cursor: "pointer" })
}
