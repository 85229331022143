import { styled } from "@linaria/react"

import Icon from "src/styles/components/Icon"
import { SPACES } from "src/styles/sizes"
import { BASE_COLORS } from "src/styles/colors"

export const StyledLink = styled.a`
  color: ${BASE_COLORS.darkViolet};
  margin-left: ${SPACES.small};
  margin-top: ${SPACES.xSmall};
  font-size: 1.35rem;

  &:hover {
    color: ${BASE_COLORS.darkViolet};
    opacity: 0.75;
  }
`

export const StyledIcon = styled(Icon)``
