import { styled } from "@linaria/react"

import { BORDER_COLORS, iconColor } from "src/styles/colors"
import { BREAKPOINTS } from "src/styles/sizes"
import BaseCloseButton from "src/components/CloseButton"

import { DRAWER_CONTENT_WIDTH } from "./constants"

export const EntityName = styled.div`
  max-width: ${DRAWER_CONTENT_WIDTH};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: ${BREAKPOINTS.small}) {
    max-width: calc(100vw - 80px);
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 15px 0;
  border-bottom: 1px solid ${BORDER_COLORS.separator};
  font-weight: 500;
`

export const Filter = styled.div`
  padding: 15px 15px 10px 15px;
  border-bottom: 1px solid ${BORDER_COLORS.separator};
`

export const FilterOptionsSwitcher = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const TogglerWrapper = styled.div`
  width: 32px;
  height: 32px;
  position: absolute;
  right: 0px;
  top: 128px;
  background: white;
  z-index: 2;
  cursor: pointer;
  border-radius: 0 0 0.25rem 0.25rem;
  box-shadow: 2px -2px 5px rgba(115, 131, 158, 0.15);
  padding-top: 0.1em;
  color: ${iconColor};
  transform: rotate(90deg);
`

export const DrawerItem = styled.div`
  display: flex;
  padding-top: 10px;
`

export const DrawerItemWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding-left: 15px;
  width: 100%;
  overflow: hidden;

  .entity-name {
    font-size: 0.9rem;
  }
`

export const DrawerList = styled.div`
  height: calc(100vh - 275px);
  overflow-y: auto;
  padding-bottom: 5px;

  &.filter-opened {
    height: calc(100vh - 275px - 152px);
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    height: auto;
    overflow: hidden;
  }
`

export const CloseButton = styled(BaseCloseButton)`
  margin-top: 10px;
`
