import { styled } from "@linaria/react"

import UnstyledButton from "src/components/UnstyledButton"

export const UndoButtton = styled(UnstyledButton)`
  font-weight: 600;
`

export const MessageWrapper = styled.div`
  display: flex;
  gap: 40px;
  max-width: 70%;
`

export const Alert = styled.div`
  display: flex;
  justify-content: space-between;
`
