import React from "react"
import { startCase } from "lodash"
import { bool, func, number, oneOf, shape } from "prop-types"

import { MEMBERSHIP_TYPES, ROLES } from "src/features/meetingPacks/MeetingPack/constants"
import { displayedMembershipType } from "src/features/meetingPacks/MeetingPack/Attendees/List/ManageUsers/helpers"
import UserAvatar from "src/components/UsersContacts/UserAvatar"
import Card, { Mascot } from "src/styles/components/Card/Card"
import { Primary, Secondary, Title } from "src/styles/components/Card/Title"
import Auxiliary from "src/styles/components/Card/Auxiliary"
import LinkButton from "src/styles/components/Button/Link"
import DeletedFromAccountBadge from "src/components/DeletedFromAccountBadge"
import UserAvatarContainer from "src/components/UsersContacts/UserAvatarContainer"
import UserBadge from "src/components/UsersContacts/UserBadge"

import { AccountRoleBadge } from "../styles"
import RoleIcon from "../RoleIcon"

const UserCard = ({
  user,
  onClick,
  isCurrentUser = false,
  deletedFromAccount,
  added = false
}) => {
  return (
    <LinkButton noFocus onClick={onClick}>
      <Card horizontal className="px-2 my-2" data-id={user.id} data-testid="user-card">
        <Mascot className="mr-2">
          <UserAvatarContainer>
            {added && (
              <UserBadge variant="added" data-testid="badge">
                +
              </UserBadge>
            )}
            <UserAvatar user={user} variant={added ? "added" : null} />
          </UserAvatarContainer>
        </Mascot>
        <Title>
          <Primary>
            {user.fullName} {isCurrentUser ? "(you)" : ""}
          </Primary>
          {deletedFromAccount ? (
            <div>
              <DeletedFromAccountBadge />
            </div>
          ) : (
            <Secondary>{user.jobTitle}</Secondary>
          )}
        </Title>
        <Auxiliary>
          {user.role !== ROLES.member && (
            <AccountRoleBadge role={user.role}>
              <RoleIcon className="mr-1" role={user.role} />
              {startCase(user.role)}
            </AccountRoleBadge>
          )}

          <div className="text-right">{displayedMembershipType(user)}</div>
        </Auxiliary>
      </Card>
    </LinkButton>
  )
}

UserCard.propTypes = {
  user: shape({
    id: number.isRequired,
    membershipType: oneOf(Object.values(MEMBERSHIP_TYPES)).isRequired,
    agendaItemMembershipType: oneOf(Object.values(MEMBERSHIP_TYPES)).isRequired
  }).isRequired,
  onClick: func,
  isCurrentUser: bool,
  deletedFromAccount: bool,
  added: bool
}

export default UserCard
