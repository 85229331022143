import React from "react"
import { number } from "prop-types"
import { useQuery } from "@apollo/client"
import { Button } from "react-bootstrap"

import { withErrorBoundary } from "src/app/ErrorBoundary"
import useLayoutHeader from "src/hooks/useLayoutHeader"
import useIsOpened from "src/hooks/useIsOpened"

import teamsQuery from "./teams.gql"
import EditTeamModal from "./EditTeamModal"
import Team from "./Team"

const Teams = ({ accountId }) => {
  useLayoutHeader()

  const { data } = useQuery(teamsQuery, {
    variables: { accountId }
  })

  const possibleTeams = (currentTeam) => {
    return data.teams.filter(
      (team) => team.id !== currentTeam.id && team.teamMemberships.length
    )
  }

  const {
    isOpened: isOpenedEditTeamModal,
    open: openEditTeamModal,
    close: closeEditTeamModal
  } = useIsOpened()

  return (
    <>
      <EditTeamModal
        accountId={accountId}
        isOpened={isOpenedEditTeamModal}
        close={closeEditTeamModal}
      />

      <div className="fluid-container">
        <div className="container mt-3">
          <div className="d-flex justify-content-between list-heading">
            <h4>Teams</h4>
            <Button className="button is-transformer" onClick={openEditTeamModal}>
              <div className="icon-add" />
              <span className="button-caption">New Team</span>
            </Button>
          </div>
          <div className="teams-container">
            {data?.teams && data?.teams.length === 0 && (
              <div className="card text-center pvxl">
                Looks like there are no teams yet.
              </div>
            )}
            {data?.teams?.map((team) => (
              <Team
                team={team}
                key={team.id}
                accountId={accountId}
                possibleTeams={possibleTeams(team)}
                accountMemberships={data.accountMemberships}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

Teams.propTypes = {
  accountId: number.isRequired
}

export default withErrorBoundary(Teams)
