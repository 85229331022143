import React from "react"
import { elementType, number } from "prop-types"

const Header = ({ meetingPackId, SubHeaderActionsComponent, ...rest }) => {
  return (
    <div className="subheader-nav subheader-nav-grid groups-list-nav">
      <div className="subheader-link">
        <a
          href={`/meeting_packs/${meetingPackId}`}
          className="subheader-control button is-secondary"
        >
          <span className="control-text">Back to Meeting Pack</span>
        </a>
      </div>
      <div className="subheader-title">
        <h5>Knowa Verse</h5>
      </div>
      {SubHeaderActionsComponent && (
        <div className="subheader-actions">
          <SubHeaderActionsComponent {...rest} />
        </div>
      )}
    </div>
  )
}

Header.propTypes = {
  meetingPackId: number.isRequired,
  SubHeaderActionsComponent: elementType
}

export default Header
