import { useEffect } from "react"
import { arrayOf, shape } from "prop-types"
import { useDispatch } from "react-redux"

import { setAgendaItemAttendeesChanges } from "src/features/meetingPacks/MeetingPack/meetingPackSlice"

import { User } from "./types"

const DispatchAttendeesChanges = ({ attendeesChanges }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setAgendaItemAttendeesChanges(attendeesChanges))
  }, [dispatch, attendeesChanges])

  return null
}

DispatchAttendeesChanges.propTypes = {
  attendeesChanges: shape({
    joiningUsers: arrayOf(User).isRequired,
    leavingUsers: arrayOf(User).isRequired
  }).isRequired
}

export default DispatchAttendeesChanges
