import React from "react"
import { Spinner } from "react-bootstrap"
import { func, instanceOf } from "prop-types"

import CloseButton from "src/components/CloseButton"
import { UploadFile } from "src/features/groups/DocumentsExplorer/UploadFile"
import TextWithTooltip from "src/styles/components/TextWithTooltip"

import { ErrorContainer, IconError } from "../styles"

import { IconDone } from "./styles"

const FileControls = ({ file, onRemove }) => {
  return (
    <>
      {file.isPending() && <CloseButton onClick={() => onRemove(file)} />}
      {file.isUploading() && <Spinner animation="border" size="sm" variant="primary" />}
      {file.isUploaded() && <IconDone type="done" />}
      {file.isFailed() && (
        <ErrorContainer>
          {file.errorMessage && (
            <TextWithTooltip text={file.errorMessage} maxLength={50} />
          )}
          <IconError type="info" />
        </ErrorContainer>
      )}
    </>
  )
}

FileControls.propTypes = {
  file: instanceOf(UploadFile),
  onRemove: func.isRequired
}

export default FileControls
