import React, { useCallback, useState } from "react"
import { useDispatch } from "react-redux"
import { bool, func, shape, number, string } from "prop-types"
import { Modal, Row, Col } from "react-bootstrap"

import PrimaryButton from "src/styles/components/Button/Primary"
import SecondaryButton from "src/styles/components/Button/Secondary"
import ModalHeader from "src/styles/components/Modal/Header"
import ModalTitle from "src/styles/components/Modal/Title"
import ModalFooter from "src/styles/components/Modal/Footer"
import BadgeIcon from "src/styles/components/BadgeIcon"
import { moveDiscussion } from "src/resources/discussions/thunks"
import { refreshWithTurbo } from "src/helpers/url"
import { optionType } from "src/constants/propTypes"

import { GroupHeader, IconForward } from "./styles"

const ConfirmModal = ({
  currentAccount,
  newAccount,
  currentScheme,
  newScheme,
  currentGroup,
  newGroup,
  discussion,
  isOpened,
  close
}) => {
  const dispatch = useDispatch()
  const [isMoving, setMoving] = useState(false)

  const handleConfirm = useCallback(() => {
    setMoving(true)
    close()
    dispatch(
      moveDiscussion({ discussionId: discussion.id, data: { groupId: newGroup.value } })
    ).finally(() => {
      setMoving(false)
      refreshWithTurbo()
    })
  }, [dispatch, setMoving, discussion, newGroup])

  return (
    <Modal centered size="md" onHide={close} show={isOpened}>
      <ModalHeader>
        <ModalTitle>Confirm Move</ModalTitle>
      </ModalHeader>
      <Modal.Body>
        <p className="text-center">Please confirm you are moving the discussion</p>
        <h5 className="text-center">{discussion.title}</h5>
        <Row className="mt-4 mb-4">
          <Col>
            <GroupHeader>Current Group</GroupHeader>
            <p className="text-center">
              <h5>{currentGroup.label}</h5>
            </p>
            <p className="text-center">
              <h6>
                {currentScheme.label}
                {currentScheme.joint && <BadgeIcon type="connected" size="small" />}
              </h6>
              <p>{currentAccount.label}</p>
            </p>
          </Col>
          <IconForward type="arrow-right-hollow" size="large" />
          <Col>
            <GroupHeader>New Group</GroupHeader>
            <p className="text-center">
              <h5>{newGroup.label}</h5>
            </p>
            <p className="text-center">
              <h6>
                {newScheme.label}
                {newScheme.joint && <BadgeIcon type="connected" size="small" />}
              </h6>
              <p>{newAccount.label}</p>
            </p>
          </Col>
        </Row>
      </Modal.Body>
      <ModalFooter>
        <PrimaryButton className="mr-3" disabled={isMoving} onClick={handleConfirm}>
          {isMoving ? "Moving..." : "Confirm move"}
        </PrimaryButton>
        <SecondaryButton onClick={close}>No, get me out of here</SecondaryButton>
      </ModalFooter>
    </Modal>
  )
}

ConfirmModal.propTypes = {
  currentAccount: optionType,
  newAccount: optionType,
  currentScheme: optionType,
  newScheme: optionType,
  currentGroup: optionType,
  newGroup: optionType,
  discussion: shape({
    id: number.isRequired,
    title: string.isRequired
  }),
  isOpened: bool.isRequired,
  close: func.isRequired
}

export default ConfirmModal
