import { EVENTS } from "src/features/offline/constants"

const triggerSavedOffline = ({ meetingPackId }) => {
  const event = new CustomEvent(EVENTS.SAVED_OFFLINE, { detail: { meetingPackId } })
  window.dispatchEvent(event)
}

const triggerRemovedOffline = ({ meetingPackId }) => {
  const event = new CustomEvent(EVENTS.REMOVED_OFFLINE, { detail: { meetingPackId } })
  window.dispatchEvent(event)
}

export { triggerSavedOffline, triggerRemovedOffline }
