import React from "react"
import { func, number, string } from "prop-types"

import UploadZone from "src/components/FilesUpload/UploadZone"
import UploadedFilesList from "src/components/FilesUpload/UploadedFilesList"
import { TRANSCRIPT_STATUSES } from "src/resources/meetingPacks/constants"
import { showFlashMessage } from "src/helpers/flash"

import useUpload from "./useUpload"

const MAX_FILE_SIZE = 2147483648 // 2Gb

const UploadModalContent = ({ meetingPackId, setStatus, status }) => {
  const { setSelectedFilesWithMeta, selectedFiles, uploadFiles, isUploading } = useUpload(
    { meetingPackId, setStatus }
  )

  const handleDropRejected = (fileRejections) => {
    const errors = fileRejections[0]?.errors
    if (!errors) return

    if (errors[0]?.code === "file-too-large")
      showFlashMessage("danger", "The size limit for the file is 2GB")
  }

  return (
    <div className="container mt-5">
      <div className="card">
        <div className="card-body">
          <div className="title d-flex align-middle justify-content-between">
            <b>Upload Audio</b>
          </div>

          {status === TRANSCRIPT_STATUSES.failed && (
            <div className="text-danger mb-3">
              Failed to upload. Please try again or contact support@knowa.co
            </div>
          )}

          <div>
            <div className="flex-grow-0 my-2">
              <UploadZone
                setSelectedFiles={setSelectedFilesWithMeta}
                multiple={false}
                allowedFiles="audio_and_video"
                maxSize={MAX_FILE_SIZE}
                onDropRejected={handleDropRejected}
              />
            </div>
            <div className="allow-scroll">
              <UploadedFilesList files={selectedFiles} />
            </div>
            <div className="text-center mt-2">
              <button
                className="button"
                disabled={!selectedFiles[0] || isUploading}
                onClick={uploadFiles}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

UploadModalContent.propTypes = {
  meetingPackId: number.isRequired,
  setStatus: func.isRequired,
  status: string
}

export default UploadModalContent
