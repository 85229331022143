import React from "react"
import classNames from "classnames"
import { shape, bool, number, string } from "prop-types"

import BadgeIcon from "src/styles/components/BadgeIcon"

import { Badge } from "./styles"

const SchemeBadge = ({ scheme }) => {
  const changeBadgeColor = scheme.colorId || scheme.colorId === 0
  const badgeClasses = classNames("scheme-badge", {
    [`badge-modifier-${scheme?.colorId}`]: changeBadgeColor
  })

  return (
    <Badge className={badgeClasses}>
      {scheme.nameForUser}
      {scheme.joint && <BadgeIcon type="connected" />}
    </Badge>
  )
}

SchemeBadge.propTypes = {
  scheme: shape({
    nameForUser: string.isRequired,
    colorId: number.isRequired,
    joint: bool.isRequired
  }).isRequired
}

export default SchemeBadge
