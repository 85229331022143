import React from "react"
import { arrayOf, bool, func, number, shape, string } from "prop-types"
import { Spinner } from "react-bootstrap"

import { Modal, Header, Footer, SubmitButton, CancelButton } from "../Modal"

import DeletionLog from "./DeletionLog"
import { Body, SpinnerContainer } from "./styles"

const DeleteItemsConfirmModal = ({
  onClose,
  onSubmit,
  deleting,
  log,
  completed,
  total
}) => {
  return (
    <Modal>
      <Header onClose={onClose}>
        {!deleting && !completed && <span>Delete Items</span>}
        {deleting && (
          <span>
            Deletion In Progress ({log.length} / {total})
          </span>
        )}
        {completed && <span>Deletion Completed</span>}
      </Header>
      <Body>
        {!deleting && !completed && (
          <span>Are you sure you want to delete selected items?</span>
        )}
        {deleting && log.length === 0 && (
          <SpinnerContainer>
            <Spinner animation="border" variant="primary" />
          </SpinnerContainer>
        )}
        {(deleting || completed) && <DeletionLog log={log} />}
      </Body>
      <Footer>
        <SubmitButton onClick={onSubmit} disabled={deleting || completed}>
          {!deleting && "Delete"}
          {deleting && "Deleting..."}
        </SubmitButton>
        <CancelButton onClick={onClose} disabled={deleting}>
          Cancel
        </CancelButton>
      </Footer>
    </Modal>
  )
}

DeleteItemsConfirmModal.propTypes = {
  onClose: func.isRequired,
  onSubmit: func.isRequired,
  deleting: bool.isRequired,
  completed: bool.isRequired,
  total: number.isRequired,
  log: arrayOf(
    shape({
      id: string.isRequired,
      type: string.isRequired,
      errorMessage: string
    })
  )
}

export default DeleteItemsConfirmModal
