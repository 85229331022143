import React, { useState } from "react"
import { string } from "prop-types"
import classNames from "classnames"

import KnowaLogoLoader from "src/styles/components/KnowaLogoLoader"

const IframeApp = ({ path, groupName, appName, wrapperClass = "" }) => {
  const [isLoading, setIsLoading] = useState(true)
  const hideLoader = () => setIsLoading(false)
  const appTitle = `Knowa - ${groupName} > ${appName}`

  return (
    <div className={`h-100 d-flex fluid-container ${wrapperClass}`}>
      {isLoading && <KnowaLogoLoader />}
      <iframe
        title={appTitle}
        className={classNames("w-100 h-100 border-0", {
          "d-none": isLoading
        })}
        src={path}
        onLoad={hideLoader}
      />
      <div className="iframe-footer" />
    </div>
  )
}

IframeApp.propTypes = {
  path: string.isRequired,
  groupName: string.isRequired,
  appName: string.isRequired,
  wrapperClass: string
}

export default IframeApp
