import React from "react"
import { number } from "prop-types"
import { useDispatch } from "react-redux"

import { getCurrentUserId } from "src/helpers/user"
import useSaveOffline from "src/features/offline/hooks/useSaveOffline"
import useAblyChannel from "src/hooks/useAblyChannel"
import { CHANNELS } from "src/constants/ably"
import { refreshAgendaItems } from "src/features/meetingPacks/MeetingPack/thunks/agendaItem"
import { refreshUnreadItems } from "src/features/meetingPacks/MeetingPack/thunks/meetingPack"

import SubHeader from "./SubHeader"
import ContentContainer from "./ContentContainer"
import MeetingPackOfflineContext from "./MeetingPackOfflineContext"

const MeetingPackView = ({ id }) => {
  const dispatch = useDispatch()
  const saveOffline = useSaveOffline({
    meetingPackId: id
  })

  useAblyChannel(CHANNELS.agendaItems, {
    onMessage: ({ data }) => {
      if (data.meetingPackId === id && data.usersToNotify.includes(getCurrentUserId())) {
        dispatch(refreshAgendaItems(id))
        dispatch(refreshUnreadItems(id))
      }
    }
  })

  return (
    <MeetingPackOfflineContext.Provider value={saveOffline}>
      <SubHeader />
      <ContentContainer />
    </MeetingPackOfflineContext.Provider>
  )
}

MeetingPackView.propTypes = {
  id: number.isRequired
}

export default MeetingPackView
