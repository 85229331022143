import React, { useCallback } from "react"
import pluralize from "pluralize"
import { shape, bool, number, string, func } from "prop-types"

import Icon from "src/styles/components/Icon"
import TextWithTooltip from "src/styles/components/TextWithTooltip"
import UnreadBadge from "src/styles/components/UnreadBadge"
import { splitFilename } from "src/helpers/string"

import { PrefixCol, ItemTitle, SuffixCol } from "../styles"

import DocumentsRow from "./DocumentsRow"
import { DocumentName, PagesCount } from "./styles"

const DocumentsListItem = ({
  document: { id, filename, pagesCount, annotationsCount },
  active = false,
  unread = false,
  ...rest
}) => {
  const onItemClick = useCallback(() => {
    rest.setActiveDocument(id)
  }, [id])
  const { name } = splitFilename(filename)
  const pages = pluralize("page", pagesCount, true)
  const hasAnnotation = annotationsCount > 0

  return (
    <DocumentsRow active={active} onClick={onItemClick}>
      <PrefixCol>{hasAnnotation && <Icon type="annotation" />}</PrefixCol>
      <ItemTitle>
        <DocumentName>
          <TextWithTooltip text={name} maxLength={0} />
        </DocumentName>
      </ItemTitle>
      <SuffixCol>
        <PagesCount>{pages}</PagesCount>
        {unread && <UnreadBadge />}
      </SuffixCol>
    </DocumentsRow>
  )
}

DocumentsListItem.propTypes = {
  setActiveDocument: func.isRequired,
  document: shape({
    id: number,
    filename: string,
    pagesCount: number
  }).isRequired,
  active: bool,
  unread: bool
}

export default DocumentsListItem
