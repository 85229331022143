import React, { useState } from "react"
import { number } from "prop-types"

import { beById, be } from "src/helpers/document"

const MessageSelection = ({ messageId }) => {
  const [selected, setSelected] = useState(
    beById("message-container", messageId).hasClass("printable")
  )

  const printModeOn = be("print-messages-form").is(":visible")
  if (printModeOn) {
    $(".message-block").addClass("with-selector")
  }

  const handleClick = () => {
    if (selected) {
      beById("message-container", messageId).removeClass("printable")
    } else {
      beById("message-container", messageId).addClass("printable")
    }
    setSelected(!selected)
  }

  const selectedClass = selected ? "icon-complete" : "icon-no-status"

  return (
    <button
      className={`message-selection ${printModeOn ? "" : "hidden"}`}
      data-behavior="message-selection"
      aria-label="message-selection"
      onClick={() => handleClick()}
    >
      <div className={selectedClass} />
    </button>
  )
}

MessageSelection.propTypes = {
  messageId: number.isRequired
}

export default MessageSelection
