import { be } from "src/helpers/document"

export class GroupMembershipManageComponent {
  constructor() {
    this.manageUserList = be("manage-members-modal-content")
  }

  // response of group/membership#edit
  showManageList(listContent) {
    this.manageUserList.html(listContent)
    window.layoutHandler.setAvatarImages(this.manageUserList)
    window.layoutHandler.showCurrentUserLabel(this.manageUserList)
    window.layoutHandler.contactDetailsComponent.bindLinks(this.manageUserList)
    be("manage-members-modal").modal("show")
    be("show-members-modal").modal("hide")
  }
}
