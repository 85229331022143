import { styled } from "@linaria/react"

import { cardBorderRadius, SPACES } from "src/styles/sizes"
import { BOX_SHADOWS } from "src/styles/shadows"
import { ICON_COLORS } from "src/styles/colors"

export const DocumentsCardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  column-gap: ${SPACES.small};
  row-gap: ${SPACES.small};
`

export const DocumentCard = styled.div`
  background: #ffffff;
  box-shadow: ${BOX_SHADOWS.standardBlock};
  border-radius: ${cardBorderRadius};
  padding: calc(${SPACES.xxSmall} + ${SPACES.xSmall});
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.clickable {
    cursor: pointer;
    transition: background 0.2s ease;

    &:hover {
      background: #fcfcfc;
    }
  }

  .close {
    color: ${ICON_COLORS.cardCloseButton};
    margin-top: -4px;
  }

  [class^="icon-"] {
    color: ${ICON_COLORS.attachedDocument};
  }
`
