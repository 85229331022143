import React from "react"
import { styled } from "@linaria/react"

import { FONT_SIZES, SPACES } from "src/styles/sizes"

const ChangedMembershipTypeReminder = ({ className }) => {
  return (
    <p className={className}>
      <span role="img" aria-label="warning">
        ⚠️
      </span>{" "}
      Remember to review access type for agenda items
    </p>
  )
}

export default styled(ChangedMembershipTypeReminder)`
  font-size: ${FONT_SIZES.medium};
  margin-top: ${SPACES.small};
  margin-bottom: 0;
`
