import React, { useState } from "react"
import { styled } from "@linaria/react"
import { oneOf, number, shape, string } from "prop-types"

import useIsOpened from "src/hooks/useIsOpened"
import { linkHoverColor, TEXT_COLORS } from "src/styles/colors"
import ContactDetailsModal from "src/components/UsersContacts/ContactDetailsModal"
import { getCurrentUserId } from "src/helpers/user"

import CloseMessageActionModal from "./CloseMessageActionModal"
import MessageActionText from "./MessageActionText"
import ActionDetailsModal from "./ActionDetailsModal"
import ModalContext from "./ModalContext"
import { ACTION_TYPES } from "./constants"

const ActionDetails = ({ message, className }) => {
  const {
    isOpened: mainModalOpened,
    open: openMainModal,
    close: closeMainModal
  } = useIsOpened()
  const currentUserId = getCurrentUserId()

  const [closeActionModalType, setCloseActionModalType] = useState()
  const [contactDetailsUser, setContactDetailsUser] = useState()

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const context = {
    message,
    openMainModal,
    closeMainModal,
    openContactDetailsModal: (user) => {
      setContactDetailsUser(user)
      closeMainModal()
    },
    closeContactDetailsModal: () => {
      setContactDetailsUser()
      openMainModal()
    },
    openCloseActionModal: (type) => {
      closeMainModal()
      setCloseActionModalType(type)
    },
    closeCloseActionModal: () => {
      openMainModal()
      setCloseActionModalType()
    },
    closeAllModals: () => {
      setCloseActionModalType()
      setContactDetailsUser()
      closeMainModal()
    }
  }

  return (
    <ModalContext.Provider value={context}>
      <ActionDetailsModal show={mainModalOpened} message={message} />
      {closeActionModalType && (
        <CloseMessageActionModal
          messageId={message.id}
          show={!!closeActionModalType}
          actionType={message.actionType}
          modalType={closeActionModalType}
        />
      )}
      {contactDetailsUser && (
        <ContactDetailsModal
          size="md"
          isOpened={!!contactDetailsUser}
          close={context.closeContactDetailsModal}
          user={contactDetailsUser || {}}
          isCurrentUser={currentUserId === contactDetailsUser?.id}
          type="Contact"
        />
      )}

      <MessageActionText message={message} />

      <button className={className} onClick={openMainModal}>
        View details
      </button>
    </ModalContext.Provider>
  )
}

ActionDetails.propTypes = {
  className: string.isRequired,
  message: shape({
    id: number.isRequired,
    actionType: oneOf(Object.keys(ACTION_TYPES)).isRequired
  })
}

export default styled(ActionDetails)`
  font-size: 0.8rem;
  color: ${TEXT_COLORS.link};
  cursor: pointer;
  text-decoration: none;
  background-color: transparent;
  border: none;
  &:focus,
  &:active,
  &:hover {
    color: ${linkHoverColor};
    outline: none;
  }
`
