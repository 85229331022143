import { omit } from "lodash"

const passkeyDeviceAddedKey = "deviceIdAddedToPassage"

const getPasskeyDevices = () =>
  JSON.parse(localStorage.getItem(passkeyDeviceAddedKey) || "{}")

export const passkeyDeviceId = (email) => getPasskeyDevices()[email]

export const storePasskeyDeviceId = (email, deviceId) =>
  localStorage.setItem(
    passkeyDeviceAddedKey,
    JSON.stringify({ ...getPasskeyDevices(), [email]: deviceId })
  )

export const removePasskeyDevice = (email) =>
  localStorage.setItem(
    passkeyDeviceAddedKey,
    JSON.stringify(omit(getPasskeyDevices(), [email]))
  )
