import React from "react"
import { arrayOf, number, shape, string } from "prop-types"

import {
  Header,
  Column,
  UserColumn,
  Table,
  FilterInput,
  useUsersList,
  useSorting,
  useFiltering
} from "src/components/AttandeesTable"
import RoleColumn from "src/components/AttandeesTable/RoleColumn"

import ActionsColumn from "./ActionsColumn"

const columns = [
  {
    key: "fullName",
    label: "FULL NAME"
  },
  {
    key: "email",
    label: "EMAIL"
  },
  {
    key: "jobTitle",
    label: "ROLE & ORGANISATION"
  },
  {
    key: "schemeRole",
    label: "SPACE ROLE"
  },
  {
    key: "lastSeenStatus",
    label: "ACTIVE IN SPACE"
  }
]

const addButtonProps = {
  "data-behavior": "add-people-button",
  "data-toggle": "modal",
  "data-target": "[data-behavior='add-new-scheme-users-modal']"
}

const AttendeesTable = ({ users: initialUsers, scheme }) => {
  const { users: rawUsers, setUserField, deleteUser } = useUsersList(initialUsers)
  const {
    sortedItems: sortedUsers,
    sort,
    sortDirection,
    sortBy
  } = useSorting({
    items: rawUsers,
    sortingColumnsRewrite: {
      lastSeenStatus: (item) => new Date(item.lastActiveAt).getTime()
    }
  })
  const { filteredItems: users, setFilter } = useFiltering({
    items: sortedUsers,
    filteredFields: ["email", "fullName", "jobTitle"]
  })

  const setUserRole = ({ id, role }) => {
    setUserField({ id, field: "schemeRole", value: role })
  }

  return (
    <div>
      <FilterInput setFilter={setFilter} addButtonProps={addButtonProps} />
      <Table className="table table-responsive-xl">
        <Header
          columns={columns}
          sortBy={sortBy}
          sortDirection={sortDirection}
          sort={sort}
        />
        <tbody>
          {users.map((user) => (
            <tr key={user.id} data-id={user.id} data-behavior="user-management-table-row">
              <UserColumn user={user} />
              <Column>{user.email}</Column>
              <Column>{user.jobTitle}</Column>
              <RoleColumn role={user.schemeRole} />
              <Column>{user.lastSeenStatus}</Column>
              <ActionsColumn
                user={user}
                setUserRole={setUserRole}
                deleteUser={deleteUser}
                schemeId={scheme.id}
              />
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

AttendeesTable.propTypes = {
  scheme: shape({ id: number.isRequired }),
  users: arrayOf(
    shape({
      id: number.isRequired,
      email: string,
      jobTitle: string,
      schemeRole: string,
      lastSeenStatus: string,
      lastActiveAt: string
    })
  )
}

export default AttendeesTable
