import React from "react"
import { styled } from "@linaria/react"
import { shape, string } from "prop-types"

import UserAvatar from "src/components/UsersContacts/UserAvatar"
import { SPACES } from "src/styles/sizes"
import { getCurrentUser } from "src/helpers/user"

import Column from "./Column"

const UserColumn = ({ className, user }) => {
  const currentUser = getCurrentUser()
  const isCurrentUser = currentUser && currentUser.id === user.id

  return (
    <Column className={className}>
      <div className="user-column">
        <UserAvatar user={user} />
        <span className="user-full-name">
          {user.fullName}
          {isCurrentUser && <span className="text-muted"> (you)</span>}
        </span>
      </div>
    </Column>
  )
}

UserColumn.propTypes = {
  user: shape({
    fullName: string
  })
}

export default styled(UserColumn)`
  .user-column {
    display: flex;
    align-items: center;
  }

  .user-full-name {
    margin: 0 ${SPACES.xxSmall} 0 ${SPACES.xSmall};
  }
`
