import { FroalaTextareaComponent } from "legacy/components/froala/froala_textarea_component.js.coffee"
import { TimePickerComponent } from "legacy/components/meeting_packs/time_picker.js.coffee"
import { FieldEncodingComponent } from "legacy/components/field_encoding_component"
import { be } from "src/helpers/document"
import { AgendaScrollPosition } from "legacy/components/meeting_packs/agenda_scroll_position"

import { getCurrentMeetingPackId } from "../helpers/document"

export default class AgendaItemsEditHandler {
  constructor() {
    this.meetingPackForm = be("meeting-pack-form")
    this.agendaItemDescription = new FroalaTextareaComponent(
      be("agenda-item-description"),
      {
        resetCacheButton: be("agenda-item-form-cancel"),
        saveButton: be("agenda-item-form-submit"),
        analyticsContext: {
          meetingPackId: getCurrentMeetingPackId(),
          agendaItemId: this.meetingPackForm.data("id")
        }
      }
    )
    this.timePicker = new TimePickerComponent()
    this.fieldEncodingComponent = new FieldEncodingComponent({
      originalFieldSelector: '[name="agenda_item[description]"]',
      encodedFieldSelector: '[name="agenda_item[encoded_description]"]'
    })
    this.agendaScrollPosition = new AgendaScrollPosition()
  }

  setup() {
    this.agendaItemDescription.setup()
    this.timePicker.setup()

    this.meetingPackForm.on("ajax:before", () => {
      this.fieldEncodingComponent.replaceMessageBody(this.meetingPackForm[0])
    })
    this.agendaScrollPosition.setupSubmitButton()
  }

  teardown() {
    this.agendaItemDescription.teardown()
    this.timePicker.teardown()
    this.agendaScrollPosition.teardownSubmitButton()
  }
}
