import * as Sentry from "@sentry/browser"

import exportIcon from "images/pspdfkit-export-icon.svg"
import { checkForPSPDFKitUpdate } from "src/features/offline/helpers/pspdfkitUpdate"

const downloadPdf = ({ url, documentName }) => {
  const a = document.createElement("a")

  a.href = url
  a.style.display = "none"
  a.download = documentName
  a.setAttribute("download", documentName)
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export const exportWithNameToolbarButton = ({ instance, documentName }) => {
  return {
    type: "custom",
    className: "custom-button export-button",
    icon: exportIcon,
    id: "export-button",
    onPress: async () => {
      const buffer = await instance.exportPDF({ flatten: true })
      const blob = new Blob([buffer], { type: "application/pdf" })

      const reader = new FileReader()
      reader.onloadend = () => downloadPdf({ url: reader.result, documentName })
      reader.readAsDataURL(blob)
    }
  }
}

export const handlePSPDFKitInitError = (e) => {
  Sentry.captureException(e, { extra: { pspdfkit: "true" } })
  // eslint-disable-next-line no-console
  console.warn(e)

  // When cache isn't cleared, initPSPDFKit fails so we force to clear cache
  if (
    e.message.includes("initPSPDFKit") ||
    e.message.includes("Expected version 1") ||
    e.message.includes("undefined is not an object")
  ) {
    checkForPSPDFKitUpdate({ pspdfkitFailedToLoad: true })
  }
}
