class @GroupsIndexHandler
  constructor: ->
    @groupList = $be('group-list')
    @listFetchPath = @groupList.data('fetchPath')

    @membershipWatcher = new SchemeMembershipWatcherComponent()

    @unreadBadges = new UnreadBadgesComponent(
      'group-card',
      'groupCardId',
      'discussions:updates'
    )

    @groupsViewComponent = new GroupsViewComponent()

    @groupMemberListComponent = new GroupMemberListComponent(
      listContainerBehavior: 'group-card',
      manageEnabled: @groupList.data('manage'),
      contactDetailsEnabled: false
    )

    @addSchemeUsersComponent = new AddSchemeUsersComponent()

    groupMembershipChannel = AblyRealtimeClient.channels.get(
      "groups:membership"
    )
    updatesChannel = AblyRealtimeClient.channels.get(
      "discussions:updates"
    )

    @channelData = [
      {
        channel: groupMembershipChannel,
        subscriber: @handleGroupMembershipChange
      },
      {
        channel: updatesChannel,
        subscriber: @handleUpdatesChannelMessage
      }
    ]
    @copyLinkComponent = new CopyLinkComponent()

  setup: =>
    $setupChannelSubscriptions(@channelData)

    @membershipWatcher.setup()
    @unreadBadges.setup()
    @groupsViewComponent.setup()
    @groupMemberListComponent.setup()
    @addSchemeUsersComponent.setup()
    @copyLinkComponent.setup()

    @bindGroupCards()

  bindGroupCards: =>
    # Safari on iPads (except the latests) triggers "mouseenter" on the first "touchend"
    # and "click" on the second one. We want link to be followed after the first tap.
    if $isIpad()
      $be("group-page-link").on('mouseenter', (e) ->
        e.target.click()
      )

    $be("group-page-link").click (e) ->
      if $(e.target).parent().data('stop-propagation')
        return
      if $(this).is("[disabled]")
        $showFlashMessage('warning', 'You must be a member to visit this group.')
        e.preventDefault()
      else
        Turbo.visit($(this).data('path'))

    pageHandler = this
    $be('show-members-from-group-card').click (e) ->
      e.preventDefault()
      button = $(this)
      memberList = button.siblings('[data-behavior=group-members-list]')
      if memberList.find("[data-behavior='member-avatar']").length
        $.getScript button.data('show-path'), ->
          $be('show-members-modal').modal('show')
      else
        $.getScript button.data('manage-path'), ->
          $be('manage-members-modal').modal('show')


  handleGroupMembershipChange: (channelMessage) =>
    groupModel = new GroupModel(channelMessage.data.group)
    affectedGroup = $beById('group-card', groupModel.id)

    return unless affectedGroup.length &&
      $currentUserId() in channelMessage.data.affectedUserIds
    $.getScript location.toString()

  handleUpdatesChannelMessage: (channelMessage) =>
    data = channelMessage.data

    userIsNotAnAuthor = $currentUserId() != data.authorId
    userIsGroupMember = $currentUserId() in (data?.group?.memberIds || [])

    if userIsNotAnAuthor && userIsGroupMember
      $.getScript @listFetchPath

  showList: (listContent) =>
    @groupList.html(listContent)
    window.layoutHandler.setAvatarImages(@groupList)
    @bindGroupCards()
    @groupMemberListComponent.avatarListComponent.updateAllCounters()

  teardown: =>
    @membershipWatcher.teardown()
    @unreadBadges.teardown()
    @groupMemberListComponent.teardown()
    $teardownChannelSubscriptions(@channelData)
