import React, { forwardRef } from "react"
import { styled } from "@linaria/react"
import { func } from "prop-types"

import { withPropagationStopped } from "src/helpers/events"
import Icon from "src/styles/components/Icon"
import { SPACES } from "src/styles/sizes"

const DropdownToggle = forwardRef((props, ref) => {
  return (
    <button
      {...props}
      ref={ref}
      onClick={withPropagationStopped(props.onClick)}
      data-testid="new-space-toggle"
    >
      <Icon type="tridot" />
    </button>
  )
})

DropdownToggle.propTypes = {
  onClick: func.isRequired
}

export default styled(DropdownToggle)`
  border: none;
  background: none;
  padding: 0 0 0 ${SPACES.xSmall};

  ${Icon} {
    font-size: 0.85em;
    color: #dadada;
  }

  &::after {
    display: none;
  }

  &:focus {
    outline: none;
  }
`
