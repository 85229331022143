import React from "react"
import { startCase } from "lodash"
import { bool, func, number, oneOf, shape } from "prop-types"

import { MEMBERSHIP_TYPES, ROLES } from "src/features/meetingPacks/MeetingPack/constants"
import { displayedMembershipType } from "src/features/meetingPacks/MeetingPack/Attendees/List/ManageUsers/helpers"
import UserAvatar from "src/components/UsersContacts/UserAvatar"
import Auxiliary from "src/styles/components/Card/Auxiliary"
import { MeetingPackMembershipsColumn } from "src/styles/components/Card/List"
import Card, { Mascot } from "src/styles/components/Card/Card"
import { Primary, Secondary, Title } from "src/styles/components/Card/Title"
import Checkbox from "src/styles/components/Checkbox"
import DeletedFromAccountBadge from "src/components/DeletedFromAccountBadge"
import UserAvatarContainer from "src/components/UsersContacts/UserAvatarContainer"
import UserBadge from "src/components/UsersContacts/UserBadge"

import { AccountRoleBadge } from "../styles"
import RoleIcon from "../RoleIcon"

const UserCard = ({
  user,
  isCurrentUser,
  toggleUserItemMembership,
  checkedMembership,
  deletedFromAccount,
  disablePresentMembership = false,
  added = false
}) => {
  return (
    <Card horizontal className="px-4 my-3" data-testid="user-card" data-id={user.id}>
      <Mascot className="mr-2">
        <UserAvatarContainer>
          {added && (
            <UserBadge data-testid="badge" variant="added">
              +
            </UserBadge>
          )}
          <UserAvatar user={user} variant={added ? "added" : null} />
        </UserAvatarContainer>
      </Mascot>
      <Title>
        <Primary>
          {user.fullName} {isCurrentUser ? "(you)" : ""}
        </Primary>
        {deletedFromAccount ? (
          <div>
            <DeletedFromAccountBadge />
          </div>
        ) : (
          <Secondary>{user.jobTitle}</Secondary>
        )}
      </Title>
      <Auxiliary>
        {user.role !== ROLES.member && (
          <AccountRoleBadge role={user.role}>
            <RoleIcon className="mr-1" role={user.role} />
            {startCase(user.role)}
          </AccountRoleBadge>
        )}

        <div className="text-right">{displayedMembershipType(user)}</div>
      </Auxiliary>
      <MeetingPackMembershipsColumn>
        <Checkbox
          id={`user-check-box-${user.id}-present`}
          checked={checkedMembership === MEMBERSHIP_TYPES.present}
          disabled={disablePresentMembership}
          onChange={() =>
            toggleUserItemMembership({
              userId: user.id,
              membershipType: MEMBERSHIP_TYPES.present
            })
          }
        />
        <Checkbox
          id={`user-check-box-${user.id}-access-only`}
          checked={checkedMembership === MEMBERSHIP_TYPES.accessOnly}
          onChange={() =>
            toggleUserItemMembership({
              userId: user.id,
              membershipType: MEMBERSHIP_TYPES.accessOnly
            })
          }
        />
      </MeetingPackMembershipsColumn>
    </Card>
  )
}

UserCard.propTypes = {
  toggleUserItemMembership: func.isRequired,
  user: shape({
    id: number.isRequired,
    membershipType: oneOf(Object.values(MEMBERSHIP_TYPES)).isRequired
  }).isRequired,
  checkedMembership: oneOf([MEMBERSHIP_TYPES.accessOnly, MEMBERSHIP_TYPES.present, null]),
  isCurrentUser: bool,
  deletedFromAccount: bool,
  disablePresentMembership: bool,
  added: bool
}

export default UserCard
