const STORAGE_KEY_PREFIX = "universal-ai-sidebar-scroll-position"

const storageKey = (key) => {
  return `${STORAGE_KEY_PREFIX}/${key}`
}

const saveScrollPosition = ({ key, position }) => {
  sessionStorage.setItem(storageKey(key), position)
}

const getScrollPosition = (key) => {
  return sessionStorage.getItem(storageKey(key)) || 0
}

const scrollPositionHandler = ({ element, restorePosition, key }) => {
  if (!element) return

  if (restorePosition) {
    // Set overflow to hidden to avoid scrollbar blinking when navigating
    // from page to page

    // eslint-disable-next-line no-param-reassign
    element.style.overflowY = "hidden"
    // eslint-disable-next-line no-param-reassign
    element.scrollTop = getScrollPosition(key)
    // eslint-disable-next-line no-param-reassign
    element.style.overflowY = null
  }

  const scrollHandler = (e) => {
    saveScrollPosition({ key, position: e.target.scrollTop })
  }

  element.addEventListener("scroll", scrollHandler)

  return () => element.removeEventListener("scroll", scrollHandler)
}

export default scrollPositionHandler
