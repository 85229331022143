import { captureException } from "@sentry/browser"

import useIsOpened from "src/hooks/useIsOpened"
import { showFlashMessage } from "src/helpers/flash"
import { splitFilename } from "src/helpers/string"

import useRenameFolderItem from "./useRenameFolderItem"

const buildNewName = (folderItem, name) => {
  if (folderItem.type === "folder") return name

  const { extension } = splitFilename(folderItem.name)
  return [name, extension].join(".")
}

const useRenameModal = ({ groupId, folderItem, onComplete }) => {
  const { open, close, isOpened } = useIsOpened()

  const { renameFolderItem, loading: submitting } = useRenameFolderItem()

  const onSubmit = (values, helpers) => {
    const { name } = values
    const { setFieldError } = helpers

    const handleSuccess = ({ successful, message }) => {
      if (successful) {
        showFlashMessage("success", "Item has been renamed successfully")
        close()
        onComplete()
      } else {
        setFieldError("name", message)
      }
    }

    const handleError = (error) => {
      captureException(error)
      showFlashMessage("danger", "Something went wrong, please try again later")
      close()
    }

    renameFolderItem({
      groupId,
      folderItem,
      name: buildNewName(folderItem, name)
    })
      .then(handleSuccess)
      .catch(handleError)
  }

  return { open, close, isOpened, onSubmit, submitting }
}

export default useRenameModal
