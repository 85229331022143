class @GroupModel
  constructor: (groupData) ->
    if (groupData)
      @id = Number(groupData.id)
      @memberIds = groupData.memberIds
      @mutedUsersIds = groupData.mutedUsersIds || []
      @mainSchemeIds = groupData.mainSchemeIds
      @jointSchemeId = groupData.jointSchemeId
      @inJointScheme = !!@jointSchemeId

  isMemberId: (userId) =>
    if @memberIds
      @memberIds.indexOf(userId) > -1

  isMutedFor: (userId) =>
    if @mutedUsersIds
      @mutedUsersIds.indexOf(userId) > -1

  isAccessible: (userId) =>
    @isMemberId(userId)

  allowLeave: (userId) =>
    if @memberIds
      @isMemberId(userId) && @memberIds.length > 1
