import React from "react"

import { useUniversalAiSelector } from "../store"
import { Modes, Commands } from "../constants"

import { Summarise, Query, GenerateDraftMinutes } from "./commandComponents"

const Sidebar = () => {
  const { mode, command, objectId, objectType, objectTitle } = useUniversalAiSelector()

  if (mode !== Modes.Sidebar) {
    return null
  }

  if (command === Commands.SummariseAsText || command === Commands.SummariseAsBullets) {
    return <Summarise objectId={objectId} objectType={objectType} command={command} />
  }

  if (command === Commands.Query) {
    return <Query objectId={objectId} objectType={objectType} objectTitle={objectTitle} />
  }

  if (command === Commands.GenerateDraftMinutes) {
    return <GenerateDraftMinutes objectId={objectId} objectType={objectType} />
  }

  throw new Error("Unknown Universal AI command")
}

export default Sidebar
