import React, { useState } from "react"
import { Dropdown } from "react-bootstrap"
import { useSelector } from "react-redux"
import { number, string, func, shape, arrayOf, bool } from "prop-types"
import { styled } from "@linaria/react"

import { getMeetingPackAttendees } from "src/features/meetingPacks/MeetingPack/selectors"
import { TEXT_COLORS } from "src/styles/colors"
import useConfirmationModal from "src/hooks/useConfirmationModal"

const SpeakerSelect = ({
  utterance,
  onSave,
  groupedUtterancesUids,
  hideAssignSpeakerSelect,
  speakerSelectShown,
  showSpeaker,
  className
}) => {
  const [selectedSpeakerId, setSelectedSpeakerId] = useState()

  const changeAssignedSpeaker = (id) => {
    onSave(
      groupedUtterancesUids.map((uid) => ({ uid, speakerId: id || selectedSpeakerId }))
    )
    hideAssignSpeakerSelect()
  }

  const changeAllAssignedSpeakers = (id) => {
    onSave([{ speaker: utterance.speaker, speakerId: id || selectedSpeakerId }])
    hideAssignSpeakerSelect()
  }

  const changeParagraphSpeaker = (id) =>
    onSave([{ uid: utterance.uid, speakerId: id || selectedSpeakerId }])

  const resetAssignedSpeaker = () =>
    onSave([{ speaker: utterance.speaker, speakerId: null }])

  const { modal: changeSingleSpeakerConfirmation, open: openSingleSpeakerConfirmation } =
    useConfirmationModal({
      onConfirm: changeAssignedSpeaker,
      onCancel: changeAllAssignedSpeakers,
      message:
        "Do you wish to change speaker for this single instance, or for all instances of this speaker?",
      confirmLabel: "Change single",
      cancelLabel: "Change all"
    })
  const meetingPackAttendees = useSelector((state) => getMeetingPackAttendees(state))

  const getAttendeeName = (attendee) => {
    if (!attendee) return ""

    return attendee.fullName?.length ? attendee.fullName : attendee.email
  }

  let speakerName
  if (speakerSelectShown) speakerName = "SPEAKER"
  else if (utterance.speakerId)
    speakerName = getAttendeeName(
      meetingPackAttendees.find((attendee) => attendee.id === utterance.speakerId)
    )
  else speakerName = `SPEAKER ${utterance.speaker || ""}`

  const handleChange = (id) => {
    if (speakerSelectShown && !showSpeaker) hideAssignSpeakerSelect()
    setSelectedSpeakerId(id)

    // if it is first time and utterance do not have assigned Knowa speaker but has default speaker
    //   then change all utterances with such default speaker
    // if utterances has Knowa speaker and has default speaker
    //   then we should ask whether user wants to change speaker for this single case or for all
    // if utterances has Knowa speaker and does not have default speaker
    //   then we need to change to another one for the group of paragraphs below
    // if utterances do not have both, it means it is "Assign speaker" feature
    //   then we change only for this one paragraph
    if (speakerSelectShown && !showSpeaker) changeParagraphSpeaker(id)
    else if (utterance.speaker && !utterance.speakerId) changeAllAssignedSpeakers(id)
    else if (utterance.speaker) openSingleSpeakerConfirmation()
    else if (utterance.speakerId) changeAssignedSpeaker(id)
  }

  return (
    <>
      {changeSingleSpeakerConfirmation}
      <Dropdown className={className} data-test-id="speaker-dropdown">
        <Dropdown.Toggle as="a">{speakerName}</Dropdown.Toggle>
        <Dropdown.Menu>
          {meetingPackAttendees.map((attendee) => (
            <Dropdown.Item
              onClick={() => handleChange(attendee.id)}
              key={attendee.id}
              className="no-wrap"
            >
              {getAttendeeName(attendee)}
            </Dropdown.Item>
          ))}
          {utterance.speakerId && (
            <>
              <Dropdown.Divider />
              <Dropdown.Item onClick={resetAssignedSpeaker}>Reset Speaker</Dropdown.Item>
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}

SpeakerSelect.propTypes = {
  utterance: shape({
    uid: string.isRequired,
    speaker: string,
    speakerId: number
  }).isRequired,
  onSave: func.isRequired,
  groupedUtterancesUids: arrayOf(string).isRequired,
  hideAssignSpeakerSelect: func.isRequired,
  speakerSelectShown: bool.isRequired,
  showSpeaker: bool.isRequired
}

export default styled(SpeakerSelect)`
  .dropdown-toggle {
    font-weight: 600;
    color: ${TEXT_COLORS.heading};
  }
`
