import { dismissAllMessages, showToastMessage } from "src/helpers/toast"

import syncOfflineAnnotations from "./syncOfflineAnnotations"

const disablePageLinks = () => {
  document.querySelector("body").classList.add("offline")
}

const enablePageLinks = () => {
  document.querySelector("body").classList.remove("offline")
}

const setPageAsOffline = () => {
  disablePageLinks()
  showToastMessage({
    duration: 0,
    type: "error",
    message: "You are offline. Some functionality will be unavailable.",
    position: {
      x: "left",
      y: "bottom"
    }
  })
}

const setPageAsOnline = () => {
  dismissAllMessages()
  enablePageLinks()
  syncOfflineAnnotations()
  showToastMessage({
    type: "success",
    message: "You are online.",
    position: {
      x: "left",
      y: "bottom"
    }
  })
}

window.addEventListener("load", () => {
  if (!navigator.onLine) {
    setPageAsOffline()
  }
})

window.addEventListener("turbo:load", () => {
  if (!navigator.onLine) {
    disablePageLinks()
  }
})

window.addEventListener("offline", setPageAsOffline)
window.addEventListener("online", setPageAsOnline)
