import { createSelector } from "@reduxjs/toolkit"
import { sortBy } from "lodash"

import { getAccountOption } from "./helpers"

export const getAccountsAllIds = (state) => state.accounts.allIds
export const getAccountsById = (state) => state.accounts.byId

export const selectAccountOptions = createSelector(
  [getAccountsAllIds, getAccountsById],
  (allIds, byId) =>
    Object.values(
      sortBy(
        allIds.map((id) => getAccountOption(byId[id])),
        "label"
      )
    )
)
