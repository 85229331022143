class @MessageActionComponent
  constructor: (discussionMessagesComponent) ->
    @discussionMessagesComponent = discussionMessagesComponent
    @messageActionService = new MessageActionService()

  setup: () ->
    @discussionMessagesComponent.discussionContainer.on('click', (e) =>
      return unless e.target.dataset.behavior == 'message-action'

      e.preventDefault()

      actionLink = $(e.target)

      if actionLink.data('action-url')
        @messageActionService.performAction(
          e.target.dataset.actionUrl,
          e.target.dataset.actionPayload
          e.target.dataset.actionComplete
        ).done((response) =>
          actionLink
            .parents('.message-actionable-panel')
            .replaceWith(response.actionable_panel_content)
        ).fail((error) =>
          $showFlashMessage('danger', error.responseJSON.error)
        )
    )
