import { createSlice } from "@reduxjs/toolkit"

import { setIsLoading, setFailure, appendCollection } from "src/helpers/slice"

const initialState = {
  allIds: [],
  byId: {},
  isLoading: false,
  error: null
}

const slice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    fetchGroupsRequest: setIsLoading,
    fetchGroupsFailure: setFailure,
    fetchGroupsSuccess: appendCollection
  }
})

const { actions, reducer } = slice

export const { fetchGroupsFailure, fetchGroupsRequest, fetchGroupsSuccess } = actions

export default reducer
