class @AgendaItemsShowHandler
  constructor: ->
    @agendaItemMembersModalsComponent = new AgendaItemMembersModalsComponent()
    @sortable = new SortableComponent()
    @agendaScrollPosition = new AgendaScrollPosition()

  setup: =>
    @agendaItemMembersModalsComponent.setup()
    @sortable.setup()
    @agendaScrollPosition.setupBackButton()

  teardown: =>
    @agendaItemMembersModalsComponent.teardown()
    @sortable.teardown()
    @agendaScrollPosition.teardownBackButton()

  showManageList: (listContent) =>
    if listContent.length
      @agendaItemMembersModalsComponent.showManageList(listContent)

  showList: (listContent) =>
    @agendaItemMembersModalsComponent.showList(listContent)

  setAgendaItemMembers: (agendaItemMembersHtml) =>
    @agendaItemMembersModalsComponent.setAgendaItemMembers(agendaItemMembersHtml)
