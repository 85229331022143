import { styled } from "@linaria/react"

import { SPACES, BREAKPOINTS } from "src/styles/sizes"

import { SidebarWrapper } from "./ContentsSidebar/styles"
import { ContentContainer } from "./Content/styles"

export const ViewContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${SPACES.xSmall};
  overflow: hidden;

  flex-direction: row;
  -webkit-overflow-scrolling: touch;
  flex: 1 1 auto;
  height: 0;

  &.sidebar-opened.full-width ${SidebarWrapper} {
    width: calc(100% - 10px);
  }

  &.sidebar-opened ${SidebarWrapper} {
    width: 440px;
  }

  @media (max-width: ${BREAKPOINTS.medium}) {
    &.sidebar-opened ${SidebarWrapper} {
      width: 400px;
    }
  }

  @media (min-width: ${BREAKPOINTS.xLarge}) {
    &.sidebar-opened ${SidebarWrapper} {
      min-width: 480px;
    }
  }

  @media (min-width: ${BREAKPOINTS.xSmall}) and (max-width: ${BREAKPOINTS.small}) {
    &.sidebar-opened ${SidebarWrapper} {
      width: calc(100% - 10px);
    }

    &.sidebar-opened ${ContentContainer} {
      display: none;
    }
  }
`
