class @StarredItemsService
  getStarredItems: (itemType, page) ->
    $.get('/starred', { item_type: itemType, page: page })

  starMessage: (messageId) ->
    @starItem(messageId, 'message')

  unstarMessage: (messageId) ->
    @unstarItem(messageId, 'message')

  starItem: (itemId, itemType) ->
    $.ajax({
      url: '/starred',
      type: 'POST',
      data: { item_id: itemId, item_type: itemType }
    })

  unstarItem: (itemId, itemType) ->
    $.ajax({
      url: '/starred',
      type: 'DELETE',
      data: { item_id: itemId, item_type: itemType }
    })
