import React, { useRef, useState, useEffect } from "react"
import { shape, number, arrayOf, string, bool, oneOfType } from "prop-types"
import { useMutation } from "@apollo/client"

import { showFlashMessage } from "src/helpers/flash"
import { be } from "src/helpers/document"

import AccountMembers from "./AccountMembers"
import updateMembershipsMutation from "./groupUpdateMembership.gql"

const ManageMembers = ({ group, groupedUsers, usersRoles }) => {
  const [confirmDisabled, setConfirmDisabled] = useState(true)
  const [selectedUserIds, setSelectedUserIds] = useState({})
  const [isChanged, setChanged] = useState(false)

  const [updateMemberships] = useMutation(updateMembershipsMutation)

  const onChangeSelected = (accountId, selected) => {
    setSelectedUserIds((stateValue) => {
      return { ...stateValue, [accountId]: selected }
    })
  }

  useEffect(() => {
    if (isChanged) setConfirmDisabled(false)
  }, [isChanged])

  const closeModal = useRef()

  const submit = () => {
    const userIds = [...new Set(Object.values(selectedUserIds).flat())]
    updateMemberships({ variables: { userIds, groupId: group.id } }).then(() => {
      be("manage-members-modal").modal("hide")
      be("show-members-modal").modal("hide")
      showFlashMessage("success", "Group membership successfully updated.")
    })
  }

  return (
    <>
      <div className="modal-content-header">
        <div>
          <h5 className="modal-title d-inline">Manage People</h5>
          <a
            data-toggle="modal"
            data-dismiss="modal"
            data-target="[data-behavior='show-members-modal']"
            href={`/groups/${group.id}/membership`}
            className="mls"
          >
            View People
          </a>
        </div>
        <button className="close" type="button" aria-label="close" data-dismiss="modal">
          <span aria-hidden> &times;</span>
        </button>
      </div>
      <div className="modal-content-container">
        {groupedUsers.map((accountSection) => (
          <AccountMembers
            accountSection={accountSection}
            onChangeSelected={onChangeSelected}
            setModified={setChanged}
            usersRoles={usersRoles}
            key={accountSection.accountId}
          />
        ))}
      </div>
      <div className="modal-content-footer align-horizontally-center">
        <button
          className={`${confirmDisabled ? "disabled" : ""} button`}
          disabled={confirmDisabled}
          onClick={submit}
        >
          Confirm
        </button>
        <button ref={closeModal} className="is-secondary button" data-dismiss="modal">
          Cancel
        </button>
      </div>
    </>
  )
}

ManageMembers.propTypes = {
  group: shape({
    id: number.isRequired
  }).isRequired,
  groupedUsers: arrayOf(
    shape({
      accountName: string.isRequired,
      isJointAccount: bool,
      users: arrayOf(
        arrayOf(
          oneOfType([
            bool,
            arrayOf(
              shape({
                id: number.isRequired,
                fullName: string.isRequired
              })
            )
          ])
        )
      )
    })
  ),
  usersRoles: shape({}).isRequired
}

export default ManageMembers
