import React, { useContext } from "react"
import { Modal } from "react-bootstrap"

import { ACTION_TYPES, ACTION_CLOSED_TYPES } from "../constants"
import { OptionContainer } from "../styles"
import NewMessageActionContext from "../NewMessageActionContext"
import { DateInputSection, NumberInputSection } from "../Shared"

import Context from "./Context"
import Option from "./Option"

const DESCRIPTION_ACTIONS = {
  [ACTION_TYPES.voting]: "the vote",
  [ACTION_TYPES.must_read]: "this must read action"
}

const NUMBER_LABEL_ACTIONS = {
  [ACTION_TYPES.voting]: "number of votes in agreement",
  [ACTION_TYPES.must_read]: "number read"
}

const DEFAULT_LABEL_ACTIONS = {
  [true]: "the vote has been collected",
  [false]: "all votes have been collected"
}

const Body = ({ className }) => {
  const { message } = useContext(NewMessageActionContext)
  const {
    selectedAction,
    selectedDate,
    setSelectedDate,
    selectedNumber,
    setSelectedNumber
  } = useContext(Context)

  const showNumberOption =
    message.actionType !== ACTION_TYPES.voting || message.actionsCount > 1

  const showDefaultOption = message.actionType !== ACTION_TYPES.must_read

  return (
    <Modal.Body className={className}>
      <p>
        Please select how you&apos;d like to close{" "}
        {DESCRIPTION_ACTIONS[message.actionType]}:
      </p>

      <OptionContainer>
        <Option
          id="new_message_actions_option_date"
          label="Close on a date"
          action={ACTION_CLOSED_TYPES.date}
        />
        {selectedAction === ACTION_CLOSED_TYPES.date && (
          <DateInputSection
            actionType={message.actionType}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
        )}

        {showNumberOption && (
          <>
            <Option
              id="new_message_actions_option_number"
              label={`Close after ${NUMBER_LABEL_ACTIONS[message.actionType]}`}
              action={ACTION_CLOSED_TYPES.number}
            />
            {selectedAction === ACTION_CLOSED_TYPES.number && (
              <NumberInputSection
                actionType={message.actionType}
                selectedNumber={selectedNumber}
                setSelectedNumber={setSelectedNumber}
                maxNumber={message.actionsCount}
              />
            )}
          </>
        )}

        {showDefaultOption && (
          <Option
            id="new_message_actions_option_default"
            label={`Close once ${DEFAULT_LABEL_ACTIONS[message.actionsCount === 1]}`}
            action={null}
          />
        )}
      </OptionContainer>
    </Modal.Body>
  )
}

export default Body
