import { Form } from "react-bootstrap"
import Select from "react-select"
import React, { useState } from "react"
import { arrayOf, bool, func, number, shape, string } from "prop-types"

import { FILE_TYPE_OPTIONS } from "../constants"
import { Filter } from "../styles"

import ExtendedControl from "./ExtendedControl"
import DatePickers from "./DatePickers"
import useFilters from "./useFilters"

const AttachmentFilter = ({ setFilters, participants, opened, toggle, visible }) => {
  const [filterFileType, setFilterFileType] = useState(null)
  const {
    filterText,
    filterAuthor,
    dateFrom,
    dateTo,
    setFilterText,
    setFilterAuthor,
    setDateFrom,
    setDateTo,
    clearCommonFilters,
    authorOptions
  } = useFilters({
    participants,
    setFilters,
    otherFilterObj: { type: filterFileType }
  })

  const clearFilters = () => {
    clearCommonFilters()
    setFilterFileType(null)
  }

  const handleFileTypeChange = ({ value }) => {
    setFilterFileType(value)
  }

  return (
    <Filter className={`${visible ? "" : "hidden"}`}>
      <Form.Control
        value={filterText}
        placeholder="Filter by filename or person"
        onChange={({ target }) => setFilterText(target.value)}
      />
      <ExtendedControl opened={opened} toggle={toggle} clear={clearFilters} />

      {opened && (
        <div className="mts">
          <div className="dropdown-pickers">
            <Select
              className="mb-2"
              onChange={({ value }) => setFilterAuthor(value)}
              options={authorOptions}
              value={authorOptions.find((option) => option.value === filterAuthor)}
              isSearchable={false}
            />
            <Select
              onChange={handleFileTypeChange}
              options={FILE_TYPE_OPTIONS}
              value={FILE_TYPE_OPTIONS.find((option) => option.value === filterFileType)}
              isSearchable={false}
            />
          </div>
          <DatePickers
            dateFrom={dateFrom}
            dateTo={dateTo}
            setDateFrom={setDateFrom}
            setDateTo={setDateTo}
          />
        </div>
      )}
    </Filter>
  )
}

AttachmentFilter.propTypes = {
  setFilters: func.isRequired,
  toggle: func.isRequired,
  opened: bool.isRequired,
  visible: bool.isRequired,
  participants: arrayOf(
    shape({
      id: number.isRequired,
      firstName: string.isRequired,
      lastName: string.isRequired
    })
  )
}

export default AttachmentFilter
