import { captureException } from "@sentry/browser"

import { showFlashMessage } from "src/helpers/flash"

import useSystemFilePicker from "./useSystemFilePicker"
import useRequestDownloadUrl from "./useRequestDownloadUrl"

const useToolbar = ({ groupId, currentFolderId, selectedFolderItems, onSelectFiles }) => {
  const onUploadFiles = useSystemFilePicker(onSelectFiles)
  const onUploadFolders = useSystemFilePicker(onSelectFiles, { directory: true })
  const requestDownloadUrl = useRequestDownloadUrl()

  const onDownload = () => {
    showFlashMessage("warning", "Download will start shortly")

    const folderItems =
      selectedFolderItems.length === 0
        ? [{ id: currentFolderId, type: "folder" }]
        : selectedFolderItems

    requestDownloadUrl({ groupId, folderItems })
      .then((downloadUrl) => {
        const link = document.createElement("a")
        link.download = "download.zip"
        link.href = downloadUrl
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
      .catch((error) => {
        captureException(error)
        showFlashMessage("danger", "Something went wrong, try again later")
      })
  }

  return { onUploadFiles, onUploadFolders, onDownload }
}

export default useToolbar
