import React from "react"
import { styled } from "@linaria/react"
import { bool, string } from "prop-types"
import classNames from "classnames"
import { capitalize } from "lodash"

import { cardBorderRadius, SPACES } from "src/styles/sizes"
import { Column } from "src/components/AttandeesTable"
import { accountAdminColor, accountGuestColor, BASE_COLORS } from "src/styles/colors"

const roleClasses = {
  admin: "admin",
  guest: "guest",
  accountAdmin: "accountAdmin"
}

const RoleColumn = ({ className, role, roleLabel, centered }) => {
  return (
    <Column className={className}>
      {role && (
        <span
          className={classNames(
            "column-role",
            roleClasses[role],
            centered ? "centered" : null
          )}
        >
          {roleLabel || capitalize(role)}
        </span>
      )}
    </Column>
  )
}

RoleColumn.propTypes = {
  role: string,
  roleLabel: string,
  centered: bool
}

export default styled(RoleColumn)`
  .column-role {
    display: block;
    width: fit-content;
    padding: ${SPACES.xxSmall} ${SPACES.xSmall};
    border-radius: ${cardBorderRadius};

    &.admin {
      background-color: ${accountAdminColor};
    }

    &.guest {
      background-color: ${accountGuestColor};
    }

    &.accountAdmin {
      background-color: ${BASE_COLORS.lightOrange};
    }

    &.centered {
      margin: auto;
    }
  }
`
