import React from "react"
import { useSelector } from "react-redux"
import { bool, func, oneOf } from "prop-types"

import useComponentUnmount from "src/hooks/useComponentUnmount"

import PreviewContainer from "./PreviewContainer"
import PreviewModal from "./PreviewModal"
import useDocumentPreview from "./useDocumentPreview"

export const Modes = {
  Modal: "modal",
  Embedded: "embedded"
}

// Ideally, all props must come from single source, however, it's hard to
// achieve in the current state of our code (e.g. combination of React components
// and Rails views). We may want to refactor it in the future.
const DocumentPreview = ({
  mode = Modes.Modal,
  sidebarEnabled = false,
  toolbarButtonsFactory = () => []
}) => {
  const { close } = useDocumentPreview()
  const { opened, document, collection } = useSelector((state) => state.documentPreview)

  useComponentUnmount(close)

  if (!opened) {
    return null
  }

  const embeddedMode = mode === Modes.Embedded
  const PreviewComponent = embeddedMode ? PreviewContainer : PreviewModal

  return (
    <PreviewComponent
      document={document}
      collection={collection}
      onClose={close}
      sidebarEnabled={sidebarEnabled}
      toolbarButtonsFactory={toolbarButtonsFactory}
    />
  )
}

export const PropTypes = {
  mode: oneOf(Object.values(Modes)),
  sidebarEnabled: bool,
  toolbarButtonsFactory: func
}

DocumentPreview.propTypes = PropTypes

export default DocumentPreview
