import React from "react"
import { instanceOf, number, oneOf, oneOfType, string } from "prop-types"

import { formatDate } from "src/helpers/datetime"
import { ACTION_TYPES } from "src/features/Messages/ActionDetails/constants"

const DateConfirmation = ({ actionType, value }) => {
  const time = `11:59pm, ${formatDate(value)}`
  const text =
    actionType === ACTION_TYPES.voting
      ? "This vote will be closed to further votes"
      : "This must read action will close"

  return (
    <>
      {text} at <strong>{time}</strong>.
    </>
  )
}

DateConfirmation.propTypes = {
  value: oneOfType([string, number, instanceOf(Date)]),
  actionType: oneOf(Object.keys(ACTION_TYPES)).isRequired
}

export default DateConfirmation
