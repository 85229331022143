import { uniq } from "lodash"

import { BOX_API_HOST, BOX_ROOT_FOLDER_ID } from "src/constants"
import boxApi from "src/resources/box/api"
import { be, beById, getCurrentThread } from "src/helpers/document"
import { preventDefault } from "src/helpers/events"
import { showFlashMessage } from "src/helpers/flash"
import { rewriteSessionEndpoints, rewriteUploadInterceptor } from "src/helpers/box"

export class AttachDocumentComponent {
  constructor(params) {
    this.setup = this.setup.bind(this)
    this.teardown = this.teardown.bind(this)
    this.setupPickerListeners = this.setupPickerListeners.bind(this)
    this.setupUploaderListeners = this.setupUploaderListeners.bind(this)
    this.show = this.show.bind(this)
    this.showMessage = this.showMessage.bind(this)
    this.hideMessage = this.hideMessage.bind(this)
    this.showPicker = this.showPicker.bind(this)
    this.hidePicker = this.hidePicker.bind(this)
    this.showUploader = this.showUploader.bind(this)
    this.hideUploader = this.hideUploader.bind(this)
    this.activateTab = this.activateTab.bind(this)
    this.clearAttachedDocuments = this.clearAttachedDocuments.bind(this)
    this.appendAttachedDocument = this.appendAttachedDocument.bind(this)
    this.removeAttachedDocument = this.removeAttachedDocument.bind(this)
    this.bindAttachedDocumentCardActions = this.bindAttachedDocumentCardActions.bind(this)
    this.attachFiles = this.attachFiles.bind(this)
    this.showCopyingAttachments = this.showCopyingAttachments.bind(this)
    this.hideCopyingAttachments = this.hideCopyingAttachments.bind(this)
    this.addAttachedDocument = this.addAttachedDocument.bind(this)
    this.documentsAreAttached = this.documentsAreAttached.bind(this)
    this.loadAutosavedDocuments = this.loadAutosavedDocuments.bind(this)
    this.autosavedMessageComponent = params.autosavedMessageComponent
    this.documentPreviewComponent = params.documentPreviewComponent
    this.attachCallback = params.attachCallback

    this.picker = new window.Box.FilePicker()
    this.uploader = new window.Box.ContentUploader()

    this.threadId = params.thread.id
    if (params.thread.type === "Discussion") {
      this.boxFoldersService = new window.DiscussionBoxFoldersService(this.threadId)
    } else if (params.thread.type === "Chat") {
      this.boxFoldersService = new window.ChatBoxFoldersService(this.threadId)
    }

    this.boxAuthService = new window.BoxAuthService()

    this.attachedDocumentsList = be("attached-documents-list")
    this.attachedDocumentsData = be("attached-documents-data")

    this.attachDocumentModal = be("attach-document-modal")
    this.pickerElement = be("file-picker")
    this.uploaderElement = be("file-uploader")

    this.tabs = be("attach-file-menu")
    this.uploadTab = beById("attach-file-menu-item", "upload-files")
    this.browseTab = beById("attach-file-menu-item", "browse-group-files")

    this.showMobileAttachDocument = be("show-mobile-attach-document")

    this.loaderMessage = be("loader-message")
    this.loaderMessageText = be("loader-message-text")
  }

  setup() {
    this.setupUploaderListeners()

    this.attachDocumentModal.on("shown.bs.modal", () => {
      this.setupPickerListeners()

      this.showMessage(
        `<p>Loading secure attachment files, please wait.</p>
        <p>If this is a new discussion or chat, it can take up to a minute.</p>`
      )
      this.tabs.hide()

      this.boxFoldersService.fetchFolders((data) => {
        if (!data.error) {
          this.uploadsFolderId = data.uploads_folder_id

          this.tabs.show()
          this.hideMessage()

          this.showUploader()
          this.hidePicker()
          this.activateTab(this.uploadTab)
        } else {
          this.showMessage(data.error)
        }
      })
    })

    this.attachDocumentModal.on("hidden.bs.modal", () => {
      this.picker.hide() // to cleanup selected files
    })

    this.uploadTab.click((e) => {
      e.preventDefault()
      this.activateTab(e.target)
      this.hidePicker()
      this.showUploader()
    })

    this.browseTab.click((e) => {
      e.preventDefault()
      this.activateTab(e.target)
      this.hideUploader()
      this.showPicker()
    })

    be("close-attach-document-modal").click((e) => {
      e.preventDefault()
      this.attachDocumentModal.modal("hide")
    })

    this.showMobileAttachDocument.click(preventDefault)
  }

  teardown() {
    if (this.attachDocumentModal.hasClass("show")) {
      this.picker.hide()
      this.uploader.hide()
      this.attachDocumentModal.modal("hide")
    }
  }

  setupPickerListeners() {
    const thread = getCurrentThread()

    this.picker.addListener("choose", (items) => {
      this.showCopyingAttachments()
      this.attachDocumentModal.modal("hide")

      boxApi
        .copyToDiscussion({
          selectedFiles: items.map(({ id }) => ({ id })),
          targetFolderId: thread.boxFolderId
        })
        .then(({ data }) => {
          if (data?.copiedFiles) {
            this.hideCopyingAttachments()
            this.attachFiles(data.copiedFiles)
          }
        })
        .catch(({ response }) => {
          if (response.data?.error) {
            this.hideCopyingAttachments()
            showFlashMessage("danger", response.data?.error)
          }
        })
    })

    this.picker.addListener("cancel", () => {
      this.attachDocumentModal.modal("hide")
    })
  }

  setupUploaderListeners() {
    this.uploader.addListener("complete", (items) => {
      this.attachFiles(items)
      this.attachDocumentModal.modal("hide")
    })

    this.uploader.addListener("close", () => {
      this.attachDocumentModal.modal("hide")
    })

    this.uploader.addListener("error", () => {
      this.attachDocumentModal.modal("hide")
      showFlashMessage(
        "warning",
        "Oops, it looks like something went wrong. Please try to attach the file again."
      )
    })
  }

  show() {
    this.attachDocumentModal.modal("show")
  }

  showMessage(errorText) {
    this.uploaderElement.hide()
    this.pickerElement.hide()
    this.loaderMessageText.html(errorText)
    this.loaderMessage.show()
  }

  hideMessage() {
    this.loaderMessage.hide()
  }

  showPicker() {
    this.picker.clearCache()
    this.picker.show(BOX_ROOT_FOLDER_ID, this.boxAuthService.fetchToken, {
      apiHost: BOX_API_HOST,
      container: "[data-behavior=file-picker]",
      chooseButtonLabel: "Attach",
      canCreateNewFolder: false,
      canSetShareAccess: false,
      canUpload: false,
      sortBy: "date",
      sortDirection: "DESC",
      isTouch: true
    })
    this.picker.getComponent().fetchFolder(this.uploadsFolderId)
    this.pickerElement.show()
  }

  hidePicker() {
    this.pickerElement.hide()
  }

  showUploader() {
    this.uploader.show(this.uploadsFolderId, this.boxAuthService.fetchToken, {
      apiHost: BOX_API_HOST,
      container: "[data-behavior=file-uploader]",
      isTouch: true,
      onClose: () => this.attachDocumentModal.modal("hide"),
      requestInterceptor: rewriteUploadInterceptor,
      responseInterceptor: rewriteSessionEndpoints
    })
    this.uploaderElement.show()
  }

  hideUploader() {
    this.uploaderElement.hide()
  }

  activateTab(element) {
    be("attach-file-menu-item").removeClass("active")
    $(element).addClass("active")
  }

  clearAttachedDocuments() {
    this.attachedDocumentsList.html("")
    this.attachedDocumentsData.val("")
  }

  appendAttachedDocument(docsData, newDocData, docCard) {
    const alreadyAddedDoc = docsData.find((d) => d.id === newDocData.id)

    if (alreadyAddedDoc) {
      return
    }

    this.attachedDocumentsList.append(docCard)
    this.autosavedMessageComponent.addDocument(newDocData, docCard[0].outerHTML)
    this.attachedDocumentsData.val(JSON.stringify(docsData.concat(newDocData)))
    this.bindAttachedDocumentCardActions(docCard)
  }

  removeAttachedDocument(id, behavior) {
    const card = beById(behavior, id)

    card.addClass("document-card--is-deleting")
    boxApi
      .deleteFile(id)
      .catch((error) => {
        if (error?.response?.status === 404) {
          return Promise.resolve()
        }

        return Promise.reject(error)
      })
      .then(() => {
        const docsJson = this.attachedDocumentsData.val()
        if (docsJson) {
          const docsData = JSON.parse(docsJson)
          this.attachedDocumentsData.val(
            JSON.stringify(docsData.filter((d) => !(d.id === id)))
          )
        }
        this.autosavedMessageComponent.removeDocument(id)
        beById(card.data("behavior"), id).remove()
      })
      .catch(() => {
        card.removeClass("document-card--is-deleting")
      })
  }

  bindAttachedDocumentCardActions(docCard) {
    // 2 lists, bind on card in each list
    const { id, behavior } = docCard.data()
    const cards = beById(behavior, id)

    cards.find("[data-behavior=remove-attached-document]").click((e) => {
      e.preventDefault()
      this.removeAttachedDocument(id, behavior)
    })

    cards.find("[data-behavior=preview-attached-document]").click((e) => {
      e.preventDefault()

      const card = $(e.target).parents("[data-behavior=attached-document-card-instance]")
      const allFiles = be("attached-document-card-instance")
        .map((i, c) => ({
          id: $(c).data("id"),
          filename: $(c).data("filename")
        }))
        .toArray()

      const uniqAllFiles = uniq(allFiles.map((file) => file.id))

      this.documentPreviewComponent.show(
        card.data("id"),
        card.data("filename"),
        uniqAllFiles
      )
    })
  }

  attachFiles(fileItems) {
    fileItems.forEach((fileItem) => {
      const card = be("attached-document-card-template").clone()
      card.attr("data-id", fileItem.id)
      card.attr("data-filename", fileItem.name)
      card.attr("data-behavior", "attached-document-card-instance")
      card.find("[data-behavior=attached-document-name]").html(fileItem.name)
      this.addAttachedDocument(card)
    })

    this.attachCallback()
  }

  showCopyingAttachments() {
    const card = be("copying-document-card-template").clone()
    card.attr("data-behavior", "copying-document-card-instance")

    this.attachedDocumentsList.append(card)
  }

  hideCopyingAttachments() {
    be("copying-document-card-instance").remove()
  }

  addAttachedDocument(card) {
    const docData = {
      id: card.data("id"),
      filename: card.data("filename")
    }
    const docsJson = this.attachedDocumentsData.val()
    if (docsJson) {
      const docsData = JSON.parse(docsJson)
      this.appendAttachedDocument(docsData, docData, card)
    } else {
      this.appendAttachedDocument([], docData, card)
    }
  }

  documentsAreAttached() {
    const docData = this.attachedDocumentsData.val()
    return docData && JSON.parse(docData).length !== 0
  }

  loadAutosavedDocuments() {
    const docsDataJson = this.autosavedMessageComponent.getDocumentsDataJson()
    const docsCards = this.autosavedMessageComponent.getDocumentCards()

    if (docsDataJson && docsCards) {
      this.attachedDocumentsData.val(docsDataJson)

      docsCards.forEach((docCard) => {
        this.attachedDocumentsList.append($(docCard))
        this.bindAttachedDocumentCardActions($(docCard))
      })
    }
  }
}
