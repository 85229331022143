import React from "react"
import { styled } from "@linaria/react"

const TwoCirclesLoader = ({ className }) => {
  return <div className={className} />
}

export default styled(TwoCirclesLoader)`
  position: absolute;
  width: 2.5vh;
  height: 2.5vh;
  top: 50%;
  left: 50%;
  margin-top: -1.25vh;
  margin-left: -1.25vh;
  perspective: 60vh;

  &:before,
  &:after {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  &:before {
    left: -1.666666666666667vh;
    background: linear-gradient(135deg, #fccf31, rgba(245, 85, 85, 0.95));
    transform: translateZ(0vh);
    z-index: 1;
    -webkit-animation: rotation1 1.5s ease-out infinite;
    animation: rotation1 1.5s ease-out infinite;
  }
  &:after {
    right: -1.666666666666667vh;
    background: linear-gradient(135deg, #e2b0ff, rgba(159, 68, 211, 0.95));
    transform: translateZ(0vh);
    z-index: 1;
    -webkit-animation: rotation2 1.5s ease-out infinite;
    animation: rotation2 1.5s ease-out infinite;
  }

  @-webkit-keyframes rotation1 {
    25% {
      left: 0;
      transform: translateZ(-10vh);
    }
    50% {
      left: 2vh;
      transform: translateZ(0vh);
    }
    75% {
      left: 0;
      transform: translateZ(20vh);
      z-index: 2;
    }
  }
  @keyframes rotation1 {
    25% {
      left: 0;
      transform: translateZ(-10vh);
    }
    50% {
      left: 2vh;
      transform: translateZ(0vh);
    }
    75% {
      left: 0;
      transform: translateZ(20vh);
      z-index: 2;
    }
  }
  @-webkit-keyframes rotation2 {
    25% {
      right: 0;
      transform: translateZ(20vh);
      z-index: 2;
    }
    50% {
      right: 2vh;
      transform: translateZ(0vh);
    }
    75% {
      right: 0;
      transform: translateZ(-10vh);
    }
  }
  @keyframes rotation2 {
    25% {
      right: 0;
      transform: translateZ(20vh);
      z-index: 2;
    }
    50% {
      right: 2vh;
      transform: translateZ(0vh);
    }
    75% {
      right: 0;
      transform: translateZ(-10vh);
    }
  }
`
