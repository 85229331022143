import React from "react"
import { Spinner } from "react-bootstrap"
import { arrayOf, bool, number, shape, string } from "prop-types"

import { FilesList, FileRow } from "./styles"

const UploadedFilesList = ({ files }) => {
  return files.length ? (
    <FilesList>
      {files.map(({ file, isUploading }) => {
        return (
          <FileRow key={`${file.name}${file.size}`} data-testid="file-row">
            <div className="file-title">{file.name}</div>

            {isUploading && <Spinner animation="grow" size="sm" />}
          </FileRow>
        )
      })}
    </FilesList>
  ) : null
}

UploadedFilesList.propTypes = {
  files: arrayOf(
    shape({
      file: shape({
        name: string.isRequired,
        size: number
      }),
      isUploading: bool
    })
  ).isRequired
}

export default UploadedFilesList
