import setActiveSidebarMode from "../setActiveSidebarMode"

// eslint-disable-next-line
import thumbnailsIcon from "./thumbnailsIcon.svg?raw"

export default (instance) => {
  return {
    type: "custom",
    className: "custom-button thumbnails-button",
    id: "thumbnails-button",
    icon: thumbnailsIcon,
    title: "thumbnails",
    mediaQueries: ["(min-width: 728px)"],
    onPress() {
      setActiveSidebarMode({
        instance,
        buttonClass: "thumbnails-button",
        sidebarMode: window.PSPDFKit.SidebarMode.THUMBNAILS
      })
    }
  }
}
