import { beById } from "src/helpers/document"

export const isMeetingPackCreate = (path) => path.match(/\/meeting_packs\/\d+$/)

export const isHomeMeetings = (path) => path.startsWith("/meetings")

export const onSameDiscussionPage = (discussionId) => {
  return discussionId && beById("discussion-page", discussionId).length > 0
}

export const onSameChatPage = (chatId) => {
  return chatId && beById("chat-page", chatId).length > 0
}

export const isAccountManagePage = (path, accountId) => {
  const match = path.match(/^\/accounts\/(\d+)\/.+$/)
  if (!match) return false

  return Number(match[1]) === accountId
}
