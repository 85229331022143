import { useState } from "react"

export default function useUsersList(initialUsers) {
  const [users, setUsers] = useState(initialUsers)

  const setUserField = ({ id, field, value }) => {
    setUsers((actualUsers) =>
      actualUsers.map((user) => {
        if (user.id !== id) {
          return user
        }

        return {
          ...user,
          [field]: value
        }
      })
    )
  }

  const deleteUser = ({ id }) => {
    setUsers((actualUsers) => actualUsers.filter((user) => user.id !== id))
  }

  return {
    users,
    deleteUser,
    setUserField
  }
}
