import React from "react"
import { number } from "prop-types"

import Title from "src/features/ManageAccount/AirtableApps/Title"
import Form from "src/features/ManageAccount/AirtableApps/Form"
import { manageApps as manageAppsRoute } from "src/features/ManageAccount/routes"
import useLayoutHeader from "src/hooks/useLayoutHeader"

const NewApp = ({ accountId }) => {
  useLayoutHeader()

  return (
    <div className="fluid-container">
      <Title text="New Application" />
      <Form accountId={accountId} backRoute={manageAppsRoute(accountId)} />
    </div>
  )
}

NewApp.propTypes = {
  accountId: number.isRequired
}

export default NewApp
