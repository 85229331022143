import React from "react"
import { bool, func, string } from "prop-types"
import { styled } from "@linaria/react"

import ContentLoader from "src/styles/components/ContentLoader"

const LoadMore = ({ loading, refPaginationTrigger, entitiesPresence, className }) => {
  return (
    <>
      {!loading && entitiesPresence && (
        <div ref={refPaginationTrigger} className={className} />
      )}

      {loading && <ContentLoader />}
    </>
  )
}

LoadMore.propTypes = {
  className: string.isRequired,
  loading: bool.isRequired,
  refPaginationTrigger: func.isRequired,
  entitiesPresence: bool.isRequired
}

export default styled(LoadMore)`
  height: 20px;
`
