import { styled } from "@linaria/react"
import { Modal } from "react-bootstrap"

import { BREAKPOINTS } from "src/styles/sizes"

export { default as Body } from "./Body"
export { default as Footer } from "./Footer"
export { default as Header } from "./Header"
export { default as Title } from "./Title"

export const StyledModal = styled(Modal)`
  .modal-content {
    overflow: visible;
    min-height: min-content;
    max-height: max-content;
  }
`

export const WideModal = styled(Modal)`
  .modal-dialog {
    @media (min-width: ${BREAKPOINTS.xSmall}) {
      max-width: 650px;
    }
  }
`

export const XWideModal = styled(Modal)`
  .modal-dialog {
    @media (min-width: ${BREAKPOINTS.small}) {
      max-width: 900px;
    }
  }
`

export const FullScreenModal = styled(Modal)`
  .modal-dialog {
    @media (min-width: ${BREAKPOINTS.small}) {
      max-width: 90%;
      width: 90%;
      height: 90%;
    }
  }
`

export default StyledModal
