import React from "react"
import { styled } from "@linaria/react"
import { bool } from "prop-types"
import { Row as BootstrapRow } from "react-bootstrap"

import { composeVariationsClasses } from "src/helpers/linaria"
import { SPACES, FONT_SIZES, FONT_WEIGHTS } from "src/styles/sizes"
import { TEXT_COLORS, meetingPackDisabledSidebarItemColor } from "src/styles/colors"

import { Index, ItemName, BreakName, BreakLine } from "./styles"

const ContentsRow = ({
  className,
  disabled,
  active,
  small,
  breakRow,
  unread,
  ...rest
}) => {
  const classes = composeVariationsClasses(className, {
    disabled,
    active,
    small,
    breakRow,
    unread
  })

  return <BootstrapRow className={classes} {...rest} />
}

ContentsRow.propTypes = {
  disabled: bool,
  active: bool,
  breakRow: bool,
  unread: bool,
  small: bool
}

export default styled(ContentsRow)`
  margin-top: ${SPACES.small};
  font-size: ${FONT_SIZES.medium};
  font-weight: ${FONT_WEIGHTS.bold};
  color: ${TEXT_COLORS.heading};
  cursor: pointer;
  flex-wrap: nowrap;

  &:not(&--disabled):hover,
  &:not(&--disabled):focus {
    outline: none;

    ${Index}, ${ItemName}, ${BreakName} {
      color: ${TEXT_COLORS.link};
    }
  }

  &--break-row:not(&--unread) {
    pointer-events: none;
    cursor: default;
  }

  &--disabled {
    color: ${meetingPackDisabledSidebarItemColor};
    cursor: default;

    span {
      cursor: default;
    }

    ${BreakLine} {
      border-color: ${meetingPackDisabledSidebarItemColor};
    }
  }

  &--active {
    ${Index}, ${ItemName} {
      color: ${TEXT_COLORS.link};
    }
  }

  &--small {
    margin-top: ${SPACES.xSmall};
    font-size: ${FONT_SIZES.xSmall};
  }
`
