import { styled } from "@linaria/react"

export const Title = styled.div`
  overflow: hidden;
  margin-bottom: 0;
  text-align: left;
`

export const Primary = styled.h6`
  margin-bottom: 0;
`

export const Secondary = styled.div`
  color: #a8abbb;
  font-size: 0.75rem;
`
