import React, { useState } from "react"
import { useQuery } from "@apollo/client"
import { bool, number, shape, string } from "prop-types"

import { DrawerList } from "../styles"
import usePagination from "../usePagination"
import LoadMore from "../LoadMore"
import NotFound from "../NotFound"

import linksQuery from "./discussionLinks.gql"
import EntityRow from "./EntityRow"
import Backlinks from "./Backlinks"

const LinkList = ({ discussionId, filters, visible, filtersOpened }) => {
  const [entities, setEntities] = useState([])
  const [page, setPage] = useState(1)

  const { data, loading } = useQuery(linksQuery, {
    variables: {
      discussionId,
      page,
      filters
    }
  })

  const rawEntities = data?.discussionMessageLinks

  const { refPaginationTrigger } = usePagination(
    rawEntities,
    setEntities,
    filters,
    page,
    setPage,
    loading
  )

  const entitiesPresence = !!entities.length

  return (
    <DrawerList
      className={`${filtersOpened && "filter-opened"} ${visible ? "" : "hidden"}`}
    >
      {entities.map((contentLink) => (
        <EntityRow key={contentLink.id} contentLink={contentLink} />
      ))}
      <NotFound
        entitiesPresence={entitiesPresence}
        loading={loading}
        text="No links found"
      />

      <LoadMore
        entitiesPresence={entitiesPresence}
        loading={loading}
        refPaginationTrigger={refPaginationTrigger}
      />
      <Backlinks discussionId={discussionId} />
    </DrawerList>
  )
}

LinkList.propTypes = {
  discussionId: number.isRequired,
  visible: bool.isRequired,
  filtersOpened: bool.isRequired,
  filters: shape({
    text: string,
    type: string,
    authorId: number,
    fromDate: string,
    toDate: string
  })
}

export default LinkList
