import React from "react"
import { styled } from "@linaria/react"
import { arrayOf, number, shape, string } from "prop-types"

import UserAvatar from "src/components/UsersContacts/UserAvatar"

const Container = styled.div`
  margin-top: 16px;
  padding-top: 16px;
`

const UserContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`

const WarningMessage = styled.div`
  color: #e97b7b;
  margin-bottom: 16px;
`

const FullName = styled.span`
  margin-left: 8px;
`

const NotAssignedAttendees = ({ attendees }) => {
  const pluralizedAttendees =
    attendees.length > 1 ? "attendees do not" : "attendee does not"

  const warningMessage = `The following ${pluralizedAttendees} have access to any agenda items:`

  return (
    <Container className="border-top" data-testid="not-assigned-attendees">
      <WarningMessage>{warningMessage}</WarningMessage>
      {attendees.map((attendee) => (
        <UserContainer key={attendee.fullName} data-testid="attendee">
          <UserAvatar user={attendee} />

          <FullName>{attendee.fullName}</FullName>
        </UserContainer>
      ))}
    </Container>
  )
}

NotAssignedAttendees.propTypes = {
  attendees: arrayOf(
    shape({
      fullName: string.isRequired,
      avatarUrl: string,
      avatarColorId: number,
      initials: string
    })
  ).isRequired
}

export default NotAssignedAttendees
