import React from "react"
import { styled } from "@linaria/react"
import classNames from "classnames"
import { number, shape, string } from "prop-types"

import { FONT_WEIGHTS, SPACES } from "src/styles/sizes"

import AgendaItemIndex from "./AgendaItemIndex"

const FixedAgendaItem = ({ agendaItem, className }) => {
  const { name, agendaItemsListIndex } = agendaItem

  return (
    <div className={classNames(className, "pr-3")}>
      <div className="card">
        <AgendaItemIndex index={agendaItemsListIndex} />
        <div className="card-body py-2">{name}</div>
      </div>
    </div>
  )
}

FixedAgendaItem.propTypes = {
  agendaItem: shape({
    agendaItemId: number.isRequired,
    name: string.isRequired,
    agendaItemsListIndex: string.isRequired
  })
}

export default styled(FixedAgendaItem)`
  margin-left: 85px;
  font-weight: ${FONT_WEIGHTS.bold};
  position: absolute;
  margin-top: 50px;
  z-index: 1;
  left: 0;
  width: calc(100% - 85px);

  .card {
    padding: ${SPACES.xxSmall};
    background-color: #dbdde0;
  }
`
