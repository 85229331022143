import { styled } from "@linaria/react"

export const Container = styled.div`
  display: flex;
  gap: 8px;
`

export const Textarea = styled.textarea`
  min-height: 49px;
  height: 38px;
  max-height: 312px;
  padding: 13px 10px;
  resize: none;
  overflow: auto;

  &:focus,
  &:focus-visible {
    outline: 0;
    box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.2);
  }
`
