import React from "react"
import { Form } from "react-bootstrap"
import { useFormikContext } from "formik"
import { arrayOf, number, shape, string } from "prop-types"
import { styled } from "@linaria/react"

import Checkbox from "src/styles/components/Checkbox"
import { FONT_SIZES, SPACES } from "src/styles/sizes"
import Icon from "src/styles/components/Icon"

import AppInstancesList from "./AppInstancesList"
import { GROUP_SETTINGS } from "./constants"

const settingsMap = [
  {
    key: GROUP_SETTINGS.discussions,
    title: "Discussions & Attachments",
    icon: "message",
    tooltip: "You can't disable discussions if there're discussions in the group",
    disableIf: ({ discussionsCount, value }) => value && discussionsCount > 0
  },
  {
    key: GROUP_SETTINGS.documents,
    title: "Folders & Documents",
    icon: "document",
    tooltip: "Some info"
  },
  {
    key: GROUP_SETTINGS.applications,
    title: "Applications & Integrations",
    icon: "arrow-right-squared",
    tooltip: "Some info",
    showIf: ({ appInstances }) => appInstances.length > 0
  }
]

const Settings = ({ appInstances, discussionsCount, className }) => {
  const { values, setFieldValue } = useFormikContext()

  const settingEnabled = (key) => values.settings?.[key]

  const checkSetting = (key, value) =>
    setFieldValue("settings", { ...values.settings, [key]: value })

  const settings = settingsMap.filter(
    (setting) => !setting.showIf || setting.showIf({ appInstances })
  )

  return (
    <Form.Group className={className}>
      <Form.Label className="mt-4 mb-3">
        <h6>What will this group be used for?</h6>
      </Form.Label>

      <div className="card">
        {settings.map((setting) => (
          <div key={setting.key} className="my-2 d-flex justify-content-between">
            <label className="d-flex" htmlFor={`settings[${setting.key}]`}>
              <Icon className="mr-2" type={setting.icon} />
              <span>{setting.title}</span>
            </label>
            <Checkbox
              id={`settings[${setting.key}]`}
              onChange={(e) => checkSetting(setting.key, e.target.checked)}
              checked={settingEnabled(setting.key)}
              disabled={
                setting.disableIf &&
                setting.disableIf({
                  discussionsCount,
                  value: settingEnabled(setting.key)
                })
              }
            />
          </div>
        ))}
      </div>

      {values.settings?.[GROUP_SETTINGS.applications] && (
        <AppInstancesList appInstances={appInstances} />
      )}
    </Form.Group>
  )
}

Settings.propTypes = {
  appInstances: arrayOf(shape({ id: number.isRequired, name: string.isRequired })),
  discussionsCount: number.isRequired
}

export default styled(Settings)`
  ${Icon} {
    font-size: ${FONT_SIZES.large};
  }

  .card {
    padding: ${SPACES.small} ${SPACES.medium} ${SPACES.xSmall} ${SPACES.medium};
  }
`
