import React from "react"
import { func, instanceOf } from "prop-types"

import UploadFolder from "../../UploadFolder"

import FolderItem from "./FolderItem"
import { List } from "./styles"

const FolderItemsList = ({ folder, onRemove }) => {
  return (
    <List>
      {folder.folders.map((nestedFolder) => {
        return (
          <FolderItem
            key={nestedFolder.name}
            folderItem={nestedFolder}
            onRemove={onRemove}
          >
            <FolderItemsList folder={nestedFolder} onRemove={onRemove} />
          </FolderItem>
        )
      })}
      {folder.files.map((file) => (
        <FolderItem key={file.name} folderItem={file} onRemove={onRemove} />
      ))}
    </List>
  )
}

FolderItemsList.propTypes = {
  folder: instanceOf(UploadFolder).isRequired,
  onRemove: func.isRequired
}

export default FolderItemsList
