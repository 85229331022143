import React, { useCallback } from "react"
import { bool, func, number, string } from "prop-types"

import Unstyled from "src/styles/components/Button/Unstyled"

import { CategoryLi } from "./styles"

const Category = ({ name, count, selected = false, setSelectedCategory }) => {
  const disabled = !count
  const selectCategory = useCallback(
    () => setSelectedCategory(name),
    [name, setSelectedCategory]
  )

  return (
    <CategoryLi disabled={disabled} selected={selected} onClick={selectCategory}>
      <Unstyled>
        {name} ({count})
      </Unstyled>
    </CategoryLi>
  )
}

Category.propTypes = {
  name: string.isRequired,
  count: number.isRequired,
  setSelectedCategory: func.isRequired,
  selected: bool
}

export default Category
