import { styled } from "@linaria/react"

export const ListItem = styled.li`
  font-size: inherit;
  & > ul {
    padding-left: 50px;
    overflow-y: visible;
  }
`

export const Container = styled.div`
  height: 53px;
  padding-left: 20px;
  padding-right: 30px;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const IconContainer = styled.div`
  display: inline-block;
  width: 40px;
  margin-right: 15px;
  flex-shrink: 0;
`

export const Name = styled.div`
  flex-grow: 1;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`
