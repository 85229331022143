import { useSelector } from "react-redux"

import {
  getActiveItem,
  getActiveDocument,
  getActiveDocumentPage
} from "src/features/meetingPacks/MeetingPack/selectors"

import calculatePages from "./calculatePages"
import useUpdatePagesCountStatus, { UpdateStatus } from "./useUpdatePagesCountStatus"

const usePagesCounter = () => {
  const activeItem = useSelector((state) => getActiveItem(state))
  const activeDocument = useSelector((state) => getActiveDocument(state))
  const activeDocumentPage = useSelector((state) => getActiveDocumentPage(state))

  const { status, agendaItems } = useUpdatePagesCountStatus()

  const { currentPage, totalPages } = calculatePages({
    agendaItems,
    activeItem,
    activeDocument,
    activeDocumentPage
  })

  return { currentPage, totalPages, loading: status === UpdateStatus.Running }
}

export default usePagesCounter
