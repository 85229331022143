import React from "react"
import { Modal } from "react-bootstrap"

import PreviewContainer from "../PreviewContainer"
import { PreviewComponentPropTypes } from "../propTypes"

import { dialogClass, contentClass } from "./styles"

const PreviewModal = (props) => {
  return (
    <Modal show dialogClassName={dialogClass} contentClassName={contentClass}>
      <PreviewContainer {...props} withinModal />
    </Modal>
  )
}

PreviewModal.propTypes = PreviewComponentPropTypes

export default PreviewModal
