import { omit } from "lodash"

const toggleBadgeClass = ({ privateSchemeId, jointSchemeId, stats }) => {
  const privateSchemes = [privateSchemeId].filter(Boolean)
  const jointSchemes = [jointSchemeId].filter(Boolean)

  const hasMentions =
    Object.values(omit(stats.mentions.mainScheme, privateSchemes)).some(Boolean) ||
    Object.values(omit(stats.mentions.privateSchemes, privateSchemes)).some(Boolean) ||
    Object.values(omit(stats.mentions.jointSchemes, jointSchemes)).some(Boolean)

  const hasActions =
    Object.values(omit(stats.actions.mainScheme, privateSchemes)).some(Boolean) ||
    Object.values(omit(stats.actions.privateSchemes, privateSchemes)).some(Boolean) ||
    Object.values(omit(stats.actions.jointSchemes, jointSchemes)).some(Boolean)

  const hasUnsigned =
    Object.values(omit(stats.unsigned.mainScheme, privateSchemes)).some(Boolean) ||
    Object.values(omit(stats.unsigned.privateSchemes, privateSchemes)).some(Boolean) ||
    Object.values(omit(stats.unsigned.jointSchemes, jointSchemes)).some(Boolean)

  const hasUnread =
    Object.values(omit(stats.unread.mainScheme, privateSchemes)).some(Boolean) ||
    Object.values(omit(stats.unread.privateSchemes, privateSchemes)).some(Boolean) ||
    Object.values(omit(stats.unread.jointSchemes, jointSchemes)).some(Boolean)

  if (hasMentions || hasActions || hasUnsigned) {
    return "mention"
  }

  if (hasUnread) {
    return "unread"
  }

  return ""
}

export { toggleBadgeClass }
