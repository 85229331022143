import React from "react"
import { styled } from "@linaria/react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { oneOf, string } from "prop-types"

const IconWithTooltip = ({ icon, text, placement, className }) => {
  if (!icon.length || !text.length) {
    return null
  }

  return (
    <OverlayTrigger
      delay={300}
      placement={placement}
      trigger="click"
      rootClose
      overlay={<Tooltip id={`tooltip-${placement}`}>{text}</Tooltip>}
    >
      <span className={`${icon} ${className}`} />
    </OverlayTrigger>
  )
}

IconWithTooltip.propTypes = {
  placement: oneOf(["top", "right", "bottom", "left"]),
  text: string,
  icon: string
}

IconWithTooltip.defaultProps = {
  placement: "bottom"
}

export default styled(IconWithTooltip)`
  cursor: pointer;
  display: flex;
`
