const countDown = (seconds, callback, finishCallback) => {
  let timer = seconds
  callback(timer)

  setTimeout(() => {
    timer -= 1
    if (timer > 0) countDown(timer, callback, finishCallback)
    else finishCallback()
  }, 1000)
}

export default countDown
