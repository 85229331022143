import { useState } from "react"
import moment from "moment"

import { WHEN_FILTER_DATES, WHEN_FILTER_DEFAULT } from "src/features/Search/constants"
import { filterDates } from "src/features/Search/SearchPage/Search/helpers"

const getDefaultFilterState = (defaults) => ({
  dateFrom: defaults.dateFrom ? moment(defaults.dateFrom) : null,
  dateTo: defaults.dateTo ? moment(defaults.dateTo) : null,
  whenFilter:
    defaults.dateFrom || defaults.dateTo ? WHEN_FILTER_DATES : WHEN_FILTER_DEFAULT
})

export default function useWhenFilter(defaults) {
  const [filterState, setFilterState] = useState(getDefaultFilterState(defaults))

  const setWhenFilter = (newFilterValue) => {
    let { dateTo, dateFrom } = filterState

    if (newFilterValue === WHEN_FILTER_DEFAULT) {
      dateTo = null
      dateFrom = null
    } else if (newFilterValue !== WHEN_FILTER_DATES) {
      const dates = filterDates(newFilterValue)
      dateTo = dates.dateTo
      dateFrom = dates.dateFrom
    }

    setFilterState((prevState) => ({
      ...prevState,
      dateTo,
      dateFrom,
      whenFilter: newFilterValue
    }))
  }

  const setDateFrom = (dateFrom) => {
    setFilterState((prevState) => ({ ...prevState, dateFrom: moment(dateFrom) }))
  }

  const setDateTo = (dateTo) => {
    setFilterState((prevState) => ({ ...prevState, dateTo: moment(dateTo) }))
  }

  return {
    dateFrom: filterState.dateFrom,
    dateTo: filterState.dateTo,
    whenFilter: filterState.whenFilter,
    setDateFrom,
    setDateTo,
    setWhenFilter
  }
}
