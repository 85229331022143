import React from "react"

import { ItemsListCard, ItemsListRow } from "src/styles/components/ItemsList"

const NoDocumentsRow = () => (
  <ItemsListRow>
    <ItemsListCard>
      <div className="text-center text-muted w-100">
        There are no documents attached to this agenda item. Click above to upload one.
      </div>
    </ItemsListCard>
  </ItemsListRow>
)

export default NoDocumentsRow
