import React from "react"
import classNames from "classnames"
import { arrayOf, bool, func, number } from "prop-types"

import { AttendeesListContainer } from "./styles"
import AttendeeRow from "./AttendeeRow"
import useMemberships from "./useMemberships"

const AttendeesList = ({ meetingPackId, checkedUsersIds, toggleUserId, isOpened }) => {
  const isUserChecked = (id) => checkedUsersIds.some((userId) => userId === id)
  const memberships = useMemberships({ meetingPackId })

  return (
    <AttendeesListContainer className={classNames({ "d-none": !isOpened })}>
      {memberships.map(({ user, membershipType, role }) => (
        <AttendeeRow
          onChange={toggleUserId}
          checked={isUserChecked(user.id)}
          key={user.id}
          user={user}
          membershipType={membershipType}
          role={role}
        />
      ))}
    </AttendeesListContainer>
  )
}

AttendeesList.propTypes = {
  meetingPackId: number.isRequired,
  isOpened: bool.isRequired,
  checkedUsersIds: arrayOf(number).isRequired,
  toggleUserId: func.isRequired
}

export default AttendeesList
