import React, { useCallback, useEffect, useState } from "react"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import { Col, Form, Modal } from "react-bootstrap"
import { bool, func, number } from "prop-types"
import { find } from "lodash"

import SharedMessageContent from "src/features/Home/Messages/SharedMessageContent"
import SchemeSelect from "src/components/SchemeSelect"
import Select from "src/styles/components/Select"
import ContentLoader from "src/styles/components/ContentLoader"
import PrimaryButton from "src/styles/components/Button/Primary"
import SecondaryButton from "src/styles/components/Button/Secondary"
import ModalTitle from "src/styles/components/Modal/Title"
import ModalHeader from "src/styles/components/Modal/Header"
import ModalFooter from "src/styles/components/Modal/Footer"
import { StyledModal } from "src/styles/components/Modal"
import { fetchAccounts } from "src/resources/accounts/thunks"
import { fetchAccountSchemes } from "src/resources/schemes/thunks"
import { fetchSchemeGroups } from "src/resources/groups/thunks"
import { fetchGroupDiscussions } from "src/resources/discussions/thunks"
import { fetchMessageContent, shareMessage } from "src/resources/messages/thunks"
import { selectAccountOptions } from "src/resources/accounts/selectors"
import { selectSchemeOptions } from "src/resources/schemes/selectors"
import { selectGroupOptions } from "src/resources/groups/selectors"
import { selectDiscussionOptions } from "src/resources/discussions/selectors"
import { getAccountOptionId } from "src/resources/accounts/helpers"
import { getSchemeOptionId } from "src/resources/schemes/helpers"
import { getGroupOptionId } from "src/resources/groups/helpers"
import { getDiscussionOptionId } from "src/resources/discussions/helpers"
import { normalizeMessage } from "src/resources/messages/helpers"
import { getCurrentUserId } from "src/helpers/user"
import { goTo } from "src/helpers/url"

import useMentionsTribute from "./useMentionsTribute"
import { ContentEditable } from "./styles"

const ShareMessageModal = ({
  accountId,
  schemeId,
  groupId,
  discussionId,
  messageId,
  isOpened,
  close
}) => {
  const dispatch = useDispatch()

  const [message, setMessage] = useState()
  const [customMessage, setCustomMessage] = useState()
  const [isLoading, setIsLoading] = useState()
  const [isSharing, setSharing] = useState(false)

  useEffect(() => {
    if (messageId && !message) {
      setIsLoading(true)
      dispatch(fetchMessageContent({ messageId }))
        .then(setMessage)
        .finally(() => setIsLoading(false))
    }
  }, [dispatch, message])

  useEffect(() => {
    dispatch(fetchAccounts())
    dispatch(fetchAccountSchemes({ accountId }))
    dispatch(
      fetchSchemeGroups({
        schemeId,
        filter: { membership: true }
      })
    )
    dispatch(fetchGroupDiscussions({ groupId }))
  }, [dispatch])

  const [selectedAccount, setSelectedAccount] = useState()
  const [selectedScheme, setSelectedScheme] = useState()
  const [selectedGroup, setSelectedGroup] = useState()
  const [selectedDiscussion, setSelectedDiscussion] = useState()

  const accountOptions = useSelector(selectAccountOptions, shallowEqual)
  const schemeOptions = useSelector(selectSchemeOptions, shallowEqual)
  const groupOptions = useSelector(selectGroupOptions, shallowEqual)
  const discussionOptions = useSelector(selectDiscussionOptions, shallowEqual)

  const currentUserId = getCurrentUserId()
  const currentAccount = accountOptions.find(({ value }) => value === accountId)
  const currentScheme = schemeOptions.find(({ value }) => value === schemeId)
  const currentGroup = groupOptions.find(({ value }) => value === groupId)

  const schemeOptionsByAccount = schemeOptions.filter(
    (scheme) =>
      scheme.accountId === getAccountOptionId(selectedAccount) ||
      scheme.accountIds.includes(getAccountOptionId(selectedAccount))
  )
  const groupOptionsByScheme = groupOptions.filter(
    (group) =>
      group.schemeId === getSchemeOptionId(selectedScheme) &&
      find(group.users, { id: currentUserId })
  )
  const discussionOptionsByGroup = discussionOptions.filter(
    (discussion) => discussion.groupId === getGroupOptionId(selectedGroup)
  )

  const canBeShared =
    selectedDiscussion && getDiscussionOptionId(selectedDiscussion) !== discussionId

  const { inputRef, extractMentionedUserIds, removeIncorrectMentions } =
    useMentionsTribute(selectedDiscussion?.userMentions)

  useEffect(() => {
    if (currentAccount && !selectedAccount) setSelectedAccount(currentAccount)
    if (currentScheme && !selectedScheme && selectedScheme !== null)
      setSelectedScheme(currentScheme)
    if (currentGroup && !selectedGroup && selectedGroup !== null)
      setSelectedGroup(currentGroup)
  }, [currentAccount, currentScheme, currentGroup])
  useEffect(() => {
    if (selectedAccount)
      dispatch(fetchAccountSchemes({ accountId: getAccountOptionId(selectedAccount) }))
  }, [dispatch, selectedAccount, selectedScheme, selectedGroup])
  useEffect(() => {
    if (selectedScheme)
      dispatch(
        fetchSchemeGroups({
          schemeId: getSchemeOptionId(selectedScheme),
          filter: { membership: true }
        })
      )
  }, [dispatch, selectedScheme])
  useEffect(() => {
    if (selectedGroup)
      dispatch(fetchGroupDiscussions({ groupId: getGroupOptionId(selectedGroup) }))
  }, [dispatch, selectedGroup])
  useEffect(() => {
    if (selectedDiscussion) removeIncorrectMentions()
  }, [selectedDiscussion])

  const handleAccountChange = useCallback(
    (accountOption) => {
      if (selectedAccount !== accountOption) {
        setSelectedAccount(accountOption)
        setSelectedScheme(null)
        setSelectedGroup(null)
        setSelectedDiscussion(null)
      }
    },
    [selectedAccount]
  )

  const handleSchemeChange = useCallback(
    (schemeOption) => {
      if (selectedScheme !== schemeOption) {
        setSelectedScheme(schemeOption)
        setSelectedGroup(null)
        setSelectedDiscussion(null)
      }
    },
    [selectedScheme]
  )

  const handleGroupChange = useCallback(
    (groupOption) => {
      if (selectedGroup !== groupOption) {
        setSelectedGroup(groupOption)
        setSelectedDiscussion(null)
      }
    },
    [selectedGroup]
  )

  const handleShare = useCallback(() => {
    const { id, fileCollection } = normalizeMessage(message)

    setSharing(true)
    close()
    dispatch(
      shareMessage({
        messageId: id,
        data: {
          discussionId: getDiscussionOptionId(selectedDiscussion),
          mentionedUserIds: extractMentionedUserIds(),
          messageBody: customMessage,
          fileCollection
        }
      })
    ).then((sharedMessage) => {
      setSharing(false)
      goTo(
        `/discussions/${getDiscussionOptionId(selectedDiscussion)}?message_id=${
          sharedMessage.id
        }`
      )
    })
  }, [dispatch, selectedDiscussion, customMessage])

  const handleCustomMessageChange = (event) =>
    setCustomMessage(event.currentTarget.innerHTML)

  const handleCancel = () => {
    close()
    setSelectedGroup(currentGroup)
    setSelectedScheme(currentScheme)
    setSelectedAccount(currentAccount)
  }

  return (
    <StyledModal
      data-testid="share-message-modal"
      size="lg"
      onHide={handleCancel}
      show={isOpened}
    >
      <ModalHeader>
        <ModalTitle data-testid="modal-title">Share this message</ModalTitle>
      </ModalHeader>
      <Modal.Body>
        <Form>
          <Form.Row>
            <Col>
              <Form.Group data-testid="account-select">
                <Select
                  placeholder="Select organisation"
                  onChange={handleAccountChange}
                  options={accountOptions}
                  value={selectedAccount}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group data-testid="scheme-select">
                <SchemeSelect
                  isDisabled={!selectedAccount}
                  placeholder="Select space"
                  onChange={handleSchemeChange}
                  options={schemeOptionsByAccount}
                  value={selectedScheme}
                />
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Group data-testid="group-select">
                <Select
                  isDisabled={!selectedScheme}
                  placeholder="Select group"
                  onChange={handleGroupChange}
                  options={groupOptionsByScheme}
                  value={selectedGroup}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group data-testid="discussion-select">
                <Select
                  isDisabled={!selectedGroup}
                  placeholder="Select discussion"
                  isOptionDisabled={({ value }) => value === discussionId}
                  onChange={setSelectedDiscussion}
                  options={discussionOptionsByGroup}
                  value={selectedDiscussion}
                />
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Group data-testid="custom-message">
            <ContentEditable
              ref={inputRef}
              contentEditable
              className="form-control"
              placeholder="Add a message, if you'd like."
              onInput={handleCustomMessageChange}
            />
          </Form.Group>
          <div className="mls">
            {isLoading && <ContentLoader />}
            {message && <SharedMessageContent withAttachments message={message} />}
          </div>
        </Form>
      </Modal.Body>
      <ModalFooter>
        <PrimaryButton
          width="md"
          className="mr-3"
          disabled={!canBeShared || isSharing}
          onClick={handleShare}
        >
          {isSharing ? "Sharing..." : "Share"}
        </PrimaryButton>
        <SecondaryButton width="md" onClick={handleCancel}>
          Cancel
        </SecondaryButton>
      </ModalFooter>
    </StyledModal>
  )
}

ShareMessageModal.propTypes = {
  accountId: number.isRequired,
  schemeId: number.isRequired,
  groupId: number.isRequired,
  discussionId: number.isRequired,
  messageId: number.isRequired,
  isOpened: bool.isRequired,
  close: func.isRequired
}

export default ShareMessageModal
