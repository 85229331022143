import { styled } from "@linaria/react"

import { BREAKPOINTS, FONT_SIZES, SPACES } from "src/styles/sizes"
import { withVariations } from "src/helpers/linaria"
import { TEXT_COLORS } from "src/styles/colors"

export const CategoriesContainer = styled.div`
  background: #fff;
  overflow-x: auto;
  position: relative;
  display: flex;

  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    background: transparent;
  }
`

export const CategoriesList = styled.ul`
  width: 75%;
  margin: 0 auto;
  list-style: none;
  display: flex;
  justify-content: space-around;
  padding: 0;

  @media (max-width: calc(${BREAKPOINTS.semiLarge} - 1px)) {
    width: 85%;
  }

  @media (max-width: calc(${BREAKPOINTS.medium} - 1px)) {
    width: fit-content;
  }
`

export const CategoryLi = styled(withVariations("li", "selected", "disabled"))`
  color: ${TEXT_COLORS.heading};
  font-size: ${FONT_SIZES.medium};
  padding: ${SPACES.xSmall};
  white-space: nowrap;

  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    font-size: ${FONT_SIZES.small};
    padding: ${SPACES.small};
  }

  & button {
    text-transform: uppercase;
  }

  &--selected {
    border-bottom: 2px solid ${TEXT_COLORS.heading};
  }

  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`

export const GoBackLink = styled.a`
  align-items: center;
  display: flex;
  font-size: ${FONT_SIZES.medium};
  left: ${SPACES.xSmall};
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 15%;
  white-space: nowrap;

  .desktop-text {
    align-items: center;
  }

  @media (max-width: calc(${BREAKPOINTS.medium} - 1px)) and (min-width: ${BREAKPOINTS.small}) {
    body:not(.no-sidebar) & {
      display: none;
    }
  }

  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    display: none;
  }
`
