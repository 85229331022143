import { styled } from "@linaria/react"

import {
  BREAKPOINTS,
  FONT_SIZES,
  SPACES,
  headerSize,
  headerMobileSize
} from "src/styles/sizes"
import { ICON_COLORS, BORDER_COLORS, TEXT_COLORS } from "src/styles/colors"

export const InputContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  color: ${TEXT_COLORS.heading};
  font-family: var(--font-family-sans-serif);

  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    top: ${headerMobileSize};
  }

  > [class^="icon-search"] {
    display: block;
    position: absolute;
    left: ${SPACES.small};
    top: 50%;
    color: ${ICON_COLORS.search};
    font-size: ${FONT_SIZES.large};
    transform: translateY(-50%);
  }

  input[type="search"] {
    padding: ${SPACES.xSmall} ${SPACES.large} ${SPACES.xSmall}
      calc(${SPACES.large} + ${SPACES.xSmall});
    border-radius: 0;
    height: ${headerSize};

    @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
      height: ${headerMobileSize};
    }

    &:focus {
      box-shadow: none;
      border: 1px solid ${BORDER_COLORS.control};
    }

    &::-webkit-search-cancel-button {
      display: none;
    }
  }
`

export const NavLink = styled.a``
