import React from "react"
import { arrayOf, number, shape, string } from "prop-types"

import { RESULT_TYPES } from "src/features/Search/constants"
import { ErrorBoundary } from "src/app/ErrorBoundary"

import { ResultCardContainer, Answer } from "./styles"
import Badge from "./Badge"
import FileTitle from "./FileTitle"
import TextTitle from "./TextTitle"
import MessageTitle from "./MessageTitle"
import Text from "./Text"
import Footer from "./Footer"

const typeTitles = {
  [RESULT_TYPES.agendaItemDocument]: FileTitle,
  [RESULT_TYPES.agendaItemDescription]: TextTitle,
  [RESULT_TYPES.transcript]: TextTitle,
  [RESULT_TYPES.assignedTranscriptAgendaItem]: TextTitle,
  [RESULT_TYPES.message]: MessageTitle,
  [RESULT_TYPES.document]: FileTitle,
  [RESULT_TYPES.contentCloudFile]: FileTitle,
  [RESULT_TYPES.attachment]: FileTitle,
  [RESULT_TYPES.iframeContent]: TextTitle
}

const typeTestIds = {
  [RESULT_TYPES.agendaItemDocument]: "agenda-item-document-result-card",
  [RESULT_TYPES.agendaItemDescription]: "agenda-item-description-result-card",
  [RESULT_TYPES.message]: "message-result-card",
  [RESULT_TYPES.document]: "document-result-card",
  [RESULT_TYPES.contentCloudFile]: "document-result-card",
  [RESULT_TYPES.attachment]: "attachment-result-card",
  [RESULT_TYPES.transcript]: "transcript-result-card",
  [RESULT_TYPES.assignedTranscriptAgendaItem]: "transcript-result-card",
  [RESULT_TYPES.iframeContent]: "transcript-result-card"
}

const ResultCard = ({
  id,
  type,
  title,
  answer,
  author,
  datetime,
  text,
  path,
  breadcrumbs
}) => {
  const TypeTitle = typeTitles[type]

  return (
    <ErrorBoundary fallback={null}>
      <ResultCardContainer data-id={id} data-behavior={typeTestIds[type]}>
        <Badge type={type} />
        <TypeTitle title={title} datetime={datetime} author={author} />

        {!!answer && <Answer>{answer}</Answer>}

        <Text text={text} />
        <Footer type={type} path={path} breadcrumbs={breadcrumbs} />
      </ResultCardContainer>
    </ErrorBoundary>
  )
}

ResultCard.propTypes = {
  type: string.isRequired,
  path: string.isRequired,
  datetime: string,
  id: string,
  text: string,
  title: string,
  answer: string,
  author: shape({
    fullName: string.isRequired,
    avatarColorId: number,
    avatarUrl: string,
    initials: string
  }),
  breadcrumbs: arrayOf(
    shape({
      name: string,
      path: string
    })
  )
}

export default ResultCard
