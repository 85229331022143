class @ProfileEditHandler
  constructor: ->
    @changePasswordComponent = new ChangePasswordComponent()
    @avatarUploadComponent = new AvatarUploadComponent()
    @twoFactorDisable = new TwoFactorDisableComponent()

  setup: =>
    @changePasswordComponent.setup()
    @avatarUploadComponent.setup()
    @twoFactorDisable.setup()

  teardown: =>

