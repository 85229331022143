import React, { useEffect, useState } from "react"
import { bool, arrayOf, func, string, shape } from "prop-types"

import ModalHeader from "src/styles/components/Modal/Header"
import ModalTitle from "src/styles/components/Modal/Title"
import ModalFooter from "src/styles/components/Modal/Footer"
import { StyledModal } from "src/styles/components/Modal"

import FieldsList from "../FieldsList"

const FieldsModal = ({
  close,
  isOpened,
  initiallySelectedFields,
  saveSelectedFields,
  className
}) => {
  const [selectedFieldIds, setSelectedFieldIds] = useState([])

  useEffect(() => {
    setSelectedFieldIds(initiallySelectedFields.map((field) => field.id))
  }, [initiallySelectedFields])

  const handleSave = () => {
    saveSelectedFields(selectedFieldIds.map((id) => ({ id })))
    close()
  }

  return (
    <StyledModal size="md" onHide={close} show={isOpened} className={className}>
      <ModalHeader>
        <ModalTitle>Fields</ModalTitle>
      </ModalHeader>

      <FieldsList
        selectedFieldIds={selectedFieldIds}
        setSelectedFieldIds={setSelectedFieldIds}
        maxCheckedFields={6}
      />

      <ModalFooter>
        <button className="btn btn-primary" onClick={handleSave}>
          Apply
        </button>
        <button className="button is-secondary" onClick={close}>
          Cancel
        </button>
      </ModalFooter>
    </StyledModal>
  )
}

FieldsModal.propTypes = {
  close: func.isRequired,
  isOpened: bool.isRequired,
  initiallySelectedFields: arrayOf(shape({ id: string.isRequired })),
  saveSelectedFields: func.isRequired
}

export default FieldsModal
