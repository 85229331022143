import React from "react"
import { styled } from "@linaria/react"
import { arrayOf, func, number, shape, string } from "prop-types"

import Icon from "src/styles/components/Icon"
import { BASE_COLORS } from "src/styles/colors"
import { getCurrentUserId } from "src/helpers/user"

const SignButton = styled.button`
  // Trick to increase specificity
  &.button {
    color: ${BASE_COLORS.lightRed};
    border-color: ${BASE_COLORS.lightRed};
    font-size: 14px;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  &.button:hover {
    color: ${BASE_COLORS.lightRed};
    border-color: ${BASE_COLORS.lightRed};
  }
`

const CurrentUserSignatureStatus = ({ className, users, onSignButtonClick }) => {
  const currentUserId = getCurrentUserId()
  const currentUser = users.find(({ id }) => id === currentUserId)

  if (!currentUser) {
    return null
  }

  return (
    <div className={className}>
      {currentUser.signedAt ? (
        <span className="current-user-signed">
          <Icon type="signed" />
          &nbsp;You have signed
        </span>
      ) : (
        <SignButton className="button is-secondary no-print" onClick={onSignButtonClick}>
          <Icon type="sign" />
          &nbsp;Sign document
        </SignButton>
      )}
    </div>
  )
}

CurrentUserSignatureStatus.propTypes = {
  users: arrayOf(
    shape({
      id: number.isRequired,
      signedAt: string
    })
  ).isRequired,
  onSignButtonClick: func
}

export default styled(CurrentUserSignatureStatus)`
  display: flex;
  align-items: center;

  & > span {
    display: inline-flex;
    align-items: center;
  }

  .current-user-signed {
    color: ${BASE_COLORS.darkBlue};
  }
`
