import React from "react"

import logo from "images/logo.svg"

const Logo = () => {
  return (
    <div className="header-logo">
      <a href="/" className="logo">
        <img src={logo} alt="Knowa logo" />
      </a>
    </div>
  )
}

Logo.propTypes = {}

export default Logo
