const calculatePages = ({
  agendaItems,
  activeItem,
  activeDocument,
  activeDocumentPage
}) => {
  const agendaItemsWithoutBreaks = agendaItems.filter((agendaItem) => !agendaItem.break)

  let currentPage = 1
  let totalPages = 0
  let activeItemReached = false

  agendaItemsWithoutBreaks.forEach((agendaItem) => {
    const docsCount = agendaItem.documents.reduce(
      (total, document) => total + document.pagesCount,
      0
    )

    totalPages += docsCount + agendaItem.pagesCount

    if (agendaItem.id === activeItem?.id) {
      activeItemReached = true

      if (activeDocument) {
        const previousDocsPagesCount = agendaItem.documents
          .filter((document) => document.position < activeDocument.position)
          .reduce((total, document) => total + document.pagesCount, 0)

        currentPage +=
          agendaItem.pagesCount + previousDocsPagesCount + (activeDocumentPage || 1) - 1
      }
    } else if (!activeItemReached) {
      currentPage += docsCount + agendaItem.pagesCount
    }
  })

  return { currentPage, totalPages }
}

export default calculatePages
