import React, { useEffect, useRef, useState } from "react"
import { bool, func, shape, number } from "prop-types"
import { Modal, InputGroup, Form } from "react-bootstrap"
import { useMutation } from "@apollo/client"

import PrimaryButton from "src/styles/components/Button/Primary"
import SecondaryButton from "src/styles/components/Button/Secondary"
import ModalHeader from "src/styles/components/Modal/Header"
import ModalTitle from "src/styles/components/Modal/Title"
import ModalFooter from "src/styles/components/Modal/Footer"
import { showFlashMessage } from "src/helpers/flash"

import DeleteGroupMutation from "./DeleteGroup.gql"

const ConfirmModal = ({ group, isOpened, close }) => {
  const [confirmInput, setConfirmInput] = useState("")
  const [mutateDeleteGroup, { loading: isDeleting }] = useMutation(DeleteGroupMutation)

  const handleConfirm = () => {
    mutateDeleteGroup({
      variables: {
        id: group.id
      }
    }).then(() => {
      close()
      showFlashMessage("success", `The group ${group.name} was successfully deleted.`)
      window.location = `/spaces/${group.schemeId}/groups`
    })
  }

  const inputRef = useRef()

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = ""
    }
  }, [isOpened])

  return (
    <Modal centered size="md" onHide={close} show={isOpened}>
      <ModalHeader>
        <ModalTitle>Delete Group confirmation</ModalTitle>
      </ModalHeader>
      <Modal.Body data-testid="move-group-confirm-modal">
        <p>
          You are about to delete the Group <i>{group.name}</i>. All information will be
          immediately removed from your organisation.
        </p>
        <p>Type the word DELETE in capitals below to confirm.</p>

        <InputGroup>
          <Form.Control
            ref={inputRef}
            value={confirmInput}
            onChange={(e) => setConfirmInput(e.target.value)}
          />
        </InputGroup>
      </Modal.Body>
      <ModalFooter>
        <PrimaryButton
          className="mr-3"
          disabled={confirmInput !== "DELETE" || isDeleting}
          onClick={handleConfirm}
        >
          {isDeleting ? "Deleting..." : "Confirm delete"}
        </PrimaryButton>
        <SecondaryButton onClick={close}>No, get me out of here</SecondaryButton>
      </ModalFooter>
    </Modal>
  )
}

ConfirmModal.propTypes = {
  group: shape({
    id: number.isRequired,
    schemeId: number.isRequired
  }),
  isOpened: bool.isRequired,
  close: func.isRequired
}

export default ConfirmModal
