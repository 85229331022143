import { arrayOf, shape, string } from "prop-types"
import React from "react"

import LogItem from "./LogItem"

const DeletionLog = ({ log }) => {
  return (
    <>
      {log.map((logItem) => (
        <LogItem key={`${logItem.type}-${logItem.id}`} logItem={logItem} />
      ))}
    </>
  )
}

DeletionLog.propTypes = {
  log: arrayOf(
    shape({
      id: string.isRequired,
      type: string.isRequired,
      name: string.isRequired,
      errorMessage: string
    }).isRequired
  )
}

export default DeletionLog
