class @ChatsIndexHandler
  constructor: ->
    @unreadBadges = new UnreadBadgesComponent(
      'chat-card',
      'chatId',
      'chats:updates'
    )
    @chatListComponent = new ChatListComponent(
      afterUpdate: => @unreadBadges.refresh()
    )
    @userStatusLabelComponent = new UserStatusLabelComponent()
    @chatMembersDialogComponent = new ChatMembersDialogComponent()
    @filterInputComponent = new FilterInputComponent(
      input:        'chat-list-filter',
      items:        'chat-card',
      emptyMessage: 'chat-list-filter-empty'
    )


  setup: =>
    @unreadBadges.setup()
    @chatListComponent.setup()
    @userStatusLabelComponent.setup()
    @chatMembersDialogComponent.setup()
    @filterInputComponent.setup()

  teardown: =>
    @unreadBadges.teardown()
    @chatListComponent.teardown()
    @userStatusLabelComponent.teardown()
