import React from "react"
import { Spinner } from "react-bootstrap"

const Pending = () => {
  return (
    <div className="container mt-4">
      <h6 className="my-3">Transcript</h6>

      <div className="card">
        <div className="card-body text-muted">
          Preparing transcript. This can take a few minutes. Audio files several hours
          long may take up to 10 minutes to transcribe. Please come back later.
          <Spinner animation="grow" size="sm" className="ml-2" />
        </div>
      </div>
    </div>
  )
}

export default Pending
