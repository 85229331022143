import { useEffect } from "react"

const useLayoutHeader = ({ useDefault = true, title } = {}) => {
  useEffect(() => {
    const headerElement = document.getElementById("layout-header-title")
    if (!headerElement) return

    const defaultHeader = headerElement.getAttribute("data-default")
    headerElement.innerHTML = `<h5>${useDefault ? defaultHeader : title || ""}</h5>`

    return () => {
      headerElement.innerHTML = ""
    }
  }, [title])
}

export default useLayoutHeader
