import { styled } from "@linaria/react"

import { FONT_SIZES, SPACES } from "src/styles/sizes"
import { ICON_COLORS } from "src/styles/colors"

export const InputContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  flex: 1;
  font-family: var(--font-family-sans-serif);

  > [class^="icon-search"] {
    display: block;
    position: absolute;
    left: ${SPACES.small};
    top: 50%;
    color: ${ICON_COLORS.search};
    font-size: ${FONT_SIZES.large};
    transform: translateY(-50%);
  }

  input[type="search"] {
    padding: 0.55rem 0.55rem 0.55rem calc(${SPACES.large} + ${SPACES.xSmall});
    height: 100%;
    border: none;
    border-radius: 0;

    &::-webkit-search-cancel-button {
      display: none;
    }
  }
`

export const ClearSearch = styled.div`
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  font-size: ${FONT_SIZES.semiLarge};
  right: ${SPACES.small};
  line-height: 0;
  cursor: pointer;

  a,
  span {
    color: ${ICON_COLORS.searchClear} !important;
  }
`
