import React from "react"
import { string, func, bool } from "prop-types"
import { styled } from "@linaria/react"

import { airtableFieldType } from "src/resources/accounts/airtablePropTypes"
import { BORDER_COLORS } from "src/styles/colors"
import MarkdownEditor from "src/components/MarkdownEditor"
import { FONT_SIZES } from "src/styles/sizes"

import FieldLabel from "./FieldLabel"

const RichTextInput = ({ field, value, setRecord, invalid, className }) => {
  const handleChange = (newValue) => {
    setRecord((prevRecord) => ({
      ...prevRecord,
      [field.id]: newValue
    }))
  }

  return (
    <div className={className}>
      <FieldLabel field={field} invalid={invalid} />
      <MarkdownEditor
        handleChange={handleChange}
        fieldId={`rich-text-${field.id}`}
        initialValue={value}
      />
    </div>
  )
}

RichTextInput.propTypes = {
  field: airtableFieldType.isRequired,
  value: string,
  invalid: bool,
  setRecord: func.isRequired
}

export default styled(RichTextInput)`
  .fr-box {
    border: 1px solid ${BORDER_COLORS.control};
  }

  .fr-box.fr-basic .fr-element {
    font-size: ${FONT_SIZES.small};
  }

  .fr-wrapper {
    max-height: 300px;
    overflow: auto;
  }
`
