import { styled } from "@linaria/react"
import DropdownTreeSelect from "react-dropdown-tree-select"

export default styled(DropdownTreeSelect)`
  a.dropdown-trigger {
    &.disabled {
      background: #f2f2f2;

      input[type="text"] {
        background: #f2f2f2;
      }
    }
  }

  input[type="radio"]:read-only {
    cursor: pointer;
  }

  i {
    font-style: normal;
  }
`
