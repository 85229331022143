import React from "react"
import { BrowserRouter } from "react-router-dom"

const withRouterProvider = (Component) => (props) => {
  return (
    <BrowserRouter>
      <Component {...props} />
    </BrowserRouter>
  )
}

export { withRouterProvider }
