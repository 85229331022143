import { useState } from "react"

const needsUpdatePosition = ({ documents, nextDocuments }) => {
  const documentsIds = documents.map(({ id }) => id).join(".")
  const nextDocumentsIds = nextDocuments.map(({ id }) => id).join(".")

  return documentsIds !== nextDocumentsIds
}

const useDocumentsSorting = ({ documents, updatePosition }) => {
  const [sortedDocumentId, setSortedDocumentId] = useState(null)

  const setDocuments = (nextDocuments) => {
    const sortedDocument = nextDocuments.find(({ chosen }) => chosen)
    if (sortedDocument) {
      setSortedDocumentId(sortedDocument.id)
    }

    if (!needsUpdatePosition({ documents, nextDocuments })) {
      return
    }

    setSortedDocumentId(null)

    const newPosition = nextDocuments.reduce((position, document, i) => {
      if (document.id === sortedDocumentId) {
        return { position: i, id: sortedDocumentId }
      }

      return position
    }, null)

    if (newPosition) {
      updatePosition({ newPosition, nextDocuments })
    }
  }

  return { setDocuments }
}

export default useDocumentsSorting
