import { styled } from "@linaria/react"
import { Card } from "react-bootstrap"

import { SPACES, FONT_SIZES } from "src/styles/sizes"

export const CardTitle = styled.h5`
  margin-bottom: ${SPACES.small};
`

export const CardSubtitle = styled.div`
  margin-bottom: ${SPACES.small};
  font-size: ${FONT_SIZES.xSmall};
`

export const AgendaItemCard = styled(Card)`
  overflow-y: auto;
  display: block;
`

export const AttendeesContainer = styled.div`
  margin: ${SPACES.large} 0;
`

export const FullscreenButton = styled.div`
  position: absolute;
  top: 9px;
  right: 11px;
  cursor: pointer;
`
