import React from "react"
import { Form } from "react-bootstrap"
import { string, func, number } from "prop-types"
import { useFormikContext } from "formik"
import classNames from "classnames"
import { styled } from "@linaria/react"
import { useQuery } from "@apollo/client"

import Select from "src/styles/components/Select"
import { TEXT_COLORS } from "src/styles/colors"

import airtableBasesListQuery from "./airtableBasesList.gql"

const AirtableBaseSelect = ({ className, instanceId, accountId, setBaseName }) => {
  const { values, setFieldValue, handleBlur, errors, touched } = useFormikContext()

  const { data } = useQuery(airtableBasesListQuery, {
    variables: { oauthUserId: values.oauthUserId, instanceId, accountId }
  })

  const baseOptions =
    data?.airtableBasesList?.map((base) => ({
      value: base.id,
      label: base.name
    })) || []

  const baseSelectValue = (baseId) =>
    baseOptions.find((option) => option.value === baseId) || ""

  const handleChange = ({ value }) => {
    setFieldValue("baseId", value)
    setBaseName(baseSelectValue(value)?.label)
    setFieldValue("tableId", "")
  }

  return (
    <Form.Group
      controlId="baseId"
      data-testid="airtable-base-select"
      className={className}
    >
      <Form.Label>Airtable base</Form.Label>
      <Select
        name="baseId"
        className={classNames("w-100", errors.baseId && touched.baseId && "is-invalid")}
        options={baseOptions}
        value={baseSelectValue(values.baseId)}
        onChange={handleChange}
        onBlur={handleBlur}
        maxMenuHeight={200}
      />
      <Form.Control.Feedback type="invalid">{errors.baseId}</Form.Control.Feedback>
    </Form.Group>
  )
}

AirtableBaseSelect.propTypes = {
  accountId: number.isRequired,
  setBaseName: func.isRequired,
  instanceId: string
}

export default styled(AirtableBaseSelect)`
  .is-invalid .form-field {
    border-color: ${TEXT_COLORS.danger};
  }
`
