import { styled } from "@linaria/react"
import { Container } from "react-bootstrap"

import { SPACES } from "src/styles/sizes"

export const ContentContainer = styled(Container)`
  margin-top: ${SPACES.medium};
  margin-bottom: ${SPACES.large};
`

export const ContentSubHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${SPACES.medium};
`
