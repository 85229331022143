import React, { createContext, useContext } from "react"
import { number } from "prop-types"

const Context = createContext(null)

export const useGroupId = () => useContext(Context)

const GroupIdContext = ({ groupId, children }) => {
  return <Context.Provider value={groupId}>{children}</Context.Provider>
}

GroupIdContext.propTypes = {
  groupId: number.isRequired
}

export default GroupIdContext
