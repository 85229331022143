class @CodeInputComponent
  VALIDATION_REGEXP = /^\d{6}$/

  constructor: (inputElement, messageElement) ->
    @input = inputElement
    @message = messageElement

  isValid: () ->
    VALIDATION_REGEXP.test(@getCode())

  getCode: () ->
    @input.val()

  showError: (message = 'You need to enter 6-digits code') ->
    if @isValid
      @message.addClass('d-block')
      @message.text(message)

  hideError: () ->
    @message.removeClass('d-block')
