import React from "react"
import { styled } from "@linaria/react"
import { func, oneOf, string } from "prop-types"

import UnstyledButton from "src/components/UnstyledButton"
import Icon from "src/styles/components/Icon"
import { TEXT_COLORS, BASE_COLORS } from "src/styles/colors"
import { FONT_WEIGHTS } from "src/styles/sizes"

import { ACTIVE_TAB_KEY, TABS, TABS_ICONS } from "./constants"

const Tab = ({ activeTab, kind, setActive, className }) => {
  const iconType = () => TABS_ICONS[kind.toUpperCase()]

  const handleClick = () => {
    localStorage.setItem(ACTIVE_TAB_KEY, kind)
    setActive(kind)
  }

  return (
    <UnstyledButton
      className={`${activeTab === kind ? "active" : ""} ${className}`}
      onClick={handleClick}
    >
      <Icon type={iconType()} />
      <span className="text">{kind}</span>
    </UnstyledButton>
  )
}

Tab.propTypes = {
  className: string.isRequired,
  setActive: func.isRequired,
  kind: oneOf([...Object.values(TABS)]).isRequired,
  activeTab: oneOf([...Object.values(TABS)]).isRequired
}

const drawerActiveColor = BASE_COLORS.darkViolet

export default styled(Tab)`
  text-transform: uppercase;
  padding: 0 15px 10px;
  width: 100%;
  flex-direction: column;
  color: ${TEXT_COLORS.muted};
  font-weight: ${FONT_WEIGHTS.normal};

  .text {
    margin-left: 0;
    font-size: 0.9rem;
  }

  &.active {
    border-bottom: 1px solid ${drawerActiveColor};
    color: ${drawerActiveColor};
  }
`
