import { styled } from "@linaria/react"

import { SPACES } from "src/styles/sizes"

export const RadioRow = styled.div`
  padding: ${SPACES.small} ${SPACES.medium};

  &:first-child {
    padding-top: ${SPACES.medium};
  }

  &:last-child {
    padding-bottom: ${SPACES.medium};
  }
`

export const ButtonsContainer = styled.div`
  margin: 0 auto;
  width: 50%;
  display: flex;
  justify-content: space-around;
`
