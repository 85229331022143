import React, { useCallback } from "react"
import Flatpickr from "react-flatpickr"
import { func } from "prop-types"

import { momentType } from "src/constants/propTypes"

import { DatePickersContainer, DatePickerContainer } from "./styles"

const flatpikrOptions = { disableMobile: true, locale: { firstDayOfWeek: 1 } }

const DatePickers = ({ dateFrom, dateTo, setDateFrom, setDateTo }) => {
  const handleDateFromChange = useCallback(
    (dates) => setDateFrom(dates?.[0]),
    [setDateFrom]
  )
  const handleDateToChange = useCallback((dates) => setDateTo(dates?.[0]), [setDateTo])

  return (
    <DatePickersContainer>
      <DatePickerContainer className="mb-2">
        <Flatpickr
          options={flatpikrOptions}
          value={dateFrom?.format()}
          onChange={handleDateFromChange}
        />
        <span className="datepicker-placeholder">{dateFrom ? "" : "Start"}</span>
      </DatePickerContainer>
      <DatePickerContainer>
        <Flatpickr
          options={flatpikrOptions}
          value={dateTo?.format()}
          onChange={handleDateToChange}
        />
        <span className="datepicker-placeholder">{dateTo ? "" : "End"}</span>
      </DatePickerContainer>
    </DatePickersContainer>
  )
}

DatePickers.propTypes = {
  dateTo: momentType,
  dateFrom: momentType,
  setDateFrom: func,
  setDateTo: func
}

export default DatePickers
