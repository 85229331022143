import React, { forwardRef } from "react"

import { Button, IconContainer } from "./styles"

const CloseButton = forwardRef((props, ref) => {
  return (
    <Button type="button" aria-label="Close" {...props} ref={ref}>
      <IconContainer>&times;</IconContainer>
    </Button>
  )
})

export default CloseButton
