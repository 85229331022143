// TODO: remove this when these issues will be resolved:
//   https://github.com/froala/wysiwyg-editor/issues/4573
//   https://github.com/froala/wysiwyg-editor/issues/4592

const linkRegExp = /<a([^<>]*?) href="([^"]+?)"([^<>]*?)>([^<>]+?)<\/a>.+/

const corruptedSafariLink = 'href="%3Ca%20target="'

export const cleanupSafariLink = (html) => {
  if (!html || !html.includes(corruptedSafariLink)) return html

  return html.replace(linkRegExp, `<a$1 href="$4"$3>$4</a>`)
}

const corruptedLinkWithAmpersand =
  /<a([^<>]*?) href="([^"]+?&amp;)"([^<>]*?>[^<>]+?)<\/a>;(.+)/

export const cleanupLinkWithAmpersand = (html) => {
  if (!html || !corruptedLinkWithAmpersand.test(html)) return html

  return html.replace(corruptedLinkWithAmpersand, `<a$1 href="$2$4"$3</a>`)
}
