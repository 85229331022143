import React, { useState } from "react"
import { startCase } from "lodash"
import { bool, func, number, shape, string } from "prop-types"
import { useMutation } from "@apollo/client"

import RoleSubheader from "src/features/meetingPacks/AttendeesTable/ManageAgendaItemsModal/RoleSubheader"
import {
  MEMBERSHIP_TYPES_FOR,
  MEMBERSHIP_TYPES
} from "src/features/meetingPacks/MeetingPack/constants"
import { showFlashMessage } from "src/helpers/flash"
import Select from "src/styles/components/Select"
import PrimaryButton from "src/styles/components/Button/Primary"
import SecondaryButton from "src/styles/components/Button/Secondary"
import AttendeesNotification from "src/components/AttendeesNotification"
import Modal, { Title, Header, Footer } from "src/styles/components/Modal"
import useIsOpened from "src/hooks/useIsOpened"

import updateMembershipTypeMutation from "./updateMembershipType.gql"
import ChangedMembershipTypeReminder from "./ChangedMembershipTypeReminder"
import ManageAgendaItemsModal from "./ManageAgendaItemsModal"

const options = MEMBERSHIP_TYPES_FOR.dropdown.map((membershipType) => ({
  value: membershipType,
  label: startCase(membershipType)
}))

const ChangeMembershipTypeModal = ({
  show,
  onHide,
  user,
  meetingPack,
  isMeetingPackPublished
}) => {
  const {
    isOpened: isManageAgendaItemsModalOpened,
    close: closeManageAgendaItemsModal,
    open: openManageAgendaItemsModal
  } = useIsOpened()

  const [mutateAllAccessOnlyItemsToAttending, setMutateAllAccessOnlyItemsToAttending] =
    useState(false)

  const [selectedMembershipType, setSelectedMembershipType] = useState(
    options.find(({ value }) => value === user.membershipType)
  )

  const isChangingFromLimitedVisibilityType =
    MEMBERSHIP_TYPES_FOR.limitedVisibilityItems.includes(user.membershipType) &&
    !MEMBERSHIP_TYPES_FOR.limitedVisibilityItems.includes(selectedMembershipType.value)

  const isChangingFromApologies =
    MEMBERSHIP_TYPES.apologies === user.membershipType &&
    !MEMBERSHIP_TYPES_FOR.limitedVisibilityItems.includes(selectedMembershipType.value)

  const [updateMembershipType, { loading }] = useMutation(updateMembershipTypeMutation, {
    refetchQueries: ["meetingPackMemberships"],
    variables: {
      meetingPackId: meetingPack?.id,
      userId: user.id,
      membershipType: selectedMembershipType.value
    },
    onCompleted: () => {
      onHide()
      showFlashMessage("success", "User has been updated")
      if (isChangingFromApologies) setMutateAllAccessOnlyItemsToAttending(true)
      if (isChangingFromLimitedVisibilityType) {
        openManageAgendaItemsModal()
      }
    }
  })

  return (
    <>
      <ManageAgendaItemsModal
        show={isManageAgendaItemsModalOpened}
        onHide={() => {
          setMutateAllAccessOnlyItemsToAttending(false)
          closeManageAgendaItemsModal()
        }}
        user={user}
        meetingPack={meetingPack}
        isMeetingPackPublished={isMeetingPackPublished}
        mutateAllAccessOnlyItemsToAttending={mutateAllAccessOnlyItemsToAttending}
      />
      <Modal centered show={show} onHide={onHide}>
        <Header>
          <Title>Change {user.firstName}&apos;s Attendee type</Title>
        </Header>
        <RoleSubheader
          firstName={user.firstName}
          role={user.role}
          meetingPackName={meetingPack?.name}
        />
        <div className="d-flex justify-content-center py-4">
          <div className="w-75 mb-2">
            <p className="text-muted mb-2">Attendee type:</p>
            <Select
              data-testid="change-membership-type-select"
              className="w-100"
              options={options}
              value={selectedMembershipType}
              onChange={setSelectedMembershipType}
              maxMenuHeight={200}
            />

            {isChangingFromLimitedVisibilityType && (
              <ChangedMembershipTypeReminder
                firstName={user.firstName}
                membershipType={user.membershipType}
              />
            )}
          </div>
        </div>
        <Footer>
          <AttendeesNotification
            className="mb-3"
            published={isMeetingPackPublished}
            membershipType={selectedMembershipType.value}
          />

          <PrimaryButton
            className="mr-3 mb-1"
            disabled={loading}
            onClick={updateMembershipType}
          >
            Confirm
          </PrimaryButton>
          <SecondaryButton onClick={onHide}>Cancel</SecondaryButton>
        </Footer>
      </Modal>
    </>
  )
}

ChangeMembershipTypeModal.propTypes = {
  meetingPack: shape({
    id: number.isRequired,
    name: string.isRequired
  }).isRequired,
  show: bool.isRequired,
  onHide: func.isRequired,
  isMeetingPackPublished: bool.isRequired,
  user: shape({
    membershipType: string.isRequired,
    firstName: string.isRequired,
    role: string.isRequired
  })
}

export default ChangeMembershipTypeModal
