import documents from "src/api/meetingPacks/documents"
import { camelcaseKeys } from "src/helpers/object"

const useBoxFilesUpload = ({ agendaItemId, appendDocument, appendToPosition }) => {
  const uploadBoxFiles = (boxFiles) => {
    return Promise.all(
      boxFiles.map(({ id: boxFileId, name: filename }, index) =>
        documents
          .uploadFromBox({
            agendaItemId,
            boxFileId,
            filename,
            position: appendToPosition + index
          })
          .then((response) => {
            const document = response?.data?.document
            const jwt = response?.data?.jwt

            if (!document) {
              throw Error(
                "Oh no, the document was not uploaded. Please try again or contact support."
              )
            }

            appendDocument(camelcaseKeys({ ...document, jwt }))

            return response
          })
          .catch((error) => {
            throw error
          })
      )
    )
  }

  return { uploadBoxFiles }
}

export default useBoxFilesUpload
