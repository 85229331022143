class @AddSchemeUsersComponent
  constructor: () ->
    @addNewSchemeUsersComponent = new AddNewSchemeUsersComponent()
    @addAccountMembersComponent = new AddAccountMembersComponent()

  setup: =>
    @addNewSchemeUsersComponent.setup()

    $be('back-to-add-account-members-link').click (e) =>
      e.preventDefault()
      $.get($(e.target).attr('href')).
        done((response) =>
          @addAccountMembersComponent.modal.html(response.list)
          @addAccountMembersComponent.setup()
          @addNewSchemeUsersComponent.modal.modal('hide')
          @addAccountMembersComponent.modal.modal('show')
        ).
        fail(->
          $showFlashMessage('danger', 'Failed to edit user info.')
        )

  hideModals: =>
    @addNewSchemeUsersComponent.modal.modal('hide')
    @addAccountMembersComponent.modal.modal('hide')
