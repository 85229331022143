import { bool, func } from "prop-types"
import React from "react"

const SubmitButton = ({ children, onClick, disabled = false }) => {
  return (
    <button type="submit" className="button" onClick={onClick} disabled={disabled}>
      {children}
    </button>
  )
}

SubmitButton.propTypes = {
  onClick: func.isRequired,
  disabled: bool
}

export default SubmitButton
