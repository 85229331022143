import React from "react"
import { shape, string, number, oneOf } from "prop-types"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

import Icon from "src/styles/components/Icon"
import TextWithTooltip from "src/styles/components/TextWithTooltip"

import MetadataLink from "../MedatataLink"
import { DrawerItem, DrawerItemWrapper, EntityName } from "../styles"
import { CONTENT_LINK_TYPES } from "../constants"

const EntityRow = ({ contentLink }) => {
  const authorsName = contentLink.source.author.fullName
  const { threadId, createdAt } = contentLink.source
  const messageId = contentLink.source.id

  const iconType = () => {
    if (contentLink.kind === CONTENT_LINK_TYPES.EXTERNAL) {
      return "screen-share"
    }

    if (!contentLink.targetType && contentLink.parentTargetType === "Group") {
      return "folder"
    }

    if (contentLink.targetType?.includes("Apps::")) {
      return "arrow-right-squared"
    }

    const iconMap = {
      PrivateScheme: "lock",
      JointScheme: "lock",
      Scheme: "lock",
      AttachedDocument: "paperclip",
      Message: "discussion-message",
      "MeetingPacks::AgendaItem": "list-numbered",
      "MeetingPacks::MeetingPack": "list-numbered",
      "ContentCloud::Folder": "folder",
      "ContentCloud::File": "document",
      "MeetingPacks::AgendaItemDocument": "document",
      Chat: "discussion",
      Discussion: "discussion",
      Group: "user-supervisor"
    }

    return iconMap[contentLink.targetType] || "link"
  }

  const entityHumanName = () => {
    if (contentLink.kind === CONTENT_LINK_TYPES.EXTERNAL) {
      return "External"
    }

    if (!contentLink.targetType && contentLink.parentTargetType === "Group") {
      return "Group Documents root"
    }

    if (contentLink.targetType?.includes("Apps::")) {
      return "App"
    }

    const nameMap = {
      PrivateScheme: "Space",
      JointScheme: "Space",
      Scheme: "Space",
      AttachedDocument: "Attachment",
      "MeetingPacks::AgendaItem": "Agenda Item",
      "MeetingPacks::MeetingPack": "Meeting Pack",
      "ContentCloud::Folder": "Folder",
      "ContentCloud::File": "Document",
      "MeetingPacks::AgendaItemDocument": "Meeting Document"
    }

    return nameMap[contentLink.targetType] || contentLink.targetType
  }

  const linkTarget = contentLink.kind === CONTENT_LINK_TYPES.EXTERNAL ? "_blank" : "_self"

  return (
    <DrawerItem data-id={contentLink.id}>
      <DrawerItemWrapper className="text-link">
        {/* eslint-disable jsx-a11y/control-has-associated-label */}
        <OverlayTrigger
          delay={300}
          placement="left"
          overlay={<Tooltip>{entityHumanName()}</Tooltip>}
        >
          <a href={contentLink.url} target="_blank" rel="noreferrer">
            <Icon type={iconType()} size="medium" className="mr-2" />
          </a>
        </OverlayTrigger>
        {/* eslint-enable jsx-a11y/control-has-associated-label */}
        <div>
          <a
            href={contentLink.url}
            target={linkTarget}
            aria-label="entity-name"
            rel="noreferrer"
            className="entity-name"
          >
            <EntityName>
              <TextWithTooltip text={contentLink.viewTitle} />
            </EntityName>
          </a>
          <MetadataLink
            href={`/discussions/${threadId}?message_id=${messageId}`}
            createdAt={createdAt}
            authorsName={authorsName}
          />
        </div>
      </DrawerItemWrapper>
    </DrawerItem>
  )
}

EntityRow.propTypes = {
  contentLink: shape({
    id: number.isRequired,
    viewTitle: string.isRequired,
    parentTargetType: string,
    targetType: string,
    kind: oneOf([...Object.values(CONTENT_LINK_TYPES)]).isRequired,
    source: shape({
      id: number.isRequired,
      threadId: number.isRequired,
      createdAt: string.isRequired,
      author: shape({
        fullName: string.isRequired
      }).isRequired
    }).isRequired
  }).isRequired
}

export default EntityRow
