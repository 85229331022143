import React from "react"
import { func } from "prop-types"
import { styled } from "@linaria/react"
import classNames from "classnames"

import SearchableSelect from "src/components/SearchableSelect"
import { sortByType } from "src/resources/accounts/airtablePropTypes"

import FieldSearchableSelect from "./FieldSearchableSelect"
import { SORT_DIRECTIONS, DEFAULT_SORT_DIRECTION } from "./FiltersList/constants"

const SortBy = ({ sortBy, setSortBy, className }) => {
  const sortDirectionOptions = Object.keys(SORT_DIRECTIONS).map((direction) => ({
    value: direction,
    label: SORT_DIRECTIONS[direction]
  }))
  const filterTypeOption = sortDirectionOptions.find(
    (option) => option.value === sortBy.direction
  )
  const defaultFilterTypeOption = sortDirectionOptions.find(
    (option) => option.value === DEFAULT_SORT_DIRECTION
  )

  return (
    <div
      className={classNames(className, "d-flex align-items-center")}
      data-testid="sort-by"
    >
      <div className="pr-2 title">Sort by</div>
      <FieldSearchableSelect
        value={sortBy.fieldId || ""}
        onChange={(option) => setSortBy({ ...sortBy, fieldId: option.value })}
        containerClassName="btn-block mt-1"
      />
      <SearchableSelect
        options={sortDirectionOptions}
        selected={filterTypeOption || defaultFilterTypeOption}
        setSelected={(option) => setSortBy({ ...sortBy, direction: option.value })}
        searchable={false}
        useSelectedLabel
        className="mt-1 ml-2"
      />
    </div>
  )
}

SortBy.propTypes = {
  sortBy: sortByType,
  setSortBy: func.isRequired
}

export default styled(SortBy)`
  .title {
    white-space: nowrap;
  }

  .btn {
    background: none;
  }
`
