import React from "react"
import { bool, func } from "prop-types"
import { Spinner } from "react-bootstrap"

import PrimaryButton from "src/styles/components/Button/Primary"
import { showFlashMessage } from "src/helpers/flash"

const SaveButton = ({
  handleSave,
  saveInProgress,
  isLastSection,
  someFieldsEditable,
  goToNextSection
}) => {
  const showSuccessMessage = () => showFlashMessage("success", "Saved")

  const successCallback = isLastSection ? showSuccessMessage : goToNextSection

  let buttonText
  if (saveInProgress) buttonText = "Saving..."
  else if (isLastSection) buttonText = "Save"
  else if (someFieldsEditable) buttonText = "Save and Next"
  else buttonText = "Next"

  return (
    <div>
      <PrimaryButton
        className="mt-3 px-4"
        onClick={() => handleSave({ onSuccess: successCallback })}
        disabled={saveInProgress}
      >
        {buttonText}
        {saveInProgress && (
          <span className="pl-1">
            <Spinner animation="border" variant="light" size="sm" />
          </span>
        )}
      </PrimaryButton>
    </div>
  )
}

SaveButton.propTypes = {
  handleSave: func.isRequired,
  saveInProgress: bool.isRequired,
  isLastSection: bool.isRequired,
  someFieldsEditable: bool.isRequired,
  goToNextSection: func.isRequired
}

export default SaveButton
