import IdbStore from "src/features/offline/helpers/IdbStore"

const STORE_NAME = "SIGNATURES"
const STRING_SIGNATURES_KEY = "STRING_SIGNATURES"

// TODO: remove this file in September when all users will have cleaned up IndexDB stores
const clearOutdatedSignatures = () => {
  const store = new IdbStore({ storeName: STORE_NAME })

  store.get(STRING_SIGNATURES_KEY).then((signaturesString) => {
    if (signaturesString) store.clear()
  })
}

export default clearOutdatedSignatures
