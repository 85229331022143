import apiClient from "src/app/apiClient"

export const createNotification = ({
  documentId,
  pspdfkitId,
  userId,
  comment = false
}) => {
  return apiClient.post(`/api/collaborative_documents/${documentId}/notifications`, {
    pspdfkitId,
    userId,
    comment
  })
}

export const deleteNotification = ({ documentId, pspdfkitId }) => {
  return apiClient.delete(`/api/collaborative_documents/${documentId}/notifications`, {
    data: { pspdfkitId }
  })
}

export const updateAnnotationsCount = ({ documentId }) => {
  return apiClient.put(
    `/api/collaborative_documents/${documentId}/update_annotations_count`
  )
}
