import React from "react"
import { Modal } from "react-bootstrap"
import { shape, bool, func, string, number } from "prop-types"

import UserAvatar from "./UserAvatar"
import { DEFAULT_TYPE } from "./constants"

const ContactDetailsModal = ({
  close,
  isOpened,
  isCurrentUser,
  user = {},
  type = DEFAULT_TYPE,
  size = "md"
}) => {
  if (!isOpened) {
    return null
  }
  return (
    <Modal size={size} onHide={close} show={isOpened}>
      <Modal.Header closeButton>
        <Modal.Title>{`${type} Details`}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className="card-mascot large mvx">
          <UserAvatar
            user={user}
            size="large"
            className="d-flex justify-content-center"
          />
        </div>
        <h4>
          {user.fullName}
          {isCurrentUser ? <span>&nbsp;(you)</span> : null}
        </h4>
        <div className="text-muted mbs">{user.jobTitle}</div>
        <div className="pbs">
          <span className="text-muted">Email:&nbsp;</span>
          <span>
            <a href={`mailto:${user.email}`}>{user.email}</a>
          </span>
        </div>
        {!isCurrentUser && (
          <div className="pas">
            <a className="button" href={`/chats/new?interlocutor_id=${user.id}`}>
              Start Chat
            </a>
          </div>
        )}
      </Modal.Body>
    </Modal>
  )
}

ContactDetailsModal.propTypes = {
  close: func.isRequired,
  isOpened: bool.isRequired,
  isCurrentUser: bool.isRequired,
  user: shape({
    id: number.isRequired,
    fullName: string.isRequired,
    jobTitle: string,
    email: string.isRequired
  }),
  type: string,
  size: string
}

export default ContactDetailsModal
