import React from "react"
import { Formik } from "formik"
import { Form, InputGroup } from "react-bootstrap"
import { useMutation } from "@apollo/client"
import { string, func, bool, number } from "prop-types"

import { StyledModal } from "src/styles/components/Modal"
import ModalHeader from "src/styles/components/Modal/Header"
import ModalBody from "src/styles/components/Modal/Body"
import ModalFooter from "src/styles/components/Modal/Footer"
import { showFlashMessage } from "src/helpers/flash"

import updateUserMutation from "./updateUserMutation.gql"

const EditUserModal = ({ accountId, userId, jobTitle, close, isOpened }) => {
  const [updateUser, { loading }] = useMutation(updateUserMutation, {
    refetchQueries: ["accountMemberships"]
  })

  const updateUserAndClose = (values) => {
    updateUser({ variables: { accountId, userId, jobTitle: values.jobTitle } }).then(
      ({ data: result = {} }) => {
        if (result.updateUser?.successful) {
          showFlashMessage("success", "User has saved")
        } else if (result.updateUser?.errors?.length) {
          showFlashMessage("danger", result.updateUser.errors.join(". "))
        }

        close()
      }
    )
  }

  return (
    <StyledModal size="md" onHide={close} show={isOpened}>
      <Formik initialValues={{ jobTitle }} onSubmit={updateUserAndClose}>
        {({ values, handleChange, handleSubmit }) => (
          <>
            <ModalHeader className="d-flex justify-content-between">
              <h5 className="flex-grow-1">Edit role</h5>
            </ModalHeader>

            <ModalBody>
              <Form.Group controlId="jobTitle">
                <Form.Label>
                  <h6>Job Title</h6>
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    value={values.jobTitle || ""}
                    placeholder="e.g. CEO"
                    onChange={handleChange}
                  />
                </InputGroup>
              </Form.Group>
            </ModalBody>

            <ModalFooter className="d-flex">
              <div className="form-main-controls actions mtm">
                <button
                  type="submit"
                  className="button"
                  disabled={loading}
                  onClick={() => handleSubmit(values)}
                >
                  Apply
                </button>
                <button className="button is-secondary" onClick={close}>
                  Cancel
                </button>
              </div>
            </ModalFooter>
          </>
        )}
      </Formik>
    </StyledModal>
  )
}

EditUserModal.propTypes = {
  accountId: number.isRequired,
  userId: number.isRequired,
  jobTitle: string,
  close: func.isRequired,
  isOpened: bool
}

export default EditUserModal
