import { connect } from "react-redux"

import {
  deleteDocument,
  updatePosition
} from "src/features/meetingPacks/AgendaItem/agendaItemSlice"

import DocumentsList from "./DocumentsList"

const mapState = ({ agendaItem }) => ({
  documents: agendaItem.documents.map((document) => ({ ...document })),
  agendaItemId: agendaItem.id
})

const mapDispatch = (dispatch) => ({
  deleteDocument({ id, confirmationMessage }) {
    dispatch(deleteDocument({ id, confirmationMessage }))
  },
  updatePosition(params) {
    dispatch(updatePosition(params))
  }
})

export default connect(mapState, mapDispatch)(DocumentsList)
