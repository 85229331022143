import apiClient from "src/app/apiClient"

export const acceptCustomTerms = ({
  accountId,
  customTermsInstanceId,
  invitationToken
}) => {
  return apiClient.post(`/api/users/accept_custom_terms`, {
    accountId,
    customTermsInstanceId,
    invitationToken
  })
}
