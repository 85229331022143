import React, { useState } from "react"
import { Accordion, Card } from "react-bootstrap"
import { arrayOf, func } from "prop-types"
import { HTML5Backend } from "react-dnd-html5-backend"
import { DndProvider } from "react-dnd"

import AccordionToggle from "src/styles/components/AccordionToggle"
import { airtableSectionType } from "src/resources/accounts/airtablePropTypes"
import useIsOpened from "src/hooks/useIsOpened"
import LinkButton from "src/styles/components/Button/Link"

import EditModal from "./EditModal"
import SectionRow from "./SectionRow"

const Sections = ({ sections, setSections }) => {
  const [sectionForEdit, setSectionForEdit] = useState()

  const { isOpened: isOpenedModal, open: openModal, close: closeModal } = useIsOpened()

  const openEditSectionModal = (section) => {
    setSectionForEdit(section)
    openModal()
  }

  return (
    <>
      <div className="container my-3">
        <Card className="px-5">
          <div className="d-flex align-items-center">
            <Accordion.Toggle as="div" eventKey="1">
              <div className="d-flex align-items-center">
                <AccordionToggle eventKey="1" />
                <Card.Title>Select fields to display on expanded record view</Card.Title>
              </div>

              <div className="mb-2">
                <small>Choose fields and split into sections</small>
              </div>
            </Accordion.Toggle>
          </div>

          <Accordion.Collapse eventKey="1">
            <>
              <DndProvider backend={HTML5Backend}>
                {sections.map((section, i) => (
                  <SectionRow
                    key={section.id}
                    index={i}
                    section={section}
                    setSections={setSections}
                    openEditSectionModal={openEditSectionModal}
                  />
                ))}
              </DndProvider>

              <LinkButton className="mt-3" onClick={() => openEditSectionModal()}>
                + Add new section
              </LinkButton>
            </>
          </Accordion.Collapse>
        </Card>
      </div>

      <EditModal
        close={closeModal}
        isOpened={isOpenedModal}
        section={sectionForEdit}
        setSections={setSections}
      />
    </>
  )
}

Sections.propTypes = {
  sections: arrayOf(airtableSectionType),
  setSections: func.isRequired
}

export default Sections
