import React from "react"
import { string } from "prop-types"

import { ResultText } from "./styles"

const Text = ({ text }) => {
  if (!text) {
    return null
  }

  return <ResultText dangerouslySetInnerHTML={{ __html: text }} />
}

Text.propTypes = {
  text: string
}

export default Text
