import React from "react"
import { instanceOf } from "prop-types"

import UploadFolder from "src/features/groups/DocumentsExplorer/UploadFolder"

import { FailedMessage, UploadedMessage, CreatedMessage } from "./styles"

const InfoBlock = ({ folder }) => {
  const message = `${folder.totalUploadedFilesCount()} / ${folder.totalFilesCount()}`

  return (
    <>
      {folder.isCreated() && !folder.isUploadFinished() && (
        <CreatedMessage>{message}</CreatedMessage>
      )}
      {folder.isUploadFinished() && folder.isSomeUploadFailed() && (
        <FailedMessage>{message}</FailedMessage>
      )}
      {folder.isUploadFinished() && !folder.isSomeUploadFailed() && (
        <UploadedMessage>{message}</UploadedMessage>
      )}
    </>
  )
}

InfoBlock.propTypes = {
  folder: instanceOf(UploadFolder).isRequired
}

export default InfoBlock
