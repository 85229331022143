import React from "react"
import { Card } from "react-bootstrap"

import Contacts from "./Contacts"
import { ContentContainer } from "./styles"

const MeetingPackAttendees = () => {
  return (
    <ContentContainer>
      <Card body>
        <Contacts />
      </Card>
    </ContentContainer>
  )
}

export default MeetingPackAttendees
