import React, { useState } from "react"
import { func, string } from "prop-types"

import { StyledModal } from "src/styles/components/Modal"
import ModalTitle from "src/styles/components/Modal/Title"
import ModalHeader from "src/styles/components/Modal/Header"
import { TWO_FACTOR_TOOLS } from "src/features/TwoFactor/constants"

import EnableByPhone from "./ByPhone/Modal"
import EnableByApp from "./ByApp/Modal"

const TwoFactorEnableModal = ({ close, qrCode, otpSecret, className }) => {
  const [twoFactorTool, setTwoFactorTool] = useState(TWO_FACTOR_TOOLS.app)

  return (
    <StyledModal
      size="md"
      onHide={close}
      show
      className={className}
      data-testid="two-factor-enable-modal"
    >
      <ModalHeader>
        <ModalTitle>Enable 2-factor authentication</ModalTitle>
      </ModalHeader>
      {twoFactorTool === TWO_FACTOR_TOOLS.app ? (
        <EnableByApp
          twoFactorTool={twoFactorTool}
          setTwoFactorTool={setTwoFactorTool}
          qrCode={qrCode}
          otpSecret={otpSecret}
          close={close}
        />
      ) : (
        <EnableByPhone
          twoFactorTool={twoFactorTool}
          setTwoFactorTool={setTwoFactorTool}
          close={close}
        />
      )}
    </StyledModal>
  )
}

TwoFactorEnableModal.propTypes = {
  close: func.isRequired,
  qrCode: string.isRequired,
  otpSecret: string.isRequired
}

export default TwoFactorEnableModal
