import React, { useCallback, useState } from "react"
import { Modal } from "react-bootstrap"
import { bool, func, number, string } from "prop-types"
import { useApolloClient } from "@apollo/client"

import PrimaryButton from "src/styles/components/Button/Primary"
import SecondaryButton from "src/styles/components/Button/Secondary"
import Radio from "src/styles/components/Radio"
import { showFlashMessage } from "src/helpers/flash"

import AttendeesList from "./AttendeesList"
import { RadioRow, ButtonsContainer } from "./styles"
import exportMeetingMutation from "./exportMeeting.gql"
import exportMeetingForUsersMutation from "./exportMeetingForUsers.gql"
import ExportSettings from "./ExportSettings"

const EXPORT_MODES = {
  admin: "admin",
  users: "users"
}

const DEFAULT_EXPORT_SETTINGS = {
  includeCoverPage: true,
  includeAgendaPage: true,
  includeAttendeesPage: true,
  includeAgendaItemTitlePages: true,
  includeSubItemTitlePages: true,
  includeDocuments: true
}

const ExportModal = ({ isOpened, close, id, name }) => {
  const [isExporting, setIsExporting] = useState(false)
  const [exportMode, setExportMode] = useState(EXPORT_MODES.admin)
  const setAdminMode = useCallback(() => setExportMode(EXPORT_MODES.admin), [])
  const setUsersMode = useCallback(() => setExportMode(EXPORT_MODES.users), [])
  const [checkedUsersIds, setCheckedUsersIds] = useState([])
  const [exportSettings, setExportSettings] = useState(DEFAULT_EXPORT_SETTINGS)

  const toggleUserId = useCallback(
    (newUserId) =>
      setCheckedUsersIds((usersIds) =>
        usersIds.some((userId) => userId === newUserId)
          ? usersIds.filter((userId) => userId !== newUserId)
          : [...usersIds, newUserId]
      ),
    []
  )
  const resetModalState = () => {
    setExportMode(EXPORT_MODES.admin)
    setCheckedUsersIds([])
  }

  const client = useApolloClient()

  const exportMeeting = useCallback(() => {
    setIsExporting(true)

    const mutatePromise =
      exportMode === EXPORT_MODES.admin
        ? client.mutate({
            mutation: exportMeetingMutation,
            variables: { id, settings: exportSettings }
          })
        : client.mutate({
            mutation: exportMeetingForUsersMutation,
            variables: { id, users: checkedUsersIds, settings: exportSettings }
          })

    mutatePromise
      .then(() => {
        close()
        resetModalState()
        showFlashMessage(
          "success",
          "A link to the export will be emailed to you shortly. If not, please check for print restrictions on your files."
        )
      })
      .catch(() => {
        showFlashMessage("danger", "An error happened while exporting the meeting pack")
      })
      .finally(() => setIsExporting(false))
  }, [exportMode, JSON.stringify(checkedUsersIds), JSON.stringify(exportSettings)])

  const exportSettingsNames = Object.keys(exportSettings)

  const isAnyExportSettingSelected = exportSettingsNames.some(
    (setting) => exportSettings[setting]
  )

  const partiallySelected =
    exportSettingsNames.length !==
    exportSettingsNames.filter((setting) => exportSettings[setting]).length

  const isExportDisabled =
    isExporting ||
    (exportMode === EXPORT_MODES.users && checkedUsersIds.length === 0) ||
    !isAnyExportSettingSelected

  return (
    <Modal size="md" show={isOpened} onHide={close}>
      <Modal.Header>
        <Modal.Title>Export {name} to PDF</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 allow-scroll">
        <div>
          <RadioRow>
            <Radio
              id="export-mode-admin"
              label="Export whole meeting pack"
              checked={exportMode === EXPORT_MODES.admin}
              onChange={setAdminMode}
            />
          </RadioRow>
          <RadioRow>
            <Radio
              id="export-mode-users"
              label="Export meeting pack for specific attendees"
              checked={exportMode === EXPORT_MODES.users}
              onChange={setUsersMode}
            />
          </RadioRow>
        </div>
        <AttendeesList
          checkedUsersIds={checkedUsersIds}
          toggleUserId={toggleUserId}
          meetingPackId={id}
          isOpened={exportMode === EXPORT_MODES.users}
        />
        <ExportSettings
          settings={exportSettings}
          onChange={setExportSettings}
          expanded={partiallySelected}
        />
      </Modal.Body>
      <Modal.Footer>
        <ButtonsContainer>
          <PrimaryButton disabled={isExportDisabled} onClick={exportMeeting}>
            {isExporting ? "Exporting..." : "Export"}
          </PrimaryButton>
          <SecondaryButton onClick={close}>Cancel</SecondaryButton>
        </ButtonsContainer>
      </Modal.Footer>
    </Modal>
  )
}

ExportModal.propTypes = {
  close: func.isRequired,
  isOpened: bool.isRequired,
  id: number.isRequired,
  name: string
}

export default ExportModal
