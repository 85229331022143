import { connect } from "react-redux"

import {
  publish,
  unpublish
} from "src/features/meetingPacks/MeetingPack/thunks/meetingPack"

import PublishButton from "./PublishButton"

const mapState = ({ meetingPack: { meetingPack } }) => ({
  meetingPack
})

const mapDispatch = (dispatch) => ({
  publishMeetingPack({ meetingPackId, customMessage }) {
    return dispatch(publish({ meetingPackId, customMessage }))
  },
  unpublishMeetingPack({ meetingPackId, confirmationMessage }) {
    return dispatch(unpublish({ meetingPackId, confirmationMessage }))
  }
})

export default connect(mapState, mapDispatch)(PublishButton)
