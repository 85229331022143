import { be } from "src/helpers/document"
import countDown from "src/helpers/countDown"
import TwoFactorService from "legacy/services/two_factor_service"

const numberShortcut = (number) => `***${number.slice(-3)}`
const errorMessage = (response) =>
  response.responseJSON ? response.responseJSON.error : response.statusText

export class TwoFactorEnableComponent {
  constructor(params) {
    this.setup = this.setup.bind(this)
    this.goToStepOne = this.goToStepOne.bind(this)
    this.goToStepTwo = this.goToStepTwo.bind(this)
    this.goToStepThree = this.goToStepThree.bind(this)
    this.cancel = this.cancel.bind(this)
    this.continue = this.continue.bind(this)
    this.setStepTwoButtonCountdown = this.setStepTwoButtonCountdown.bind(this)

    this.params = params

    this.phoneInput = new window.PhoneInputComponent(
      be("two-factor-phone-input"),
      be("two-factor-phone-input-message")
    )

    this.codeInput = new window.CodeInputComponent(
      be("two-factor-code-input"),
      be("two-factor-code-input-message")
    )

    this.stepOneContent = be("two-factor-step-one-content")
    this.stepTwoContent = be("two-factor-step-two-content")
    this.stepThreeContent = be("two-factor-step-three-content")
    this.enteredNumberShortcut = be("entered-number-shortcut")

    this.stepOneButton = be("step-one-button")
    this.stepTwoButton = be("step-two-button")

    this.cancelButton = be("cancel-two-factor-enabling")
    this.verifyButton = be("verify-code")
    this.continueButton = be("two-factor-continue")

    this.sendingAttempts = 0

    this.service = new TwoFactorService()
  }

  setup() {
    this.stepOneButton.click((e) => {
      e.preventDefault()
      this.goToStepOne()
    })

    this.stepTwoButton.click(() => this.goToStepTwo())
    this.verifyButton.click(() => this.goToStepThree())
    this.cancelButton.click(() => this.cancel())
    this.continueButton.click(() => this.continue())
  }

  goToStepOne() {
    this.stepOneContent.removeClass("hidden")
    this.stepTwoContent.addClass("hidden")
  }

  goToStepTwo() {
    if (this.phoneInput.isValid()) {
      this.stepTwoButton.attr("disabled", "disabled")

      this.service
        .sendNumber(this.phoneInput.getNumber())
        .done(() => {
          this.sendingAttempts += 1
          this.enteredNumberShortcut.text(numberShortcut(this.phoneInput.getNumber()))
          this.stepTwoContent.removeClass("hidden")
          this.stepOneContent.addClass("hidden")

          if (this.sendingAttempts > 1) this.setStepTwoButtonCountdown()
        })
        .fail((error) => this.phoneInput.showError(errorMessage(error)))
        .always(() => this.stepTwoButton.attr("disabled", null))
    } else this.phoneInput.showError()
  }

  goToStepThree() {
    if (this.codeInput.isValid()) {
      this.verifyButton.attr("disabled", "disabled")

      this.service
        .sendCode(this.codeInput.getCode())
        .done(() => {
          this.stepThreeContent.removeClass("hidden")
          this.stepTwoContent.addClass("hidden")
        })
        .fail((error) => this.codeInput.showError(errorMessage(error)))
        .always(() => this.verifyButton.attr("disabled", null))
    } else this.codeInput.showError()
  }

  cancel() {
    this.params.onCancel()
  }

  continue() {
    this.params.onContinue()
  }

  setStepTwoButtonCountdown() {
    const countdownTime = 60
    const initialButtonContent = this.stepTwoButton.html()

    countDown(
      countdownTime,
      (seconds) => {
        this.stepTwoButton.attr("disabled", "disabled")
        this.stepTwoButton.text(`You can resend the code again in ${seconds} seconds`)
      },
      () => {
        this.sendingAttempts -= 1
        this.stepTwoButton.html(initialButtonContent)
        this.stepTwoButton.attr("disabled", null)
      }
    )
  }
}

window.TwoFactorEnableComponent = TwoFactorEnableComponent
