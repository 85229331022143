import { useCallback, useState } from "react"

const useIsOpened = (defaultIsOpened = false) => {
  const [isOpened, setIsOpened] = useState(defaultIsOpened)

  const open = useCallback(() => setIsOpened(true), [])
  const close = useCallback(() => setIsOpened(false), [])
  const toggle = useCallback(() => setIsOpened(!isOpened), [isOpened])

  return { isOpened, open, close, toggle }
}

export default useIsOpened
