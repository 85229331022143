import { styled } from "@linaria/react"

import { Button as BaseButton } from "src/features/groups/DocumentsExplorer/styles"
import BaseIcon from "src/styles/components/Icon"
import { BREAKPOINTS } from "src/styles/sizes"

export const Container = styled.div`
  background-color: white;
  flex-grow: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 8px;
`

export const DeselectAllButton = styled(BaseButton)`
  padding: 0;
  height: 34px;
  border: 2px solid #e8e8e8;
  border-radius: 17px;
  padding-left: 15px;
  padding-right: 15px;

  &:hover {
    background-color: #edeff1;
  }
`

export const IconClose = styled.span`
  font-size: 24px;
  font-weight: 200;
  line-height: 1;
  position: relative;
  bottom: 1px;
  margin-right: 5px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`

export const IconPencil = styled(BaseIcon)`
  font-size: 14px;
`

export const DeselectAllButtonLabel = styled.span`
  @media (max-width: ${BREAKPOINTS.xSmall}) {
    display: none;
  }
`
