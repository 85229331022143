import React from "react"

import { Backdrop, ModalContainer } from "./styles"

const Modal = ({ children }) => {
  return (
    <Backdrop>
      <ModalContainer>{children}</ModalContainer>
    </Backdrop>
  )
}

export default Modal
