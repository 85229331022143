import React from "react"
import { arrayOf, func, number, string } from "prop-types"

import { momentType, optionType } from "src/constants/propTypes"
import { ErrorBoundary } from "src/app/ErrorBoundary"
import useIsOpened from "src/hooks/useIsOpened"

import { FiltersTitle, FiltersContainer, FiltersWithToggleContainer } from "./styles"
import MobileFilterToggle from "./MobileFilterToggle"
import WhereFilter from "./WhereFilter"
import WhenFilter from "./WhenFilter"
import WhoFilter from "./WhoFilter"

const Filters = ({
  whereFilter,
  addWhereFilterValue,
  removeWhereFilterValue,
  whereFilterOptions,
  whenFilter,
  setWhenFilter,

  whoFilter,
  whoFilterOptions,
  addWhoFilterValue,
  removeWhoFilterValue,
  dateTo,
  dateFrom,
  setDateFrom,
  setDateTo
}) => {
  const { isOpened, toggle } = useIsOpened(false)

  const appliedFiltersCount = [
    whereFilter.length > 0 ? 1 : 0,
    whenFilter.value ? 1 : 0,
    whoFilter.length > 0 ? 1 : 0
  ].reduce((a, b) => a + b, 0)

  return (
    <ErrorBoundary fallback={null}>
      <FiltersWithToggleContainer className={isOpened ? "shown-on-mobile" : ""}>
        <MobileFilterToggle
          isFilterShown={isOpened}
          toggleFilterShown={toggle}
          appliedFiltersCount={appliedFiltersCount}
        />
        <FiltersContainer>
          <FiltersTitle>Filter your search</FiltersTitle>
          <WhereFilter
            whereFilter={whereFilter}
            addWhereFilterValue={addWhereFilterValue}
            removeWhereFilterValue={removeWhereFilterValue}
            whereFilterOptions={whereFilterOptions}
          />
          <WhenFilter
            whenFilter={whenFilter}
            setWhenFilter={setWhenFilter}
            dateTo={dateTo}
            dateFrom={dateFrom}
            setDateFrom={setDateFrom}
            setDateTo={setDateTo}
          />
          <WhoFilter
            whoFilter={whoFilter}
            whoFilterOptions={whoFilterOptions}
            addWhoFilterValue={addWhoFilterValue}
            removeWhoFilterValue={removeWhoFilterValue}
          />
        </FiltersContainer>
      </FiltersWithToggleContainer>
    </ErrorBoundary>
  )
}

Filters.propTypes = {
  whereFilter: arrayOf(string),
  addWhereFilterValue: func.isRequired,
  whereFilterOptions: arrayOf(optionType),
  removeWhereFilterValue: func.isRequired,
  whenFilter: optionType,
  setWhenFilter: func.isRequired,
  setDateFrom: func.isRequired,
  setDateTo: func.isRequired,
  dateTo: momentType,
  dateFrom: momentType,
  whoFilter: arrayOf(number),
  whoFilterOptions: arrayOf(optionType),
  addWhoFilterValue: func.isRequired,
  removeWhoFilterValue: func.isRequired
}

export default Filters
