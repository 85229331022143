import { toCamelCase } from "src/helpers/string"

export const camelcaseKeys = (object) => {
  if (Array.isArray(object)) {
    return object.map((objectItem) => camelcaseKeys(objectItem))
  }

  if (!(object instanceof Object)) {
    return object
  }

  return Object.fromEntries(
    Object.entries(object).map(([key, value]) => [
      toCamelCase(key),
      value instanceof Object ? camelcaseKeys(value) : value
    ])
  )
}
