class @ChatMembersDialogComponent
  constructor: ->
    @preselectedUserIds = window.chatMemberIds
    @filterInputComponent = new FilterInputComponent(
      input:        'start-chat-filter',
      items:        'user-pick-card',
      emptyMessage: 'chat-filter-empty'
    )

  setup: =>
    @filterInputComponent.setup()
    @bindDialogControls()
    @checkPreselectedUsers()
    updateDialogControls()

  checkPreselectedUsers: =>
    $(@preselectedUserIds).each (idx, userId) ->
      $beById('available-user-picker', userId).click()

  bindDialogControls: =>
    $be('chat-manage-members-modal').on 'hide.bs.modal', =>
      resetDialogControls(@preselectedUserIds)

    $be('selected-users-count').click (e) ->
      e.preventDefault()
      $be('modal-container').scrollTop(0)

    $be('available-user-picker').change ->
      picker = $(this)
      userId = picker.data('id')
      card = picker.parents('[data-behavior=user-pick-card]')
      card.toggleClass('is-selected', picker.is(":checked"))
      if picker.is(":checked")
        appendSelectedCard(card, userId)
      else
        removeSelectedCard(userId)
      updateDialogControls()

    $be('start-chat-submit').click (e) ->
      e.preventDefault()
      $be('start-chat-form').submit()
      $be('chat-manage-members-modal').modal('hide')

  bindSelectedUserPicker = (element) ->
    element.change ->
      picker = $(this)
      userId = picker.data('id')
      card = picker.parents('[data-behavior=user-pick-card]')
      unless picker.is(":checked")
        card.remove()
        availableUserCard = $beById('user-pick-card', userId)
        availableUserCard.removeClass('is-selected')
        availableUserCard.
          find('[data-behavior=available-user-picker]').
          prop('checked', false)
        updateDialogControls()

  appendSelectedCard = (card, userId) ->
    return if $beById('selected-user-picker', userId).length

    selectedUserCard = card.clone()
    picker = selectedUserCard.find('[data-behavior=available-user-picker]')
    picker.attr('data-behavior', 'selected-user-picker')
    picker.attr('id', "selected-user-picker-#{userId}")

    label = selectedUserCard.find('[data-behavior=available-user-picker-label]')
    label.attr('data-behavior', 'selected-user-picker-label')
    label.attr('for', "selected-user-picker-#{userId}")

    $be('selected-users-list').append(selectedUserCard)
    bindSelectedUserPicker(picker)

  removeSelectedCard = (userId) ->
    $be('selected-users-list').find(
      "[data-behavior=user-pick-card][data-id='#{userId}']"
    ).remove()

  resetDialogControls = (initialSelectedUserIds) ->
    $be('start-chat-filter').val('')
    $be('start-chat-filter').keyup()

    $be('modal-container').scrollTop(0)

    if initialSelectedUserIds && initialSelectedUserIds.length > 0
      $be('available-user-picker').each (i, picker) ->
        picker = $(picker)
        userId = picker.data('id')
        picker.prop('checked', (userId in initialSelectedUserIds))
        picker.change()

  updateDialogControls = ->
    count = $be('available-user-picker').filter(':checked').length
    counter = $be('selected-users-count')
    submitButton = $be('start-chat-submit')
    switch count
      when 0
        counter.hide()
        submitButton.addClass('disabled')
      when 1
        counter.show()
        counter.html('1 person selected')
        submitButton.removeClass('disabled')
      else
        counter.show()
        counter.html("#{count} people selected")
        submitButton.removeClass('disabled')
