import { createSelector } from "@reduxjs/toolkit"
import { orderBy } from "lodash"

import { normalizeMessage } from "src/resources/messages/helpers"

export const getStarredItems = (state) => state.starredItems
export const getStarredItemsAllIds = (state) => state.starredItems.allIds
export const getStarredItemsById = (state) => state.starredItems.byId

export const selectAllStarredItems = createSelector(
  [getStarredItemsAllIds, getStarredItemsById],
  (allIds, byId) =>
    orderBy(
      allIds.map((id) => normalizeMessage(byId[id])),
      ["date"],
      ["desc"]
    )
)
