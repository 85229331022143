import { useState, useEffect } from "react"

const useMediaQuery = (query) => {
  const matcher = window.matchMedia(query)
  const [match, setMatch] = useState(matcher.matches)

  useEffect(() => {
    const onUpdateMatch = ({ matches }) => {
      setMatch(matches)
    }

    const isModern = "addEventListener" in matcher
    if (isModern) {
      matcher.addEventListener("change", onUpdateMatch)
    } else {
      matcher.addListener(onUpdateMatch)
    }

    onUpdateMatch(matcher)

    return () => {
      if (isModern) {
        matcher.removeEventListener("change", onUpdateMatch)
      } else {
        matcher.removeListener(onUpdateMatch)
      }
    }
  }, [query, setMatch])

  return match
}

export default useMediaQuery
