import * as Sentry from "@sentry/browser"

import OfflineAnnotationsStore from "src/features/offline/helpers/OfflineAnnotationsStore"
import { CRUD } from "src/features/offline/constants"
import { getCurrentUserId } from "src/helpers/user"
import annotationsApi from "src/api/meetingPacks/annotations"

const createAnnotation = (offlineStore, offlineAnnotation) => {
  annotationsApi
    .create({
      documentId: offlineAnnotation.documentId,
      annotation: offlineAnnotation.annotation
    })
    .then(() => offlineStore.removeOfflineAnnotation(offlineAnnotation.annotation.id))
    .catch((e) => Sentry.captureException(e, { extra: { offlineAnnotation } }))
}

const updateAnnotation = (offlineStore, offlineAnnotation) => {
  annotationsApi
    .get({
      documentId: offlineAnnotation.documentId,
      annotationId: offlineAnnotation.annotation.id
    })
    .then((backendAnnotation) => {
      const date = new Date(backendAnnotation.data.content.updatedAt)
      const backendTimestamp = date.getTime()
      if (offlineAnnotation.timestamp > backendTimestamp) {
        annotationsApi
          .update({
            documentId: offlineAnnotation.documentId,
            annotation: offlineAnnotation.annotation,
            annotationId: offlineAnnotation.annotation.id
          })
          .catch((e) => Sentry.captureException(e, { extra: { offlineAnnotation } }))
          .then(() =>
            offlineStore.removeOfflineAnnotation(offlineAnnotation.annotation.id)
          )
      } else {
        offlineStore.removeOfflineAnnotation(offlineAnnotation.annotation.id)
      }
    })
    .catch((e) => {
      if (e.response.status === 404) {
        createAnnotation(offlineStore, offlineAnnotation)
      } else {
        Sentry.captureException(e, { extra: { offlineAnnotation } })
      }
    })
}

const deleteAnnotation = (offlineStore, offlineAnnotation) => {
  annotationsApi
    .destroy({
      documentId: offlineAnnotation.documentId,
      annotationId: offlineAnnotation.annotation.id
    })
    .then(() => offlineStore.removeOfflineAnnotation(offlineAnnotation.annotation.id))
    .catch((e) => Sentry.captureException(e, { extra: { offlineAnnotation } }))
}

const syncOfflineAnnotations = (offlineStore = new OfflineAnnotationsStore()) => {
  const currentTimestamp = new Date().getTime()
  const KEEP_PERIOD = 60 * 1000 * 3600 * 24 // 60 Days in ms
  offlineStore
    .getOfflineAnnotations({ userId: getCurrentUserId() })
    .then((offlineAnnotations) =>
      offlineAnnotations.forEach((offlineAnnotation) => {
        if (currentTimestamp - offlineAnnotation.timestamp > KEEP_PERIOD) {
          offlineStore.removeOfflineAnnotation(offlineAnnotation.annotation.id)
        } else {
          switch (offlineAnnotation.action) {
            case CRUD.CREATE:
              createAnnotation(offlineStore, offlineAnnotation)
              break
            case CRUD.UPDATE:
              updateAnnotation(offlineStore, offlineAnnotation)
              break
            case CRUD.DELETE:
              deleteAnnotation(offlineStore, offlineAnnotation)
              break
            default:
          }
        }
      })
    )
}

export default syncOfflineAnnotations
