import React, { useContext, useEffect, useRef, useState } from "react"
import { string, number, shape, func } from "prop-types"
import { styled } from "@linaria/react"
import classNames from "classnames"

import { FONT_WEIGHTS, SPACES } from "src/styles/sizes"
import {
  useDrag,
  useDropIn
} from "src/features/meetingPacks/MeetingPack/Transcript/View/useDragDrop"
import { BASE_COLORS } from "src/styles/colors"

import ScrollContext from "../ScrollContext"

import AgendaItemIndex from "./AgendaItemIndex"

const AgendaItem = ({ agendaItem, index, dropOptions, className }) => {
  const {
    scrollToAgendaItemId,
    agendaItemsAbove,
    setAgendaItemsAbove,
    closedToTopAgendaItems,
    setClosedToTopAgendaItems,
    utterancesListElement
  } = useContext(ScrollContext)
  const { agendaItemId, name, agendaItemsListIndex } = agendaItem
  const [isAbove, setIsAbove] = useState(false)
  const [closeToTop, setCloseToTop] = useState(false)
  const scrollRef = useRef(null)

  const ref = useRef(null)
  const {
    drag,
    preview,
    style: dragAndDropStyle
  } = useDrag({
    item: agendaItem,
    index
  })
  const { handlerId, drop } = useDropIn({ ref, index, ...dropOptions })
  drag(drop(ref))

  const checkInView = () => {
    const rect = ref.current.getBoundingClientRect()
    const containerRect = utterancesListElement.getBoundingClientRect()
    const bottom = rect.bottom - containerRect.top
    setIsAbove(bottom < 0)
    setCloseToTop(bottom >= 0 && bottom < 60)
  }

  useEffect(() => {
    if (ref.current) checkInView()
  }, [ref.current])

  useEffect(() => {
    if (utterancesListElement) {
      utterancesListElement.addEventListener("scroll", checkInView)

      return () => {
        utterancesListElement.removeEventListener("scroll", checkInView)
      }
    }
  }, [utterancesListElement])

  useEffect(() => {
    const foundInAgendaItemsAbove = agendaItemsAbove.find(
      (item) => item.agendaItemId === agendaItemId
    )
    if (isAbove && !foundInAgendaItemsAbove) {
      setAgendaItemsAbove((prevAgendaItemsAbove) => [...prevAgendaItemsAbove, agendaItem])
    } else if (!isAbove && foundInAgendaItemsAbove) {
      setAgendaItemsAbove((prevAgendaItemsAbove) =>
        prevAgendaItemsAbove.filter((item) => item.agendaItemId !== agendaItemId)
      )
    }

    const foundInClosedToTopAgendaItems = closedToTopAgendaItems.find(
      (item) => item.agendaItemId === agendaItemId
    )
    if (closeToTop && !foundInClosedToTopAgendaItems) {
      setClosedToTopAgendaItems((prevClosedToTopAgendaItems) => [
        ...prevClosedToTopAgendaItems,
        agendaItem
      ])
    } else if (!closeToTop && foundInClosedToTopAgendaItems) {
      setClosedToTopAgendaItems((prevClosedToTopAgendaItems) =>
        prevClosedToTopAgendaItems.filter((item) => item.agendaItemId !== agendaItemId)
      )
    }
  }, [isAbove, closeToTop, agendaItemsAbove, closedToTopAgendaItems])

  useEffect(() => {
    if (scrollRef.current && scrollToAgendaItemId === agendaItemId)
      scrollRef.current.scrollIntoView({ behavior: "instant" })
  }, [scrollRef.current, scrollToAgendaItemId])

  return (
    <div
      ref={ref}
      data-handler-id={handlerId}
      style={dragAndDropStyle}
      className={className}
    >
      <div ref={scrollRef}>
        <div ref={preview} className={classNames("card", index > 0 ? "mt-3" : "mt-0")}>
          <AgendaItemIndex index={agendaItemsListIndex} />
          <div className="card-body py-2">{name}</div>
        </div>
      </div>
    </div>
  )
}

AgendaItem.propTypes = {
  agendaItem: shape({
    agendaItemId: number.isRequired,
    name: string.isRequired,
    agendaItemsListIndex: string.isRequired
  }),
  dropOptions: shape({
    setTranscriptItems: func.isRequired,
    handleSave: func.isRequired
  }).isRequired,
  index: number.isRequired
}

export default styled(AgendaItem)`
  position: relative;
  margin-left: 85px;
  font-weight: ${FONT_WEIGHTS.bold};

  .card {
    padding: ${SPACES.xxSmall};
    background-color: ${BASE_COLORS.darkGray};
  }
`
