import React from "react"
import { bool, string, shape, func } from "prop-types"
import classNames from "classnames"

import Checkbox from "src/styles/components/Checkbox"

const Row = ({ item, isChecked, onChange, isDisabled }) => {
  return (
    <div
      key={item.id}
      className={classNames(
        "d-flex justify-content-between px-3 py-2",
        isChecked && "is-selected"
      )}
    >
      <label htmlFor={`field-${item.id}`} className="text-truncate">
        {item.name}
      </label>
      <Checkbox
        id={`field-${item.id}`}
        checked={isChecked}
        onChange={() => onChange(item.id)}
        disabled={isDisabled}
      />
    </div>
  )
}

Row.propTypes = {
  item: shape({
    id: string.isRequired,
    name: string.isRequired
  }),
  isChecked: bool.isRequired,
  onChange: func.isRequired,
  isDisabled: bool
}

export default Row
