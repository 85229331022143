// eslint-disable-next-line import/no-unresolved
import aiButtonIcon from "./aiIcon.svg?raw"
// eslint-disable-next-line import/no-unresolved
import pencilPlusIcon from "./pencilPlusIcon.svg?raw"

const buildAiButtons = ({
  documentContentExtracted,
  summariseDocumentHandler,
  queryDocumentHandler,
  queryMeetingPackHandler
}) => {
  if (
    !window.Knowa?.featureFlags?.aiFeaturesEnabled ||
    !documentContentExtracted ||
    !navigator.onLine
  ) {
    return []
  }

  return [
    {
      type: "custom",
      className: "custom-button",
      id: "ai-button-summarise",
      icon: aiButtonIcon,
      title: "Summarise document",
      dropdownGroup: "ai-dropdown",
      disabledOffline: true,
      onPress() {
        summariseDocumentHandler()
      }
    },
    {
      type: "custom",
      className: "custom-button",
      id: "ai-button-custom-prompt-document",
      icon: pencilPlusIcon,
      title: "Query document",
      dropdownGroup: "ai-dropdown",
      disabledOffline: true,
      onPress() {
        queryDocumentHandler()
      }
    },
    {
      type: "custom",
      className: "custom-button",
      id: "ai-button-custom-prompt-meeting-pack",
      icon: pencilPlusIcon,
      title: "Query meeting pack",
      dropdownGroup: "ai-dropdown",
      disabledOffline: true,
      onPress() {
        queryMeetingPackHandler()
      }
    }
  ]
}

export default buildAiButtons
