class @MessageActionStatusComponent
  constructor: () ->
    @modal = $be('message-action-status')
    @modalContent = $be('message-action-status-content')

  showModal: (modalContent) =>
    @modalContent.html(modalContent)
    window.layoutHandler.setAvatarImages(@modalContent)
    window.layoutHandler.contactDetailsComponent.bindLinks(@modalContent)

    @modal.modal('show')
