import { useState } from "react"
import { useApolloClient } from "@apollo/client"

import { showFlashMessage } from "src/helpers/flash"

import exportMeetingForUserMutation from "./exportMeetingForUser.gql"

const useExportMeetingForUser = ({ meetingPackId }) => {
  const [isExporting, setIsExporting] = useState(false)
  const client = useApolloClient()

  const exportMeeting = () => {
    if (isExporting) return

    setIsExporting(true)

    const mutatePromise = client.mutate({
      mutation: exportMeetingForUserMutation,
      variables: { id: meetingPackId }
    })

    mutatePromise
      .then(() => {
        showFlashMessage(
          "success",
          "A link to the export will be emailed to you shortly."
        )
      })
      .catch(() => {
        showFlashMessage("danger", "An error happened while exporting the meeting pack")
      })
      .finally(() => setIsExporting(false))
  }

  return exportMeeting
}

export default useExportMeetingForUser
