import React from "react"
import { styled } from "@linaria/react"
import { bool, string } from "prop-types"

import { BACKGROUND_COLORS, BORDER_COLORS, successColor } from "src/styles/colors"
import { FONT_SIZES, SPACES } from "src/styles/sizes"
import { composeVariationsClasses } from "src/helpers/linaria"

const Checkbox = ({ className, id, variant, children, disabled = false, ...props }) => {
  const classes = composeVariationsClasses(className, { variant, disabled })

  return (
    <div className={classes}>
      <input type="checkbox" id={id} {...props} />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor={id}>{children}</label>
    </div>
  )
}

Checkbox.propTypes = {
  id: string.isRequired,
  variant: string,
  disabled: bool
}

export default styled(Checkbox)`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  cursor: pointer;
  margin-bottom: 0;
  padding-left: 0;
  font-size: ${FONT_SIZES.medium};

  &--variant-small {
    margin: ${SPACES.small} ${SPACES.medium};
    font-size: ${FONT_SIZES.small};

    label {
      &:before {
        margin-top: 0 !important;
        height: 20px !important;
        width: 20px !important;
      }

      &:after {
        margin-top: 0 !important;
        font-size: 19px !important;
        line-height: 19px !important;
      }
    }
  }

  input {
    display: none;
  }

  & > input:checked + label:after {
    opacity: 1;
  }

  label {
    cursor: inherit;
    display: flex;
    flex-flow: row nowrap;
    justify-items: center;
    margin-bottom: 0;
    user-select: none;
    width: 100%;

    &:before {
      background-color: white;
      border-radius: 0.2rem;
      border: 1px solid ${BORDER_COLORS.control};
      content: "";
      display: block;
      margin-top: -2px;
      height: 25px;
      width: 25px;
    }

    &:after {
      content: "\\e91c";
      color: ${successColor};
      font-family: "knowa-icons";
      font-size: 24px;
      display: block;
      line-height: 24px;
      left: 1.5px;
      margin-top: -1px;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: opacity 0.1s ease-in;
    }
  }

  &--disabled {
    pointer-events: none;

    label {
      &:before {
        background: ${BACKGROUND_COLORS.disabled};
      }
    }
  }
`
