import React from "react"
import { func, bool } from "prop-types"
import { Button } from "react-bootstrap"

import { ButtonContainer } from "../../styles"

const ButtonGroup = ({ confirmDisabled, onConfirm, onCancel }) => (
  <ButtonContainer>
    <Button disabled={confirmDisabled} variant="primary" size="lg" onClick={onConfirm}>
      Confirm
    </Button>
    <Button variant="outline-primary" onClick={onCancel}>
      Cancel
    </Button>
  </ButtonContainer>
)

ButtonGroup.propTypes = {
  onConfirm: func.isRequired,
  onCancel: func.isRequired,
  confirmDisabled: bool
}

export default ButtonGroup
