import React from "react"

import { be } from "src/helpers/document"

import { openPrintWindow } from "./helpers"

const PrintForm = () => {
  const handleCancel = () => {
    be("print-messages-form").addClass("hidden")
    be("message-selection").addClass("hidden")
    $(".message-block").removeClass("with-selector")
    $(
      '[data-behavior="show-new-message-form"], [data-behavior="new-message-form-wrapper"]'
    ).each(function showPrintButtons() {
      if ($(this).hasClass("hidden-for-print-mode")) {
        $(this).removeClass("hidden-for-print-mode")
        $(this).show()
      }
    })
  }

  const handlePrint = () => {
    $(".printable").each(function uncollapseMessages() {
      $(this)
        .find(".message-share.message-block__minimizable")
        .removeClass("message-block__minimized")
    })
    openPrintWindow()
  }

  return (
    <div
      className="discussion-message-selector hidden"
      data-behavior="print-messages-form"
    >
      <button className="button is-outline-primary" onClick={() => handleCancel()}>
        Cancel
      </button>
      <button className="button pull-right is-primary" onClick={() => handlePrint()}>
        Print selected messages
      </button>
    </div>
  )
}

export default PrintForm
