import React from "react"
import { func } from "prop-types"
import { styled } from "@linaria/react"

import { BASE_COLORS } from "src/styles/colors"
import { FONT_SIZES, SPACES } from "src/styles/sizes"
import UnstyledButton from "src/components/UnstyledButton"

const OutdatedWarning = ({ onClick, className }) => {
  return (
    <div className={className}>
      <div>
        Transcript was updated. Would you like to{" "}
        <UnstyledButton onClick={onClick}>regenerate</UnstyledButton>?
      </div>
    </div>
  )
}

OutdatedWarning.propTypes = {
  onClick: func.isRequired
}

export default styled(OutdatedWarning)`
  div {
    position: fixed;
    font-size: ${FONT_SIZES.small};
    color: ${BASE_COLORS.red};
    border-radius: 4px;
    border: 1px ${BASE_COLORS.red} solid;
    padding: ${SPACES.xxSmall};
    margin-bottom: ${SPACES.small};
  }

  button {
    text-decoration: underline;
  }
`
