import React, { useContext } from "react"
import classNames from "classnames"
import { shape, string, number } from "prop-types"
import { styled } from "@linaria/react"

import { FONT_WEIGHTS } from "src/styles/sizes"
import { BASE_COLORS } from "src/styles/colors"

import ScrollContext from "../ScrollContext"

import DropdownActions from "./DropdownActions"

const AssignedAgendaItem = ({ agendaItem, assignedAgendaItem, className }) => {
  const { setScrollToAgendaItemId } = useContext(ScrollContext)

  return (
    <h6
      className={classNames(
        "py-2 pl-3 pr-4 mb-3",
        agendaItem.deepLevel > 1 && "sub-agenda-item",
        className
      )}
      data-testid="assigned-agenda-item"
    >
      <span onClick={() => setScrollToAgendaItemId(agendaItem.id)} aria-hidden="true">
        {agendaItem.index}. {agendaItem.name}
      </span>
      <DropdownActions
        agendaItem={agendaItem}
        initialAssignedAgendaItemId={assignedAgendaItem.id}
      />
    </h6>
  )
}

AssignedAgendaItem.propTypes = {
  agendaItem: shape({
    name: string.isRequired,
    index: string.isRequired,
    deepLevel: number.isRequired
  }),
  assignedAgendaItem: shape({
    id: number
  })
}

export default styled(AssignedAgendaItem)`
  position: relative;
  background-color: ${BASE_COLORS.darkGray};
  border-radius: 3px;
  font-weight: ${FONT_WEIGHTS.semiBold};
  cursor: pointer;

  &.sub-agenda-item {
    margin-left: 10px;
  }

  & > span {
    display: inline-block;
    width: 100%;
  }
`
