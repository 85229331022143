import React from "react"
import { bool, number, shape, string } from "prop-types"

import UnreadBadge from "src/styles/components/UnreadBadge"
import TextWithTooltip from "src/styles/components/TextWithTooltip"
import { formatDateTime, validateDuration } from "src/helpers/datetime"
import { TIME_FORMAT } from "src/constants/datetimeFormats"

import ContentsRow from "./ContentsRow"
import { PrefixCol, SuffixCol, ItemTitle, BreakName, BreakLine, Duration } from "./styles"

const BreakRow = ({ item, disabled = false, unread = false, timeZone = null }) => {
  const startTime = formatDateTime({
    timeZone,
    datetime: item.startTime,
    format: TIME_FORMAT
  })
  const hasDuration = validateDuration({ duration: item.duration })

  return (
    <ContentsRow breakRow disabled={disabled}>
      <PrefixCol />
      <ItemTitle>
        <BreakName>
          <TextWithTooltip text={item.name} maxLength={25} />
        </BreakName>
        <BreakLine />
      </ItemTitle>
      <SuffixCol>
        {hasDuration && <Duration>{startTime}</Duration>}
        {unread && <UnreadBadge />}
      </SuffixCol>
    </ContentsRow>
  )
}

BreakRow.propTypes = {
  item: shape({
    id: number.isRequired,
    name: string.isRequired,
    startTime: string,
    break: bool,
    index: string,
    duration: number
  }).isRequired,
  disabled: bool,
  unread: bool,
  timeZone: string
}

export default BreakRow
