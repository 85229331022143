import React from "react"
import { arrayOf, func, shape, string } from "prop-types"

import AttachedDocumentCard from "src/components/AttachedDocuments/AttachedDocumentCard"

import { DocumentsCardsContainer } from "./styles"

const AttachedDocuments = ({
  attachedDocuments,
  onCardClick,
  detachDocument,
  inputNamePrefix,
  iconType
}) => {
  return (
    <DocumentsCardsContainer>
      {attachedDocuments.map((document, index) => (
        <AttachedDocumentCard
          key={document.id}
          index={index}
          document={document}
          onClick={onCardClick}
          detachDocument={detachDocument}
          inputNamePrefix={inputNamePrefix}
          iconType={iconType}
        />
      ))}
    </DocumentsCardsContainer>
  )
}

AttachedDocuments.propTypes = {
  attachedDocuments: arrayOf(shape({ id: string, name: string })).isRequired,
  detachDocument: func.isRequired,
  inputNamePrefix: string,
  iconType: string,
  onCardClick: func
}

export default AttachedDocuments
