import React from "react"
import { styled } from "@linaria/react"
import { string, oneOf } from "prop-types"
import { Dropdown } from "react-bootstrap"

import Icon from "src/styles/components/Icon"
import { BASE_COLORS } from "src/styles/colors"

const ICON_TYPES = {
  now: "block",
  number: "schedule",
  date: "today"
}

const Item = ({ text, eventKey, className }) => (
  <Dropdown.Item className={className} eventKey={eventKey}>
    <Icon type={ICON_TYPES[eventKey]} className="mr-2" />
    {text}
  </Dropdown.Item>
)

Item.propTypes = {
  text: string.isRequired,
  eventKey: oneOf(Object.keys(ICON_TYPES)).isRequired,
  className: string.isRequired
}

export default styled(Item)`
  white-space: nowrap;
  color: ${BASE_COLORS.darkViolet};
  padding: 0.5rem 0.75rem !important;

  ${Icon} {
    color: #dadada;

    &.icon-today {
      padding-right: 2px;
    }
  }
`
