import { useCallback } from "react"
import update from "immutability-helper"
import { useDrop } from "react-dnd"

import dropSort from "src/helpers/dropSort"

import { ITEM_TYPE } from "./constants"

const useDropIn = ({ ref, index, setTranscriptItems, handleSave }) => {
  const addItem = useCallback((item, hoverIndex) => {
    setTranscriptItems((prevItems) =>
      update(prevItems, {
        $splice: [[hoverIndex, 0, item]]
      })
    )
  }, [])

  const [{ handlerId }, drop] = useDrop({
    accept: ITEM_TYPE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      }
    },
    hover(item, monitor) {
      if (!ref.current) return

      const isNewItem = !item.index && item.index !== 0

      if (isNewItem) {
        addItem(item, index)
        // eslint-disable-next-line no-param-reassign
        item.index = index
      } else {
        dropSort({ ref, item, index, setItems: setTranscriptItems, monitor })
      }
    },
    drop(item) {
      if (item.index !== item.initialIndex) handleSave(item)
    }
  })

  return { handlerId, drop }
}

export default useDropIn
