import React from "react"
import { func } from "prop-types"
import { useDrop } from "react-dnd"
import { NativeTypes } from "react-dnd-html5-backend"

import { Container, DropBackground } from "./styles"

const readDirectory = (directory) => {
  const dirReader = directory.createReader()

  return new Promise((resolve, reject) => {
    let iterationAttempts = []

    const readEntries = () => {
      // According to the FileSystem API spec, readEntries() must be called until
      // it calls the callback with an empty array.
      dirReader.readEntries(
        (entries) => {
          if (entries.length === 0) {
            resolve(Promise.all(iterationAttempts))
          } else {
            const nestedEntries = entries.map((entry) => {
              return entry.isFile ? entry : readDirectory(entry)
            })

            iterationAttempts = [...iterationAttempts, Promise.all(nestedEntries)]

            readEntries()
          }
        },
        (error) => reject(error)
      )
    }

    readEntries()
  })
}

const DropZone = ({ children, className, onDrop }) => {
  const [{ isOver }, dropRef] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop: async (_item, monitor) => {
        const droppedItem = monitor.getItem()
        const items = Array.from(droppedItem.items)

        const files = await Promise.all(
          items.map((item) => {
            const entry = item.webkitGetAsEntry()
            return entry.isFile ? entry : readDirectory(entry)
          })
        )

        onDrop(files.flat(Infinity))
      },
      collect: (monitor) => {
        return {
          isOver: !!monitor.isOver()
        }
      }
    }),
    [onDrop]
  )

  return (
    <Container ref={dropRef} className={className}>
      {isOver && <DropBackground>Drop to upload into current folder</DropBackground>}

      {children}
    </Container>
  )
}

DropZone.propTypes = {
  onDrop: func.isRequired
}

export default DropZone
