import React from "react"
import { shape, bool, string } from "prop-types"

import useIsOpened from "src/hooks/useIsOpened"

import ContactDetailsModal from "./ContactDetailsModal"
import UserAvatar from "./UserAvatar"
import { UserAvatarContainer } from "./styles"
import { DEFAULT_TYPE } from "./constants"

const UserAvatarWithDetails = ({ user, isCurrentUser, type = DEFAULT_TYPE }) => {
  const { isOpened, open, close } = useIsOpened()

  return (
    <>
      <UserAvatarContainer onClick={open}>
        <UserAvatar user={user} />
      </UserAvatarContainer>
      {user && (
        <ContactDetailsModal
          isOpened={isOpened}
          close={close}
          user={user}
          isCurrentUser={isCurrentUser}
          type={type}
        />
      )}
    </>
  )
}

UserAvatarWithDetails.propTypes = {
  user: shape({}),
  isCurrentUser: bool.isRequired,
  type: string
}

export default UserAvatarWithDetails
