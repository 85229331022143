import { createSlice } from "@reduxjs/toolkit"

import { setIsLoading, setFailure, setCollection } from "src/helpers/slice"

const initialState = {
  allIds: [],
  byId: {},
  isLoading: false,
  error: null
}

const slice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    fetchTagsRequest: setIsLoading,
    fetchTagsFailure: setFailure,
    fetchTagsSuccess: setCollection,
    updateTagSuccess: (state, action) => {
      const tag = action.payload

      state.byId[tag.id] = tag
    }
  }
})

const { actions, reducer } = slice

export const {
  fetchTagsFailure,
  fetchTagsRequest,
  fetchTagsSuccess,
  updateTagSuccess
} = actions

export default reducer
