import React, { useContext } from "react"
import { Accordion, Card } from "react-bootstrap"
import { arrayOf, func, string, shape } from "prop-types"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"

import AccordionToggle from "src/styles/components/AccordionToggle"
import useIsOpened from "src/hooks/useIsOpened"
import LinkButton from "src/styles/components/Button/Link"
import PageMetadataContext from "src/features/ManageAccount/AirtableApps/EditPage/PageMetadataContext"

import FieldsModal from "./FieldsModal"
import FieldRow from "./FieldRow"

const OverviewSettings = ({ cardFields, setCardFields }) => {
  const {
    isOpened: isOpenedFieldsModal,
    open: openFieldsModal,
    close: closeFieldsModal
  } = useIsOpened()

  const { allFields } = useContext(PageMetadataContext)

  const removeField = (fieldId) =>
    setCardFields((prevFields) => prevFields.filter((field) => field.id !== fieldId))

  const cardFieldsWithName = cardFields.map((field) => ({
    ...field,
    name: allFields[field.id]?.name
  }))

  return (
    <>
      <div className="container my-3">
        <Card className="px-5">
          <Accordion.Toggle as="div" eventKey="0">
            <div className="d-flex align-items-center">
              <AccordionToggle eventKey="0" />
              <Card.Title>Select fields to display on records overview</Card.Title>
            </div>
            <div className="mb-2">
              <small>
                Choose up to six fields. The primary field will be used as the record
                title
              </small>
            </div>
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="0">
            <>
              <DndProvider backend={HTML5Backend}>
                {cardFieldsWithName.map((field, i) => (
                  <FieldRow
                    key={field.id}
                    field={field}
                    onRemove={removeField}
                    setFields={setCardFields}
                    index={i}
                  />
                ))}
              </DndProvider>
              <LinkButton className="mt-2" onClick={openFieldsModal}>
                + Add fields
              </LinkButton>
            </>
          </Accordion.Collapse>
        </Card>
      </div>

      <FieldsModal
        close={closeFieldsModal}
        isOpened={isOpenedFieldsModal}
        initiallySelectedFields={cardFields}
        saveSelectedFields={setCardFields}
      />
    </>
  )
}

OverviewSettings.propTypes = {
  cardFields: arrayOf(shape({ id: string.isRequired })),
  setCardFields: func.isRequired
}

export default OverviewSettings
