class @AvatarListComponent
  constructor: (listContainerBehavior) ->
    @listContainerBehavior = listContainerBehavior

  setup: =>
    @updateAllCounters()
    $(window).on 'resize', =>
      @updateAllCounters()

  update: (groupId, listContainer, contactDetailsEnabled, callback) =>
    $.get(
      "/groups/#{groupId}/membership",
      {
        contact_details_enabled: contactDetailsEnabled
      },
      (newContent) =>
        listContainer.
          find("[data-behavior=group-members-list]").
          html(newContent)
        @updateMembersCounter(listContainer)

        window.layoutHandler.setAvatarImages(listContainer)

        if contactDetailsEnabled
          window.layoutHandler.contactDetailsComponent.bindLinks(listContainer)

        callback() if callback

      , 'html'
    )

  updateMembersCounter: (listContainer) ->
    nonVisibleCount = (
      listContainer.find(
        '[data-behavior="member-avatar"]'
      ).length -
      listContainer.find(
        '[data-behavior="member-avatar"]:visible'
        ).length
    )
    membersCounter = listContainer.find("[data-behavior=members-counter]")
    if nonVisibleCount > 0
      membersCounter.show()
    else
      membersCounter.hide()
    membersCounter.find(".counter-text").text($userCountView(nonVisibleCount))

  updateAllCounters: =>
    $be(@listContainerBehavior).each (idx, listContainer) =>
      @updateMembersCounter($(listContainer))
