import React, { useCallback, useState } from "react"
import { useDispatch } from "react-redux"
import { Modal, Form } from "react-bootstrap"
import { arrayOf, bool, func } from "prop-types"

import ModalBody from "src/styles/components/Modal/Body"
import ModalTitle from "src/styles/components/Modal/Title"
import ModalHeader from "src/styles/components/Modal/Header"
import ModalFooter from "src/styles/components/Modal/Footer"
import { updateTag } from "src/resources/tags/thunks"
import { optionType } from "src/constants/propTypes"
import { KEY_CODES } from "src/constants"

import { validateTag } from "./helpers"
import { FormWarning } from "./styles"

const RenameTagModal = ({ close, isOpened, tag, options }) => {
  const dispatch = useDispatch()
  const [tagName, setTagName] = useState(tag.label)

  const isTagNameChanged = tagName !== tag.label
  const invalidMessage = isTagNameChanged ? validateTag(tagName, options) : false
  const isInvalid = !!invalidMessage
  const isSubmitDisabled = isTagNameChanged ? isInvalid : true

  const handleChange = (event) => setTagName(event.target.value.trim())
  const handleSubmit = useCallback(() => {
    close()
    dispatch(updateTag({ tagId: tag.value, data: { name: tagName } }))
  }, [tagName, dispatch])
  const handleKeyDown = useCallback(
    (event) => {
      if (event.keyCode === KEY_CODES.enter) {
        event.preventDefault()
        if (!isSubmitDisabled) handleSubmit()
      }
    },
    [isSubmitDisabled, handleSubmit]
  )

  return (
    <Modal onHide={close} show={isOpened}>
      <ModalHeader closeButton>
        <ModalTitle>Rename Tag</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Form.Group controlId="tagName">
          <Form.Control
            placeholder="Type a new name for this tag"
            autoComplete="off"
            isInvalid={isInvalid}
            defaultValue={tagName}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
          <Form.Control.Feedback type="invalid">{invalidMessage}</Form.Control.Feedback>
          <FormWarning>
            New name will be applied for all meetings with this tag.
          </FormWarning>
        </Form.Group>
        <ModalFooter noBorder>
          <button className="button is-secondary" onClick={close}>
            Close
          </button>
          <button className="button" disabled={isSubmitDisabled} onClick={handleSubmit}>
            Rename
          </button>
        </ModalFooter>
      </ModalBody>
    </Modal>
  )
}

RenameTagModal.propTypes = {
  close: func,
  isOpened: bool,
  tag: optionType,
  options: arrayOf(optionType)
}

export default RenameTagModal
