const linkRegExp = /(<a[^<>]+?>)([^<>]+?)<\/a>/
const urlRegExp = /https?:\/\/[^\s<>]+/g
const paramsForAnonymizing = [
  "message_id",
  "agenda_item_id",
  "document_id",
  "folder_id",
  "file_id"
]

/**
 * This function eliminate all parameters not from paramsForAnonymizing array
 * @param {string} searchString
 * @example withoutUnknownParams("?document_id=document_id&page=4") => "?document_id=document_id"
 */
const withoutUnknownParams = (searchString) => {
  if (!searchString) return ""

  return `?${searchString
    .replace(/^\?/, "")
    .split("&")
    .filter((searchParam) => paramsForAnonymizing.includes(searchParam.split("=")[0]))
    .join("&")}`
}

export const getLinkData = (availableLinks, plainLink) => {
  const url = new URL(plainLink.replaceAll("&amp;", "&"))
  const urlParams = {}

  paramsForAnonymizing.forEach((param) => {
    if (url.searchParams.get(param)) {
      urlParams[param] = url.searchParams.get(param)
      url.searchParams.set(param, param)
    }
  })

  const linkData =
    availableLinks[url.pathname + url.search] ||
    availableLinks[url.pathname + withoutUnknownParams(url.search)] ||
    availableLinks[url.pathname]
  const requestLinkText = !!linkData && typeof linkData !== "string"

  return {
    linkText: requestLinkText ? linkData?.text : linkData,
    requestLinkText,
    objectId: requestLinkText ? urlParams.agenda_item_id || urlParams.document_id : null,
    objectName: requestLinkText ? linkData.object_name : null
  }
}

const fetchAndReplaceLinkText = async (objectId, objectName) => {
  fetch(`/api/available_links/text?object_name=${objectName}&object_id=${objectId}`)
    .then((response) => response.json())
    .then((data) => {
      if (!data.id) return

      document
        .querySelectorAll(`a.content_pending_link[data-object-id="${data.id}"]`)
        .forEach((pendingLink) => {
          // eslint-disable-next-line no-param-reassign
          pendingLink.textContent = data.text
          pendingLink.classList.remove("content_pending_link")
          pendingLink.removeAttribute("data-object-id")
        })
    })
}

const getPlainLinksHTML = ({ html, availableLinks, plainLinks }) => {
  let updatedHTML = html

  plainLinks.forEach((plainLink) => {
    const { linkText, objectId, objectName, requestLinkText } = getLinkData(
      availableLinks,
      plainLink
    )

    if (linkText && requestLinkText) {
      fetchAndReplaceLinkText(objectId, objectName)
      updatedHTML = updatedHTML.replace(
        plainLink,
        `<a href="${plainLink}" class="content_pending_link" data-object-id="${objectId}">${linkText}</a>`
      )
    } else if (linkText)
      updatedHTML = updatedHTML.replace(
        plainLink,
        `<a href="${plainLink}">${linkText}</a>`
      )
  })

  return updatedHTML
}

const getLinksHTML = ({ html, availableLinks, plainLink }) => {
  const { linkText, objectId, objectName, requestLinkText } = getLinkData(
    availableLinks,
    plainLink
  )

  if (!linkText) return html

  if (requestLinkText) {
    fetchAndReplaceLinkText(objectId, objectName)
    const jqueryHTML = $(html)
      .addClass("content_pending_link")
      .attr("data-object-id", objectId)
      .text(linkText)
    return html.replace(linkRegExp, jqueryHTML[0].outerHTML)
  }

  return html.replace(linkRegExp, `$1${linkText}</a>`)
}

export const replaceContentLinkText = (html, availableLinks) => {
  if (!availableLinks || !html) return html

  try {
    const link = html.match(linkRegExp)
    const plainLinks = html.replaceAll(new RegExp(linkRegExp, "g"), "#").match(urlRegExp)

    if (link && link[2].match(urlRegExp)) {
      return getLinksHTML({ html, availableLinks, plainLink: link[2] })
    }

    if (plainLinks) {
      return getPlainLinksHTML({ html, availableLinks, plainLinks })
    }

    return html
  } catch {
    return html
  }
}
