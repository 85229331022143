import React, { useMemo, useContext } from "react"
import { arrayOf, bool, func, number, string } from "prop-types"
import { styled } from "@linaria/react"
import classNames from "classnames"

import { useFiltering } from "src/components/AttandeesTable"
import { BACKGROUND_COLORS, BASE_COLORS } from "src/styles/colors"
import PageMetadataContext from "src/features/ManageAccount/AirtableApps/EditPage/PageMetadataContext"

import Row from "./Row"

const FieldsList = ({
  selectedFieldIds,
  setSelectedFieldIds,
  scrollableFieldsList = true,
  maxCheckedFields,
  className
}) => {
  const {
    pageMetadata: { fields }
  } = useContext(PageMetadataContext)
  const { filteredItems, setFilter } = useFiltering({
    items: fields,
    filteredFields: ["name"]
  })

  const checkedFields = useMemo(
    () => filteredItems.filter((item) => selectedFieldIds.includes(item.id)),
    [filteredItems, selectedFieldIds]
  )
  const notCheckedFields = useMemo(
    () => filteredItems.filter((item) => !selectedFieldIds.includes(item.id)),
    [filteredItems, selectedFieldIds]
  )

  const checkField = (fieldId) =>
    setSelectedFieldIds((prevFields) => [...prevFields, fieldId])
  const uncheckField = (fieldId) =>
    setSelectedFieldIds((prevFields) => prevFields.filter((id) => id !== fieldId))

  return (
    <div
      className={classNames(className, scrollableFieldsList && "scrollable-fields-list")}
    >
      <div className="pt-3 filter-fields-container">
        <input
          className="form-field"
          placeholder="Filter fields..."
          type="text"
          onChange={setFilter}
        />
      </div>

      <div className="fields-list">
        {!!checkedFields.length && (
          <>
            <div className="fields-selected-title text-center ptx pbx">
              <span>{checkedFields.length} fields selected</span>
            </div>
            {checkedFields.map((item) => (
              <Row key={item.id} item={item} onChange={uncheckField} isChecked />
            ))}
          </>
        )}

        {!!notCheckedFields.length && (
          <>
            <div className="fields-unselected-title text-center ptx pbx mb-3">
              <h6>All fields</h6>
            </div>
            {notCheckedFields.map((item) => (
              <Row
                key={item.id}
                item={item}
                onChange={checkField}
                isChecked={false}
                isDisabled={maxCheckedFields && checkedFields.length >= maxCheckedFields}
              />
            ))}
          </>
        )}

        {!checkedFields.length && !notCheckedFields.length && (
          <div className="empty pam">No results</div>
        )}
      </div>
    </div>
  )
}

FieldsList.propTypes = {
  selectedFieldIds: arrayOf(string).isRequired,
  setSelectedFieldIds: func.isRequired,
  maxCheckedFields: number,
  scrollableFieldsList: bool
}

export default styled(FieldsList)`
  .fields-selected-title {
    color: ${BASE_COLORS.lightBlue};
  }

  .fields-selected-title,
  .fields-unselected-title {
    border-bottom: 1px solid ${BASE_COLORS.lightGray};
  }

  .is-selected {
    background-color: ${BACKGROUND_COLORS.lightBlue};
  }

  &.scrollable-fields-list {
    .fields-list {
      height: 400px;
      overflow: auto;
    }

    .filter-fields-container {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
`
