import CacheStore from "src/features/offline/helpers/CacheStore"

export default class SyncCachedAnnotations {
  constructor({ documentId, userId }) {
    this.cacheStore = new CacheStore()
    this.documentId = documentId
    this.userId = userId
  }

  async create({ annotation }) {
    const { documentId, userId } = this

    const cachedAnnotations = await this.cacheStore.getAnnotations({
      documentId,
      userId
    })

    return this.cacheStore.saveAnnotations({
      documentId,
      annotations: [
        ...(cachedAnnotations || []),
        { content: annotation, id: annotation.id }
      ],
      userId
    })
  }

  async update({ annotation }) {
    const { documentId, userId } = this

    const cachedAnnotations = await this.cacheStore.getAnnotations({
      documentId,
      userId
    })

    return this.cacheStore.saveAnnotations({
      documentId,
      annotations: (cachedAnnotations || []).map((a) =>
        a.id === annotation.id ? { ...a, content: annotation } : a
      ),
      userId
    })
  }

  async delete({ annotation }) {
    const { documentId, userId } = this

    const cachedAnnotations = await this.cacheStore.getAnnotations({
      documentId,
      userId
    })

    return this.cacheStore.saveAnnotations({
      documentId,
      annotations: (cachedAnnotations || []).filter((a) => a.id !== annotation.id),
      userId
    })
  }
}
