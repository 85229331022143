import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import { func, oneOf } from "prop-types"
import moment from "moment"

import { ACTION_TYPES } from "src/features/Messages/ActionDetails/constants"

import { DateInputSection } from "../../Shared"

import ButtonGroup from "./ButtonGroup"

const DateForm = ({ actionType, onConfirm, onCancel }) => {
  const [value, setValue] = useState(moment().endOf("day").toDate())

  return (
    <>
      <Modal.Body>
        <DateInputSection
          actionType={actionType}
          selectedDate={value}
          setSelectedDate={setValue}
        />
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup onConfirm={() => onConfirm(value.toJSON())} onCancel={onCancel} />
      </Modal.Footer>
    </>
  )
}

DateForm.propTypes = {
  onConfirm: func.isRequired,
  onCancel: func.isRequired,
  actionType: oneOf(Object.keys(ACTION_TYPES)).isRequired
}

export default DateForm
