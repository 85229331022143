import { connect } from "react-redux"

import {
  getActiveItemAttendees,
  getIsCurrentUserGuest,
  getActiveItem
} from "src/features/meetingPacks/MeetingPack/selectors"

import Attendees from "./Attendees"

export const mapState = (state) => ({
  currentUser: state.meetingPack.currentUser,
  attendees: getActiveItemAttendees(state),
  activeItem: getActiveItem(state),
  isGuest: getIsCurrentUserGuest(state)
})

export default connect(mapState)(Attendees)
