import React from "react"
import { styled } from "@linaria/react"
import { func, string } from "prop-types"

import Icon from "src/styles/components/Icon"
import LinkButton from "src/styles/components/Button/Link"
import { FONT_SIZES, SPACES } from "src/styles/sizes"
import { ICON_COLORS } from "src/styles/colors"

const DocumentHeader = ({ className, onClick, name }) => {
  return (
    <h5 className={className}>
      <Icon type="collaborative-document" />
      <LinkButton onClick={onClick}>{name}</LinkButton>
    </h5>
  )
}

DocumentHeader.propTypes = {
  onClick: func.isRequired,
  name: string.isRequired
}

export default styled(DocumentHeader)`
  display: flex;
  align-items: center;
  font-size: ${FONT_SIZES.medium};
  margin: ${SPACES.small} 0 ${SPACES.xSmall};

  [class^="icon"] {
    color: ${ICON_COLORS.collaborationDocument};
    font-size: ${FONT_SIZES.large};
    margin-right: ${SPACES.xSmall};
  }

  ${LinkButton} {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`
