import React from "react"
import { bool, func, number, shape, string } from "prop-types"

import { MembershipsColumn } from "src/styles/components/Card/List"
import Card, { Mascot } from "src/styles/components/Card/Card"
import { Primary, Title } from "src/styles/components/Card/Title"
import Checkbox from "src/styles/components/Checkbox"
import Icon from "src/styles/components/Icon"

const TeamCard = ({ team, toggleTeam, checkedTeam }) => {
  return (
    <Card horizontal className="px-4 py-2" data-testid="user-card" data-id={team.id}>
      <Mascot className="mr-2">
        <Icon className="mr-2 text-black-50" type="user-supervisor" size="large" />
      </Mascot>
      <Title>
        <Primary>{team.name}</Primary>
      </Title>
      <MembershipsColumn>
        <Checkbox
          id={`team-check-box-${team.id}-present`}
          checked={checkedTeam}
          onChange={(e) =>
            toggleTeam({
              checked: e.target.checked,
              memberships: team.teamMemberships
            })
          }
        />
      </MembershipsColumn>
    </Card>
  )
}

TeamCard.propTypes = {
  toggleTeam: func.isRequired,
  team: shape({
    id: number.isRequired,
    name: string.isRequired
  }).isRequired,
  checkedTeam: bool
}

export default TeamCard
