class @DiscussionsIndexHandler
  constructor: ->
    @groupId = $be('group-resource').data('id')
    @unreadBadges = new UnreadBadgesComponent(
      'discussion-card',
      'discussionId',
      'discussions:updates'
    )
    @groupMembershipWatcherComponent = new GroupMembershipWatcherComponent()
    @groupLeaveLinkComponent = new GroupLeaveLinkComponent(@groupId)
    @groupMemberListComponent = new GroupMemberListComponent(
      listContainerBehavior: 'group-participants',
      manageEnabled: false,
      contactDetailsEnabled: true
    )
    @copyLinkComponent = new CopyLinkComponent()
    @discussionList = new DiscussionListComponent($be('discussion-list'), {})

  setup: =>
    @unreadBadges.setup()
    @groupMembershipWatcherComponent.setup()
    @groupLeaveLinkComponent.setup()
    @groupMemberListComponent.setup()
    @copyLinkComponent.setup()
    @discussionList.setup()

  teardown: =>
    @unreadBadges.teardown()
    @groupMembershipWatcherComponent.teardown()
    @groupLeaveLinkComponent.teardown()
    @groupMemberListComponent.teardown()
    @discussionList.teardown()
