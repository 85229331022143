import React from "react"
import { shape, bool } from "prop-types"

import Restore from "./Restore"
import Archive from "./Archive"
import Delete from "./Delete"

const RemoveGroupActions = ({ group }) => {
  return group.archived ? (
    <>
      <Restore group={group} />
      <Delete group={group} />
    </>
  ) : (
    <Archive group={group} />
  )
}

RemoveGroupActions.propTypes = {
  group: shape({
    archived: bool.isRequired
  }).isRequired
}

export default RemoveGroupActions
