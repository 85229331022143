import React from "react"
import { arrayOf, bool, number, shape } from "prop-types"

import { attendeesSortFunction } from "src/features/meetingPacks/MeetingPack/Attendees/helpers"

import CardView from "./CardView"
import ExtendedView from "./ExtendedView"

const List = ({
  currentUserId,
  agendaItem,
  teams = [],
  agendaItemAttendees = [],
  possibleAttendees = [],
  extended = false
}) => {
  const sortedAgendaItemAttendees = agendaItemAttendees.sort(attendeesSortFunction)

  return extended ? (
    <ExtendedView
      currentUserId={currentUserId}
      agendaItem={agendaItem}
      teams={teams}
      agendaItemAttendees={sortedAgendaItemAttendees}
      possibleAttendees={possibleAttendees}
    />
  ) : (
    <CardView
      currentUserId={currentUserId}
      agendaItem={agendaItem}
      teams={teams}
      agendaItemAttendees={sortedAgendaItemAttendees}
      possibleAttendees={possibleAttendees}
    />
  )
}

List.propTypes = {
  agendaItem: shape({}).isRequired,
  agendaItemAttendees: arrayOf(shape({})),
  possibleAttendees: arrayOf(shape({})),
  teams: arrayOf(shape),
  currentUserId: number,
  extended: bool
}

export default List
