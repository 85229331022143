export class MessageValidationComponent {
  constructor(options) {
    this.options = options
  }

  validate = (mobile, form, editor) => {
    this.#hideError(form)

    return (
      this.#validateClosingOption(form) &&
      this.#validateNumberClosingOption(form, editor) &&
      this.#validateMessage(editor) &&
      this.#validateSignedDocuments(mobile)
    )
  }

  #validateClosingOption = (form) => {
    const actionType = this.#formActionType(form)
    const valid = actionType === "" || $(form).data().closingOptionSelected

    if (!valid) this.#showError(form, this.#closingOptionError(actionType))

    return valid
  }

  #closingOptionError = (actionType) => {
    const innerText = { voting: "vote", must_read: "must read" }[actionType]

    return `Please select an option to automatically close the ${innerText}.`
  }

  #validateNumberClosingOption = (form, editor) => {
    const actionType = this.#formActionType(form)

    if (
      actionType === "" ||
      !$(form).data().closingOptionSelected ||
      this.#formInputValue(form, "action_closed_type") !== "number"
    )
      return true

    const actorsCount = editor.extractActorIds().length
    const maxActionsCount = this.#formInputValue(form, "max_actions_count")
    const valid = maxActionsCount && maxActionsCount <= actorsCount

    if (!valid) this.#showError(form, this.#numberClosingOptionError(actionType))

    return valid
  }

  #numberClosingOptionError = () => {
    return `The total number of mentioned users has changed. Please update the options.`
  }

  #validateMessage = (editor) => {
    return (
      !editor.contentIsEmpty() ||
      this.options.attachDocumentComponent.documentsAreAttached()
    )
  }

  #validateSignedDocuments = (mobile, form) => {
    if (mobile) return true

    return (
      !$('input[name*="message[signed_documents][0][id]"]', form).length ||
      $('input[name*="message[document_assigned_users][]"]', form).length
    )
  }

  #hideError = (form) => {
    $(".message-validation", form).hide()
    $(".message-closing-options", form).removeClass("message-closing-options-error")
  }

  #showError = (form, error) => {
    $(".message-validation", form).text(error).show()
    $(".message-closing-options", form).addClass("message-closing-options-error")
  }

  #formInputValue = (form, field) => {
    return $(`input[name="message[${field}]"]`, form).val()
  }

  #formActionType = (form) => {
    return this.#formInputValue(form, "action_type")
  }
}
