import React from "react"
import { bool, func, number, shape } from "prop-types"

import { MEMBERSHIP_TYPES } from "src/features/meetingPacks/MeetingPack/constants"
import { MeetingPackMembershipsColumn } from "src/styles/components/Card/List"
import Card, { Mascot } from "src/styles/components/Card/Card"
import { Primary, Title } from "src/styles/components/Card/Title"
import Icon from "src/styles/components/Icon"
import CheckboxContainer from "src/features/meetingPacks/AttendeesTable/ManageAgendaItemsModal/AgendaItemRow/CheckboxContainer"

const TeamCard = ({
  team,
  disablePresentMembership,
  toggleTeam,
  selectedMemberships
}) => {
  const selectedAny = (membershipType) => {
    return team.userIds.some((userId) => {
      return selectedMemberships[userId] === membershipType
    })
  }

  const selectedAll = (membershipType) => {
    return team.userIds.every((userId) => {
      return (
        typeof selectedMemberships[userId] === "undefined" ||
        selectedMemberships[userId] === membershipType
      )
    })
  }

  return (
    <Card horizontal className="px-4" data-testid="user-card" data-id={team.id}>
      <Mascot className="mr-2">
        <Icon className="mr-2 text-black-50" type="user-supervisor" size="large" />
      </Mascot>
      <Title>
        <Primary>{team.name}</Primary>
      </Title>
      <MeetingPackMembershipsColumn>
        <CheckboxContainer
          id={`team-check-box-${team.id}-present`}
          checked={selectedAny(MEMBERSHIP_TYPES.present)}
          partiallyChecked={!selectedAll(MEMBERSHIP_TYPES.present)}
          disabled={disablePresentMembership}
          onChange={(e) =>
            toggleTeam({
              checked: e.target.checked,
              teamMembershipIds: team.userIds,
              membershipType: MEMBERSHIP_TYPES.present
            })
          }
        />
        <CheckboxContainer
          id={`team-check-box-${team.id}-access-only`}
          checked={selectedAny(MEMBERSHIP_TYPES.accessOnly)}
          partiallyChecked={!selectedAll(MEMBERSHIP_TYPES.accessOnly)}
          onChange={(e) =>
            toggleTeam({
              checked: e.target.checked,
              teamMembershipIds: team.userIds,
              membershipType: MEMBERSHIP_TYPES.accessOnly
            })
          }
        />
      </MeetingPackMembershipsColumn>
    </Card>
  )
}

TeamCard.propTypes = {
  toggleTeam: func.isRequired,
  disablePresentMembership: bool.isRequired,
  team: shape({
    id: number.isRequired
  }).isRequired,
  selectedMemberships: shape().isRequired
}

export default TeamCard
