import React from "react"
import { func, instanceOf, oneOfType } from "prop-types"

import { UploadFile } from "src/features/groups/DocumentsExplorer/UploadFile"
import UploadFolder from "src/features/groups/DocumentsExplorer/UploadFolder"

import FileControls from "./FileControls"
import FolderControls from "./FolderControls"
import { Container } from "./styles"

const isFile = (folderItem) => {
  return folderItem instanceof UploadFile
}

const Controls = ({ folderItem, onRemove }) => {
  return (
    <Container>
      {isFile(folderItem) && <FileControls file={folderItem} onRemove={onRemove} />}
      {!isFile(folderItem) && <FolderControls folder={folderItem} onRemove={onRemove} />}
    </Container>
  )
}

Controls.propTypes = {
  folderItem: oneOfType([instanceOf(UploadFile), instanceOf(UploadFolder)]).isRequired,
  onRemove: func.isRequired
}

export default Controls
