import React from "react"
import { arrayOf, func, number } from "prop-types"

import SearchableSelect from "src/components/SearchableSelect"
import { optionType } from "src/constants/propTypes"
import SelectedValues from "src/features/Search/SearchPage/Search/Filters/SelectedValues"

import { FilterContainer, FilterTitle } from "../styles"
import { filterOption } from "../helpers"

const Label = ({ label }) => label

const components = { NoOptionsMessage: () => null }

const WhoFilter = ({
  whoFilter,
  whoFilterOptions,
  addWhoFilterValue,
  removeWhoFilterValue
}) => {
  const label = whoFilter.length ? "Select" : "Everyone"
  const selectedValues = whoFilterOptions.filter(({ value }) => whoFilter.includes(value))

  return (
    <FilterContainer>
      <FilterTitle>Who</FilterTitle>
      <SelectedValues
        selectedValues={selectedValues}
        deselect={removeWhoFilterValue}
        labelComponent={Label}
      />

      <SearchableSelect
        isMulti
        useSelectedLabel={false}
        label={label}
        options={whoFilterOptions}
        selected={selectedValues}
        setSelected={addWhoFilterValue}
        components={components}
        filterOption={filterOption}
      />
    </FilterContainer>
  )
}

WhoFilter.propTypes = {
  whoFilter: arrayOf(number),
  whoFilterOptions: arrayOf(optionType),
  addWhoFilterValue: func,
  removeWhoFilterValue: func
}

export default WhoFilter
