import React from "react"
import { bool, func } from "prop-types"

import { withDefaultPrevented } from "src/helpers/events"

const ShowMoreLink = ({ isListOpened = true, onClick }) => {
  const handleClick = withDefaultPrevented(onClick)

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href="#" className="sidebar-more-link collapsed mtx" onClick={handleClick}>
      {isListOpened ? "Show less" : "Show more"}
    </a>
  )
}

ShowMoreLink.propTypes = {
  isListOpened: bool.isRequired,
  onClick: func.isRequired
}

export default ShowMoreLink
