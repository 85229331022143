import { createSlice } from "@reduxjs/toolkit"
import { keyBy, union } from "lodash"

import { setIsLoading, setSuccess, setFailure } from "src/helpers/slice"
import { PER_PAGE_LIMIT } from "src/constants"

const initialState = {
  allIds: [],
  byId: {},
  isLoading: false,
  error: null,
  hasNextPage: true
}

const slice = createSlice({
  name: "meetings",
  initialState,
  reducers: {
    resetState: () => initialState,
    fetchMeetingPackFailure: setFailure,
    fetchMeetingPackRequest: setIsLoading,
    fetchMeetingPackSuccess: (state, action) => {
      setSuccess(state)

      const meeting = action.payload

      if (!state.allIds.includes(meeting.id)) {
        state.allIds.unshift(meeting.id)
      }
      state.byId[meeting.id] = { ...state.byId[meeting.id], ...meeting }
    },
    fetchMeetingPacksFailure: (state, action) => {
      setFailure(state, action)

      state.hasNextPage = false
    },
    fetchMeetingPacksRequest: setIsLoading,
    fetchMeetingPacksSuccess: (state, action) => {
      setSuccess(state)

      const meetings = action.payload

      const newMeetingsIds = meetings.map((meeting) => meeting.id)
      const newMeetingsById = keyBy(meetings, "id")

      state.hasNextPage = newMeetingsIds.length === PER_PAGE_LIMIT
      state.allIds = union(state.allIds, newMeetingsIds)
      state.byId = { ...state.byId, ...newMeetingsById }
    },
    removeMeetingSuccess: (state, action) => {
      const { meetingPackId } = action.payload
      const meetingIndex = state.allIds.findIndex((id) => id === meetingPackId)

      if (meetingIndex !== -1) state.allIds.splice(meetingIndex, 1)
      if (state.byId[meetingPackId]) delete state.byId[meetingPackId]
    },
    updateMeetingPack: (state, action) => {
      const meetingPack = action.payload

      if (state.byId[meetingPack.id]) {
        state.byId[meetingPack.id] = {
          ...state.byId[meetingPack.id],
          ...meetingPack
        }
      }
    }
  }
})

const { actions, reducer } = slice

export const {
  resetState,
  fetchMeetingPacksFailure,
  fetchMeetingPacksRequest,
  fetchMeetingPacksSuccess,
  fetchMeetingPackFailure,
  fetchMeetingPackRequest,
  fetchMeetingPackSuccess,
  removeMeetingSuccess,
  updateMeetingPack
} = actions

export default reducer
