import React, { useState } from "react"
import { func } from "prop-types"

import UploadModalContent from "src/components/FilesUpload/ModalContent"

const UploadSection = ({ close, uploadDocument, addAttachedDocuments }) => {
  const [selectedFiles, setSelectedFiles] = useState([])
  const isUploading = selectedFiles.some((file) => file.isUploading)

  const setSelectedFilesWithMeta = (files) => {
    setSelectedFiles(files.map((file) => ({ file, name: file.name, isUploading: false })))
  }

  const setAllFilesAsUploading = () => {
    setSelectedFiles(selectedFiles.map((file) => ({ ...file, isUploading: true })))
  }

  const setFileAsNotUploading = (file) => {
    setSelectedFiles((actualFiles) => {
      return actualFiles.map((selectedFile) => ({
        ...selectedFile,
        isUploading:
          file.name === selectedFile.file.name ? false : selectedFile.isUploading
      }))
    })
  }

  const uploadDocuments = () => {
    setAllFilesAsUploading()

    Promise.all(
      selectedFiles.map((document) =>
        uploadDocument(document).then(({ data }) => {
          setFileAsNotUploading(document)

          return { id: data.id, name: document.name }
        })
      )
    ).then((documents) => {
      addAttachedDocuments(documents)
      close()
    })
  }

  return (
    <UploadModalContent
      selectedFiles={selectedFiles}
      close={close}
      uploadFiles={uploadDocuments}
      isUploading={isUploading}
      setSelectedFiles={setSelectedFilesWithMeta}
      multiple
      allowedFiles="documents"
    />
  )
}

UploadSection.propTypes = {
  close: func.isRequired,
  uploadDocument: func.isRequired,
  addAttachedDocuments: func.isRequired
}

export default UploadSection
