import React, { useState } from "react"
import { string, number, bool } from "prop-types"
import { cx } from "@linaria/core"

import SourceMeetingPackSelect from "../SourceMeetingPackSelect"

const StepOneForm = ({
  accountId,
  copyInformation: storedCopyInformation,
  copyAgendaItems: storedCopyAgendaItems,
  copySubItems: storedCopySubItems,
  copyAttendees: storedCopyAttendees,
  submitPath,
  cancelPath
}) => {
  const [copyInformation, setCopyInformation] = useState(Boolean(storedCopyInformation))
  const [copyAgendaItems, setCopyAgendaItems] = useState(Boolean(storedCopyAgendaItems))
  const [copySubItems, setCopySubItems] = useState(Boolean(storedCopySubItems))
  const [copyAttendees, setCopyAttendees] = useState(Boolean(storedCopyAttendees))
  const [copyAttendeesEnabled, enableCopyAttendees] = useState(true)

  const handleCopyAgendaItemsChange = () => {
    const newValue = !copyAgendaItems

    if (!newValue) {
      setCopySubItems(false)
    }

    setCopyAgendaItems(newValue)
  }

  const handleMeetingPackChange = ({ accountId: selectedAccountId }) => {
    if (selectedAccountId !== accountId) {
      setCopyAttendees(false)
      enableCopyAttendees(false)
    } else {
      enableCopyAttendees(true)
    }
  }

  return (
    <form
      className="meeting-pack-form"
      action={submitPath}
      method="post"
      data-turbo="false"
      data-remote="true"
    >
      <p className="text-muted text-small mbm">
        Would you like to copy information from an existing meeting pack?
      </p>
      <input
        type="checkbox"
        id="copy_information"
        className="copy-information-checkbox"
        checked={copyInformation}
        name="step_one[copy_information]"
        readOnly
      />
      <div className="btn-group w-100">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
        <label
          className="button justify-content-center"
          htmlFor="copy_information"
          onClick={() => setCopyInformation(false)}
          data-testid="copy-information-no"
        >
          No
        </label>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
        <label
          className="button justify-content-center"
          htmlFor="copy_information"
          onClick={() => setCopyInformation(true)}
          data-testid="copy-information-yes"
        >
          Yes
        </label>
      </div>
      <div className={cx("copy-information-section", !copyInformation && "d-none")}>
        <SourceMeetingPackSelect
          currentAccountId={accountId}
          onChange={handleMeetingPackChange}
        />
        <div className="mtm text-small text-muted">
          <div className="checkbox">
            <input
              type="checkbox"
              id="copy_agenda_items"
              checked={copyAgendaItems}
              name="step_one[copy_agenda_items]"
              readOnly
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
            <label htmlFor="copy_agenda_items" onClick={handleCopyAgendaItemsChange}>
              Copy agenda items
            </label>
          </div>
          <div className={cx("checkbox mlm", !copyAgendaItems && "disabled")}>
            <input
              type="checkbox"
              id="copy_sub_items"
              name="step_one[copy_sub_items]"
              checked={copySubItems}
              disabled={!copyAgendaItems}
              readOnly
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
            <label
              htmlFor="copy_sub_items"
              onClick={() => setCopySubItems(!copySubItems)}
            >
              Copy sub items
            </label>
          </div>
          <div className={cx("checkbox", !copyAttendeesEnabled && "disabled")}>
            <input
              type="checkbox"
              id="copy_attendees"
              name="step_one[copy_attendees]"
              checked={copyAttendees}
              disabled={!copyAttendees}
              readOnly
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
            <label
              htmlFor="copy_attendees"
              onClick={() => setCopyAttendees(!copyAttendees)}
            >
              Copy attendees
            </label>
          </div>
        </div>
        <div className="mtm">
          <p className="text-notification">
            You can alter the agenda and attendees once the pack is created.
          </p>
          <p className="text-notification">
            Attendees won&apos;t be notified until you have published the pack.
          </p>
        </div>
      </div>
      <hr />
      <div className="form-footer">
        <a
          href={cancelPath}
          className="button is-outline-primary mrl pxl w-25 justify-content-center"
          rel="nofollow"
          data-method="delete"
        >
          Cancel
        </a>
        <input
          type="submit"
          name="commit"
          value="Next"
          className="button is-primary pxl mx-0 w-25 justify-content-center"
        />
      </div>
    </form>
  )
}

StepOneForm.propTypes = {
  accountId: number.isRequired,
  copyInformation: bool,
  copyAgendaItems: bool,
  copySubItems: bool,
  copyAttendees: bool,
  submitPath: string.isRequired,
  cancelPath: string.isRequired
}

export default StepOneForm
