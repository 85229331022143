import React from "react"
import { bool, number } from "prop-types"

import useSyncStatus from "src/features/offline/hooks/useSyncStatus"
import { showSavedOfflineIconInSidebar } from "src/features/offline/helpers/meetingPacks"

import { IconSyncing, IconDone, OfflineStatusText } from "./styles"

const SyncStatus = ({ meetingPackId, showText = true }) => {
  const onSyncFinished = () => showSavedOfflineIconInSidebar({ meetingPackId })
  const { allDocumentsAreSaved, savedDocumentsCount, allDocumentsCount } = useSyncStatus({
    meetingPackId,
    onSyncFinished
  })

  if (allDocumentsAreSaved) {
    return (
      <span className="saved-offline">
        {showText && <OfflineStatusText>Available offline</OfflineStatusText>}
        <IconDone />
      </span>
    )
  }

  if (allDocumentsCount && savedDocumentsCount !== null) {
    return (
      <span className="saved-offline">
        {showText && (
          <OfflineStatusText>
            Syncing {savedDocumentsCount} of {allDocumentsCount}
          </OfflineStatusText>
        )}
        <IconSyncing />
      </span>
    )
  }

  return null
}

SyncStatus.propTypes = {
  meetingPackId: number.isRequired,
  showText: bool
}

export default SyncStatus
