import { styled } from "@linaria/react"

import { BREAKPOINTS, FONT_SIZES, SPACES } from "src/styles/sizes"
import { Dropdown } from "src/components/SearchableSelect/styles"
import UnstyledButton from "src/styles/components/Button/Unstyled"
import { BASE_COLORS, TEXT_COLORS } from "src/styles/colors"

export const FiltersTitle = styled.h4`
  font-size: ${FONT_SIZES.small};

  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    display: none;
  }
`

export const FilterMobileToggle = styled(UnstyledButton)`
  display: none;
  color: ${TEXT_COLORS.muted};
  margin: 0 auto ${SPACES.small};

  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    display: block;
  }
`

export const FiltersContainer = styled.div``

export const FiltersWithToggleContainer = styled.div`
  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    ${FiltersContainer} {
      display: none;
    }
    &.shown-on-mobile {
      ${FiltersContainer} {
        display: block;
      }
    }
  }
`

export const FilterContainer = styled.div`
  margin-top: ${SPACES.medium};
  font-size: ${FONT_SIZES.small};

  [class*="-control"] {
    min-height: 0;
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: none;
    transition: border-color 0.2s ease;

    &:hover,
    &:active {
      border-color: rgba(0, 0, 0, 0.3);
    }
  }

  ${Dropdown} {
    width: 100%;

    .dropdown-menu {
      width: 100%;
      font-size: ${FONT_SIZES.small};
    }

    .btn.dropdown-toggle {
      background-color: ${BASE_COLORS.white};
      border-radius: ${SPACES.xxSmall};
      font-size: ${FONT_SIZES.small};
      margin: ${SPACES.xSmall} 0;
      padding: 0 ${SPACES.xSmall};
      overflow: hidden;
      width: 100%;

      .show & {
        background-color: ${BASE_COLORS.white};
        &:hover,
        &:active {
          background-color: ${BASE_COLORS.white};
        }
      }

      &:after {
        width: 100%;
        text-align: right;
      }
    }
  }
`

export const FilterTitle = styled.h5`
  font-size: ${FONT_SIZES.small};
  font-weight: normal;
  margin-bottom: 0;
`

export const SelectedValueContainer = styled.div`
  align-items: center;
  background: ${BASE_COLORS.lightGray};
  border-radius: ${SPACES.xxSmall};
  display: flex;
  font-size: ${FONT_SIZES.small};
  justify-content: space-between;
  padding: ${SPACES.xSmall} 0.75rem ${SPACES.xSmall} ${SPACES.xSmall};

  &:first-of-type {
    margin-top: ${SPACES.xSmall};
  }

  [class^="icon-"] {
    font-size: ${FONT_SIZES.small};
    cursor: pointer;
  }
`
