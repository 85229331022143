class @UserStatusLabelComponent
  constructor: () ->
    @statusChannel = AblyRealtimeClient.channels.get(
      "users:online-status"
    )

    @channelData = [
      {
        channel: @statusChannel,
        subscriber: @handleStatusChannelMessage
      }
    ]

  setup: =>
    $setupChannelSubscriptions(@channelData)

  teardown: =>
    $teardownChannelSubscriptions(@channelData)

  handleStatusChannelMessage: (channelMessage) =>
    statusLabels = $beById('user-status-label', channelMessage.data.userId)
    return if statusLabels.length < 1

    switch channelMessage.data.status
      when "online"
        statusLabels.
          text("Online").
          removeClass('offline').
          addClass('online')
      when "offline"
        statusLabels.text("Offline").
          removeClass('online').
          addClass('offline')
