import React from "react"
import { number, func, shape, string } from "prop-types"
import { styled } from "@linaria/react"
import classNames from "classnames"

import { BACKGROUND_COLORS } from "src/styles/colors"
import { SPACES } from "src/styles/sizes"
import Icon from "src/styles/components/Icon"
import useDragDrop from "src/features/ManageAccount/AirtableApps/EditPage/useDragDrop"

const FieldRow = ({ field, onRemove, setFields, index, className }) => {
  const {
    ref,
    preview,
    handlerId,
    style: dragAndDropStyle
  } = useDragDrop({
    id: field.id,
    index,
    itemType: "field",
    setItems: setFields
  })

  return (
    <div
      ref={ref}
      data-handler-id={handlerId}
      className={classNames("d-flex align-items-center mt-2", className)}
    >
      <div
        ref={preview}
        style={dragAndDropStyle}
        className="flex-grow-1 pl-3 text-truncate field-row"
      >
        {field.name}
      </div>
      <Icon
        type="cancel"
        size="medium"
        className="pl-2"
        onClick={() => onRemove(field.id)}
      />
    </div>
  )
}

FieldRow.propTypes = {
  field: shape({
    id: string.isRequired,
    name: string.isRequired
  }),
  onRemove: func.isRequired,
  setFields: func.isRequired,
  index: number.isRequired
}

export default styled(FieldRow)`
  .field-row {
    background-color: ${BACKGROUND_COLORS.main};
    border-radius: 3px;
    padding: ${SPACES.xSmall};
  }
`
