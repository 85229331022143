import { func } from "prop-types"
import React from "react"

const Filter = ({ onChange }) => {
  return (
    <input
      className="form-field"
      type="text"
      placeholder="Filter by name, email or job title..."
      onChange={(e) => onChange(e.target.value)}
    />
  )
}

Filter.propTypes = {
  onChange: func.isRequired
}

export default Filter
