import React from "react"
import { func, string } from "prop-types"

const MembershipTypeSelect = ({ onChange, value, ...rest }) => {
  return (
    <select
      className="form-field membership-type-select"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      data-behavior="membership-type-select"
      {...rest}
    >
      <option value="">Attendee Type</option>
      <option value="present">Present</option>
      <option value="in_attendance">In Attendance</option>
      <option value="apologies">Apologies</option>
      <option value="access_only">Access Only</option>
    </select>
  )
}

MembershipTypeSelect.propTypes = {
  value: string,
  onChange: func.isRequired
}

export default MembershipTypeSelect
