class @UserSettingsService
  setGroupsView: (id, schemeId, groupsView) ->
    $.ajax(
      url: "/user_settings/#{id}",
      type: 'PUT',
      data: { groups_view: groupsView, scheme_id: schemeId }
    )

  setGroupsOrder: (id, schemeId, groupsOrder) ->
    $.ajax(
      url: "/user_settings/#{id}",
      type: 'PUT',
      data: { groups_order: groupsOrder, scheme_id: schemeId }
    )
