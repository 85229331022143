import { showFlashMessage } from "src/helpers/flash"
import { showCaughtErrorMessage } from "src/helpers/errors"

import tags from "./api"
import {
  fetchTagsFailure,
  fetchTagsRequest,
  fetchTagsSuccess,
  updateTagSuccess
} from "./slice"

export const fetchTags = (params = {}) => (dispatch) => {
  dispatch(fetchTagsRequest())

  tags
    .index(params)
    .then((response) => {
      dispatch(fetchTagsSuccess(response.data))
    })
    .catch((error) => {
      showCaughtErrorMessage(error)
      dispatch(fetchTagsFailure(error))
    })
}

export const setInitialStateFromProps = (props) => (dispatch) => {
  dispatch(fetchTagsSuccess(props))
}

export const updateTag = (data) => (dispatch) => {
  return tags
    .update(data)
    .then((response) => {
      showFlashMessage("info", "Tag has been renamed.")
      dispatch(updateTagSuccess(response.data))
    })
    .catch((error) => showCaughtErrorMessage(error))
}
