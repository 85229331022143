import React, { useEffect, useState } from "react"
import { func, number } from "prop-types"

import SearchableSelect from "src/components/SearchableSelect"
import ContentLoader from "src/styles/components/ContentLoader"

import useMeetingPacks from "./useMeetingPacks"
import useAccounts from "./useAccounts"

const LoadingOption = { value: null, label: "Loading..." }

const SourceMeetingPackSelect = ({ currentAccountId, onChange }) => {
  const { accounts, loading: accountsLoading } = useAccounts({ currentAccountId })

  const accountOptions = accounts.map((account) => ({
    value: account.id,
    label: account.name
  }))

  const [selectedAccountOption, selectAccountOption] = useState(LoadingOption)

  const { meetingPacks, loading: meetingPacksLoading } = useMeetingPacks({
    accountId: selectedAccountOption.value
  })
  const meetingPackOptions = meetingPacks.map((meetingPack) => ({
    value: meetingPack.id,
    label: meetingPack.name
  }))

  const [selectedMeetingPackOption, selectMeetingPackOption] = useState(LoadingOption)

  const handleAccountOptionSelect = (option) => {
    if (onChange) {
      onChange({
        accountId: option.value,
        meetingPackId: selectedMeetingPackOption.value
      })
    }

    selectAccountOption(option)
  }

  const handleMeetingPackOptionSelect = (option) => {
    selectMeetingPackOption(option)

    if (onChange) {
      onChange({ accountId: selectedAccountOption.value, meetingPackId: option.value })
    }
  }

  useEffect(() => {
    if (accountsLoading) {
      return
    }

    const accountOption = accountOptions.find(
      (option) => option.value === currentAccountId
    )

    handleAccountOptionSelect(accountOption || accountOptions[0])
  }, [accountsLoading])

  useEffect(() => {
    if (meetingPackOptions.length === 0) {
      return
    }

    handleMeetingPackOptionSelect(meetingPackOptions[0])
  }, [meetingPackOptions.length])

  const meetingPackSelectOptions = meetingPacksLoading ? [] : meetingPackOptions
  const meetingPackSelectedOption = meetingPacksLoading
    ? LoadingOption
    : selectedMeetingPackOption

  if (accountsLoading) {
    return <ContentLoader />
  }

  const hideAccountsSelect = accounts.length === 1 && accounts[0].id === currentAccountId

  return (
    <>
      {!hideAccountsSelect && (
        <>
          <p className="text-muted text-small mbx">Select Organisation to copy from</p>
          <SearchableSelect
            options={accountOptions}
            selected={selectedAccountOption || LoadingOption}
            setSelected={handleAccountOptionSelect}
            className="btn-block mbx"
          />
        </>
      )}

      <p className="text-muted text-small mbx">Select which Meeting Pack</p>
      <SearchableSelect
        options={meetingPackSelectOptions}
        selected={meetingPackSelectedOption}
        setSelected={handleMeetingPackOptionSelect}
        className="btn-block"
      />
      <input
        id="step_one[source_meeting_pack_id]"
        type="hidden"
        name="step_one[source_meeting_pack_id]"
        value={selectedMeetingPackOption?.value || ""}
        className="hidden"
        readOnly
      />
    </>
  )
}

SourceMeetingPackSelect.propTypes = {
  currentAccountId: number.isRequired,
  onChange: func
}

export default SourceMeetingPackSelect
