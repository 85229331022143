const Status = {
  Pending: "pending",
  Uploading: "uploading",
  Uploaded: "uploaded",
  Failed: "failed"
}

export class UploadFile {
  rawFile

  status = Status.Pending

  errorMessage = null

  path = []

  storagePath = null

  constructor({ file, path = [] }) {
    this.rawFile = file
    this.path = path
  }

  get name() {
    return this.rawFile.name
  }

  get size() {
    if (!this.rawFile.size) throw new Error("File must have size")

    return this.rawFile.size
  }

  markAsUploading() {
    this.status = Status.Uploading
  }

  markAsUploaded() {
    this.status = Status.Uploaded
  }

  markAsFailed(errorMessage = null) {
    this.status = Status.Failed
    this.errorMessage = errorMessage
  }

  markAsPending() {
    this.status = Status.Pending
    this.errorMessage = null
  }

  isPending() {
    return this.status === Status.Pending
  }

  isUploading() {
    return this.status === Status.Uploading
  }

  isUploaded() {
    return this.status === Status.Uploaded
  }

  isFailed() {
    return this.status === Status.Failed
  }
}
