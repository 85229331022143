import React, { useCallback, useEffect, useState } from "react"
import { useSelector, shallowEqual } from "react-redux"
import { Form, Modal, Row, Col } from "react-bootstrap"
import { shape, bool, func, number } from "prop-types"

import SchemeSelect from "src/components/SchemeSelect"
import Select from "src/styles/components/Select"
import PrimaryButton from "src/styles/components/Button/Primary"
import SecondaryButton from "src/styles/components/Button/Secondary"
import ContentDivider from "src/styles/components/ContentDivider"
import ModalTitle from "src/styles/components/Modal/Title"
import ModalHeader from "src/styles/components/Modal/Header"
import ModalFooter from "src/styles/components/Modal/Footer"
import WarningText from "src/styles/components/WarningText"
import useIsOpened from "src/hooks/useIsOpened"
import { StyledModal } from "src/styles/components/Modal"
import { selectAccountOptions } from "src/resources/accounts/selectors"
import { selectSchemeOptions } from "src/resources/schemes/selectors"

import UsersList from "./UsersList"
import ConfirmModal from "./ConfirmModal"
import { GroupHeader, UsersListContainer } from "./styles"

const MoveModal = ({ account, group, isOpened, close }) => {
  const {
    isOpened: isConfirmModalOpened,
    open: openConfirmModal,
    close: closeConfirmModal
  } = useIsOpened()

  const [selectedScheme, setSelectedScheme] = useState()
  const [selectedAccount, setSelectedAccount] = useState()

  const schemeOptions = useSelector(selectSchemeOptions, shallowEqual)
  const accountOptions = useSelector(selectAccountOptions, shallowEqual)

  const currentScheme = schemeOptions.find(({ value }) => value === group.schemeId)
  const currentAccount = accountOptions.find(({ value }) => value === account.id)

  const schemeOptionsByAccount = schemeOptions
    .filter(
      ({ accountId, accountIds }) =>
        accountId === selectedAccount?.value ||
        accountIds.includes(selectedAccount?.value)
    )
    .map((schemeOption) => ({
      ...schemeOption,
      isDisabled: schemeOption.value === group.schemeId
    }))

  const accountIdsBySchemes = [
    ...new Set(
      schemeOptions.flatMap(({ accountId, accountIds }) =>
        accountId ? [accountId] : accountIds
      )
    )
  ]
  const accountOptionsBySchemes = accountOptions.filter(({ value }) =>
    accountIdsBySchemes.includes(value)
  )

  const canBeMoved = selectedScheme && selectedScheme.value !== group.schemeId

  const newSchemeUserIds = canBeMoved ? selectedScheme.users.map(({ id }) => id) : []
  const currentUsers = canBeMoved
    ? group.users.map((user) => ({
        ...user,
        removed: !newSchemeUserIds.includes(user.id)
      }))
    : []

  useEffect(() => {
    if (currentScheme && !selectedScheme) setSelectedScheme(currentScheme)
    if (currentAccount && !selectedAccount) setSelectedAccount(currentAccount)
  }, [currentScheme, currentAccount])

  const handleAccountChange = useCallback(
    (accountOption) => {
      if (selectedAccount !== accountOption) {
        setSelectedAccount(accountOption)
        setSelectedScheme(null)
      }
    },
    [selectedAccount]
  )

  const handleMove = () => {
    close()
    openConfirmModal()
  }

  const handleClose = () => {
    close()
    closeConfirmModal()
    setSelectedScheme(currentScheme)
    setSelectedAccount(currentAccount)
  }

  return (
    <>
      <StyledModal size="md" onHide={handleClose} show={isOpened}>
        <ModalHeader>
          <ModalTitle>Move Group to another Space</ModalTitle>
        </ModalHeader>
        <Modal.Body data-testid="move-group-modal">
          <Form>
            <Form.Label className="text-muted">Move {group.name} to:</Form.Label>
            <Form.Group data-testid="account-select">
              <Select
                placeholder="Select organisation"
                onChange={handleAccountChange}
                options={accountOptionsBySchemes}
                value={selectedAccount}
              />
            </Form.Group>
            <Form.Group data-testid="scheme-select">
              <SchemeSelect
                isDisabled={!selectedAccount}
                placeholder="Select space"
                isOptionDisabled={({ value }) => value === group.schemeId}
                onChange={setSelectedScheme}
                options={schemeOptionsByAccount}
                value={selectedScheme}
              />
            </Form.Group>
            {canBeMoved && (
              <>
                <Row className="mt-4">
                  <Col>
                    <GroupHeader>Group Members</GroupHeader>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <UsersListContainer>
                      <UsersList
                        field="removed"
                        currentUserId={window.$currentUserId()}
                        users={currentUsers}
                      />
                    </UsersListContainer>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ContentDivider fullWidth />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <WarningText
                      variant="danger"
                      text={`Highlighted people will lose access to ${group.name}`}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Form>
        </Modal.Body>
        <ModalFooter>
          <PrimaryButton className="mr-3" disabled={!canBeMoved} onClick={handleMove}>
            Move here
          </PrimaryButton>
          <SecondaryButton onClick={handleClose}>Close</SecondaryButton>
        </ModalFooter>
      </StyledModal>
      {canBeMoved && (
        <ConfirmModal
          currentAccount={currentAccount}
          currentScheme={currentScheme}
          newAccount={selectedAccount}
          newScheme={selectedScheme}
          group={group}
          isOpened={isConfirmModalOpened}
          close={handleClose}
        />
      )}
    </>
  )
}

MoveModal.propTypes = {
  account: shape({
    id: number.isRequired
  }).isRequired,
  group: shape({
    id: number.isRequired,
    schemeId: number.isRequired
  }).isRequired,
  isOpened: bool.isRequired,
  close: func.isRequired
}

export default MoveModal
