import { isValidElement } from "react"
import ReactDOM from "react-dom"
import { captureMessage } from "@sentry/browser"

import { buildDownloadButton } from "src/helpers/box"
import { BOX_API_HOST } from "src/constants"

const LEFT_CUSTOM_TOOLBAR_BUTTONS_CONTAINER_ID = "left-custom-toolbar-buttons-container"
const RIGHT_CUSTOM_TOOLBAR_BUTTONS_CONTAINER_ID = "right-custom-toolbar-buttons-container"
const PREVIEW_MIN_WIDTH_FOR_SIDEBAR = 768 // px

// Must be higher that z-index of .header and .main-menu
// (specified in header.sass and main-menu.sass)
const FULLSCREEN_MODE_Z_INDEX = 5

class BoxPreview {
  /* eslint-disable lines-between-class-members */
  #preview
  #boxAuthService
  #sidebar

  #resizeObserver

  #document
  #previewContainerElement
  #sidebarContainerElement
  #collection
  #onNavigate
  #fullscreenToggle
  #inFullscreen
  #toolbarButtonsFactory
  /* eslint-enable lines-between-class-members */

  constructor({
    document,
    previewContainer,
    sidebarContainer = null,
    collection = [],
    onNavigate = () => {},
    toolbarButtonsFactory = () => []
  }) {
    this.#preview = new window.Box.Preview()
    this.#boxAuthService = new window.BoxAuthService()
    this.#sidebar = new window.Box.ContentSidebar()

    this.#resizeObserver = new ResizeObserver((entries) => this.#handleResize(entries))

    this.#document = document
    this.#previewContainerElement = window.document.querySelector(previewContainer)

    this.#sidebarContainerElement = sidebarContainer
      ? window.document.querySelector(sidebarContainer)
      : null

    this.#collection = collection
    this.#onNavigate = onNavigate
    this.#fullscreenToggle = null
    this.#inFullscreen = false

    this.#toolbarButtonsFactory = toolbarButtonsFactory
  }

  show() {
    this.#bindListeners()
    this.#onNavigate(this.#document)
    this.#showPreview()

    this.#forceSidebarCollapsedState()
    this.#showSidebar(this.#document.boxFileId)
  }

  destroy() {
    if (this.#sidebarContainerElement) {
      this.#sidebar.hide()
    }

    this.#preview.removeAllListeners()
    this.#preview.destroy()
    this.#resizeObserver.disconnect()
  }

  #bindListeners() {
    this.#listenPreviewNavigateEvent()
    this.#listenPreviewLoadEvent()
    this.#observePreviewParentResize()
  }

  // Due to problems with download button on iOS PWA
  // https://github.com/Knowa-platform/knowa/pull/1519
  #replaceDownloadButton(file) {
    const btnDownload = document.getElementsByClassName("bp-btn-download")[0]
    if (!btnDownload) return

    const { button: newDownloadButton, onClick: onClickDownloadButton } =
      buildDownloadButton()

    const { parentNode } = btnDownload
    parentNode.removeChild(btnDownload)

    parentNode.appendChild(newDownloadButton)
    newDownloadButton.onclick = onClickDownloadButton({
      filename: file.filename,
      boxAuthService: this.#boxAuthService,
      fileId: file.boxFileId
    })
  }

  #displayCustomToolbarButtons(file) {
    this.#removeCustomToolbarButtons()

    const customToolbarButtons = this.#toolbarButtonsFactory(file)

    if (customToolbarButtons.length === 0) {
      return
    }

    this.#displayLeftCustomToolbarButtons(customToolbarButtons)
    this.#displayRightCustomToolbarButtons(customToolbarButtons)
  }

  #displayLeftCustomToolbarButtons(buttons) {
    const leftButtons = buttons.filter(({ align }) => align === "left")
    if (leftButtons.length === 0) return

    const leftButtonsContatainer = document.createElement("div")
    leftButtonsContatainer.setAttribute("id", LEFT_CUSTOM_TOOLBAR_BUTTONS_CONTAINER_ID)

    const header = this.#previewContainerElement.querySelector(
      ".bp-header.bp-base-header"
    )

    leftButtons.forEach((button) => {
      this.#renderCustomToolbarButton(button, leftButtonsContatainer)
    })

    header.prepend(leftButtonsContatainer)
  }

  #displayRightCustomToolbarButtons(buttons) {
    const rightButtons = buttons.filter(({ align }) => align === "right")
    if (rightButtons.length === 0) return

    const rightButtonsContainer = document.createElement("div")
    rightButtonsContainer.setAttribute("id", RIGHT_CUSTOM_TOOLBAR_BUTTONS_CONTAINER_ID)
    rightButtonsContainer.style.display = "flex"

    const headerButtonsContainer = this.#previewContainerElement.querySelector(
      ".bp-header.bp-base-header .bp-header-btns"
    )

    rightButtons.forEach((button) => {
      this.#renderCustomToolbarButton(button, rightButtonsContainer)
    })

    headerButtonsContainer.prepend(rightButtonsContainer)
  }

  #renderCustomToolbarButton(button, buttonsContainer) {
    if (isValidElement(button.element)) {
      const reactComponentWrapper = document.createElement("div")
      reactComponentWrapper.style.display = "contents"
      buttonsContainer.append(reactComponentWrapper)
      ReactDOM.render(button.element, reactComponentWrapper)
    } else {
      buttonsContainer.appendChild(button.element)
    }
  }

  #removeCustomToolbarButtons() {
    const leftButtonscontainer = document.getElementById(
      LEFT_CUSTOM_TOOLBAR_BUTTONS_CONTAINER_ID
    )

    leftButtonscontainer?.remove()

    const rightButtonscontainer = document.getElementById(
      RIGHT_CUSTOM_TOOLBAR_BUTTONS_CONTAINER_ID
    )

    rightButtonscontainer?.remove()
  }

  #showPreview() {
    this.#preview.show(this.#document.boxFileId, this.#boxAuthService.fetchToken, {
      container: this.#previewContainerElement,
      collection: this.#collection.map((doc) => ({ id: doc.boxFileId })),
      apiHost: BOX_API_HOST,
      showDownload: true,
      header: "light"
    })
  }

  #showSidebar(boxFileId) {
    if (!this.#sidebarContainerElement) {
      return
    }

    this.#sidebar.show(boxFileId, this.#boxAuthService.fetchToken, {
      container: this.#sidebarContainerElement,
      apiHost: BOX_API_HOST,
      detailsSidebarProps: {
        hasAccessStats: true,
        hasProperties: true,
        hasVersions: true
      },
      hasVersions: true
    })
  }

  #forceSidebarCollapsedState() {
    // There's no official way to display sidebar as collapsed by default,
    // however, I've found out that its state is controlled by local storage.
    // Note that the second argument must be a string with quotes.
    localStorage.setItem("localStore/0/bcs.force", '"closed"')
  }

  #findFile(fileId) {
    return this.#collection.find((f) => String(f.boxFileId) === String(fileId))
  }

  #listenPreviewNavigateEvent() {
    this.#preview.addListener("navigate", (fileId) => {
      const file = this.#findFile(fileId)

      this.#onNavigate(file)
      this.#showSidebar(fileId)
    })
  }

  #listenPreviewLoadEvent() {
    this.#preview.addListener("load", (event) => {
      // Trigger resize to fix issue with CSV files not showed on repeated preview
      // and images shown not in the center
      event.viewer?.debouncedResizeHandler()

      const fileId = event.file.id
      const file = this.#findFile(fileId) || this.#document

      this.#replaceDownloadButton(file)
      this.#displayCustomToolbarButtons(file)
      this.#bindFullscreenToggle()
    })
  }

  #observePreviewParentResize() {
    this.#resizeObserver.observe(this.#previewContainerElement.parentElement)
  }

  #handleResize(entries) {
    if (!this.#sidebarContainerElement) {
      return
    }

    const resizeEntry = entries[0]
    const width = resizeEntry.contentBoxSize
      ? resizeEntry.contentBoxSize[0].inlineSize
      : resizeEntry.contentRect.width
    const displayStyle = width < PREVIEW_MIN_WIDTH_FOR_SIDEBAR ? "none" : "block"

    this.#sidebarContainerElement.style.display = displayStyle
  }

  // Workaround: Fullscreen API is not available on iOS so Box applies some
  // logic to emulate fullscreen behavior on iOS. However, their logic doesn't
  // work properly when Box preview is displayed as part of the page
  // (not in modal). We may delete this workaround in future when fullscreen
  // API is available on iOS (refer to https://caniuse.com/fullscreen to check
  // current support status for Safari and other browsers on iOS)
  #bindFullscreenToggle() {
    // If browser Fullscreen API is available then we don't need this workaround
    if (window.document.fullscreenEnabled) {
      return
    }

    this.#fullscreenToggle =
      this.#previewContainerElement.querySelector(".bp-FullscreenToggle")

    if (!this.#fullscreenToggle) {
      return
    }

    this.#fullscreenToggle.addEventListener("click", () => {
      this.#inFullscreen = !this.#inFullscreen

      if (this.#isDiscussionPage()) {
        this.#toggleFullscreenOnDiscussionPage()
      } else if (this.#isGroupDocumentsPage()) {
        this.#toggleFullscreenOnGroupDocumentsPage()
      } else {
        captureMessage("Fullscreen has been toggled on unexpected page")
      }
    })
  }

  #isDiscussionPage() {
    return !!window.location.pathname.match(/^\/discussions\/\d+/)
  }

  #toggleFullscreenOnDiscussionPage() {
    const discussionPageWrapper = document.querySelector(
      "[data-behavior=discussion-page-wrapper]"
    )

    if (!discussionPageWrapper || !this.#fullscreenToggle) {
      return
    }

    const drawerToggler = discussionPageWrapper.querySelector(
      "[data-behavior=drawer-toggler]"
    )

    if (this.#inFullscreen) {
      drawerToggler.style.display = "none"

      discussionPageWrapper.style.position = "fixed"
      discussionPageWrapper.style.top = 0
      discussionPageWrapper.style.left = 0
      discussionPageWrapper.style.width = "100%"
      discussionPageWrapper.style.height = "100%"
      discussionPageWrapper.style.zIndex = FULLSCREEN_MODE_Z_INDEX
    } else {
      drawerToggler.style.display = "block"

      discussionPageWrapper.style.position = "static"
      discussionPageWrapper.style.top = "auto"
      discussionPageWrapper.style.left = "auto"
      discussionPageWrapper.style.width = "auto"
      discussionPageWrapper.style.height = 0
      discussionPageWrapper.style.zIndex = "auto"
    }
  }

  #isGroupDocumentsPage() {
    return !!window.location.pathname.match(/^\/groups\/\d+\/documents/)
  }

  #toggleFullscreenOnGroupDocumentsPage() {
    const documentPreview = document.querySelector("[data-behavior=document-preview]")

    if (!documentPreview) {
      return
    }

    if (this.#inFullscreen) {
      documentPreview.style.zIndex = FULLSCREEN_MODE_Z_INDEX
    } else {
      documentPreview.style.zIndex = "auto"
    }
  }
}

export default BoxPreview
