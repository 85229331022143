import {
  MEMBERSHIP_TYPES,
  MEMBERSHIP_TYPES_FOR
} from "src/features/meetingPacks/MeetingPack/constants"

const attendeeCompareString = (attendee) => {
  const hasAccessOnlyAgendaItem =
    attendee.agendaItemMembershipType &&
    attendee.agendaItemMembershipType === MEMBERSHIP_TYPES.accessOnly &&
    !MEMBERSHIP_TYPES_FOR.limitedVisibilityItems.includes(attendee.membershipType)

  const attendeeTypeIndex = MEMBERSHIP_TYPES_FOR.meetingPackAttendees.indexOf(
    hasAccessOnlyAgendaItem ? attendee.agendaItemMembershipType : attendee.membershipType
  )

  return [attendeeTypeIndex === -1 ? 999 : attendeeTypeIndex, attendee.lastName].join("")
}

export const attendeesSortFunction = (attendee1, attendee2) => {
  return attendeeCompareString(attendee1).localeCompare(
    attendeeCompareString(attendee2),
    "en"
  )
}
