import { styled } from "@linaria/react"

import { BREAKPOINTS } from "src/styles/sizes"

import { Skeleton as BaseSkeleton } from "../styles"

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 60px;

  @media (max-width: ${BREAKPOINTS.small}) {
    min-height: 50px;
    height: 50px;
  }
`

export const Skeleton = styled(BaseSkeleton)`
  height: 24px;
  max-width: 800px;
`
