import { number, shape, string } from "prop-types"

export const AgendaItem = shape({
  id: number.isRequired,
  agendaItemId: number
})

export const User = shape({
  id: number.isRequired,
  firstName: string.isRequired,
  lastName: string.isRequired,
  email: string.isRequired,
  avatarColorId: number,
  avatarUrl: string,
  initials: string,
  status: string
})
