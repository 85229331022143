import React from "react"
import { Badge } from "react-bootstrap"
import { styled } from "@linaria/react"
import { bool } from "prop-types"

import { withVariations } from "src/helpers/linaria"
import { FONT_WEIGHTS, SPACES } from "src/styles/sizes"
import { BACKGROUND_COLORS, BORDER_COLORS, TEXT_COLORS } from "src/styles/colors"

const DividerContainer = styled(withVariations("div", ["fullWidth"]))`
  position: relative;
  transform: translateY(50%);
  margin: ${SPACES.small};
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 2px solid ${BORDER_COLORS.separator};

  &--full-width {
    margin: ${SPACES.small} 0;
  }
`

const DividerBadge = styled(Badge)`
  position: relative;
  bottom: 1px;
  transform: translateY(-50%);
  padding: 5px 7px;
  border: 1px solid ${BORDER_COLORS.separator};
  border-radius: 1rem;
  background-color: ${BACKGROUND_COLORS.main};
  color: ${TEXT_COLORS.separator};
  font-size: 9px;
  font-weight: ${FONT_WEIGHTS.bold};
  text-shadow: 1px 1px 0px white;
  text-transform: uppercase;
`

const ContentDivider = ({ children, fullWidth = false }) => {
  return (
    <DividerContainer fullWidth={fullWidth}>
      {!!children?.length && <DividerBadge>{children}</DividerBadge>}
    </DividerContainer>
  )
}

ContentDivider.propTypes = {
  fullWidth: bool
}

export default ContentDivider
