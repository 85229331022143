import { useQuery } from "@apollo/client"
import * as Sentry from "@sentry/browser"

import query from "./featureFlagQuery.gql"

const useFeatureFlag = (key, { defaultValue = false, context = {} } = {}) => {
  const { data, error, loading } = useQuery(query, {
    variables: { key, defaultValue, context }
  })

  if (error) {
    Sentry.captureException(error)
    return { flagValue: defaultValue, loading: false }
  }

  return { flagValue: data?.featureFlag?.value || defaultValue, loading }
}

export default useFeatureFlag
