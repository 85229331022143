import React, { useEffect } from "react"
import { Modal } from "react-bootstrap"
import moment from "moment"

import { isIOS, isPWA, isMobileSafari } from "src/helpers/browser"
import { StyledModal } from "src/styles/components/Modal"
import ModalHeader from "src/styles/components/Modal/Header"
import ModalTitle from "src/styles/components/Modal/Title"
import useIsOpened from "src/hooks/useIsOpened"
import Icon from "src/styles/components/Icon"
import logo from "images/logo-PWA.png"

const InstallAppModal = () => {
  const localStorageLastSeenModalKey = "LastSeenInstallAppModal"
  const lastSeenModal = localStorage.getItem(localStorageLastSeenModalKey)
  const { isOpened, close } = useIsOpened(true)
  const repeatPeriodInDays = 40
  const shouldSeeModal =
    isIOS() &&
    isMobileSafari() &&
    !isPWA() &&
    (!lastSeenModal || moment().diff(moment(lastSeenModal), "days") > repeatPeriodInDays)

  useEffect(() => {
    if (shouldSeeModal)
      localStorage.setItem(localStorageLastSeenModalKey, moment().format())
  }, [shouldSeeModal])

  if (!shouldSeeModal) return null

  return (
    <StyledModal show={isOpened} onHide={close} backdrop="static" keyboard={false}>
      <ModalHeader>
        <ModalTitle>Install Knowa</ModalTitle>
      </ModalHeader>
      <Modal.Body className="text-center">
        <img src={logo} alt="Knowa logo" className="w-100" />
        <strong>Add Knowa to your Home Screen</strong> for the best experience, with
        offline meetings packs and easy access when you’re on the go.
        <br />
        <br />
        Just tap <Icon className="text-primary" type="share-and-arrow-up" /> then ‘Add to
        Home Screen’
      </Modal.Body>
    </StyledModal>
  )
}

export default InstallAppModal
