import IdbStore from "src/features/offline/helpers/IdbStore"
import { STORE_NAMES } from "src/features/offline/constants"

export default class OfflineAnnotationsStore {
  constructor() {
    this.dataStore = new IdbStore({ storeName: STORE_NAMES.OFFLINE_ANNOTATIONS })
  }

  clearOfflineAnnotations = () => {
    return this.dataStore.getAllKeys().then((keys) => {
      return Promise.all(
        keys.map((key) => {
          return this.dataStore.delete(key)
        })
      )
    })
  }

  getOfflineAnnotations = ({ userId }) => {
    return this.dataStore
      .getAllValues()
      .then((annotations) =>
        annotations.filter((annotation) => annotation.userId === userId)
      )
  }

  pushOfflineAnnotation = ({ documentId, userId, action, annotation }) => {
    return this.dataStore.set(annotation.id, {
      documentId,
      userId,
      action,
      annotation,
      timestamp: new Date().getTime()
    })
  }

  removeOfflineAnnotation = (annotationId) => {
    return this.dataStore.delete(annotationId)
  }
}
