import { useQuery } from "@apollo/client"

import folderPathQuery from "./folderPathQuery.gql"

const useFolderPath = ({ groupId, folderId }) => {
  const { loading, data, previousData } = useQuery(folderPathQuery, {
    variables: { groupId, folderId },
    fetchPolicy: "network-only"
  })

  const dataSource = loading ? previousData : data
  const path = dataSource?.groupFolderPath || []

  return { path, loading }
}

export default useFolderPath
