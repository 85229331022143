import React, { useState, useEffect, useRef } from "react"
import { useApolloClient } from "@apollo/client"
import { string, arrayOf, number, func } from "prop-types"
import { sample } from "lodash"

import useAblyChannel from "src/hooks/useAblyChannel"
import { CHANNELS } from "src/constants/ably"
import { getCurrentUserId } from "src/helpers/user"
import logo from "images/ai/answer.png"
import { generateUniqId } from "src/helpers/string"
import useStreaming from "src/hooks/ai/useStreaming"

import { Container, Title, Card, Logo } from "./components"
import generateAiAnswerMutation from "./generateAiAnswerMutation.gql"
import CacheStore from "./CacheStore"

const DEFAULT_ANSWER = "Sorry Knowa AI is busy. Please try again."
const SORTED_LINKS_JOINER = "|"

const AiAnswer = ({
  question,
  whereIds,
  whoIds,
  dateFrom,
  dateTo,
  setAiAnswerSortedLinks
}) => {
  const apolloClient = useApolloClient()

  const [loading, setLoading] = useState(true)
  const { content, setContent } = useStreaming({})
  const aiRequestId = useRef()
  const channelName = useRef()
  const cacheStore = new CacheStore()

  const parseAndSetAiAnswerSortedLinks = (links) => {
    if (links) setAiAnswerSortedLinks(links.split(SORTED_LINKS_JOINER))
  }

  useAblyChannel(channelName.current, {
    onMessage: ({ data }) => {
      if (
        data.userId === getCurrentUserId() &&
        data.aiRequestId === aiRequestId.current
      ) {
        setLoading(false)

        const answer = data.success ? data.answer : DEFAULT_ANSWER
        setContent(answer)

        if (data.finished && data.success && data.answer) {
          cacheStore.saveAnswer(
            question,
            { whereIds, whoIds, dateFrom, dateTo },
            answer,
            data.answerSortedLinks
          )
          parseAndSetAiAnswerSortedLinks(data.answerSortedLinks)
        }
      }
    }
  })

  const placeholder = "Generating response..."
  const displayedText = loading ? placeholder : content

  const handleError = () => {
    setLoading(false)
    setContent(DEFAULT_ANSWER)
  }

  useEffect(() => {
    cacheStore
      .getAnswer(question, { whereIds, whoIds, dateFrom, dateTo })
      .then(({ cachedAnswer, cachedAnswerSortedLinks }) => {
        if (cachedAnswer) {
          setLoading(false)
          setContent(cachedAnswer, { stream: false })
          parseAndSetAiAnswerSortedLinks(cachedAnswerSortedLinks)
        } else {
          setLoading(true)
          setContent("")
          aiRequestId.current = generateUniqId()
          channelName.current = sample(CHANNELS.search.aiAnswer)

          apolloClient
            .mutate({
              mutation: generateAiAnswerMutation,
              variables: {
                question,
                whereIds,
                whoIds,
                dateFrom,
                dateTo,
                aiRequestId: aiRequestId.current,
                channelName: channelName.current
              }
            })
            .then(({ data }) => {
              if (!data?.generateAiAnswer?.success) {
                handleError()
              }
            })
            .catch(() => handleError())
        }
      })
  }, [question, whereIds, whoIds, dateFrom, dateTo])

  return (
    <Container>
      <Title>Based on the results below, here is our answer to your question:</Title>
      <Card>
        <Logo src={logo} alt="Knowa logo" />
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: displayedText }} />
      </Card>
    </Container>
  )
}

AiAnswer.propTypes = {
  question: string.isRequired,
  setAiAnswerSortedLinks: func.isRequired,
  whereIds: arrayOf(string),
  whoIds: arrayOf(number),
  dateFrom: string,
  dateTo: string
}

export default AiAnswer
