const getObjectCount = (object) =>
  Object.values(object || {}).reduce((sum, count) => sum + (count || 0), 0)

export const getUnreadDiscussionsCount = (stats) =>
  getObjectCount(stats?.unread?.discussions)

export const getUnreadChatsCount = (stats) => getObjectCount(stats?.unread?.chats)

export const getUnreadCount = (stats) =>
  getUnreadDiscussionsCount(stats) + getUnreadChatsCount(stats)

export const getUnreadMentionsCount = (stats) =>
  getObjectCount(stats?.mentions?.discussions) +
  getObjectCount(stats?.mentions?.chats) +
  getObjectCount(stats?.unsigned?.discussions)

export const getPendingActionsCount = (stats) =>
  getObjectCount(stats?.actions?.discussions)

export const getMentionsCount = (stats) =>
  getUnreadMentionsCount(stats) + getPendingActionsCount(stats)
