import React, { useState, useEffect } from "react"
import { arrayOf, shape, number, string } from "prop-types"
import { styled } from "@linaria/react"
import classNames from "classnames"

import useIsOpened from "src/hooks/useIsOpened"
import { be } from "src/helpers/document"
import { formatDate } from "src/helpers/datetime"
import { FONT_SIZES, FONT_WEIGHTS } from "src/styles/sizes"
import { DATE_SHORT_FORMAT } from "src/constants/datetimeFormats"
import { TEXT_COLORS } from "src/styles/colors"

import CustomTermsModal from "./index"

const ReviewButton = ({ accountId, invitationToken, customTerms, className }) => {
  const { isOpened, open, close } = useIsOpened(false)
  const [customTermsToReview, setCustomTermsToReview] = useState()
  const [acceptedDates, setAcceptedDates] = useState(
    customTerms.reduce((map, obj) => ({ ...map, [obj.id]: obj.acceptedDate }), {})
  )

  const handleAccept =
    (customTerm) =>
    ({ acceptedDate: acceptedDateValue }) => {
      setAcceptedDates((prevAcceptedDates) => ({
        ...prevAcceptedDates,
        [customTerm.id]: acceptedDateValue
      }))
      close()
    }

  useEffect(() => {
    if (Object.values(acceptedDates).every((el) => !!el))
      be("create-account").removeAttr("disabled")
  }, [acceptedDates])

  const reviewCustomTerm = (customTerm) => {
    setCustomTermsToReview(customTerm)
    open()
  }

  return (
    <>
      {customTermsToReview && (
        <CustomTermsModal
          accountId={accountId}
          invitationToken={invitationToken}
          customTerms={[customTermsToReview]}
          onAccept={handleAccept(customTermsToReview)}
          isOpened={isOpened}
          close={close}
        />
      )}

      {customTerms.map((customTerm) => (
        <div key={customTerm.id} className={classNames("security-copy mt-1", className)}>
          {/* eslint-disable-next-line react/no-danger */}
          <div className="pr-2" dangerouslySetInnerHTML={{ __html: customTerm.title }} />
          <div className="d-flex align-items-center">
            {acceptedDates[customTerm.id] ? (
              <div className="accepted-date">
                Accepted {formatDate(acceptedDates[customTerm.id], DATE_SHORT_FORMAT)}
              </div>
            ) : (
              <button
                type="button"
                className="button is-secondary btn-sm align-middle"
                onClick={() => reviewCustomTerm(customTerm)}
              >
                Review and accept
              </button>
            )}
          </div>
        </div>
      ))}
    </>
  )
}

ReviewButton.propTypes = {
  accountId: number.isRequired,
  invitationToken: string,
  customTerms: arrayOf(
    shape({
      title: string,
      pspdfkitId: string.isRequired,
      jwt: string.isRequired,
      id: number.isRequired,
      acceptedDate: string
    })
  )
}

export default styled(ReviewButton)`
  .accepted-date {
    font-size: ${FONT_SIZES.xSmall};
    font-weight: ${FONT_WEIGHTS.semiBold};
    color: ${TEXT_COLORS.heading};
    white-space: nowrap;
  }
`
