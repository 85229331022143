import { useEffect, useRef } from "react"

import { setParamsToUrl } from "src/helpers/url"

export default function useUrlSync({
  query,
  order,
  category,
  dateFrom,
  dateTo,
  whereIds,
  userIds
}) {
  const didMountRef = useRef(false)
  const initialQueryRef = useRef(query)

  useEffect(() => {
    if (!query) {
      return
    }

    if (didMountRef.current || !initialQueryRef.current) {
      setParamsToUrl({
        search: {
          order,
          category,
          query,
          where_ids: whereIds,
          user_ids: userIds,
          date_from: dateFrom,
          date_to: dateTo
        }
      })
    } else {
      didMountRef.current = true
    }
  }, [
    query,
    order,
    dateFrom,
    dateTo,
    category,
    JSON.stringify(whereIds),
    JSON.stringify(userIds)
  ])
}
