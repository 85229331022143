import React from "react"
import { Modal } from "react-bootstrap"
import { arrayOf, bool, func, oneOf, shape } from "prop-types"

import UploadZone, { FILE_TYPES } from "./UploadZone"
import UploadedFilesList from "./UploadedFilesList"

const UploadModalContent = ({
  setSelectedFiles,
  selectedFiles,
  close,
  uploadFiles,
  isUploading,
  multiple,
  allowedFiles
}) => {
  return (
    <>
      <Modal.Body className="flex-grow-0">
        <UploadZone
          setSelectedFiles={setSelectedFiles}
          multiple={multiple}
          allowedFiles={allowedFiles}
        />
      </Modal.Body>
      <Modal.Body className="allow-scroll">
        <UploadedFilesList files={selectedFiles} />
      </Modal.Body>
      <Modal.Footer>
        <button className="button is-secondary" onClick={close}>
          Close
        </button>
        <button className="button" disabled={isUploading} onClick={uploadFiles}>
          Upload
        </button>
      </Modal.Footer>
    </>
  )
}

UploadModalContent.propTypes = {
  setSelectedFiles: func.isRequired,
  selectedFiles: arrayOf(shape({})).isRequired,
  close: func.isRequired,
  uploadFiles: func.isRequired,
  isUploading: bool.isRequired,
  multiple: bool.isRequired,
  allowedFiles: oneOf(Object.keys(FILE_TYPES)).isRequired
}

export default UploadModalContent
