import { styled } from "@linaria/react"

import BaseIcon from "src/styles/components/Icon"
import { BREAKPOINTS } from "src/styles/sizes"

const ROW_HEIGHT = "53px"
const BORDER_COLOR = "#e8e8e8"

const ICON_CELL_WIDTH = "73px"
const NAME_CELL_WIDTH = "250px"
const MODIFIED_CELL_WIDTH = "250px"
const MODIFIED_BY_CELL_WIDTH = "250px"
const SIZE_CELL_WIDTH = "100px"
const SELECTION_CELL_WIDTH = "35px"

export const Container = styled.div`
  position: relative;
  overflow-y: auto;
  flex-grow: 1;
`

export const IconCell = styled.td``

export const HeaderIconCell = styled.th``

export const HeaderSelectionCell = styled.th``

export const SelectionCell = styled.td``

export const Table = styled.table`
  background-color: white;
  width: 100%;
  min-width: 862px;
  border-collapse: separate;
  border-spacing: 0;

  & td {
    padding-right: 20px;
  }

  & ${IconCell}, & ${HeaderIconCell} {
    width: ${ICON_CELL_WIDTH};
    text-align: left;
    padding-left: 20px;
    padding-right: 10px;

    @media (max-width: ${BREAKPOINTS.small}) {
      padding-right: 5px;
      padding-left: 0;
      width: 53px;
    }
  }

  & ${SelectionCell}, & ${HeaderSelectionCell} {
    width: ${SELECTION_CELL_WIDTH};
    padding-right: 0;
    padding-left: 20px;
    text-align: center;

    @media (max-width: ${BREAKPOINTS.small}) {
      padding-left: 5px;
    }
  }

  & ${SelectionCell} input[type="checkbox"],
  & ${HeaderSelectionCell} input[type="checkbox"] {
    visibility: hidden;
  }

  & ${SelectionCell} input[type="checkbox"]:checked,
  & ${HeaderSelectionCell} input[type="checkbox"]:checked {
    visibility: visible;
  }

  & thead {
    position: sticky;
    inset-block-start: 0;
    background-color: white;
    z-index: 2;
  }

  & thead tr {
    height: ${ROW_HEIGHT};
  }

  & thead tr:hover ${HeaderSelectionCell} input[type="checkbox"] {
    visibility: visible;
  }

  & td,
  & th {
    border-bottom: 1px solid ${BORDER_COLOR};
  }
`

export const Row = styled.tr`
  height: ${ROW_HEIGHT};
  border-bottom: 1px solid ${BORDER_COLOR};

  position: relative;

  &:hover,
  &:focus {
    background-color: rgba(228, 244, 255, 0.4);
  }

  &:hover ${SelectionCell} input[type="checkbox"] {
    visibility: visible;
  }

  &.drag-over::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border: 2px dashed blue;
  }
`

export const Icon = styled(BaseIcon)`
  vertical-align: middle;
  font-size: 38px;
`

export const NameCell = styled.td`
  min-width: ${NAME_CELL_WIDTH};
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: ${BREAKPOINTS.medium}) {
    min-width: unset;
    max-width: ${NAME_CELL_WIDTH};
  }
`

export const HeaderNameCell = styled.th`
  min-width: ${NAME_CELL_WIDTH};
`

export const ModifiedCell = styled.td`
  white-space: nowrap;
  width: ${MODIFIED_CELL_WIDTH};
`

export const HeaderModifiedCell = styled.th`
  width: ${MODIFIED_CELL_WIDTH};
`

export const ModifiedByCell = styled.td`
  width: ${MODIFIED_BY_CELL_WIDTH};
`

export const HeaderModifiedByCell = styled.th`
  width: ${MODIFIED_BY_CELL_WIDTH};
`

export const SizeCell = styled.td`
  white-space: nowrap;
  width: ${SIZE_CELL_WIDTH};
`

export const HeaderSizeCell = styled.th`
  width: ${SIZE_CELL_WIDTH};
`

export const InfoBlock = styled.div`
  text-align: center;
  height: 159px;
  line-height: 159px;
  color: #72727e;
  background-color: white;
  position: sticky;
  left: 0;
`
