import React from "react"
import { string } from "prop-types"

import FileIcon from "src/styles/components/FileIcon"
import { formatTimestamp } from "src/helpers/datetime"

import { TitleContainer, TitleDatetime, TitleText } from "./styles"

const FileTitle = ({ title, datetime }) => {
  return (
    <TitleContainer className="mb-2">
      <div className="d-flex align-items-center text-truncate">
        <FileIcon extension="pdf" />
        <TitleText class="text-truncate">{title}</TitleText>
      </div>

      <TitleDatetime>{formatTimestamp(datetime)}</TitleDatetime>
    </TitleContainer>
  )
}

FileTitle.propTypes = {
  title: string,
  datetime: string
}

export default FileTitle
