import React from "react"
import { func } from "prop-types"

import { ManageUsersButton as StyledButton } from "./styles"

const ManageUsersButton = ({ onClick, ...props }) => {
  return (
    <StyledButton
      onClick={onClick}
      variant="outline-secondary"
      className="has-icon"
      {...props}
    >
      <span>View / Manage</span>
      <span className="icon-user" />
    </StyledButton>
  )
}

ManageUsersButton.propTypes = {
  onClick: func.isRequired
}

export default ManageUsersButton
