import { useMutation } from "@apollo/client"

import deleteItemsMutation from "./deleteItemsMutation.gql"

const useDeleteItems = () => {
  const [mutate, { loading }] = useMutation(deleteItemsMutation)

  const deleteItems = ({ groupId, parentFolderId, folderItems }) => {
    return mutate({ variables: { groupId, parentFolderId, folderItems } }).then(
      (response) => response.data?.deleteGroupFolderItems
    )
  }

  return { deleteItems, loading }
}

export default useDeleteItems
