import React, { createContext, useContext } from "react"

import { PermissionsType } from "./types"

const Context = createContext(null)

export const usePermissionsContext = () => useContext(Context)

const PermissionsContext = ({ permissions, children }) => {
  return <Context.Provider value={permissions}>{children}</Context.Provider>
}

PermissionsContext.propTypes = {
  permissions: PermissionsType.isRequired
}

export default PermissionsContext
