import React from "react"
import { number } from "prop-types"
import pluralize from "pluralize"

import Icon from "src/styles/components/Icon"

import AnnotationsCount from "./AnnotationsCount"

const CommentsCount = ({ count }) => {
  return (
    <AnnotationsCount>
      <Icon type="comment" /> {pluralize("comment", count, true)}
    </AnnotationsCount>
  )
}

CommentsCount.propTypes = {
  count: number.isRequired
}

export default CommentsCount
