import { showCaughtErrorMessage } from "src/helpers/errors"

import meetingPacks from "./api"
import {
  fetchMeetingPackFailure,
  fetchMeetingPackRequest,
  fetchMeetingPacksFailure,
  fetchMeetingPacksRequest,
  fetchMeetingPacksSuccess,
  fetchMeetingPackSuccess
} from "./slice"

export const fetchMeetingPacks = (params) => (dispatch) => {
  dispatch(fetchMeetingPacksRequest())

  meetingPacks
    .index(params)
    .then((response) => {
      dispatch(fetchMeetingPacksSuccess(response.data))
    })
    .catch((error) => {
      showCaughtErrorMessage(error)
      dispatch(fetchMeetingPacksFailure(error))
    })
}

export const fetchMeetingPack = ({ meetingPackId }) => (dispatch) => {
  dispatch(fetchMeetingPackRequest())

  meetingPacks
    .show({ meetingPackId })
    .then((response) => {
      dispatch(fetchMeetingPackSuccess(response.data))
    })
    .catch((error) => {
      showCaughtErrorMessage(error)
      dispatch(fetchMeetingPackFailure(error))
    })
}
