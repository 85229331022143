import axios from "axios"
import Qs from "qs"
import { camelizeKeys, decamelizeKeys } from "humps"

import { csrfToken } from "src/helpers/ajax"

const nestedParamsNormalizer = (config) => {
  config.paramsSerializer = {
    serialize: (params) =>
      Qs.stringify(decamelizeKeys(params), {
        arrayFormat: "brackets",
        encode: false
      })
  }

  return config
}

const setCSRFToken = (config) => {
  config.headers["X-CSRF-TOKEN"] = csrfToken()
  return config
}

const getClientEnvOptions = () => {
  if (typeof window !== "undefined") {
    return {
      baseURL: window.location.origin,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "X-Requested-With": "XMLHttpRequest"
      }
    }
  }

  return {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
      "X-Requested-With": "XMLHttpRequest"
    }
  }
}

const apiClient = axios.create({
  ...getClientEnvOptions(),
  transformRequest: [
    (data) => {
      if (data instanceof FormData) {
        return data
      }

      return decamelizeKeys(data)
    },
    ...axios.defaults.transformRequest
  ],
  transformResponse: [
    ...axios.defaults.transformResponse,
    (data) => {
      return camelizeKeys(data)
    }
  ]
})

apiClient.interceptors.request.use(nestedParamsNormalizer)
apiClient.interceptors.request.use(setCSRFToken)

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      window.location.reload()
    }

    return Promise.reject(error)
  }
)

export default apiClient
