import React from "react"
import { styled } from "@linaria/react"
import { string } from "prop-types"

import { BASE_COLORS } from "src/styles/colors"
import { SPACES } from "src/styles/sizes"

const Radio = ({ className, label, id, ...props }) => {
  return (
    <div className={className}>
      <input type="radio" id={id} {...props} />
      <label htmlFor={id}>{label}</label>
    </div>
  )
}

Radio.propTypes = {
  id: string.isRequired,
  label: string
}

export default styled(Radio)`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  cursor: pointer;
  font-size: 1em;
  margin-bottom: 0;
  padding-left: 0;

  input {
    display: none;
  }

  & > input:checked + label:after {
    opacity: 1;
  }

  label {
    cursor: inherit;
    display: flex;
    flex-flow: row nowrap;
    justify-items: center;
    margin-bottom: 0;
    user-select: none;
    width: 100%;

    &:before {
      background-color: white;
      border-radius: 100%;
      border: 2px solid ${BASE_COLORS.gray};
      content: "";
      display: block;
      height: 24px;
      flex-shrink: 0;
      margin-top: -2px;
      margin-right: ${SPACES.small};
      width: 24px;
    }

    &:after {
      content: "";
      border-radius: 100%;
      background-color: ${BASE_COLORS.gray};
      display: block;
      line-height: 24px;
      left: 5px;
      flex-shrink: 0;
      opacity: 0;
      position: absolute;
      top: 3px;
      height: 14px;
      width: 14px;
      transition: opacity 0.1s ease-in;
    }
  }
`
