import { connect } from "react-redux"

import { setActiveItem } from "src/features/meetingPacks/MeetingPack/meetingPackSlice"

import AgendaItemRow from "./AgendaItemRow"

const mapDispatch = (dispatch) => ({
  setActiveItem(itemId) {
    dispatch(setActiveItem(itemId))
  }
})

export default connect(null, mapDispatch)(AgendaItemRow)
