import React from "react"
import { Form } from "react-bootstrap"
import { shape, string, bool } from "prop-types"
import { styled } from "@linaria/react"

import { composeVariationsClasses } from "src/helpers/linaria"
import { BASE_COLORS } from "src/styles/colors"
import { FONT_WEIGHTS, FONT_SIZES, SPACES } from "src/styles/sizes"

const FieldLabel = ({ field, invalid, className }) => {
  const { name, description, indexToShow } = field
  const classNameWithVariations = composeVariationsClasses(className, { invalid })

  return (
    <Form.Label className={classNameWithVariations}>
      {indexToShow && `${indexToShow}. `}
      {name}
      {description && (
        // eslint-disable-next-line react/no-danger
        <div className="description" dangerouslySetInnerHTML={{ __html: description }} />
      )}
    </Form.Label>
  )
}

FieldLabel.propTypes = {
  field: shape({ name: string, description: string }),
  invalid: bool
}

export default styled(FieldLabel)`
  font-weight: ${FONT_WEIGHTS.semiBold};
  font-size: ${FONT_SIZES.medium};
  line-height: 1.4rem;
  margin-bottom: ${SPACES.small};
  color: #6c757d;

  .description {
    margin-top: ${SPACES.xSmall};
    color: ${BASE_COLORS.darkViolet};
    font-size: ${FONT_SIZES.small};
  }

  &--invalid {
    color: ${BASE_COLORS.red};
    width: 100%;
    border-right: 3px ${BASE_COLORS.red} solid;

    .description {
      color: ${BASE_COLORS.red};
    }
  }
`
