import { useDrag } from "react-dnd"

import { ITEM_TYPE } from "./constants"

const useCustomDrag = ({ item, index }) => {
  const [{ isDragging }, drag, preview] = useDrag({
    type: ITEM_TYPE,
    item: () => ({ ...item, index, initialIndex: index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  })

  return { preview, drag, style: { opacity: isDragging ? 0 : 1 } }
}

export default useCustomDrag
