import React from "react"
import { oneOf } from "prop-types"

import Icon from "src/styles/components/Icon"
import { RESULT_TYPES } from "src/features/Search/constants"

import { BadgeContainer } from "./styles"

const typeIcons = {
  [RESULT_TYPES.agendaItemDescription]: "list-numbered",
  [RESULT_TYPES.agendaItemDocument]: "list-numbered",
  [RESULT_TYPES.message]: "discussion-message",
  [RESULT_TYPES.attachment]: "paperclip",
  [RESULT_TYPES.document]: "document",
  [RESULT_TYPES.contentCloudFile]: "document",
  [RESULT_TYPES.iframeContent]: "arrow-right-squared",
  [RESULT_TYPES.transcript]: "list-numbered",
  [RESULT_TYPES.assignedTranscriptAgendaItem]: "list-numbered"
}

const Badge = ({ type }) => {
  const iconType = typeIcons[type]

  if (!iconType) {
    return null
  }

  return (
    <BadgeContainer>
      <Icon type={iconType} />
    </BadgeContainer>
  )
}

Badge.propTypes = {
  type: oneOf(Object.keys(typeIcons)).isRequired
}

export default Badge
