import documents from "./documents"
import meetingPacks from "./meetingPacks"
import unreadUserItems from "./unreadUserItems"
import annotations from "./annotations"
import agendaItems from "./agendaItems"

export default {
  agendaItems,
  documents,
  meetingPacks,
  unreadUserItems,
  annotations
}
