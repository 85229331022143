import React from "react"
import { shape, string, number, bool } from "prop-types"

import useIsOpened from "src/hooks/useIsOpened"
import DeletedFromAccountBadge from "src/components/DeletedFromAccountBadge"

import UserAvatar from "./UserAvatar"
import ContactDetailsModal from "./ContactDetailsModal"
import { UserCardContainer } from "./styles"

const UserCard = ({ user, isCurrentUser, deletedFromAccount }) => {
  const { isOpened, open, close } = useIsOpened()

  return (
    <>
      <UserCardContainer md={4} onClick={open}>
        <div className="card-mascot mrx">
          <UserAvatar user={user} />
        </div>
        <div className="card-title">
          <h6>
            {user.fullName}
            {isCurrentUser ? <span>&nbsp;(you)</span> : null}
          </h6>
          {deletedFromAccount ? (
            <DeletedFromAccountBadge />
          ) : (
            <div className="card-title-secondary">{user.jobTitle}</div>
          )}
        </div>
      </UserCardContainer>
      <ContactDetailsModal
        isOpened={isOpened}
        close={close}
        user={user}
        isCurrentUser={isCurrentUser}
      />
    </>
  )
}

UserCard.propTypes = {
  user: shape({
    id: number.isRequired,
    fullName: string.isRequired,
    jobTitle: string
  }).isRequired,
  isCurrentUser: bool.isRequired,
  deletedFromAccount: bool
}

export default UserCard
