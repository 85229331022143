import { styled } from "@linaria/react"
import { Button } from "react-bootstrap"

import { ItemsListCard, ItemsListRow } from "src/styles/components/ItemsList"
import { TEXT_COLORS } from "src/styles/colors"
import { SPACES, FONT_SIZES } from "src/styles/sizes"
import Icon from "src/styles/components/Icon"

export const DocumentsListCard = styled(ItemsListCard)`
  padding: ${SPACES.small};

  .file-name {
    display: flex;
    align-items: center;
    width: 85%;
  }
`

export const DocumentsListRow = styled(ItemsListRow)`
  margin-bottom: ${SPACES.small};

  &.is-deleting {
    opacity: 0.5;
    pointer-events: none;
  }
`

export const RearrangeIcon = styled(Icon)`
  margin: 0 ${SPACES.small};
  font-size: ${FONT_SIZES.medium};
  color: ${TEXT_COLORS.muted};
`

export const PreviewButton = styled(Button)`
  &.btn.btn-link {
    border: none;
    padding-left: 0;
    padding-right: 0;
    color: ${TEXT_COLORS.link};
  }

  &.btn.btn-link:hover {
    background-color: transparent;
  }

  &.btn.btn-link:focus {
    box-shadow: none;
  }
`
