import { styled } from "@linaria/react"
import { DropdownButton } from "react-bootstrap"

import { BREAKPOINTS, SPACES } from "src/styles/sizes"
import { BOX_SHADOWS } from "src/styles/shadows"
import { BACKGROUND_COLORS } from "src/styles/colors"

export const Dropdown = styled(DropdownButton)`
  .dropdown-menu {
    padding: 0;
    min-width: max(100px, 100% - 9px);
  }

  .dropdown-toggle {
    margin: 5px;
    margin-right: 0;
    min-height: 33px;
    max-width: 600px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    padding-right: 25px;

    &:after {
      position: absolute;
      right: 5px;
      top: 2px;
    }
  }

  &.btn-block {
    .dropdown-toggle {
      margin: 0px;
      justify-content: space-between;
    }

    .dropdown-toggle,
    .dropdown-menu {
      width: 100%;
    }
  }

  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    .dropdown-menu {
      width: 100%;
    }

    .dropdown-toggle {
      width: 100%;
      margin: ${SPACES.xxSmall} 0;
    }
  }
`

export const getSelectStyles = ({ searchable }) => ({
  input: (styles) => ({ ...styles, fontSize: "16px" }),
  control: (styles) => ({ ...styles, margin: 8, display: searchable ? "flex" : "none" }),
  menu: () => ({ boxShadow: BOX_SHADOWS.selectMenu }),
  option: (styles, state) => {
    let backgroundColor = null

    if (state.isSelected) backgroundColor = BACKGROUND_COLORS.selected
    if (state.isFocused) backgroundColor = BACKGROUND_COLORS.focused

    return {
      ...styles,
      color: "inherit",
      backgroundColor
    }
  }
})
