import React from "react"
import { shape, number, arrayOf, string, bool } from "prop-types"
import { Formik } from "formik"
import { Form } from "react-bootstrap"
import { useMutation } from "@apollo/client"
import * as Yup from "yup"

import { showFlashMessage } from "src/helpers/flash"
import { goTo } from "src/helpers/url"

import Header from "./Header"
import saveGroupMutation from "./saveGroup.gql"
import Settings from "./Settings"
import { DEFAULT_GROUP_SETTINGS, GROUP_SETTINGS } from "./constants"

const GroupSchema = Yup.object().shape({
  name: Yup.string().required("Name can't be blank"),
  settings: Yup.object().test({
    message: "At least one should be selected",
    test: (value) => Object.values(value).some((v) => v)
  }),
  enabledAppInstanceIds: Yup.array().when("settings", {
    is: (value) => value[GROUP_SETTINGS.applications],
    then: (schema) => schema.min(1),
    otherwise: (schema) => schema.min(0)
  })
})

const GroupForm = ({ group, scheme, appInstances, discussionsCount }) => {
  const [saveGroup, { loading }] = useMutation(saveGroupMutation)
  const spacePath = `/spaces/${scheme.id}/groups`

  const handleSaveGroup = (values) => {
    saveGroup({
      variables: {
        ...values,
        schemeId: scheme.id
      }
    })
      .then(({ data: result }) => {
        if (result?.saveGroup?.successful) {
          goTo(`${spacePath}/successful_${group.id ? "update" : "create"}?id=${group.id}`)
        } else if (result?.saveGroup?.errors) {
          showFlashMessage("danger", result.saveGroup.errors)
        }
      })
      .catch((error) => {
        if (error.graphQLErrors[0]?.extensions?.code === "unauthorized")
          showFlashMessage("danger", "You have no permission to this action.")
        else {
          throw error
        }
      })
  }

  const initialValues = {
    id: group.id,
    name: group.name || "",
    enabledAppInstanceIds: group.enabledAppInstanceIds || [],
    settings: group.settings || DEFAULT_GROUP_SETTINGS
  }

  return (
    <>
      <Header groupId={group.id} jointScheme={scheme.isJoint} />

      <div className="fluid-container">
        <div className="narrow-container container mvm">
          <Formik
            initialValues={initialValues}
            validationSchema={GroupSchema}
            onSubmit={handleSaveGroup}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              errors,
              touched,
              isValid
            }) => (
              <form onSubmit={handleSubmit}>
                <Form.Group controlId="name" className="mb-0">
                  <Form.Label>
                    <h6>Name</h6>
                  </Form.Label>
                  <Form.Control
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={errors.name && touched.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>

                <Settings
                  appInstances={appInstances}
                  discussionsCount={discussionsCount || 0}
                />

                <div className="form-main-controls actions mtm">
                  <input
                    type="submit"
                    name="commit"
                    value="Save Group"
                    className="button"
                    disabled={loading || !isValid}
                  />
                  <a className="button is-secondary" href={spacePath}>
                    Cancel
                  </a>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}

GroupForm.propTypes = {
  group: shape({ id: number, name: string }).isRequired,
  scheme: shape({ id: number.isRequired, isJoint: bool.isRequired }).isRequired,
  appInstances: arrayOf(shape({ id: number.isRequired, name: string.isRequired })),
  discussionsCount: number
}

export default GroupForm
