import React from "react"
import { bool, func, string } from "prop-types"
import { styled } from "@linaria/react"

import BaseCheckbox from "src/styles/components/Checkbox"

import IndeterminateCheckbox from "./IndeterminateCheckbox"

const CellContainer = styled.div`
  position: relative;
`

const InfoLabel = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  color: #a8abbb;
  font-size: 10px;
  width: 50px;
  text-align: center;
  line-height: 1;
  left: -33px;
`

const CheckboxContainer = ({
  checked,
  onChange,
  id,
  disabled = false,
  partiallyChecked = false,
  infoLabel
}) => {
  const Checkbox = partiallyChecked ? IndeterminateCheckbox : BaseCheckbox

  return (
    <CellContainer>
      <div className="d-flex justify-content-center">
        <Checkbox id={id} onChange={onChange} checked={checked} disabled={disabled} />
      </div>

      {infoLabel && <InfoLabel>{infoLabel}</InfoLabel>}
    </CellContainer>
  )
}

CheckboxContainer.propTypes = {
  id: string,
  checked: bool.isRequired,
  onChange: func.isRequired,
  disabled: bool,
  partiallyChecked: bool,
  infoLabel: string
}

export default CheckboxContainer
