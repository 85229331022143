import React from "react"
import { styled } from "@linaria/react"
import { string, number } from "prop-types"
import { Badge } from "react-bootstrap"

import { composeVariationsClasses } from "src/helpers/linaria"
import { FONT_WEIGHTS } from "src/styles/sizes"
import { BASE_COLORS, TAG_COLOR_STYLES } from "src/styles/colors"

const Tag = ({ className, color, ...props }) => {
  const colorId = (color || color === 0) && color.toString()
  const classes = composeVariationsClasses(className, { color: colorId })

  return <Badge className={classes} {...props} />
}

Tag.propTypes = {
  className: string,
  color: number
}

export default styled(Tag)`
  display: inline-flex;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  color: ${BASE_COLORS.white};
  font-weight: ${FONT_WEIGHTS.bold};
  text-transform: none;

  ${TAG_COLOR_STYLES}
`
