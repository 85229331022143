class @FilterInputComponent
  constructor: (params)  ->
    @input = $be(params.input)
    @items = $be(params.items)
    @emptyMessage = $be(params.emptyMessage)

  setup: =>
    component = this
    @input.on('keyup', ->
      value = $(this).val().toLowerCase()
      component.items.filter(->
        $(this).toggle(
          $(this).data('filter-text').toLowerCase().indexOf(value) > -1
        )
      )

      if !component.items.is(':visible')
        component.emptyMessage.show()
      else
        component.emptyMessage.hide()
    )
