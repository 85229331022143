import React from "react"
import { Card } from "react-bootstrap"

const DEFAULT_EMPTY_CONTENT_TEXT = "Nothing here yet."

const EmptyContent = ({ children }) => {
  return (
    <Card body>
      <div className="text-center text-muted w-100 pvl">
        {children || DEFAULT_EMPTY_CONTENT_TEXT}
      </div>
    </Card>
  )
}

export default EmptyContent
