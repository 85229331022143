import React from "react"
import { func } from "prop-types"

import { WHEN_FILTER_DATES, WHEN_FILTER_OPTIONS } from "src/features/Search/constants"
import SearchableSelect from "src/components/SearchableSelect"
import { momentType, optionType } from "src/constants/propTypes"

import { FilterContainer, FilterTitle } from "../styles"

import Control from "./Control"
import DatePickers from "./DatePickers"

const WhenFilter = ({
  whenFilter,
  setWhenFilter,
  dateTo,
  dateFrom,
  setDateFrom,
  setDateTo
}) => {
  return (
    <FilterContainer>
      <FilterTitle>When</FilterTitle>
      <SearchableSelect
        options={WHEN_FILTER_OPTIONS}
        selected={whenFilter}
        setSelected={setWhenFilter}
        components={{ Control }}
      />

      {whenFilter === WHEN_FILTER_DATES && (
        <DatePickers
          dateTo={dateTo}
          dateFrom={dateFrom}
          setDateFrom={setDateFrom}
          setDateTo={setDateTo}
        />
      )}
    </FilterContainer>
  )
}

WhenFilter.propTypes = {
  setWhenFilter: func.isRequired,
  whenFilter: optionType,
  setDateFrom: func.isRequired,
  setDateTo: func.isRequired,
  dateTo: momentType,
  dateFrom: momentType
}

export default WhenFilter
