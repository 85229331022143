import { TEXT_COLORS, BACKGROUND_COLORS, BORDER_COLORS } from "src/styles/colors"
import { BOX_SHADOWS } from "src/styles/shadows"

export const selectStyles = {
  option: (styles, state) => {
    const color = state.isDisabled ? TEXT_COLORS.muted : "inherit"
    const cursor = state.isDisabled ? "not-allowed" : "default"
    let activeBackgroundColor = BORDER_COLORS.inputHover
    let backgroundColor = null
    if (state.isSelected || state.isDisabled) {
      activeBackgroundColor = BACKGROUND_COLORS.selected
      backgroundColor = BACKGROUND_COLORS.selected
    }
    if (state.isFocused) backgroundColor = BACKGROUND_COLORS.focused

    return {
      ...styles,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      color,
      cursor,
      backgroundColor,
      "&:active": {
        backgroundColor: activeBackgroundColor
      }
    }
  },
  control: (styles, state) => ({
    ...styles,
    height: "auto",
    boxShadow: state.isFocused ? BOX_SHADOWS.outerInput : "none",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: state.isFocused ? BORDER_COLORS.inputHover : "lightgray",
    "&:hover": {
      borderColor: state.isFocused ? BORDER_COLORS.inputHover : "lightgray"
    }
  }),
  singleValue: (styles) => ({ ...styles, margin: "0" }),
  valueContainer: (styles) => ({ ...styles, padding: "0" }),
  indicatorsContainer: (styles) => ({ ...styles, cursor: "pointer" })
}
