import React from "react"

const BoxFailedState = () => (
  <div className="h-100" data-behavior="file-manager">
    <h6 className="text-center mtl">
      Can&apos;t load documents. There is a problem with Box service.
    </h6>
  </div>
)

export default BoxFailedState
