class @GroupsShowHandler
  constructor: ->
    @groupId = $be('group-resource').data('id')

    @unreadBadges = new UnreadBadgesComponent(
      'discussion-card',
      'discussionId',
      'discussions:updates'
    )

    @discussionList = new DiscussionListComponent(
      $be('discussion-list'),
      afterUpdate: => @unreadBadges.refresh()
    )

    @groupMembershipWatcherComponent = new GroupMembershipWatcherComponent()

    @groupMemberListComponent = new GroupMemberListComponent(
      listContainerBehavior: 'group-participants',
      manageEnabled: true,
      contactDetailsEnabled: true
    )

    @groupLeaveLinkComponent = new GroupLeaveLinkComponent(@groupId)
    @copyLinkComponent = new CopyLinkComponent()

  setup: =>
    @unreadBadges.setup()
    @discussionList.setup()
    @groupMembershipWatcherComponent.setup()
    @groupMemberListComponent.setup()
    @groupLeaveLinkComponent.setup()
    @copyLinkComponent.setup()

  teardown: =>
    @unreadBadges.teardown()
    @discussionList.teardown()
    @groupMembershipWatcherComponent.teardown()
    @groupMemberListComponent.teardown()
    @groupLeaveLinkComponent.teardown()

  updateDiscussionList: (listContent) =>
    @discussionList.update(listContent)




