import React, { useEffect, useState } from "react"
import { Modal, Nav } from "react-bootstrap"
import { func } from "prop-types"

import useIsOpened from "src/hooks/useIsOpened"

import { SIGNED_DOCUMENTS_MODAL_TOGGLE_EVENT } from "./constants"
import FileUpload from "./FileUpload"
import FilePicker from "./FilePicker"

const defaultTab = "uploadNewFile"

const SignedDocumentsUploadModal = ({
  uploadDocument,
  buildForm,
  setAttachedDocument
}) => {
  const [activeTab, setActiveTab] = useState(defaultTab)
  const { isOpened, open, close } = useIsOpened()

  useEffect(() => {
    window.addEventListener(SIGNED_DOCUMENTS_MODAL_TOGGLE_EVENT, open)

    return () => {
      window.removeEventListener(SIGNED_DOCUMENTS_MODAL_TOGGLE_EVENT, open)
    }
  }, [])

  return (
    <Modal dialogClassName="modal-lg" onHide={close} show={isOpened}>
      <Modal.Header closeButton className="align-items-center">
        <Modal.Title as="h6">Upload document for Signing</Modal.Title>
      </Modal.Header>
      <Nav variant="tabs" activeKey={activeTab} onSelect={setActiveTab}>
        <Nav.Item>
          <Nav.Link eventKey="uploadNewFile">Upload New File</Nav.Link>
        </Nav.Item>
      </Nav>
      {activeTab === "uploadNewFile" ? (
        <FileUpload
          close={close}
          uploadDocument={uploadDocument}
          setAttachedDocument={setAttachedDocument}
          buildForm={buildForm}
        />
      ) : (
        <FilePicker // TODO: fix selecting files from Box
          close={close}
          setAttachedDocument={setAttachedDocument}
          uploadDocument={uploadDocument}
        />
      )}
    </Modal>
  )
}

SignedDocumentsUploadModal.propTypes = {
  setAttachedDocument: func.isRequired,
  uploadDocument: func.isRequired,
  buildForm: func.isRequired
}

export default SignedDocumentsUploadModal
