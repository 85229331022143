class @WindowFocusComponent
  constructor: (options) ->
    @windowFocused = !document.hidden
    @options = options

  setup: =>
    window.addEventListener 'focus', @onFocus
    window.addEventListener 'blur', @onBlur

  teardown: =>
    window.removeEventListener 'focus', @onFocus
    window.removeEventListener 'blur', @onBlur

  onFocus: =>
    @windowFocused = true
    @options.onfocus.forEach (callback) ->
      callback()

  onBlur: =>
    @windowFocused = false


  windowIsFocused: =>
    return @windowFocused
