import { useCallback, useEffect, useState } from "react"
import { useApolloClient } from "@apollo/client"

import whereFilterQuery from "./whereFilter.gql"

export default function useWhereFilter(whereIds) {
  const whereFilterDefaultIds = whereIds ? Object.values(whereIds).filter(Boolean) : []
  const apolloClient = useApolloClient()

  const [whereFilterState, setWhereFilterState] = useState({
    options: [],
    whereFilter: whereFilterDefaultIds.length ? whereFilterDefaultIds : []
  })

  const addWhereFilterValue = useCallback(
    (newValues) => {
      setWhereFilterState((currentState) => {
        const updatedWhereFilter = newValues.map(({ value }) => value)

        return {
          ...currentState,
          whereFilter: updatedWhereFilter
        }
      })
    },
    [setWhereFilterState]
  )

  const removeWhereFilterValue = useCallback(
    (removedValue) => {
      setWhereFilterState((currentState) => {
        const updatedWhereFilter = currentState.whereFilter.filter(
          (value) => value !== removedValue.value
        )

        return {
          ...currentState,
          whereFilter: updatedWhereFilter
        }
      })
    },
    [setWhereFilterState]
  )

  useEffect(() => {
    apolloClient.query({ query: whereFilterQuery }).then(({ data }) => {
      if (!data?.whereFilter?.length) {
        return
      }

      const options = data.whereFilter.map(({ id, name }) => ({
        value: id,
        label: name
      }))

      setWhereFilterState((state) => ({
        ...state,
        options
      }))
    })
  }, [])

  return {
    addWhereFilterValue,
    removeWhereFilterValue,
    whereFilter: whereFilterState.whereFilter,
    whereFilterOptions: whereFilterState.options
  }
}
