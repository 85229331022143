import React from "react"
import { Dropdown } from "react-bootstrap"
import { string } from "prop-types"

import useDownloadFileLink from "src/hooks/useDownloadFileLink"

const MinutesAgendaExport = ({ href, fileName }) => {
  const { href: linkHref, handleClick } = useDownloadFileLink({ href, fileName })

  return (
    <Dropdown.Item onClick={handleClick} href={linkHref} data-turbo="false">
      Export Minutes / Agenda
    </Dropdown.Item>
  )
}

MinutesAgendaExport.propTypes = {
  href: string.isRequired,
  fileName: string.isRequired
}

export default MinutesAgendaExport
