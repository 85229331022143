import React, { useState } from "react"
import { string } from "prop-types"

import Switch from "src/components/Switch"
import Radio from "src/styles/components/Radio"
import { ALLOW_ATTENDEES_TO_EXPORT_MODES } from "src/resources/meetingPacks/constants"

const AllowAttendeesToExport = ({ initialValue }) => {
  const [value, setValue] = useState(initialValue)

  const handleSwitch = (v) =>
    v ? setValue(ALLOW_ATTENDEES_TO_EXPORT_MODES.members) : setValue(null)

  return (
    <>
      <div
        className="d-flex align-items-center justify-content-between"
        data-testid="allow-export-meeting-pdf"
      >
        <div>Allow Attendees to export a PDF version of the meeting pack</div>
        <Switch switchedOn={!!value} setSwitchedOn={handleSwitch} />
      </div>

      {!value && <input type="hidden" name="meeting_pack[attendees_allowed_to_export]" />}

      {value && (
        <div className="py-3">
          <Radio
            id="members_attendees_allowed_to_export"
            label="Members only"
            name="meeting_pack[attendees_allowed_to_export]"
            value={ALLOW_ATTENDEES_TO_EXPORT_MODES.members}
            checked={value === ALLOW_ATTENDEES_TO_EXPORT_MODES.members}
            onChange={() => setValue(ALLOW_ATTENDEES_TO_EXPORT_MODES.members)}
          />
          <div className="pt-3">
            <Radio
              id="all_attendees_allowed_to_export"
              label="Members + Guests (All Attendees)"
              name="meeting_pack[attendees_allowed_to_export]"
              value={ALLOW_ATTENDEES_TO_EXPORT_MODES.all}
              checked={value === ALLOW_ATTENDEES_TO_EXPORT_MODES.all}
              onChange={() => setValue(ALLOW_ATTENDEES_TO_EXPORT_MODES.all)}
            />
          </div>
        </div>
      )}
    </>
  )
}

AllowAttendeesToExport.propTypes = {
  initialValue: string
}

export default AllowAttendeesToExport
