class @AttachDocumentStubComponent
  constructor: (params) ->
    @showMobileAttachDocument = $be('show-mobile-attach-document')

  setup: =>
    @showMobileAttachDocument.attr({
      disabled: 'disabled',
      title: 'Attachments are not available. There is a problem with Box service.'
    })

  teardown: ->

  setupBoxListeners: ->

  show: ->

  showMessage: (errorText) ->

  hideMessage: ->

  showPicker: ->

  hidePicker: ->

  showUploader: ->

  hideUploader: ->

  activateTab: (element) ->

  clearAttachedDocuments: ->

  appendAttachedDocument: (docsData, newDocData, docCard) ->

  removeAttachedDocument: (card) ->

  bindAttachedDocumentCardActions: (docCard) ->

  attachFiles: (fileItems) ->

  addAttachedDocument: (card) ->

  documentsAreAttached: ->

  loadAutosavedDocuments: ->
