import React from "react"
import { bool, func, number, shape, string } from "prop-types"

import UserAvatar from "src/components/UsersContacts/UserAvatar"
import { MembershipsColumn } from "src/styles/components/Card/List"
import Card, { Mascot } from "src/styles/components/Card/Card"
import { Primary, Secondary, Title } from "src/styles/components/Card/Title"
import Checkbox from "src/styles/components/Checkbox"
import DeletedFromAccountBadge from "src/components/DeletedFromAccountBadge"
import UserAvatarContainer from "src/components/UsersContacts/UserAvatarContainer"
import UserBadge from "src/components/UsersContacts/UserBadge"

import Auxiliary from "./Auxiliary"

const UserCard = ({
  user,
  isCurrentUser,
  toggleUserMembership,
  checkedMembership,
  deletedFromAccount,
  added = false,
  role = null
}) => {
  return (
    <Card horizontal className="px-4 my-3" data-testid="user-card" data-id={user.id}>
      <Mascot className="mr-2">
        <UserAvatarContainer>
          {added && (
            <UserBadge data-testid="badge" variant="added">
              +
            </UserBadge>
          )}
          <UserAvatar user={user} variant={added ? "added" : null} />
        </UserAvatarContainer>
      </Mascot>
      <Title className="card-title">
        <Primary>
          {user.fullName} {isCurrentUser ? "(you)" : ""}
        </Primary>
        {deletedFromAccount ? (
          <div>
            <DeletedFromAccountBadge />
          </div>
        ) : (
          <Secondary>{user.jobTitle}</Secondary>
        )}
      </Title>
      {!!user.onlineStatus && <Auxiliary role={role} status={user.onlineStatus} />}
      <MembershipsColumn>
        <Checkbox
          id={`user-check-box-${user.id}-present`}
          checked={checkedMembership}
          onChange={() =>
            toggleUserMembership({
              userId: user.id
            })
          }
        />
      </MembershipsColumn>
    </Card>
  )
}

UserCard.propTypes = {
  toggleUserMembership: func.isRequired,
  user: shape({
    id: number.isRequired,
    schemeRoles: shape({})
  }).isRequired,
  checkedMembership: bool,
  isCurrentUser: bool,
  deletedFromAccount: bool,
  onlineStatus: string,
  role: string,
  added: bool
}

export default UserCard
