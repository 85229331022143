import React, { useContext } from "react"
import classNames from "classnames"
import { bool, number, shape } from "prop-types"

import HeaderContext from "src/features/Header/HeaderContext"

const MainMenu = ({
  privateSchemeHighlighted,
  jointSchemeHighlighted,
  contactsHighlighted,
  privateScheme,
  jointScheme
}) => {
  const stats = useContext(HeaderContext)

  if (!privateScheme) {
    return null
  }

  const privateSchemeUnread = stats.unread?.privateSchemes?.[privateScheme?.id]
  const jointSchemeUnread = stats.unread?.jointSchemes?.[jointScheme?.id]
  const privateSchemeMention = stats.mentions?.privateSchemes?.[privateScheme?.id]
  const jointSchemeMention = stats.mentions?.jointSchemes?.[jointScheme?.id]
  const privateSchemeAction = stats.actions?.privateSchemes?.[privateScheme?.id]
  const jointSchemeAction = stats.actions?.jointSchemes?.[jointScheme?.id]
  const privateSchemeUnsigned = stats.unsigned?.privateSchemes?.[privateScheme?.id]
  const jointSchemeUnsigned = stats.unsigned?.jointSchemes?.[jointScheme?.id]

  return (
    <ul className="main-menu">
      <li className="navigation-menu-item">
        <a
          href={`/spaces/${privateScheme.id}/groups`}
          className={classNames("navigation-menu-link", {
            highlighted: privateSchemeHighlighted,
            unread: privateSchemeUnread,
            mention: privateSchemeMention || privateSchemeAction || privateSchemeUnsigned
          })}
          data-testid="private-navbar-link"
          data-id={privateScheme.id}
        >
          <span className="icon-lock" />
          <span className="menu-link-caption">Private</span>
        </a>
      </li>

      {jointScheme && (
        <li className="navigation-menu-item">
          <a
            href={`/spaces/${jointScheme.id}/groups`}
            className={classNames("navigation-menu-link", {
              highlighted: jointSchemeHighlighted,
              unread: jointSchemeUnread,
              mention: jointSchemeMention || jointSchemeAction || jointSchemeUnsigned
            })}
          >
            <span className="icon-connected" />
            <span className="menu-link-caption">Connected</span>
          </a>
        </li>
      )}
      <li className="navigation-menu-item">
        <a
          href={`/spaces/${privateScheme.id}/contacts`}
          className={classNames("navigation-menu-link", {
            highlighted: contactsHighlighted
          })}
        >
          <span className="icon-user" />
          <span className="menu-link-caption">People</span>
        </a>
      </li>
    </ul>
  )
}

MainMenu.propTypes = {
  privateSchemeHighlighted: bool.isRequired,
  jointSchemeHighlighted: bool.isRequired,
  contactsHighlighted: bool.isRequired,
  jointScheme: shape({
    id: number.isRequired
  }),
  privateScheme: shape({
    id: number.isRequired
  })
}

export default MainMenu
