import { createSelector } from "@reduxjs/toolkit"
import { partition, orderBy } from "lodash"

import { normalizeMessage } from "src/resources/messages/helpers"

export const getMentions = (state) => state.mentions
export const getMentionsAllIds = (state) => state.mentions.allIds
export const getMentionsById = (state) => state.mentions.byId

export const selectAllMentions = createSelector(
  [getMentionsAllIds, getMentionsById],
  (allIds, byId) =>
    orderBy(
      allIds.map((id) => normalizeMessage(byId[id])),
      ["date"],
      ["desc"]
    )
)

export const selectUnreadMentions = createSelector([selectAllMentions], (mentions) =>
  partition(mentions, (mention) => mention.unread)
)
