import { oneOfType, shape, number, string, bool } from "prop-types"

export const tagType = shape({
  id: number.isRequired,
  name: string.isRequired,
  colorId: number.isRequired,
  accountId: number.isRequired
})

export const tagOptionType = shape({
  value: oneOfType([number, string]).isRequired,
  label: string.isRequired,
  colorId: number,
  account: shape({
    id: number
  }),
  isDefault: bool
})
