import useAblyChannel from "src/hooks/useAblyChannel"
import { getCurrentUserId } from "src/helpers/user"
import { onSameChatPage } from "src/helpers/routes"
import { CHANNELS, MESSAGE_NAMES } from "src/constants/ably"

import {
  updateChatsStats,
  updateDiscussionsStats,
  userAblyDestroyedMessageInfo,
  userAblyMessageInfo,
  shouldSkipStatsUpdate
} from "../helpers"

const useStatsUpdates = (setStats, fetchStats) => {
  const currentUserId = getCurrentUserId()

  useAblyChannel(CHANNELS.discussions.updates, {
    filterByUsersId: false,
    onMessage: ({ name, data }) => {
      if ([MESSAGE_NAMES.messageCreate, MESSAGE_NAMES.messageUpdate].includes(name)) {
        const messageInfo = userAblyMessageInfo({ name, data, userId: currentUserId })

        if (!shouldSkipStatsUpdate(data, messageInfo.status, currentUserId)) {
          setStats((stats) => updateDiscussionsStats({ stats, messageInfo }))
        }
      } else if (name === MESSAGE_NAMES.messageDestroy) {
        setStats((stats) => {
          const statsWithoutUnread = updateDiscussionsStats({
            stats,
            messageInfo: userAblyDestroyedMessageInfo({ data, status: "unread" })
          })
          const statsWithoutMentions = updateDiscussionsStats({
            stats: statsWithoutUnread,
            messageInfo: userAblyDestroyedMessageInfo({ data, status: "mentions" })
          })
          return updateDiscussionsStats({
            stats: statsWithoutMentions,
            messageInfo: userAblyDestroyedMessageInfo({ data, status: "actions" })
          })
        })
      }
    }
  })

  useAblyChannel(CHANNELS.chats.updates, {
    filterByUsersId: false,
    onMessage: (message) => {
      const {
        data: { authorId, thread, userIds },
        name
      } = message

      const isMention = userIds.includes(currentUserId)

      if (
        name === MESSAGE_NAMES.messageCreate &&
        thread.memberIds.includes(currentUserId) &&
        authorId !== currentUserId &&
        !onSameChatPage(thread.id)
      ) {
        setStats((stats) => updateChatsStats({ threadId: thread.id, isMention, stats }))
      }
    }
  })

  useAblyChannel(CHANNELS.signedDocuments, {
    onMessage: fetchStats
  })
}

export default useStatsUpdates
