import { keyBy, union } from "lodash"

export const setIsLoading = (state) => {
  state.isLoading = true
}

export const setSuccess = (state) => {
  state.isLoading = false
  state.error = null
}

export const setFailure = (state, action) => {
  state.isLoading = false
  state.error = action.payload
}

export const setCollection = (state, action) => {
  setSuccess(state)

  const collection = action.payload
  const collectionAllIds = collection.map(({ id }) => id)
  const collectionById = keyBy(collection, "id")

  state.allIds = collectionAllIds
  state.byId = collectionById
}

export const appendCollection = (state, action) => {
  setSuccess(state)

  const collection = action.payload
  const collectionAllIds = collection.map(({ id }) => id)
  const collectionById = keyBy(collection, "id")

  state.allIds = union(state.allIds, collectionAllIds)
  state.byId = { ...state.byId, ...collectionById }
}
