import { styled } from "@linaria/react"

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
`

export const Footer = styled.div`
  flex-shrink: 0;
  flex-grow: 0;
  padding: 10px 44px 10px 20px;
  border-top: 1px solid #e8e8e8;
  display: flex;
  justify-content: end;
`
