import { createSlice } from "@reduxjs/toolkit"

import { setIsLoading, setFailure, appendCollection } from "src/helpers/slice"

const initialState = {
  allIds: [],
  byId: {},
  isLoading: false,
  error: null
}

const slice = createSlice({
  name: "discussions",
  initialState,
  reducers: {
    fetchDiscussionsRequest: setIsLoading,
    fetchDiscussionsFailure: setFailure,
    fetchDiscussionsSuccess: appendCollection
  }
})

const { actions, reducer } = slice

export const {
  fetchDiscussionsFailure,
  fetchDiscussionsRequest,
  fetchDiscussionsSuccess
} = actions

export default reducer
