class @Sensitivity
  @BAR_ORDER_COLORS: {
    1: '#FFC000',
    2: '#92D050',
    3: '#6490CB',
    4: '#726D93',
    5: '#D99694'
  }

  @NEGATIVE_SUM_COLOR = '#C00000'
  @POSITIVE_SUM_COLOR = '#00B050'

  constructor: (params) ->
    @data      = params.data
    @elementId = params.elementId

  setup: () ->
    xValues = @data.map((row) -> row.sensitivity)

    yText    = @data.map (row) ->
      "#{row.name} (#{$percent(-1 * Math.abs(Math.round(row.standard_deviation * 100)))})"
    yValues  = Object.keys(yText).map((key) => parseInt(key))

    barText       = xValues.map((sum) -> $bold($formattedSumMln(sum)))
    barColors     = @data.map((row) -> Sensitivity.BAR_ORDER_COLORS[row.order])
    barTextColors = xValues.map (sum) ->
      Sensitivity[if sum > 0 then 'POSITIVE_SUM_COLOR' else 'NEGATIVE_SUM_COLOR']
    data = [{
      x: xValues,
      y: yValues,
      orientation: 'h',
      type: 'bar',
      hoverinfo: 'none'
      text: barText
      textposition: 'outside',
      textfont: {
        color: barTextColors
      }
      marker: {
        color: barColors
      }
    }]
    # offset from y axis to make space for outside labels
    offsetForLabels = 25000000
    layout = {
      barmode: 'stack',
      showlegend: false,
      yaxis: {
        title: '<b>Asset classes <i>(1 standard deviation)</i></b>',
        tickvals: yValues,
        ticktext: yText,
        autorange: 'reversed',
        fixedrange: true,
        automargin: true
      },
      xaxis: {
        title: '<b>Deficit movement</b>',
        side: 'top',
        range: [
          Math.min.apply(null, xValues) - offsetForLabels,
          Math.max.apply(null, xValues) + offsetForLabels
        ]
      }
      margin: {
        t: 40
      }
    }

    Plotly.newPlot(
      @elementId,
      data,
      layout,
      { displayModeBar: false, responsive: true }
    )

