import { DEFAULT_TAG_FILTER } from "./constants"

export const getTagOption = (tag) => {
  if (!tag) return

  return {
    ...tag,
    value: tag.id,
    label: tag.name
  }
}

export const getTagId = (option) => {
  if (option.value === DEFAULT_TAG_FILTER.value) return

  return option.value
}
