import React, { useState } from "react"
import { styled } from "@linaria/react"
import { arrayOf, bool, shape, string } from "prop-types"

import LinkButton from "src/styles/components/Button/Link"
import Icon from "src/styles/components/Icon"
import { BREAKPOINTS, SPACES } from "src/styles/sizes"
import useIsOpened from "src/hooks/useIsOpened"
import useAblyChannel from "src/hooks/useAblyChannel"
import { CHANNELS } from "src/constants/ably"

import AttachedSignedDocumentModal from "./AttachedSignedDocumentModal"
import SignatoriesPanel from "./SignatoriesPanel"

const AttachedSignedDocument = ({ className, isCurrentUserAuthor, ...props }) => {
  const { isOpened, open, close } = useIsOpened()
  const [document, setDocument] = useState(props.document)
  const [users, setUsers] = useState(props.users)

  useAblyChannel(CHANNELS.signedDocuments, {
    onMessage: ({ data }) => {
      if (data.id !== document.id) {
        return
      }

      setUsers((currentUsers) => {
        return currentUsers.map((user) => {
          const signedAt = data.usersSignedAt[user.id.toString()]

          return { ...user, signedAt }
        })
      })
      setDocument({ ...document, signedAt: data.signedAt })
    }
  })

  const updateDocument = (newDocumentAttributes) => {
    setDocument((currentDocument) => ({
      ...currentDocument,
      ...newDocumentAttributes
    }))
  }

  return (
    <div className={className}>
      <div className="attached-signed-document">
        <Icon type="sign" className="mr-1" />
        <LinkButton onClick={open}>{document.name}</LinkButton>
        <AttachedSignedDocumentModal
          isCurrentUserAuthor={isCurrentUserAuthor}
          isOpened={isOpened}
          close={close}
          document={document}
          updateDocument={updateDocument}
        />
      </div>
      <SignatoriesPanel
        users={users}
        signedAt={document.signedAt}
        onSignButtonClick={open}
      />
    </div>
  )
}

AttachedSignedDocument.propTypes = {
  isCurrentUserAuthor: bool.isRequired,
  document: shape({
    name: string.isRequired,
    signedAt: string
  }).isRequired,
  users: arrayOf(shape({}))
}

export default styled(AttachedSignedDocument)`
  .attached-signed-document {
    display: flex;
    align-items: center;
    padding: 0 2.75rem;
    margin-top: ${SPACES.small};
  }

  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    .attached-signed-document {
      padding: 0;
    }
  }
`
