import React, { useState } from "react"
import Select, { components } from "react-select"
import { arrayOf, bool, elementType, func, oneOfType, shape, string } from "prop-types"

import { optionType } from "src/constants/propTypes"

import { Dropdown, getSelectStyles } from "./styles"

const SearchableSelect = ({
  prefix = false,
  searchable = true,
  useSelectedLabel = true,
  options = [],
  selected = [],
  setSelected,
  label,
  className,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleDropdownToggle = (value) => setIsOpen(value)

  const handleSelectChange = (value) => {
    setIsOpen(false)
    setSelected(value)
  }

  const selectedLabel = selected?.joint ? selected?.publicNameForUser : selected?.label
  const prefixedLabel = prefix ? `${prefix}: ${selectedLabel}` : selectedLabel
  const dropdownTitle = useSelectedLabel && selected ? prefixedLabel : label

  return (
    <Dropdown
      size="sm"
      variant="light"
      alignRight
      focusFirstItemOnShow
      show={isOpen}
      onToggle={handleDropdownToggle}
      title={dropdownTitle}
      className={className}
    >
      <Select
        autoFocus
        clearable
        menuIsOpen
        backspaceRemovesValue={false}
        components={{
          DropdownIndicator: null,
          IndicatorSeparator: null,
          ...props.components
        }}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        onChange={handleSelectChange}
        options={options}
        placeholder="Search..."
        styles={getSelectStyles({ searchable })}
        tabSelectsValue={false}
        value={selected}
        {...props}
      />
    </Dropdown>
  )
}

SearchableSelect.propTypes = {
  setSelected: func.isRequired,
  prefix: string,
  useSelectedLabel: bool,
  label: string,
  className: string,
  searchable: bool,
  options: arrayOf(optionType),
  selected: oneOfType([optionType, arrayOf(optionType)]),
  components: shape(
    Object.fromEntries(Object.keys(components).map((key) => [key, elementType]))
  )
}

export default SearchableSelect
