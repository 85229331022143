import React, { useEffect, useRef } from "react"
import { func, string } from "prop-types"

import { KEY_CODES } from "src/constants"

import { Container, Textarea } from "./styles"

const PromptBox = ({ prompt, onChange, onSubmit }) => {
  const textareaRef = useRef()

  // Auto-expand textarea to fit content
  useEffect(() => {
    // Reset height when content is deleted
    textareaRef.current.style.height = 0
    // 3px to take borders into account
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight + 3}px`
  }, [prompt])

  const handlePromptChange = (e) => {
    onChange(e.currentTarget.value)
  }

  const handleKeyDown = (e) => {
    if (e.keyCode !== KEY_CODES.enter || e.shiftKey === true) return

    e.preventDefault()
    onSubmit(prompt)
  }

  return (
    <Container>
      <Textarea
        ref={textareaRef}
        value={prompt}
        onChange={handlePromptChange}
        onKeyDown={handleKeyDown}
        placeholder="Enter query or instruction"
      />
    </Container>
  )
}

PromptBox.propTypes = {
  prompt: string,
  onSubmit: func.isRequired,
  onChange: func.isRequired
}

export default PromptBox
