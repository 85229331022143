import React from "react"
import { Row } from "react-bootstrap"
import { arrayOf, shape, number } from "prop-types"

import UserCard from "./UserCard"

const UsersContacts = ({ attendees, currentUser }) => {
  return (
    <Row>
      {attendees.map((user) => (
        <UserCard
          key={user.id}
          user={user}
          isCurrentUser={user.id === currentUser.id}
          deletedFromAccount={user.deletedFromAccount}
        />
      ))}
    </Row>
  )
}

UsersContacts.propTypes = {
  attendees: arrayOf(
    shape({
      id: number.isRequired
    })
  ).isRequired,
  currentUser: shape({
    id: number.isRequired
  }).isRequired
}

export default UsersContacts
