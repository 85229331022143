import meetingPacks from "src/api/meetingPacks"
import { showCaughtErrorMessage } from "src/helpers/errors"

import { setMeetingPack, setUnreadItems } from "../meetingPackSlice"

export const unpublish =
  ({ meetingPackId, confirmationMessage }) =>
  (dispatch) => {
    if (window.confirm(confirmationMessage)) {
      return meetingPacks.meetingPacks
        .unpublish({ meetingPackId })
        .then((response) => dispatch(setMeetingPack(response.data)))
        .catch((error) => showCaughtErrorMessage(error))
    }
  }

export const publish =
  ({ meetingPackId, customMessage }) =>
  (dispatch) => {
    return meetingPacks.meetingPacks
      .publish({ meetingPackId, customMessage })
      .then((response) => {
        dispatch(setMeetingPack(response.data))
      })
      .catch((error) => {
        showCaughtErrorMessage(error)
        throw error
      })
  }

export const refreshUnreadItems = (meetingPackId) => (dispatch) => {
  meetingPacks.meetingPacks
    .fetchUnreadItems({ meetingPackId })
    .then((response) => dispatch(setUnreadItems(response.data)))
    .catch((error) => {
      showCaughtErrorMessage(error)
      throw error
    })
}
