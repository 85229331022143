export const addBadgeToTheScheme = (scheme, stats) => {
  const { unread, mentions, actions, unsigned } = stats

  let badge = ""

  if (unread?.mainScheme?.[scheme.id] || unread?.privateSchemes?.[scheme.id]) {
    badge = "unread"
  }

  if (
    mentions?.mainScheme?.[scheme.id] ||
    mentions?.privateSchemes?.[scheme.id] ||
    actions?.mainScheme?.[scheme.id] ||
    actions?.privateSchemes?.[scheme.id] ||
    unsigned?.mainScheme?.[scheme.id] ||
    unsigned?.privateSchemes?.[scheme.id]
  ) {
    badge = "mention"
  }

  return { ...scheme, badge }
}
