import { styled } from "@linaria/react"
import { Badge as BootstrapBadge } from "react-bootstrap"

import { SPACES, FONT_WEIGHTS, BREAKPOINTS } from "src/styles/sizes"
import { BASE_COLORS } from "src/styles/colors"

export const Badge = styled(BootstrapBadge)`
  border-radius: 4px;
  margin: -4px -25px 0px ${SPACES.xSmall};
  padding: 5px 8px 6px;
  color: ${BASE_COLORS.white};
  font-weight: ${FONT_WEIGHTS.bold};
  cursor: pointer;
  display: flex;
  float: right;

  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    margin-right: -70px;
  }
`
