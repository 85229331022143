import React, { useState } from "react"
import { ReactSortable } from "react-sortablejs"
import { arrayOf, bool, func, number, shape, string } from "prop-types"

import useIsOpened from "src/hooks/useIsOpened"
import { ItemsList } from "src/styles/components/ItemsList"
import { splitFilename } from "src/helpers/string"
import CopyToFolderModal from "src/features/meetingPacks/AgendaItem/Documents/DocumentsList/CopyToFolderModal"

import PreviewModal from "../Preview/PreviewModal"

import useDocumentsSorting from "./useDocumentsSorting"
import DocumentRow from "./DocumentRow"
import RenameModal from "./RenameModal"
import NoDocumentsRow from "./NoDocumentsRow"

const DocumentsList = ({ documents, deleteDocument, updatePosition }) => {
  const { setDocuments } = useDocumentsSorting({ documents, updatePosition })
  const {
    isOpened: isPreviewModalOpened,
    open: openPreviewModal,
    close: closePreviewModal
  } = useIsOpened()
  const {
    isOpened: isRenameModelOpened,
    open: openRenameModal,
    close: closeRenameModal
  } = useIsOpened()
  const {
    isOpened: isCopyToFolderModalOpened,
    open: openCopyToFolderModal,
    close: closeCopyToFolderModal
  } = useIsOpened()

  const [activeDocument, setActiveDocument] = useState()
  const { name, extension } = splitFilename(activeDocument?.filename)

  const onPreviewModalOpen = (document) => {
    setActiveDocument(document)
    openPreviewModal()
  }

  const onRenameModalOpen = (document) => {
    setActiveDocument(document)
    openRenameModal()
  }

  const onCopyToFolderModalOpen = (document) => {
    setActiveDocument(document)
    openCopyToFolderModal()
  }

  // setTimeout(() => onCopyToFolderModalOpen(documents[0]), 1000)

  return (
    <ItemsList className="mb-5">
      {documents.length ? (
        <>
          <ReactSortable
            animation={300}
            ghostClass="ghost"
            list={documents}
            setList={setDocuments}
          >
            {documents.map((document) => (
              <div key={document.id}>
                <DocumentRow
                  documentId={document.id}
                  filename={document.filename}
                  rawDownloadUrl={document.rawDownloadUrl}
                  pspdfkitId={document.pspdfkitId}
                  pagesCount={document.pagesCount}
                  isDeleting={document.isDeleting}
                  deleteDocument={deleteDocument}
                  openPreviewModal={() => onPreviewModalOpen(document)}
                  openRenameModal={() => onRenameModalOpen(document)}
                  openCopyToFolderModal={() => onCopyToFolderModalOpen(document)}
                />
              </div>
            ))}
          </ReactSortable>
          {activeDocument && (
            <>
              <PreviewModal
                isOpened={isPreviewModalOpened}
                close={closePreviewModal}
                name={name}
                documentId={activeDocument.id}
                pspdfkitId={activeDocument.pspdfkitId}
              />
              <RenameModal
                isOpened={isRenameModelOpened}
                close={closeRenameModal}
                name={name}
                extension={extension}
                documentId={activeDocument.id}
              />
              <CopyToFolderModal
                isOpened={isCopyToFolderModalOpened}
                close={closeCopyToFolderModal}
                documentId={activeDocument.id}
              />
            </>
          )}
        </>
      ) : (
        <NoDocumentsRow />
      )}
    </ItemsList>
  )
}

DocumentsList.propTypes = {
  deleteDocument: func.isRequired,
  documents: arrayOf(
    shape({
      id: number.isRequired,
      filename: string.isRequired,
      pspdfkitId: string.isRequired,
      pagesCount: number.isRequired,
      isDeleting: bool
    })
  ).isRequired,
  updatePosition: func.isRequired
}

export default DocumentsList
