import React from "react"
import { Dropdown } from "react-bootstrap"
import { number } from "prop-types"

import Icon from "src/styles/components/Icon"
import useIsOpened from "src/hooks/useIsOpened"

import ShareMessageModal from "./ShareMessageModal"

const ShareMessage = ({ accountId, schemeId, groupId, discussionId, messageId }) => {
  const { isOpened, open, close } = useIsOpened()
  return (
    <>
      <Dropdown.Item onClick={open}>
        <div className="dropdown-icon">
          <Icon type="share" />
        </div>
        Share message
      </Dropdown.Item>
      {isOpened && (
        <ShareMessageModal
          accountId={accountId}
          schemeId={schemeId}
          groupId={groupId}
          discussionId={discussionId}
          messageId={messageId}
          isOpened={isOpened}
          close={close}
        />
      )}
    </>
  )
}

ShareMessage.propTypes = {
  accountId: number.isRequired,
  schemeId: number.isRequired,
  groupId: number.isRequired,
  discussionId: number.isRequired,
  messageId: number.isRequired
}

export default ShareMessage
