import React, { useEffect, useMemo, useRef, useState } from "react"
import { number, string } from "prop-types"
import { camelCase, mapKeys, pick } from "lodash"
import { Card } from "react-bootstrap"
import classNames from "classnames"
import { styled } from "@linaria/react"

import { airtableTableType } from "src/resources/accounts/airtablePropTypes"
import { BREAKPOINTS, SPACES } from "src/styles/sizes"
import useAirtableApi from "src/features/AirtableApp/useAirtable"
import usePageMetadata from "src/features/AirtableApp/usePageMetadata"
import ContentLoader from "src/styles/components/ContentLoader"
import { be } from "src/helpers/document"
import { showCaughtErrorMessage } from "src/helpers/errors"
import { showFlashMessage } from "src/helpers/flash"
import CacheStore from "src/features/offline/helpers/CacheStore"
import DocumentPreview from "src/features/DocumentPreview"

import Sidebar from "./Sidebar"
import { FIELD_TYPES } from "./constants"
import SaveButton from "./SaveButton"
import ActiveSectionForm from "./ActiveSectionForm"
import useSavingFormPosition from "./useSavingFormPosition"
import RecordContext from "./RecordContext"

const activeSectionPosition = (paddingTop) =>
  be("sections-list").find(".active")[0].offsetTop - paddingTop

const AirtableAppForm = ({
  pageId,
  tableId,
  baseId,
  recordId,
  schemeId,
  pageMetadata,
  className
}) => {
  const { allFields, primaryField } = usePageMetadata(pageMetadata)
  const editableFieldTypes = Object.values(FIELD_TYPES)
  const editableFieldIds = useMemo(
    () =>
      pageMetadata.fields
        .filter((field) => editableFieldTypes.includes(field.type))
        .map((field) => field.id),
    [pageMetadata.fields]
  )

  const { table } = useAirtableApi({ pageId, schemeId, baseId, tableId })

  const [sections, setSections] = useState([])
  const [activeSection, setActiveSection] = useState()
  const [record, setRecord] = useState()
  const [savedRecord, setSavedRecord] = useState()
  const [loadingRecord, setLoadingRecord] = useState(true)
  const [saveInProgress, setSaveInProgress] = useState(false)
  const [changeSectionLoading, setChangeSectionLoading] = useState(false)
  const activeSectionCardRef = useRef()
  const sectionsListRef = useRef()
  const { updateSection, getDefaultActiveSection } = useSavingFormPosition({
    activeSection,
    recordId,
    activeSectionCardRef,
    setActiveSection,
    loadingRecord,
    changeSectionLoading
  })
  const cacheStore = new CacheStore()

  const editableSectionFieldIds = useMemo(() => {
    if (!activeSection || activeSection.readOnly) return []

    return activeSection.fields
      .filter((field) => !field.readOnly && editableFieldIds.includes(field.id))
      .map((field) => field.id)
  }, [activeSection, editableFieldIds])

  const setRecordFromResponse = (response, cachedRecord = null) => {
    setSavedRecord(response.fields)

    if (!cachedRecord) return setRecord(response.fields)

    const airtableRecord = response.fields
    const newRecord = Object.keys({ ...cachedRecord, ...airtableRecord }).reduce(
      (n, fieldId) => {
        // eslint-disable-next-line no-param-reassign
        n[fieldId] = cachedRecord[fieldId] || airtableRecord[fieldId]
        return n
      },
      {}
    )

    setRecord(newRecord)
  }

  const setSectionsFromResponse = (response) => {
    // eslint-disable-next-line no-underscore-dangle
    const newSections = response._rawJson.sections.map((section) => ({
      ...mapKeys(section, (v, k) => camelCase(k)),
      fields: section.fields.map((field) => mapKeys(field, (v, k) => camelCase(k)))
    }))
    setSections(newSections)

    return newSections
  }

  useEffect(() => {
    setLoadingRecord(true)

    table
      .find(recordId)
      .then(async (result) => {
        const cachedRecord = await cacheStore.getRecord({ recordId })
        setRecordFromResponse(result, cachedRecord)
        const newSections = setSectionsFromResponse(result)
        updateSection(getDefaultActiveSection(newSections))
      })
      .finally(() => setLoadingRecord(false))
  }, [])

  const goToNextSection = (newSections) => {
    const newSection =
      newSections[newSections.findIndex((s) => s.id === activeSection.id) + 1]

    if (newSection) {
      updateSection(newSection)
      activeSectionCardRef.current.scrollTo(0, 0)
      sectionsListRef.current.scrollTo(
        0,
        activeSectionPosition(sectionsListRef.current.offsetHeight)
      )
    } else {
      showFlashMessage("success", "Saved")
    }
  }

  const isLastSection =
    sections.findIndex((s) => s.id === activeSection?.id) === sections.length - 1

  const handleSave = ({ onSuccess }) => {
    setSaveInProgress(true)

    table
      .update(recordId, pick(record, editableFieldIds))
      .then((result) => {
        setRecordFromResponse(result)
        const newSections = setSectionsFromResponse(result)
        cacheStore.clearRecord({ recordId })
        onSuccess(newSections)
      })
      .finally(() => setSaveInProgress(false))
      .catch((error) => {
        if (error.statusCode === 401)
          showFlashMessage("danger", "You need to sign in before continuing.")
        else
          showCaughtErrorMessage(
            error,
            "Sorry, the form can not be saved. Please contact support@knowa.co"
          )
      })
  }

  const handleSetRecord = (setFunc) => {
    cacheStore.saveRecord({ recordId, record: setFunc(record) })
    setRecord(setFunc)
  }

  const recordContextValue = useMemo(() => ({ pageId, recordId }), [pageId, recordId])

  if (loadingRecord) return <ContentLoader />

  if (!record) return null

  return (
    <RecordContext.Provider value={recordContextValue}>
      <DocumentPreview />

      <div className="text-center my-4">
        <h5 className="text-truncate">{record[primaryField.id]}</h5>
      </div>

      <div className="fluid-container">
        <div
          className={classNames("h-100 mx-4", className, {
            "one-section": sections.length === 1
          })}
        >
          {sections.length > 1 && (
            <Sidebar
              sections={sections}
              activeSection={activeSection}
              setActiveSection={updateSection}
              setChangeSectionLoading={setChangeSectionLoading}
              record={record}
              savedRecord={savedRecord}
              handleSave={handleSave}
              editableFieldIds={editableFieldIds}
              ref={sectionsListRef}
            />
          )}

          {activeSection && (
            <div className="active-section-card" ref={activeSectionCardRef}>
              <div className="card mb-4">
                <Card.Title>{activeSection.name}</Card.Title>

                {changeSectionLoading ? (
                  <ContentLoader />
                ) : (
                  <>
                    <ActiveSectionForm
                      section={activeSection}
                      allFields={allFields}
                      editableFieldIds={editableSectionFieldIds}
                      record={record}
                      savedRecord={savedRecord}
                      setRecord={handleSetRecord}
                    />
                    <SaveButton
                      handleSave={handleSave}
                      isLastSection={isLastSection}
                      someFieldsEditable={!!editableSectionFieldIds.length}
                      saveInProgress={saveInProgress}
                      goToNextSection={goToNextSection}
                    />
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </RecordContext.Provider>
  )
}

AirtableAppForm.propTypes = {
  pageId: number.isRequired,
  baseId: string.isRequired,
  tableId: string.isRequired,
  recordId: string.isRequired,
  schemeId: number.isRequired,
  pageMetadata: airtableTableType.isRequired
}

export default styled(AirtableAppForm)`
  display: grid;
  grid-template-columns: 400px 1fr 400px;

  &.one-section {
    display: block;
  }

  .active-section-card {
    margin-left: 1.5rem;
  }

  .active-section-card {
    overflow-y: auto;

    .card {
      padding: ${SPACES.semiLarge};
      max-width: 900px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media (max-width: calc(${BREAKPOINTS.xxLarge} - 1px)) {
    grid-template-columns: 400px 1fr 20px;

    &:not(.one-section) .active-section-card .card {
      margin-left: 0px;
    }
  }

  @media (max-width: calc(${BREAKPOINTS.medium} - 1px)) {
    grid-template-columns: 250px 1fr 10px;
  }

  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    display: block;

    &:not(.one-section) .active-section-card {
      margin-left: 0px;
    }
  }
`
