import React, { useState } from "react"
import { Collapse } from "react-bootstrap"
import { arrayOf, number, shape, string } from "prop-types"

import { isCurrentThread } from "src/features/Sidebar/RecentThreads/helpers"
import { getCurrentThread } from "src/helpers/document"

import ShowMoreLink from "./ShowMoreLink"
import Thread from "./Thread"

const recentThreadsStorageKey = "recent-items-shown"

const HiddenThreads = ({ threads }) => {
  const currentThread = getCurrentThread()
  const [isHiddenThreadsOpened, setHiddenThreadsOpened] = useState(
    !!localStorage.getItem(recentThreadsStorageKey)
  )
  const toggleHiddenThreadsCollapsed = () => {
    const willHiddenThreadsBeOpened = !isHiddenThreadsOpened

    setHiddenThreadsOpened(willHiddenThreadsBeOpened)

    if (willHiddenThreadsBeOpened) {
      localStorage.setItem(recentThreadsStorageKey, "true")
    } else {
      localStorage.removeItem(recentThreadsStorageKey)
    }
  }

  if (!threads.length) {
    return null
  }

  return (
    <>
      <Collapse in={isHiddenThreadsOpened}>
        <ul className="sidebar-menu">
          {threads.map((thread) => (
            <Thread
              current={isCurrentThread(thread, currentThread)}
              key={thread.path}
              thread={thread}
            />
          ))}
        </ul>
      </Collapse>

      <ShowMoreLink
        onClick={toggleHiddenThreadsCollapsed}
        isListOpened={isHiddenThreadsOpened}
      />
    </>
  )
}

HiddenThreads.propTypes = {
  threads: arrayOf(
    shape({
      id: number.isRequired,
      path: string.isRequired,
      title: string.isRequired
    })
  )
}

export default HiddenThreads
