import React, { useCallback } from "react"
import { func, number, shape, string } from "prop-types"
import classNames from "classnames"

import LinkButton from "src/styles/components/Button/Link"
import Icon from "src/styles/components/Icon"

import { DocumentCard } from "./styles"

const AttachedDocumentCard = ({
  index,
  document,
  detachDocument,
  onClick,
  iconType = "collaborative-document",
  inputNamePrefix = "message[pspdfkit_documents]"
}) => {
  const handleClick = useCallback(() => (onClick ? onClick() : null), [onClick, document])
  const cardClass = classNames({ clickable: onClick })
  const handleCloseButtonClick = useCallback(
    (e) => {
      e.stopPropagation()
      detachDocument(document.id)
    },
    [detachDocument, document]
  )

  return (
    <DocumentCard data-testid="document-card" className={cardClass} onClick={handleClick}>
      <h6 className="text-truncate d-flex align-items-center">
        <Icon type={iconType} className="mr-2" />
        {document.name}
      </h6>

      <input
        type="hidden"
        name={`${inputNamePrefix}[${index}][id]`}
        value={document.id}
      />
      <input
        type="hidden"
        name={`${inputNamePrefix}[${index}][name]`}
        value={document.name}
      />
      <LinkButton
        type="button"
        className="close"
        data-testid="detach-document-button"
        onClick={handleCloseButtonClick}
      >
        &times;
      </LinkButton>
    </DocumentCard>
  )
}

AttachedDocumentCard.propTypes = {
  document: shape({
    id: string.isRequired,
    name: string.isRequired
  }),
  index: number.isRequired,
  detachDocument: func.isRequired,
  onClick: func,
  iconType: string,
  inputNamePrefix: string
}

export default AttachedDocumentCard
