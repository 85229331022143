import { useState } from "react"
import * as Yup from "yup"

import { sendNumber, sendCode } from "src/api/twoFactorAuth"
import { countDown, digitsOnly } from "src/features/TwoFactor/helpers"

const PhoneFormSchema = Yup.object().shape({
  phone: Yup.string()
    .test("Digits only", "Number is invalid", digitsOnly)
    .required("Number is invalid")
})

const CodeFormSchema = Yup.object().shape({
  code: Yup.string()
    .test("Digits only", "Code is invalid", digitsOnly)
    .required("Code is invalid")
})

const usePhoneSubmissionSteps = ({ onStepChange, onSuccess }) => {
  const [step, setStep] = useState(1)
  const [timeLeftUntilNextAttempt, setTimeLeftUntilNextAttempt] = useState(0)
  const [error, setError] = useState()

  const moveToFirstStep = () => {
    setError(null)

    if (onStepChange) {
      onStepChange(1)
    }

    setStep(1)
  }

  const moveToSecondStep = (values, { setSubmitting }) => {
    const phoneNumberWithCode = `+${values.dialCode}${values.phone}`

    sendNumber({ number: phoneNumberWithCode })
      .then(() => {
        countDown(
          60,
          (seconds) => setTimeLeftUntilNextAttempt(seconds),
          () => setTimeLeftUntilNextAttempt(0)
        )

        if (onStepChange) {
          onStepChange(2)
        }

        setStep(2)
      })
      .catch((e) => setError(e.response?.data?.error))
      .finally(() => setSubmitting(false))
  }

  const enableTwoFactor = (values, { setSubmitting }) =>
    sendCode({ code: values.code })
      .then(onSuccess)
      .catch((e) => {
        setError(e.response?.data?.error)
        setSubmitting(false)
      })

  const validationSchema = step === 1 ? PhoneFormSchema : CodeFormSchema
  const handleSubmit = step === 1 ? moveToSecondStep : enableTwoFactor

  return {
    error,
    timeLeftUntilNextAttempt,
    step,
    moveToFirstStep,
    handleSubmit,
    initialFormValues: { phone: "", code: "", countryCode: "", dialCode: "" },
    validationSchema
  }
}

export default usePhoneSubmissionSteps
