import { be } from "src/helpers/document"
import { disable } from "src/api/twoFactorAuth"

export class TwoFactorDisableComponent {
  constructor() {
    this.setup = this.setup.bind(this)

    this.disableButton = be("two-factor-disable")
    this.disableConfirmButton = be("two-factor-disable-confirm")
    this.disableCancelButton = be("two-factor-disable-cancel")
    this.disableModal = be("two-factor-disable-modal")
    this.disableStepOneContent = be("two-factor-disable-step-one-content")
    this.disableStepTwoContent = be("two-factor-disable-step-two-content")
  }

  setup() {
    this.disableButton.click(() => this.disableModal.modal("show"))

    this.disableCancelButton.click(() => this.disableModal.modal("hide"))

    this.disableConfirmButton.click(() => {
      disable().then((response) => {
        this.disableStepOneContent.addClass("hidden")
        this.disableStepTwoContent.removeClass("hidden")

        $(".two-factor-section .plate-section").replaceWith(response.data.disableSection)
      })
    })
  }
}

window.TwoFactorDisableComponent = TwoFactorDisableComponent
