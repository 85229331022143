import { useApolloClient } from "@apollo/client"

import useAblyChannel from "src/hooks/useAblyChannel"
import { getCurrentUserId } from "src/helpers/user"
import { CHANNELS } from "src/constants/ably"
import sidebarDataQuery from "src/features/Sidebar/sidebarData.gql"

import meetingPackUnreadFragment from "./meetingPackUnreadFragment.gql"

const useMeetingPacksUpdates = () => {
  const currentUserId = getCurrentUserId()
  const client = useApolloClient()
  const reloadSidebarDataQueryQuery = () => {
    client.query({ query: sidebarDataQuery, fetchPolicy: "network-only" })
  }

  useAblyChannel(CHANNELS.meetingPacks, {
    onMessage: ({ name, data }) => {
      if (name === "update") {
        // when agenda item was updated or new document was uploaded
        if (data?.totalCounts?.[currentUserId] !== undefined) {
          client.writeFragment({
            id: `MeetingPack:${data.meetingPackId}`,
            fragment: meetingPackUnreadFragment,
            data: { id: data.meetingPackId, unread: data.totalCounts[currentUserId] > 0 }
          })
        } else {
          // meeting created/published/unpublished user added
          reloadSidebarDataQueryQuery()
        }
      }

      if (name === "destroy") {
        reloadSidebarDataQueryQuery()
      }
    }
  })
}

export default useMeetingPacksUpdates
