import React, { useContext } from "react"
import { oneOf, shape, number, string, arrayOf } from "prop-types"

import ModalContext from "src/features/Messages/ActionDetails/ModalContext"
import { ACTION_TYPES } from "src/features/Messages/ActionDetails/constants"

import UserRow from "./UserRow"

const ActionGroup = ({ group, actionType }) => {
  const { openContactDetailsModal } = useContext(ModalContext)

  return (
    <>
      <h5 className="people-list-account-title">{group.accountName}</h5>
      <div className="card-list people-list-list">
        {group.actions.map((action) => (
          <UserRow
            key={action.userId}
            user={action.user}
            onClick={() => openContactDetailsModal(action.user)}
            action={{ ...action, type: actionType }}
          />
        ))}
      </div>
    </>
  )
}

ActionGroup.propTypes = {
  actionType: oneOf(Object.keys(ACTION_TYPES)).isRequired,
  group: shape({
    groupId: number.isRequired,
    accountName: string.isRequired,
    actions: arrayOf(
      shape({
        userId: number.isRequired,
        user: shape({
          id: number.isRequired,
          avatarColorId: number,
          avatarUrl: string,
          email: string.isRequired,
          firstName: string,
          fullName: string.isRequired,
          jobTitle: string,
          lastName: string,
          onlineStatus: string.isRequired
        })
      })
    )
  })
}

export default ActionGroup
