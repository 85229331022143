import React from "react"
import classNames from "classnames"
import { Row } from "react-bootstrap"

import UserAvatarWithDetails from "src/components/UsersContacts/UserAvatarWithDetails"
import UserNameWithDetails from "src/components/UsersContacts/UserNameWithDetails"
import useToggle from "src/hooks/useToggle"
import useIsMinimizable from "src/hooks/useIsMinimizable"
import { messageType } from "src/resources/messages/propTypes"
import { getCurrentUserId } from "src/helpers/user"
import PreviewLink from "src/components/DocumentPreviewLink"

import Breadcrumbs from "./Breadcrumbs"
import MenuPanel from "./MenuPanel"
import SchemeBadge from "./SchemeBadge"
import SharedMessageContent from "./SharedMessageContent"
import { MessageIcon, MessageToggle } from "./styles"
import { MESSAGE_ICONS } from "./constants"

const USER_DETAILS_TYPE = "Contact"

const Message = ({ message }) => {
  const {
    actionType,
    actionable,
    attachedDocuments,
    author,
    authorName,
    createdAt,
    edited,
    fromEmail,
    mustRead,
    scheme,
    share,
    sharedMessage,
    starred,
    systemNotification,
    userMentioned,
    voting,
    signing
  } = message

  const { wrapperRef, bodyRef, isMinimizable } = useIsMinimizable()
  const [isMinimized, toggleMinimized] = useToggle("messageMinimized", true)
  const isCurrentUser = author?.id === getCurrentUserId()
  const mentionIcon = userMentioned && MESSAGE_ICONS.mention
  const signIcon = signing && MESSAGE_ICONS.sign
  const messageIcon = MESSAGE_ICONS[actionType] || mentionIcon || signIcon
  const messageBlockClasses = classNames("message-block", {
    "message-block__system": systemNotification,
    "message-block__minimizable": isMinimizable,
    "message-block__minimized": isMinimizable && isMinimized,
    "message-block__actionable": actionable,
    "message-block__from-email": fromEmail,
    "message-block__must-read": mustRead,
    "message-block__starred": starred,
    "message-block__voting": voting,
    "message-block__sign": signing
  })

  return (
    <div
      className={messageBlockClasses}
      data-id={message.id}
      data-behavior="message-container"
    >
      {messageIcon && (
        <div className="message-icon">
          <MessageIcon className={messageIcon} />
        </div>
      )}
      <MenuPanel message={message} />
      <div className="message-meta">
        <div className="message-avatar">
          <UserAvatarWithDetails
            user={author}
            isCurrentUser={isCurrentUser}
            type={USER_DETAILS_TYPE}
          />
        </div>
        <div className="message-creds">
          {scheme && <SchemeBadge scheme={scheme} />}
          <span>
            <UserNameWithDetails
              name={authorName}
              user={author}
              isCurrentUser={isCurrentUser}
              type={USER_DETAILS_TYPE}
            />
            <span>, </span>
            <span className="text-muted text-nowrap">{createdAt}</span>
          </span>
          {fromEmail && (
            <span className="from-email" title="Message sent by email">
              <span className="icon-email" />
            </span>
          )}
        </div>
      </div>
      <div className="message-content">
        <div className="message-body-wrapper" ref={wrapperRef}>
          <div className="message-body fr-view" ref={bodyRef}>
            <div dangerouslySetInnerHTML={{ __html: message.body }} />
            {edited && <span className="edited-label">(edited)</span>}
          </div>
        </div>
      </div>
      <div className="message-footer">
        <MessageToggle
          className="message-size_toggle"
          data-id={message.id}
          data-behavior="message-size_toggle"
          data-show-less-text="Show less"
          data-show-more-text="Show whole message"
          onClick={toggleMinimized}
        />
        {share && <SharedMessageContent minimizable message={sharedMessage} />}
        <Row noGutters md={2} className={classNames("position-relative", { pls: share })}>
          {share && <div className="message-blockquote" />}
          {attachedDocuments.map((attachedDocument) => (
            <PreviewLink
              key={attachedDocument.id}
              document={attachedDocument}
              collection={attachedDocuments}
            />
          ))}
        </Row>
        <Breadcrumbs message={message} />
      </div>
    </div>
  )
}

Message.propTypes = {
  message: messageType.isRequired
}

export default Message
