import apiClient from "src/app/apiClient"

const index = (params) => apiClient.get(`/api/mentions`, { params })
const accounts = (params) => apiClient.get(`/api/mentions/accounts`, { params })
const schemes = (params) => apiClient.get(`/api/mentions/schemes`, { params })

const mentions = {
  index,
  accounts,
  schemes
}

export default mentions
