import React from "react"
import { Dropdown } from "react-bootstrap"
import { func, string } from "prop-types"
import { styled } from "@linaria/react"

import Icon from "src/styles/components/Icon"

import Toggle from "./Toggle"

const DocumentActions = ({
  className,
  rawDownloadUrl,
  deleteDocument,
  openRenameModal,
  openCopyToFolderModal
}) => {
  return (
    <Dropdown className={className}>
      <Dropdown.Toggle as={Toggle} />

      <Dropdown.Menu>
        <Dropdown.Item href={rawDownloadUrl}>
          <div className="dropdown-icon">
            <Icon type="arrow-down-hollow" className="icon-large" />
          </div>
          Download
        </Dropdown.Item>
        <Dropdown.Item onClick={openRenameModal}>
          <div className="dropdown-icon">
            <Icon type="pencil" className="icon-small" />
          </div>
          Rename
        </Dropdown.Item>
        <Dropdown.Item onClick={openCopyToFolderModal}>
          <div className="dropdown-icon">
            <Icon type="document-add" />
          </div>
          <span className="text-nowrap">Copy to folder</span>
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item className="text-red" onClick={deleteDocument}>
          <div className="dropdown-icon">
            <Icon type="cancel-circled" />
          </div>
          Delete
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

DocumentActions.propTypes = {
  deleteDocument: func.isRequired,
  openRenameModal: func.isRequired,
  openCopyToFolderModal: func.isRequired,
  rawDownloadUrl: string.isRequired
}

export default styled(DocumentActions)`
  & .dropdown-toggle:after {
    display: none;
  }

  & .dropdown-menu {
    min-width: 10rem;
  }

  & .dropdown-item:active {
    background: #fff;
  }

  & .icon-document-add {
    font-size: 1.5em;
  }
`
