class @SchemeMembershipWatcherComponent
  constructor: () ->
    @schemeId = $be('scheme-resource').data('id')
    @schemesMembershipChannel = AblyRealtimeClient.channels.get(
      "schemes:membership-#{@schemeId}"
    )

    @channelData = [
      {
        channel: @schemesMembershipChannel,
        subscriber: @handleSchemeMembershipChange
      }
    ]

  setup: =>
    $setupChannelSubscriptions(@channelData)

  handleSchemeMembershipChange: (channelMessage) =>
    if channelMessage.data.indexOf($currentUserId()) > -1
      flash = {
        level: "warning",
        text: "Membership in current space was changed."
      }
      sessionStorage.setItem("clientFlashMessage", JSON.stringify(flash))
      Turbo.visit(window.location)

  teardown: =>
    $teardownChannelSubscriptions(@channelData)
