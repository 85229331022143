import { useCallback, useState } from "react"

import { BOX_COMPONENTS } from "src/constants"

import { contentPickerOptions } from "./helpers"

const defaultContainer = `[data-behavior=${BOX_COMPONENTS.filePicker}]`

const useBoxFilePicker = ({
  folderId,
  openedFolderId,
  handleCancel,
  handleChoose,
  extensions,
  container = defaultContainer
}) => {
  const canManageDocuments = window.currentGroupData?.canManageDocuments
  const boxFilePicker = new window.Box.FilePicker()
  const boxAuthService = new window.BoxAuthService()

  const [selectedFiles, setSelectedFiles] = useState([])

  const open = useCallback(() => {
    boxFilePicker.addListener("cancel", handleCancel)
    boxFilePicker.addListener("choose", (items) => {
      setSelectedFiles(items)
      handleChoose(items)
    })
    boxFilePicker.show(folderId, boxAuthService.fetchToken, {
      ...contentPickerOptions(canManageDocuments),
      extensions,
      container
    })

    if (openedFolderId) boxFilePicker.getComponent().fetchFolder(openedFolderId)
  }, [folderId, openedFolderId, handleCancel, handleChoose, setSelectedFiles])

  const hide = useCallback(() => {
    boxFilePicker.hide()
    boxFilePicker.removeAllListeners()
    boxFilePicker.clearCache()
  }, [])

  return {
    open,
    hide,
    selectedFiles
  }
}

export default useBoxFilePicker
