import { styled } from "@linaria/react"
import { Container } from "react-bootstrap"

import { SPACES } from "src/styles/sizes"

export const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 2rem 1fr 2rem;
  width: 100%;
  min-width: 0;
`

export const EmptyContentContainer = styled(Container)`
  margin-top: calc(${SPACES.semiLarge} - ${SPACES.xSmall});
`
