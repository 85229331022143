import React from "react"

import Select from "src/styles/components/Select"

import SingleValue from "./SingleValue"
import SchemeOption from "./Option"

const SchemeSelect = (props) => {
  return <Select components={{ SingleValue, Option: SchemeOption }} {...props} />
}

export default SchemeSelect
