import pluralize from "pluralize"
import { isEmpty } from "lodash"

import { formatDate } from "src/helpers/datetime"
import { DATE_SHORT_FORMAT } from "src/constants/datetimeFormats"

import { ACTION_TYPES, VOTING_OPTIONS } from "./constants"

const TEXT_ACTION = {
  [ACTION_TYPES.voting]: "Vote",
  [ACTION_TYPES.must_read]: "Must read"
}

const DEFAULT_LABEL_ACTIONS = {
  [true]: "the vote is collected",
  [false]: "all votes are collected"
}

const ADDITIONAL_TEXTS_SUBJECT = {
  [ACTION_TYPES.voting]: "votes",
  [ACTION_TYPES.must_read]: "users"
}

const dateText = (actionType, date) => {
  const textAction = TEXT_ACTION[actionType]

  return `${textAction} will close at 11:59pm, ${formatDate(date, DATE_SHORT_FORMAT)}.`
}

const numberText = (actionType, number, actionsCount) => {
  const textAction = TEXT_ACTION[actionType]

  if (actionType === ACTION_TYPES.must_read) {
    if (number === actionsCount)
      return `${textAction} will close after everyone has marked as read.`

    const people = pluralize("person", number, true)
    const have = pluralize("has", number)

    return `${textAction} will close after ${people} ${have} marked as read.`
  }

  if (actionType === ACTION_TYPES.voting) {
    if (number === 1) return `${textAction} will close after 1 vote.`

    const votes = pluralize("vote", number, true)

    return `${textAction} will close after ${votes} in agreement.`
  }
}

const defaultText = (actionType, actionsCount) => {
  const textAction = TEXT_ACTION[actionType]
  const endText =
    actionType === ACTION_TYPES.voting
      ? DEFAULT_LABEL_ACTIONS[actionsCount === 1]
      : "all have marked as read"

  return `${textAction} will close when ${endText}.`
}

export const summaryText = ({ actionType, actionsCount }, { action, date, number }) => {
  switch (action) {
    case "date":
      return dateText(actionType, date)

    case "number":
      return numberText(actionType, number, actionsCount)

    default:
      return defaultText(actionType, actionsCount)
  }
}

export const additionalText = ({ actionType, actionsCount }, { action, number }) => {
  if (!actionType || action !== "number") return
  if (actionsCount === number) return

  const subject = ADDITIONAL_TEXTS_SUBJECT[actionType]

  return `Once reached, it will remain open to outstanding ${subject} for 24 hours.`
}

export const decisiveVotesText = ({
  decisiveVotes,
  actionsDisabled,
  maxActionsCount
}) => {
  if (isEmpty(decisiveVotes)) return "Closed with no agreement"

  const votesLength = decisiveVotes.length

  const votesText = decisiveVotes.map(({ vote }, i) => {
    let separator
    if (i === votesLength - 1) separator = ""
    else separator = i === votesLength - 2 ? " and " : ", "

    return `<b>${maxActionsCount} ${VOTING_OPTIONS[vote]}</b>${separator}`
  })

  const pluralizedVotes = votesLength === 1 ? pluralize("vote", maxActionsCount) : "votes"

  if (actionsDisabled) return `Resolved after ${votesText.join("")} ${pluralizedVotes}`

  return `${votesText.join("")} ${pluralizedVotes} has been reached`
}
