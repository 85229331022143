class @ChatsNewHandler
  constructor: ->
    @unreadBadges = new UnreadBadgesComponent(
      'chat-card',
      'chatId',
      'chats:updates'
    )
    @chatListComponent = new ChatListComponent(
      afterUpdate: => @unreadBadges.refresh()
    )
    @userStatusLabelComponent = new UserStatusLabelComponent()
    @chatMembersDialogComponent = new ChatMembersDialogComponent()

  setup: =>
    @unreadBadges.setup()
    @chatListComponent.setup()
    @userStatusLabelComponent.setup()
    @chatMembersDialogComponent.setup()

  teardown: =>
    @unreadBadges.teardown()
    @chatListComponent.teardown()
    @userStatusLabelComponent.teardown()
