import { startCase, capitalize, trimStart } from "lodash"

export const toSnakeCase = (camelString) =>
  camelString
    .split(/(?=[A-Z])/)
    .join("_")
    .toLowerCase()

export const toCamelCase = (snakeString) => {
  return snakeString.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace("-", "").replace("_", "")
  })
}

export const splitFilename = (filename) => {
  const filenameParts = filename?.split(".") || []
  const extension = filenameParts.length > 1 ? filenameParts.pop() : null
  const name = filenameParts.join(".")

  return { extension, name }
}

export const humanize = (string) => capitalize(startCase(string))

export const highlight = ({ text, textToHighlight, openingTag, closingTag }) => {
  if (!textToHighlight) {
    return text
  }

  return text.replaceAll(textToHighlight, `${openingTag}${textToHighlight}${closingTag}`)
}

export const withArticle = (string) => {
  const vowelLetters = ["a", "e", "i", "o", "u", "y"]
  const firstLetter = trimStart(string).charAt(0).toLocaleLowerCase()

  if (vowelLetters.some((letter) => letter === firstLetter)) {
    return `an ${string}`
  }

  return `a ${string}`
}

export const replaceMention = (string, name, replacement) => {
  return string
    .replace(new RegExp(`@${name}&nbsp;`, "ig"), `${replacement}&nbsp;`)
    .replace(new RegExp(`@${name}([.\\,:\\!;\\s])`), `${replacement}$1`)
}

export const generateUniqId = () => Date.now().toString()
