import React from "react"
import { styled } from "@linaria/react"
import { bool, string } from "prop-types"

import { BASE_COLORS } from "src/styles/colors"
import { MEMBERSHIP_TYPES } from "src/features/meetingPacks/MeetingPack/constants"
import { composeVariationsClasses } from "src/helpers/linaria"

const AttendeesNotification = ({ published, membershipType, className }) => {
  const classNameWithVariations = composeVariationsClasses(className, { published })

  let text
  if (published && membershipType !== MEMBERSHIP_TYPES.accessOnly)
    text = "Meeting pack is published: attendees will be notified of updates."
  else if (published)
    text =
      "Meeting pack published: Access only attendees will not receive email notifications for new updates"
  else text = "Attendees won’t be notified until the meeting pack has been published."

  return <p className={classNameWithVariations}>{text}</p>
}

AttendeesNotification.propTypes = {
  published: bool,
  membershipType: string
}

export default styled(AttendeesNotification)`
  text-align: center;
  font-size: 0.85rem;
  color: ${BASE_COLORS.red};
  width: 100%;

  &--published {
    color: ${BASE_COLORS.green};
  }
`
