import React from "react"
import { Form } from "react-bootstrap"
import { bool, func } from "prop-types"
import classNames from "classnames"
import { styled } from "@linaria/react"

import { airtableFilterType } from "src/resources/accounts/airtablePropTypes"
import Icon from "src/styles/components/Icon"
import FieldSearchableSelect from "src/features/ManageAccount/AirtableApps/EditPage/FieldSearchableSelect"
import SearchableSelect from "src/components/SearchableSelect"

import {
  EXTENDED_FILTER_TYPES,
  FILTER_TYPES,
  FILTER_TYPES_WITHOUT_VALUES
} from "./constants"

const Row = ({
  filter,
  setFilters,
  onlyPlainTextFields,
  extendedFiltersList,
  className
}) => {
  const removeFilter = () => setFilters((filters) => filters.filter((f) => f !== filter))
  const chooseField = (attrName) => (option) =>
    setFilters((filters) =>
      filters.map((f) => (f === filter ? { ...f, [attrName]: option.value } : f))
    )
  const setValue = (e) => {
    e.persist()
    setFilters((filters) =>
      filters.map((f) => (f === filter ? { ...f, value: e.target.value } : f))
    )
  }
  const filterTypes = extendedFiltersList ? EXTENDED_FILTER_TYPES : FILTER_TYPES
  const filterTypeOptions = Object.keys(filterTypes).map((filterType) => ({
    value: filterType,
    label: filterTypes[filterType]
  }))
  const filterTypeOption = filterTypeOptions.find(
    (option) => option.value === filter.type
  )

  return (
    <div
      className={classNames("d-flex align-items-center", className)}
      data-testid="filter-row"
    >
      <div className="pr-1">Where</div>
      <FieldSearchableSelect
        value={filter.fieldId}
        onChange={chooseField("fieldId")}
        containerClassName="btn-block mt-1"
        onlyPlainTextFields={onlyPlainTextFields}
      />
      <SearchableSelect
        options={filterTypeOptions}
        selected={filterTypeOption}
        setSelected={chooseField("type")}
        searchable={false}
        useSelectedLabel
        className="mt-1 mx-2 filter-type-select"
      />
      {!FILTER_TYPES_WITHOUT_VALUES.includes(filter.type) && (
        <Form.Control
          placeholder="Enter a value"
          value={filter.value}
          onChange={setValue}
        />
      )}
      <Icon
        type="cancel"
        size="medium"
        className="pl-2"
        onClick={() => removeFilter(filter)}
      />
    </div>
  )
}

Row.propTypes = {
  filter: airtableFilterType,
  setFilters: func.isRequired,
  onlyPlainTextFields: bool,
  extendedFiltersList: bool
}

export default styled(Row)`
  .btn {
    background: none;
    margin-top: 0.25rem;
  }

  input {
    margin-top: 0.15rem;
    padding: 0.5rem 0.75rem;
    border-color: #bdc3c7;
  }

  .filter-type-select .btn {
    margin-left: 0px;
  }
`
