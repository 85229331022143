import React from "react"
import { arrayOf } from "prop-types"

import { FolderItemType } from "./types"

const { createContext, useContext, useMemo } = require("react")

const Context = createContext([])

export const useSelectedFolderItems = () => useContext(Context)

const SelectedFolderItemsContext = ({ selectedFolderItems, children }) => {
  const dependencyKey = selectedFolderItems
    .map((item) => `${item.type}-${item.id}`)
    .join(",")

  const memoisedItems = useMemo(() => selectedFolderItems, [dependencyKey])

  return <Context.Provider value={memoisedItems}>{children}</Context.Provider>
}

SelectedFolderItemsContext.propTypes = {
  selectedFolderItems: arrayOf(FolderItemType).isRequired
}

export default SelectedFolderItemsContext
