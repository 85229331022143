class @FroalaTextareaComponent
  @DEFAULT_CONFIRM_TEXT =
    'You have unsaved changes. Are you sure you want to discard them?'

  constructor: (editorElement, options = {}) ->
    @editorElement =     editorElement
    @autosaveContentId = editorElement.data('id')
    @accountId = editorElement.data('account-id')
    @aiFeaturesEnabled = window.Knowa?.featureFlags?.aiFeaturesEnabled

    @analyticsContext =  options.analyticsContext
    @saveButton =        options.saveButton
    @resetCacheButton =  options.resetCacheButton
    @confirmText =       options.resetCacheButton.data('confirm-text') ||
      FroalaTextareaComponent.DEFAULT_CONFIRM_TEXT

    @autosavedMessageComponent = new AutosavedMessageComponent(@autosaveContentId)

    toolbarButtons =
      if @aiFeaturesEnabled
        FroalaToolbarButtons.DESKTOP.large
      else
        Object.assign(
          {},
          FroalaToolbarButtons.DESKTOP.large,
          { openaiButton: { buttons: [] } }
        )
    toolbarButtonsXS =
      if @aiFeaturesEnabled
        FroalaToolbarButtons.DESKTOP.xSmall
      else
        Object.assign(
          {},
          FroalaToolbarButtons.DESKTOP.xSmall,
          { openaiButton: { buttons: [] } }
        )
    @editor = new MessageEditorComponent(@editorElement, {
      toolbarButtons: toolbarButtons,
      toolbarButtonsXS: toolbarButtonsXS,
      quickInsertButtons: ['image', 'table', 'ul', 'ol', 'hr']
      autosaveContentId:  @autosaveContentId
      events:             @editorEventHandlers()
      params: {
        videoUploadToS3: window.videoUploadToS3
        events: {
          'video.inserted': (img) ->
            videos = this.$el.find("video[src=\"#{img[0].children[0].src}\"]")
            if videos.length > 1
              videos[0].closest('.fr-video').remove()
        }
        imageUploadToS3: window.imageUploadToS3
        imageEditButtons: [
          'imageReplace',
          'imageAlign',
          'imageSize',
          'imageRemove',
          'imageLink'
        ]
        toolbarSticky:     false,
        emoticonsUseImage: false,
        emoticonsStep:     10,
        heightMin:         200,
        heightMax:         350
      }
    })

  setup: =>
    (new FroalaEmbedComponent).setup()
    (new FroalaOpenaiButtonComponent(@analyticsContext)).setup()

    @editor.setup()
    @setupResetCacheButton()
    @setupSaveButton()

  teardown: ->
    @editor.teardown()
    @resetCacheButton.off('click')
    @saveButton.off('click')

  setupResetCacheButton: =>
    @resetCacheButton.click (e) =>
      if @resetCacheButton.data('confirmRequired')
        if confirm(@confirmText)
          @autosavedMessageComponent.reset()
        else
          e.preventDefault()

  setupSaveButton: =>
    @saveButton.click (e) =>
      if @resetCacheButton.data('confirmRequired')
        @autosavedMessageComponent.reset()

  editorEventHandlers: =>
    component = this

    return {
      initialized: () ->
        cachedMessage = component.autosavedMessageComponent.get()
        if cachedMessage
          this.html.set(cachedMessage)
          component.resetCacheButton.data('confirmRequired', true)
      contentChanged: () ->
        component.autosavedMessageComponent.set(this.html.get())
        unless component.resetCacheButton.data('confirmRequired')
          component.resetCacheButton.data('confirmRequired', true)
      'paste.afterCleanup': (html) ->
        html.replaceAll(/ color:\stransparent/ig, ' color: #302e67')
        replaceContentLinkText(
          cleanupLinkWithAmpersand(cleanupSafariLink(html)), window.availableLinks
        )
    }
