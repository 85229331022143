import React from "react"
import { shape, oneOf, number, bool, arrayOf } from "prop-types"
import { styled } from "@linaria/react"
import pluralize from "pluralize"

import { TEXT_COLORS } from "src/styles/colors"
import { formatDate } from "src/helpers/datetime"

import { decisiveVotesText } from "./helpers"
import { ACTION_TYPES, VOTING_OPTIONS } from "./constants"

const ACTION = {
  [ACTION_TYPES.voting]: "voted",
  [ACTION_TYPES.must_read]: "marked as read"
}

const text = ({
  actionType,
  completeActionsCount,
  actionClosedAt,
  actionsCount,
  maxActionsCount,
  decisiveVotes,
  actionClosed,
  actionsDisabled
}) => {
  if (!actionClosed) {
    if (!completeActionsCount) return `No one has ${ACTION[actionType]}`
    if (actionsCount === completeActionsCount) return `Everyone has ${ACTION[actionType]}`

    const people = pluralize("person", actionsCount, true)
    const have = pluralize("has", completeActionsCount)

    return `${completeActionsCount} of ${people} ${have} ${ACTION[actionType]}`
  }

  if (maxActionsCount && actionType === ACTION_TYPES.voting) {
    return decisiveVotesText({ decisiveVotes, actionsDisabled, maxActionsCount })
  }
  if (maxActionsCount && actionType === ACTION_TYPES.must_read) {
    const people = pluralize("person", maxActionsCount, true)
    const have = pluralize("has", maxActionsCount)

    return `Closed after ${people} ${have} marked as read`
  }
  return `Closed ${formatDate(actionClosedAt)}`
}

const MessageActionText = ({ className, message }) => {
  if (!message.actionType) return null

  return (
    <span
      data-testid="message-action-text"
      className={className}
      /* eslint-disable-next-line react/no-danger */
      dangerouslySetInnerHTML={{ __html: text(message) }}
    />
  )
}

MessageActionText.propTypes = {
  message: shape({
    actionType: oneOf(Object.keys(ACTION_TYPES)),
    completeActionsCount: number,
    actionsCount: number,
    decisiveVotes: arrayOf(
      shape({
        vote: oneOf(Object.keys(VOTING_OPTIONS)),
        count: number
      })
    ),
    actionClosed: bool,
    actionsDisabled: bool
  })
}

export default styled(MessageActionText)`
  font-size: 0.8rem;
  color: ${TEXT_COLORS.muted};
`
