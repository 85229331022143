import React from "react"
import { func } from "prop-types"
import { Formik } from "formik"

import LinkButton from "src/styles/components/Button/Link"
import SubmitButton from "src/features/TwoFactor/Enable/SubmitButton"

import PhoneInput from "./PhoneInput"
import CodeInput from "./CodeInput"
import usePhoneSubmissionSteps from "./usePhoneSubmissionSteps"

const EnableByPhonePage = ({ close, onStepChange }) => {
  const {
    error,
    timeLeftUntilNextAttempt,
    step,
    moveToFirstStep,
    handleSubmit,
    initialFormValues,
    validationSchema
  } = usePhoneSubmissionSteps({ onStepChange, onSuccess: close })

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      <>
        {step === 1 && <PhoneInput error={error} />}

        {step === 2 && <CodeInput error={error} moveToFirstStep={moveToFirstStep} />}

        <div className="form-main-controls">
          <SubmitButton
            className="wide-button"
            timeLeftUntilNextAttempt={step === 1 ? timeLeftUntilNextAttempt : 0}
          />
        </div>
        <div className="form-main-controls">
          <LinkButton onClick={close}>Skip adding extra security</LinkButton>
        </div>
      </>
    </Formik>
  )
}

EnableByPhonePage.propTypes = {
  close: func.isRequired,
  onStepChange: func
}

export default EnableByPhonePage
