import React, { useState, useEffect } from "react"
import { Form } from "react-bootstrap"
import { string } from "prop-types"
import { useFormikContext } from "formik"
import { styled } from "@linaria/react"
import classNames from "classnames"

import { TEXT_COLORS } from "src/styles/colors"
import { getCurrentUser } from "src/helpers/user"
import { CHANNELS } from "src/constants/ably"
import useAblyChannel from "src/hooks/useAblyChannel"
import PrimaryButton from "src/styles/components/Button/Primary"

const AIRTABLE_AUTHORIZE_PATH = "/users/airtable_oauth/authorize"
const TEST_CURRENT_USER_ID_SET = "TEST_CURRENT_USER_ID_SET"

const OAuthUser = ({ className, initialFullName }) => {
  const { setFieldValue, errors, touched } = useFormikContext()
  const currentUser = getCurrentUser()
  const [fullName, setFullName] = useState("")

  useEffect(() => {
    if (initialFullName) setFullName(initialFullName)
  }, [initialFullName])

  let airtableWindow

  const setupOauth = (e) => {
    e.preventDefault()

    airtableWindow = window.open(
      AIRTABLE_AUTHORIZE_PATH,
      "targetWindow",
      "width=600,height=700"
    )
  }

  const handleAuthCurrentUser = () => {
    setFieldValue("oauthUserId", currentUser.id)
    setFullName(currentUser.full_name)
    airtableWindow?.close()
  }

  useAblyChannel(CHANNELS.airtable.authorization, {
    onMessage: ({ data }) => {
      if (data.userId === currentUser?.id) handleAuthCurrentUser()
    }
  })

  // This useEffect is only for test purposes to use in Cucumber because Ably is not working there
  useEffect(() => {
    window.addEventListener(TEST_CURRENT_USER_ID_SET, handleAuthCurrentUser)

    return () =>
      window.removeEventListener(TEST_CURRENT_USER_ID_SET, handleAuthCurrentUser)
  }, [])

  return (
    <Form.Group controlId="oauthUser" className={className}>
      <Form.Label>OAuth User:</Form.Label>
      {` ${fullName}`}
      <div
        className={classNames(
          "d-flex justify-content-between",
          errors.oauthUserId && touched.oauthUserId ? "is-invalid" : ""
        )}
      >
        {currentUser.airtable_oauth_exist && (
          <PrimaryButton onClick={handleAuthCurrentUser}>
            Set current user as an OAuth User
          </PrimaryButton>
        )}

        <a
          href={AIRTABLE_AUTHORIZE_PATH}
          className="button is-danger"
          onClick={setupOauth}
        >
          Reauthorise
        </a>
      </div>
      <Form.Control.Feedback type="invalid">{errors.oauthUserId}</Form.Control.Feedback>
    </Form.Group>
  )
}

OAuthUser.propTypes = {
  initialFullName: string
}

export default styled(OAuthUser)`
  .is-invalid .form-field {
    border-color: ${TEXT_COLORS.danger};
  }
`
