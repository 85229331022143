import React from "react"
import { arrayOf, bool, number, shape, string } from "prop-types"

import DoubleLineHeader from "src/styles/components/DoubleLineHeader"
import useUnreadStats from "src/hooks/useUnreadStats"

import HeaderContext from "./HeaderContext"
import Logo from "./Logo"
import SidebarToggle from "./SidebarToggle"
import MainMenu from "./MainMenu"
import HeaderNav from "./HeaderNav"
import { toggleBadgeClass } from "./helpers"

const Header = ({
  accountName,
  exitPath,
  privateScheme,
  privateSchemeHighlighted,
  jointSchemeHighlighted,
  contactsHighlighted,
  jointScheme,
  backControl,
  whereIds,
  whereFilter
}) => {
  const stats = useUnreadStats()

  return (
    <HeaderContext.Provider value={stats}>
      <Logo />
      <SidebarToggle
        badgeClass={toggleBadgeClass({
          stats,
          privateSchemeId: privateScheme?.id,
          jointSchemeId: jointScheme?.id
        })}
      />

      {privateScheme && (
        <div className="header-space-name">
          <DoubleLineHeader mainText={privateScheme.name} subText={accountName} />
        </div>
      )}

      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: backControl }} />
      <MainMenu
        privateScheme={privateScheme}
        jointScheme={jointScheme}
        privateSchemeHighlighted={privateSchemeHighlighted}
        jointSchemeHighlighted={jointSchemeHighlighted}
        contactsHighlighted={contactsHighlighted}
      />
      <HeaderNav exitPath={exitPath} whereIds={whereIds} whereFilter={whereFilter} />
    </HeaderContext.Provider>
  )
}

Header.propTypes = {
  privateScheme: shape({
    id: number.isRequired,
    name: string.isRequired
  }),
  jointScheme: shape({
    id: number.isRequired
  }),
  backControl: string,
  exitPath: string,
  privateSchemeHighlighted: bool,
  jointSchemeHighlighted: bool,
  contactsHighlighted: bool,
  whereIds: arrayOf(string),
  whereFilter: string,
  accountName: string
}

export default Header
