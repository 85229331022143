class @CopyLinkComponent
  constructor: ->
    @behavior = 'copy-url-link'
    @triggerLinks = $be(@behavior)

  setup: ->
    @bindClick()

  bindClick: (container) =>
    if container
      container.find("[data-behavior=#{@behavior}]").click (e) ->
        unless $(this).data('dismiss')
          e.stopPropagation()
        handleClick($(this).data('url'))
    else
      @triggerLinks.click (e) ->
        unless $(this).data('dismiss')
          e.stopPropagation()
        handleClick($(this).data('url'))

  handleClick = (url) ->
    $copyPlainText(url)
    $showFlashMessage('success', 'Link has been copied. Paste it anywhere to share.')
