import { GroupMemberListComponent } from "legacy/components/group_member_list_component.js.coffee"
import { be } from "src/helpers/document"

export default class AppsInstancesShowHandler {
  constructor() {
    this.groupMemberListComponent = new GroupMemberListComponent({
      listContainerBehavior: "group-participants",
      manageEnabled: be("group-participants").data("manage"),
      contactDetailsEnabled: true
    })
  }

  setup() {
    this.groupMemberListComponent.setup()
  }

  teardown() {
    this.groupMemberListComponent.teardown()
  }
}
