import React, { useEffect, useState } from "react"
import { string, bool } from "prop-types"

import apiClient from "src/app/apiClient"
import LinkButton from "src/styles/components/Button/Link"
import { countDown } from "src/features/TwoFactor/helpers"
import { csrfToken } from "src/helpers/ajax"

import SignInCard from "./SignInCard"

const CODE_SENDING_PERIOD = 60 // seconds

const AuthForm = ({ phoneNumber, rememberMe, email }) => {
  const [resendCodeButtonDisabled, setResendCodeButtonDisabled] = useState(false)
  const [showHelpLink, setShowHelpLink] = useState(false)
  const defaultResendLinkText = "No code? Click to resend it."
  const [resendLinkText, setResendLinkText] = useState(defaultResendLinkText)

  const disableResendingButton = () => {
    setResendCodeButtonDisabled(true)
    countDown(
      CODE_SENDING_PERIOD,
      (seconds) =>
        setResendLinkText(`You can resend the code again in ${seconds} seconds`),
      () => {
        setResendLinkText(defaultResendLinkText)
        setResendCodeButtonDisabled(false)
        setShowHelpLink(true)
      }
    )
  }

  useEffect(() => {
    if (phoneNumber) disableResendingButton()
  }, [])

  const resendCode = () =>
    apiClient.post("/users/two_factor/resending").then(disableResendingButton)

  const otpAttemptId = "user[otp_attempt]"

  return (
    <SignInCard>
      <form action="/users/sign_in" method="POST">
        <input
          type="hidden"
          name="authenticity_token"
          id="authenticity_token"
          value={csrfToken()}
        />

        <input
          type="hidden"
          name="user[remember_me]"
          id="user[remember_me]"
          value={rememberMe}
        />

        <input type="hidden" name="user[email]" id="user[email]" value={email} />

        <h4 className="sign-in-header mb-4">2-Step Verification required</h4>

        <p className="text-muted">
          You&apos;ve asked us to require a temporary 6-digit code when logging into your
          account.
        </p>

        <p className="text-muted">
          Enter the 6-digit code{" "}
          {phoneNumber
            ? `that has been messaged to your phone number ending ${phoneNumber}`
            : "that you see on your authenticator app"}
        </p>

        <div className="form-block">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor={otpAttemptId}>6-digit login code</label>
          <div className="field">
            {/* eslint-disable jsx-a11y/no-autofocus */}
            <input
              type="text"
              id={otpAttemptId}
              name={otpAttemptId}
              autoFocus
              className="form-field"
            />
            {/* eslint-enable jsx-a11y/no-autofocus */}
          </div>
        </div>

        <div className="actions mtm">
          <button type="submit" className="button">
            Verify
          </button>
        </div>

        {phoneNumber ? (
          <div className="form-block mts">
            <LinkButton
              className="sign-in-issue"
              type="button"
              onClick={resendCode}
              disabled={resendCodeButtonDisabled}
            >
              {resendLinkText}
            </LinkButton>
          </div>
        ) : (
          <p className="text-muted mt-2 no-code-section">
            Any problems?{" "}
            <a href="mailto:support@knowa.co">Get in touch with support here.</a>
          </p>
        )}

        {phoneNumber && showHelpLink && (
          <p className="text-muted.no-code-section">
            Still no code received?{" "}
            <a href="mailto:support@knowa.co">Get in touch with support here.</a>
          </p>
        )}

        <div className="security-copy">
          <div className="security-copy-icon">
            <div className="icon-lock" />
          </div>
          <p className="security-copy-text">
            Your information is encrypted while being transmitted to us using 256-bit SSL.
          </p>
        </div>
      </form>
    </SignInCard>
  )
}

AuthForm.propTypes = {
  phoneNumber: string,
  rememberMe: bool,
  email: string.isRequired
}

export default AuthForm
