import React from "react"
import { bool } from "prop-types"
import { styled } from "@linaria/react"
import { lighten } from "polished"

import Icon from "src/styles/components/Icon"
import { composeVariationsClasses } from "src/helpers/linaria"
import { navigationButtonColor, meetingPackMutedTextColor } from "src/styles/colors"

const lightenNavigationButtonColor = lighten(0.1, navigationButtonColor)

const NavigationIcon = ({ className, disabled, ...rest }) => {
  const classes = composeVariationsClasses(className, { disabled })

  return <Icon className={classes} {...rest} />
}

NavigationIcon.propTypes = {
  disabled: bool
}

export default styled(NavigationIcon)`
  align-self: center;
  justify-self: center;
  color: ${navigationButtonColor};
  cursor: pointer;

  &:hover,
  &:focus {
    color: ${lightenNavigationButtonColor};
  }

  &--disabled {
    color: ${meetingPackMutedTextColor};
    cursor: not-allowed;

    &:hover,
    &:focus {
      color: ${meetingPackMutedTextColor};
    }
  }
`
