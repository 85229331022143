import { chain } from "lodash"

import { getSignatureAnnotations } from "src/features/signedDocuments/helpers"
import { findByText } from "src/helpers/document"
import { avatarsColorsPool } from "src/styles/colors"

export const createAnnotationRenderer = (groupMembers) => {
  const usersById = groupMembers.reduce((users, member) => {
    return {
      ...users,
      [member.id]: member
    }
  }, {})

  return ({ annotation }) => {
    const { customData } = annotation

    if (!annotation.formFieldName) {
      return null
    }

    const user = usersById[customData?.assignedUserId]

    // signature and text fields with assigned users
    if (customData?.assignedUserId && user) {
      const node = document.createElement("div")
      node.appendChild(document.createTextNode(user.initials))
      node.classList.add("PSPDFKit-Form-Assigned-User-Initials")
      node.style.backgroundColor = avatarsColorsPool[user.avatarColorId || 0]

      return {
        node,
        append: true
      }
    }

    // signature fields without assigned users
    if (annotation.formFieldName?.includes("SIGN")) {
      const node = document.createElement("div")
      node.classList.add("PSPDFKit-Form-Assigned-User-Error-Message")
      node.appendChild(document.createTextNode("Please select user"))

      return {
        node,
        append: true
      }
    }
  }
}

export const getAnnotationFormField = async (annotation, PSPDFKitInstance) => {
  if (!annotation) {
    return null
  }

  const formFields = await PSPDFKitInstance.getFormFields()
  return formFields.get(formFields.map((e) => e.name).indexOf(annotation.formFieldName))
}

export const getAssignedUsersIds = async (instance) => {
  return getSignatureAnnotations(instance).then((signatureAnnotations) => {
    return chain(signatureAnnotations)
      .map((annotation) => annotation?.customData?.assignedUserId)
      .compact()
      .uniq()
      .value()
  })
}

export const areSignatureFieldsHaveUsers = async (instance) => {
  const signatureFieldsAnnotations = await getSignatureAnnotations(instance)

  return signatureFieldsAnnotations.every(
    (annotation) => annotation?.customData?.assignedUserId
  )
}

export const buildUserSelect = ({ users, annotationId, assignedUserId }) => `
  <div class="PSPDFKit-Form-Assigned-User">
    <label>
      Assign To User *
      <select id="assignedUser" class="PSPDFKit-Form-Creator-Editor-Form-Field-Name" data-annotation-id="${annotationId}">
        <option hidden>select user</option>
        ${users.map(
          (member) =>
            `<option value="${member.id}" ${
              member.id === assignedUserId ? "selected" : ""
            }>${member.fullName}</option>`
        )}
      </select>
    </label>
  </div>
`

export const selectUserInUserSelect = ({ select, userId }) => {
  Array.from(select.querySelectorAll("option")).forEach((option) => {
    option.removeAttribute("selected")

    if (Number(option.value) === Number(userId)) {
      option.setAttribute("selected", "true")
    }
  })
}

export const hideAdvancedSection = (contentDocument) => {
  const advancedSectionTitle = findByText(
    contentDocument.querySelectorAll(".PSPDFKit-Form-Creator-Editor span"),
    "Advanced"
  )

  advancedSectionTitle?.parentElement?.parentElement?.parentElement?.classList?.add(
    "d-none"
  )
}

export const removeFieldNameInput = (document) => {
  const fieldNameInput = document.querySelector(
    ".PSPDFKit-Form-Creator-Editor-Form-Field-Name"
  )

  // PSPDFKit only adds generated classnames so it's the only way to select the desired element
  const inputWrapperElement = fieldNameInput?.parentElement?.parentElement?.parentElement

  if (inputWrapperElement) {
    inputWrapperElement.classList.add("d-none")
    inputWrapperElement.nextSibling?.classList?.add("d-none")
  }
}
