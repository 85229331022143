import { useState } from "react"

import useMoveFolderItemMutation from "./useMoveFolderItemMutation"

const useMoveFolderItem = ({
  groupId,
  currentFolder,
  onMoveStart,
  onMoveSuccess,
  onUndoStart,
  onFailedResult,
  onUndoSuccess,
  onError
}) => {
  const mutate = useMoveFolderItemMutation()
  const [undoMoveData, setUndoMoveData] = useState(null)

  const move = ({ folderItem, destinationFolder }) => {
    onMoveStart({
      folderItem,
      destinationFolder
    })

    setUndoMoveData(null)

    return mutate({ groupId, destinationFolder, folderItem })
      .then((result) => {
        if (!result.successful) {
          onFailedResult(result.message)
        } else {
          setUndoMoveData({ folderItem, destinationFolder: currentFolder })

          onMoveSuccess({
            folderItem,
            destinationFolder
          })
        }
      })
      .catch((error) => onError(error))
  }

  const undo = () => {
    onUndoStart(undoMoveData)

    return mutate({ groupId, ...undoMoveData })
      .then((result) => {
        if (!result.successful) {
          onFailedResult(result.message)
        } else {
          onUndoSuccess(undoMoveData)
        }
      })
      .catch((error) => onError(error))
  }

  return { move, undo }
}

export default useMoveFolderItem
