import React, { useState } from "react"
import { shape, string } from "prop-types"

import Header from "./Header"
import Search from "./Search"

const SearchPage = ({
  exitLink,
  searchParams: { query, category, order, dateFrom, dateTo, whereIds, userIds }
}) => {
  const [searchQuery, setSearchQuery] = useState(query)
  return (
    <>
      <Header
        exitLink={exitLink}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
      <Search
        query={searchQuery}
        category={category}
        exitLink={exitLink}
        order={order}
        dateFrom={dateFrom}
        dateTo={dateTo}
        whereIds={whereIds}
        userIds={userIds}
      />
    </>
  )
}

SearchPage.propTypes = {
  exitLink: string.isRequired,
  searchParams: shape({
    query: string
  })
}

export default SearchPage
