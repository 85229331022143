import React from "react"

const MeetingPackOfflineContext = React.createContext({
  isSavedOffline: undefined,
  saveMeetingOffline: () => {},
  clearMeetingCache: () => {},
  setIsSavedOffline: () => {}
})

export default MeetingPackOfflineContext
