import React from "react"
import { Formik } from "formik"
import { Form, InputGroup } from "react-bootstrap"
import { useMutation } from "@apollo/client"
import { string, func, bool, number } from "prop-types"

import { StyledModal } from "src/styles/components/Modal"
import ModalHeader from "src/styles/components/Modal/Header"
import ModalBody from "src/styles/components/Modal/Body"
import ModalFooter from "src/styles/components/Modal/Footer"
import { showFlashMessage } from "src/helpers/flash"

import updateTeamMutation from "./updateTeamMutation.gql"
import createTeamMutation from "./createTeamMutation.gql"

const EditTeamModal = ({ id, accountId, name, close, isOpened }) => {
  const [updateTeam, { updateLoading }] = useMutation(updateTeamMutation, {
    refetchQueries: ["teams"]
  })

  const [createTeam, { createLoading }] = useMutation(createTeamMutation, {
    refetchQueries: ["teams"]
  })

  const updateTeamAndClose = (values) => {
    if (values.id) {
      updateTeam({ variables: { name: values.name, id: values.id } }).then(
        ({ data: result = {} }) => {
          if (result.updateTeam?.successful) {
            showFlashMessage("success", "Team was update")
          } else if (result.updateTeam?.errors?.length) {
            showFlashMessage("danger", result.updateTeam.errors.join(". "))
          }
          close()
        }
      )
    } else {
      createTeam({ variables: { accountId, name: values.name } }).then(
        ({ data: result = {} }) => {
          if (result.createTeam?.successful) {
            showFlashMessage("success", "Team was created")
          } else if (result.createTeam?.errors?.length) {
            showFlashMessage("danger", result.createTeam.errors.join(". "))
          }
          close()
        }
      )
    }
  }

  return (
    <StyledModal size="md" onHide={close} show={isOpened}>
      <Formik initialValues={{ id, name }} onSubmit={updateTeamAndClose}>
        {({ values, handleChange, handleSubmit }) => (
          <>
            <ModalHeader className="d-flex justify-content-between">
              <h5 className="flex-grow-1">{id ? "Edit" : "New"} Team</h5>
            </ModalHeader>

            <ModalBody>
              <Form.Group controlId="name">
                <Form.Label>
                  <h6>Team name</h6>
                </Form.Label>
                <InputGroup>
                  <Form.Control value={values.name || ""} onChange={handleChange} />
                </InputGroup>
              </Form.Group>
            </ModalBody>

            <ModalFooter className="d-flex">
              <div className="form-main-controls actions mtm">
                <button
                  type="submit"
                  className="button"
                  disabled={updateLoading || createLoading}
                  onClick={() => handleSubmit(values)}
                >
                  Apply
                </button>
                <button className="button is-secondary" onClick={close}>
                  Cancel
                </button>
              </div>
            </ModalFooter>
          </>
        )}
      </Formik>
    </StyledModal>
  )
}

EditTeamModal.propTypes = {
  id: number,
  accountId: number.isRequired,
  name: string,
  close: func.isRequired,
  isOpened: bool
}

export default EditTeamModal
