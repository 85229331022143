import moment from "moment-timezone"
import { partition, sortBy } from "lodash"

import {
  CATEGORIES,
  ORDER_OPTION_RELEVANCE,
  RESULT_CATEGORIES
} from "src/features/Search/constants"

export const categoriesCount = (searchResults) => {
  const categories = Object.fromEntries(
    Object.values(CATEGORIES).map((category) => [category, 0])
  )

  if (!searchResults) {
    return categories
  }

  return searchResults.reduce((countedCategories, result) => {
    Object.values(CATEGORIES).forEach((category) => {
      if (RESULT_CATEGORIES[result.type].includes(category)) {
        // eslint-disable-next-line no-param-reassign
        countedCategories[category] += 1
      }
    })

    return countedCategories
  }, categories)
}

export const filterCategory = ({ results, category }) => {
  return results.filter((result) => {
    if (category) {
      return RESULT_CATEGORIES[result.type].includes(category)
    }

    return true
  })
}

export const filterDates = (whenFilter) => {
  return {
    dateFrom: moment().subtract(whenFilter.timePeriod),
    dateTo: moment()
  }
}

export const sortResults = ({ results, order, aiAnswerSortedLinks }) => {
  if (aiAnswerSortedLinks && order === ORDER_OPTION_RELEVANCE.value) {
    const [resultsWithinAiAnswer, otherResults] = partition(
      results,
      (r) => aiAnswerSortedLinks.indexOf(r.path) !== -1
    )
    return [
      ...sortBy(resultsWithinAiAnswer, (r) => aiAnswerSortedLinks.indexOf(r.path)),
      ...sortBy(otherResults, (r) => -1 * r.relevance)
    ]
  }

  return sortBy(results, (result) => {
    if (order === ORDER_OPTION_RELEVANCE.value) return -1 * result.relevance

    return 9999999900000 - new Date(result.datetime).valueOf()
  })
}
