export const getDiscussionOption = (discussion) => {
  if (!discussion) return

  return {
    ...discussion,
    value: discussion.id,
    label: discussion.title
  }
}

export const getDiscussionOptionId = (option) => option?.value
