import { TimePickerComponent } from "legacy/components/meeting_packs/time_picker.js.coffee"
import { AgendaScrollPosition } from "legacy/components/meeting_packs/agenda_scroll_position"

export default class BreaksEditHandler {
  constructor() {
    this.timePicker = new TimePickerComponent()
    this.agendaScrollPosition = new AgendaScrollPosition()
  }

  setup() {
    this.timePicker.setup()
    this.agendaScrollPosition.setupSubmitBreakButton()
  }

  teardown() {
    this.agendaScrollPosition.teardownSubmitBreakButton()
  }
}
