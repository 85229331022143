import React from "react"
import classNames from "classnames"
import { bool } from "prop-types"
import { Row } from "react-bootstrap"

import UserNameWithDetails from "src/components/UsersContacts/UserNameWithDetails"
import useToggle from "src/hooks/useToggle"
import useIsMinimizable from "src/hooks/useIsMinimizable"
import { messageType } from "src/resources/messages/propTypes"
import { normalizeMessage } from "src/resources/messages/helpers"
import { getCurrentUserId } from "src/helpers/user"
import PreviewLink from "src/components/DocumentPreviewLink"

import { MessageContent, MessageCreds, MessageToggle } from "./styles"
import Breadcrumbs from "./Breadcrumbs"

const USER_DETAILS_TYPE = "Contact"

const SharedMessageContent = ({
  message,
  minimizable = false,
  withAttachments = false
}) => {
  const { attachedDocuments, author, authorName, createdAt, edited, fromEmail } =
    normalizeMessage(message)

  const { wrapperRef, bodyRef, isMinimizable } = useIsMinimizable()
  const [isMinimized, toggleMinimized] = useToggle("shareMessageMinimized", true)
  const isCurrentUser = author.id === getCurrentUserId()
  const messageBlockClasses = classNames("message-block", "message-share", {
    "message-block__minimizable": isMinimizable,
    "message-block__minimized": isMinimizable && isMinimized,
    "message-block__from-email": fromEmail
  })

  return (
    <div
      className={messageBlockClasses}
      data-behavior="shared-message-container"
      data-id={message.id}
    >
      <div className="message-blockquote" />
      <div className="message-meta">
        <MessageCreds className="message-creds">
          <span>
            <UserNameWithDetails
              name={authorName}
              user={author}
              isCurrentUser={isCurrentUser}
              type={USER_DETAILS_TYPE}
            />
            <span>, </span>
            <span className="text-muted text-nowrap">{createdAt}</span>
          </span>
          {fromEmail && (
            <span className="from-email" title="Message sent by email">
              <span className="icon-email" />
            </span>
          )}
        </MessageCreds>
      </div>
      <MessageContent className="message-content">
        <div className="message-body-wrapper" ref={wrapperRef}>
          <MessageContent className="message-body fr-view" ref={bodyRef}>
            <div dangerouslySetInnerHTML={{ __html: message.body }} />
            {edited && <span className="edited-label">(edited)</span>}
          </MessageContent>
        </div>
      </MessageContent>
      <MessageContent className="message-footer">
        {minimizable ? (
          <MessageToggle
            className="message-size_toggle"
            data-id={message.id}
            data-behavior="message-size_toggle"
            data-show-less-text="Show less"
            data-show-more-text="Show whole message"
            onClick={toggleMinimized}
          />
        ) : (
          <MessageToggle
            disabled
            className="message-size_toggle"
            data-behavior="message-size_toggle"
            data-show-more-text=". . ."
          />
        )}
        <Breadcrumbs
          shared
          withMessageLink={minimizable}
          withDiscussionLink={minimizable}
          message={message}
        />
        {withAttachments && (
          <Row noGutters md={2}>
            {attachedDocuments.map((attachedDocument) => (
              <PreviewLink
                key={attachedDocument.id}
                document={attachedDocument}
                collection={attachedDocuments}
                previewable={minimizable}
              />
            ))}
          </Row>
        )}
      </MessageContent>
    </div>
  )
}

SharedMessageContent.propTypes = {
  message: messageType.isRequired,
  minimizable: bool,
  withAttachments: bool
}

export default SharedMessageContent
