import { useMemo } from "react"
import { keyBy } from "lodash"

const usePageMetadata = (pageMetadata) => {
  const metadataFields = pageMetadata?.fields || []
  const allFields = useMemo(() => keyBy(metadataFields, "id"), [metadataFields])
  const primaryField = useMemo(
    () => metadataFields.find((field) => field.isPrimaryField),
    [metadataFields]
  )

  return { allFields, primaryField }
}

export default usePageMetadata
