import React, { useState, useEffect } from "react"
import { Accordion } from "react-bootstrap"
import { number } from "prop-types"
import { Link, useParams } from "react-router-dom"
import { useMutation, useQuery } from "@apollo/client"
import { styled } from "@linaria/react"
import classNames from "classnames"
import { omit } from "lodash"

import Title from "src/features/ManageAccount/AirtableApps/Title"
import { showApp as showAppRoute } from "src/features/ManageAccount/routes"
import { showFlashMessage } from "src/helpers/flash"
import { withoutAttributes } from "src/helpers/array"
import useLayoutHeader from "src/hooks/useLayoutHeader"
import { SPACES } from "src/styles/sizes"

import appAirtablePageQuery from "./appAirtablePage.gql"
import appAirtableInstanceQuery from "./appAirtableInstance.gql"
import updatePageMutation from "./updatePageMutation.gql"
import FilterRecords from "./FilterRecords"
import OverviewSettings from "./OverviewSettings"
import Sections from "./Sections"
import PageMetadataContext from "./PageMetadataContext"
import usePageMetadata from "./usePageMetadata"

const EditPage = ({ accountId, className }) => {
  const { appId, id } = useParams()

  const { data: airtableInstanceData } = useQuery(appAirtableInstanceQuery, {
    variables: { id: appId }
  })
  useLayoutHeader({
    useDefault: false,
    title: airtableInstanceData?.appAirtableInstance?.name
  })

  const { data } = useQuery(appAirtablePageQuery, {
    variables: { id },
    fetchPolicy: "no-cache"
  })

  const pageData = data?.appAirtablePage
  const pageMetadata = pageData?.metadata
  const { fieldOptions, allFields } = usePageMetadata({
    pageMetadata
  })
  const [filters, setFilters] = useState([])
  const [filtersSwitchedOn, setFiltersSwitchedOn] = useState(false)
  const [cardFields, setCardFields] = useState([])
  const [sections, setSections] = useState([])
  const [sortBy, setSortBy] = useState({})
  const [updatePage, { loading }] = useMutation(updatePageMutation)

  useEffect(() => {
    if (!pageData) return

    setFilters(pageData.filters || [])
    setFiltersSwitchedOn(!!pageData.filters?.length)
    setCardFields(pageData.cardFields || [])
    setSections(pageData.sections || [])
    setSortBy(pageData.sortBy || {})
  }, [pageData?.filters, pageData?.cardFields, pageData?.sections, pageData?.sortBy])

  const updatePageAndReturn = () => {
    updatePage({
      variables: {
        accountId,
        id,
        filters: filtersSwitchedOn ? withoutAttributes(filters, ["__typename"]) : [],
        cardFields: withoutAttributes(cardFields, ["__typename"]),
        sections: withoutAttributes(sections, [
          "__typename",
          "filters.__typename",
          "fields.__typename",
          "fields.filters.__typename"
        ]),
        sortBy: omit(sortBy, ["__typename"])
      }
    }).then(({ data: result = {} }) => {
      if (result.updateAirtablePage?.successful) {
        showFlashMessage("success", "Application design saved")
      } else if (result.updateAirtablePage?.errors?.length) {
        showFlashMessage("danger", result.updateAirtablePage.errors.join(". "))
      }
    })
  }

  return (
    <div className={classNames("fluid-container", className)}>
      <Title
        text="Design Application"
        backButtonRoute={showAppRoute(accountId, appId)}
        backButtonText="Back to overview"
      />

      <Accordion>
        {pageData && (
          // eslint-disable-next-line react/jsx-no-constructed-context-values
          <PageMetadataContext.Provider value={{ pageMetadata, fieldOptions, allFields }}>
            <FilterRecords
              filters={filters}
              setFilters={setFilters}
              filtersSwitchedOn={filtersSwitchedOn}
              setFiltersSwitchedOn={setFiltersSwitchedOn}
              sortBy={sortBy}
              setSortBy={setSortBy}
            />
            <OverviewSettings cardFields={cardFields} setCardFields={setCardFields} />
            <Sections sections={sections} setSections={setSections} />
          </PageMetadataContext.Provider>
        )}
      </Accordion>

      <div className="d-flex justify-content-center pb-3">
        <button
          type="submit"
          className="button"
          disabled={loading}
          onClick={updatePageAndReturn}
        >
          Save
        </button>
        <Link to={showAppRoute(accountId, appId)} className="button is-secondary">
          Cancel
        </Link>
      </div>
    </div>
  )
}

EditPage.propTypes = {
  accountId: number.isRequired
}

export default styled(EditPage)`
  .card {
    padding-left: ${SPACES.xLarge};
    padding-right: ${SPACES.xLarge};
  }
`
