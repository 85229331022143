import React, { useContext } from "react"
import { Modal } from "react-bootstrap"
import { styled } from "@linaria/react"
import { string, bool } from "prop-types"

import ModalContext from "../ModalContext"

import Header from "./Header"
import Body from "./Body"

const ActionDetailsModal = ({ show, className }) => {
  const { closeMainModal } = useContext(ModalContext)
  return (
    <Modal
      className={className}
      show={show}
      keyboard
      onHide={closeMainModal}
      data-testid="message-action-status-content"
    >
      <Header />
      <Body />
    </Modal>
  )
}

ActionDetailsModal.propTypes = {
  show: bool.isRequired,
  className: string
}

export default styled(ActionDetailsModal)`
  .modal-content {
    min-height: 10%;
  }
`
