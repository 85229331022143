import React, { useCallback, useState } from "react"
import { styled } from "@linaria/react"
import { bool, func, shape, string } from "prop-types"

import { Header, Body, FullScreenModal } from "src/styles/components/Modal"

import SignedDocument from "./SignedDocument"

const AttachedSignedDocumentModal = ({
  className,
  document,
  isOpened,
  close,
  ...props
}) => {
  const [isSavingChanges, setIsSavingChanges] = useState(false)
  const closeIfSaved = useCallback(
    () => isSavingChanges || close(),
    [isSavingChanges, close]
  )

  return (
    <FullScreenModal show={isOpened} onHide={closeIfSaved} className={className}>
      <Header>{document.name}</Header>
      <Body className="m-0 p-0">
        <SignedDocument
          {...props}
          setIsSavingChanges={setIsSavingChanges}
          document={document}
          isOpened={isOpened}
          close={closeIfSaved}
        />
      </Body>
    </FullScreenModal>
  )
}

AttachedSignedDocumentModal.propTypes = {
  document: shape({
    name: string.isRequired
  }).isRequired,
  isOpened: bool.isRequired,
  close: func.isRequired
}

export default styled(AttachedSignedDocumentModal)`
  .modal-dialog {
    height: 90%;
  }

  .modal-content {
    height: 100%;
  }

  .modal-body {
    height: 100%;
  }
`
