export default ({
  instance,
  buttonClass,
  sidebarMode,
  activeButtonClass = "custom-button--active"
}) => {
  const document = instance.contentDocument
  const activeButton = document.querySelector(`.${activeButtonClass}`)
  const currentButton = document.querySelector(`.${buttonClass}`)

  if (activeButton) {
    activeButton.classList.remove(activeButtonClass)
  }

  instance.setViewState((viewState) => {
    let nextSidebarMode = null

    if (viewState.sidebarMode !== sidebarMode) {
      nextSidebarMode = sidebarMode
      currentButton.classList.add(activeButtonClass)
    }

    return viewState.set("sidebarMode", nextSidebarMode)
  })
}
