import React, { useState } from "react"
import { arrayOf, bool, func, number, shape, string } from "prop-types"
import { styled } from "@linaria/react"
import { partition } from "lodash"
import { useMutation } from "@apollo/client"

import { StyledModal } from "src/styles/components/Modal"
import ModalHeader from "src/styles/components/Modal/Header"
import ModalBody from "src/styles/components/Modal/Body"
import ModalTitle from "src/styles/components/Modal/Title"
import ModalFooter from "src/styles/components/Modal/Footer"
import Primary from "src/styles/components/Button/Primary"
import Secondary from "src/styles/components/Button/Secondary"
import WarningText from "src/styles/components/WarningText"
import SelectTeammates from "src/components/SelectTeammates"

import updateTeamMemberships from "./updateTeamMembershipsMutation.gql"

const ManageTeamMembersModal = ({
  isOpened = false,
  team,
  onHide,
  className,
  possibleUsers = [],
  teamMembers = [],
  possibleTeams = []
}) => {
  const initialSelectedUserIds = teamMembers.map(({ id }) => id)
  const [addedTeams, possibleTeamsToAdd] = partition(possibleTeams, (possibleTeam) => {
    return possibleTeam.teamMemberships.every(({ userId }) =>
      initialSelectedUserIds.includes(userId)
    )
  })

  const [selectedUserIds, setSelectedUserIds] = useState(initialSelectedUserIds)

  const teamId = team.id
  const [setMemberships, { loading: areMembershipsUpdating }] = useMutation(
    updateTeamMemberships,
    {
      variables: {
        teamId,
        userIds: selectedUserIds
      },
      refetchQueries: ["teams"],
      awaitRefetchQueries: true
    }
  )
  const updateMembershipsAndClose = () => {
    setMemberships().then(onHide)
  }

  return (
    <StyledModal show={isOpened} onHide={onHide} className={className}>
      <ModalHeader closeButton>
        <div>
          <ModalTitle as="h5" className="d-inline text-center">
            Manage Team
          </ModalTitle>
          <div>{team.name}</div>
        </div>
      </ModalHeader>
      <ModalBody className="p-0 allow-scroll m-0">
        <SelectTeammates
          possibleTeams={possibleTeamsToAdd}
          addedTeams={addedTeams}
          addedUsers={teamMembers}
          possibleUsers={possibleUsers}
          title="Team Members"
          selectedUserIds={selectedUserIds}
          setSelectedUserIds={setSelectedUserIds}
        />
      </ModalBody>
      <ModalFooter>
        <div className="w-100">
          <div className="mb-3 text-center">
            <WarningText
              variant="danger"
              className="text-center"
              text="Adding and removing team members does not affect access to meeting packs, agenda items or collaboration groups."
            />
          </div>
          <div className="d-flex align-horizontally-center">
            <Primary
              className="mr-4"
              onClick={updateMembershipsAndClose}
              disabled={areMembershipsUpdating}
            >
              {areMembershipsUpdating ? "Updating..." : "Confirm"}
            </Primary>
            <Secondary variant="outline-secondary" onClick={onHide}>
              Cancel
            </Secondary>
          </div>
        </div>
      </ModalFooter>
    </StyledModal>
  )
}

ManageTeamMembersModal.propTypes = {
  isOpened: bool.isRequired,
  onHide: func.isRequired,
  possibleUsers: arrayOf(
    shape({
      id: number.isRequired,
      fullName: string.isRequired
    })
  ),
  teamMembers: arrayOf(
    shape({
      id: number.isRequired,
      fullName: string.isRequired
    })
  ),
  possibleTeams: arrayOf(
    shape({
      id: number.isRequired
    })
  ),
  team: shape({
    id: number.isRequired,
    name: string.isRequired
  })
}

export default styled(ManageTeamMembersModal)`
  .modal-content {
    overflow: hidden;
  }
`
