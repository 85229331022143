import React, { useEffect, useMemo } from "react"
import { number, shape } from "prop-types"
import { useQuery } from "@apollo/client"
import { styled } from "@linaria/react"

import {
  Header,
  Column,
  UserColumn,
  Table,
  FilterInput,
  RoleColumn,
  useSorting,
  useFiltering
} from "src/components/AttandeesTable"
import MembershipTypeColumn from "src/features/meetingPacks/AttendeesTable/MembershipTypeColumn"
import DeletedFromAccountBadge from "src/components/DeletedFromAccountBadge"

import ActionsColumn from "./ActionsColumn"
import meetingPackMembershipsQuery from "./meetingPackMemberships.gql"
import PublicationStatusColumn from "./PublicationStatusColumn"

const columns = [
  {
    key: "fullName",
    label: "FULL NAME"
  },
  {
    key: "email",
    label: "EMAIL"
  },
  {
    key: "jobTitle",
    label: "ROLE & ORGANISATION"
  },
  {
    key: "role",
    label: "PERMISSION"
  },
  {
    key: "membershipType",
    label: "ATTENDEE TYPE"
  },
  {
    key: "publicationStatus",
    label: "PUBLISH STATUS"
  },
  {
    key: "lastSeenStatus",
    label: "ACTIVE IN MP"
  }
]

const addButtonProps = {
  "data-behavior": "add-people-button",
  "data-toggle": "modal",
  "data-target": "[data-behavior='add-members-modal']"
}

const AttendeesTable = ({ meetingPack, className }) => {
  const { data, refetch } = useQuery(meetingPackMembershipsQuery, {
    variables: { meetingPackId: meetingPack.id, accountId: meetingPack.accountId }
  })

  useEffect(() => {
    window.addEventListener("meetingMembershipsUpdated", () => {
      refetch({ meetingPackId: meetingPack.id })
    })
  }, [])

  const rawUsers = useMemo(
    () =>
      (data?.meetingPackMemberships || []).map((membership) => ({
        ...membership,
        ...membership.user,
        jobTitle: membership.user.accountMemberships[0]?.userJobTitle,
        lastSeenStatus: membership.lastSeenStatus,
        lastActiveAt: membership.lastActiveAt
      })),
    [data?.meetingPackMemberships]
  )

  const {
    sortedItems: sortedUsers,
    sort,
    sortDirection,
    sortBy
  } = useSorting({
    items: rawUsers,
    sortingColumnsRewrite: {
      lastSeenStatus: (item) => new Date(item.lastActiveAt).getTime()
    }
  })
  const { filteredItems: users, setFilter } = useFiltering({
    items: sortedUsers,
    filteredFields: ["email", "fullName", "jobTitle"]
  })

  return (
    <div className={className}>
      <FilterInput
        setFilter={setFilter}
        addButtonCaption="Add new Attendees"
        addButtonProps={addButtonProps}
      />
      <Table className="table table-responsive-xl">
        <Header
          columns={columns}
          sortBy={sortBy}
          sortDirection={sortDirection}
          sort={sort}
        />
        <tbody>
          {users.map((user) => (
            <tr key={user.id} data-id={user.id} data-behavior="user-management-table-row">
              <UserColumn user={user} />
              <Column className="fixedWidth">{user.email}</Column>
              <Column>{user.jobTitle}</Column>
              {user.deletedFromAccount ? (
                <Column>
                  <DeletedFromAccountBadge variant="multiline" />
                </Column>
              ) : (
                <RoleColumn role={user.role} centered />
              )}
              <MembershipTypeColumn
                membershipType={user.membershipType}
                hasLimitedVisibilityItems={user.hasLimitedVisibilityItems}
              />
              <PublicationStatusColumn
                publicationStatus={user.publicationStatus}
                deletedFromAccount={user.deletedFromAccount}
              />
              <Column>{user.lastSeenStatus}</Column>
              <ActionsColumn user={user} meetingPack={meetingPack} />
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

AttendeesTable.propTypes = {
  meetingPack: shape({
    id: number.isRequired
  })
}

export default styled(AttendeesTable)`
  overflow: auto;

  .fixedWidth {
    max-width: 220px;
  }
`
