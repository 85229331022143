import { DEFAULT_ACCOUNT_FILTER } from "./constants"

export const getAccountOption = (account) => {
  if (!account) return

  return {
    value: account.id,
    label: account.name
  }
}

export const getAccountOptionId = (option) => {
  if (option?.value === DEFAULT_ACCOUNT_FILTER.value) return

  return option?.value
}
