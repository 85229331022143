import React from "react"
import { styled } from "@linaria/react"
import { shape, string } from "prop-types"

import UserAvatar from "src/components/UsersContacts/UserAvatar"
import { CardTitle, UserFullName, UserJobTitle } from "src/components/UsersModal/styles"
import { SPACES } from "src/styles/sizes"

const UserRow = ({ className, user }) => {
  const hasUserSigned = Boolean(user.signedAt)

  return (
    <div className={className}>
      <UserAvatar className="mr-2" user={user} />
      <CardTitle>
        <UserFullName>{user.fullName}</UserFullName>
        <UserJobTitle>{user.jobTitle}</UserJobTitle>
      </CardTitle>
      {hasUserSigned ? (
        <div>Signed</div>
      ) : (
        <div className="text-muted">Hasn&apos;t signed</div>
      )}
    </div>
  )
}

UserRow.propTypes = {
  user: shape({
    fullName: string,
    jobTitle: string
  })
}

export default styled(UserRow)`
  display: flex;
  align-items: center;
  margin-bottom: ${SPACES.small};
`
