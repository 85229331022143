import { styled } from "@linaria/react"

import CirclesLoader from "src/styles/components/TwoCirclesLoader"

export const Container = styled.div`
  border-top: 1px solid #e3e7ed;
  padding: 10px 16px;
  display: flex;
  justify-content: end;
  position: relative;
  min-height: 59px;
`

export const TwoCirclesLoader = styled(CirclesLoader)`
  left: unset;
  right: 40px;
`
