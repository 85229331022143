import { debounce } from "lodash"

import {
  useSidebarOpened as useUniversalAiSidebarOpened,
  useSidebarClosed as useUniversalAiSidebarClosed
} from "src/features/UniversalAi"
import useResizeObserver from "src/hooks/useResizeObserver"

const useCloseButtonDynamicPosition = ({ closeButton, skip = false }) => {
  const closeButtonPositionHandler = debounce(() => {
    if (!closeButton || skip) return

    const aiSidebar = document.querySelector("[data-behavior=universal-ai-sidebar]")
    if (!aiSidebar) return

    const sidebarRect = aiSidebar.getBoundingClientRect()
    // When sidebar is in fullscreen mode
    if (sidebarRect.left === 0) return

    const buttonRect = closeButton.getBoundingClientRect()
    const closeButtonContainer = closeButton.parentElement
    const containerRect = closeButtonContainer.getBoundingClientRect()

    if (containerRect.right <= sidebarRect.left) {
      // eslint-disable-next-line no-param-reassign
      closeButton.style.marginRight = null
    } else if (sidebarRect.left < buttonRect.right + 10) {
      // 15 is little indent between button and AI sidebar
      const offset = buttonRect.right + 15 - sidebarRect.left
      // eslint-disable-next-line no-param-reassign
      closeButton.style.marginRight = `${offset}px`
    }
  }, 50)

  useUniversalAiSidebarOpened(closeButtonPositionHandler, [!!closeButton])

  useUniversalAiSidebarClosed(() => {
    if (!closeButton) return

    // eslint-disable-next-line no-param-reassign
    closeButton.style.marginRight = null
  }, [!!closeButton])

  useResizeObserver({
    selector: "[data-behavior=content]",
    handler: closeButtonPositionHandler,
    deps: [!!closeButton]
  })
}

export default useCloseButtonDynamicPosition
