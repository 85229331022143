import { partition } from "lodash"

import { MEMBERSHIP_TYPES } from "src/features/meetingPacks/MeetingPack/constants"

export default ({
  setSelectedMemberships,
  selectedMemberships,
  initialSelectedMemberships,
  disablePresentMembership,
  possibleAttendees,
  teams
}) => {
  const disablePresentTeam = (team) => {
    return team.userIds.every((id) => {
      const userMember = possibleAttendees.find((u) => u.id === id)
      return !userMember || disablePresentMembership(userMember)
    })
  }

  const toggleTeam = ({ checked, teamMembershipIds, membershipType }) => {
    setSelectedMemberships((currentSelectedMemberships) => {
      const updatedMemberships = { ...currentSelectedMemberships }
      teamMembershipIds.forEach((id) => {
        if (typeof updatedMemberships[id] !== "undefined") {
          if (membershipType === MEMBERSHIP_TYPES.accessOnly) {
            updatedMemberships[id] = checked ? membershipType : null
          } else if (membershipType === MEMBERSHIP_TYPES.present) {
            const userMember = possibleAttendees.find((u) => u.id === id)

            if (disablePresentMembership(userMember)) {
              updatedMemberships[id] = checked ? MEMBERSHIP_TYPES.accessOnly : null
            } else {
              updatedMemberships[id] = checked ? MEMBERSHIP_TYPES.present : null
            }
          }
        }
      })
      return updatedMemberships
    })
  }

  const teamsWithPossibleAttendee = teams.filter(({ userIds }) => {
    return userIds.some((userId) => typeof selectedMemberships[userId] !== "undefined")
  })
  const [possibleTeamsToAdd, addedTeams] = partition(
    teamsWithPossibleAttendee,
    ({ userIds }) => {
      return userIds.some((userId) => initialSelectedMemberships[userId] === null)
    }
  )

  return {
    addedTeams,
    possibleTeamsToAdd,
    toggleTeam,
    disablePresentTeam
  }
}
