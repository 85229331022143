import { normalizeUser } from "src/helpers/user"
import { formatTimestamp } from "src/helpers/datetime"

import { DEFAULT_ACTION_TYPE_FILTER } from "./constants"

const collectFiles = (attachedDocuments) =>
  attachedDocuments.map(({ boxFileId, filename }) => ({
    id: boxFileId,
    filename
  }))

export const normalizeMessage = (props) => ({
  ...props,
  fileCollection: collectFiles(props.attachedDocuments),
  createdAt: formatTimestamp(props.createdAt),
  author: normalizeUser(props.author),
  date: props.createdAt
})

export const getActionType = (option) => {
  if (option.value === DEFAULT_ACTION_TYPE_FILTER.value) return

  return option.value
}
