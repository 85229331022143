class @AddNewSchemeUsersComponent
  constructor: () ->
    @modal = $be('add-new-scheme-users-modal')
    @form = $be('add-new-users-form')
    @userListContainer = $be('user-list-container')
    @userList = $be('user-form-list')

  setup: =>
    $be('add-another-user-form-link').click (e) =>
      e.preventDefault()

      rndKey = Math.random().toString(16).substring(2, 10)
      card = $be('additional-user-form-template').clone()

      card.attr('data-id', rndKey)
      card.attr('data-behavior', 'additional-user-form')

      card.find('[data-behavior=first-name-field]').attr(
        'name', "users[#{rndKey}][first_name]"
      )

      card.find('[data-behavior=last-name-field]').attr(
        'name', "users[#{rndKey}][last_name]"
      )

      card.find('[data-behavior=email-field]').attr(
        'name', "users[#{rndKey}][email]"
      )

      card.find('[data-behavior=role-field]').attr(
        'name', "users[#{rndKey}][role]"
      )

      card.find('[data-behavior=remove-additional-user-form]').click (e) ->
        e.preventDefault()
        card.remove()

      @userList.append(card)
      card.show()
      @userListContainer.scrollTop(@userList[0].scrollHeight)
