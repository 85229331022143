import React from "react"
import { styled } from "@linaria/react"
import { Form } from "react-bootstrap"
import { func, string, shape } from "prop-types"

import { BREAKPOINTS, FONT_WEIGHTS, SPACES } from "src/styles/sizes"
import PrimaryButton from "src/styles/components/Button/Primary"
import Icon from "src/styles/components/Icon"

const FilterInput = ({
  className,
  setFilter,
  addButtonCaption = "Add People",
  addButtonProps
}) => {
  return (
    <div className={className}>
      <Form.Control
        placeholder="Filter by name, email or job title..."
        onChange={setFilter}
      />
      {addButtonProps && (
        <PrimaryButton {...addButtonProps}>
          <Icon type="add" />
          <span className="button-caption">{addButtonCaption}</span>
        </PrimaryButton>
      )}
    </div>
  )
}

FilterInput.propTypes = {
  setFilter: func.isRequired,
  addButtonCaption: string,
  addButtonProps: shape({})
}

export default styled(FilterInput)`
  display: flex;
  margin-bottom: ${SPACES.small};

  .form-control {
    padding: 0.8rem;
    line-height: 1.25;
  }

  .btn {
    margin-left: ${SPACES.small};
    display: flex;
    align-items: center;

    .button-caption {
      margin: 0;
      font-weight: ${FONT_WEIGHTS.bold};
      padding-bottom: 1px; // adjust title with icon
    }

    @media (max-width: ${BREAKPOINTS.semiLarge}) {
      .button-caption {
        display: none;
      }

      [class^="icon-"] {
        display: block;
      }
    }
  }
`
