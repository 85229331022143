import React from "react"
import classNames from "classnames"
import { styled } from "@linaria/react"
import { string } from "prop-types"
import { Badge } from "react-bootstrap"

import { composeVariationsClasses } from "src/helpers/linaria"
import { FONT_WEIGHTS } from "src/styles/sizes"
import { BASE_COLORS, BACKGROUND_COLORS } from "src/styles/colors"

const UserBadge = ({ className, children, variant, ...props }) => {
  const classes = classNames(
    "badge-knowa",
    composeVariationsClasses(className, { variant })
  )

  return (
    <Badge className={classes} {...props}>
      {children}
    </Badge>
  )
}

UserBadge.propTypes = {
  className: string,
  variant: string.isRequired
}

export default styled(UserBadge)`
  &.badge-knowa {
    position: absolute;
    top: -1px;
    left: -6px;
    z-index: -1;
    border-radius: 4px;
    color: ${BASE_COLORS.white};
    font-weight: ${FONT_WEIGHTS.bold};
    cursor: pointer;
  }

  &--variant-removed {
    padding: 0 4px 2px 4px;
    background-color: ${BACKGROUND_COLORS.danger};
  }

  &--variant-added {
    padding: 0 3px 2px 3px;
    background-color: ${BACKGROUND_COLORS.success};
  }
`
