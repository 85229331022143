import React from "react"
import { useQuery } from "@apollo/client"
import { number, string } from "prop-types"
import { styled } from "@linaria/react"
import classNames from "classnames"

import {
  BACKLINKS_HEADER_HEIGTH,
  BACKLINKS_WIDTH
} from "src/features/Messages/Drawer/constants"
import { BASE_COLORS, BACKGROUND_COLORS } from "src/styles/colors"
import useIsOpened from "src/hooks/useIsOpened"
import UnstyledButton from "src/components/UnstyledButton"
import { BREAKPOINTS, headerMobileSize } from "src/styles/sizes"

import backlinksQuery from "./discussionBacklinks.gql"
import EntityRow from "./EntityRow"

const Backlinks = ({ discussionId, className }) => {
  const { isOpened, toggle } = useIsOpened()
  const { data, loading } = useQuery(backlinksQuery, {
    variables: {
      discussionId
    }
  })
  if (loading) return null

  const backlinks = data?.discussionBacklinks || []
  const backlinksCount = backlinks.length

  if (backlinksCount === 0) return null

  return (
    <div className={classNames(className, { isOpened })}>
      <UnstyledButton
        className="header-btn"
        disabled={backlinksCount === 0}
        onClick={toggle}
      >
        Backlinks&nbsp;
        <b>({backlinksCount})</b>
      </UnstyledButton>

      {isOpened && (
        <div className="content">
          {backlinks.map((contentLink) => (
            <EntityRow key={contentLink.id} contentLink={contentLink} />
          ))}
        </div>
      )}
    </div>
  )
}

Backlinks.propTypes = {
  discussionId: number.isRequired,
  className: string.isRequired
}

export default styled(Backlinks)`
  position: absolute;
  bottom: 0;
  width: ${BACKLINKS_WIDTH};
  height: ${BACKLINKS_HEADER_HEIGTH};
  background-color: ${BASE_COLORS.white};
  transition: height;
  transition-duration: 0.5s;
  transition-timing-function: ease;

  @media (max-width: ${BREAKPOINTS.small}) {
    width: 100%;
    bottom: ${headerMobileSize};
  }

  .header-btn {
    font-size: 0.95rem;
    padding-left: 15px;
    height: ${BACKLINKS_HEADER_HEIGTH};
    background-color: ${BACKGROUND_COLORS.lightGray};
    width: 100%;

    &:disabled {
      cursor: auto;
    }
    &:hover {
      background-color: ${BACKGROUND_COLORS.lightGray} !important;
    }
  }

  &.isOpened {
    height: 35%;
  }

  .content {
    overflow-y: scroll;
    height: calc(100% - ${BACKLINKS_HEADER_HEIGTH});
  }
`
