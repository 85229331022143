class @DiscussionAttachmentsService
  get: (params) ->
    discussionId = params.discussionId
    page = params.page
    filters = params.filters

    $.get(
      "/discussions/#{discussionId}/attachments",
      { page: page, filters: filters }
    )
