import { styled } from "@linaria/react"

import BaseCopyLink from "src/components/CopyLink"

export const CopyLink = styled(BaseCopyLink)`
  font-size: 16px;
  // This button is rendered inside toolbar of Box preview,
  // Box applies own paddings for buttons in toolbar, it's hard to override them here
  padding-right: 0 !important;
  color: #6e6e6e;
`
