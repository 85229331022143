export class FieldEncodingComponent {
  constructor(params = {}) {
    this.originalFieldSelector = params.originalFieldSelector || '[name="message[body]"]'
    this.encodedFieldSelector =
      params.encodedFieldSelector || '[name="message[encoded_body]"]'
  }

  replaceMessageBody(form) {
    const fields = [...form.querySelectorAll(this.originalFieldSelector)]
    const fieldValue = fields.find((field) => field.value)?.value || ""
    const encodedHiddenFields = [...form.querySelectorAll(this.encodedFieldSelector)]

    encodedHiddenFields.forEach((encodedField) => {
      // eslint-disable-next-line no-param-reassign
      encodedField.value = btoa(encodeURIComponent(fieldValue))
    })

    fields.forEach((field) => {
      // eslint-disable-next-line no-param-reassign
      field.value = ""
    })
  }
}

window.FieldEncodingComponent = FieldEncodingComponent
