import { styled } from "@linaria/react"

export const Container = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
`

export const DropBackground = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(153, 212, 247, 0.7);
  border: 3px dashed #0095ed;
  z-index: 3;
  font-weight: bold;
`
