import React from "react"
import ReactSelect from "react-select"
import { elementType, shape } from "prop-types"

import Control from "./Control"
import MenuList from "./MenuList"
import { selectStyles } from "./styles"

const Select = ({ component: Component, components = {}, styles = {}, ...props }) => {
  return (
    <Component
      maxMenuHeight={150}
      components={{ Control, MenuList, IndicatorSeparator: null, ...components }}
      styles={{ ...selectStyles, ...styles }}
      {...props}
    />
  )
}

Select.propTypes = {
  component: elementType,
  components: shape({}),
  styles: shape({})
}

Select.defaultProps = {
  component: ReactSelect
}

export default Select
