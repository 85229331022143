@$isSafari = () ->
  /safari/i.test(navigator.userAgent)

@$isIOS = () ->
  /ipad|iphone|ipod/i.test(navigator.userAgent)

@$isIpad = () ->
  /ipad/i.test(navigator.userAgent) ||
    (/Macintosh; Intel Mac OS/i.test(navigator.userAgent) && window.orientation != undefined)

@$isTouchDevice = () ->
  typeof document.documentElement.ontouchstart != 'undefined'

@$isMobile = () ->
  (typeof window.orientation != "undefined") ||
    (navigator.userAgent.indexOf('IEMobile') != -1)
