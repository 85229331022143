import { compact } from "lodash"

export const getCurrentUser = () => window.currentUserData

export const getCurrentUserId = () => getCurrentUser()?.id

export const getFullName = (user) => {
  if (!user) return

  return compact([user.firstName, user.lastName]).join(" ")
}

export const getInitials = (user) => {
  if (!user) return

  const nameInitials = compact([user.firstName, user.lastName])
    .map((name) => name[0])
    .join("")
  const emailInitials = user.email
    .split(".")
    .map((part) => part[0])
    .join("")

  return (nameInitials || emailInitials).toUpperCase()
}

export const normalizeUser = (user) => {
  if (!user) return

  return {
    ...user,
    fullName: getFullName(user),
    initials: getInitials(user)
  }
}

export const cacheReloadUuid = () => window.cacheReloadUuid
