import React from "react"
import { func } from "prop-types"

import BoxFilePicker from "src/components/BoxFilePicker"
import { downloadFile } from "src/api/box"
import { disableChooseButton, enableChooseButton } from "src/helpers/box"

const FilePicker = ({ close, uploadDocument, addAttachedDocuments }) => {
  const choseFiles = (files) => {
    disableChooseButton("Uploading...")

    Promise.all(
      files.map(({ authenticated_download_url: authenticatedDownloadUrl, name }) =>
        downloadFile({ authenticatedDownloadUrl })
          .then(({ data }) => uploadDocument({ file: new File([data], name) }))
          .then(({ data }) => ({ name, id: data.id }))
      )
    )
      .then((uploadedFiles) => {
        addAttachedDocuments(uploadedFiles.map(({ id, name }) => ({ id, name })))
        close()
      })
      .catch(() => enableChooseButton("Upload"))
  }

  return (
    <BoxFilePicker
      handleChoose={choseFiles}
      handleCancel={close}
      container="collaborative-documents-file-picker"
    />
  )
}

FilePicker.propTypes = {
  close: func.isRequired,
  uploadDocument: func.isRequired,
  addAttachedDocuments: func.isRequired
}

export default FilePicker
