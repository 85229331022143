import React from "react"
import { arrayOf, bool, oneOfType, shape, string } from "prop-types"
import { styled } from "@linaria/react"

import FieldLabel from "src/features/AirtableApp/Form/FieldLabel"
import { airtableFieldType } from "src/resources/accounts/airtablePropTypes"
import { FONT_SIZES, SPACES } from "src/styles/sizes"

const PlainReadOnly = ({ value, field, invalid, className }) => {
  return (
    <div className={className}>
      <FieldLabel field={field} invalid={invalid} />
      <div className="readOnly-field">{value?.specialValue || value || ""}</div>
    </div>
  )
}

PlainReadOnly.propTypes = {
  field: airtableFieldType.isRequired,
  value: oneOfType([string, shape({}), arrayOf(string)]),
  invalid: bool
}

export default styled(PlainReadOnly)`
  .readOnly-field {
    white-space: pre-wrap;
    font-size: ${FONT_SIZES.small};
    margin-left: ${SPACES.xSmall};
  }
`
