import apiClient from "src/app/apiClient"

const index = (params) => apiClient.get(`/api/tags`, { params })
const update = ({ tagId, data }) => apiClient.patch(`/api/tags/${tagId}`, data)

const tags = {
  index,
  update
}

export default tags
