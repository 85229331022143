import { SIGNED_DOCUMENTS_MODAL_TOGGLE_EVENT } from "src/features/signedDocuments/constants"

export class FroalaSignedDocumentComponent {
  setup = () => {
    const { FroalaEditor } = window

    FroalaEditor.DefineIcon("signedDocument", {
      NAME: "sign",
      template: "knowa"
    })

    FroalaEditor.RegisterCommand("signedDocument", {
      title: "eSignature document",
      focus: true,
      undo: true,
      refreshAfterCallback: true,
      callback: () => {
        window.dispatchEvent(new Event(SIGNED_DOCUMENTS_MODAL_TOGGLE_EVENT))
      }
    })
  }
}
