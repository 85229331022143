import React from "react"
import { arrayOf, number, shape, string } from "prop-types"
import { Form } from "react-bootstrap"
import { uniq } from "lodash"
import { useFormikContext } from "formik"
import { styled } from "@linaria/react"
import classNames from "classnames"

import Checkbox from "src/styles/components/Checkbox"
import { BASE_COLORS } from "src/styles/colors"

const AppInstancesList = ({ appInstances, className }) => {
  const { values, setFieldValue } = useFormikContext()

  const appInstanceEnabled = (appInstanceId) =>
    values.enabledAppInstanceIds?.includes(appInstanceId)

  const checkAppInstance = (appInstanceId, e) => {
    if (e.target.checked)
      setFieldValue(
        "enabledAppInstanceIds",
        uniq([...values.enabledAppInstanceIds, appInstanceId])
      )
    else
      setFieldValue(
        "enabledAppInstanceIds",
        values.enabledAppInstanceIds.filter((id) => id !== appInstanceId)
      )
  }

  return (
    <div className={classNames("card mt-1", className)}>
      <Form.Label>Available Apps</Form.Label>

      {appInstances.map((appInstance) => (
        <div
          key={appInstance.id}
          className="py-2 px-3 mb-2 card horizontal"
          data-behavior="app-instance-option-card"
        >
          <div className="card-title">{appInstance.name}</div>
          <div className="card-controls">
            <Checkbox
              id={`app-instance-option-${appInstance.id}`}
              onChange={(e) => checkAppInstance(appInstance.id, e)}
              checked={appInstanceEnabled(appInstance.id)}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

AppInstancesList.propTypes = {
  appInstances: arrayOf(shape({ id: number.isRequired, name: string.isRequired }))
}

export default styled(AppInstancesList)`
  .card.horizontal {
    border: 1px solid ${BASE_COLORS.lightGray};

    .card-controls {
      flex-basis: 0;
    }
  }
`
