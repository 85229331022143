class @TpFundingPositionComponent
  constructor: (params) ->
    @data = params.data.points
    @ticks = params.data.ticks

    @elementId = params.elementId

  setup: () ->
    deficitColor = '#6C94CA'
    fundingRatioColor = '#60A366'

    deficit = {
      x: @data.deficit.date,
      y: @data.deficit.value,
      hoverinfo: 'text',
      hovertext: @data.deficit.value.map((val) -> $formattedSum(val)),
      hoverlabel: { font: { color: '#FFF'}},
      name: 'TP deficit',
      line: {
        color: deficitColor
      }
    }

    deficitObjective = {
      x: @data.deficit_objective.date,
      y: @data.deficit_objective.value,
      hoverinfo: 'text',
      hovertext: @data.deficit_objective.value.map((val) -> $formattedSum(val)),
      hoverlabel: { font: { color: '#FFF'}},
      name: 'TP deficit objective',
      mode: 'lines',
      line: {
        color: deficitColor
        dash: 'dot',
        width: 2
      }
    }

    fundingRatio = {
      x: @data.funding_ratio.date,
      y: @data.funding_ratio.value,
      hoverinfo: 'text',
      hovertext: @data.funding_ratio.value.map((val) -> $percent(Math.round(val))),
      hoverlabel: { font: { color: '#FFF'}},
      name: 'TP funding ratio',
      yaxis: 'y2',
      line: {
        color: fundingRatioColor
      }
    }

    fundingRatioObjective = {
      x: @data.funding_ratio_objective.date,
      y: @data.funding_ratio_objective.value,
      hoverinfo: 'text',
      hovertext: @data.funding_ratio_objective.value.map((val) -> $percent(Math.round(val))),
      hoverlabel: { font: { color: '#FFF'}},
      name: 'TP funding ratio objective',
      yaxis: 'y2',
      mode: 'lines',
      line: {
        color: fundingRatioColor
        dash: 'dot',
        width: 2
      }
    }

    layout = {
      hoverinfo: 'none',
      legend: {
        orientation: 'h',
        traceorder: 'normal'
      },
      xaxis: {
        type: 'date'
      },
      yaxis: {
        title: 'TP deficit (£m)',
        tickvals: @ticks.deficit,
        fixedrange: true,
        tickfont: {
          color: deficitColor
        },
        range: [@ticks.deficit[0], @ticks.deficit[@ticks.deficit.length - 1]]
      },
      yaxis2: {
        title: 'TP funding ratio (%)',
        tickvals: @ticks.funding_ratio,
        ticktext: @ticks.funding_ratio.map((val) -> $percent(val)),
        zeroline: false,
        showgrid: false,
        overlaying: 'y',
        side: 'right',
        fixedrange: true,
        tickfont: {
          color: fundingRatioColor
        },
        constraintoward: 'bottom',
        range: [
          @ticks.funding_ratio[0],
          @ticks.funding_ratio[@ticks.funding_ratio.length - 1]
        ]
      },
      margin: {
        t: 15
      }
    };

    data = [deficit, deficitObjective, fundingRatio, fundingRatioObjective]

    Plotly.newPlot(
      @elementId,
      data,
      layout,
      { displayModeBar: false, responsive: true }
    )
