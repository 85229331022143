import React, { useState, useCallback } from "react"
import { Dropdown } from "react-bootstrap"
import { shape, number, string, func } from "prop-types"
import { useLazyQuery } from "@apollo/client"

import useIsOpened from "src/hooks/useIsOpened"

import PublishModal from "./PublishModal"
import SuccessModal from "./SuccessModal"
import notAssignedAttendeesQuery from "./notAssignedAttendeesQuery.gql"

const publishedStatus = "published"

const PublishButton = ({ meetingPack, publishMeetingPack, unpublishMeetingPack }) => {
  const [loadNotAssignedAttendees, { data }] = useLazyQuery(notAssignedAttendeesQuery)

  const {
    isOpened: isPublishOpened,
    open: openPublish,
    close: closePublish
  } = useIsOpened()

  const {
    isOpened: isSuccessOpened,
    open: openSuccess,
    close: closeSuccess
  } = useIsOpened()

  const [customMessage, setCustomMessage] = useState()
  const onCustomMessageChange = (event) => setCustomMessage(event.target.value)

  const isPublished = meetingPack.publicationStatus === publishedStatus
  const notAssignedAttendees = data?.notAssignedAttendees || []

  const handleModalOpen = () => {
    loadNotAssignedAttendees({
      variables: { meetingPackId: meetingPack.id },
      fetchPolicy: "no-cache"
    })

    openPublish()
  }

  const onPublish = useCallback(() => {
    return publishMeetingPack({
      meetingPackId: meetingPack.id,
      customMessage
    })
      .then(() => {
        closePublish()
        openSuccess()
      })
      .catch(closePublish)
  }, [publishMeetingPack, meetingPack.id, customMessage])

  const onUnpublish = () =>
    unpublishMeetingPack({
      meetingPackId: meetingPack.id,
      confirmationMessage:
        "Are you sure you wish to unpublish this meeting pack? " +
        "Attendees will immediately lose access to the pack."
    })

  return (
    <>
      <Dropdown.Item onClick={isPublished ? onUnpublish : handleModalOpen}>
        {isPublished ? "Unpublish" : "Publish"}
      </Dropdown.Item>
      <PublishModal
        isOpened={isPublishOpened}
        onClose={closePublish}
        onPublish={onPublish}
        onCustomMessageChange={onCustomMessageChange}
        notAssignedAttendees={notAssignedAttendees}
      />
      <SuccessModal
        isOpened={isSuccessOpened}
        onClose={closeSuccess}
        meetingPackId={meetingPack.id}
      />
    </>
  )
}

PublishButton.propTypes = {
  meetingPack: shape({
    id: number.isRequired,
    publicationStatus: string.isRequired
  }).isRequired,
  publishMeetingPack: func.isRequired,
  unpublishMeetingPack: func.isRequired
}

export default PublishButton
