import { parse } from "qs"
import { createSlice } from "@reduxjs/toolkit"

import meetingPacks from "src/api/meetingPacks"
import { camelcaseKeys } from "src/helpers/object"
import { showFlashMessage } from "src/helpers/flash"
import { setParamsToUrl } from "src/helpers/url"
import { clearOffline } from "src/features/offline/helpers/meetingPacks"

import {
  ITEM_ID_URL_KEY,
  DOCUMENT_ID_URL_KEY,
  DOCUMENT_TYPE,
  AGENDA_ITEM_TYPE
} from "./constants"
import {
  findAgendaItem,
  findActiveItemIdByDocument,
  findFirstAttendingAgendaItemId,
  findNextNavigationResource,
  findPreviousNavigationResource,
  saveMeetingPackVisit,
  getMeetingPackVisit
} from "./helpers"
import {
  getAgendaItems,
  getActiveItemId,
  getActiveDocumentId,
  getMeetingPack,
  getMeetingPackId,
  getDocuments
} from "./selectors"

const meetingPackSlice = createSlice({
  name: "meetingPack",
  initialState: {
    meetingPack: {},
    memberships: [],
    attendees: [],
    teams: [],
    agendaItems: [],
    currentUser: {},
    unreadItems: [],
    tags: [],
    activeItemId: null,
    activeDocument: {
      id: null,
      data: {}
    },
    currentPage: null,
    attendeesChanges: {}
  },
  reducers: {
    setInitialState(state, { payload }) {
      state.meetingPack = payload.meetingPack
      state.memberships = payload.memberships
      state.attendees = payload.attendees
      state.agendaItems = payload.agendaItems
      state.currentUser = payload.currentUser
      state.unreadItems = payload.unreadItems
      state.tags = payload.tags
      state.teams = payload.teams
      state.activeItemId = payload.activeItemId
      state.attendeesChanges = payload.attendeesChanges
    },
    setActiveItemAndDocument(state, action) {
      const { itemId, documentId, page, highlighted } = action.payload
      const { agendaItems } = state
      const item = findAgendaItem(
        agendaItems,
        itemId ||
          findActiveItemIdByDocument({
            agendaItems,
            documentId
          })
      )

      if (item?.attending) {
        state.activeItemId = itemId || null
        state.activeDocument.id = documentId || null
        state.highlighted = highlighted || null

        const currentPage = page ? Number(page) : null
        state.page = currentPage
        state.currentPage = currentPage
      } else {
        state.activeItemId = null
        state.activeDocument.id = null
        state.activeDocument.data = {}
        state.currentPage = null
      }
    },
    setCurrentPage(state, action) {
      const { currentPage } = action.payload
      state.currentPage = currentPage || null
    },
    updateDocumentAnnotations(state, { payload }) {
      const { itemId, documentId, incBy } = payload
      const { agendaItems } = state
      const item = findAgendaItem(agendaItems, itemId)
      const doc = item.documents.find(({ id }) => id === documentId)
      doc.annotationsCount += incBy
    },
    setDefaultIds(state) {
      if (state.activeItemId !== null) {
        return
      }

      if (state.activeDocument.id !== null) {
        const itemId = findActiveItemIdByDocument({
          agendaItems: state.agendaItems,
          documentId: state.activeDocument.id
        })
        state.activeItemId = itemId || null
      } else {
        state.activeItemId = findFirstAttendingAgendaItemId(state.agendaItems)
      }
    },
    setMeetingPack(state, action) {
      const payload = camelcaseKeys(action.payload)

      state.meetingPack = payload.meetingPack
    },
    setAgendaItems(state, action) {
      const agendaItems = camelcaseKeys(action.payload)
      const activeItem = agendaItems.find(({ id }) => id === state.activeItemId)

      if (!activeItem) {
        state.activeItemId = agendaItems[0]?.id
        state.activeDocument.id = null
        state.activeDocument.data = {}
      }

      state.agendaItems = agendaItems
    },
    setAgendaItemAttendeesChanges(state, { payload }) {
      state.attendeesChanges = payload
    },
    setAgendaItemStatus(state, action) {
      const payload = camelcaseKeys(action.payload)
      const agendaItem = findAgendaItem(state.agendaItems, payload.id)
      agendaItem.status = payload.status
    },
    setAgendaItemMemberships(state, { payload }) {
      const agendaItem = findAgendaItem(state.agendaItems, payload.id)
      agendaItem.memberships = payload.memberships || []
      state.attendeesChanges = payload.attendeesChanges
    },
    updateMeetingPack(state, action) {
      const updates = action.payload

      if (!(updates && typeof updates === "object")) {
        return
      }

      state.meetingPack = { ...state.meetingPack, ...updates }
    },
    setUnreadItems(state, { payload }) {
      state.unreadItems = payload
    },
    removeUnreadItem(state, { payload: { id } }) {
      state.unreadItems = state.unreadItems.filter((unreadItem) => unreadItem.id !== id)
    }
  }
})

const { actions, reducer } = meetingPackSlice

export const {
  setInitialState,
  setActiveItemAndDocument,
  setCurrentPage,
  setDefaultIds,
  setMeetingPack,
  updateMeetingPack,
  setAgendaItemStatus,
  setAgendaItemMemberships,
  setAgendaItems,
  updateDocumentAnnotations,
  setUnreadItems,
  removeUnreadItem,
  setAgendaItemAttendeesChanges
} = actions

export default reducer

export const setInitialStateFromProps = (props) => (dispatch) => {
  dispatch(setInitialState(props))
}

const findItemIdFromUrl = (parsedQuery, agendaItems) => {
  const itemId = Number(parsedQuery[ITEM_ID_URL_KEY]) || null

  const item = findAgendaItem(agendaItems, itemId)
  if (item?.attending === true) {
    return itemId
  }

  if (item?.attending === false) {
    showFlashMessage("warning", "You have no access to this resource.")
  }

  return null
}

const findDocumentIdAndItemIdFromUrl = (parsedQuery, agendaItems) => {
  const documentId = Number(parsedQuery[DOCUMENT_ID_URL_KEY]) || null

  const item = findAgendaItem(
    agendaItems,
    findActiveItemIdByDocument({
      agendaItems,
      documentId
    })
  )

  if (item?.attending === true) {
    return { documentId, itemId: item.id }
  }

  return { documentId: null, itemId: null }
}

export const setInitialStateFromUrl = () => (dispatch, getState) => {
  const parsedQuery = parse(window.location.search, { ignoreQueryPrefix: true })
  const state = getState()
  const agendaItems = getAgendaItems(state)
  const documents = getDocuments(state)
  const meetingPack = getMeetingPack(state)
  const itemIdFromUrl = findItemIdFromUrl(parsedQuery, agendaItems)
  const firstAttendingAgendaItemId = findFirstAttendingAgendaItemId(agendaItems)

  const { itemId, documentId } = findDocumentIdAndItemIdFromUrl(parsedQuery, agendaItems)

  let { itemId: savedItemId, documentId: savedDocumentId } = getMeetingPackVisit({
    meetingPackId: meetingPack.id
  })

  savedItemId = agendaItems.find(({ id }) => id === savedItemId) ? savedItemId : null
  savedDocumentId = documents.find(({ id }) => id === savedDocumentId)
    ? savedDocumentId
    : null

  dispatch(
    setActiveItemAndDocument({
      itemId: itemId || itemIdFromUrl || savedItemId || firstAttendingAgendaItemId,
      documentId: documentId || (itemIdFromUrl ? null : savedDocumentId),
      page: parsedQuery.page,
      highlighted: parsedQuery.highlighted
    })
  )
}

export const initState = (props) => (dispatch) => {
  dispatch(setInitialStateFromProps(props))
  dispatch(setInitialStateFromUrl(props))
  dispatch(setDefaultIds())
}

export const readUserItem =
  ({ itemId, itemType }) =>
  (dispatch, getState) => {
    const {
      unreadItems,
      meetingPack: { id: meetingPackId }
    } = getState().meetingPack
    const unreadItem = unreadItems.find(
      (item) => item.itemType === itemType && item.itemId === itemId
    )

    if (!unreadItem) {
      return
    }

    return meetingPacks.unreadUserItems
      .destroy({ meetingPackId, id: unreadItem.id })
      .then(() => dispatch(removeUnreadItem({ id: unreadItem.id })))
  }

export const setActiveItem = (itemId) => (dispatch, getState) => {
  const {
    meetingPack: { meetingPack }
  } = getState()

  saveMeetingPackVisit({ meetingPackId: meetingPack.id, itemId })

  dispatch(readUserItem({ itemId, itemType: AGENDA_ITEM_TYPE }))

  dispatch(
    setActiveItemAndDocument({
      itemId
    })
  )

  setParamsToUrl({ [ITEM_ID_URL_KEY]: itemId })
}

export const setActiveDocument = (documentId) => (dispatch, getState) => {
  const {
    meetingPack: { meetingPack, agendaItems }
  } = getState()

  const itemId = findActiveItemIdByDocument({ documentId, agendaItems })

  saveMeetingPackVisit({ meetingPackId: meetingPack.id, itemId, documentId })

  dispatch(readUserItem({ itemId: documentId, itemType: DOCUMENT_TYPE }))

  dispatch(
    setActiveItemAndDocument({
      itemId,
      documentId
    })
  )

  dispatch(readUserItem({ itemId, itemType: AGENDA_ITEM_TYPE }))

  setParamsToUrl({ [DOCUMENT_ID_URL_KEY]: documentId })
}

export const setActiveDocumentPage = (currentPage) => (dispatch) => {
  dispatch(
    setCurrentPage({
      currentPage
    })
  )
}

export const incrementDocumentAnnotations =
  ({ documentId, incBy = +1 }) =>
  (dispatch, getState) => {
    const {
      meetingPack: { agendaItems }
    } = getState()

    const itemId = findActiveItemIdByDocument({ documentId, agendaItems })
    dispatch(updateDocumentAnnotations({ documentId, incBy, itemId }))
  }

const goToResource = (findResource) => (dispatch, getState) => {
  const state = getState()
  const agendaItems = getAgendaItems(state)
  const activeItemId = getActiveItemId(state)
  const activeDocumentId = getActiveDocumentId(state)

  const resource = findResource({
    agendaItems,
    activeItemId,
    activeDocumentId
  })

  if (resource.document) {
    dispatch(setActiveDocument(resource.document.id))
  } else if (resource.agendaItem) {
    dispatch(setActiveItem(resource.agendaItem.id))
  }
}

export const goBack = () => (dispatch) => {
  dispatch(goToResource(findPreviousNavigationResource))
}

export const goForward = () => (dispatch) => {
  dispatch(goToResource(findNextNavigationResource))
}

export const clearMeetingPackOfflineCache = () => (dispatch, getState) => {
  const state = getState()
  const meetingPackId = getMeetingPackId(state)

  return clearOffline({ meetingPackId }).then(() => {
    dispatch(updateMeetingPack({ savedOffline: false }))
  })
}
