import { useEffect } from "react"

import { SIDEBAR_CLOSED_EVENT } from "./constants"

const useSidebarClosed = (handler, deps = []) => {
  useEffect(() => {
    window.addEventListener(SIDEBAR_CLOSED_EVENT, handler)

    return () => window.removeEventListener(SIDEBAR_CLOSED_EVENT, handler)
  }, deps)
}

export default useSidebarClosed
