import React from "react"
import { styled } from "@linaria/react"
import classNames from "classnames"
import { bool, func } from "prop-types"

import { BORDER_COLORS } from "src/styles/colors"

const Switch = ({ switchedOn, setSwitchedOn, className }) => {
  return (
    <div className={classNames("btn-group", className)}>
      <button
        className={classNames("btn", switchedOn ? "btn-outline-primary" : "btn-primary")}
        onClick={() => setSwitchedOn(false)}
        type="button"
      >
        No
      </button>
      <button
        className={classNames("btn", switchedOn ? "btn-primary" : "btn-outline-primary")}
        onClick={() => setSwitchedOn(true)}
        type="button"
      >
        Yes
      </button>
    </div>
  )
}

Switch.propTypes = {
  switchedOn: bool.isRequired,
  setSwitchedOn: func.isRequired
}

export default styled(Switch)`
  .btn {
    width: 65px;
  }

  .btn-outline-primary:hover {
    background-color: ${BORDER_COLORS.button};
  }
`
