import React, { useState } from "react"
import classNames from "classnames"
import { number } from "prop-types"

import useToggle from "src/hooks/useToggle"
import { mainContentWidth } from "src/helpers/document"
import useResizeObserver from "src/hooks/useResizeObserver"

import { ViewContainer } from "./styles"
import ContentsSidebar from "./ContentsSidebar"
import Content from "./Content"

const ContentContainer = ({ meetingPackId }) => {
  const isTabletScreen = window.matchMedia("(max-width: 1024px)").matches
  const [isSidebarOpened, toggleSidebar] = useToggle(
    `contents-sidebar/${meetingPackId}`,
    !isTabletScreen
  )
  const [isFullWidth, setFullWidth] = useState(false)

  useResizeObserver({
    selector: "[data-behavior=content]",
    handler: () => setFullWidth(mainContentWidth() < 880)
  })

  return (
    <ViewContainer
      className={classNames({
        "sidebar-opened": isSidebarOpened,
        "full-width": isFullWidth
      })}
    >
      <ContentsSidebar opened={isSidebarOpened} toggleSidebar={toggleSidebar} />
      <Content />
    </ViewContainer>
  )
}

ContentContainer.propTypes = {
  meetingPackId: number
}

export default ContentContainer
