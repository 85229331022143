import React from "react"
import { string } from "prop-types"
import { styled } from "@linaria/react"
import { upperFirst } from "lodash"

import { composeVariationsClasses } from "src/helpers/linaria"
import { accountAdminColor, accountGuestColor } from "src/styles/colors"
import { withArticle } from "src/helpers/string"

const RoleSubheader = ({ className, firstName, role, meetingPackName }) => {
  const classNameWithVariations = composeVariationsClasses(className, { role })

  return (
    <div className={classNameWithVariations}>
      {firstName} is {withArticle(upperFirst(role))} of {meetingPackName}
    </div>
  )
}

RoleSubheader.propTypes = {
  firstName: string.isRequired,
  role: string.isRequired,
  meetingPackName: string.isRequired
}

export default styled(RoleSubheader)`
  padding: 0.75rem 1.25rem;
  border-bottom: 1px solid #dee2e6;

  &--role-admin {
    background-color: ${accountAdminColor};
  }

  &--role-guest {
    background-color: ${accountGuestColor};
  }
`
