export const PER_PAGE_LIMIT = 20

export const BOX_COMPONENTS = {
  filePicker: "file-picker"
}

export const BOX_ROOT_FOLDER_ID = "0"

export const BOX_API_HOST = "/box/api"
export const BOX_STATIC_HOST = "/box/cdn"
window.BOX_API_HOST = BOX_API_HOST // used in /legacy/helpers/helpers.js.coffee

export const { BOX_UPLOAD_URLS } = window

export const KEY_CODES = {
  leftArrow: 37,
  upArrow: 38,
  rightArrow: 39,
  downArrow: 40,
  enter: 13
}
