import React, { forwardRef } from "react"
import classNames from "classnames"
import { styled } from "@linaria/react"
import { oneOf, string } from "prop-types"

import { composeVariationsClasses } from "src/helpers/linaria"
import { FONT_SIZES } from "src/styles/sizes"

const Icon = forwardRef(({ className, type, size = "", ...props }, ref) => {
  const classes = classNames(
    `icon-${type}`,
    composeVariationsClasses(className, { size })
  )

  return <span className={classes} ref={ref} {...props} />
})

Icon.propTypes = {
  size: oneOf(["", "small", "medium", "large"]),
  type: string.isRequired
}

export default styled(Icon)`
  &--size-small {
    font-size: ${FONT_SIZES.medium};
  }

  &--size-medium {
    font-size: ${FONT_SIZES.large};
  }

  &--size-large {
    font-size: ${FONT_SIZES.xLarge};
  }
`
