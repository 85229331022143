class @ChatBadgesComponent
  constructor: (chatsStats, navbarComponent, sidebarComponent) ->
    @messageStats = chatsStats || {}

  increaseCount: (chatId) =>
    if @messageStats[chatId]
      @messageStats[chatId]++
    else
      @messageStats[chatId] = 1

  hasMessages: =>
    !!Object.keys(@messageStats).length

