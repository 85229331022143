import { useRef } from "react"
import { useDrop } from "react-dnd"

import { ITEM_TYPE } from "./constants"

const useDropOut = ({ transcriptItems, setTranscriptItems, handleSave }) => {
  const ref = useRef(null)

  const [{ handlerId }, drop] = useDrop({
    accept: ITEM_TYPE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      }
    },
    drop(item) {
      const newTranscriptItems = transcriptItems.filter((el) =>
        item.agendaItemId ? el.agendaItemId !== item.agendaItemId : el.uid !== item.uid
      )

      setTranscriptItems(newTranscriptItems)
      handleSave(newTranscriptItems)
    }
  })

  drop(ref)

  return { ref, handlerId, drop }
}

export default useDropOut
