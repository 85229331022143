import React from "react"
import { pick, omit } from "lodash"
import classNames from "classnames"
import kebabCase from "lodash/kebabCase"

export const composeVariationsClasses = (className, variations = {}) => {
  return classNames(
    className,
    Object.keys(variations)
      .filter((key) => !!variations[key])
      .map((key) => {
        if (typeof variations[key] === "boolean") {
          return `${className}--${kebabCase(key)}`
        }
        if (typeof variations[key] === "string") {
          return `${className}--${kebabCase(key)}-${variations[key]}`
        }
        return ""
      })
  )
}

export const withVariations = (Component, ...variations) => {
  const ComponentWithVariations = ({ className, ...props }) => {
    return (
      <Component
        className={composeVariationsClasses(className, pick(props, variations))}
        {...omit(props, variations)}
      />
    )
  }

  ComponentWithVariations.displayName = `${
    Component.displayName || Component
  }WithVariations`

  return ComponentWithVariations
}
