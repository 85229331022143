import React, { useCallback, useEffect } from "react"
import useInfiniteScroll from "react-infinite-scroll-hook"
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import { string, number } from "prop-types"

import Message from "src/features/Home/Messages/Message"
import ContentLoader from "src/styles/components/ContentLoader"
import EmptyContent from "src/styles/components/EmptyContent"
import useAblyChannel from "src/hooks/useAblyChannel"
import { PER_PAGE_LIMIT } from "src/constants"
import { resetState } from "src/resources/starredItems/slice"
import { fetchStarredItems } from "src/resources/starredItems/thunks"
import {
  getStarredItems,
  selectAllStarredItems
} from "src/resources/starredItems/selectors"
import { useMessageChangeHandlers } from "src/features/Home/Messages/handlers"
import { CHANNELS } from "src/constants/ably"

const StarredItemsList = ({ actionType, accountId, schemeId }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetState())
  }, [actionType, accountId, schemeId, dispatch])

  const {
    allIds: starredItemIds,
    hasNextPage,
    isLoading
  } = useSelector(getStarredItems, shallowEqual)
  const starredItems = useSelector(selectAllStarredItems, shallowEqual)
  const handleLoadMore = useCallback(() => {
    dispatch(
      fetchStarredItems({
        page: { offset: starredItemIds.length, limit: PER_PAGE_LIMIT },
        filter: { actionType, accountId, schemeId }
      })
    )
  }, [starredItemIds, dispatch])

  const [infiniteRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage,
    onLoadMore: handleLoadMore
  })

  const { handleMessageChange, handleMessageStatusChange, handleReadMessage } =
    useMessageChangeHandlers(starredItemIds)

  useAblyChannel(CHANNELS.users.starredItemsStatus, {
    onMessage: handleMessageStatusChange
  })
  useAblyChannel(CHANNELS.discussions.updates, { onMessage: handleMessageChange })
  useAblyChannel(CHANNELS.chats.updates, { onMessage: handleMessageChange })
  useAblyChannel(CHANNELS.messages, { onMessage: handleReadMessage })

  if (!isLoading && !hasNextPage) {
    if (!starredItemIds.length) {
      return <EmptyContent>You haven’t starred any items yet.</EmptyContent>
    }
  }

  return (
    <div data-behavior="starred-items-list">
      {starredItems.map((starredItem) => (
        <Message key={starredItem.id} message={starredItem} />
      ))}
      <div ref={infiniteRef} />
      {isLoading && <ContentLoader />}
    </div>
  )
}

StarredItemsList.propTypes = {
  actionType: string,
  accountId: number,
  schemeId: number
}

export default StarredItemsList
