import apiClient from "src/app/apiClient"

const publish = ({ meetingPackId, customMessage }) =>
  apiClient.put(`/meeting_packs/${meetingPackId}/publish`, {
    publish: { custom_message: customMessage }
  })

const unpublish = ({ meetingPackId }) =>
  apiClient.put(`/meeting_packs/${meetingPackId}/unpublish`)

const fetchAgendaItems = ({ meetingPackId }) =>
  apiClient.get(`/api/meeting_packs/${meetingPackId}/agenda_items`)

const fetchUnreadItems = ({ meetingPackId }) =>
  apiClient.get(`/api/meeting_packs/${meetingPackId}/unread_items`)

export default {
  publish,
  unpublish,
  fetchAgendaItems,
  fetchUnreadItems
}
