import { Modes, Commands } from "../constants"

import initialState from "./initialState"

const setObject = (state, action) => {
  const { objectId, objectType, objectTitle } = action.payload

  state.objectId = objectId
  state.objectType = objectType
  state.objectTitle = objectTitle
}

export const summariseAsTextInSidebar = (state, action) => {
  setObject(state, action)

  state.mode = Modes.Sidebar
  state.command = Commands.SummariseAsText
  state.commandResult = null
}

export const summariseAsBulletsInSidebar = (state, action) => {
  setObject(state, action)

  state.mode = Modes.Sidebar
  state.command = Commands.SummariseAsBullets
  state.commandResult = null
}

export const queryInSidebar = (state, action) => {
  setObject(state, action)

  state.mode = Modes.Sidebar
  state.command = Commands.Query
  state.commandResult = null
}

export const generateDraftMinutesInSidebar = (state, action) => {
  setObject(state, action)

  state.mode = Modes.Sidebar
  state.command = Commands.GenerateDraftMinutes
  state.commandResult = null
}

export const setCommandResult = (state, action) => {
  state.commandResult = action.payload
}

export const close = (_state) => initialState
