import React from "react"
import { shape, string, number } from "prop-types"
import { styled } from "@linaria/react"

import {
  DrawerItem,
  DrawerItemWrapper,
  EntityName
} from "src/features/Messages/Drawer/styles"
import Icon from "src/styles/components/Icon"
import { DRAWER_CONTENT_WIDTH } from "src/features/Messages/Drawer/constants"
import { TEXT_COLORS } from "src/styles/colors"

const EntityRow = ({ contentLink, className }) => {
  const { id, sourceTitle, sourceUrl, sourceType, metadata } = contentLink

  const ICON_TYPES = {
    "MeetingPacks::AgendaItem": "list-numbered",
    Message: "discussion-message"
  }

  return (
    <DrawerItem data-id={id}>
      <a href={sourceUrl}>
        <DrawerItemWrapper className="text-link">
          <Icon type={ICON_TYPES[sourceType]} size="medium" className="mr-2" />
          <div className="entity-name">
            <EntityName>{sourceTitle}</EntityName>
            <div className={className}>{metadata}</div>
          </div>
        </DrawerItemWrapper>
      </a>
    </DrawerItem>
  )
}

EntityRow.propTypes = {
  contentLink: shape({
    id: number.isRequired,
    sourceTitle: string.isRequired,
    sourceUrl: string.isRequired,
    sourceType: string.isRequired,
    metadata: string.isRequired
  }).isRequired,
  className: string.isRequired
}

export default styled(EntityRow)`
  font-size: 0.85rem;
  max-width: ${DRAWER_CONTENT_WIDTH};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${TEXT_COLORS.muted};
`
