import { compact } from "lodash"

import buildAnnotationButton from "src/components/pspsdfkitButtons/AnnotationButton/buildAnnotationButton"
import buildThumbnailsButton from "src/components/pspsdfkitButtons/ThumbnailsButton/buildThumbnailsButton"
import buildFullscreenButton from "src/components/pspsdfkitButtons/FullscreenButton/buildFullscreenButton"
import { currentPageIndexKey } from "src/features/meetingPacks/MeetingPack/helpers"

import { hideButtonsInOfflineMode, showButtonsInOnlineMode } from "./createOfflineHandler"

const createModifyEditorView =
  ({ highlighted, documentId, page, customToolbarButtons }) =>
  (instance) => {
    instance.setToolbarItems((items) => {
      return compact([
        ...customToolbarButtons,
        ...items,
        buildAnnotationButton(instance),
        buildThumbnailsButton(instance),
        { type: "export-pdf" },
        { type: "search" },
        buildFullscreenButton(instance)
      ])
    })

    instance.setViewState((viewState) => {
      if (typeof page === "number") {
        const currentPageIndex = Math.max(page - 1, 0)
        localStorage.setItem(currentPageIndexKey(documentId), currentPageIndex)
        return viewState
          .set("sidebarPlacement", window.PSPDFKit.SidebarPlacement.END)
          .set("currentPageIndex", currentPageIndex)
      }

      return viewState.set("sidebarPlacement", window.PSPDFKit.SidebarPlacement.END)
    })

    // Handle switch between online/offline mode
    instance.addEventListener("viewState.change", (viewState) => {
      if (viewState.readOnly) {
        hideButtonsInOfflineMode({ instance })
      } else {
        showButtonsInOnlineMode({ instance, customToolbarButtons })
      }
    })

    if (!navigator.onLine) {
      hideButtonsInOfflineMode({ instance })
    }

    if (highlighted) {
      instance
        .search(highlighted, {
          startPageIndex: page - 1,
          endPageIndex: page - 1
        })
        .then((results) => {
          instance.setSearchState((searchState) => {
            return searchState.set("results", results)
          })
        })
    }

    return instance
  }

export default createModifyEditorView
