import React from "react"
import { styled } from "@linaria/react"

import Icon from "src/styles/components/Icon"

const commonIconStyles = {
  marginLeft: "5px"
}

const BaseIcon = (props) => <Icon type="dropdown-arrow" {...props} />

export const IconDown = styled(BaseIcon)`
  ${commonIconStyles}

  position: relative;
  top: 2px;
`

export const IconUp = styled(BaseIcon)`
  ${commonIconStyles}

  transform: rotate(180deg);
`
