import React, { useEffect, useState, useRef } from "react"
import { arrayOf, number, shape, string } from "prop-types"
import { styled } from "@linaria/react"
import classNames from "classnames"
import { truncate } from "lodash"

import { airtableTableType } from "src/resources/accounts/airtablePropTypes"
import { FONT_SIZES, SPACES } from "src/styles/sizes"
import ContentLoader from "src/styles/components/ContentLoader"

import useAirtableApi from "./useAirtable"
import usePageMetadata from "./usePageMetadata"

const localStoragePositionKey = "appRecordListPosition"

const AirtableApp = ({
  pageId,
  baseId,
  tableId,
  cardFields,
  schemeId,
  pageMetadata,
  className
}) => {
  const { allFields, primaryField } = usePageMetadata(pageMetadata)
  const [allRecords, setAllRecords] = useState([])
  const [loading, setLoading] = useState(true)
  const { table } = useAirtableApi({ pageId, schemeId, baseId, tableId })
  const componentRef = useRef()

  useEffect(() => {
    table
      .select()
      .firstPage()
      .then((records) => {
        if (records.length) setAllRecords(records)
      })
      .finally(() => setLoading(false))
  }, [])

  const getScrollTop = () => componentRef.current.closest(".fluid-container").scrollTop
  const setScrollTop = (value) => {
    componentRef.current.closest(".fluid-container").scrollTop = value
  }

  useEffect(() => {
    const scrollTo = localStorage.getItem(localStoragePositionKey)
    if (componentRef.current && scrollTo) {
      setScrollTop(scrollTo)
      localStorage.removeItem(localStoragePositionKey)
    }
  }, [componentRef.current, loading])

  const getFieldName = (field) => allFields[field.id]?.name

  if (loading) return <ContentLoader />

  return (
    <div ref={componentRef} className={classNames("container pb-4", className)}>
      {!allRecords.length && (
        <div className="card mt-4 text-center">
          There is currently nothing for you to see here.
        </div>
      )}

      {allRecords.map((record) => (
        <div
          className="card"
          key={record.id}
          onClick={() => localStorage.setItem(localStoragePositionKey, getScrollTop())}
          aria-hidden="true"
        >
          <a href={`${window.location.href}/${record.id}`} className="text-dark">
            <div className="card-title">
              <h5>{record.fields[primaryField.id]}</h5>
            </div>
            <div className="mt-2 d-flex justify-content-between">
              {cardFields.map((cardField) => (
                <div key={cardField.id}>
                  <div className="text-muted field-title">{getFieldName(cardField)}</div>
                  <div className="field-value">
                    {truncate(record.fields[cardField.id], { length: 50 }) || "-"}
                  </div>
                </div>
              ))}
            </div>
          </a>
        </div>
      ))}
    </div>
  )
}

AirtableApp.propTypes = {
  pageId: number.isRequired,
  baseId: string.isRequired,
  tableId: string.isRequired,
  cardFields: arrayOf(shape({ id: string.isRequired })),
  schemeId: number.isRequired,
  pageMetadata: airtableTableType.isRequired
}

export default styled(AirtableApp)`
  .field-title {
    font-size: ${FONT_SIZES.small};
    text-transform: uppercase;
  }

  .field-value {
    font-size: ${FONT_SIZES.small};
  }

  .card {
    margin-top: 0.7em;

    &:first-child {
      margin-top: ${SPACES.medium};
    }
  }
`
