import { useCallback, useEffect, useRef } from "react"

const useMentionsTribute = (values) => {
  const inputRef = useRef()

  const extractMentionedUserIds = useCallback(() => {
    if (!inputRef.current) return ""

    const ids = []

    $(inputRef.current)
      .find("[data-behavior=user-mention]")
      .each((i, el) => ids.push($(el).data("id")))

    return ids.join(" ")
  }, [inputRef])

  const removeIncorrectMentions = useCallback(() => {
    if (!inputRef.current || !values) return

    const allowedMentionIds = values.map(({ id }) => id)

    $(inputRef.current)
      .find("[data-behavior=user-mention]")
      .each((i, el) => {
        const mentionId = $(el).data("id")

        if (!allowedMentionIds.includes(mentionId)) {
          $(el).remove()
        }
      })
  }, [inputRef, values])

  useEffect(() => {
    if (!inputRef.current || !values) return

    const tribute = new window.Tribute({
      values,
      lookup: "fullName",
      selectTemplate: (item) => {
        if (typeof item === "undefined") return null
        return `${
          '<span class="fr-deletable fr-tribute user-mention"' +
          ' contenteditable="false" data-behavior="user-mention" '
        } data-entity="${item.original.entity}" data-id="${item.original.id}">@${
          item.original.insertedName
        }</span>`
      },
      menuItemTemplate: (item) => {
        return `<span data-id=${item.original.id} data-entity=${item.original.entity} class="user-mention-option" data-behavior="user-mention-option">${item.original.fullName}</span>`
      }
    })

    tribute.attach(inputRef.current)

    return () => tribute.detach(inputRef.current)
  }, [inputRef, values])

  return { inputRef, extractMentionedUserIds, removeIncorrectMentions }
}

export default useMentionsTribute
