import React, { useState } from "react"
import { func, string } from "prop-types"

import ModalBody from "src/styles/components/Modal/Body"
import ModalFooter from "src/styles/components/Modal/Footer"
import SubmitButton from "src/features/TwoFactor/Enable/SubmitButton"
import Intro from "src/features/TwoFactor/Enable/Intro"
import ChooseTwoFactorTool from "src/features/TwoFactor/Enable/ChooseTwoFactorTool"

import CodeInput from "./CodeInput"
import Form from "./Form"

const EnableByAppModal = ({
  twoFactorTool,
  setTwoFactorTool,
  close,
  qrCode,
  otpSecret,
  className
}) => {
  const [error, setError] = useState()

  return (
    <Form onSuccess={() => window.location.reload()} setError={setError}>
      <>
        <ModalBody className={className}>
          <Intro />

          <ChooseTwoFactorTool
            twoFactorTool={twoFactorTool}
            setTwoFactorTool={setTwoFactorTool}
          />

          <CodeInput qrCode={qrCode} otpSecret={otpSecret} error={error} />
        </ModalBody>

        <ModalFooter>
          <button className="button is-secondary" type="button" onClick={close}>
            Cancel
          </button>

          <SubmitButton />
        </ModalFooter>
      </>
    </Form>
  )
}

EnableByAppModal.propTypes = {
  twoFactorTool: string.isRequired,
  setTwoFactorTool: func.isRequired,
  qrCode: string.isRequired,
  otpSecret: string.isRequired,
  close: func.isRequired
}

export default EnableByAppModal
