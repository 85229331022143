import React, { useEffect, useState } from "react"
import { bool, func, number } from "prop-types"
import { useApolloClient, useMutation, useQuery } from "@apollo/client"

import Button from "src/styles/components/Button/Primary"
import SelectFolderModal from "src/components/SelectFolderModal"
import { SCHEME_MEMBERSHIP_ROLES } from "src/resources/schemes/constants"
import { buildFoldersTree } from "src/resources/box/helpers"
import { showFlashMessage } from "src/helpers/flash"
import { getFolderGroupDocumentLink } from "src/helpers/url"

import schemesQuery from "./schemes.gql"
import boxFoldersQuery from "./boxFolders.gql"
import copyDocumentToFolderMutation from "./copyDocumentToFolder.gql"

const CopyToFolderModal = ({ documentId, isOpened, close }) => {
  const apolloClient = useApolloClient()
  const { data } = useQuery(schemesQuery, {
    variables: { filter: { role: SCHEME_MEMBERSHIP_ROLES.admin } }
  })

  const [runCopyDocumentToFolderMutation, { loading: isCopying }] = useMutation(
    copyDocumentToFolderMutation
  )

  const accounts = [...new Set(data?.schemes.map(({ account }) => account))]
  const accountsOptions = accounts.map(({ id, name }) => ({ value: id, label: name }))

  const [schemeBoxFolders, setSchemeBoxFolders] = useState([])
  const [expandedFolders, setExpandedFolders] = useState([])
  const [selectedScheme, setSelectedScheme] = useState(null)
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [selectedFolder, setSelectedFolder] = useState(null)

  const copyingAllowed = selectedScheme && selectedAccount && selectedFolder

  const getFoldersByParentId = (boxFolderId) =>
    apolloClient
      .query({ query: boxFoldersQuery, variables: { boxFolderId } })
      .then(({ data: { boxFolders = [] } = {} } = {}) => boxFolders)

  const toggleFolder = ({ id: boxFolderId }) => {
    if (expandedFolders.includes(boxFolderId)) {
      setExpandedFolders((folders) => folders.filter((id) => boxFolderId === id))
    } else {
      setExpandedFolders((folders) => [...folders, boxFolderId])

      getFoldersByParentId(boxFolderId).then((boxFolders) => {
        setSchemeBoxFolders((savedFolders) => [...savedFolders, ...boxFolders])
      })
    }
  }

  const copyFolder = () => {
    return runCopyDocumentToFolderMutation({
      variables: {
        documentId,
        boxFolderId: selectedFolder.id
        // groupId: selectedFolder.groupId
      }
    }).then(({ data: { copyDocumentToFolder = {} } = {} }) => {
      if (copyDocumentToFolder.successful) {
        showFlashMessage(
          "success",
          `Document have been copied. ${getFolderGroupDocumentLink(selectedFolder)}`
        )
      } else if (copyDocumentToFolder.message) {
        showFlashMessage("danger", copyDocumentToFolder.message)
      } else {
        showFlashMessage("danger", "Could not copy document")
      }

      close()
    })
  }

  const schemeBoxFolderId = selectedScheme?.boxFolderId
  useEffect(() => {
    if (schemeBoxFolderId) {
      getFoldersByParentId(selectedScheme.boxFolderId).then(setSchemeBoxFolders)
    }
  }, [schemeBoxFolderId])

  const schemesOptions = data?.schemes
    .filter((scheme) => scheme.account.id === selectedAccount?.value)
    .map(({ id, name, ...rest }) => ({
      value: id,
      label: name,
      ...rest
    }))

  const foldersTree = buildFoldersTree({
    folders: schemeBoxFolders.map((folder) => ({
      ...folder,
      label: folder.name,
      value: folder.id,
      checked: selectedFolder?.id === folder.id,
      expanded: expandedFolders.includes(folder.id)
    })),
    rootFolderId: selectedScheme?.boxFolderId,
    // react-dropdown-tree-select won't allow to expand items without children
    modifyChildren: (folder, children) =>
      children?.length ? children : [{ disabled: true, label: "", className: "d-none" }]
  })

  return (
    <SelectFolderModal
      show={isOpened}
      onHide={close}
      onAccountChange={setSelectedAccount}
      accounts={accountsOptions}
      selectedAccount={selectedAccount}
      onSchemeChange={setSelectedScheme}
      schemes={schemesOptions}
      selectedScheme={selectedScheme}
      onNodeChange={setSelectedFolder}
      onNodeToggle={toggleFolder}
      folders={foldersTree}
    >
      <Button onClick={copyFolder} disabled={!copyingAllowed || isCopying}>
        {isCopying ? "Copying" : "Copy"}
      </Button>
    </SelectFolderModal>
  )
}

CopyToFolderModal.propTypes = {
  isOpened: bool,
  close: func.isRequired,
  documentId: number.isRequired
}

export default CopyToFolderModal
