import React, { useEffect, useState } from "react"
import { string } from "prop-types"
import { debounce } from "lodash"

import useUniversalAi from "src/features/UniversalAi/useUniversalAi"
import {
  SIDEBAR_OPENED_EVENT,
  SIDEBAR_CLOSED_EVENT,
  SIDEBAR_FULLSCREEN_BREAKPOINT,
  SIDEBAR_OVERLAY_BREAKPOINT
} from "src/features/UniversalAi/constants"
import { isMainContentNarrow } from "src/helpers/document"
import useResizeObserver from "src/hooks/useResizeObserver"
import CloseButton from "src/components/CloseButton"

import { Aside, Header, Toolbar, Title, OverlayClass, FullscreenClass } from "./styles"

const BaseComponent = ({ title, titleVersion, children }) => {
  const { close } = useUniversalAi()
  const [className, setClassName] = useState("")

  useEffect(() => {
    const openEvent = new CustomEvent(SIDEBAR_OPENED_EVENT)
    window.dispatchEvent(openEvent)

    if (window.innerWidth <= SIDEBAR_FULLSCREEN_BREAKPOINT) {
      setClassName(FullscreenClass)
    } else if (isMainContentNarrow()) {
      setClassName(OverlayClass)
    }

    return () => {
      const closeEvent = new CustomEvent(SIDEBAR_CLOSED_EVENT)
      window.dispatchEvent(closeEvent)
    }
  }, [])

  const resizeHandler = debounce((element) => {
    const width = element.contentBoxSize[0].inlineSize

    if (window.innerWidth <= SIDEBAR_FULLSCREEN_BREAKPOINT) {
      setClassName(FullscreenClass)
    } else if (isMainContentNarrow()) {
      setClassName(OverlayClass)
    } else if (width >= SIDEBAR_OVERLAY_BREAKPOINT) {
      setClassName(null)
    }
  }, 100)

  useResizeObserver({
    selector: "[data-behavior=content]",
    handler: resizeHandler,
    cleanupFunc: () => resizeHandler.cancel(),
    deps: [className]
  })

  return (
    <Aside
      className={className}
      data-testid="universal-ai-sidebar"
      data-behavior="universal-ai-sidebar"
    >
      <Header data-testid="header">
        <Title className={titleVersion} dangerouslySetInnerHTML={{ __html: title }} />
        <Toolbar>
          <CloseButton onClick={close} data-testid="close-button" />
        </Toolbar>
      </Header>
      {children}
    </Aside>
  )
}

BaseComponent.propTypes = {
  title: string,
  titleVersion: string
}

export default BaseComponent
