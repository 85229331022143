import React, { useCallback, useEffect } from "react"
import { func, bool, number, string } from "prop-types"

import EmptyContent from "src/styles/components/EmptyContent"
import { KEY_CODES } from "src/constants"

import Document from "./Document"
import AgendaItemSummary from "./AgendaItemSummary"
import NavigationIcon from "./NavigationIcon"
import { ContentContainer, EmptyContentContainer } from "./styles"

const Content = ({
  activeItemId,
  activeDocumentId,
  highlighted,
  isPrevButtonDisabled,
  isNextButtonDisabled,
  goBack,
  goForward
}) => {
  const activeId = activeDocumentId || activeItemId
  const showDocument = !!activeDocumentId
  const setActiveItemBefore = useCallback(() => goBack(), [activeId])
  const setActiveItemNext = useCallback(() => goForward(), [activeId])
  const handleKeyDown = useCallback((event) => {
    // Bug fix: when Universal AI sidebar is opened in "Query" mode and
    // user navigates with arrow keys in the prompt box then
    // user is also navigated to the next or previous meeting pack item.
    if (event.srcElement !== document.body) return

    const { keyCode } = event

    switch (keyCode) {
      case KEY_CODES.leftArrow:
      case KEY_CODES.upArrow:
        goBack()
        break
      case KEY_CODES.rightArrow:
      case KEY_CODES.downArrow:
        goForward()
        break
      default:
    }
  }, [])

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown)

    return () => document.removeEventListener("keydown", handleKeyDown)
  })

  return activeId ? (
    <ContentContainer className="view-content-container">
      <NavigationIcon
        onClick={setActiveItemBefore}
        type="navigate-before"
        size="large"
        disabled={isPrevButtonDisabled}
      />
      {showDocument ? (
        <Document highlighted={highlighted} />
      ) : (
        <AgendaItemSummary highlighted={highlighted} />
      )}
      <NavigationIcon
        onClick={setActiveItemNext}
        type="navigate-next"
        size="large"
        disabled={isNextButtonDisabled}
      />
    </ContentContainer>
  ) : (
    <EmptyContentContainer>
      <EmptyContent>You have not been added to any items.</EmptyContent>
    </EmptyContentContainer>
  )
}

Content.propTypes = {
  goForward: func.isRequired,
  goBack: func.isRequired,
  isPrevButtonDisabled: bool.isRequired,
  isNextButtonDisabled: bool.isRequired,
  activeItemId: number,
  activeDocumentId: number,
  highlighted: string
}

export default Content
