import React from "react"
import { Dropdown } from "react-bootstrap"
import { bool, func, number } from "prop-types"
import { styled } from "@linaria/react"

import Icon from "src/styles/components/Icon"
import { preventDefault } from "src/helpers/events"
import TridotIcon from "src/components/TridotIcon"
import { FONT_SIZES } from "src/styles/sizes"
import useExportMeetingForUser from "src/features/meetingPacks/useExportMeetingForUser"

import { Actions, DropdownItem } from "./styles"

const MeetingActions = ({
  className,
  meetingPackId,
  isSavedOffline,
  isExportAllowed,
  showOfflineActions,
  ...props
}) => {
  const closeActionsDropdown = () => document.querySelector("body").click()

  const saveMeetingOffline = () => {
    props.saveMeetingOffline()
    closeActionsDropdown()
  }

  const clearMeetingCache = () => {
    props.clearMeetingCache()
    closeActionsDropdown()
  }

  const exportMeeting = useExportMeetingForUser({ meetingPackId })

  const handleExportMeeting = () => {
    exportMeeting()
    closeActionsDropdown()
  }

  return (
    <Actions className={className} onClick={preventDefault}>
      <Dropdown>
        <Dropdown.Toggle as={TridotIcon} />

        <Dropdown.Menu alignRight>
          {showOfflineActions && isSavedOffline && (
            <DropdownItem onClick={clearMeetingCache} variant="danger">
              Remove from device <Icon type="cloud-empty" />
            </DropdownItem>
          )}
          {showOfflineActions && !isSavedOffline && (
            <DropdownItem onClick={saveMeetingOffline}>
              Make available offline <Icon type="cloud-download" />
            </DropdownItem>
          )}
          {isExportAllowed && (
            <DropdownItem
              onClick={handleExportMeeting}
              className="w-100 pt-1 d-flex justify-content-between"
            >
              <span className="pr-2">Export PDF of pack</span>
              <Icon type="pdf-download" size="large" />
            </DropdownItem>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </Actions>
  )
}

MeetingActions.propTypes = {
  meetingPackId: number.isRequired,
  saveMeetingOffline: func.isRequired,
  clearMeetingCache: func.isRequired,
  isSavedOffline: bool,
  showOfflineActions: bool,
  isExportAllowed: bool
}

export default styled(MeetingActions)`
  .icon-pdf-download {
    font-size: ${FONT_SIZES.large};
    margin-right: 0.1rem;
  }
`
