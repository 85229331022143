import React from "react"
import { shape, string } from "prop-types"
import { startCase } from "lodash"

import { Error } from "./styles"

const LogItem = ({ logItem }) => {
  const { errorMessage } = logItem

  return (
    <div>
      {!errorMessage && (
        <span>
          {startCase(logItem.type)} &quot;{logItem.name}&quot; has been deleted
        </span>
      )}
      {errorMessage && (
        <Error>
          Error for {logItem.type} &quot;{logItem.name}&quot;: {errorMessage}
        </Error>
      )}
    </div>
  )
}

LogItem.propTypes = {
  logItem: shape({
    name: string.isRequired,
    type: string.isRequired,
    errorMessage: string
  }).isRequired
}

export default LogItem
