import CacheStore from "src/features/offline/helpers/CacheStore"

export default class SyncStatusCheck {
  static MAX_SYNC_TIME = 2 * 60 * 1000

  static TICK_INTERVAL = 1000

  constructor({ onTick } = {}) {
    this.syncInterval = null
    this.syncTimeStart = null
    this.onTick = typeof onTick === "function" ? onTick : () => {}
  }

  start = () => {
    this.syncTimeStart = new Date().getTime()
    this.syncInterval = setInterval(
      this.syncStatusCheckTick,
      SyncStatusCheck.TICK_INTERVAL
    )
  }

  stop = () => {
    clearInterval(this.syncInterval)
    this.syncTimeStart = null
    this.syncInterval = null
  }

  syncStatusCheckTick = () => {
    this.getAllMeetingsStats().then((stats) => {
      this.onTick(stats)

      // stop synchronization if it takes too much time
      if (this.syncTimeStart + SyncStatusCheck.MAX_SYNC_TIME < new Date().getTime()) {
        this.stop()
      }
    })
  }

  getAllMeetingsStats = async () => {
    const cacheStore = new CacheStore()

    const [meetingPacks, allDocumentsIds] = await Promise.all([
      cacheStore.getAllMeetingPacks(),
      cacheStore.getAllDocumentsIds()
    ])

    return Object.fromEntries(
      meetingPacks.map((meetingPack) => {
        const all = (meetingPack.documents || []).map(({ id }) => id)
        const saved = all.filter((documentId) => allDocumentsIds.includes(documentId))

        return [
          meetingPack.id,
          {
            allDocumentsAreSaved: !all?.length || all?.length === saved?.length,
            savedDocumentsCount: saved?.length,
            allDocumentsCount: all?.length
          }
        ]
      })
    )
  }
}
