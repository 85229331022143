import { useEffect, useState } from "react"

const DEFAULT_LEVEL = "info"
const FLASH_TIMEOUT = 5000

const useMoveFolderItemFlashMessage = () => {
  const [visible, setVisible] = useState(false)
  const [withUndo, setWithUndo] = useState(false)
  const [level, setLevel] = useState(DEFAULT_LEVEL)
  const [message, setMessage] = useState("")

  const close = () => {
    setVisible(false)
    setWithUndo(false)
    setLevel(DEFAULT_LEVEL)
    setMessage("")
  }

  useEffect(() => {
    if (!visible) return

    const timeoutId = setTimeout(() => close(), FLASH_TIMEOUT)

    return () => clearTimeout(timeoutId)
    // Reset timeout when props change
  }, [visible, withUndo, level, message])

  const show = ({
    level: providedLevel = DEFAULT_LEVEL,
    message: providedMessage,
    withUndo: providedWithUndo = false
  }) => {
    setVisible(true)
    setWithUndo(providedWithUndo)
    setLevel(providedLevel)
    setMessage(providedMessage)
  }

  return { visible, level, message, withUndo, close, show }
}

export default useMoveFolderItemFlashMessage
