import classNames from "classnames"

import setActiveSidebarMode from "../setActiveSidebarMode"

import annotationButton from "./annotationButton"

const tinyToolbarWidth = 445

const shouldHideAnnotationButton = (instance) => {
  const toolbar = instance.contentDocument.querySelector(".PSPDFKit-Toolbar")

  return toolbar && toolbar.offsetWidth < tinyToolbarWidth
}

export default (instance) => {
  window.addEventListener("resize", () => {
    const annotationsButton = instance.contentDocument.querySelector(
      ".PSPDFKit-Toolbar .annotation-button"
    )

    if (!annotationsButton) {
      return
    }

    if (shouldHideAnnotationButton(instance)) {
      annotationsButton.classList.add("d-none")
    } else {
      annotationsButton.classList.remove("d-none")
    }
  })

  return {
    type: "custom",
    className: classNames(
      { "d-none": shouldHideAnnotationButton(instance) },
      "custom-button annotation-button "
    ),
    id: "annotation-button",
    node: annotationButton,
    onPress() {
      setActiveSidebarMode({
        instance,
        sidebarMode: window.PSPDFKit.SidebarMode.ANNOTATIONS,
        buttonClass: "annotation-button"
      })
    }
  }
}
