import React, { useContext } from "react"
import pluralize from "pluralize"
import { instanceOf, number, oneOf, oneOfType, string } from "prop-types"

import ModalContext from "src/features/Messages/ActionDetails/ModalContext"
import { ACTION_TYPES } from "src/features/Messages/ActionDetails/constants"

const NumberConfirmation = ({ actionType, value }) => {
  const {
    message: { actionsCount }
  } = useContext(ModalContext)

  if (actionType === ACTION_TYPES.voting) {
    return (
      <>
        This vote will be resolved after{" "}
        <strong>
          {value === 1
            ? "1 vote"
            : `${value} of ${actionsCount} votes have reached agreement`}
        </strong>
        . Once reached, it will remain open to outstanding votes for 24 hours.
      </>
    )
  }

  return (
    <>
      This must read action will close after{" "}
      <strong>
        {value === actionsCount
          ? "everyone has"
          : `${value} of ${actionsCount} ${pluralize("have", value)}`}{" "}
        marked as read
      </strong>
      . Once reached, it will remain open to outstanding users for 24 hours.
    </>
  )
}

NumberConfirmation.propTypes = {
  value: oneOfType([string, number, instanceOf(Date)]),
  actionType: oneOf(Object.keys(ACTION_TYPES)).isRequired
}

export default NumberConfirmation
