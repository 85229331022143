import React from "react"
import { components } from "react-select"
import { shape, string } from "prop-types"

import IconLabel from "src/features/Search/SearchPage/Search/Filters/IconLabel"

import { WhereOption } from "./styles"

const Option = ({ data, ...props }) => {
  const ReactOption = components.Option

  return (
    <ReactOption {...props}>
      <WhereOption>
        <IconLabel {...data} />
      </WhereOption>
    </ReactOption>
  )
}

Option.propTypes = {
  data: shape({
    value: string,
    label: string
  })
}

export default Option
