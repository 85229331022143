import { styled } from "@linaria/react"

import { BASE_COLORS } from "src/styles/colors"
import BaseIcon from "src/styles/components/Icon"

export const Container = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
`

export const ErrorContainer = styled.div`
  color: ${BASE_COLORS.red};
  display: flex;
  align-items: center;
  gap: 10px;
`

export const IconError = styled(BaseIcon)`
  font-size: 17px;
`
