import { bool, shape, string } from "prop-types"
import React from "react"

import SecondaryButton from "src/styles/components/Button/Secondary"
import { showFlashMessage } from "src/helpers/flash"
import { copyHTMLText } from "src/helpers/copyText"

import { Container, TwoCirclesLoader } from "./styles"

const Footer = ({ loading = false, content, contentRef, buttonTitle = "Copy text" }) => {
  const copyText = () => {
    copyHTMLText(contentRef.current, content)
    showFlashMessage("success", "Text has been copied. Paste it anywhere to share.")
  }

  return (
    <Container>
      {loading && <TwoCirclesLoader />}
      {!loading && content && (
        <SecondaryButton onClick={copyText}>{buttonTitle}</SecondaryButton>
      )}
    </Container>
  )
}

Footer.propTypes = {
  loading: bool,
  content: string,
  buttonTitle: string,
  contentRef: shape({}).isRequired
}

export default Footer
