import React from "react"
import { func, string } from "prop-types"
import { styled } from "@linaria/react"

import Radio from "src/styles/components/Radio"
import { TEXT_COLORS } from "src/styles/colors"
import { TWO_FACTOR_TOOLS } from "src/features/TwoFactor/constants"

const ChooseTwoFactorTool = ({ twoFactorTool, setTwoFactorTool, className }) => {
  return (
    <div className={className}>
      <div className="mb-3 ml-2">
        <Radio
          id="two-factor-tool-app"
          label="Use an authenticator app"
          checked={twoFactorTool === TWO_FACTOR_TOOLS.app}
          onChange={() => setTwoFactorTool(TWO_FACTOR_TOOLS.app)}
        />
      </div>
      <div className="ml-2">
        <Radio
          id="two-factor-tool-phone"
          label="Receive a text message"
          checked={twoFactorTool === TWO_FACTOR_TOOLS.phone}
          onChange={() => setTwoFactorTool(TWO_FACTOR_TOOLS.phone)}
        />
      </div>
    </div>
  )
}

ChooseTwoFactorTool.propTypes = {
  twoFactorTool: string,
  setTwoFactorTool: func.isRequired
}

export default styled(ChooseTwoFactorTool)`
  ${Radio} {
    label {
      color: ${TEXT_COLORS.muted};
    }

    label:before {
      margin-top: 0;
    }

    label:after {
      top: 5px;
    }
  }
`
