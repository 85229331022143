import React from "react"
import { arrayOf, number, func } from "prop-types"
import { styled } from "@linaria/react"

import UserAvatar from "src/components/UsersContacts/UserAvatar"
import LinkButton from "src/styles/components/Button/Link"
import UserAvatarContainer from "src/components/UsersContacts/UserAvatarContainer"
import UserBadge from "src/components/UsersContacts/UserBadge"

import MembersCounter from "./MembersCounter"
import { User } from "./types"

const DEFAULT_SHOWN_USERS_LIMIT = 3
const SHOWN_ATTENDEES_CHANGES_LIMIT = 2

const Separator = styled.div`
  display: inline-block;
  width: 2px;
  height: 28px;
  background-color: #cbcdd5;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 5px;
  margin-left: 5px;
`

const AttendeesChangesContainer = styled.div`
  display: contents;
`

const ShownUsersList = ({
  users = [],
  attendeesChanges = [],
  onUserClick,
  shownUsersLimit = DEFAULT_SHOWN_USERS_LIMIT
}) => {
  const badge = (user) => (user.status === "added" ? "+" : "-")

  const shownUsers = users.slice(0, shownUsersLimit)
  const hiddenUsers = users.slice(shownUsersLimit)

  const shownAttendeesChanges = attendeesChanges.slice(0, SHOWN_ATTENDEES_CHANGES_LIMIT)
  const hiddenAttendeesChanges = attendeesChanges.slice(SHOWN_ATTENDEES_CHANGES_LIMIT)

  return (
    <>
      {attendeesChanges.length > 0 && (
        <AttendeesChangesContainer data-testid="attendees-changes">
          {shownAttendeesChanges.map((user) => (
            <LinkButton
              noFocus
              key={user.id}
              onClick={onUserClick}
              data-testid={`attendee-change-${user.id}`}
            >
              <UserAvatarContainer>
                <UserBadge variant={user.status} data-testid={`${user.status}-badge`}>
                  {badge(user)}
                </UserBadge>
                <UserAvatar
                  className="ml-1"
                  user={user}
                  key={user.id}
                  variant={user.status}
                />
              </UserAvatarContainer>
            </LinkButton>
          ))}
          {!!hiddenAttendeesChanges.length && (
            <MembersCounter count={hiddenAttendeesChanges.length} />
          )}
        </AttendeesChangesContainer>
      )}
      {attendeesChanges.length > 0 && users.length > 0 && <Separator />}
      {shownUsers.map((user) => (
        <LinkButton noFocus key={user.id} onClick={onUserClick}>
          <UserAvatar className="ml-1" user={user} key={user.id} />
        </LinkButton>
      ))}
      {!!hiddenUsers.length && <MembersCounter count={hiddenUsers.length} />}
    </>
  )
}

ShownUsersList.propTypes = {
  users: arrayOf(User).isRequired,
  attendeesChanges: arrayOf(User),
  onUserClick: func,
  shownUsersLimit: number
}

export default ShownUsersList
