import { useCallback } from "react"
import { useDispatch } from "react-redux"

import { fetchMessage } from "src/resources/messages/thunks"
import { removeStarredItemSuccess } from "src/resources/starredItems/slice"
import { updateMessageStarredSuccess } from "src/resources/messages/slice"
import { ITEM_TYPE } from "src/resources/messages/constants"

export const useMessageChangeHandlers = (messageIds) => {
  const dispatch = useDispatch()

  const handleMessageChange = useCallback(
    (message) => {
      switch (message.name) {
        case "message-create":
        case "message-update":
          dispatch(fetchMessage(message.data))
          break
        case "message-destroy":
          dispatch(removeStarredItemSuccess(message.data))
          break
        default:
      }
    },
    [dispatch]
  )

  const handleMessageStatusChange = useCallback(
    (message) => {
      if (message.name === "status-updated" && message.data.itemType === ITEM_TYPE) {
        const messageId = Number(message.data.itemId)

        if (messageIds.includes(messageId)) {
          dispatch(
            updateMessageStarredSuccess({ id: messageId, starred: !message.data.starred })
          )
        } else {
          dispatch(fetchMessage({ messageId }))
        }
      }
    },
    [messageIds, dispatch]
  )

  const handleReadMessage = useCallback(
    (message) => {
      if (message.name === "read") {
        message.data.messageIds.forEach((messageId) => {
          if (messageIds.includes(messageId)) dispatch(fetchMessage({ messageId }))
        })
      }
    },
    [messageIds, dispatch]
  )

  return { handleMessageChange, handleMessageStatusChange, handleReadMessage }
}
