import { styled } from "@linaria/react"

import { Button as BaseButton } from "src/features/groups/DocumentsExplorer/styles"
import BaseIcon from "src/styles/components/Icon"
import { BREAKPOINTS } from "src/styles/sizes"

export const Icon = styled(BaseIcon)`
  font-size: 20px;
`

export const Label = styled.span``

export const Button = styled(BaseButton)`
  @media (max-width: ${BREAKPOINTS.medium}) {
    & ${Label} {
      display: none;
    }
  }
`
