import React from "react"
import { styled } from "@linaria/react"

import Icon from "src/styles/components/Icon"
import { successColor } from "src/styles/colors"
import { FONT_SIZES } from "src/styles/sizes"

const IconDone = ({ ...props }) => {
  return <Icon type="cloud-done" {...props} />
}

export const ICON_WIDTH = "28px"

export default styled(IconDone)`
  background-color: ${successColor};
  border-radius: 100%;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: ${FONT_SIZES.small};
  height: ${ICON_WIDTH};
  padding-bottom: 1px;
  width: ${ICON_WIDTH};
`
