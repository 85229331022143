import { styled } from "@linaria/react"

import { withVariations } from "src/helpers/linaria"
import { SPACES } from "src/styles/sizes"

import { Title, Primary } from "./Title"
import Auxiliary from "./Auxiliary"

export const Mascot = styled.div``

export default styled(withVariations("div", "horizontal"))`
  border-width: 0 0 1px;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none;
  flex: 1 0 auto;
  min-height: 1px;

  &:last-child {
    border-bottom-width: 0;
  }

  &--horizontal {
    display: flex;
    flex-flow: row nowrap;
    justify-items: stretch;
    align-items: center;

    ${Mascot} {
      flex: 0 0 auto;
    }

    ${Title} {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
    }

    ${Primary} {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    ${Auxiliary} {
      display: flex;
      flex: 0 0 auto;
      font-size: 0.75rem;
      justify-content: space-between;
      padding-left: ${SPACES.small};
    }
  }
`
