import React from "react"
import { arrayOf, func, shape, string } from "prop-types"

import { Container, Icon } from "./styles"
import Segment from "./Segment"

const Breadcrumbs = ({ path, onNavigate }) => {
  return (
    <Container data-testid="breadcrumbs">
      {path.map((segment, index) => (
        <Segment key={segment.id} segment={segment} onNavigate={onNavigate}>
          {index !== path.length - 1 && <Icon type="navigate-next" />}
        </Segment>
      ))}
    </Container>
  )
}

Breadcrumbs.propTypes = {
  path: arrayOf(
    shape({
      id: string.isRequired,
      name: string.isRequired
    })
  ).isRequired,
  onNavigate: func.isRequired
}

export default Breadcrumbs
