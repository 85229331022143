import React from "react"

import { toggleSidebar } from "src/helpers/document"
import UnstyledButton from "src/styles/components/Button/Unstyled"

const Toggle = () => {
  return (
    <UnstyledButton
      className="sidebar-toggle saved-offline show-sidebar"
      onClick={toggleSidebar}
    >
      <span className="icon-hamburger" />
    </UnstyledButton>
  )
}

Toggle.propTypes = {}

export default Toggle
