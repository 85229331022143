import React from "react"
import { arrayOf, shape } from "prop-types"

import AttendeesContacts from "src/components/AttendeesContacts"
import { MEMBERSHIP_TYPES_FOR } from "src/features/meetingPacks/MeetingPack/constants"

const Contacts = ({ attendees, currentUser }) => {
  return (
    <AttendeesContacts
      attendees={attendees}
      currentUser={currentUser}
      allowedMembershipTypes={MEMBERSHIP_TYPES_FOR.meetingPackAttendees}
    />
  )
}

Contacts.propTypes = {
  attendees: arrayOf(shape({})).isRequired,
  currentUser: shape({}).isRequired
}

export default Contacts
