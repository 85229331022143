import React from "react"
import { func, number } from "prop-types"
import pluralize from "pluralize"

import LinkButton from "src/components/LinkButton"

const SelectedUsersCount = ({ count, onClick }) => {
  return (
    <div className="text-center ptx pbx">
      {count > 0 && (
        <LinkButton onClick={onClick}>
          {pluralize("person", count, true)} selected
        </LinkButton>
      )}
    </div>
  )
}

SelectedUsersCount.propTypes = {
  count: number.isRequired,
  onClick: func
}

export default SelectedUsersCount
