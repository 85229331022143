import React from "react"

import Logo from "./Logo"
import Toggle from "./Toggle"

const SidebarHead = () => {
  return (
    <div className="sidebar-section sidebar-head">
      <a href="/" className="logo">
        <Logo />
      </a>
      <Toggle />
    </div>
  )
}

SidebarHead.propTypes = {}

export default SidebarHead
