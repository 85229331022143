import { connect } from "react-redux"

import { setActiveDocument } from "src/features/meetingPacks/MeetingPack/meetingPackSlice"
import { getUnreadDocumentIds } from "src/features/meetingPacks/MeetingPack/selectors"

import DocumentsList from "./DocumentsList"

const mapState = (state) => ({
  activeDocumentId: state.meetingPack.activeDocument.id,
  unreadDocumentIds: getUnreadDocumentIds(state)
})

const mapDispatch = (dispatch) => ({
  setActiveDocument(documentId) {
    dispatch(setActiveDocument(documentId))
  }
})

export default connect(mapState, mapDispatch)(DocumentsList)
