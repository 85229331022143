import { HeartBeat } from "src/helpers/pollForSessionTimeout"
import clearOutdatedSignatures from "src/features/signedDocuments/AttachedSignedDocument/storedSignatures/clearOutdatedSignatures"

import { checkForPSPDFKitUpdate } from "./helpers/pspdfkitUpdate"
import { registerServiceWorker } from "./registerServiceWorker"
import syncOfflineAnnotations from "./syncOfflineAnnotations"

const handleLoadPage = async ({ shouldRegisterServiceWorker }) => {
  if (navigator.onLine) {
    const cacheWasReloaded = await checkForPSPDFKitUpdate({})
    if (cacheWasReloaded !== true && !navigator.serviceWorker?.controller)
      registerServiceWorker()
    syncOfflineAnnotations()
    clearOutdatedSignatures()
  } else if (shouldRegisterServiceWorker) registerServiceWorker()
}

window.addEventListener("load", () => {
  handleLoadPage({ shouldRegisterServiceWorker: true })

  window.heartBeat = new HeartBeat()
})

window.addEventListener("turbo:load", () => {
  handleLoadPage({})
})
