import { useQuery } from "@apollo/client"

import folderItemsQuery from "./folderItemsQuery.gql"

const useFolderItemsQuery = ({ groupId, folderId, sortBy }) => {
  const { loading, data, previousData, error, refetch } = useQuery(folderItemsQuery, {
    variables: { groupId, folderId, sortBy },
    fetchPolicy: "network-only"
  })

  const dataSource = loading || error ? previousData : data
  const folderItems = dataSource?.groupFolderItems || []

  return { folderItems, loading, refetch, error: !!error }
}

export default useFolderItemsQuery
