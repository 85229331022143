import React, { useEffect, useState } from "react"
import Select from "react-select/creatable"
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import { components } from "react-select"
import { arrayOf } from "prop-types"

import useIsOpened from "src/hooks/useIsOpened"
import { getRandomInt } from "src/helpers/number"
import { setInitialStateFromProps } from "src/resources/tags/thunks"
import { getTagOption } from "src/resources/tags/helpers"
import { selectTagOptions } from "src/resources/tags/selectors"
import { tagType } from "src/resources/tags/propTypes"
import { TAG_COLORS_COUNT } from "src/styles/colors"

import RenameTagModal from "./RenameTagModal"
import SingleValue from "./SingleValue"
import { useOption } from "./useOption"
import { isValidNewOption, noOptionsMessage } from "./helpers"
import { selectStyles } from "./styles"

const Control = (props) => <components.Control className="form-field" {...props} />

const MeetingPackTag = ({ selectedTags, tags }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setInitialStateFromProps(tags))
  }, [dispatch])

  const defaultValue = selectedTags.map((tag) => getTagOption(tag))
  const options = useSelector(selectTagOptions, shallowEqual)

  const [renameTag, setRenameTag] = useState()
  const [newColorId, setNewColorId] = useState(getRandomInt(TAG_COLORS_COUNT))
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const menuIsOpenProp = menuIsOpen ? { menuIsOpen } : {}

  const { isOpened, open, close } = useIsOpened()

  const handleChange = (newValue, actionMeta) => {
    setMenuIsOpen(false)
    if (actionMeta.action !== "create-option") return

    // eslint-disable-next-line
    newValue.colorId = newColorId
    setNewColorId(getRandomInt(TAG_COLORS_COUNT))

    return newValue
  }

  const handleBlur = () => {
    if (!isOpened) setMenuIsOpen(false)
  }

  const handleMenuIconClick = (event, tag) => {
    event.stopPropagation()
    setRenameTag(tag)
    setMenuIsOpen(true)
    open()
  }

  const Option = useOption({ newColorId, handleMenuIconClick })

  return (
    <>
      <Select
        {...menuIsOpenProp}
        isClearable
        placeholder="Select a tag or create a new one"
        components={{ Control, SingleValue, Option }}
        isValidNewOption={isValidNewOption}
        noOptionsMessage={noOptionsMessage}
        formatCreateLabel={(inputValue) => inputValue}
        onChange={handleChange}
        onBlur={handleBlur}
        styles={selectStyles}
        defaultValue={defaultValue}
        maxMenuHeight={150}
        options={options}
      />
      {isOpened && (
        <RenameTagModal
          isOpened={isOpened}
          close={close}
          tag={renameTag}
          options={options}
        />
      )}
    </>
  )
}

MeetingPackTag.propTypes = {
  selectedTags: arrayOf(tagType),
  tags: arrayOf(tagType)
}

export default MeetingPackTag
