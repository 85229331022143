import { styled } from "@linaria/react"

import { successColor } from "src/styles/colors"
import BaseIcon from "src/styles/components/Icon"

export const IconDone = styled(BaseIcon)`
  font-size: 28px;
  color: ${successColor};

  &:before {
    position: relative;
    left: 6px;
  }
`
