import React, { useState } from "react"
import { Modal, Nav } from "react-bootstrap"
import { arrayOf, bool, func, shape, string } from "prop-types"

import { showCaughtErrorMessage } from "src/helpers/errors"
import { disableChooseButton } from "src/helpers/box"
import UploadModalContent from "src/components/FilesUpload/ModalContent"

import FilePicker from "./FilePicker"
import { NavTabs, ModalHeader } from "./styles"

const UploadModal = ({
  close,
  isOpened,
  selectedFiles,
  setSelectedFiles,
  uploadFiles,
  uploadBoxFiles,
  defaultActiveTab = "uploadNewFile"
}) => {
  const [activeTab, setActiveTab] = useState(defaultActiveTab)
  const isUploading = selectedFiles.some((file) => file.isUploading)
  const resetSelectedFiles = () => setSelectedFiles([])
  const closeModal = () => {
    close()
    resetSelectedFiles()
    setActiveTab(defaultActiveTab)
  }
  const uploadSelectedFiles = () =>
    uploadFiles(selectedFiles)
      .then(closeModal)
      .catch((error) => {
        closeModal()
        showCaughtErrorMessage(error)
      })

  const handleChoose = (files) => {
    disableChooseButton("Uploading...")
    uploadBoxFiles(files)
      .then(closeModal)
      .catch((error) => {
        closeModal()
        showCaughtErrorMessage(error)
      })
  }

  return (
    <Modal
      contentClassName="upload-document"
      size="lg"
      show={isOpened}
      onHide={closeModal}
    >
      <ModalHeader closeButton>
        <Modal.Title>Upload documents</Modal.Title>
      </ModalHeader>
      <NavTabs variant="tabs" activeKey={activeTab} onSelect={setActiveTab}>
        <Nav.Item>
          <Nav.Link eventKey="collaborationFiles">Collaboration files</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="uploadNewFile">Upload New File</Nav.Link>
        </Nav.Item>
      </NavTabs>
      {activeTab === defaultActiveTab ? (
        <UploadModalContent
          selectedFiles={selectedFiles}
          close={closeModal}
          uploadFiles={uploadSelectedFiles}
          isUploading={isUploading}
          setSelectedFiles={setSelectedFiles}
          multiple
          allowedFiles="documents"
        />
      ) : (
        <FilePicker handleCancel={closeModal} handleChoose={handleChoose} />
      )}
    </Modal>
  )
}

UploadModal.propTypes = {
  close: func.isRequired,
  isOpened: bool,
  selectedFiles: arrayOf(shape).isRequired,
  setSelectedFiles: func.isRequired,
  uploadFiles: func.isRequired,
  uploadBoxFiles: func.isRequired,
  defaultActiveTab: string
}

UploadModal.defaultProps = {
  isOpened: false
}

export default UploadModal
