class @InvitationsEditHandler
  constructor: ->
    @changePasswordComponent = new ChangePasswordComponent()
    @avatarUploadComponent = new AvatarUploadComponent()

  setup: =>
    @changePasswordComponent.setup()
    @avatarUploadComponent.setup()

  teardown: ->
