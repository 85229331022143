import Airtable from "airtable"

const useAirtableApi = ({ pageId, schemeId, baseId, tableId }) => {
  const airtable = new Airtable({
    endpointUrl: `${window.location.origin}/api/airtable/records/${pageId}/${schemeId}`,
    apiKey: "fake"
  })

  const base = airtable.base(baseId)
  const table = base.table(tableId)

  return { table }
}

export default useAirtableApi
