import React from "react"
import { css } from "@linaria/core"
import { styled } from "@linaria/react"
import { arrayOf, bool, func, shape, string } from "prop-types"

import Icon from "src/styles/components/Icon"

const toggleContainer = css`
  position: relative;
  cursor: pointer;
`

const ExpandedIcon = styled(Icon)`
  display: inline-block;
  position: absolute;
  left: -15px;
  top: 50%;
  translate: 0 -50%;
`

const CollapsedIcon = styled(ExpandedIcon)`
  transform: rotate(-90deg);
`

const MainItemIndex = styled.div`
  flex-grow: 0;
  margin-right: 10px;
`

const SubItemIndex = styled(MainItemIndex)`
  margin-left: 20px;
`

const NameWithIndex = styled.div`
  display: flex;
`

const Name = styled.div`
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const NameCell = ({ item, expanded = false, index, onToggle, isSubItem = false }) => {
  const hasSubItems = (item.subItems || []).length > 0
  const ToggleIcon = expanded ? ExpandedIcon : CollapsedIcon
  const Index = isSubItem ? SubItemIndex : MainItemIndex

  const handleToggle = () => {
    if (onToggle) onToggle()
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events , jsx-a11y/no-noninteractive-element-interactions
    <td className={hasSubItems ? toggleContainer : undefined} onClick={handleToggle}>
      {hasSubItems && <ToggleIcon type="dropdown-arrow-filled" />}
      <NameWithIndex>
        <Index>{index}</Index>
        <Name>{item.name}</Name>
      </NameWithIndex>
    </td>
  )
}

NameCell.propTypes = {
  item: shape({
    name: string.isRequired,
    subItems: arrayOf(shape({}))
  }),
  expanded: bool,
  index: string.isRequired,
  onToggle: func,
  isSubItem: bool
}

export default NameCell
