import { styled } from "@linaria/react"

export default styled.button`
  background: transparent;
  color: inherit;
  border: none;
  padding: 0;

  &:focus {
    color: inherit;
    outline: none;
  }
`
