import React, { useEffect, useMemo, useState } from "react"
import { string } from "prop-types"

import useIsOpened from "src/hooks/useIsOpened"

import NewMessageActionContext from "./NewMessageActionContext"
import { NewMessageActionModal } from "./NewMessageActionModal"
import NewMessageActionText from "./NewMessageActionText"

const ACTION_TYPE_CHANGED_EVENT = "newMessageAction:actionTypeChanged"
const ACTORS_CHANGED_EVENT = "newMessageAction:actorsChanged"

const subscribeToEvent = (event, callback) => {
  return () => {
    window.addEventListener(event, callback)

    return () => {
      window.removeEventListener(event, callback)
    }
  }
}

const setFormInputs = (form, { action, date, number }, selected) => {
  $('input[name="message[action_closed_type]"]', form).val(action)
  $('input[name="message[action_closed_at]"]', form).val(date?.toISOString())
  $('input[name="message[max_actions_count]"]', form).val(number)
  $(form).data("closingOptionSelected", selected)
}

const prepareAttributes = (action, date, number) => {
  switch (action) {
    case "date":
      return { action, date }

    case "number":
      return { action, number }

    default:
      return { action }
  }
}

const NewMessageActionDetails = ({ formSelector }) => {
  const form = $(formSelector)

  const {
    isOpened: actionsModalOpened,
    open: openActionsModal,
    close: closeActionsModal
  } = useIsOpened()

  const [message, setMessage] = useState({})
  const [optionConfirmed, setOptionConfirmed] = useState(false)
  const [optionAttributes, setOptionAttributes] = useState({})

  const confirmOption = (action, date, number) => {
    const attributes = prepareAttributes(action, date, number)

    setOptionAttributes(attributes)
    setOptionConfirmed(true)
    setFormInputs(form, attributes, true)
  }

  const resetOption = () => {
    setOptionConfirmed(false)
    setOptionAttributes({})
    setFormInputs(form, {}, false)
  }

  useEffect(
    subscribeToEvent(ACTION_TYPE_CHANGED_EVENT, (event) => {
      resetOption()
      setMessage({ ...message, actionType: event.detail.actionType })
    }),
    [message]
  )

  useEffect(
    subscribeToEvent(ACTORS_CHANGED_EVENT, (event) => {
      const actionsCount = event.detail.actorIds.length
      setMessage({ ...message, actionsCount })
    }),
    [message]
  )

  const context = useMemo(() => {
    return {
      message,
      optionConfirmed,
      optionAttributes,
      confirmOption,
      actionsModalOpened,
      openActionsModal,
      closeActionsModal
    }
  }, [message, optionConfirmed, optionAttributes, actionsModalOpened])

  return (
    <NewMessageActionContext.Provider value={context}>
      {actionsModalOpened && <NewMessageActionModal show={actionsModalOpened} />}
      <NewMessageActionText />
    </NewMessageActionContext.Provider>
  )
}

NewMessageActionDetails.propTypes = {
  formSelector: string
}

export default NewMessageActionDetails
