import React, { useContext } from "react"
import { styled } from "@linaria/react"
import { string, func, bool } from "prop-types"
import classNames from "classnames"

import SearchableSelect from "src/components/SearchableSelect"

import PageMetadataContext from "./PageMetadataContext"
import { PLAIN_TEXT_FIELD_TYPES } from "./constants"

const FieldSearchableSelect = ({
  value,
  onChange,
  containerClassName,
  onlyPlainTextFields,
  className,
  ...props
}) => {
  let options
  const { fieldOptions, allFields } = useContext(PageMetadataContext)
  const fieldOption = (fieldId) => options.find((option) => option.value === fieldId)

  if (onlyPlainTextFields)
    options = fieldOptions.filter((option) =>
      PLAIN_TEXT_FIELD_TYPES.includes(allFields[option.value]?.type)
    )
  else options = fieldOptions

  return (
    <SearchableSelect
      useSelectedLabel={!!value}
      label="Select field"
      options={options}
      selected={fieldOption(value)}
      setSelected={onChange}
      className={classNames(containerClassName, className)}
      {...props}
    />
  )
}

FieldSearchableSelect.propTypes = {
  value: string,
  onChange: func.isRequired,
  containerClassName: string,
  onlyPlainTextFields: bool
}

export default styled(FieldSearchableSelect)`
  .btn {
    background: none;
  }
`
