import { styled } from "@linaria/react"
import { Card, Col, Row } from "react-bootstrap"

import UnreadBadge from "src/styles/components/UnreadBadge"
import { TEXT_COLORS } from "src/styles/colors"
import { BREAKPOINTS, SPACES, FONT_SIZES, FONT_WEIGHTS } from "src/styles/sizes"
import BaseIconDone, { ICON_WIDTH } from "src/styles/components/IconDone"
import { Actions } from "src/features/Home/Meetings/MeetingActions/styles"

const attendanceWidth = "90px"

export const MeetingCard = styled(Card)`
  padding: 0;
  position: relative;
  margin: ${SPACES.small} 0;
  color: ${TEXT_COLORS.heading};

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  ${Actions} {
    position: absolute;
    right: -2.5rem;
    top: 40%;

    @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
      position: absolute;
      right: 1rem;
      top: 1rem;
    }
  }
`

export const IconDone = styled(BaseIconDone)`
  position: absolute;
  right: calc(${ICON_WIDTH} / -2);
  top: calc(${ICON_WIDTH} / -2);
`

export const CardRow = styled(Row)`
  justify-content: space-between;

  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    flex-direction: column;
  }
`

export const SpaceName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${FONT_SIZES.small};
  font-weight: ${FONT_WEIGHTS.xBold};
`

export const Attendance = styled.div`
  text-align: right;
  font-size: ${FONT_SIZES.small};
  font-weight: ${FONT_WEIGHTS.semiBold};
  font-style: italic;
`

export const MeetingName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${Attendance} {
    display: block;
    width: ${attendanceWidth};

    @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
      display: none;
    }
  }
`

export const Name = styled.div`
  font-size: ${FONT_SIZES.medium};
  font-weight: ${FONT_WEIGHTS.normal};
  margin-right: ${SPACES.small};
  max-width: calc(100%);

  @media (min-width: ${BREAKPOINTS.small}) {
    max-width: calc(100% - ${attendanceWidth} - ${SPACES.small});
  }
`

export const CardDivider = styled.hr`
  display: none;

  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    display: flex;
    margin: ${SPACES.small};
  }
`

export const MeetingDetails = styled(Col)`
  ${Attendance} {
    display: none;

    @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
      display: flex;
      justify-content: flex-end;
    }
  }
`

export const DetailsRow = styled(Row)`
  text-align: right;

  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    text-align: left;
  }
`

export const DetailsCol = styled(Col)`
  font-size: ${FONT_SIZES.small};
  font-weight: ${FONT_WEIGHTS.semiBold};
`

export const UnreadItemsCount = styled(UnreadBadge)`
  position: absolute;
  right: calc(100% - 10px);
`
