import React from "react"
import { styled } from "@linaria/react"
import { string, number, func } from "prop-types"

import useDragDrop from "src/features/ManageAccount/AirtableApps/EditPage/useDragDrop"
import { SPACES } from "src/styles/sizes"
import { BACKGROUND_COLORS } from "src/styles/colors"
import Icon from "src/styles/components/Icon"
import useIsOpened from "src/hooks/useIsOpened"
import { airtableSectionFieldType } from "src/resources/accounts/airtablePropTypes"

import EditFieldModal from "./EditFieldModal"

const FieldRow = ({ field, setField, sectionId, index, setFields, className }) => {
  const {
    ref,
    preview,
    handlerId,
    style: dragAndDropStyle
  } = useDragDrop({
    id: field.id,
    index,
    itemType: `field-${sectionId}`,
    setItems: setFields
  })

  const { isOpened: isOpenedModal, open: openModal, close: closeModal } = useIsOpened()

  return (
    <div
      ref={ref}
      data-handler-id={handlerId}
      style={dragAndDropStyle}
      className={className}
    >
      <div className="d-flex align-items-center mt-2 mr-2">
        <div ref={preview} className="flex-grow-1 pl-3 text-truncate field-row">
          {field.name}
        </div>

        <Icon
          type="pencil"
          size="small"
          className="pl-2"
          onClick={openModal}
          data-testid="edit-field"
        />
      </div>

      {isOpenedModal && (
        <EditFieldModal field={field} close={closeModal} setField={setField} />
      )}
    </div>
  )
}

FieldRow.propTypes = {
  field: airtableSectionFieldType.isRequired,
  sectionId: string.isRequired,
  index: number.isRequired,
  setFields: func.isRequired,
  setField: func.isRequired
}

export default styled(FieldRow)`
  .field-row {
    padding: ${SPACES.xSmall};
    margin-left: ${SPACES.xSmall};
    border-radius: 4px;
    background-color: ${BACKGROUND_COLORS.selected};
  }
`
