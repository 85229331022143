import React from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"

import { getAgendaItems } from "src/features/meetingPacks/MeetingPack/selectors"
import { findAgendaItem } from "src/features/meetingPacks/MeetingPack/helpers"
import { updateStatus } from "src/features/meetingPacks/MeetingPack/thunks/agendaItem"

import Dropdown from "./Dropdown"

const AgendaItemStatus = ({ agendaItemId }) => {
  const dispatch = useDispatch()
  const agendaItems = useSelector(getAgendaItems)
  const agendaItem = findAgendaItem(agendaItems, agendaItemId)

  const onSelect = (eventKey) => dispatch(updateStatus(agendaItem, { status: eventKey }))

  return <Dropdown agendaItem={agendaItem} onSelect={onSelect} />
}

AgendaItemStatus.propTypes = {
  agendaItemId: PropTypes.number
}

export default AgendaItemStatus
