import { styled } from "@linaria/react"

export const Container = styled.div`
  min-height: 38px;
  min-width: 38px;
  position: relative;
  margin-right: 0.3rem;
  margin-bottom: 0.3rem;
  background: transparent no-repeat center left;
  background-image: url("~images/file_icons/folder.svg");
  background-size: cover;
`
