// eslint-disable-next-line import/no-extraneous-dependencies
import { Passage } from "@passageidentity/passage-js"

import { MyTokenStore } from "./MyTokenStore"

const appId = () => window.passageAppId

export const passkeyLogin = async (email) => {
  const tokenStore = new MyTokenStore()
  const passage = new Passage(appId(), { tokenStore })
  const result = await passage.login(email)

  if (result.auth_token) {
    tokenStore.setTokens(result.auth_token).then(() => {
      window.location.href = "/"
    })
  }
}

export const addPasskeyDevice = (authToken) => {
  const tokenStore = new MyTokenStore()
  const passage = new Passage(appId(), { tokenStore })

  return tokenStore.setTokens(authToken).then(() => passage.getCurrentUser().addDevice())
}
