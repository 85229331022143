import React from "react"
import { Dropdown } from "react-bootstrap"
import { number } from "prop-types"

import { OBJECT_TYPES } from "src/features/UniversalAi/constants"
import IconAI from "src/styles/components/IconAI"
import IconPencilPlus from "src/styles/components/IconPencilPlus"

import { useUniversalAi } from "../UniversalAi"

const MessageAiActions = ({ messageId }) => {
  const { summariseAsTextInSidebar, summariseAsBulletsInSidebar, queryInSidebar } =
    useUniversalAi()

  const summariseAsText = () => {
    summariseAsTextInSidebar({ objectId: messageId, objectType: OBJECT_TYPES.message })
  }

  const summariseAsBullets = () => {
    summariseAsBulletsInSidebar({ objectId: messageId, objectType: OBJECT_TYPES.message })
  }

  const queryMessage = () => {
    queryInSidebar({ objectId: messageId, objectType: OBJECT_TYPES.message })
  }

  return (
    <>
      <Dropdown.Item onClick={summariseAsText}>
        <div className="dropdown-icon">
          <IconAI />
        </div>
        Summarise as text
      </Dropdown.Item>
      <Dropdown.Item onClick={summariseAsBullets}>
        <div className="dropdown-icon">
          <IconAI />
        </div>
        Summarise as bullets
      </Dropdown.Item>
      <Dropdown.Item onClick={queryMessage}>
        <div className="dropdown-icon">
          <IconPencilPlus />
        </div>
        Query message
      </Dropdown.Item>
    </>
  )
}

MessageAiActions.propTypes = {
  messageId: number.isRequired
}

export default MessageAiActions
