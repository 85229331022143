import React from "react"
import { number } from "prop-types"
import { useQuery } from "@apollo/client"
import { Link } from "react-router-dom"

import { withErrorBoundary } from "src/app/ErrorBoundary"
import * as routes from "src/features/ManageAccount/routes"
import useLayoutHeader from "src/hooks/useLayoutHeader"

import appAirtableInstancesQuery from "./appAirtableInstances.gql"

const AirtableApps = ({ accountId }) => {
  useLayoutHeader()

  const { data } = useQuery(appAirtableInstancesQuery, {
    variables: { accountId }
  })

  return (
    <div className="fluid-container">
      <div className="container mt-3">
        <div className="d-flex justify-content-between list-heading">
          <h4>Applications</h4>
          <Link className="button is-transformer" to={routes.newApp(accountId)}>
            <div className="icon-add" />
            <span className="button-caption">New Application</span>
          </Link>
        </div>
        <div>
          {data?.appAirtableInstances && data?.appAirtableInstances.length === 0 && (
            <div className="card text-center pvxl">
              Looks like there are no applications yet.
            </div>
          )}

          {data?.appAirtableInstances?.map((app) => (
            <div className="card mb-3" key={app.id}>
              <Link className="text-dark" to={routes.showApp(accountId, app.id)}>
                <div className="card-title">
                  <h5>{app.name}</h5>
                </div>
                {app.description && (
                  <div className="card-title-secondary mts">{app.description}</div>
                )}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

AirtableApps.propTypes = {
  accountId: number.isRequired
}

export default withErrorBoundary(AirtableApps)
