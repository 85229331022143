import React from "react"
import { arrayOf, number, shape } from "prop-types"

import { AgendaItem } from "./types"
import ManageUsers from "./ManageUsers"
import ShownUsersList from "./ShownUsersList"
import useAgendaItemAttendeesChanges from "./useAgendaItemAttendeesChanges"
import { AddNew } from "./styles"

const CardView = ({
  currentUserId,
  agendaItem,
  teams,
  agendaItemAttendees = [],
  possibleAttendees = []
}) => {
  const { leavingUsers, joiningUsers } = useAgendaItemAttendeesChanges(agendaItem.id)

  const isSubItem = !!agendaItem.agendaItemId

  const leavingUsersWithStatus = leavingUsers.map((user) => ({
    ...user,
    status: "removed"
  }))
  const joiningUsersWithStatus = joiningUsers.map((user) => ({
    ...user,
    status: "added"
  }))

  const joinedUsersIds = joiningUsers.map((user) => user.id)
  const leavingUsersIds = leavingUsers.map((user) => user.id)

  const filteredAttendees = agendaItemAttendees.filter(
    (user) => !joinedUsersIds.includes(user.id) && !leavingUsersIds.includes(user.id)
  )

  const attendeesChanges = isSubItem ? leavingUsersWithStatus : joiningUsersWithStatus

  if (isSubItem && attendeesChanges.length === 0) {
    return null
  }

  const users = isSubItem ? [] : filteredAttendees

  return (
    <div
      className="card-attendees member-list"
      data-testid={`members-list-${agendaItem.id}`}
    >
      <ManageUsers
        currentUserId={currentUserId}
        agendaItem={agendaItem}
        teams={teams}
        agendaItemAttendees={agendaItemAttendees}
        possibleAttendees={possibleAttendees}
      >
        {(openModal) => (
          <>
            <ShownUsersList
              users={users}
              attendeesChanges={attendeesChanges}
              onUserClick={openModal}
            />
            {!(attendeesChanges.length || users.length) && (
              <AddNew noFocus onClick={openModal}>
                Add attendees
                <span className="round-toggle action">+</span>
              </AddNew>
            )}
          </>
        )}
      </ManageUsers>
    </div>
  )
}

CardView.propTypes = {
  agendaItem: AgendaItem.isRequired,
  agendaItemAttendees: arrayOf(shape({})),
  teams: arrayOf(shape({})),
  possibleAttendees: arrayOf(shape({})),
  currentUserId: number
}

export default CardView
