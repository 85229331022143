import React from "react"
import { Modal } from "react-bootstrap"
import { string } from "prop-types"

const DescriptionModal = ({ title, description, ...modalProps }) => {
  return (
    <Modal scrollable {...modalProps}>
      <Modal.Header closeButton className="align-items-center">
        <h6>{title}</h6>
      </Modal.Header>
      <Modal.Body>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: description }} className="fr-view" />
      </Modal.Body>
    </Modal>
  )
}

DescriptionModal.propTypes = {
  title: string,
  description: string
}

export default DescriptionModal
