import React from "react"
import { oneOf } from "prop-types"

import { ACTION_TYPES } from "src/features/Messages/ActionDetails/constants"

const InstantCloseConfirmation = ({ actionType }) => {
  if (actionType === ACTION_TYPES.voting) {
    return (
      <>
        This vote will be <strong>immediately closed to further votes</strong>.
      </>
    )
  }

  return (
    <>
      This must read action will be <strong>closed immediately</strong>.
    </>
  )
}

InstantCloseConfirmation.propTypes = {
  actionType: oneOf(Object.keys(ACTION_TYPES)).isRequired
}

export default InstantCloseConfirmation
