import React from "react"
import { styled } from "@linaria/react"
import { bool, string } from "prop-types"
import { startCase } from "lodash"

import { Column } from "src/components/AttandeesTable"
import { composeVariationsClasses } from "src/helpers/linaria"
import {
  accountAdminColor,
  BACKGROUND_COLORS,
  BASE_COLORS,
  meetingPreviewColor
} from "src/styles/colors"

const PublicationStatusColumn = ({
  className,
  publicationStatus,
  deletedFromAccount
}) => {
  const classNameWithVariations = composeVariationsClasses(className, {
    status: publicationStatus,
    deletedFromAccount
  })

  return (
    <Column>
      <div className={classNameWithVariations}>
        {deletedFromAccount ? "No access" : startCase(publicationStatus)}
      </div>
    </Column>
  )
}

PublicationStatusColumn.propTypes = {
  publicationStatus: string.isRequired,
  deletedFromAccount: bool
}

export default styled(PublicationStatusColumn)`
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: white;
  display: flex;
  font-size: 0.85rem;
  padding: 4px 6px;
  white-space: nowrap;
  text-transform: uppercase;

  &--status-admin_access {
    background-color: ${accountAdminColor};
    color: ${BASE_COLORS.darkViolet};
  }

  &--status-preview {
    background-color: ${meetingPreviewColor};
  }

  &--status-unpublished {
    background-color: ${BACKGROUND_COLORS.danger};
  }

  &--status-published {
    background-color: ${BACKGROUND_COLORS.success};
  }

  &--deleted-from-account {
    background-color: ${BASE_COLORS.lightGray};
    color: ${BASE_COLORS.darkViolet};
  }
`
