import React, { useContext, useEffect, useRef, useState } from "react"
import { func, number, shape, string, bool, arrayOf } from "prop-types"
import { styled } from "@linaria/react"
import classNames from "classnames"

import useIsOpened from "src/hooks/useIsOpened"
import { SPACES } from "src/styles/sizes"
import {
  useDrag,
  useDropIn
} from "src/features/meetingPacks/MeetingPack/Transcript/View/useDragDrop"
import ScrollContext from "src/features/meetingPacks/MeetingPack/Transcript/View/ScrollContext"

import SpeakerSelect from "./SpeakerSelect"
import DropdownActions from "./DropdownActions"
import EditForm from "./EditForm"
import Timeline from "./Timeline"

const Utterance = ({
  utterance,
  onSave,
  groupedUtterancesUids,
  onAssignAgendaItem,
  index,
  showSpeaker,
  isEdited,
  showTimeline,
  prevLastShownMinute,
  dropOptions,
  className
}) => {
  const { isOpened: editMode, open: edit, close: closeEditMode } = useIsOpened()
  const [speakerSelectShown, setSpeakerSelectShown] = useState(false)

  const { scrollToUtteranceUid, flashUtteranceUids, utterancesListElement } =
    useContext(ScrollContext)
  const scrollTo = scrollToUtteranceUid === utterance.uid
  const highlighted = flashUtteranceUids.includes(utterance.uid)

  const handleDelete = () => onSave([{ uid: utterance.uid }])

  const ref = useRef(null)
  const {
    drag,
    preview,
    style: dragAndDropStyle
  } = useDrag({
    item: utterance,
    index
  })
  const { handlerId, drop } = useDropIn({ ref, index, ...dropOptions })
  drag(drop(ref))

  useEffect(() => {
    if (isEdited) edit()
  }, [isEdited])

  useEffect(() => {
    if (scrollTo) {
      const yOffset = 100
      const y = ref.current.offsetTop - yOffset
      utterancesListElement.scrollTo({ top: y > 0 ? y : 0, behavior: "smooth" })
    }
  }, [scrollToUtteranceUid, ref.current])

  return (
    <div
      className={className}
      data-testid="utterance"
      ref={ref}
      data-handler-id={handlerId}
      style={dragAndDropStyle}
    >
      {showTimeline && (
        <Timeline
          utteranceStart={utterance.start}
          lastShownMinute={prevLastShownMinute}
        />
      )}

      <div
        ref={preview}
        className={classNames("card", {
          "mt-0": index === 0,
          "mt-3": showSpeaker && index > 0,
          "mt-1": !showSpeaker,
          highlighted
        })}
        id={`utterance-${utterance.uid}`}
      >
        <div className="card-body py-2">
          <DropdownActions
            onEdit={edit}
            onDelete={handleDelete}
            speakerShown={showSpeaker}
            showAssignSpeakerSelect={() => setSpeakerSelectShown(true)}
            onAssignAgendaItem={() => onAssignAgendaItem(utterance, index)}
          />

          {(showSpeaker || speakerSelectShown) && (
            <SpeakerSelect
              utterance={utterance}
              speakerSelectShown={speakerSelectShown}
              showSpeaker={showSpeaker}
              onSave={onSave}
              groupedUtterancesUids={groupedUtterancesUids}
              hideAssignSpeakerSelect={() => setSpeakerSelectShown(false)}
            />
          )}

          {editMode ? (
            <EditForm
              utterance={utterance}
              index={index}
              onSave={onSave}
              close={closeEditMode}
            />
          ) : (
            <div>{utterance.text}</div>
          )}
        </div>
      </div>
    </div>
  )
}

Utterance.propTypes = {
  utterance: shape({
    uid: string.isRequired,
    text: string.isRequired,
    start: number,
    end: number,
    speaker: string,
    speakerId: number
  }).isRequired,
  onSave: func.isRequired,
  dropOptions: shape({
    setTranscriptItems: func.isRequired,
    handleSave: func.isRequired
  }).isRequired,
  groupedUtterancesUids: arrayOf(string).isRequired,
  showTimeline: bool.isRequired,
  onAssignAgendaItem: func.isRequired,
  index: number.isRequired,
  showSpeaker: bool.isRequired,
  isEdited: bool.isRequired,
  prevLastShownMinute: number
}

export default styled(Utterance)`
  position: relative;
  margin-left: 85px;

  .card {
    padding: ${SPACES.xxSmall};
  }

  .highlighted {
    background-color: #d7fffc;
    animation: blinkingBackground 2s infinite;
  }

  @keyframes blinkingBackground {
    0% {
      background-color: #ffffff;
    }
    25% {
      background-color: #ecf9fd;
    }
    50% {
      background-color: #d7fffc;
    }
    75% {
      background-color: #ecf9fd;
    }
    100% {
      background-color: #ffffff;
    }
  }
`
