import { useContext, useEffect, useState } from "react"

import SidebarContext from "src/features/Sidebar/SidebarContext"
import useAblyChannel from "src/hooks/useAblyChannel"
import { isOnThePage } from "src/helpers/url"
import { getCurrentUserId } from "src/helpers/user"
import {
  getPendingActionsCount,
  getUnreadChatsCount,
  getUnreadMentionsCount
} from "src/features/unreadStats/helpers"
import { CHANNELS } from "src/constants/ably"

export default function useBaseNavData({
  unreadItems = [],
  starredItems = [],
  meetingPacks = []
}) {
  const currentUserId = getCurrentUserId()
  const { stats } = useContext(SidebarContext)
  const [groupedUnreadItems, setGroupedUnreadItems] = useState({})

  useAblyChannel(CHANNELS.meetingPacks, {
    onMessage: ({ data: messageData }) => {
      setGroupedUnreadItems({
        ...groupedUnreadItems,
        [messageData.meetingPackId]: messageData.totalCounts[currentUserId]
      })
    }
  })

  useEffect(() => {
    setGroupedUnreadItems(
      unreadItems.reduce((items, unreadItem) => {
        // eslint-disable-next-line no-param-reassign
        if (!items[unreadItem.meetingPack.id]) items[unreadItem.meetingPack.id] = 0

        // eslint-disable-next-line no-param-reassign
        items[unreadItem.meetingPack.id] += 1

        return items
      }, {})
    )
  }, [JSON.stringify(unreadItems)])

  const meetingPacksCount = meetingPacks?.length
  const unreadMentionsCount = getUnreadMentionsCount(stats)
  const pendingActionsCount = getPendingActionsCount(stats)
  const starredItemsCount = starredItems?.length
  const unreadChatsCount = getUnreadChatsCount(stats)

  const isOnTheMeetingsPage = isOnThePage("meetings")
  const isOnTheMentionsPage = isOnThePage("mentions")
  const isOnTheStarredItemsPage = isOnThePage("starredItems")
  const isOnTheChatsPage = isOnThePage("chats")

  return {
    meetingPacksCount,
    unreadMentionsCount,
    pendingActionsCount,
    starredItemsCount,
    unreadChatsCount,
    isOnTheMeetingsPage,
    isOnTheMentionsPage,
    isOnTheStarredItemsPage,
    isOnTheChatsPage
  }
}
