import { func, oneOf } from "prop-types"
import React from "react"

import UnstyledButton from "src/components/UnstyledButton"
import { SortingType } from "src/features/groups/DocumentsExplorer/types"
import {
  SORTING_FIELDS_VALUES,
  SortingDirections
} from "src/features/groups/DocumentsExplorer/consts"

import { IconDown, IconUp } from "./styles"

const SortingToggle = ({ field, sorting, children, onToggle }) => {
  const isCurrentSortingField = field === sorting.field

  const handleToggle = () => {
    onToggle(field)
  }

  return (
    <UnstyledButton onClick={handleToggle}>
      {children}
      {isCurrentSortingField && (
        <>
          {sorting.direction === SortingDirections.Asc && <IconUp />}
          {sorting.direction === SortingDirections.Desc && <IconDown />}
        </>
      )}
    </UnstyledButton>
  )
}

SortingToggle.propTypes = {
  field: oneOf(SORTING_FIELDS_VALUES).isRequired,
  sorting: SortingType.isRequired,
  onToggle: func.isRequired
}

export default SortingToggle
