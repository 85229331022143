import React from "react"
import { func, shape, number, bool, string } from "prop-types"
import Dropdown from "react-bootstrap/Dropdown"
import { styled } from "@linaria/react"

import ToggleButton from "./ToggleButton"
import { Status } from "./styles"

const STATUSES = {
  no_status: "No Status",
  todo: "To do",
  in_progress: "In Progress",
  complete: "Complete"
}

const iconClass = (status) => `icon-${status.replace("_", "-")}`

const AgendaItemStatusDropdown = ({ agendaItem, onSelect, className, style }) => (
  <Dropdown onSelect={onSelect} className={className} style={style} data-testid="">
    <Dropdown.Toggle as={ToggleButton}>
      <div
        data-testid={`agenda-item-${agendaItem.id}-status-toggle`}
        className={`${iconClass(agendaItem.status)} agenda-item-status`}
      />
    </Dropdown.Toggle>

    <Dropdown.Menu
      alignRight
      container="body"
      data-testid={`agenda-item-${agendaItem.id}-status-dropdown-menu`}
    >
      {Object.keys(STATUSES).map((status) => (
        <Dropdown.Item
          key={status}
          active={status === agendaItem.status}
          eventKey={status}
        >
          <span className={iconClass(status)} />
          <Status>{STATUSES[status]}</Status>
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  </Dropdown>
)

AgendaItemStatusDropdown.propTypes = {
  onSelect: func,
  style: shape({}),
  className: string,
  agendaItem: shape({
    id: number,
    status: string,
    break: bool
  })
}

export default styled(AgendaItemStatusDropdown)`
  cursor: pointer;
  display: block;
  visibility: ${({ agendaItem }) => (agendaItem.break ? "hidden" : "visible")};
`
