import React, { useEffect } from "react"
import classNames from "classnames"
import { bool, func } from "prop-types"
import { useSelector } from "react-redux"

import SidebarToggleButton from "src/styles/components/SidebarToggleButton"
import isOfflineEnabled from "src/features/offline/isOfflineEnabled"
import { be } from "src/helpers/document"
import {
  getActiveItemId,
  getAgendaItems,
  getIsCurrentUserAdmin,
  getIsCurrentUserGuest,
  getMeetingPack,
  getUnreadAgendaItemIds
} from "src/features/meetingPacks/MeetingPack/selectors"
import { withErrorBoundary } from "src/app/ErrorBoundary"
import { isUserExportEnabled } from "src/helpers/meetingPacks"

import AgendaItemRow from "./AgendaItemRow"
import BreakRow from "./AgendaItemRow/BreakRow"
import OfflineToggle from "./OfflineToggle"
import ExportAgenda from "./ExportAgenda"
import SyncStatus from "./OfflineToggle/SyncStatus"
import {
  SidebarWrapper,
  Sidebar,
  SidebarHeader,
  SidebarHeaderActions,
  SidebarContainer
} from "./styles"
import ExportMeetingPack from "./ExportMeetingPack"

const isBreakItemDisabled = (agendaItems, breakItemIndex) => {
  return !(
    agendaItems[breakItemIndex - 1]?.attending ||
    agendaItems[breakItemIndex + 1]?.attending
  )
}

const activeAgendaItemPosition = () => {
  const activeAgendaItem = be("agenda-items-list").find(".row[class*='--active']")[0]
  const paddingTop = 220

  return activeAgendaItem && activeAgendaItem.offsetTop
    ? activeAgendaItem.offsetTop - paddingTop
    : null
}

const scrollToActiveAgendaItem = ({ withAnimation }) => {
  const scrollPosition = activeAgendaItemPosition()
  if (!scrollPosition) return

  const agendaItemList = be("agenda-items-list")

  return withAnimation
    ? agendaItemList.animate({ scrollTop: scrollPosition })
    : agendaItemList.scrollTop(scrollPosition)
}

const ContentsSidebar = ({ opened, toggleSidebar, className = "" }) => {
  const meetingPack = useSelector(getMeetingPack)
  const agendaItems = useSelector(getAgendaItems)
  const activeItemId = useSelector(getActiveItemId)
  const unreadItemIds = useSelector(getUnreadAgendaItemIds)
  const isCurrentUserAdmin = useSelector(getIsCurrentUserAdmin)
  const isCurrentUserGuest = useSelector(getIsCurrentUserGuest)
  const isExportAllowed = isUserExportEnabled({ meetingPack, isCurrentUserGuest })

  useEffect(() => {
    if (agendaItems?.length && activeItemId)
      scrollToActiveAgendaItem({ withAnimation: false })
  }, [])

  useEffect(() => {
    if (agendaItems?.length && activeItemId)
      scrollToActiveAgendaItem({ withAnimation: true })
  }, [agendaItems, activeItemId])

  if (!agendaItems?.length) return null

  const syncStatusBadge = isOfflineEnabled() && !opened && (
    <SyncStatus meetingPackId={meetingPack.id} showText={false} />
  )

  return (
    <SidebarWrapper className={`${className} flex-sm-column`}>
      <SidebarHeader>
        <SidebarHeaderActions className={classNames({ "d-none": !opened })}>
          Agenda
          <ExportAgenda
            meetingPackId={meetingPack.id}
            meetingPackName={meetingPack.name}
          />
          {isExportAllowed && <ExportMeetingPack meetingPackId={meetingPack.id} />}
        </SidebarHeaderActions>
        <div className="d-flex">
          <OfflineToggle className={classNames({ "d-none": !opened })} />
          <SidebarToggleButton
            onClick={toggleSidebar}
            badge={syncStatusBadge}
            border="right"
          />
        </div>
      </SidebarHeader>

      <Sidebar
        className={classNames({ "d-none": !opened })}
        data-behavior="agenda-items-list"
      >
        <SidebarContainer>
          {agendaItems.map((item, i) =>
            item.break ? (
              <BreakRow
                key={item.id}
                item={item}
                timeZone={meetingPack.timeZone}
                disabled={isBreakItemDisabled(agendaItems, i)}
                unread={unreadItemIds.includes(item.id)}
              />
            ) : (
              <AgendaItemRow
                key={item.id}
                item={item}
                timeZone={meetingPack.timeZone}
                active={item.id === activeItemId}
                unread={unreadItemIds.includes(item.id)}
                isCurrentUserAdmin={isCurrentUserAdmin}
              />
            )
          )}
        </SidebarContainer>
      </Sidebar>
    </SidebarWrapper>
  )
}

ContentsSidebar.propTypes = {
  opened: bool.isRequired,
  toggleSidebar: func.isRequired
}

export default withErrorBoundary(ContentsSidebar)
