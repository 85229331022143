import { connect } from "react-redux"

import { getMeetingPack } from "src/features/meetingPacks/MeetingPack/selectors"
import { canStoreMeetingPack } from "src/features/offline/helpers/meetingPacks"

import OfflineToggle from "./OfflineToggle"

const mapState = (state) => {
  const meetingPack = getMeetingPack(state)

  return {
    meetingPackId: meetingPack.id,
    canSaveOffline: canStoreMeetingPack(meetingPack)
  }
}

export default connect(mapState, null)(OfflineToggle)
