import { useEffect, useState } from "react"

const useStreaming = ({ onChangeContent }) => {
  const [content, setContent] = useState("")
  const [finished, setFinished] = useState(false)

  const [contentToStream, setContentToStream] = useState("")
  const [contentStreamFinished, setContentStreamFinished] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)

  const updateContent = (value, options = { stream: true }) => {
    if (options.stream) {
      setContentToStream(value)
    } else {
      setContent(value)
      setFinished(true)
    }
  }

  useEffect(() => {
    if (contentToStream && currentIndex <= contentToStream.length) {
      const timeout = setTimeout(() => {
        setContent(contentToStream.slice(0, currentIndex + 1))
        setCurrentIndex((prevIndex) => prevIndex + 2)
        if (onChangeContent) onChangeContent()
      }, 50)

      return () => clearTimeout(timeout)
    }
  }, [currentIndex, contentToStream, finished])

  useEffect(() => {
    if (contentStreamFinished && contentToStream === content) setFinished(true)
  }, [contentStreamFinished, contentToStream, content])

  return {
    content,
    setContent: updateContent,
    finished,
    setFinished: setContentStreamFinished
  }
}

export default useStreaming
