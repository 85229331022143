import React from "react"
import { number } from "prop-types"
import { styled } from "@linaria/react"

const CounterContainer = styled.div`
  padding: 0;
  margin-left: 0.25rem;
`

const MembersCounter = ({ count }) => {
  const viewCount = count > 99 ? ">99" : `+${count}`
  return (
    <div className="members-counter">
      <CounterContainer>
        <span className="round-toggle action">
          <span className="counter-text">{viewCount}</span>
        </span>
      </CounterContainer>
    </div>
  )
}

MembersCounter.propTypes = {
  count: number.isRequired
}

export default MembersCounter
