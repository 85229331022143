import { normalizeUser } from "src/helpers/user"

export const normalizeGroup = (props) => ({
  ...props,
  users: props.users.map((user) => normalizeUser(user))
})

export const getGroupOption = (group) => {
  if (!group) return

  return {
    ...normalizeGroup(group),
    value: group.id,
    label: group.name
  }
}

export const getGroupOptionId = (option) => option?.value
