import { createSlice } from "@reduxjs/toolkit"

import { setIsLoading, setFailure, setCollection } from "src/helpers/slice"

const initialState = {
  allIds: [],
  byId: {},
  isLoading: false,
  error: null
}

const slice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    fetchAccountsRequest: setIsLoading,
    fetchAccountsFailure: setFailure,
    fetchAccountsSuccess: setCollection
  }
})

const { actions, reducer } = slice

export const {
  fetchAccountsRequest,
  fetchAccountsFailure,
  fetchAccountsSuccess
} = actions

export default reducer
