import React from "react"
import { styled } from "@linaria/react"
import { number, string } from "prop-types"

import UserAvatar from "src/components/UsersContacts/UserAvatar"
import AvatarImageContainer from "src/components/UsersContacts/AvatarImageContainer"
import { FONT_SIZES, messageActorAvatar, SPACES } from "src/styles/sizes"
import { UserInitials } from "src/components/UsersContacts/styles"
import { BACKGROUND_COLORS } from "src/styles/colors"

const MessageActorCard = ({
  className,
  avatarColorId,
  fullName,
  avatarUrl,
  initials
}) => {
  return (
    <div className={className}>
      <UserAvatar user={{ avatarColorId, fullName, avatarUrl, initials }} />
      {fullName}
    </div>
  )
}

MessageActorCard.propTypes = {
  fullName: string.isRequired,
  avatarColorId: number,
  avatarUrl: string,
  initials: string
}

export default styled(MessageActorCard)`
  display: flex;
  align-items: center;
  border-radius: ${SPACES.xxSmall};
  background: ${BACKGROUND_COLORS.main};
  padding: 0.4rem;
  height: 26px;

  ${UserAvatar} {
    margin-right: ${SPACES.xxSmall};
    width: ${messageActorAvatar};
    height: ${messageActorAvatar};

    ${AvatarImageContainer}, ${UserInitials} {
      width: ${messageActorAvatar};
      height: ${messageActorAvatar};
      min-width: ${messageActorAvatar};
    }

    ${UserInitials} {
      font-size: ${FONT_SIZES.xxSmall};
      padding: 0;
    }
  }
`
