import React, { useState } from "react"
import { shape, string } from "prop-types"

import {
  CATEGORIES,
  ORDER_OPTION_DATE,
  ORDER_OPTION_RELEVANCE
} from "src/features/Search/constants"

import useWhoFilter from "./useWhoFilter"
import useSearch from "./useSearch"
import useWhenFilter from "./useWhenFilter"
import useWhereFilter from "./useWhereFilter"
import { categoriesCount } from "./helpers"
import Categories from "./Categories"
import Results from "./Results"
import Filters from "./Filters"
import { Searching } from "./messages"
import { ResultsFilterContainer, ResultsContainer, FiltersContainer } from "./styles"
import useShowAiAnswer from "./AiAnswer/useShowAiAnswer"
import AiAnswer from "./AiAnswer"

const Search = ({ query, exitLink, userIds, whereIds, ...props }) => {
  const { whenFilter, setWhenFilter, dateTo, dateFrom, setDateFrom, setDateTo } =
    useWhenFilter({ dateFrom: props.dateFrom, dateTo: props.dateTo })
  const { whereFilter, whereFilterOptions, addWhereFilterValue, removeWhereFilterValue } =
    useWhereFilter(whereIds)
  const { whoFilter, whoFilterOptions, addWhoFilterValue, removeWhoFilterValue } =
    useWhoFilter({ userIds })

  const [selectedCategory, setSelectedCategory] = useState(
    props.category || CATEGORIES.everything
  )
  const [order, setOrder] = useState(
    props.order === "date" ? ORDER_OPTION_DATE : ORDER_OPTION_RELEVANCE
  )

  const { showAiAnswer, loading: showAiAnswerLoading } = useShowAiAnswer(
    query,
    whereFilter
  )

  const [aiAnswerSortedLinks, setAiAnswerSortedLinks] = useState(null)

  const { loading, results, orderedResults } = useSearch({
    query,
    dateFrom,
    dateTo,
    whereFilter,
    whoFilter,
    order: order?.value,
    category: selectedCategory,
    showAiAnswer,
    skip: showAiAnswerLoading,
    aiAnswerSortedLinks
  })

  return (
    <>
      <Categories
        exitLink={exitLink}
        categoriesCount={categoriesCount(results)}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
      <ResultsFilterContainer>
        <FiltersContainer>
          <Filters
            whereFilter={whereFilter}
            addWhereFilterValue={addWhereFilterValue}
            removeWhereFilterValue={removeWhereFilterValue}
            whereFilterOptions={whereFilterOptions}
            whenFilter={whenFilter}
            setWhenFilter={setWhenFilter}
            dateTo={dateTo}
            dateFrom={dateFrom}
            whoFilter={whoFilter}
            whoFilterOptions={whoFilterOptions}
            addWhoFilterValue={addWhoFilterValue}
            removeWhoFilterValue={removeWhoFilterValue}
            setDateFrom={setDateFrom}
            setDateTo={setDateTo}
          />
        </FiltersContainer>
        <ResultsContainer>
          {showAiAnswer && (
            <AiAnswer
              question={query}
              whereIds={whereFilter}
              whoIds={whoFilter}
              dateTo={dateTo?.format("YYYY-MM-DD") || ""}
              dateFrom={dateFrom?.format("YYYY-MM-DD") || ""}
              setAiAnswerSortedLinks={setAiAnswerSortedLinks}
            />
          )}
          {loading || showAiAnswerLoading ? (
            <Searching />
          ) : (
            <Results results={orderedResults} order={order} setOrder={setOrder} />
          )}
        </ResultsContainer>
      </ResultsFilterContainer>
    </>
  )
}

Search.propTypes = {
  category: string,
  dateFrom: string,
  dateTo: string,
  exitLink: string,
  order: string,
  query: string,
  userIds: shape({}),
  whereIds: shape({})
}

export default Search
