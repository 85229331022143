import React from "react"
import { arrayOf, number, shape } from "prop-types"

import useMediaQuery from "src/hooks/useMediaQuery"
import { BREAKPOINTS } from "src/styles/sizes"

import { AgendaItem } from "./types"
import ShownUsersList from "./ShownUsersList"
import ManageUsers from "./ManageUsers"
import { CardAttendees } from "./styles"
import ManageUsersButton from "./ManageUsers/ManageUsersButton"
import useAgendaItemAttendeesChanges from "./useAgendaItemAttendeesChanges"

const SMALL_SCREEN_SHOWN_ATTENDEES_LIMIT = 3
const MEDIUM_SCREEN_SHOWN_ATTENDEES_LIMIT = 5

const ExtendedView = ({
  currentUserId,
  agendaItem,
  teams,
  agendaItemAttendees = [],
  possibleAttendees = []
}) => {
  const isSmallScreen = useMediaQuery(`(max-width: calc(${BREAKPOINTS.small} - 1px))`)
  const { joiningUsers, leavingUsers } = useAgendaItemAttendeesChanges(agendaItem.id)

  const joiningUsersWithStatus = joiningUsers.map((user) => ({
    ...user,
    status: "added"
  }))

  const leavingUsersWithStatus = leavingUsers.map((user) => ({
    ...user,
    status: "removed"
  }))

  const isSubItem = !!agendaItem.agendaItemId
  const attendeesChanges = isSubItem ? leavingUsersWithStatus : joiningUsersWithStatus

  const joiningUsersIds = joiningUsers.map((user) => user.id)
  const leavingUsersIds = leavingUsers.map((user) => user.id)

  const filteredAttendees = agendaItemAttendees.filter(
    (user) => !joiningUsersIds.includes(user.id) && !leavingUsersIds.includes(user.id)
  )

  const shownAttendeesLimit = isSmallScreen
    ? SMALL_SCREEN_SHOWN_ATTENDEES_LIMIT
    : MEDIUM_SCREEN_SHOWN_ATTENDEES_LIMIT

  return (
    <CardAttendees>
      <ShownUsersList
        users={filteredAttendees}
        attendeesChanges={attendeesChanges}
        shownUsersLimit={shownAttendeesLimit}
      />

      <ManageUsers
        currentUserId={currentUserId}
        agendaItem={agendaItem}
        teams={teams}
        agendaItemAttendees={agendaItemAttendees}
        possibleAttendees={possibleAttendees}
      >
        {(openModal) => (
          <ManageUsersButton data-testid="view-manage-users" onClick={openModal} />
        )}
      </ManageUsers>
    </CardAttendees>
  )
}

ExtendedView.propTypes = {
  agendaItem: AgendaItem.isRequired,
  agendaItemAttendees: arrayOf(shape({})),
  teams: arrayOf(shape),
  possibleAttendees: arrayOf(shape({})),
  currentUserId: number
}

export default ExtendedView
