export const FILE_TYPE_OPTIONS = [
  { value: null, label: "All file types" },
  { value: "documents", label: "Documents" },
  { value: "spreadsheets", label: "Spreadsheets" },
  { value: "images", label: "Images" }
]

export const LINK_KIND_OPTIONS = [
  { value: null, label: "All link types" },
  { value: "external", label: "External links" },
  { value: "internal", label: "Knowa links" }
]

export const AUTHOR_BLANK_OPTION = {
  value: null,
  label: "Everyone"
}

export const TABS = {
  DOCS: "docs",
  LINKS: "links",
  EMAILS: "emails"
}

export const TABS_ICONS = {
  DOCS: "paperclip",
  LINKS: "link",
  EMAILS: "email"
}

export const CONTENT_LINK_TYPES = {
  INTERNAL: "internal",
  EXTERNAL: "external"
}

export const ACTIVE_TAB_KEY = "discussion-drawer-active-tab"
export const DRAWER_CONTENT_WIDTH = "240px"

export const BACKLINKS_HEADER_HEIGTH = "50px"
export const BACKLINKS_WIDTH = "300px"
