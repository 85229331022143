import React from "react"
import { Form } from "react-bootstrap"
import { string, func, bool } from "prop-types"

import { airtableFieldType } from "src/resources/accounts/airtablePropTypes"

import FieldLabel from "./FieldLabel"

const TextInput = ({ field, value, invalid, setRecord }) => {
  const handleChange = (e) => {
    const newValue = e.target.value
    setRecord((prevRecord) => ({
      ...prevRecord,
      [field.id]: newValue
    }))
  }

  return (
    <>
      <FieldLabel field={field} invalid={invalid} />
      <Form.Control
        placeholder="Type something"
        value={value || ""}
        onChange={handleChange}
      />
    </>
  )
}

TextInput.propTypes = {
  field: airtableFieldType.isRequired,
  value: string,
  invalid: bool,
  setRecord: func.isRequired
}

export default TextInput
