import { styled } from "@linaria/react"

import { BREAKPOINTS, SPACES } from "src/styles/sizes"

export const ResultsContainer = styled.div`
  width: 75%;
  overflow-y: scroll;

  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    width: 100%;
  }
`

export const FiltersContainer = styled.div`
  padding-left: ${SPACES.small};
  width: 25%;

  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    width: 100%;
    padding: 0 ${SPACES.medium};
    margin-bottom: ${SPACES.small};
  }
`

export const ResultsFilterContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: ${SPACES.medium} ${SPACES.large};
  max-height: calc(100vh - 91px);

  @media (max-width: calc(${BREAKPOINTS.xLarge} - 1px)) {
    padding: ${SPACES.medium} ${SPACES.small};
  }

  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    flex-flow: column;
    overflow: scroll;
    padding: ${SPACES.medium} ${SPACES.xSmall} 0;
    height: calc(100vh - 175px);
  }
`
