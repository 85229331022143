import apiClient from "src/app/apiClient"

const index = ({ documentId }) =>
  apiClient.get(`/api/documents/${documentId}/annotations`)

const create = ({ documentId, annotation }) =>
  apiClient.post(`/api/documents/${documentId}/annotations`, { annotation })

const update = ({ documentId, annotationId, annotation }) =>
  apiClient.put(`/api/documents/${documentId}/annotations/${annotationId}`, {
    annotation
  })

const destroy = ({ documentId, annotationId }) =>
  apiClient.delete(`/api/documents/${documentId}/annotations/${annotationId}`)

const get = ({ documentId, annotationId }) =>
  apiClient.get(`/api/documents/${documentId}/annotations/${annotationId}`)

const annotationsApi = {
  index,
  create,
  update,
  destroy,
  get
}

export default annotationsApi
