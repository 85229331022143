import React from "react"

import Message from "./Message"

export const Searching = () => {
  return <Message>Searching...</Message>
}

export const NoResults = () => {
  return <Message>No results found.</Message>
}
