import React from "react"
import { func, string } from "prop-types"

import Icon from "src/styles/components/Icon"
import HeaderContext from "src/features/Header/HeaderContext"
import HeaderNav from "src/features/Header/HeaderNav"
import useUnreadStats from "src/hooks/useUnreadStats"
import { toggleBadgeClass } from "src/features/Header/helpers"

import SearchInput from "./SearchInput"
import {
  GoBackLink,
  HeaderContainer,
  SidebarToggle,
  MobileInputContainer
} from "./styles"

const Header = ({ exitLink, searchQuery, setSearchQuery }) => {
  const stats = useUnreadStats()

  return (
    <HeaderContext.Provider value={stats}>
      <HeaderContainer className="header">
        <SidebarToggle badgeClass={toggleBadgeClass({ stats })} />
        <GoBackLink href={exitLink}>
          <Icon type="cancel" />
        </GoBackLink>
        <div className="header-space-name">Search</div>
        <SearchInput searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        <HeaderNav exitPath={exitLink} />
      </HeaderContainer>
      <MobileInputContainer>
        <SearchInput searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
      </MobileInputContainer>
    </HeaderContext.Provider>
  )
}

Header.propTypes = {
  exitLink: string.isRequired,
  setSearchQuery: func.isRequired,
  searchQuery: string
}

export default Header
