import React, { useState } from "react"
import { number, string } from "prop-types"
import { pick } from "lodash"

import useIsOpened from "src/hooks/useIsOpened"
import useAblyChannel from "src/hooks/useAblyChannel"
import { CHANNELS } from "src/constants/ably"

import PreviewModal from "./PreviewModal"
import DocumentHeader from "./DocumentHeader"
import AnnotationsCountersContainer from "./AnnotationsCountersContainer"
import CommentsCount from "./CommentsCount"
import OtherAnnotationsCount from "./OtherAnnotationsCount"

const CollaborativeDocumentPreview = ({
  id,
  className,
  name,
  commentsCount = 0,
  otherAnnotationsCount = 0,
  ...props
}) => {
  const { isOpened, close, open } = useIsOpened()
  const [annotationsStats, setAnnotationsStats] = useState({
    commentsCount,
    otherAnnotationsCount
  })

  useAblyChannel(CHANNELS.collaborativeDocuments, {
    onMessage: ({ name: actionName, data }) => {
      if (actionName === "update" && data.documentId === id) {
        setAnnotationsStats({
          commentsCount: data.commentsCount,
          otherAnnotationsCount: data.otherAnnotationsCount
        })
      }
    }
  })

  return (
    <div className={className}>
      <DocumentHeader onClick={open} name={name} />
      <AnnotationsCountersContainer>
        <CommentsCount count={annotationsStats.commentsCount} />
        <OtherAnnotationsCount count={annotationsStats.otherAnnotationsCount} />
      </AnnotationsCountersContainer>
      <PreviewModal
        id={id}
        isOpened={isOpened}
        close={close}
        name={name}
        {...pick(props, ["jwt", "pspdfkitId", "users"])}
      />
    </div>
  )
}

CollaborativeDocumentPreview.propTypes = {
  id: number.isRequired,
  name: string.isRequired,
  commentsCount: number,
  otherAnnotationsCount: number
}

export default CollaborativeDocumentPreview
