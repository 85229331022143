import { createSlice } from "@reduxjs/toolkit"
import { keyBy, union } from "lodash"

import { PER_PAGE_LIMIT } from "src/constants"
import { setIsLoading, setSuccess, setFailure } from "src/helpers/slice"
import {
  fetchMessageFailure,
  fetchMessageSuccess,
  updateMessageStarredSuccess
} from "src/resources/messages/slice"

const initialState = {
  allIds: [],
  byId: {},
  hasNextPage: true,
  isLoading: false,
  error: null
}

const slice = createSlice({
  name: "mentions",
  initialState,
  reducers: {
    resetState: () => initialState,
    fetchMentionsFailure: (state, action) => {
      setFailure(state, action)

      state.hasNextPage = false
    },
    fetchMentionsRequest: setIsLoading,
    fetchMentionsSuccess: (state, action) => {
      setSuccess(state)

      const mentions = action.payload
      const newMentionsIds = mentions.map((mention) => mention.id)
      const newMentionsById = keyBy(mentions, "id")

      state.hasNextPage = newMentionsIds.length === PER_PAGE_LIMIT
      state.allIds = union(state.allIds, newMentionsIds)
      state.byId = { ...state.byId, ...newMentionsById }
    },
    removeMentionSuccess: (state, action) => {
      const { messageId } = action.payload
      const mentionIndex = state.allIds.findIndex((id) => id === messageId)

      if (mentionIndex !== -1) state.allIds.splice(mentionIndex, 1)
      if (state.byId[messageId]) delete state.byId[messageId]
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMessageFailure, setFailure)
    builder.addCase(fetchMessageSuccess, (state, action) => {
      const mention = action.payload

      if (!state.allIds.includes(mention.id)) {
        state.allIds.unshift(mention.id)
      }
      state.byId[mention.id] = { ...state.byId[mention.id], ...mention }
    })
    builder.addCase(updateMessageStarredSuccess, (state, action) => {
      const { id, starred } = action.payload

      if (state.byId[id]) {
        state.byId[id].starred = !starred
      }
    })
  }
})

const { actions, reducer } = slice

export const {
  resetState,
  fetchMentionsRequest,
  fetchMentionsFailure,
  fetchMentionsSuccess,
  removeMentionSuccess
} = actions

export default reducer
