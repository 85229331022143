import { BOX_API_HOST, BOX_STATIC_HOST } from "src/constants"
import { rewriteSessionEndpoints, rewriteUploadInterceptor } from "src/helpers/box"

const sharedBoxOptions = () => ({
  apiHost: BOX_API_HOST,
  staticHost: BOX_STATIC_HOST,
  sortBy: "date",
  sortDirection: "DESC",
  canShare: false,
  requestInterceptor: rewriteUploadInterceptor,
  responseInterceptor: rewriteSessionEndpoints
})

export default sharedBoxOptions
