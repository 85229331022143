import React, { useContext } from "react"
import { Modal } from "react-bootstrap"

import { ACTION_TYPES } from "../constants"
import NewMessageActionContext from "../NewMessageActionContext"

const TITLE_ACTIONS = {
  [ACTION_TYPES.voting]: "Vote",
  [ACTION_TYPES.must_read]: "Must read"
}

const Header = () => {
  const { message, closeActionsModal } = useContext(NewMessageActionContext)

  const titleAction = TITLE_ACTIONS[message.actionType]

  return (
    <Modal.Header closeButton onHide={closeActionsModal}>
      <Modal.Title as="h5">{titleAction} closing options</Modal.Title>
    </Modal.Header>
  )
}

export default Header
