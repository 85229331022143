import React from "react"
import { arrayOf, string, func, bool } from "prop-types"

import Checkbox from "src/styles/components/Checkbox"
import { airtableFieldType } from "src/resources/accounts/airtablePropTypes"

import FieldLabel from "./FieldLabel"

const MultipleSelect = ({ field, value, invalid, setRecord }) => {
  const handleChange = (option) =>
    setRecord((prevRecord) => {
      const prevValue = prevRecord[field.id] || []
      const newValue = prevValue.includes(option.name)
        ? prevValue.filter((v) => v !== option.name)
        : [...prevValue, option.name]

      return {
        ...prevRecord,
        [field.id]: newValue
      }
    })

  return (
    <>
      <FieldLabel field={field} invalid={invalid} />
      {field.options.map((option) => (
        <div key={option.id} className="mb-2">
          <Checkbox
            id={option.id}
            checked={value && value.includes(option.name)}
            onChange={() => handleChange(option)}
          >
            <span className="pl-2">{option.name}</span>
          </Checkbox>
        </div>
      ))}
    </>
  )
}

MultipleSelect.propTypes = {
  field: airtableFieldType.isRequired,
  value: arrayOf(string),
  invalid: bool,
  setRecord: func.isRequired
}

export default MultipleSelect
