import { useDispatch } from "react-redux"

import { actions } from "./slice"
import useUniversalAiSelector from "./useUniversalAiSelector"

const useCommandResult = () => {
  const { commandResult } = useUniversalAiSelector()
  const dispatch = useDispatch()

  const setCommandResult = (newCommandResult) => {
    dispatch(actions.setCommandResult(newCommandResult))
  }

  return { commandResult, setCommandResult }
}

export default useCommandResult
