class @AttributionOfChangeInDeficitComponent
  constructor: (params) ->
    @data      = params.data
    @elementId = params.elementId
    @desktopViewFlag = $be('desktop-view-flag')

  setup: () ->
    fromDate = moment(@data.from_date)
    toDate   = moment(@data.to_date)
    dateFormat = 'DD MMM YY'

    xTextBr = [
      "TP deficit <br>#{fromDate.format(dateFormat)}",
      'Recovery <br>contributions',
      'Expected <br>performance',
      "TP deficit <br>objective <br>#{toDate.format(dateFormat)}",
      'Growth assets <br>performance',
      'Liability <br>performance',
      'Matching assets <br>performance',
      'Actuarial <br>experience',
      "Actual <br>TP deficit <br>#{toDate.format(dateFormat)}"
    ]

    xTextPlain = [
      "TP deficit #{fromDate.format(dateFormat)}",
      'Recovery contributions',
      'Expected performance',
      "TP deficit objective #{toDate.format(dateFormat)}",
      'Growth assets performance',
      'Liability performance',
      'Matching assets performance',
      'Actuarial experience',
      "Actual TP deficit #{toDate.format(dateFormat)}"
    ]

    xValues = Object.keys(xTextBr).map((key) => parseInt(key))

    [hiddenBar, visibleBar] = @waterfallValues()

    trace1 = {
      x: xValues,
      y: hiddenBar,
      type: 'bar',
      hoverinfo: 'none',
      opacity: 0,
    };

    trace2 = {
      x: xValues,
      y: visibleBar,
      text: visibleBar.map(@barText),
      textfont: {
        size: 11,
        color: visibleBar.map(@barTextColor)
      }
      type: 'bar',
      textposition: 'outside',
      hoverinfo: 'none',
      marker: {
        color: [
           '#254D83',
           '#FCBE00',
           '#4BACC6',
           '#A5A5A5',
           '#92D050',
           '#6490CB',
           '#5FA364',
           '#666699',
           '#264E84'
        ]
      }
    };

    data = [@chartAnnotations(), trace1, trace2];

    if @desktopViewFlag.is(':visible')
      tickangle = 'auto'
      xText = xTextBr
    else
      tickangle = 90
      xText = xTextPlain

    layout = {
      showlegend: false,
      barmode: 'stack',
      xaxis: {
        tickvals: xValues,
        ticktext: xText,
        automargin: true,
        tickangle: tickangle,
        tickfont: {
          size: 11
        }
      },
      yaxis: {
        title: '£'
      },
      shapes: [
        @objectiveBracket()...,
        @unexpectedPerformanceBracket()...
        @liabilitiesBracket()...
      ],
      margin: {
        t: 10
        r: 0
        l: 60
      }
    }

    Plotly.newPlot(
      @elementId,
      data,
      layout,
      { displayModeBar: false, responsive: true }
    )

  waterfallValues: () ->
    [[
      0,
      @data.deficit,
      @data.deficit + @data.recovery_contributions,
      0,
      @data.deficit_objective,
      @data.deficit_objective + @data.growth_assets_performance,
      @data.deficit_objective + @data.growth_assets_performance + @data.liability_performance,
      @data.deficit_objective +
        @data.growth_assets_performance +
        @data.liability_performance +
        @data.matching_assets_performance,
      0
    ], [
      @data.deficit,
      @data.recovery_contributions,
      @data.expected_performance,
      @data.deficit_objective,
      @data.growth_assets_performance,
      @data.liability_performance,
      @data.matching_assets_performance,
      @data.actuarial_experience,
      @data.actual_deficit
    ]]

  objectiveBracket: () ->
    @bracketFromCoords(@objectiveBracketCoords())

  unexpectedPerformanceBracket: () ->
    @bracketFromCoords(@unexpectedPerformanceBracketCoords())

  liabilitiesBracket: () ->
    @bracketFromCoords(@liabilitiesBracketCoords())

  objectiveBracketCoords: () ->
    bracketMargin = 30000000
    bracketStart = 0.5
    bracketEnd = 2.5

    maxPoint = Math.max(
      @unexpectedPerformanceMaxPoint(),
      @liabilitiesBracketCoords().centerVerticalLine.y1 + 3 * bracketMargin
    )

    @bracketShapes(maxPoint, bracketMargin, bracketStart, bracketEnd)

  unexpectedPerformanceBracketCoords: () ->
    bracketMargin = 30000000
    bracketStart = 3.5
    bracketEnd = 7.5

    maxPoint = Math.max(
      @unexpectedPerformanceMaxPoint(),
      @liabilitiesBracketCoords().centerVerticalLine.y1 + 3 * bracketMargin
    )

    @bracketShapes(maxPoint, bracketMargin, bracketStart, bracketEnd)

  liabilitiesBracketCoords: () ->
    maxPoint = @liabilitiesBracketMaxPoint()

    bracketMargin = 30000000
    bracketStart = 4.5
    bracketEnd = 6.5

    @bracketShapes(maxPoint, bracketMargin, bracketStart, bracketEnd)

  unexpectedPerformanceMaxPoint: () ->
    @maxPointInRange(4, 7)

  liabilitiesBracketMaxPoint: () ->
    @maxPointInRange(5, 6)

  maxPointInRange: (startKey, endKey) ->
    waterfallValues = @waterfallValues()

    maxPoint = 0

    [startKey..endKey].map((valueKey) ->
      startPoint = waterfallValues[0][valueKey]
      endPoint   = startPoint + waterfallValues[1][valueKey]
      maxPoint   = Math.max(startPoint, endPoint, maxPoint)
    )

    maxPoint

  bracketShapes: (maxPoint, bracketMargin, bracketStart, bracketEnd) ->
    bracketCenter = (bracketStart + bracketEnd) / 2

    {
      horizontalLine: {
        x0: bracketStart,
        y0: maxPoint + bracketMargin,
        x1: bracketEnd,
        y1: maxPoint + bracketMargin
      },
      leftVerticalLine: {
        x0: bracketStart,
        y0: maxPoint + bracketMargin,
        x1: bracketStart,
        y1: maxPoint
      },
      rightVerticalLine: {
        x0: bracketEnd,
        y0: maxPoint + bracketMargin,
        x1: bracketEnd,
        y1: maxPoint
      },
      centerVerticalLine: {
        x0: bracketCenter,
        y0: maxPoint + bracketMargin,
        x1: bracketCenter,
        y1: maxPoint + 2 * bracketMargin
      }
    }

  bracketFromCoords: (coords) ->
    [
      @line(coords.horizontalLine),
      @line(coords.leftVerticalLine),
      @line(coords.rightVerticalLine),
      @line(coords.centerVerticalLine)
    ]

  chartAnnotations: () ->
    objectiveBracketCoords = @objectiveBracketCoords().centerVerticalLine
    unexpectedPerfCoords = @unexpectedPerformanceBracketCoords().centerVerticalLine
    liabilitiesCoords = @liabilitiesBracketCoords().centerVerticalLine
    annotationMargin = liabilitiesCoords.y1 - liabilitiesCoords.y0

    {
      x: [
        objectiveBracketCoords.x0,
        unexpectedPerfCoords.x0,
        liabilitiesCoords.x0
      ],
      y: [
        objectiveBracketCoords.y1 + annotationMargin,
        unexpectedPerfCoords.y1 + annotationMargin,
        liabilitiesCoords.y1 + annotationMargin
      ],
      hoverinfo: 'none',
      text: [
        'Objective',
        'Unexpected performance',
        "Liabilities net of hedging = #{@liabilities()}"
      ],
      mode: 'text'
    }

  liabilities: () ->
    liabilities = Math.round(
      (@data.liability_performance + @data.matching_assets_performance) / 1000000
    )

    textColor = if liabilities > 0 then 'green' else 'red'

    $colored(
      $bold(
        $formattedSumMln(
          @data.liability_performance + @data.matching_assets_performance
        )
      ),
      textColor
    )

  line: (params) ->
    defaults = {
      type: 'line',
      line: {
        color: 'rgb(55, 128, 191)',
        width: 2,
      }
    }

    Object.assign({}, defaults, params)

  barText: (value) ->
    plusSign = if value > 0 then '+' else ''
    $bold("#{plusSign}#{Math.round(value / 1000000)}")

  barTextColor: (value) ->
    if value > 0
      'green'
    else
      'red'
