export const MODAL_TYPES = {
  now: "now",
  date: "date",
  number: "number"
}

export const ACTION_TYPES = {
  voting: "voting",
  must_read: "must_read"
}

export const VOTING_OPTIONS = {
  yes: "👍 Yes",
  no: "👎 No",
  abstain: "❌ Abstain"
}

export const ACTION_CLOSED_TYPES = {
  number: "number",
  now: "now",
  date: "date",
  all_actions: "all_actions"
}
