import React from "react"
import { string, func } from "prop-types"
import { Formik } from "formik"

import ModalBody from "src/styles/components/Modal/Body"
import ModalFooter from "src/styles/components/Modal/Footer"
import SubmitButton from "src/features/TwoFactor/Enable/SubmitButton"
import Intro from "src/features/TwoFactor/Enable/Intro"
import ChooseTwoFactorTool from "src/features/TwoFactor/Enable/ChooseTwoFactorTool"

import PhoneInput from "./PhoneInput"
import CodeInput from "./CodeInput"
import usePhoneSubmissionSteps from "./usePhoneSubmissionSteps"

const EnableByPhoneModal = ({ twoFactorTool, setTwoFactorTool, close }) => {
  const {
    error,
    timeLeftUntilNextAttempt,
    step,
    moveToFirstStep,
    handleSubmit,
    initialFormValues,
    validationSchema
  } = usePhoneSubmissionSteps({
    onSuccess: () => window.location.reload()
  })

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      <>
        <ModalBody>
          <Intro />

          {step === 1 && (
            <>
              <ChooseTwoFactorTool
                twoFactorTool={twoFactorTool}
                setTwoFactorTool={setTwoFactorTool}
              />
              <PhoneInput error={error} />
            </>
          )}

          {step === 2 && <CodeInput error={error} moveToFirstStep={moveToFirstStep} />}
        </ModalBody>

        <ModalFooter>
          <button className="button is-secondary" type="button" onClick={close}>
            Cancel
          </button>
          <SubmitButton
            timeLeftUntilNextAttempt={step === 1 ? timeLeftUntilNextAttempt : 0}
          />
        </ModalFooter>
      </>
    </Formik>
  )
}

EnableByPhoneModal.propTypes = {
  twoFactorTool: string.isRequired,
  setTwoFactorTool: func.isRequired,
  close: func.isRequired
}

export default EnableByPhoneModal
