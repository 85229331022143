import React, { useEffect } from "react"
import { useDispatch } from "react-redux"

import Documents from "./Documents"
import { setInitialStateFromProps } from "./agendaItemSlice"

const AgendaItem = (props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    setInitialStateFromProps(props, dispatch)
  }, [dispatch, props])

  return <Documents />
}

export default AgendaItem
