import { useEffect, useState } from "react"
import { useMutation } from "@apollo/client"

import useFeatureFlag from "src/hooks/useFeatureFlag"

import CacheStore from "./CacheStore"
import askQuestionTypeMutation from "./askAiQuestionTypeMutation.gql"

const complexQuestionType = 2

const useShowAiAnswer = (query, whereFilter) => {
  const [questionType, setQuestionType] = useState()
  const [askQuestionType, { loading: askQuestionTypeLoading }] = useMutation(
    askQuestionTypeMutation
  )

  const accountFilter = whereFilter.find((filter) => filter.startsWith("account/"))
  const [, accountId = -1] = (accountFilter || "").split("/")

  const { flagValue: aiFeaturesEnabled, loading: aiFeaturedEnabledLoading } =
    useFeatureFlag("ai-features-enabled", {
      context: { accountId }
    })
  const { flagValue: pineconeSearchEnabled, loading: pineconeSearchEnabledLoading } =
    useFeatureFlag("pinecone-search-enabled")
  const aiAnswerEnabled =
    pineconeSearchEnabled || (whereFilter.length > 0 && aiFeaturesEnabled)

  const loading =
    aiFeaturedEnabledLoading || pineconeSearchEnabledLoading || askQuestionTypeLoading

  const cacheStore = new CacheStore()

  useEffect(() => {
    if (!aiAnswerEnabled) return

    setQuestionType(null)

    if (!query || !query.length) return

    cacheStore.getQuestionType(query).then((cachedQuestionType) => {
      if (cachedQuestionType) {
        setQuestionType(cachedQuestionType)
      } else {
        askQuestionType({ variables: { question: query } }).then(
          ({ data: result = {} }) => {
            if (result.askAiQuestionType?.success) {
              setQuestionType(result.askAiQuestionType.questionType)
              cacheStore.saveQuestionType(query, result.askAiQuestionType.questionType)
            }
          }
        )
      }
    })
  }, [query, aiAnswerEnabled])

  return {
    showAiAnswer: !loading && aiAnswerEnabled && questionType === complexQuestionType,
    loading
  }
}

export default useShowAiAnswer
