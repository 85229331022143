import { be, isSidebarVisible, togglePersistedSidebarState } from "src/helpers/document"
import { isWideScreen } from "src/helpers/browser"

export class SidebarComponent {
  constructor() {
    this.setup = this.setup.bind(this)
    this.collapseAccountSections = this.collapseAccountSections.bind(this)
    this.bindCollapsibleAccountSections = this.bindCollapsibleAccountSections.bind(this)
    this.resetSchemeLinks = this.resetSchemeLinks.bind(this)
    this.bindSidebarToggle = this.bindSidebarToggle.bind(this)
    this.showSidebar = this.showSidebar.bind(this)
    this.storeAccountSectionHidden = this.storeAccountSectionHidden.bind(this)
    this.storeAccountSectionShown = this.storeAccountSectionShown.bind(this)

    this.sidebarMobileBreakpoint = "1100"
    this.sidebarClass = "no-sidebar"
    this.sidebarToggleButtonClass = "show-sidebar"
  }

  setup() {
    this.sidebarToggle = be("sidebar-toggle")
    this.accountSidebarSections = be("account-sidebar-section")
    this.accountSectionToggler = be("account-section-toggler")

    this.showSidebar()
    this.bindSidebarToggle()

    this.accountSectionToggler.click(function (e) {
      if ($(e.target).parent('[data-behavior="new-space-menu"]').length) {
        return e.stopPropagation()
      }
    })
  }

  collapseAccountSections() {
    return this.accountSidebarSections.each((idx, sectionElement) => {
      const section = $(sectionElement)
      if (section.data("id") === $currentAccountId()) {
        this.showAccountSection(section)
        return this.storeAccountSectionShown(section)
      }
      const hidden = localStorage.getItem(
        this.hiddenAccountSectionKey(section.data("id"))
      )
      if (hidden) {
        return this.hideAccountSection(section)
      }
    })
  }

  bindCollapsibleAccountSections() {
    const { storeAccountSectionShown } = this
    const { storeAccountSectionHidden } = this

    this.accountSidebarSections.on("shown.bs.collapse", function onCollapseShown() {
      storeAccountSectionShown(this)
    })

    return this.accountSidebarSections.on(
      "hidden.bs.collapse",
      function onCollapseHidden() {
        storeAccountSectionHidden(this)
      }
    )
  }

  resetSchemeLinks(schemeIds, badgeType) {
    return be("scheme-menu-link").removeClass(badgeType)
  }

  markSchemeLinks(schemeIds, className) {
    return be("scheme-menu-link")
      .filter(
        (_, el) =>
          schemeIds.map((id) => id.toString()).indexOf(el.dataset.id.toString()) !== -1
      )
      .addClass(className)
      .each((_, el) => {
        const section = $(el).parents("[data-behavior=account-sidebar-section]")
        this.showAccountSection(section)
        this.storeAccountSectionShown(section)
      })
  }

  markRecentItemLinksAsUnread(type, itemIds) {
    return Array.from(itemIds).map((itemId) =>
      $beById("recent-thread-link", itemId)
        .filter(`[data-type=${type}]`)
        .addClass("unread")
    )
  }

  markRecentItemLinks(discussionIds, className) {
    return be("recent-thread-link")
      .filter("[data-type='discussion']")
      .removeClass(className)
      .filter(
        (_, el) =>
          discussionIds.map((id) => id.toString()).indexOf(el.dataset.id.toString()) !==
          -1
      )
      .addClass(className)
  }

  bindSidebarToggle() {
    return this.sidebarToggle.on("click", () => {
      $("body").addClass("with-transition") // turn on sidebar transition
      $("body").toggleClass(this.sidebarClass)
      this.sidebarToggle.toggleClass(this.sidebarToggleButtonClass)
      return togglePersistedSidebarState()
    })
  }

  showSidebar() {
    if (!isWideScreen()) return
    if (isSidebarVisible()) {
      $("body").addClass(this.sidebarClass)
      this.sidebarToggle.addClass(this.sidebarToggleButtonClass)
    } else {
      $("body").removeClass(this.sidebarClass)
      this.sidebarToggle.removeClass(this.sidebarToggleButtonClass)
    }
  }

  hideAccountSection(section) {
    section.find("[data-behavior=account-section-toggler]").addClass("collapsed")
    return section.find("[data-behavior=account-section-content]").removeClass("show")
  }

  showAccountSection(section) {
    section.find("[data-behavior=account-section-toggler]").removeClass("collapsed")
    return section.find("[data-behavior=account-section-content]").addClass("show")
  }

  storeAccountSectionHidden(section) {
    return localStorage.setItem(this.hiddenAccountSectionKey($(section).data("id")), true)
  }

  storeAccountSectionShown(section) {
    return localStorage.removeItem(this.hiddenAccountSectionKey($(section).data("id")))
  }

  hiddenAccountSectionKey(id) {
    return `hidden-account-sidebar-section-${id}`
  }
}

window.SidebarComponent = SidebarComponent
