import { styled } from "@linaria/react"

import { BORDER_COLORS } from "src/styles/colors"
import { SPACES } from "src/styles/sizes"

export const AttendeeTitle = styled.div`
  border-bottom: 1px solid ${BORDER_COLORS.separator};
  border-top: 1px solid ${BORDER_COLORS.separator};
  display: flex;
  justify-content: space-between;
  font-size: 0.875rem;
  font-weight: 500;
  padding: ${SPACES.xSmall} ${SPACES.small};
  text-transform: uppercase;

  &:first-child {
    border-top: none;
  }

  &:last-child {
    border-bottom: none;
  }
`

export const MembershipsColumn = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: space-around;
  text-transform: initial;
  width: 13%;
`

export const MeetingPackMembershipsColumn = styled(MembershipsColumn)`
  width: 30%;
`

export default styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  overflow: hidden;
`
