class @CurrentUserModel
  constructor: (userData) ->
    if userData
      @id = Number(userData.id)
      @fullName     = userData.full_name
      @topRole      = userData.top_role
      @email        = userData.email
      @mainAccount  = userData.main_account
      @accountIds   = userData.account_ids
      @accountNames = userData.account_names
      @schemeIds    = userData.scheme_ids
      @schemeNames  = userData.scheme_names
      @isAccountUser = userData.type == 'AccountUser'
      @twoFactorAuthEnabled = userData.two_factor_auth_enabled

  isPresent: =>
    @id && @id > 0
