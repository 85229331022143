import React from "react"
import { styled } from "@linaria/react"
import classNames from "classnames"
import { string } from "prop-types"

import { cardBorderRadius, SPACES } from "src/styles/sizes"
import Column from "src/components/AttandeesTable/Column"
import { BASE_COLORS } from "src/styles/colors"

const ROLE_CLASSES = {
  admin: "admin",
  meeting_pack_creator: "meeting-pack-creator",
  member: "member"
}

const ROLE_LABELS = {
  admin: "Org Admin",
  meeting_pack_creator: "MP Creator",
  member: "Member"
}

const StyledColumn = styled(Column)`
  .role-container {
    text-transform: uppercase;
    display: block;
    width: fit-content;
    padding: ${SPACES.xxSmall} ${SPACES.xSmall};
    border-radius: ${cardBorderRadius};

    &.admin {
      background-color: ${BASE_COLORS.lightOrange};
    }

    &.meeting-pack-creator {
      background-color: #fde4e5;
    }

    &.member {
      background-color: #e2f2c5;
    }
  }
`

const RoleColumn = ({ role }) => {
  return (
    <StyledColumn>
      <span className={classNames("role-container", ROLE_CLASSES[role])}>
        {ROLE_LABELS[role]}
      </span>
    </StyledColumn>
  )
}

RoleColumn.propTypes = {
  role: string.isRequired
}

export default RoleColumn
