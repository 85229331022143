import React from "react"
import { arrayOf, oneOf, shape, string } from "prop-types"

import { RESULT_TYPES } from "src/features/Search/constants"

import { FooterContainer } from "../styles"
import Breadcrumbs from "../Breadcrumbs"

import trackSearchResultLinkClick from "./trackSearchResultLinkClick"

const typeLinkMessages = {
  [RESULT_TYPES.agendaItemDescription]: "Agenda Item",
  [RESULT_TYPES.agendaItemDocument]: "Meeting Paper",
  [RESULT_TYPES.message]: "Message",
  [RESULT_TYPES.attachment]: "Attachment",
  [RESULT_TYPES.document]: "Document",
  [RESULT_TYPES.contentCloudFile]: "Document",
  [RESULT_TYPES.iframeContent]: "Group App",
  [RESULT_TYPES.transcript]: "Transcript",
  [RESULT_TYPES.assignedTranscriptAgendaItem]: "Transcript"
}

const Footer = ({ type, path, breadcrumbs = [] }) => {
  return (
    <FooterContainer>
      <a
        href={path}
        onClick={() => trackSearchResultLinkClick({ type, path })}
        data-result-link
      >
        View {typeLinkMessages[type]}
      </a>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
    </FooterContainer>
  )
}

Footer.propTypes = {
  type: oneOf(Object.keys(typeLinkMessages)).isRequired,
  path: string.isRequired,
  breadcrumbs: arrayOf(
    shape({
      name: string,
      path: string
    })
  )
}

export default Footer
