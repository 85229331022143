import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useMutation } from "@apollo/client"
import { string, number, shape } from "prop-types"
import { Form, InputGroup } from "react-bootstrap"
import { Formik } from "formik"
import * as Yup from "yup"

import { showFlashMessage } from "src/helpers/flash"

import createAppMutation from "./createAppMutation.gql"
import updateAppMutation from "./updateAppMutation.gql"
import AirtableBaseSelect from "./AirtableBaseSelect"
import AirtableTableSelect from "./AirtableTableSelect"
import OAuthUser from "./OAuthUser"

const AppFormSchema = Yup.object().shape({
  name: Yup.string().required("Name can't be blank"),
  oauthUserId: Yup.string().required("OAuth User can't be blank"),
  baseId: Yup.string().required("Base can't be blank"),
  tableId: Yup.string().required("Table can't be blank")
})

const AppForm = ({ id, accountId, initialValues, backRoute }) => {
  const navigate = useNavigate()
  const [createApp, { loading: createAppLoading }] = useMutation(createAppMutation)
  const [updateApp, { loading: updateAppLoading }] = useMutation(updateAppMutation)
  const [baseName, setBaseName] = useState()

  const processResponse = (data) => {
    if (data?.successful) {
      navigate(backRoute)
      showFlashMessage("success", `App was saved.`)
    } else if (data?.errors?.length) {
      showFlashMessage("danger", data.errors.join(". "))
    }
  }

  const saveNewApp = (values) =>
    createApp({
      variables: {
        accountId,
        baseName,
        ...values
      },
      refetchQueries: ["appAirtableInstances"]
    }).then(({ data = {} }) => {
      processResponse(data.createAirtableInstance)
    })

  const saveExistingApp = (values) =>
    updateApp({
      variables: {
        accountId,
        id,
        baseName,
        ...values
      },
      refetchQueries: ["appAirtableInstances"]
    }).then(({ data = {} }) => {
      processResponse(data.updateAirtableInstance)
    })

  return (
    <div className="narrow-container container mvm">
      <Formik
        initialValues={
          initialValues || {
            name: "",
            description: "",
            oauthUserId: null,
            baseId: "",
            tableId: ""
          }
        }
        validationSchema={AppFormSchema}
        onSubmit={initialValues?.id ? saveExistingApp : saveNewApp}
        enableReinitialize
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <InputGroup>
                <Form.Control
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={errors.name && touched.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <Form.Group controlId="description">
              <Form.Label>Description (optional)</Form.Label>
              <Form.Control
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Group>

            <OAuthUser initialFullName={initialValues?.oauthUserFullName} />

            {values.oauthUserId && (
              <AirtableBaseSelect
                instanceId={id}
                setBaseName={setBaseName}
                accountId={accountId}
              />
            )}

            {values.oauthUserId && values.baseId && (
              <AirtableTableSelect
                instanceId={id}
                baseId={values.baseId}
                accountId={accountId}
              />
            )}

            <div className="form-main-controls actions mtm">
              <button
                type="submit"
                className="button"
                disabled={createAppLoading || updateAppLoading}
              >
                Save Application
              </button>
              <Link to={backRoute} className="button is-secondary">
                Cancel
              </Link>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

AppForm.propTypes = {
  id: string,
  accountId: number.isRequired,
  backRoute: string.isRequired,
  initialValues: shape({
    name: string,
    description: string,
    oauthUserFullName: string,
    pageId: number,
    baseId: string,
    tableId: string
  })
}

export default AppForm
