import React from "react"
import { string, func, bool } from "prop-types"

import Radio from "src/styles/components/Radio"
import { airtableFieldType } from "src/resources/accounts/airtablePropTypes"

import FieldLabel from "./FieldLabel"

const SingleSelect = ({ field, value, invalid, setRecord }) => {
  const handleChange = (option) =>
    setRecord((prevRecord) => ({
      ...prevRecord,
      [field.id]: option.name
    }))

  return (
    <>
      <FieldLabel field={field} invalid={invalid} />
      {field.options.map((option) => (
        <div key={option.id} className="mb-2">
          <Radio
            id={option.id}
            label={option.name}
            checked={value === option.name}
            onChange={() => handleChange(option)}
          />
        </div>
      ))}
    </>
  )
}

SingleSelect.propTypes = {
  field: airtableFieldType.isRequired,
  value: string,
  invalid: bool,
  setRecord: func.isRequired
}

export default SingleSelect
