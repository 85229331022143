import React from "react"
import { Formik } from "formik"
import { Form } from "react-bootstrap"
import { bool, func } from "prop-types"
import * as Yup from "yup"

import { Modal, Header, Body, Footer, SubmitButton, CancelButton, Input } from "../Modal"

const CreateFolderSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .max(100, "Name is too long (max is 100 characters)")
    .test(
      "no-slash",
      "Name should not contain '/' character",
      (value) => !value.includes("/")
    )
})

const CreateFolderModal = ({ onSubmit, onClose, submitting }) => {
  return (
    <Modal>
      <Formik
        initialValues={{ name: "" }}
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={CreateFolderSchema}
      >
        {({ values, handleSubmit, handleChange, errors, touched }) => (
          <>
            <Header onClose={onClose}>Create folder</Header>
            <Body>
              <Form onSubmit={handleSubmit}>
                <Input
                  value={values.name}
                  placeholder="Enter your folder name"
                  isInvalid={errors.name && touched.name}
                  onChange={handleChange}
                  name="name"
                  autoFocus
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form>
            </Body>
            <Footer>
              <SubmitButton
                onClick={() => handleSubmit(values)}
                disabled={!values.name || submitting}
              >
                {!submitting && "Create"}
                {submitting && "Creating..."}
              </SubmitButton>
              <CancelButton onClick={onClose}>Cancel</CancelButton>
            </Footer>
          </>
        )}
      </Formik>
    </Modal>
  )
}

CreateFolderModal.propTypes = {
  onSubmit: func.isRequired,
  onClose: func.isRequired,
  submitting: bool.isRequired
}

export default CreateFolderModal
