import { getFolderGroupDocumentLink } from "src/helpers/url"
import { showCaughtErrorMessage } from "src/helpers/errors"
import { showFlashMessage } from "src/helpers/flash"

import box from "./api"

export const fetchSchemeFolders = (data) => () => {
  return box
    .subFolders(data)
    .then((response) => response.data)
    .catch((error) => showCaughtErrorMessage(error))
}

const handleSuccess = ({ targetFolder, currentGroup, message }) => {
  const isCurrentGroup = currentGroup.id === targetFolder.groupId

  if (isCurrentGroup) {
    showFlashMessage("info", message)
  } else {
    showFlashMessage(
      "success",
      [message, getFolderGroupDocumentLink(targetFolder)].join(" "),
      { timeout: 20000 }
    )
  }
}

export const moveBoxItems = ({ targetFolder, currentGroup, requestData = {} }) => () => {
  showFlashMessage("warning", "We are moving selected items. Please wait.")

  return box
    .move(requestData)
    .then(() =>
      handleSuccess({
        targetFolder,
        currentGroup,
        message: "Selected items have been moved."
      })
    )
    .catch((error) => showCaughtErrorMessage(error))
}

export const copyBoxItems = ({ targetFolder, currentGroup, requestData = {} }) => () => {
  showFlashMessage("warning", "We are copying selected items. Please wait.")

  return box
    .copy(requestData)
    .then(() =>
      handleSuccess({
        targetFolder,
        currentGroup,
        message: "Selected items have been copied."
      })
    )
    .catch((error) => showCaughtErrorMessage(error))
}
