import { arrayOf, bool, shape, string } from "prop-types"

export const airtableFilterType = shape({
  fieldId: string.isRequired,
  type: string.isRequired,
  value: string.isRequired
})

export const airtableFieldType = shape({
  id: string.isRequired,
  type: string.isRequired,
  name: string.isRequired,
  isPrimaryField: bool,
  options: arrayOf(shape({ id: string.isRequired, name: string.isRequired }))
})

export const airtableSectionFieldType = shape({
  id: string.isRequired,
  name: string,
  readOnly: bool,
  description: string,
  filters: arrayOf(airtableFilterType)
})

export const airtableSectionType = shape({
  id: string.isRequired,
  name: string.isRequired,
  filters: arrayOf(airtableFilterType),
  fields: arrayOf(airtableSectionFieldType),
  statusFieldId: string,
  showQuestionsCount: bool,
  automaticNumbering: bool,
  readOnly: bool
})

export const airtableTableType = shape({
  id: string.isRequired,
  filters: arrayOf(airtableFilterType),
  cardFields: arrayOf(airtableFieldType),
  sections: arrayOf(airtableSectionType)
})

export const sortByType = shape({
  fieldId: string,
  direction: string
})
