import { useQuery } from "@apollo/client"

import groupPermissionsQuery from "./groupPermissionsQuery.gql"

const useGroupPermissions = (id) => {
  const { data, loading } = useQuery(groupPermissionsQuery, {
    variables: { id },
    fetchPolicy: "cache-first"
  })

  const permissions = data?.groupPermissions || {}

  return { permissions, loading }
}

export default useGroupPermissions
