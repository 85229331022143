class @DiscussionMessagesComponent
  constructor: (params) ->
    @discussionViewport = $be('discussion-viewport')
    @discussionContainer = $be('discussion-container')
    @loadingMessagesInProgress = false

    @thread                    = params.thread
    @editDesktopMessageConfig  = params.editDesktopMessageConfig
    @editMobileMessageConfig   = params.editMobileMessageConfig
    @messageChannelName        = params.messageChannelName
    @messagePathTemplate       = params.messagePathTemplate
    @unreadMessagePathTemplate = params.unreadMessagePathTemplate

    @sendingMessagesComponent = new SendingMessagesComponent()
    @fieldEncodingComponent = new FieldEncodingComponent()

    @messageEditor = null

    @newMessagesWatcher = new NewMessagesWatcher(
      @unreadMessagePathTemplate
    )

    @messageChannel = AblyRealtimeClient.channels.get(
      @messageChannelName
    )
    @channelSubscriptions = [
      {
        channel: @messageChannel,
        subscriber: @handleChannelMessage
      }
    ]

    @copyLinkComponent = new CopyLinkComponent()

  setup: =>
    @bindMessagesLoadingLink()
    fillTimestamps()
    fillDateSeparators()
    showAuthorData()
    setupMessageToggles($be('message-container'))
    @placeUnreadSeparator()
    @setupImagePreview()
    @scrollToActualPosition()
    @newMessagesWatcher.setup()
    $setupChannelSubscriptions(@channelSubscriptions)
    @copyLinkComponent.setup()
    @bindInnerKnowaLinks()

  teardown: =>
    $teardownChannelSubscriptions(@channelSubscriptions)

  handleChannelMessage: (channelMessage) =>
    messageThread = new ThreadModel(channelMessage.data.thread)
    return if !showMessageFromThread(@thread, messageThread)

    messageId = channelMessage.data.messageId
    authorId = channelMessage.data.authorId
    switch channelMessage.name
      when "message-create"
        @fetchMessageContent(
          messageId,
          (data) =>
            @addNewMessage(messageId, authorId, data.content)
            @setupImagePreview()
            unless $isCurrentUserId(authorId)
              @newMessagesWatcher.markMessageAsRead(messageId)
        )
      when "message-update"
        @fetchMessageContent(
          messageId,
          (data) =>
            @updateMessage(messageId, data.content)
        )
        @setupImagePreview()
      when "message-destroy"
        @removeMessage(messageId)

  fetchMessageContent: (messageId, successCallback) =>
    $.get(
      @messagePathTemplate.replace('MESSAGE_ID', messageId),
      successCallback
    )

  setupImagePreview: ()=>
    @gallery.destroy() if @gallery
    @gallery = $be("message-body").find('img').simpleLightbox({sourceAttr: 'src'})


  insertPage: (pageNumber, messagesPage) =>
    currentPage = $be('message-container').last().data('page')

    if pageNumber > currentPage
      $be('next-page-placeholder').replaceWith(messagesPage)
    else
      $be('previous-page-placeholder').replaceWith(messagesPage)

    @bindMessagesLoadingLink()
    fillTimestamps()
    fillDateSeparators()
    showAuthorData()
    window.layoutHandler.setAvatarImages(
      @discussionContainer
    )

    pageMessageContainers = $(
      "[data-behavior=message-container][data-page=#{pageNumber}]"
    )
    setupMessageToggles(pageMessageContainers)
    window.layoutHandler.contactDetailsComponent.bindLinks(
      pageMessageContainers
    )
    @copyLinkComponent.bindClick(pageMessageContainers)
    @bindInnerKnowaLinks(pageMessageContainers)
    @setupImagePreview()

  addNewMessage: (messageId, authorId, messageHtml) =>
    return if $beById('message-container', messageId).length > 0

    @sendingMessagesComponent.removeFailedSendingMessages()
    if @sendingMessagesComponent.hasSendingMessages() && $isCurrentUserId(authorId)
      @sendingMessagesComponent.replaceFirstSendingMessage(messageHtml)
    else
      @discussionContainer.prepend(messageHtml)

    updateDateSeparators(messageId)
    adjustMessage(messageId)
    @placeUnreadSeparator(messageId) unless $isCurrentUserId(authorId)
    @copyLinkComponent.bindClick($beById('message-container', messageId))
    @bindInnerKnowaLinks($beById('message-container', messageId))
    @scrollToBottom()

  showMessageEditForm: (messageId, messageEditForm) =>
    messageFormPlaceholder = $beById('message-form-placeholder', messageId)
    messageFormPlaceholder.html(messageEditForm)
    cancelLink = $beById('remove-message-edit-form', messageId)
    bindRemoveEdit(cancelLink)
    $beById('message-body', messageId).parent().hide()
    $beById('message-container', messageId).removeClass('message-block__minimizable')
    @initFroalaEditor(messageId)
    editMessageForm = messageFormPlaceholder.find('form')
    editMessageForm.on('ajax:before', () =>
      @fieldEncodingComponent.replaceMessageBody(editMessageForm[0])
    )
    messageFormPlaceholder.show()

  updateMessage: (messageId, messageHtml) =>
    $beById('message-container', messageId).replaceWith(messageHtml)
    adjustMessage(messageId)
    @copyLinkComponent.bindClick($beById('message-container', messageId))
    @bindInnerKnowaLinks($beById('message-container', messageId))

  removeMessage: (messageId) =>
    targetMessage = $beById('message-container', messageId)
    targetMessage.hide('fast', -> targetMessage.remove())

  placeUnreadSeparator: (messageId) =>
    return if $be('message-separator').length > 0

    firstUnreadMessageId = messageId ||
      @discussionViewport.data('first-new-message')

    if firstUnreadMessageId
      firstUnreadMessageElement = $beById(
        'message-container', firstUnreadMessageId
      )
      firstUnreadMessageElement.after(
        '<div class="message-separator no-print" data-behavior="message-separator"></div>'
      )

  scrollToActualPosition: =>
    focusedMessageId = @discussionViewport.data('focused-message')
    firstUnreadMessageId = @discussionViewport.data('first-new-message')
    actualMessageId = focusedMessageId || firstUnreadMessageId

    actualDocumentId = @discussionViewport.data('focused-document')

    if actualMessageId
      messageElement = $beById('message-container', actualMessageId)
      if messageElement.length
        @scrollToMessage(messageElement)
        @blinkMessage(messageElement) if focusedMessageId

    else
      @scrollToBottom()

  scrollToBottom: =>
    @discussionViewport.scrollTop(
      @discussionContainer[0].scrollHeight
    ) if @discussionContainer[0]

  scrollToMessage: (messageElement) =>
    messageBound = messageElement.offset().top
    @discussionViewport.scrollTop(
      @discussionViewport.scrollTop() + messageBound - 210
    )

  blinkMessage: (messageElement) ->
    messageElement.addClass('message-block__highlighted')
    setTimeout( ->
      messageElement.removeClass('message-block__highlighted')
    , 3000)

  preserveScrollPosition: (previousOffset, linkHeight) =>
    @discussionViewport.scrollTop(
      @discussionContainer.height() + previousOffset - linkHeight
    )

  fetchMessages: (linkElement) =>
    @loadingMessagesInProgress = true
    $(linkElement).hide()
    $(linkElement).parent().find('[data-behavior=loading-animation]').show()
    oldOffset = @discussionViewport.scrollTop() - @discussionContainer.height()
    href = $(linkElement).attr('href')
    # Then second argument is a dummy host to make href parseable by URL
    url = new URL(href, 'http://knowa.co')
    insert = url.searchParams.get('insert')

    $.getScript href, =>
      if insert == 'after'
        @preserveScrollPosition(oldOffset, $(linkElement).height())
      @loadingMessagesInProgress = false

  bindMessagesLoadingLink: =>
    loadMessagesPageLinks = $be('load-messages-page-link')
    loadMessagesPageLinks.on 'inview', (e, visible) =>
      return if @loadingMessagesInProgress or not visible
      @fetchMessages(e.target)

  initFroalaEditor: (messageId) =>
    if $be('desktop-view-flag').is(':visible')
      config = @editDesktopMessageConfig
    else
      config = @editMobileMessageConfig

    @messageEditor = new MessageEditorComponent(
      $beById("message-edit-input", messageId),
      config
    )
    @messageEditor.setup()

  bindSendingMessageHandler: (form, getMessageBody) ->
    form.on('ajax:beforeSend', () =>
      @addSendingMessage(getMessageBody())
    )
    form.on('ajax:error', (event, xhr) =>
      unless $isXhrSuccessful(xhr)
        @sendingMessageFailed()
    )

  addSendingMessage: (messageBody) ->
    $sendingMessage = @sendingMessagesComponent.composeSendingMessage(messageBody)

    @discussionContainer.prepend($sendingMessage)
    @sendingMessagesComponent.push($sendingMessage)
    @scrollToBottom()

  sendingMessageFailed: () ->
    @sendingMessagesComponent.sendingMessageFailed()

  bindInnerKnowaLinks: (container) =>
    messageLinksPattern = "a[href*='" +
      window.location.host +
      window.location.pathname +
      "?message_id']"
    allLinksPattern = "a[href*='#{window.location.host}']"
    bindOwnMessageLinks(this, container, messageLinksPattern)
    bindOtherLinks(container, allLinksPattern, messageLinksPattern)

  # private

  bindOtherLinks = (container, linkPattern, exceptPattern) ->
    links = if container
      container.find(linkPattern).not(exceptPattern)
    else
      $(linkPattern).not(exceptPattern)
    links.click (e) ->
      e.preventDefault()
      Turbo.visit($(this).attr('href'))

  bindOwnMessageLinks = (component, container, linkPattern) ->
    links = if container
      container.find(linkPattern)
    else
      $(linkPattern)

    links.click (e) ->
      e.preventDefault()
      href = $(this).attr('href')
      messageId = new URL(href).searchParams.get('message_id')
      return unless messageId
      messageElement = $beById('message-container', messageId)
      if messageElement.length
        component.scrollToMessage(messageElement)
        component.blinkMessage(messageElement)
      else
        Turbo.visit(href)

  showMessageFromThread = (discussionThread, messageThreadModel) =>
    messageThreadModel.isMemberId($currentUserId()) &&
      messageThreadModel.type is discussionThread.type &&
      messageThreadModel.id is Number(discussionThread.id)

  showAuthorData = (messageId) ->
    if messageId
      ownMesagges = $(
        "[data-behavior=message-container]" +
        "[data-id=#{messageId}]" +
        "[data-author-id=#{$currentUserId()}]"
      )
    else
      ownMesagges = $(
        "[data-behavior=message-container][data-author-id=#{$currentUserId()}]"
      )
    ownMesagges.find(
      "[data-behavior=message-owner-flag]"
    ).show()

  updateDateSeparators = (messageId) ->
    message = $beById('message-container', messageId)
    previousMessage = $(message).nextAll(
      "[data-behavior=message-container]:first"
    )
    mDate = moment.utc(message.data('datetime')).local()
    if previousMessage.length
      pmDate = moment.utc(previousMessage.data('datetime')).local()
      if mDate.startOf('day').format() != pmDate.startOf('day').format()
        drawDateSeparator(message, mDate)
        redrawDateSeparators()
    else
      drawDateSeparator(message, mDate)

  fillMessageTimestamp = (messageId) ->
    $setTimestampContent($beById('message-timestamp', messageId))

  fillTimestamps = ->
    for el in $be('message-timestamp')
      $setTimestampContent($(el))

  setupMessageToggles = (messageContainers) ->
    for el in messageContainers
      sharedMessageContainer = $(el).find("[data-behavior='shared-message-container']")
      $setupMessageToggle($(el))
      $setupMessageToggle(sharedMessageContainer) if sharedMessageContainer

  fillDateSeparators = ->
    for mc in $be('message-container').filter('[data-id]')
      message = $(mc)
      previousMessage = $(mc).nextAll(
        "[data-behavior=message-container]:first"
      )
      mDate = moment.utc(message.data('datetime')).local()

      if previousMessage.length
        pmDate = moment.utc(previousMessage.data('datetime')).local()
        if mDate.startOf('day').format() != pmDate.startOf('day').format()
          drawDateSeparator(message, mDate)
      else if $be('loading-animation').length
        $be('loading-animation').append(
          onLoadingDateSeparator(mDate)
        )
      else
        drawDateSeparator(message, mDate)


  drawDateSeparator = (message, date) ->
    messageId = message.data('id')
    return if $beById("date-separator", messageId).length
    message.after(
      "<div class='text-center mbs date-separator-wrapper'>
        <span class='date-separator'
          data-behavior='date-separator'
          data-id=#{messageId}
          data-date='#{date.format()}'>
          #{formatDateSeparator(date)}
        </<span>
      </div>"
    )

  onLoadingDateSeparator = (date) ->
    "<span class='date-separator'
      data-behavior='date-separator'
      data-id='on-loading'
      data-date='#{date.format()}'>
      #{formatDateSeparator(date)}
    </<span>"

  formatDateSeparator = (date) ->
    if date.isSame(moment(), 'day')
      'Today'
    else if date.isSame(moment().subtract(1, 'days'), 'day')
      'Yesterday'
    else if date.isSame(moment(), 'year')
      date.format("MMM Do")
    else
      date.format("MMM Do, Y")

  redrawDateSeparators = ->
    for el in $be('date-separator')
      $(el).html(
        formatDateSeparator(moment($(el).data('date')))
      )

  bindRemoveEdit = (cancelLink) ->
    cancelLink.on 'click', (e) ->
      e.preventDefault()
      messageId = $(this).data('id')
      $beById('message-body', messageId).parent().show()
      $beById('message-form-placeholder', messageId).html("")
      $beById('message-container', messageId).addClass('message-block__minimizable')

  adjustMessage = (messageId) ->
    messageContainer = $beById('message-container', messageId)
    sharedMessageContainer = messageContainer.find(
      "[data-behavior='shared-message-container']"
    )
    window.layoutHandler.setAvatarImages(messageContainer)
    window.layoutHandler.contactDetailsComponent.bindLinks(messageContainer)
    fillMessageTimestamp(messageId)
    showAuthorData(messageId)
    $setupMessageToggle(messageContainer)
    $setupMessageToggle(sharedMessageContainer) if sharedMessageContainer
