import { useEffect } from "react"

import BoxPreview from "./BoxPreview"

const useBoxPreview = ({
  document,
  previewContainer,
  sidebarContainer = null,
  collection = [],
  onNavigate = () => {},
  toolbarButtonsFactory = () => []
}) => {
  useEffect(() => {
    const preview = new BoxPreview({
      document,
      collection,
      previewContainer,
      sidebarContainer,
      onNavigate,
      toolbarButtonsFactory
    })

    preview.show()

    return () => preview.destroy()
  }, [document.boxFileId, previewContainer, sidebarContainer])
}

export default useBoxPreview
