import { useQuery } from "@apollo/client"
import { useSelector } from "react-redux"
import * as Sentry from "@sentry/browser"

import updatePagesCountStatusQuery from "./query.gql"

export const UpdateStatus = {
  Running: "running",
  Done: "done"
}

const useUpdatePagesCountStatus = () => {
  const meetingPackId = useSelector((state) => state.meetingPack.meetingPack.id)
  const savedAgendaItems = useSelector((state) => state.meetingPack.agendaItems)
  const defaultResponse = { status: UpdateStatus.Done, agendaItems: savedAgendaItems }

  const { data, error, loading, stopPolling } = useQuery(updatePagesCountStatusQuery, {
    variables: { meetingPackId },
    pollInterval: 3000,
    skip: !navigator.onLine
  })

  if (loading) {
    return { status: UpdateStatus.Running, agendaItems: [] }
  }

  if (error) {
    Sentry.captureException(error, {
      extra: { location: "useUpdatePagesCountStatus", online: navigator.onLine }
    })
    return defaultResponse
  }

  const { status, agendaItems } =
    data?.agendaItemsUpdatePagesCountStatus || defaultResponse

  if (status === UpdateStatus.Done) {
    stopPolling()
  }

  return { status, agendaItems }
}

export default useUpdatePagesCountStatus
