import { styled } from "@linaria/react"

import UnstyledButton from "src/styles/components/Button/Unstyled"
import { contentsSidebarToggleBackground, TEXT_COLORS } from "src/styles/colors"
import { BREAKPOINTS } from "src/styles/sizes"

export const SidebarToggleButton = styled(UnstyledButton)`
  color: ${TEXT_COLORS.sidebar};

  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    border-left: 1px solid ${contentsSidebarToggleBackground};
  }
`
