import React, { useState } from "react"
import { shape, number, bool, func, string, arrayOf } from "prop-types"

import { MEMBERSHIP_TYPES } from "src/features/meetingPacks/MeetingPack/constants"

import NameCell from "./NameCell"
import CheckboxContainer from "./CheckboxContainer"

const AgendaItemRow = ({
  item,
  displayAttending,
  attendingItemsIds,
  accessOnlyItemsIds,
  onChange,
  expanded: initiallyExpanded = false
}) => {
  const [expanded, setExpanded] = useState(initiallyExpanded)

  const attendingChecked = attendingItemsIds.includes(item.id)
  const accessOnlyChecked = accessOnlyItemsIds.includes(item.id)

  const subItemsIds = item.subItems.map(({ id }) => id)

  const selectedAttendingSubItems = attendingItemsIds.filter((id) =>
    subItemsIds.includes(id)
  )
  const attendingPartiallySelected =
    attendingChecked && selectedAttendingSubItems.length < subItemsIds.length

  const selectedAccessOnlySubItems = accessOnlyItemsIds.filter((id) =>
    subItemsIds.includes(id)
  )
  const accessOnlyPartiallySelected =
    accessOnlyChecked && selectedAccessOnlySubItems.length < subItemsIds.length

  const partiallySelected = attendingPartiallySelected || accessOnlyPartiallySelected
  const partialAccessLabel = partiallySelected ? "partial access" : null

  return (
    <>
      <tr data-testid={`agenda-item-row-${item.id}`}>
        <NameCell
          item={item}
          index={item.index}
          onToggle={() => setExpanded(!expanded)}
          expanded={expanded}
        />

        {displayAttending && (
          <td>
            <CheckboxContainer
              id={`attending-item-${item.id}`}
              checked={attendingChecked}
              onChange={() => onChange(item.id)}
              partiallyChecked={attendingPartiallySelected}
            />
          </td>
        )}

        <td>
          <CheckboxContainer
            id={`access-only-item-${item.id}`}
            onChange={() => onChange(item.id, MEMBERSHIP_TYPES.accessOnly)}
            checked={accessOnlyChecked}
            partiallyChecked={accessOnlyPartiallySelected}
            infoLabel={partialAccessLabel}
          />
        </td>
      </tr>
      {expanded &&
        item.subItems.map((subItem) => (
          <tr key={subItem.id} data-testid={`agenda-item-row-${subItem.id}`}>
            <NameCell item={subItem} index={subItem.index} isSubItem />

            {displayAttending && (
              <td>
                <CheckboxContainer
                  id={`attending-item-${subItem.id}`}
                  checked={attendingItemsIds.includes(subItem.id)}
                  onChange={() => onChange(subItem.id)}
                  disabled={accessOnlyChecked || !attendingChecked}
                />
              </td>
            )}

            <td>
              <CheckboxContainer
                id={`access-only-item-${subItem.id}`}
                checked={accessOnlyItemsIds.includes(subItem.id)}
                onChange={() => onChange(subItem.id, MEMBERSHIP_TYPES.accessOnly)}
                disabled={!accessOnlyChecked && !attendingChecked}
              />
            </td>
          </tr>
        ))}
    </>
  )
}

AgendaItemRow.propTypes = {
  item: shape({
    id: number.isRequired,
    name: string.isRequired
  }).isRequired,
  displayAttending: bool.isRequired,
  attendingItemsIds: arrayOf(number).isRequired,
  accessOnlyItemsIds: arrayOf(number).isRequired,
  onChange: func.isRequired,
  expanded: bool
}

export default AgendaItemRow
