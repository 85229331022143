import { styled } from "@linaria/react"

import { withVariations } from "src/helpers/linaria"
import { FONT_SIZES } from "src/styles/sizes"

import Icon from "./Icon"

export default styled(withVariations(Icon, ["size"]))`
  color: inherit;
  margin-left: 5px;
  margin-bottom: -2px;
  font-size: 0.75rem;

  &--size-small {
    font-size: ${FONT_SIZES.small};
  }

  &--size-medium {
    font-size: ${FONT_SIZES.medium};
  }
`
