import { styled } from "@linaria/react"

import BaseIcon from "src/styles/components/Icon"
import { BREAKPOINTS } from "src/styles/sizes"
import { TEXT_COLORS } from "src/styles/colors"

import { Button } from "../styles"

export const Icon = styled(BaseIcon)`
  margin-left: 3px;
  margin-right: 3px;
  font-size: 12px;
`

export const Container = styled.div`
  display: flex;
  font-size: 1.2rem;
  flex-wrap: wrap;
  flex-basis: 40px;
  flex-shrink: 0;
`

export const SegmentContainer = styled.span`
  display: flex;
  align-items: center;
  position: relative;

  & button${Button} {
    font-size: 19px;
    font-weight: 500;
    color: ${TEXT_COLORS.link};
    min-width: 0;
  }

  & button${Button}.drag-over {
    min-width: 0;
    border: 2px dashed blue;
  }

  &:last-of-type button${Button} {
    color: inherit;
    cursor: default;
  }

  &:last-of-type ${Button}:hover {
    background-color: transparent;
    border-radius: 0;
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    max-width: 300px;
  }
`

export const Name = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
