import { connect } from "react-redux"

import {
  getActiveItem,
  getMeetingPack
} from "src/features/meetingPacks/MeetingPack/selectors"

import AgendaItemSummary from "./AgendaItemSummary"

export const mapState = (state) => ({
  meetingPack: getMeetingPack(state),
  item: getActiveItem(state)
})

export default connect(mapState)(AgendaItemSummary)
