import { isEqual } from "lodash"

import IdbStore from "src/features/offline/helpers/IdbStore"

const STORE_NAME = "SEARCH_AI_ANSWER"

export default class CacheStore {
  constructor() {
    this.store = new IdbStore({ storeName: STORE_NAME })
  }

  cacheExpirationTime = () => Date.now() + 10 * 60 * 1000 // 10 minutes in ms

  getAnswer = async (question, filters) => {
    return this.store.get(question).then((cachedAnswer) => {
      if (
        cachedAnswer?.answer &&
        isEqual(JSON.parse(cachedAnswer.filters), filters) &&
        cachedAnswer.expiredAt > Date.now()
      )
        return {
          cachedAnswer: cachedAnswer.answer,
          cachedAnswerSortedLinks: cachedAnswer.answerSortedLinks
        }

      this.store.delete(question)
      return {}
    })
  }

  saveAnswer = (question, filters, answer, answerSortedLinks) => {
    return this.store.set(question, {
      filters: JSON.stringify(filters),
      answer,
      answerSortedLinks,
      expiredAt: this.cacheExpirationTime()
    })
  }

  questionTypeKey = (question) => `${question} [type]`

  getQuestionType = async (question) => {
    const key = this.questionTypeKey(question)

    return this.store.get(key).then((cachedQuestionType) => {
      if (cachedQuestionType && cachedQuestionType.expiredAt > Date.now())
        return cachedQuestionType.questionType

      this.store.delete(key)
      return null
    })
  }

  saveQuestionType = (question, questionType) => {
    return this.store.set(this.questionTypeKey(question), {
      questionType,
      expiredAt: this.cacheExpirationTime()
    })
  }
}
