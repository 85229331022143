import React from "react"
import { bool, number, func } from "prop-types"
import { Modal, Image } from "react-bootstrap"

import successImage from "images/bermuda-message-sent.png"
import WarningText from "src/styles/components/WarningText"

import { ModalFooter } from "./styles"

const SuccessModal = ({ isOpened, onClose, meetingPackId }) => {
  return (
    <Modal centered size="md" onHide={onClose} show={isOpened}>
      <Modal.Header closeButton>
        <Modal.Title>Successfully published</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pvl text-center">
        <Image height={200} src={successImage} rounded />
      </Modal.Body>
      <ModalFooter>
        <div>
          <p>
            <WarningText
              component="a"
              href="//help.knowa.co/en/articles/5103981-meeting-pack-post-publishing-checklist"
              target="_blank"
              rel="noreferrer"
              className="mbx"
              text="Take a look at the post-publish checklist"
            />
          </p>
          <div className="d-flex justify-content-center">
            <button className="button is-secondary" onClick={onClose}>
              Close
            </button>
            <a className="button" href={`/meeting_packs/${meetingPackId}/view`}>
              View Meeting Pack
            </a>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  )
}

SuccessModal.propTypes = {
  isOpened: bool.isRequired,
  onClose: func.isRequired,
  meetingPackId: number.isRequired
}

export default SuccessModal
