import { useApolloClient } from "@apollo/client"
import { useEffect } from "react"

import saveSearchMutation from "./saveSearch.gql"

export default function useSearchPersistence({
  query,
  order,
  category,
  whereIds,
  userIds,
  dateFrom,
  dateTo
}) {
  const apolloClient = useApolloClient()

  useEffect(() => {
    if (!query) {
      return
    }

    apolloClient.mutate({
      mutation: saveSearchMutation,
      variables: { query, order, category, whereIds, userIds, dateFrom, dateTo }
    })
  }, [
    query,
    order,
    dateFrom,
    dateTo,
    category,
    JSON.stringify(whereIds),
    JSON.stringify(userIds)
  ])
}
