import React from "react"
import { styled } from "@linaria/react"
import { bool } from "prop-types"
import { Row as BootstrapRow } from "react-bootstrap"

import { composeVariationsClasses } from "src/helpers/linaria"
import { TEXT_COLORS, meetingPackDisabledSidebarItemColor } from "src/styles/colors"
import { SPACES, FONT_SIZES, FONT_WEIGHTS } from "src/styles/sizes"

import { DocumentName } from "./styles"

const DocumentsRow = ({ className, disabled, active, ...rest }) => {
  const classes = composeVariationsClasses(className, { disabled, active })

  return <BootstrapRow className={classes} {...rest} />
}

DocumentsRow.propTypes = {
  active: bool,
  disabled: bool
}

export default styled(DocumentsRow)`
  margin-top: ${SPACES.xSmall};
  font-size: ${FONT_SIZES.xSmall};
  font-weight: ${FONT_WEIGHTS.semiBold};
  color: ${TEXT_COLORS.heading};
  cursor: pointer;

  &--disabled {
    color: ${meetingPackDisabledSidebarItemColor};
    pointer-events: none;
    cursor: default;
  }

  &:not(&--disabled):hover,
  &:not(&--disabled):focus {
    color: ${TEXT_COLORS.link};
    outline: none;
  }

  &--active:not(&--disabled) {
    ${DocumentName} {
      color: ${TEXT_COLORS.link};
    }
  }
`
