import React from "react"
import classNames from "classnames"
import { arrayOf, number, shape, string } from "prop-types"

import useBaseNavData from "src/features/Sidebar/BaseNav/useBaseNavData"

const BaseNav = ({ exitPath, unreadItems, starredItems, meetingPacks }) => {
  const {
    meetingPacksCount,
    unreadMentionsCount,
    pendingActionsCount,
    starredItemsCount,
    unreadChatsCount,
    isOnTheMeetingsPage,
    isOnTheMentionsPage,
    isOnTheStarredItemsPage,
    isOnTheChatsPage
  } = useBaseNavData({ unreadItems, starredItems, meetingPacks })

  return (
    <div className="sidebar-section sidebar-base-nav">
      {!!meetingPacksCount && (
        <a
          href={isOnTheMeetingsPage ? exitPath : "/meetings"}
          className={classNames("sidebar-base-nav-link saved-offline", {
            highlighted: isOnTheMeetingsPage,
            "unread mention": unreadItems.length
          })}
          data-testid="meetings-navbar-link"
        >
          <span className="icon-list-numbered" />
          <span className="text mt-1">Meetings</span>
        </a>
      )}
      <a
        href={isOnTheMentionsPage ? exitPath : "/mentions"}
        className={classNames("sidebar-base-nav-link", {
          highlighted: isOnTheMentionsPage,
          "unread mention": unreadMentionsCount || pendingActionsCount
        })}
        data-testid="mentions-navbar-link"
      >
        <span className="icon-mention icon-small" />
        <span className="text mt-1">Mentions</span>
      </a>
      <a
        href={isOnTheStarredItemsPage ? exitPath : "/starred_items"}
        className={classNames("sidebar-base-nav-link", {
          highlighted: isOnTheStarredItemsPage
        })}
        data-testid="starred-navbar-link"
      >
        <span className={starredItemsCount ? "icon-star-filled" : "icon-star-hollow"} />
        <span className="text mt-1">Starred</span>
      </a>
      <a
        href={isOnTheChatsPage ? exitPath : "/chats"}
        className={classNames("sidebar-base-nav-link", {
          highlighted: isOnTheChatsPage,
          unread: unreadChatsCount
        })}
        data-testid="chats-navbar-link"
      >
        <span className="icon-message" />
        <span className="text mt-1">Chats</span>
      </a>
    </div>
  )
}

BaseNav.propTypes = {
  exitPath: string.isRequired,
  unreadItems: arrayOf(
    shape({
      id: number
    })
  ),
  starredItems: arrayOf(
    shape({
      id: number
    })
  ),
  meetingPacks: arrayOf(
    shape({
      id: number
    })
  )
}

export default BaseNav
