import React from "react"
import { Formik } from "formik"
import { Form } from "react-bootstrap"
import * as Yup from "yup"
import { bool, func } from "prop-types"

import { splitFilename } from "src/helpers/string"

import { Modal, Header, Body, Footer, SubmitButton, CancelButton, Input } from "../Modal"
import { FolderItemType } from "../types"

const RenameFolderItemSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .max(100, "Name is too long (max is 100 characters)")
    .test(
      "no-slash",
      "Name should not contain '/' character",
      (value) => !value.includes("/")
    )
})

const RenameModal = ({ folderItem, onSubmit, onClose, submitting }) => {
  const initialName =
    folderItem.type === "folder" ? folderItem.name : splitFilename(folderItem.name).name

  return (
    <Modal>
      <Formik
        initialValues={{ name: initialName || "" }}
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={RenameFolderItemSchema}
      >
        {({ values, handleSubmit, handleChange, errors, touched }) => (
          <>
            <Header onClose={onClose}>Rename</Header>
            <Body>
              <Form onSubmit={handleSubmit}>
                <Input
                  value={values.name}
                  placeholder="Enter new name"
                  isInvalid={errors.name && touched.name}
                  onChange={handleChange}
                  name="name"
                  type="text"
                  autoFocus
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form>
            </Body>
            <Footer>
              <SubmitButton
                onClick={() => handleSubmit(values)}
                disabled={!values.name || submitting}
              >
                {!submitting && "Rename"}
                {submitting && "Renaming..."}
              </SubmitButton>
              <CancelButton onClick={onClose}>Cancel</CancelButton>
            </Footer>
          </>
        )}
      </Formik>
    </Modal>
  )
}

RenameModal.propTypes = {
  folderItem: FolderItemType.isRequired,
  onSubmit: func.isRequired,
  onClose: func.isRequired,
  submitting: bool.isRequired
}

export default RenameModal
