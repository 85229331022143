import React from "react"
import { number, string } from "prop-types"
import { omitBy, isNil } from "lodash"

import { getBaseUrl } from "src/helpers/document"

import { CopyLink as StyledCopyLink } from "./styles"

const CopyLink = ({ groupId, folderId, fileId }) => {
  const baseURL = getBaseUrl()
  const searchParams = new URLSearchParams(
    omitBy({ folder_id: folderId, file_id: fileId }, isNil)
  )
  const fileUrl = `${baseURL}/groups/${groupId}/documents?${searchParams.toString()}`

  return <StyledCopyLink url={fileUrl} />
}

CopyLink.propTypes = {
  groupId: number.isRequired,
  folderId: string.isRequired,
  fileId: string
}

export default CopyLink
