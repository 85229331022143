import useMobileScreen from "src/hooks/useMobileScreen"

const DEFAULT_LIMIT = 20

const useTruncate = () => {
  const isMobile = useMobileScreen()

  const truncate = (text, { limit = DEFAULT_LIMIT } = {}) => {
    if (!isMobile || text.length <= limit) return text

    return `${text.substring(0, limit)}...`
  }

  return truncate
}

export default useTruncate
