class @UnreadBadgesComponent
  constructor: (containerBehavior, containerIdMessageField, channel) ->
    @containerBehavior = containerBehavior
    @badgeBehavior = 'notification-badge'
    @containerIdMessageField = containerIdMessageField
    @updatesChannel = AblyRealtimeClient.channels.get(channel)

    @channelData = [
      {
        channel: @updatesChannel,
        subscriber: @handleUnreadsChannelMessage
      }
    ]

  setup: =>
    @refresh()
    $setupChannelSubscriptions(@channelData)

  teardown: =>
    $teardownChannelSubscriptions(@channelData)

  refresh: =>
    @hideZeroBadges()

  handleUnreadsChannelMessage: (channelMessage) =>
    return if channelMessage.data.skipUpdate
    return if channelMessage.data?.authorId == $currentUserId()
    return if (channelMessage.name is 'message-create') && $currentUserId() in (channelMessage.data?.usersToSkipUpdate || [])
    containerIds = channelMessage.data[@containerIdMessageField]
    return unless containerIds && (channelMessage.name is 'message-create')

    threadModel = new ThreadModel(channelMessage.data.thread)
    return unless threadModel.isMemberId($currentUserId())

    containerIds = [containerIds] if !(containerIds instanceof Array)
    for containerId in containerIds
      @increaseCounter(containerId)

  hideZeroBadges: =>
    $be(@badgeBehavior).each (i, badge) ->
      if $(badge).text() is "0"
        $(badge).hide()

  increaseCounter: (containerId) =>
    [unreadBadge, currentCounter] = @getBadgeWithCounter(containerId)
    return unless unreadBadge
    unreadBadge.show()
    unreadBadge.text(currentCounter + 1)

  getBadgeWithCounter: (containerId) =>
    targetContainer = $beById(@containerBehavior, containerId)
    return [null, null] if targetContainer.length is 0
    unreadBadge = targetContainer.find("[data-behavior~='#{@badgeBehavior}']")
    currentCounter = parseInt(unreadBadge.text(), 10)
    return [unreadBadge, currentCounter]
