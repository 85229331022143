import React from "react"
import { string } from "prop-types"

import { formatTimestamp } from "src/helpers/datetime"

import { TitleContainer, TitleDatetime, TitleText } from "./styles"

const TextTitle = ({ title, datetime }) => {
  return (
    <TitleContainer>
      <div className="d-flex align-items-center text-truncate">
        <TitleText class="text-truncate">{title}</TitleText>
      </div>

      {datetime && <TitleDatetime>{formatTimestamp(datetime)}</TitleDatetime>}
    </TitleContainer>
  )
}

TextTitle.propTypes = {
  title: string,
  datetime: string
}

export default TextTitle
