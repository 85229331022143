export const ITEM_ID_URL_KEY = "agenda_item_id"
export const DOCUMENT_ID_URL_KEY = "document_id"
export const DOCUMENT_LOADING_STATES = {
  notLoaded: "notLoaded",
  isLoading: "isLoading",
  loaded: "loaded",
  loadingFailed: "loadingFailed"
}
export const ROLES = {
  admin: "admin",
  member: "member",
  guest: "guest"
}
export const MEMBERSHIP_TYPES = {
  present: "present",
  inAttendance: "in_attendance",
  accessOnly: "access_only",
  apologies: "apologies"
}
export const MEMBERSHIP_TYPES_FOR = {
  agendaItemSummary: [MEMBERSHIP_TYPES.inAttendance, MEMBERSHIP_TYPES.present],
  limitedVisibilityItems: [MEMBERSHIP_TYPES.accessOnly, MEMBERSHIP_TYPES.apologies],
  meetingPackAttendees: [
    MEMBERSHIP_TYPES.present,
    MEMBERSHIP_TYPES.inAttendance,
    MEMBERSHIP_TYPES.apologies
  ],
  meetingPackExport: [
    MEMBERSHIP_TYPES.present,
    MEMBERSHIP_TYPES.inAttendance,
    MEMBERSHIP_TYPES.apologies,
    MEMBERSHIP_TYPES.accessOnly
  ],
  dropdown: [
    MEMBERSHIP_TYPES.present,
    MEMBERSHIP_TYPES.inAttendance,
    MEMBERSHIP_TYPES.apologies,
    MEMBERSHIP_TYPES.accessOnly
  ]
}
export const ADMIN_ROLES = ["admin"]
export const GUEST_ROLES = ["guest"]
export const AGENDA_ITEM_TYPE = "MeetingPacks::AgendaItem"
export const DOCUMENT_TYPE = "MeetingPacks::AgendaItemDocument"
