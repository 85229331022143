import { useEffect } from "react"

import { getUnreadCount, getMentionsCount } from "src/features/unreadStats/helpers"
import { isPortableDevice } from "src/helpers/browser"
import { BADGE_COLORS } from "src/styles/colors"

const faviconBadgeType = ({ mentionsCount, unreadCount }) => {
  if (mentionsCount) return "mention"
  if (unreadCount) return "unread"
}

const fetchFavicons = () =>
  Array.from(document.querySelectorAll("link[rel*=icon]")).map((el) => {
    return new window.Favico({
      bgColor: "",
      position: "up",
      animation: "none",
      element: el
    })
  })

const useFaviconUpdates = (stats) => {
  const portableDevise = isPortableDevice()

  useEffect(() => {
    if (portableDevise) return

    try {
      const mentionsCount = getMentionsCount(stats)
      const unreadCount = getUnreadCount(stats)

      if (mentionsCount || unreadCount) {
        const badgeType = faviconBadgeType({ unreadCount, mentionsCount })

        if (!badgeType) return

        window.favicons = fetchFavicons()
        window.favicons.forEach((favicon) => {
          favicon.badge(" ", { bgColor: BADGE_COLORS[badgeType] || "" })
        })
      } else {
        window.favicons = window.favicons || fetchFavicons()
        window.favicons.forEach((favicon) => favicon.badge(""))
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }, [JSON.stringify(stats)])
}

export default useFaviconUpdates
