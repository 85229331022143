class BoxComponentsFactory
  attachDocumentComponent: (params) ->
    if @isBoxFilePickerAvailable() && @isBoxContentUploaderAvailable()
      new AttachDocumentComponent(params)
    else
      new AttachDocumentStubComponent(params)

  isBoxFilePickerAvailable: ->
    try
      new Boolean(Box.FilePicker)
    catch
      false

  isBoxContentUploaderAvailable: ->
    try
      new Boolean(Box.ContentUploader)
    catch
      false

window.boxComponentsFactory = new BoxComponentsFactory()
