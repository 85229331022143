import React from "react"
import { bool, func } from "prop-types"
import classNames from "classnames"
import { styled } from "@linaria/react"

import Checkbox from "src/styles/components/Checkbox"
import { airtableFieldType } from "src/resources/accounts/airtablePropTypes"
import { BASE_COLORS } from "src/styles/colors"

const RecordCheckbox = ({ field, value, setRecord, invalid, className }) => {
  const handleChange = () =>
    setRecord((prevRecord) => ({
      ...prevRecord,
      [field.id]: !prevRecord[field.id]
    }))

  return (
    <Checkbox id={field.id} checked={!!value} onChange={handleChange}>
      <span className={classNames("pl-2", invalid ? "invalid" : null, className)}>
        {field.name}
      </span>
    </Checkbox>
  )
}

RecordCheckbox.propTypes = {
  field: airtableFieldType.isRequired,
  value: bool,
  invalid: bool,
  setRecord: func.isRequired
}

export default styled(RecordCheckbox)`
  font-weight: 400;
  font-size: 1rem;
  margin-top: -2px;

  &.invalid {
    color: ${BASE_COLORS.red};
  }
`
