import { css } from "@linaria/core"

const dialogClass = css`
  max-width: 80vw;
  margin-left: auto;
  margin-right: auto;
  height: calc(100% - 30px * 2);
`

const contentClass = css`
  min-height: 100%;
`

export { dialogClass, contentClass }
