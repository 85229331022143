import React from "react"
import classNames from "classnames"
import { bool, func } from "prop-types"

import { ToggleLabel } from "./styles"

const FiltersToggle = ({ showFilters, toggleShowFilters }) => {
  const filtersToggleClasses = classNames("search-filters-toggle", {
    "search-filters-close": !showFilters,
    "search-filters-open": showFilters
  })

  return (
    <ToggleLabel className={filtersToggleClasses} onClick={toggleShowFilters}>
      <span>{showFilters ? "Hide filters" : "Show filters"}</span>
    </ToggleLabel>
  )
}

FiltersToggle.propTypes = {
  showFilters: bool.isRequired,
  toggleShowFilters: func.isRequired
}

export default FiltersToggle
