import { styled } from "@linaria/react"

import { FONT_SIZES, SPACES } from "src/styles/sizes"
import { BACKGROUND_COLORS, ICON_COLORS, TEXT_COLORS } from "src/styles/colors"
import Icon from "src/styles/components/Icon"

export const JumpToCaption = styled.div`
  font-size: ${FONT_SIZES.small};
  color: ${TEXT_COLORS.heading};
  margin-bottom: ${SPACES.xSmall};
`

export const SuggestionsContainer = styled.div`
  margin-top: ${SPACES.xSmall};
`

export const SuggestionGroup = styled.div`
  margin-bottom: ${SPACES.xSmall};

  &:last-of-type {
    margin-bottom: 0;
  }
`

export const SuggestionLink = styled.a`
  font-size: ${FONT_SIZES.medium};
  color: ${TEXT_COLORS.heading};
  display: flex;
  align-items: center;
  padding: ${SPACES.xSmall} ${SPACES.small};
  margin-left: -${SPACES.small};
  margin-right: -${SPACES.small};

  &.active,
  &:hover,
  &:focus,
  &:active {
    color: ${TEXT_COLORS.heading};
    background: ${BACKGROUND_COLORS.selected};
  }
`

export const SuggestionIcon = styled(Icon)`
  color: ${ICON_COLORS.search};
  margin-right: ${SPACES.xSmall};
`

export const SuggestionTitle = styled.span`
  font-weight: 500;
  margin-right: ${SPACES.xSmall};
`

export const SuggestionHint = styled.span`
  color: ${TEXT_COLORS.muted};
  display: inline-block;
  font-size: ${FONT_SIZES.small};
`
