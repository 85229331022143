export const GROUP_SETTINGS = {
  discussions: "discussions",
  documents: "documents",
  applications: "applications"
}

export const DEFAULT_GROUP_SETTINGS = {
  [GROUP_SETTINGS.discussions]: true,
  [GROUP_SETTINGS.documents]: false,
  [GROUP_SETTINGS.applications]: false
}
