import React from "react"
import { Dropdown } from "react-bootstrap"
import { shape, number } from "prop-types"
import { useMutation } from "@apollo/client"

import Icon from "src/styles/components/Icon"

import ArchiveGroupMutation from "./ArchiveGroup.gql"

const Archive = ({ group }) => {
  const [mutateArchiveGroup] = useMutation(ArchiveGroupMutation)

  const archiveGroup = () => {
    mutateArchiveGroup({
      variables: {
        id: group.id
      }
    }).then(() => {
      window.location.reload()
    })
  }

  return (
    <Dropdown.Item
      onClick={archiveGroup}
      data-confirm="Are you sure you want to archive this group?"
    >
      <div className="dropdown-icon">
        <Icon className="icon-small" type="archive" />
      </div>
      Archive Group
    </Dropdown.Item>
  )
}

Archive.propTypes = {
  group: shape({
    id: number.isRequired
  }).isRequired
}

export default Archive
