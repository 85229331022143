class @TimePickerComponent
  @DURATIONS_SEPARATOR = ' and '
  @DURATION_SEPARATOR = ' '
  @ALLOWED_UNITS = ['h', 'm']

  constructor: ->
    @timePicker = $be('time-picker')
    @clearButton = $be('clear-time-picker')

  setup: =>
    @setupTimePicker()
    @setupClearButton()

  teardown: ->

  setupTimePicker: =>
    @timePicker.flatpickr(
      disableMobile: true,
      noCalendar: true,
      enableTime: true,
      time_24hr: true,
      defaultDate: @timePicker.val(),
      defaultHour: 0,
      parseDate: @parseDate,
      formatDate: @formatDate
    )

  setupClearButton: =>
    @clearButton.click => @timePicker.val('')

  parseDate: (dateString, _format) ->
    durationFromStartOfDay = moment().startOf('day')

    dateString.split(TimePickerComponent.DURATIONS_SEPARATOR).forEach (duration) ->
      [number, unit] = duration.split(TimePickerComponent.DURATION_SEPARATOR)
      durationFromStartOfDay += moment.duration(parseInt(number, 10), unit)

    return new Date(durationFromStartOfDay)

  formatDate: (_date, _format, _locale) ->
    timeString = $('.flatpickr-hour').val() + ':' + $('.flatpickr-minute').val()

    return humanizeDuration(moment.duration(timeString),
      { delimiter: TimePickerComponent.DURATIONS_SEPARATOR,
      units: TimePickerComponent.ALLOWED_UNITS })
