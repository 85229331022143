import { useEffect, useMemo, useState } from "react"

import { MEMBERSHIP_TYPES } from "src/features/meetingPacks/MeetingPack/constants"

export default function useAgendaMemberships({
  userId,
  agendaItems,
  mutateAllAccessOnlyItemsToAttending
}) {
  const [attendingItemsIds, setAttendingItemsIds] = useState([])
  const [accessOnlyItemsIds, setAccessOnlyItemsIds] = useState([])

  const hasAgendaItems = agendaItems?.length > 0
  const allAgendaItemsIds = useMemo(
    () =>
      (agendaItems || []).reduce(
        (allIds, agendaItem) =>
          allIds.concat(agendaItem.id).concat(agendaItem.subItems.map(({ id }) => id)),
        []
      ),
    [agendaItems]
  )

  useEffect(() => {
    if (!hasAgendaItems) {
      return
    }

    const agendaSubItems = agendaItems.flatMap(({ subItems }) => subItems)

    const allUserMemberships = agendaItems
      .concat(agendaSubItems)
      .flatMap(({ agendaItemMemberships }) => agendaItemMemberships)
      .filter((membership) => membership.userId === userId)

    const accessOnlyAgendaItemIds = allUserMemberships
      .filter(({ membershipType }) => membershipType === MEMBERSHIP_TYPES.accessOnly)
      .map(({ agendaItemId }) => agendaItemId)

    const attendingAgendaItemIds = allUserMemberships
      .filter(({ membershipType }) => membershipType !== MEMBERSHIP_TYPES.accessOnly)
      .map(({ agendaItemId }) => agendaItemId)

    if (mutateAllAccessOnlyItemsToAttending) {
      setAttendingItemsIds([...attendingAgendaItemIds, ...accessOnlyAgendaItemIds])
      setAccessOnlyItemsIds([])
    } else {
      setAttendingItemsIds(attendingAgendaItemIds)
      setAccessOnlyItemsIds(accessOnlyAgendaItemIds)
    }
  }, [userId, hasAgendaItems, agendaItems, mutateAllAccessOnlyItemsToAttending])

  const filter = (values, excludedItems) =>
    values.filter((value) => !excludedItems.includes(value))

  const toggleItemAndSubItems = (values, mainItemId, subItemsIds) => {
    if (values.includes(mainItemId)) {
      return filter(values, subItemsIds.concat(mainItemId))
    }

    return values.concat(mainItemId).concat(subItemsIds)
  }

  const toggleAccessOnlyForItemAndSubItems = (mainItemId, subItemsIds) => {
    setAccessOnlyItemsIds(
      toggleItemAndSubItems(accessOnlyItemsIds, mainItemId, subItemsIds)
    )
  }

  const toggleAttendingForItemAndSubItems = (mainItemId, subItemsIds) => {
    setAttendingItemsIds(
      toggleItemAndSubItems(attendingItemsIds, mainItemId, subItemsIds)
    )
  }

  const removeAttending = (allItemsIds) => {
    setAttendingItemsIds(filter(attendingItemsIds, allItemsIds))
  }

  const removeAccessOnly = (allItemsIds) => {
    setAccessOnlyItemsIds(filter(accessOnlyItemsIds, allItemsIds))
  }

  const checkAgendaItem = (agendaItemId, membershipType) => {
    const agendaItem = agendaItems.find(({ id }) => id === agendaItemId)
    const subItems = agendaItem?.subItems || []
    const subItemsIds = subItems.map(({ id }) => id)
    const allItemsIds = subItemsIds.concat(agendaItemId)
    const attendingSelected = attendingItemsIds.includes(agendaItemId)
    const accessOnlySelected = accessOnlyItemsIds.includes(agendaItemId)

    if (membershipType === MEMBERSHIP_TYPES.accessOnly) {
      toggleAccessOnlyForItemAndSubItems(agendaItemId, subItemsIds)

      if (attendingSelected || !accessOnlySelected) {
        removeAttending(allItemsIds)
      }
    } else {
      toggleAttendingForItemAndSubItems(agendaItemId, subItemsIds)

      if (accessOnlySelected || attendingSelected) {
        removeAccessOnly(allItemsIds)
      }
    }
  }

  const selectAllAttendingItems = () => {
    setAttendingItemsIds(allAgendaItemsIds)
    setAccessOnlyItemsIds([])
  }

  const selectAllAccessOnlyItems = () => {
    setAccessOnlyItemsIds(allAgendaItemsIds)
    setAttendingItemsIds([])
  }

  return {
    attendingItemsIds,
    accessOnlyItemsIds,
    checkAgendaItem,
    selectAllAttendingItems,
    selectAllAccessOnlyItems
  }
}
