import { connect } from "react-redux"

import { PUBLICATION_STATUSES } from "src/resources/meetingPacks/constants"
import { getMeetingPack } from "src/features/meetingPacks/MeetingPack/selectors"

import ManageAttendeesModal from "./ManageAttendeesModal"

const mapState = (state) => {
  const meetingPack = getMeetingPack(state)
  return {
    isMeetingPackPublished:
      meetingPack?.publicationStatus === PUBLICATION_STATUSES.published
  }
}

export default connect(mapState)(ManageAttendeesModal)
