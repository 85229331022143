@$be = (behavior) ->
  $("[data-behavior~=#{behavior}]")

@$beById = (behavior, id) ->
  $("[data-behavior~=#{behavior}][data-id=#{id}]")

@$setupChannelSubscriptions = (channelData) ->
  for cd in channelData
    cd.channel.subscribe(cd.subscriber)

@$teardownChannelSubscriptions = (channelData) ->
  for cd in channelData
    cd.channel.unsubscribe(cd.subscriber)

@$generateFlashMessage = (level, message, container) ->
  # levels:
  # info
  # success
  # warning
  # danger
  if container && container.length
    flashContainer = container
  else
    flashContainer = $be('flash-container')

  alertElement = $("
    <div 'data-behavior'='flash-message' class='alert is-#{level}'>
      #{message}
      <button type='button' class='close' 'data-dismiss'='alert' 'aria-label'='Close'>
        <span 'aria-hidden'='true'>&times;</span>
      </button>
    </div>
  ")

  flashContainer.html(alertElement)
  alertElement.find('button.close').click(() -> alertElement.remove())
  alertElement

@$delayedRemove = (element) ->
  if element.length
    setTimeout( ->
      element.remove()
    , 4000)


@$showFlashMessage = (level, message, container) ->
  alertElement = $generateFlashMessage(level, message, container)
  $delayedRemove(alertElement)

@$currentUserId = ->
  @$currentUser().id

@$currentUser = =>
  return @$currentUserModel if @$currentUserModel
  @$currentUserModel = new CurrentUserModel(
    window.currentUserData
  )

@$currentAccountId = ->
  $be('app-layout').data('current-account-id')

@$currentSchemeId = ->
  $be('app-layout').data('current-scheme-id')

@$isCurrentUserId = (id) ->
  Number($currentUserId()) is Number(id)

@$setTimestampContent = (element) ->
  today          = moment().startOf('day')
  yesterday      = moment().startOf('day').subtract(1, 'day')
  messageTime    = moment.utc(element.data('datetime')).local()
  shortTimestamp = messageTime.format("h:mma")

  if (messageTime.format('L') == today.format('L'))
    timestamp = shortTimestamp
  else if (messageTime.format('L') == yesterday.format('L'))
    timestamp = 'yesterday, ' + shortTimestamp
  else if (messageTime.format('Y') == yesterday.format('Y'))
    timestamp = messageTime.format("MMM Do, h:mma")
  else
    timestamp = messageTime.format("MMM Do Y, h:mma")

  element.html(timestamp)

@$isXhrSuccessful = (xhr) ->
  xhr && Math.round(xhr.status / 200) == 1

@$on = (eventType, callback) ->
  $('body').on(eventType, callback)

@$observe = (element, callback, options = {attributes: true}) ->
  observer = new MutationObserver(callback)
  observer.observe(element, options)

@$isPositiveInteger = (string) ->
  return /^\+?(0|[1-9]\d*)$/.test(string)

@$setupUsersCards = (cardsContainer) ->
  window.layoutHandler.setAvatarImages(cardsContainer)
  window.layoutHandler.contactDetailsComponent.bindLinks(cardsContainer)
  window.layoutHandler.showCurrentUserLabel(cardsContainer)

@$scrollToMessage = ($el) ->
  discussionViewport = $be('discussion-viewport')
  messageBound = $el.offset().top
  discussionViewport.scrollTop(
    discussionViewport.scrollTop() + messageBound - 210
  )

@$setupMessageToggle = ($el) ->
  $setToggleVisibility($el)
  $el.find('> .message-footer > .message-size_toggle').on('click', () ->
    $el.toggleClass('message-block__minimized')
    $scrollToMessage($el)
  )

@$setToggleVisibility = ($el) ->
  $imagesInMessage = $el.find('img')

  if $imagesInMessage.length
    $imagesInMessage.on('load', (e) -> $setMinimizable($el))
  else
    $setMinimizable($el)

@$setMinimizable = ($el) ->
  $messageBodyWrapper = $el.find('> .message-content .message-body-wrapper')
  $messageBody = $el.find('> .message-content .message-body')

  if $messageBodyWrapper.height() >= $messageBody.height()
    $el.removeClass('message-block__minimizable')
  else
    $messageBodyWrapper.height($messageBody.height())
    $el.addClass('message-block__minimizable')

@$userCountView = ($count) ->
  if $count > 99
    '>99'
  else
    "+#{$count}"
