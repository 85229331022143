import React from "react"
import { string } from "prop-types"

import useIsOpened from "src/hooks/useIsOpened"

import EnableModal from "./Modal"
import Intro from "./Intro"

const EnableSection = ({ qrCode, otpSecret }) => {
  const { isOpened, open, close } = useIsOpened(false)

  return (
    <>
      {isOpened && <EnableModal close={close} qrCode={qrCode} otpSecret={otpSecret} />}
      <div className="plate-section">
        <h6 className="mbs">Enable 2-factor authentication</h6>
        <Intro />
        <button
          className="is-success-light two-factor-switch"
          type="button"
          onClick={open}
        >
          Turn on extra security
        </button>
      </div>
    </>
  )
}

EnableSection.propTypes = {
  qrCode: string.isRequired,
  otpSecret: string.isRequired
}

export default EnableSection
