class @FroalaToolbarButtons
  @DESKTOP_BUTTONS = {
    moreClearformatting: ['clearFormatting'],
    moreText: [
      'bold',
      'italic',
      'underline',
      'strikeThrough',
      'superscript',
      'subscript',
      'fontSize',
      'textColor',
      'backgroundColor',
      'inlineClass'
    ],
    moreParagraph: [
      'align',
      'formatUL',
      'formatOL',
      'quote',
      'paragraphFormat',
      'paragraphStyle',
      'lineHeight',
      'outdent',
      'indent'
    ],
    moreRich:  [
      'insertTable',
      'insertLink',
      'insertImage',
      'emoticons',
      'insertVideo',
      'specialCharacters',
      'insertHR',
      'embed'
    ],
    openaiButton: ['openaiButton'],
    moreButtons: ['fullscreen'],
    moreMisc:  ['undo', 'redo']
  }
  @DESKTOP = {
    large: {
      moreClearformatting: {
        buttons: FroalaToolbarButtons.DESKTOP_BUTTONS.moreClearformatting,
        align: 'left',
        buttonsVisible: 1
      }
      moreText: {
        buttons: FroalaToolbarButtons.DESKTOP_BUTTONS.moreText,
        align: 'left',
        buttonsVisible: 3
      },
      moreParagraph: {
        buttons: FroalaToolbarButtons.DESKTOP_BUTTONS.moreParagraph,
        align: 'left',
        buttonsVisible: 4
      },
      moreRich: {
        buttons: FroalaToolbarButtons.DESKTOP_BUTTONS.moreRich,
        align: 'left',
        buttonsVisible: 2
      },
      openaiButton: {
        buttons: FroalaToolbarButtons.DESKTOP_BUTTONS.openaiButton,
        align: 'left',
        buttonsVisible: 1
      },
      moreButtons: {
        buttons: FroalaToolbarButtons.DESKTOP_BUTTONS.moreButtons,
        align: 'right',
        buttonsVisible: 1
      },
      moreMisc: {
        buttons: FroalaToolbarButtons.DESKTOP_BUTTONS.moreMisc,
        align: 'right',
        buttonsVisible: 2
      }
    },
    xSmall: {
      moreText: {
        buttons: [
          FroalaToolbarButtons.DESKTOP_BUTTONS.moreText...,
          FroalaToolbarButtons.DESKTOP_BUTTONS.moreClearformatting...
        ],
        align: 'left',
        buttonsVisible: 2
      },
      moreParagraph: {
        buttons: FroalaToolbarButtons.DESKTOP_BUTTONS.moreParagraph,
        align: 'left',
        buttonsVisible: 1
      },
      moreRich: {
        buttons: FroalaToolbarButtons.DESKTOP_BUTTONS.moreRich,
        align: 'left',
        buttonsVisible: 0
      },
      openaiButton: {
        buttons: FroalaToolbarButtons.DESKTOP_BUTTONS.openaiButton,
        align: 'left',
        buttonsVisible: 1
      },
      moreButtons: {
        buttons: FroalaToolbarButtons.DESKTOP_BUTTONS.moreButtons,
        align: 'right',
        buttonsVisible: 1
      },
      moreMisc: {
        buttons: FroalaToolbarButtons.DESKTOP_BUTTONS.moreMisc,
        align: 'right',
        buttonsVisible: 2
      }
    }
  }

  # TODO: use for creating documents
  @DOCUMENT_READY = {
    large: [
      [
        'fullscreen',
        'undo',
        'redo',
        'print'
      ], [
        'bold',
        'italic',
        'underline',
        'textColor',
        'backgroundColor',
        'clearFormatting'
      ], [
        'alignLeft',
        'alignCenter',
        'alignRight',
        'alignJustify',
      ], [
        'formatOL',
        'formatUL',
        'indent',
        'outdent',
      ],
      ['paragraphFormat'],
      ['fontFamily'],
      ['fontSize'],
      [
        'insertLink',
        'insertImage',
        'quote'
      ]
    ],
    xSmall: {
      moreText: {
        buttons: [
          'bold',
          'italic',
          'underline',
          'textColor',
          'backgroundColor',
          'clearFormatting',
          'fontFamily',
          'fontSize'
        ],
        align: 'left',
        buttonsVisible: 2
      },
      moreParagraph: {
        buttons: [
          'align',
          'formatOL',
          'formatUL',
          'indent',
          'outdent',
          'quote',
          'paragraphFormat'
        ],
        align: 'left',
        buttonsVisible: 1
      },
      moreRich: {
        buttons: ['insertLink', 'insertImage', 'print'],
        align: 'left',
        buttonsVisible: 0
      },
      moreMisc: {
        buttons: ['undo', 'redo', 'fullscreen'],
        align: 'right',
        buttonsVisible: 3
      }
    }
  }
