import apiClient from "src/app/apiClient"

const move = ({ discussionId, data }) =>
  apiClient.patch(`/api/discussions/${discussionId}/move`, data)

const discussions = {
  move
}

export default discussions
