import { styled } from "@linaria/react"
import { Modal } from "react-bootstrap"

import { withVariations } from "src/helpers/linaria"

export default styled(withVariations(Modal.Footer, ["noBorder"]))`
  justify-content: center;

  &--no-border {
    border: none;
  }
`
