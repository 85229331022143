import { connect } from "react-redux"

import { appendDocument } from "src/features/meetingPacks/AgendaItem/agendaItemSlice"

import Documents from "./Documents"

const mapState = ({ agendaItem }) => ({
  agendaItemId: agendaItem.id,
  appendToPosition: agendaItem.documents.length
})

const mapDispatch = (dispatch) => ({
  appendDocument(newDocument) {
    dispatch(appendDocument(newDocument))
  }
})

export default connect(mapState, mapDispatch)(Documents)
