import React from "react"
import { oneOf } from "prop-types"

import Icon from "src/styles/components/Icon"

const ROLE_ICON_TYPES = {
  admin: "crown-filled",
  guest: "user",
  member: null
}

const RoleIcon = ({ role, ...props }) => {
  const type = ROLE_ICON_TYPES[role]

  if (!type) {
    return null
  }

  return <Icon type={type} {...props} />
}

RoleIcon.propTypes = {
  role: oneOf([...Object.keys(ROLE_ICON_TYPES)])
}

export default RoleIcon
