import React from "react"
import { Card } from "react-bootstrap"
import { styled } from "@linaria/react"
import classNames from "classnames"
import { arrayOf, func, bool } from "prop-types"

import { airtableFilterType, sortByType } from "src/resources/accounts/airtablePropTypes"
import FiltersList from "src/features/ManageAccount/AirtableApps/EditPage/FiltersList"
import Switch from "src/components/Switch"
import { SPACES } from "src/styles/sizes"

import SortBy from "./SortBy"

const FilterRecords = ({
  filters,
  setFilters,
  filtersSwitchedOn,
  setFiltersSwitchedOn,
  sortBy,
  setSortBy,
  className
}) => {
  return (
    <div className={classNames(className, "container my-3")}>
      <Card className="px-5">
        <div className="d-flex justify-content-between">
          <div>
            <Card.Title>Filter records based on conditions</Card.Title>
            <div className="mb-2">
              <small>All records will be shown if you do not apply filters</small>
            </div>
          </div>

          <Switch switchedOn={filtersSwitchedOn} setSwitchedOn={setFiltersSwitchedOn} />
        </div>

        {filtersSwitchedOn && (
          <div className="mt-2">
            <FiltersList filters={filters} setFilters={setFilters} />
          </div>
        )}

        <SortBy sortBy={sortBy} setSortBy={setSortBy} />
      </Card>
    </div>
  )
}

FilterRecords.propTypes = {
  filters: arrayOf(airtableFilterType),
  filtersSwitchedOn: bool.isRequired,
  setFilters: func.isRequired,
  setFiltersSwitchedOn: func.isRequired,
  sortBy: sortByType,
  setSortBy: func.isRequired
}

export default styled(FilterRecords)`
  .card {
    overflow: visible !important;
  }

  ${Switch} {
    padding-bottom: ${SPACES.small};
    max-height: ${SPACES.xLarge};
  }
`
