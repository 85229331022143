import { createSelector } from "@reduxjs/toolkit"
import { partition, pickBy, identity } from "lodash"

import { isBeforeToday } from "src/helpers/datetime"

export const getMeetingPacks = (state) => state.meetingPacks
export const getMeetingPacksAllIds = (state) => state.meetingPacks.allIds
export const getMeetingPacksById = (state) => state.meetingPacks.byId

export const selectAllMeetingPacks = createSelector(
  [getMeetingPacksAllIds, getMeetingPacksById],
  (allIds, byId) =>
    pickBy(
      allIds.map((id) => byId[id]),
      identity
    )
)

export const selectUpcomingMeetings = createSelector(
  [selectAllMeetingPacks],
  (meetings) => partition(meetings, (meeting) => !isBeforeToday(meeting.startDate))
)
