import { useDispatch } from "react-redux"
import { shape, number } from "prop-types"

import { saveMeetingPackLocation } from "src/helpers/meetingPacks"

import { initState } from "./MeetingPack/meetingPackSlice"

const MeetingPackState = (props) => {
  const dispatch = useDispatch()
  saveMeetingPackLocation(props.meetingPack.id)
  dispatch(initState(props))

  return null
}

MeetingPackState.propTypes = {
  meetingPack: shape({
    id: number.isRequired
  })
}

export default MeetingPackState
