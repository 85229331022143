import React, { useState } from "react"
import { func, string } from "prop-types"

import LinkButton from "src/styles/components/Button/Link"
import SubmitButton from "src/features/TwoFactor/Enable/SubmitButton"

import CodeInput from "./CodeInput"
import Form from "./Form"

const EnableByAppPage = ({ close, qrCode, otpSecret, className }) => {
  const [error, setError] = useState()

  return (
    <Form onSuccess={close} setError={setError}>
      <div className={className}>
        <CodeInput qrCode={qrCode} otpSecret={otpSecret} error={error} />

        <div className="form-main-controls">
          <SubmitButton className="wide-button" />
        </div>
        <div className="form-main-controls">
          <LinkButton onClick={close}>Skip adding extra security</LinkButton>
        </div>
      </div>
    </Form>
  )
}

EnableByAppPage.propTypes = {
  qrCode: string.isRequired,
  otpSecret: string.isRequired,
  close: func.isRequired
}

export default EnableByAppPage
