import React, { useState } from "react"
import { Form, InputGroup } from "react-bootstrap"
import { useFormikContext } from "formik"
import { string } from "prop-types"

import LinkButton from "src/styles/components/Button/Link"

const CodeInput = ({ qrCode, otpSecret, error }) => {
  const { handleChange, handleBlur, values, errors, touched } = useFormikContext()
  const [showSecret, setShowSecret] = useState(false)

  return (
    <>
      <h5 className="mt-4 text-muted">Step 1</h5>
      <p className="text-muted">
        Scan this QR code with your{" "}
        <a
          href="https://help.knowa.co/en/articles/2679196-make-knowa-even-more-secure"
          target="_blank"
          rel="noreferrer"
        >
          authenticator app
        </a>
        :
      </p>

      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: qrCode }} />

      {showSecret ? (
        <p className="text-muted">
          Please enter the following code manually: <code>{otpSecret}</code>
        </p>
      ) : (
        <LinkButton onClick={() => setShowSecret(true)}>
          Can&apos;t scan the QR code?
        </LinkButton>
      )}

      <Form.Group controlId="code">
        <Form.Label className="text-muted small mb-1 mt-3">
          Enter the six-digit code from the application
        </Form.Label>
        <InputGroup>
          <Form.Control
            value={values.code}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            isInvalid={error || (errors.code && touched.code)}
          />
          <Form.Control.Feedback type="invalid">
            {error || errors.code}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    </>
  )
}

CodeInput.propTypes = {
  qrCode: string.isRequired,
  otpSecret: string.isRequired,
  error: string
}

export default CodeInput
