import { parse } from "qs"

const trackSearchResultLinkClick = ({ type, path }) => {
  const queryParams = parse(window.location.search, { ignoreQueryPrefix: true })
  const searchParams = queryParams.search || {}

  const resultLinks = Array.from(document.querySelectorAll("[data-result-link]"))
  const resultLinksHrefs = resultLinks.map((link) => link.href)
  const resultLinksPaths = resultLinksHrefs.map((href) => {
    const url = new URL(href)
    return url.pathname + url.search
  })
  const resultNumber = resultLinksPaths.indexOf(path)

  window.analytics.track("Search_Visit-result", {
    content_type: type,
    where_ids: searchParams.whereIds || searchParams.where_ids || [],
    from_date: searchParams.dateFrom || searchParams.date_from || "",
    to_date: searchParams.dateTo || searchParams.date_to || "",
    user_ids: searchParams.userIds || searchParams.user_ids || [],
    result_number: resultNumber + 1
  })
}

export default trackSearchResultLinkClick
