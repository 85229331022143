class @GroupsViewComponent
  constructor: ->
    @setGroupViewButtons  = $be('set-groups-view')
    @orderToggle          = $be('set-groups-order-toggle')
    @setGroupOrderButtons = $be('set-groups-order')
    @myGroupsList         = $be('my-groups-list')
    @otherGroupsList      = $be('other-groups-list')
    @activeButtonClass    = 'btn-groups-view--active'
    @userSettingsService  = new UserSettingsService()

  setup: ->
    service = @userSettingsService

    @setGroupOrderButtons.click (e) =>
      e.preventDefault()
      service.setGroupsOrder(
        e.target.dataset.id,
        $currentSchemeId(),
        e.target.dataset.value
      ).then(() =>
        @orderToggle.text(e.target.dataset.value)
        Turbo.visit(window.location.pathname)
      )

    @setGroupViewButtons.click (e) =>
      clickedButton = $(e.currentTarget)

      return if clickedButton.hasClass(@activeButtonClass)

      activeButton = @setGroupViewButtons.not(clickedButton)

      activeButton.removeClass(@activeButtonClass)
      clickedButton.addClass(@activeButtonClass)

      @myGroupsList.toggleClass('card-deck--list')
      @otherGroupsList.toggleClass('card-deck--list')

      service.setGroupsView(
        clickedButton.data('id'),
        $currentSchemeId(),
        clickedButton.data('value')
      )

    if $isTouchDevice()
      $('.card-link').show()
