import moment from "moment"

export const SUGGESTIONS = {
  account: "account",
  app: "app",
  discussion: "discussion",
  folder: "folder",
  group: "group",
  jointScheme: "jointScheme",
  meeting: "meeting",
  privateScheme: "privateScheme",
  search: "search"
}

export const ICON_TYPES = {
  [SUGGESTIONS.account]: "earth",
  [SUGGESTIONS.app]: "arrow-right-squared",
  [SUGGESTIONS.discussion]: "message",
  [SUGGESTIONS.folder]: "folder",
  [SUGGESTIONS.group]: "user-group",
  [SUGGESTIONS.jointScheme]: "connected",
  [SUGGESTIONS.meeting]: "list-numbered",
  [SUGGESTIONS.privateScheme]: "lock",
  [SUGGESTIONS.search]: "search"
}

export const RESULT_TYPES = {
  agendaItemDocument: "agenda_item_document",
  agendaItemDescription: "agenda_item_description",
  message: "message",
  attachment: "attachment",
  document: "document",
  contentCloudFile: "content_cloud_file",
  transcript: "transcript",
  assignedTranscriptAgendaItem: "assigned_transcript_agenda_item",
  iframeContent: "iframe_content"
}

export const CATEGORIES = {
  everything: "everything",
  meetings: "meetings",
  discussions: "discussions",
  documents: "documents"
}

export const RESULT_CATEGORIES = {
  [RESULT_TYPES.agendaItemDocument]: [CATEGORIES.everything, CATEGORIES.meetings],
  [RESULT_TYPES.transcript]: [CATEGORIES.everything, CATEGORIES.meetings],
  [RESULT_TYPES.assignedTranscriptAgendaItem]: [
    CATEGORIES.everything,
    CATEGORIES.meetings
  ],
  [RESULT_TYPES.agendaItemDescription]: [CATEGORIES.everything, CATEGORIES.meetings],
  [RESULT_TYPES.message]: [CATEGORIES.everything, CATEGORIES.discussions],
  [RESULT_TYPES.attachment]: [CATEGORIES.everything, CATEGORIES.documents],
  [RESULT_TYPES.document]: [CATEGORIES.everything, CATEGORIES.documents],
  [RESULT_TYPES.contentCloudFile]: [CATEGORIES.everything, CATEGORIES.documents],
  [RESULT_TYPES.iframeContent]: [CATEGORIES.everything, CATEGORIES.discussions]
}

export const ORDER_OPTION_RELEVANCE = { label: "Relevance", value: "relevance" }
export const ORDER_OPTION_DATE = { label: "Date", value: "date" }

export const ORDER_OPTIONS = [ORDER_OPTION_RELEVANCE, ORDER_OPTION_DATE]

export const WHEN_FILTER_DEFAULT = { label: "Since Knowa began", value: null }

export const WHEN_FILTER_MONTH = {
  value: "month",
  label: "Last month",
  timePeriod: moment.duration({ months: 1 })
}
export const WHEN_FILTER_SIX_MONTHS = {
  value: "halfYear",
  label: "Last six months",
  timePeriod: moment.duration({ months: 6 })
}
export const WHEN_FILTER_YEAR = {
  value: "year",
  label: "Last year",
  timePeriod: moment.duration({ years: 1 })
}
export const WHEN_FILTER_DATES = {
  label: "Select dates",
  value: "dates"
}

export const WHEN_FILTER_OPTIONS = [
  WHEN_FILTER_DEFAULT,
  WHEN_FILTER_MONTH,
  WHEN_FILTER_SIX_MONTHS,
  WHEN_FILTER_YEAR,
  WHEN_FILTER_DATES
]
