class @GroupMembershipWatcherComponent
  constructor: () ->
    @schemeMembershipWatcher = new SchemeMembershipWatcherComponent()

    @groupId = $be('group-resource').data('id')
    @redirectToOnLeave = $be('group-resource').data('redirect-to-on-leave')

    @groupMembershipChannel = AblyRealtimeClient.channels.get(
      "groups:membership"
    )

    @channelData = [
      {
        channel: @groupMembershipChannel,
        subscriber: @handleGroupMembershipChange
      }
    ]

  setup: =>
    @schemeMembershipWatcher.setup()
    $setupChannelSubscriptions(@channelData)

  handleGroupMembershipChange: (channelMessage) =>
    groupModel = new GroupModel(channelMessage.data.group)
    return unless groupModel.id is @groupId

    unless groupModel.isAccessible($currentUserId())
      flash = {
        level: "warning",
        text: "You've left the group."
      }
      sessionStorage.setItem("clientFlashMessage", JSON.stringify(flash))
      Turbo.visit(@redirectToOnLeave)

  teardown: =>
    @schemeMembershipWatcher.teardown()
    $teardownChannelSubscriptions(@channelData)
