export const isValidNewOption = (inputValue, _selectValue, selectOptions) => {
  const newOption = inputValue.trim()

  if (newOption.length < 3) return
  if (newOption.length > 30) return
  if (selectOptions.map(({ label }) => label).includes(newOption)) return

  return true
}

export const noOptionsMessage = ({ inputValue }) => {
  const newOption = inputValue.trim()
  const defaultMessage = "No tags found. Create a new one"

  if (!newOption) return defaultMessage
  if (newOption.length < 3) return "Tag is too short"
  if (newOption.length > 30) return "Tag is too long"

  return defaultMessage
}

export const validateTag = (tag, options) => {
  if (tag.length < 3) return "Name is too short."
  if (tag.length > 30) return "Name is too long."
  if (options.map(({ label }) => label).includes(tag)) return "Name is already taken."

  return false
}
