import React, { useEffect, useRef } from "react"
import { Form } from "react-bootstrap"
import { bool, func, string } from "prop-types"
import { styled } from "@linaria/react"

import { airtableFieldType } from "src/resources/accounts/airtablePropTypes"

import FieldLabel from "./FieldLabel"

const MAX_TEXTAREA_HEIGHT = 300

const Textarea = ({ field, value, setRecord, invalid, className }) => {
  const ref = useRef(null)

  useEffect(() => {
    if (!ref.current) return

    const currentHeight = ref.current.scrollHeight
    ref.current.setAttribute(
      "style",
      `height:${
        currentHeight > MAX_TEXTAREA_HEIGHT ? MAX_TEXTAREA_HEIGHT : currentHeight
      }px;`
    )
    ref.current.addEventListener("input", () => {
      const updatedHeight = ref.current.scrollHeight
      ref.current.style.height = `${
        updatedHeight > MAX_TEXTAREA_HEIGHT ? MAX_TEXTAREA_HEIGHT : updatedHeight
      }px`
    })
  }, [ref.current])

  const handleChange = (e) => {
    const newValue = e.target.value
    setRecord((prevRecord) => ({
      ...prevRecord,
      [field.id]: newValue
    }))
  }

  return (
    <div className={className}>
      <FieldLabel field={field} invalid={invalid} />
      <Form.Control
        placeholder="Type something"
        value={value || ""}
        onChange={handleChange}
        as="textarea"
        rows={3}
        ref={ref}
      />
    </div>
  )
}

Textarea.propTypes = {
  field: airtableFieldType.isRequired,
  value: string,
  invalid: bool,
  setRecord: func.isRequired
}

export default styled(Textarea)`
  textarea {
    resize: none;
  }
`
