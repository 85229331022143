import { useEffect, useState } from "react"

import SyncStatusCheck from "../helpers/SyncStatusCheck"

export default function useSyncStatus({ meetingPackId }) {
  const [stats, setStats] = useState({})

  const syncStatusCheck = new SyncStatusCheck({
    onTick: (newStats) => {
      if (meetingPackId && !newStats[meetingPackId]) {
        return
      }

      setStats(meetingPackId ? newStats[meetingPackId] : newStats)
    }
  })

  useEffect(() => {
    syncStatusCheck.start()
    return () => syncStatusCheck.stop()
  }, [syncStatusCheck.syncTimeStart])

  return stats
}
