import React, { useEffect } from "react"
import showdown from "showdown"
import TurndownService from "turndown"
import { string, func } from "prop-types"

const MarkdownEditor = ({ fieldId, initialValue, handleChange }) => {
  useEffect(() => {
    const converterToHTML = new showdown.Converter({ strikethrough: true })
    const converterFromHTML = new TurndownService()

    converterFromHTML.addRule("h1", {
      filter: ["h1"],
      replacement(content) {
        return `# ${content}\n`
      }
    })
    converterFromHTML.addRule("h2", {
      filter: ["h2"],
      replacement(content) {
        return `## ${content}\n`
      }
    })
    converterFromHTML.addRule("h3", {
      filter: ["h3"],
      replacement(content) {
        return `### ${content}\n`
      }
    })
    converterFromHTML.addRule("strikethrough", {
      filter: ["s", "del"],
      replacement(content) {
        return `~~${content}~~`
      }
    })

    // eslint-disable-next-line no-new
    const editor = new window.FroalaEditor(
      `#${fieldId}`,
      {
        key: window.FroalaKey,
        toolbarButtons: [
          "clearFormatting",
          "|",
          "bold",
          "italic",
          "strikeThrough",
          "insertLink",
          "paragraphFormat",
          "|",
          "formatOL",
          "formatUL"
        ],
        paragraphFormat: {
          N: "Normal",
          H1: "Heading 1",
          H2: "Heading 2",
          H3: "Heading 3"
        },
        htmlAllowedTags: [
          "strong",
          "del",
          "b",
          "i",
          "em",
          "s",
          "a",
          "h1",
          "h2",
          "h3",
          "ol",
          "ul",
          "li",
          "p"
        ],
        htmlAllowedAttrs: ["href"],
        quickInsertEnabled: false,
        listAdvancedTypes: false,
        events: {
          contentChanged() {
            const htmlValue = editor.html.get()
            const markdown = converterFromHTML.turndown(htmlValue)

            handleChange(markdown)
          }
        }
      },
      () => {
        editor.html.set(converterToHTML.makeHtml(initialValue || ""))
      }
    )
  }, [])

  return <textarea id={fieldId} />
}

MarkdownEditor.propTypes = {
  fieldId: string.isRequired,
  handleChange: func.isRequired,
  initialValue: string
}

export default MarkdownEditor
