import React from "react"
import { styled } from "@linaria/react"
import { bool, node, oneOf, oneOfType } from "prop-types"

import { composeVariationsClasses } from "src/helpers/linaria"
import {
  BREAKPOINTS,
  SPACES,
  contentsSidebarToggleBorderRadius,
  sidebarToggleButtonSize,
  FONT_SIZES
} from "src/styles/sizes"
import {
  contentsSidebarToggleColor,
  contentsSidebarToggleBackground
} from "src/styles/colors"
import Icon from "src/styles/components/Icon"

const SidebarToggleButton = ({
  className,
  children,
  badge,
  border = "",
  fullWidth = false,
  ...rest
}) => {
  const classes = composeVariationsClasses(className, { border, fullWidth })

  return (
    <div className={classes} {...rest}>
      {children}
      <Icon type="list-numbered" size="large" />
      {badge && <div className="sidebar-toggle-badge">{badge}</div>}
    </div>
  )
}

SidebarToggleButton.propTypes = {
  badge: oneOfType([node, bool]),
  border: oneOf(["", "left", "right"]),
  fullWidth: bool
}

export default styled(SidebarToggleButton)`
  background-color: ${contentsSidebarToggleBackground};
  height: ${sidebarToggleButtonSize};
  width: ${sidebarToggleButtonSize};
  min-width: ${sidebarToggleButtonSize};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: ${SPACES.small};
  position: relative;

  &--border-right {
    border-radius: 0 ${contentsSidebarToggleBorderRadius}
      ${contentsSidebarToggleBorderRadius} 0;
  }

  &--border-left {
    border-radius: ${contentsSidebarToggleBorderRadius} 0 0
      ${contentsSidebarToggleBorderRadius};
  }

  .icon-list-numbered {
    color: ${contentsSidebarToggleColor};
  }

  & .sidebar-toggle-badge {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);

    ${Icon} {
      font-size: ${FONT_SIZES.xSmall};
      height: 20px;
      width: 20px;
    }
  }

  @media (max-width: ${BREAKPOINTS.xSmall}) {
    display: none;
  }
`
