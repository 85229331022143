import React from "react"

import useIsOpened from "src/hooks/useIsOpened"

import CustomTermsModal from "./index"

const OpenedModal = (props) => {
  const { isOpened, close } = useIsOpened(true)

  const handleAccept = () => close()

  if (!navigator.onLine) return

  return (
    <CustomTermsModal
      isOpened={isOpened}
      close={close}
      onAccept={handleAccept}
      {...props}
    />
  )
}

export default OpenedModal
