import apiClient from "src/app/apiClient"

const setMemberships = ({ agendaItemId, memberships }) => {
  return apiClient.put(
    `/api/agenda_items/${agendaItemId}/memberships`,
    { memberships },
    {
      headers: {
        Accept: "text/html"
      }
    }
  )
}

export default {
  setMemberships
}
