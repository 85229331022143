import React, { useContext } from "react"
import { number, func } from "prop-types"
import { pick } from "lodash"
import { styled } from "@linaria/react"
import { Accordion } from "react-bootstrap"

import { airtableSectionType } from "src/resources/accounts/airtablePropTypes"
import Icon from "src/styles/components/Icon"
import useDragDrop from "src/features/ManageAccount/AirtableApps/EditPage/useDragDrop"
import { SPACES } from "src/styles/sizes"
import { BASE_COLORS } from "src/styles/colors"
import AccordionToggle from "src/styles/components/AccordionToggle"
import PageMetadataContext from "src/features/ManageAccount/AirtableApps/EditPage/PageMetadataContext"
import { generateUniqId } from "src/helpers/string"

import FieldRow from "./FieldRow"

const SectionRow = ({ section, setSections, index, openEditSectionModal, className }) => {
  const { allFields } = useContext(PageMetadataContext)
  const {
    ref,
    preview,
    handlerId,
    style: dragAndDropStyle
  } = useDragDrop({
    id: section.id,
    index,
    itemType: "section",
    setItems: setSections
  })

  const setFields = (setFieldsFunc) =>
    setSections((prevSections) =>
      prevSections.map((s) =>
        s.id === section.id ? { ...s, fields: setFieldsFunc(s.fields) } : s
      )
    )

  const setField = (field) =>
    setSections((prevSections) =>
      prevSections.map((s) => {
        if (s.id !== section.id) return s

        return {
          ...s,
          fields: s.fields.map((f) =>
            f.id === field.id
              ? pick(field, ["id", "readOnly", "required", "description", "filters"])
              : f
          )
        }
      })
    )

  const duplicateSection = (sectionToDuplicate) =>
    setSections((prevSections) => {
      const insertToIndex = prevSections.findIndex((s) => s.id === section.id) + 1
      return prevSections.toSpliced(insertToIndex, 0, {
        ...sectionToDuplicate,
        id: generateUniqId()
      })
    })

  return (
    <Accordion
      ref={ref}
      data-handler-id={handlerId}
      style={dragAndDropStyle}
      className={className}
    >
      <div className="d-flex align-items-center mt-2">
        <AccordionToggle eventKey={index.toString()} />
        <Accordion.Toggle
          as="div"
          eventKey={index.toString()}
          className="flex-grow-1 pl-3 text-truncate section-row"
          ref={preview}
        >
          {section.name}
        </Accordion.Toggle>
        <Icon
          type="pencil"
          size="small"
          className="pl-2"
          onClick={() => openEditSectionModal(section)}
        />
        <Icon
          type="copy"
          size="small"
          className="pl-2"
          onClick={() => duplicateSection(section)}
        />
      </div>

      <Accordion.Collapse eventKey={index.toString()}>
        <>
          {section.fields.map((field, i) => (
            <FieldRow
              key={field.id}
              field={{ ...field, name: allFields[field.id]?.name }}
              index={i}
              setFields={setFields}
              sectionId={section.id}
              setField={setField}
            />
          ))}
        </>
      </Accordion.Collapse>
    </Accordion>
  )
}

SectionRow.propTypes = {
  section: airtableSectionType,
  setSections: func.isRequired,
  index: number.isRequired,
  openEditSectionModal: func.isRequired
}

export default styled(SectionRow)`
  padding-left: ${SPACES.xSmall};

  .section-row {
    border-radius: 4px;
    padding: ${SPACES.xSmall};
    background-color: ${BASE_COLORS.lightGray};
  }
`
