import React from "react"
import { func } from "prop-types"

import Icon from "src/styles/components/Icon"

import { TogglerWrapper } from "./styles"

const Toggler = ({ openFunction }) => {
  return (
    <div
      onClick={openFunction}
      aria-hidden="true"
      className="position-relative no-print"
      data-behavior="drawer-toggler"
    >
      <TogglerWrapper className="wrapper">
        <Icon type="dropdown-arrow" size="large" />
      </TogglerWrapper>
    </div>
  )
}

Toggler.propTypes = {
  openFunction: func.isRequired
}

export default Toggler
