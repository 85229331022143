import React from "react"
import classNames from "classnames"
import { styled } from "@linaria/react"
import { arrayOf, string, shape, oneOf, func } from "prop-types"

import { BACKGROUND_COLORS } from "src/styles/colors"
import Icon from "src/styles/components/Icon"

const nextSortDirection = (sortDirection) => (sortDirection === "asc" ? "desc" : "asc")

const Header = ({ className, columns, sortBy, sortDirection, sort }) => {
  return (
    <thead className={className}>
      <tr>
        {columns.map((column, index) => (
          <th
            data-testid="attendees-table-header-cell"
            className={`${index === columns.length - 1 ? "pr-0" : ""}`}
            key={column.key}
            onClick={() =>
              sort({
                sortBy: column.key,
                sortDirection:
                  sortBy === column.key ? nextSortDirection(sortDirection) : "asc"
              })
            }
          >
            <div>
              {column.label}
              <Icon
                type="dropdown-arrow-filled"
                className={classNames({
                  invisible: sortBy !== column.key,
                  "desc-sorted": sortDirection === "desc"
                })}
              />
            </div>
          </th>
        ))}
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <th> </th>
      </tr>
    </thead>
  )
}

Header.propTypes = {
  columns: arrayOf(
    shape({
      key: string.isRequired
    })
  ).isRequired,
  sort: func.isRequired,
  sortBy: string,
  sortDirection: oneOf(["asc", "desc"])
}

export default styled(Header)`
  background-color: ${BACKGROUND_COLORS.dark};
  color: white;

  tr th {
    cursor: pointer;
    vertical-align: middle;

    & > div {
      display: flex;
      align-items: center;
    }
  }

  [class*="icon-"] {
    &.desc-sorted {
      transform: rotate(180deg);
    }
  }
`
