import React, { useCallback } from "react"
import classNames from "classnames"
import { shape, number, bool } from "prop-types"
import { useDispatch } from "react-redux"

import { toggleMessageStarred } from "src/resources/messages/thunks"

import { MessageStarLink, MessageStarIcon } from "./styles"

const MessageMenu = ({ message: { id, starred } }) => {
  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
    dispatch(toggleMessageStarred({ id, starred }))
  }, [id, starred, dispatch])

  const starIconClass = starred ? "icon-star-filled" : "icon-star-hollow"
  const messageStarClasses = classNames("message-star", {
    "message-star__starred": starred
  })

  return (
    <div className="message-menu button-group">
      <div className="message-stars">
        <MessageStarLink
          className={messageStarClasses}
          data-behavior={starred ? "unstar-message" : "star-message"}
        >
          <MessageStarIcon className={starIconClass} onClick={handleClick} />
        </MessageStarLink>
      </div>
    </div>
  )
}

MessageMenu.propTypes = {
  message: shape({
    id: number.isRequired,
    starred: bool,
    scheme: shape({})
  }).isRequired
}

export default MessageMenu
