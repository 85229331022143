import { useMemo } from "react"
import { keyBy, truncate } from "lodash"

const usePageMetadata = ({ pageMetadata }) => {
  const metadataFields = pageMetadata?.fields || []
  const fieldOptions = useMemo(
    () =>
      metadataFields.map((field) => ({
        value: field.id,
        label: truncate(field.name, { length: 50 })
      })) || [],
    [metadataFields]
  )

  const allFields = useMemo(() => keyBy(metadataFields, "id"), [metadataFields])

  return { fieldOptions, allFields }
}

export default usePageMetadata
