import { be } from "src/helpers/document"

const localStoragePositionKey = "agendaItemsListPosition"
const localStorageShouldScrollKey = "agendaItemsListShouldScrollToPosition"

export class AgendaScrollPosition {
  setupList() {
    const agendaItemsList = be("agenda-items-list").get(0)

    be("agenda-item-link").on("click", () =>
      localStorage.setItem(localStoragePositionKey, agendaItemsList.scrollTop)
    )

    be("edit-agenda-item-link").on("click", () =>
      localStorage.setItem(localStoragePositionKey, agendaItemsList.scrollTop)
    )

    be("new-agenda-item-link").on("click", () =>
      localStorage.setItem(localStoragePositionKey, 999999)
    )

    be("add-break-link").on("click", () =>
      localStorage.setItem(localStoragePositionKey, 999999)
    )

    if (localStorage.getItem(localStorageShouldScrollKey)) {
      localStorage.removeItem(localStorageShouldScrollKey)
      agendaItemsList.scrollTop = localStorage.getItem(localStoragePositionKey)
    }

    localStorage.removeItem(localStoragePositionKey)
  }

  teardownList() {
    be("agenda-item-link").off("click")
  }

  setupBackButton() {
    be("back-to-agenda").on("click", () =>
      localStorage.setItem(localStorageShouldScrollKey, true)
    )
  }

  teardownBackButton() {
    be("back-to-agenda").off("click")
  }

  setupSubmitButton() {
    be("agenda-item-form-submit").on("click", () =>
      localStorage.setItem(localStorageShouldScrollKey, true)
    )
  }

  teardownSubmitButton() {
    be("agenda-item-form-submit").off("click")
  }

  setupSubmitBreakButton() {
    be("break-form-submit").on("click", () =>
      localStorage.setItem(localStorageShouldScrollKey, true)
    )
  }

  teardownSubmitBreakButton() {
    be("break-form-submit").off("click")
  }
}
