import * as Sentry from "@sentry/browser"

import { getCurrentUserId } from "src/helpers/user"
import {
  getCommonToolbarItems,
  getAllAnnotations,
  getFormFieldsWithAnnotationsForUser
} from "src/features/signedDocuments/helpers"
import { sign, updateSignatories } from "src/api/signedDocuments"
import { showFlashMessage } from "src/helpers/flash"

export const createJumpToNextSignatureButton = (instance) => {
  let annotationNavigationIndex = 0
  const currentUserId = getCurrentUserId()

  return {
    type: "custom",
    className: "custom-button jump-to-next-button",
    id: "jump-to-next-button",
    title: "Jump to next",
    async onPress() {
      const formFields = await instance.getFormFields()
      const annotations = await getAllAnnotations(instance)

      const formFieldsAnnotations = formFields
        .map((ff) => ff?.annotationIds?.toArray())
        .toArray()
        .flat()
        .filter(Boolean)
        .map((annotationId) =>
          annotations
            .find(
              ({ id, customData }) =>
                customData?.assignedUserId === currentUserId && annotationId === id
            )
            ?.toJS()
        )
        .filter(Boolean)

      const signedAnnotationIds = annotations
        .filter((annotation) => annotation.isSignature)
        .map((annotation) => annotation?.customData?.signatureFieldId)
        .filter(Boolean)

      const unsignedAnnotations = formFieldsAnnotations
        .filter((annotation) => !signedAnnotationIds.includes(annotation.id))
        .sort((first, second) => first.pageIndex - second.pageIndex)

      if (unsignedAnnotations[annotationNavigationIndex]) {
        instance.setViewState((viewState) => {
          return viewState.set(
            "currentPageIndex",
            unsignedAnnotations[annotationNavigationIndex].pageIndex
          )
        })
      }

      annotationNavigationIndex = unsignedAnnotations[annotationNavigationIndex + 1]
        ? annotationNavigationIndex + 1
        : 0
    }
  }
}

export const createFinishButton = ({ signDocument }) => {
  return {
    type: "custom",
    className: "custom-button finish-button",
    id: "finish-button",
    title: "Finish and save",
    onPress: () => {
      signDocument()
    }
  }
}

export const setToolbarButtons = async ({
  instance,
  signDocument,
  documentName,
  isCurrentUserAuthor = false
}) => {
  const formFields = await getFormFieldsWithAnnotationsForUser(
    instance,
    getCurrentUserId()
  )

  let toolbarItems = getCommonToolbarItems({ instance, documentName })

  if (!formFields.length && isCurrentUserAuthor) {
    toolbarItems = [...toolbarItems, createFinishButton({ signDocument })]
  }

  if (formFields.length) {
    const finished = formFields.every((formField) => {
      return formField?.signature || formField?.formField?.value
    })

    toolbarItems = [
      ...toolbarItems,
      finished
        ? createFinishButton({ signDocument })
        : createJumpToNextSignatureButton(instance)
    ]
  }

  instance.setToolbarItems(toolbarItems)
}

export const annotationRenderer = ({ annotation }) => {
  const currentUserId = getCurrentUserId()
  const { customData } = annotation

  if (!customData) {
    return null
  }

  // add "sign" label to text fields
  if (
    annotation.formFieldName?.includes("TEXT") &&
    customData.assignedUserId === currentUserId
  ) {
    const node = document.createElement("div")
    node.classList.add("PSPDFKit-Text-Field-Sign-Label")
    node.innerText = "sign"

    return {
      node,
      append: true
    }
  }

  // don't modify signature and text field assigned to current user
  if (
    customData.assignedUserId === currentUserId ||
    customData.signedUserId === currentUserId
  ) {
    return null
  }

  if (customData?.signature) {
    const node = document.createElement("div")
    node.classList.add("PSPDFKit-Form-Sign-Block")

    return {
      node,
      append: false
    }
  }

  const node = document.createElement("div")
  node.classList.add("PSPDFKit-Annotation-Data")
  node.dataset.id = annotation.id
  node.dataset.userId = customData.assignedUserId || customData.signedUserId

  return {
    node,
    append: true
  }
}

/**
 * This observer looks for inserted annotations and makes them readonly if user is not allowed to edit them
 * @param {Document} contentDocument
 * @param {number} currentUserId
 */
export const connectAnnotationsMutationObserver = ({
  contentDocument,
  currentUserId
}) => {
  const observer = new MutationObserver((mutationList) => {
    mutationList.forEach((mutation) => {
      // PSPDFKit-Annotation-Data will be added into plain "div"
      // so we can skip return early here
      if (mutation.target.className) {
        return
      }

      const annotationDataElement = mutation.target.querySelector(
        ".PSPDFKit-Annotation-Data"
      )

      if (!annotationDataElement) {
        return
      }

      const annotationData = annotationDataElement.dataset

      if (annotationData.userId === currentUserId) {
        return
      }

      const annotationElement = contentDocument.querySelector(
        `[data-annotation-id='${annotationData.id}']`
      )

      if (!annotationElement) {
        return
      }

      annotationElement.style.pointerEvents = "none"
      annotationElement.style.cursor = "default"

      const signatureBadge = annotationElement.querySelector(
        ".PSPDFKit-Annotation-Widget-Signature-Badge"
      )
      if (signatureBadge) signatureBadge.style.display = "none"

      const annotationInput = annotationElement.querySelector("input")
      annotationInput?.setAttribute("disabled", "disabled")
    })
  })

  observer.observe(contentDocument, { childList: true, subtree: true })
}

export const withSyncTimeout = (callback) => {
  window.setTimeout(callback, 1000)
}

const updateSignatoriesAndSign = ({
  documentId,
  updateDocument,
  close,
  setIsSigning
}) => {
  updateSignatories({ documentId })
    .then(({ data }) => {
      if (!data.signedByAll || data.isSigned) {
        setIsSigning(false)
        return close()
      }

      sign({ documentId })
        .then(({ data: { document } = {} }) => {
          updateDocument({
            ...document,
            signedPSPDFKitId: document.signedPspdfkitId
          })
        })
        .catch((e) => {
          Sentry.captureException(e)
          showFlashMessage("danger", "Could not sign document")
        })
        .finally(() => {
          setIsSigning(false)
        })
    })
    .catch((e) => {
      Sentry.captureException(e)
      setIsSigning(false)
      showFlashMessage("danger", "Could not sign document")
    })
}

export const createSignDocument =
  ({ documentId, updateDocument, close, setIsSigning }) =>
  () => {
    setIsSigning(true)

    withSyncTimeout(() =>
      updateSignatoriesAndSign({ documentId, updateDocument, close, setIsSigning })
    )
  }

// PSPDFKit has but in saving attachments and as result <img> tag with attachment
// removed after annotation with attachment saved to the backend for the first time per document
// this piece of code restores removed img tag
export const ensureAnnotationAttachmentsAreShown = ({ instance, annotations }) => {
  annotations.forEach(async ({ id, imageAttachmentId }) => {
    if (!imageAttachmentId) {
      return
    }

    const annotationElement = instance.contentDocument.querySelector(
      `.PSPDFKit-Image-Annotation[data-annotation-id="${id}"]`
    )

    if (!annotationElement) {
      return
    }

    const attachmentBlob = await instance.getAttachment(imageAttachmentId)

    // somehow added tag prevents <img> from being deleted
    annotationElement.querySelector("div").appendChild(document.createElement("span"))

    const reader = new FileReader()
    reader.addEventListener(
      "load",
      () => {
        const existingPreview = annotationElement.querySelector("img")

        if (existingPreview) {
          existingPreview.src = reader.result
        } else {
          const preview = document.createElement("img")
          annotationElement.querySelector(".PSPDFKit-APStream").appendChild(preview)
          preview.src = reader.result
        }
      },
      false
    )

    reader.readAsDataURL(attachmentBlob)
  })
}
