import React from "react"
import { func, instanceOf, oneOfType } from "prop-types"

import { splitFilename } from "src/helpers/string"
import FileIcon from "src/styles/components/FileIcon"
import FolderIcon from "src/styles/components/icons/FolderIcon"

import UploadFolder from "../../../UploadFolder"
import { UploadFile } from "../../../UploadFile"

import { ListItem, Container, IconContainer, Name } from "./styles"
import Controls from "./Controls"

const fileIcon = (file) => {
  const { extension } = splitFilename(file.name)

  return <FileIcon extension={extension} />
}

const folderItemIcon = (folderItem) => {
  if (folderItem instanceof UploadFile) {
    return fileIcon(folderItem)
  }

  return <FolderIcon />
}

const FolderItem = ({ folderItem, onRemove, children }) => {
  return (
    <ListItem>
      <Container>
        <IconContainer>{folderItemIcon(folderItem)}</IconContainer>
        <Name>{folderItem.name}</Name>
        <Controls folderItem={folderItem} onRemove={onRemove} />
      </Container>
      {children}
    </ListItem>
  )
}

FolderItem.propTypes = {
  folderItem: oneOfType([instanceOf(UploadFile), instanceOf(UploadFolder)]).isRequired,
  onRemove: func.isRequired
}

export default FolderItem
