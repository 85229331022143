import { Form } from "react-bootstrap"
import Select from "react-select"
import React from "react"
import { arrayOf, bool, func, number, shape, string } from "prop-types"

import { Filter } from "../styles"

import ExtendedControl from "./ExtendedControl"
import DatePickers from "./DatePickers"
import useFilters from "./useFilters"

const EmailsFilter = ({ setFilters, participants, opened, toggle, visible }) => {
  const {
    filterText,
    filterAuthor,
    dateFrom,
    dateTo,
    setFilterText,
    setFilterAuthor,
    setDateFrom,
    setDateTo,
    clearCommonFilters,
    authorOptions
  } = useFilters({
    participants,
    setFilters
  })

  const clearFilters = () => {
    clearCommonFilters()
  }

  return (
    <Filter className={`${visible ? "" : "hidden"}`}>
      <Form.Control
        value={filterText}
        placeholder="Filter by subject, body or person"
        onChange={({ target }) => setFilterText(target.value)}
      />
      <ExtendedControl opened={opened} toggle={toggle} clear={clearFilters} />

      {opened && (
        <div className="mts">
          <div className="dropdown-pickers">
            <Select
              className="mb-2"
              onChange={({ value }) => setFilterAuthor(value)}
              options={authorOptions}
              value={authorOptions.find((option) => option.value === filterAuthor)}
              isSearchable={false}
            />
          </div>
          <DatePickers
            dateFrom={dateFrom}
            dateTo={dateTo}
            setDateFrom={setDateFrom}
            setDateTo={setDateTo}
          />
        </div>
      )}
    </Filter>
  )
}

EmailsFilter.propTypes = {
  setFilters: func.isRequired,
  toggle: func.isRequired,
  opened: bool.isRequired,
  visible: bool.isRequired,
  participants: arrayOf(
    shape({
      id: number.isRequired,
      firstName: string.isRequired,
      lastName: string.isRequired
    })
  )
}

export default EmailsFilter
