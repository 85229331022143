import { styled } from "@linaria/react"

import Icon from "src/styles/components/Icon"
import { ICON_COLORS, TEXT_COLORS } from "src/styles/colors"
import { FONT_WEIGHTS, SPACES } from "src/styles/sizes"

export const GroupHeader = styled.p`
  color: ${TEXT_COLORS.muted};
  font-weight: ${FONT_WEIGHTS.normal};
  text-align: center;
  text-transform: uppercase;
`

export const UsersListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 6px;
`

export const UserCardContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  &:hover {
    cursor: pointer;
  }
`

export const IconForward = styled(Icon)`
  margin-top: ${SPACES.large};
  color: ${ICON_COLORS.header};
`
