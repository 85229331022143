import { styled } from "@linaria/react"

import { TEXT_COLORS } from "src/styles/colors"

const Text = styled.div`
  background-color: transparent;
  border: none;
  color: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  padding: 0;
`

export const DocumentName = styled(Text)``

export const PagesCount = styled(Text)`
  color: ${TEXT_COLORS.heading};
`
