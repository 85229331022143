import React from "react"
import { oneOfType, shape, string, bool, arrayOf } from "prop-types"

import { airtableFieldType } from "src/resources/accounts/airtablePropTypes"
import {
  FIELD_TYPES,
  NON_EDITABLE_FIELD_TYPES
} from "src/features/AirtableApp/Form/constants"

import RichTextField from "./RichTextField"
import MultipleAttachmentsField from "./MultipleAttachmentsField"
import PlainReadOnly from "./PlainReadOnly"

const Index = ({ field, value, invalid }) => {
  const inputProps = { field, value, invalid }

  if (value?.error) return <PlainReadOnly {...inputProps} value={value.error} />

  if (field.type === FIELD_TYPES.richText) return <RichTextField {...inputProps} />

  if (field.type === NON_EDITABLE_FIELD_TYPES.multipleAttachments)
    return <MultipleAttachmentsField {...inputProps} />

  return <PlainReadOnly {...inputProps} value={value} />
}

Index.propTypes = {
  field: airtableFieldType.isRequired,
  value: oneOfType([string, shape({}), arrayOf(string), arrayOf(shape({}))]),
  invalid: bool
}

export default Index
