import { useEffect, useMemo, useState } from "react"
import { useMutation } from "@apollo/client"
import { sample } from "lodash"

import searchMutation from "src/features/Search/SearchPage/Search/search.gql"
import { CHANNELS } from "src/constants/ably"
import { generateUniqId } from "src/helpers/string"
import { getCurrentUserId } from "src/helpers/user"
import useAblyChannel from "src/hooks/useAblyChannel"

export default function useSearchResults({
  query,
  whereIds,
  dateFrom,
  dateTo,
  whoIds,
  showAiAnswer,
  skip
}) {
  const [loading, setLoading] = useState(true)
  const [results, setResults] = useState([])
  const channelName = useMemo(() => sample(CHANNELS.search.results), [])
  const searchRequestId = useMemo(() => generateUniqId(), [])

  const [performSearch] = useMutation(searchMutation)

  useEffect(() => {
    if (!query || skip) return

    performSearch({
      variables: {
        query,
        whereIds,
        dateFrom,
        dateTo,
        whoIds,
        analyticsContext: { aiAnswer: showAiAnswer },
        channelName,
        searchRequestId
      }
    })
  }, [query, whereIds, dateFrom, dateTo, whoIds, showAiAnswer, skip])

  useEffect(() => {
    if (!query) setResults([])
  }, [query])

  useAblyChannel(channelName, {
    onMessage: ({ data: publisherData }) => {
      if (
        publisherData.userId === getCurrentUserId() &&
        publisherData.searchRequestId === searchRequestId
      ) {
        setResults(publisherData.results)
        setLoading(false)
      }
    }
  })

  return {
    results,
    loading
  }
}
