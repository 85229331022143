import { styled } from "@linaria/react"

import { withVariations } from "src/helpers/linaria"
import { FONT_SIZES, SPACES } from "src/styles/sizes"

export const MessageIcon = styled.span`
  &.icon-mention {
    font-size: ${FONT_SIZES.semiLarge};
    padding-left: 0.15rem;
  }
`

export const MessageToggle = styled(withVariations("span", ["disabled"]))`
  &--disabled {
    cursor: default;
  }
`

export const MessageContent = styled.div`
  margin: 0;
`

export const MessageCreds = styled.div`
  margin-bottom: ${SPACES.xxSmall};
`
