class @GroupLeaveLinkComponent
  constructor: (groupId)  ->
    @groupId = Number(groupId)
    @link = $be('leave-group-link')
    @stub = $be('leave-group-link-stub')

    @groupMembershipChannel = AblyRealtimeClient.channels.get(
      "groups:membership"
    )

    @channelData = [
      {
        channel: @groupMembershipChannel,
        subscriber: @updateLinkAvailability
      }
    ]

  setup: =>
    $setupChannelSubscriptions(@channelData)

  teardown: =>
    $teardownChannelSubscriptions(@channelData)

  updateLinkAvailability: (channelMessage) =>
    groupModel = new GroupModel(channelMessage.data.group)
    return unless groupModel.id is @groupId
    @toggle(groupModel.allowLeave($currentUserId()))

  toggle: (flag) =>
    if flag
      @link.removeClass('d-none')
      @stub.addClass('d-none')
    else
      @stub.removeClass('d-none')
      @link.addClass('d-none')
