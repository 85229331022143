# TODO: check if it's used
class @StarredItemsShowHandler
  constructor: ->
    @starredItemsService = new StarredItemsService()
    @starredItemsList = $be('starred-items-list')

    @starMessageComponent = new StarMessageComponent(@starredItemsList)

    @loadMessagesPageLink = $be('load-starred-items-page-link')
    @loadingAnimation = $be('loading-animation')

    @loadingItemsInProgress = false
    @allItemsAreLoaded = false

    @perPage = @starredItemsList.data('per-page')
    @nextPage = 2

  setup: ->
    @starMessageComponent.setup()

    @setupMessages()
    @setupMessageToggle()
    @setupScrollHandler()

    window.layoutHandler.showCurrentUserLabel(@starredItemsList)

  setupMessages: ->
    @setTimestamps()
    @setTogglesVisibility()
    window.layoutHandler.setAvatarImages(@starredItemsList)

  setupMessageToggle: ->
    @starredItemsList.on('click', (e) ->
      return unless e.target.dataset.behavior == 'message-size_toggle'

      $(".message-block[data-id='#{e.target.dataset.id}']")
        .toggleClass('message-block__minimized')
    )

  setupScrollHandler: =>
    @loadMessagesPageLink.on 'inview', (e, visible) =>
      return if @loadingItemsInProgress or @allItemsAreLoaded or not visible
      @fetchItems(@nextPage)

  fetchItems: (page) =>
    @startLoading()

    @starredItemsService
      .getStarredItems('message', page)
      .done((response) => @pageLoaded(response))
      .fail(() => @stopLoading())

  startLoading: ->
    @loadingItemsInProgress = true
    @loadingAnimation.removeClass('hidden')

  stopLoading: ->
    @loadingAnimation.addClass('hidden')
    @loadingItemsInProgress = false

  pageLoaded: (response) =>
    if response.size
      @starredItemsList.append(response.items)
      @setupMessages()
      @setupAttachments()

      if response.size < @perPage
        @allItemsAreLoaded = true
      else
        @nextPage++
    else
      @allItemsAreLoaded = true

    @stopLoading()

  setTimestamps: ->
    for el in $be('message-timestamp')
      $setTimestampContent($(el))

  setTogglesVisibility: ->
    $be('message-container').each((i, el) =>
      $el = $(el)
      $imagesInMessage = $el.find('img')

      if $imagesInMessage.length
        $imagesInMessage.on('load', (e) => @setToggleVisibility($el))
      else
        @setToggleVisibility($el)
    )

  setToggleVisibility: ($el) ->
    $messageBodyWrapper = $el.find('.message-body-wrapper')
    $messageBody = $el.find('.message-body')

    if $messageBodyWrapper.height() >= $messageBody.height()
      $el.removeClass('message-block__minimizable')
    else
      $messageBodyWrapper.height($messageBody.height())
      $el.addClass('message-block__minimizable')

  teardown: ->

