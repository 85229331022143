import React from "react"
import { string } from "prop-types"
import { css } from "@linaria/core"

import TextWithTooltip from "src/styles/components/TextWithTooltip"

const containerStyles = css`
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: default;
  overflow: hidden;
`

const mainTextStyles = css`
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const subTextStyles = css`
  font-size: 11px;
  line-height: 1;
`

const DoubleLineHeader = ({ mainText, subText }) => (
  <div className={containerStyles}>
    <div className={mainTextStyles}>
      <TextWithTooltip text={mainText} />
    </div>
    <div className={subTextStyles}>
      <TextWithTooltip text={subText} />
    </div>
  </div>
)

DoubleLineHeader.propTypes = {
  mainText: string.isRequired,
  subText: string.isRequired
}

export default DoubleLineHeader
