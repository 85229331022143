import sharedBoxOptions from "./sharedBoxOptions"

const contentPickerOptions = () => ({
  ...sharedBoxOptions(),
  isTouch: true,
  canUpload: false,
  canCreateNewFolder: false,
  canSetShareAccess: false
})

export default contentPickerOptions
