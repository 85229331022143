import React, { useState } from "react"
import { arrayOf, number, shape, string } from "prop-types"

import useAblyChannel from "src/hooks/useAblyChannel"
import { TRANSCRIPT_STATUSES } from "src/resources/meetingPacks/constants"
import { CHANNELS } from "src/constants/ably"

import UploadForm from "./UploadForm"
import Header from "./Header"
import View from "./View"
import Pending from "./Pending"

const Transcript = ({ meetingPackId, initialTranscript }) => {
  const [status, setStatus] = useState(initialTranscript?.status)
  const [transcriptId, setTranscriptId] = useState(initialTranscript?.id)

  useAblyChannel(CHANNELS.meetingPacksTranscript, {
    onMessage: ({ data, name }) => {
      if (name === "update" && data.meeting_pack_id === meetingPackId) {
        setTranscriptId(data.id)
        setStatus(data.status)
      }
    }
  })

  const completed = status === TRANSCRIPT_STATUSES.complete
  const pending = status && status !== TRANSCRIPT_STATUSES.failed && !completed

  return (
    <>
      {!completed && <Header meetingPackId={meetingPackId} />}

      {completed && <View meetingPackId={meetingPackId} transcriptId={transcriptId} />}
      {pending && <Pending />}
      {!completed && !pending && (
        <UploadForm meetingPackId={meetingPackId} setStatus={setStatus} status={status} />
      )}
    </>
  )
}

Transcript.propTypes = {
  meetingPackId: number.isRequired,
  initialTranscript: shape({
    id: number.isRequired,
    status: string,
    content: string,
    utterances: arrayOf(
      shape({
        start: number,
        end: number,
        speaker: string,
        text: string.isRequired
      })
    )
  })
}

export default Transcript
