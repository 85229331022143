import React from "react"
import { styled } from "@linaria/react"
import { string } from "prop-types"

import { linkHoverColor, TEXT_COLORS } from "src/styles/colors"
import { formatTimestamp } from "src/helpers/datetime"
import { BREAKPOINTS } from "src/styles/sizes"

import { DRAWER_CONTENT_WIDTH } from "./constants"

const MetadataLink = ({ href, createdAt, authorsName, className }) => {
  const text = `${formatTimestamp(createdAt)} by ${authorsName}`
  return (
    <div className={className}>
      <a href={href} className="link">
        {text}
      </a>
    </div>
  )
}

MetadataLink.propTypes = {
  href: string.isRequired,
  createdAt: string.isRequired,
  authorsName: string.isRequired
}

export default styled(MetadataLink)`
  font-size: 0.85rem;
  max-width: ${DRAWER_CONTENT_WIDTH};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${TEXT_COLORS.muted};

  .link {
    color: ${TEXT_COLORS.muted};
    text-transform: capitalize;
  }

  &:hover {
    color: ${linkHoverColor} !important;
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    max-width: calc(100vw - 80px);
  }
`
