import React from "react"
import { styled } from "@linaria/react"
import { string } from "prop-types"
import classNames from "classnames"

import Icon from "src/styles/components/Icon"
import { showFlashMessage } from "src/helpers/flash"
import { BASE_COLORS } from "src/styles/colors"
import { BREAKPOINTS, FONT_SIZES, SPACES } from "src/styles/sizes"
import { copyPlainText } from "src/helpers/copyText"

const CopyLink = ({ className, url, labelText = "Copy link" }) => {
  const handleClick = async () => {
    await copyPlainText(url)
    showFlashMessage("success", "Link has been copied. Paste it anywhere to share.")
  }

  return (
    <button
      className={classNames(className, "text-small m-0 saved-offline")}
      onClick={handleClick}
    >
      <Icon type="link" />
      <span>{labelText}</span>
    </button>
  )
}

CopyLink.propTypes = {
  url: string.isRequired,
  labelText: string
}

export default styled(CopyLink)`
  padding: 0;
  border: none;
  background: none;
  outline: none;
  color: ${BASE_COLORS.darkViolet};
  font-size: ${FONT_SIZES.xSmall};

  &:focus {
    color: ${BASE_COLORS.darkViolet};
    outline: none;
  }

  &:hover {
    color: ${BASE_COLORS.gray};
  }

  span:not(.icon-link) {
    margin-left: ${SPACES.xxSmall};
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    span {
      display: none;
    }

    [class^="icon-"] {
      display: block;
    }
  }
`
