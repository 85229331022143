import { useCallback, useEffect, useState } from "react"
import { useApolloClient } from "@apollo/client"

import useStatsUpdates from "src/features/Sidebar/hooks/useStatsUpdates"
import statsQuery from "src/features/Sidebar/stats.gql"
import { getCurrentUserId } from "src/helpers/user"

const emptyStats = {
  unread: {},
  mentions: {},
  actions: {},
  unsigned: {}
}

const getUserStatsStorageKey = (userId) => `user-${userId}-stats`

const getUserStatsFromTheStorage = (userId) => {
  try {
    const savedStats = JSON.parse(localStorage.getItem(getUserStatsStorageKey(userId)))

    if (
      !(
        savedStats.unread &&
        savedStats.mentions &&
        savedStats.actions &&
        savedStats.unsigned
      )
    ) {
      return null
    }

    return savedStats
  } catch {
    return null
  }
}

export default function useUnreadStats() {
  const userId = getCurrentUserId()
  const savedStats = getUserStatsFromTheStorage(userId)

  const [stats, setStats] = useState(savedStats || emptyStats)
  const apolloClient = useApolloClient()

  const fetchStats = useCallback(() => {
    apolloClient
      .query({ query: statsQuery, fetchPolicy: "network-only" })
      .then(({ data }) => {
        if (data) {
          setStats({
            unread: data.unreadStats,
            mentions: data.mentionsStats,
            actions: data.actionsStats,
            unsigned: data.unsignedStats
          })
        }
      })
  }, [apolloClient])

  useStatsUpdates(setStats, fetchStats)

  const statsJson = JSON.stringify(stats)
  useEffect(
    () => localStorage.setItem(getUserStatsStorageKey(userId), statsJson),
    [userId, statsJson]
  )

  useEffect(fetchStats, [apolloClient])

  return stats
}
