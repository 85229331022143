import { arrayOf, bool, number, shape, string } from "prop-types"

export const messageType = shape({
  id: number.isRequired,
  body: string,
  starredBody: string,
  share: bool,
  edited: bool.isRequired,
  starred: bool,
  voting: bool.isRequired,
  mustRead: bool.isRequired,
  fromEmail: bool.isRequired,
  actionable: bool.isRequired,
  actionType: string,
  authorName: string,
  messagePath: string.isRequired,
  userMentioned: bool.isRequired,
  systemNotification: bool.isRequired,
  author: shape({}),
  attachedDocuments: arrayOf(
    shape({
      id: number.isRequired,
      filename: string.isRequired,
      boxFileId: string.isRequired
    })
  )
})
