import React from "react"
import { bool, shape, string } from "prop-types"

const Breadcrumbs = ({
  message: {
    threadParticipant,
    threadPath,
    messagePath,
    accountName,
    scheme,
    groupName,
    discussionTitle,
    chatWith
  },
  shared = false,
  withMessageLink = true,
  withDiscussionLink = true
}) => {
  return (
    <div className="starred-items">
      {threadParticipant && (
        <a className="breadcrumb-link" href={withMessageLink ? messagePath : null}>
          {shared ? "View original message" : "View message"}
        </a>
      )}
      <a
        className="starred-breadcrumbs"
        href={withDiscussionLink && threadParticipant ? threadPath : null}
        data-behavior="starred-item-secondary-action"
      >
        <span className="breadcrumbs-separator">in</span>
        {accountName && (
          <>
            <b>{accountName}</b>
            <span className="breadcrumbs-separator">{">"}</span>
          </>
        )}
        {scheme && (
          <>
            <b>{scheme.publicNameForUser}</b>
            <span className="breadcrumbs-separator">{">"}</span>
          </>
        )}
        {groupName && (
          <>
            <span>{groupName}</span>
            <span className="breadcrumbs-separator">{">"}</span>
          </>
        )}
        {discussionTitle || (
          <>
            <span>Chats</span>
            <span className="breadcrumbs-separator">{">"}</span>
            <span>{chatWith}</span>
          </>
        )}
      </a>
    </div>
  )
}

Breadcrumbs.propTypes = {
  message: shape({
    threadParticipant: bool,
    threadPath: string,
    chatWith: string,
    groupName: string,
    accountName: string,
    discussionTitle: string,
    scheme: shape({
      publicNameForUser: string.isRequired
    })
  }).isRequired,
  shared: bool,
  withMessageLink: bool,
  withDiscussionLink: bool
}

export default Breadcrumbs
