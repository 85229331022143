import { useEffect } from "react"

import { SIDEBAR_OPENED_EVENT } from "./constants"

const useSidebarOpened = (handler, deps = []) => {
  useEffect(() => {
    window.addEventListener(SIDEBAR_OPENED_EVENT, handler)

    return () => window.removeEventListener(SIDEBAR_OPENED_EVENT, handler)
  }, deps)
}

export default useSidebarOpened
