import * as Sentry from "@sentry/browser"

import { getCurrentUser } from "./user"

let heartBeatActivated = false

const sessionTimeoutPollFrequency = 60 // seconds

const logOut = ({ message, currentUser }) => {
  Sentry.captureMessage(message, {
    extra: { userId: currentUser.id }
  })
  window.location.reload()
}

const pollForSessionTimeout = () => {
  const currentUser = getCurrentUser()

  if (!currentUser) return
  if (!navigator.onLine) return

  setTimeout(pollForSessionTimeout, sessionTimeoutPollFrequency * 1000)

  if (Date.now() - window.heartBeat.lastActive < sessionTimeoutPollFrequency * 1000)
    return

  fetch("/check_session_timeout")
    .then((response) => response.json())
    .then((data) => {
      if (data <= 0) logOut({ message: "User session has finished", currentUser })
    })
    .catch((e) => {
      if (e.response?.status === 401) {
        logOut({ message: "Request of session returned 401 status", currentUser })
      } else {
        Sentry.captureException(e)
      }
    })
}

export class HeartBeat {
  constructor() {
    if (document.readyState !== "loading") {
      this.initHeartBeat()
    } else {
      document.addEventListener("DOMContentLoaded", () => {
        this.initHeartBeat()
      })
    }
  }

  initHeartBeat() {
    this.lastActive = new Date().valueOf()
    if (!heartBeatActivated) {
      ;["mousemove", "scroll", "click", "keydown"].forEach((activity) => {
        document.addEventListener(
          activity,
          (ev) => {
            this.lastActive = ev.timeStamp + performance.timing.navigationStart
          },
          false
        )
      })
      window.setTimeout(pollForSessionTimeout, sessionTimeoutPollFrequency * 1000)
      heartBeatActivated = true
    }
  }
}
