import React from "react"
import { styled } from "@linaria/react"
import { cx } from "@linaria/core"
import { arrayOf, func, number, shape, string } from "prop-types"

import { BREAKPOINTS, FONT_SIZES, messageBlockPadding, SPACES } from "src/styles/sizes"
import { BASE_COLORS } from "src/styles/colors"
import LinkButton from "src/styles/components/Button/Link"
import useIsOpened from "src/hooks/useIsOpened"
import { getCurrentUserId } from "src/helpers/user"

import SignedPeopleMessage from "./SignedPeopleMessage"
import SignatoriesModal from "./SignatoriesModal"
import CurrentUserSignatureStatus from "./CurrentUserSignatureStatus"

const SignatoriesContainer = styled.div`
  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
`

const SignatoriesWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  line-height: 1.44rem;
`

const SignatoriesPanel = ({ className, users, signedAt, onSignButtonClick }) => {
  const { isOpened, close, open } = useIsOpened()

  const currentUserId = getCurrentUserId()
  const currentUser = users.find(({ id }) => id === currentUserId)
  const shouldBeSignedByUser = currentUser && !currentUser.signedAt

  return (
    <div className={cx(className, shouldBeSignedByUser && "sign")}>
      <SignatoriesContainer>
        <SignatoriesWrapper>
          <SignedPeopleMessage users={users} signedAt={signedAt} />
          &nbsp;
          <LinkButton onClick={open} className="no-print">
            View details
          </LinkButton>
        </SignatoriesWrapper>
        <SignatoriesModal users={users} isOpened={isOpened} close={close} />
      </SignatoriesContainer>
      <CurrentUserSignatureStatus users={users} onSignButtonClick={onSignButtonClick} />
    </div>
  )
}

SignatoriesPanel.propTypes = {
  users: arrayOf(
    shape({
      id: number
    })
  ),
  signedAt: string,
  onSignButtonClick: func
}

export default styled(SignatoriesPanel)`
  border-top: 1px solid ${BASE_COLORS.beige};
  font-size: ${FONT_SIZES.small};
  margin: ${SPACES.xSmall} -${messageBlockPadding} -${messageBlockPadding} -${messageBlockPadding};
  padding: ${SPACES.xSmall} ${messageBlockPadding};
  display: flex;
  justify-content: space-between;

  &.sign {
    padding: ${SPACES.xxSmall} ${messageBlockPadding};
  }

  @media (max-width: ${BREAKPOINTS.xSmall}) {
    flex-direction: column;
    align-items: center;
  }
`
