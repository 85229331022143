import { styled } from "@linaria/react"

export const ModalHeader = styled.div`
  align-items: center;
  padding: 0.5rem 1rem;
  flex: 0 1 10%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
`

export const ModalTitle = styled.h5``

export const CloseButton = styled.button`
  cursor: pointer;
  padding: 1rem;
  outline: none;
  background-color: transparent;
  border: 0;
  color: #000;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  opacity: 0.5;
  margin: -1rem -1rem -1rem auto;
  &:hover {
    opacity: 0.75;
  }
  &:focus {
    outline: none;
    color: #000;
  }
`
