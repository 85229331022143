class @SendingMessagesComponent
  @MESSAGE_SENDING = 'Sending...'
  @MESSAGE_FAILED = 'Message failed to send. Please check your connection and try again.'

  constructor: ->
    @newMessageTemplate = $('#new-message-template')
    @sendingMessages = []

  setup: ->

  push: (message) =>
    @sendingMessages.push(message)

  hasSendingMessages: =>
    !!@sendingMessages.length

  sendingMessageFailed: =>
    $lastSendingMessage = @sendingMessages[@sendingMessages.length - 1]

    return unless $lastSendingMessage

    $messageError = $('<p>')
      .addClass('text-danger small mb-md-3')
      .text(this.constructor.MESSAGE_FAILED)
    $lastSendingMessage
      .addClass('message-block__sending_failed')
      .find('[data-behavior="message-timestamp"]')
      .replaceWith($messageError)

  replaceFirstSendingMessage: (messageHtml) =>
    $firstSendingMessage = @sendingMessages.shift()

    return unless $firstSendingMessage

    $firstSendingMessage.replaceWith(messageHtml)

  removeFailedSendingMessages: ->
    failedMessages = @sendingMessages.filter(($message) ->
      $message.hasClass('message-block__sending_failed')
    )

    @sendingMessages = @sendingMessages.filter(($message) ->
      !$message.hasClass('message-block__sending_failed')
    )

    failedMessages.forEach(($message) -> $message.remove())

  composeSendingMessage: (messageBody) ->
    messageHTML = @newMessageTemplate.html()
    messageHTML = messageHTML.replace('{body}', messageBody)

    $sendingMessage = findMessageBlock($.parseHTML(messageHTML))
    $sendingMessage
      .addClass('message-block__sending')
      .find('[data-behavior="message-timestamp"]')
      .text(this.constructor.MESSAGE_SENDING)
    $sendingMessage

  # private

  findMessageBlock = (nodes) ->
    $(
      nodes.find(
        (node) -> node.classList && node.classList.contains('message-block')
      )
    )
