import { styled } from "@linaria/react"
import FormControl from "react-bootstrap/FormControl"

export const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(51, 51, 51, 0.4);
  width: 100%;
  height: 100%;
`

export const ModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 300px;
  background-color: white;
  border-radius: 5px;
`
export const HeaderContainer = styled.div`
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e8;
`

export const Body = styled.div`
  padding: 20px;
  border-bottom: 1px solid #e8e8e8;
`

export const Footer = styled.div`
  padding: 10px 20px;
  display: flex;
  justify-content: center;
`

export const Input = styled(FormControl)`
  &:focus,
  &:focus-visible {
    border-color: #ced4da;
    outline: 0;
    box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.2);
  }
`
