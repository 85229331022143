import { useCallback, useEffect, useState } from "react"

import {
  clearOffline,
  isMeetingSavedOffline,
  saveOffline
} from "src/features/offline/helpers/meetingPacks"
import { getCurrentUserId } from "src/helpers/user"
import {
  triggerRemovedOffline,
  triggerSavedOffline
} from "src/features/offline/helpers/events"

export default function useSaveOffline({ meetingPackId }) {
  const [isSavedOffline, setIsSavedOffline] = useState(undefined)

  const saveMeetingOffline = useCallback(() => {
    saveOffline({ meetingPackId, userId: getCurrentUserId() })
    setIsSavedOffline(true)
    triggerSavedOffline({ meetingPackId })
  }, [meetingPackId])

  const clearMeetingCache = useCallback(() => {
    clearOffline({ meetingPackId })
    setIsSavedOffline(false)
    triggerRemovedOffline({ meetingPackId })
  }, [meetingPackId])

  useEffect(() => {
    isMeetingSavedOffline({ meetingPackId }).then((isSaved) => {
      setIsSavedOffline(isSaved)
    })
  }, [meetingPackId])

  return { isSavedOffline, saveMeetingOffline, clearMeetingCache, setIsSavedOffline }
}
