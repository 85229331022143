import { useState } from "react"

import documents from "src/api/meetingPacks/documents"
import { camelcaseKeys } from "src/helpers/object"

const useFilesUpload = ({ agendaItemId, appendDocument, appendToPosition }) => {
  const [selectedFiles, setSelectedFiles] = useState([])

  const setAllFilesAsUploading = () => {
    setSelectedFiles(
      selectedFiles.map((selectedFile) => ({
        ...selectedFile,
        isUploading: true
      }))
    )
  }

  const setFileAsNotUploading = (file) => {
    setSelectedFiles((actualSelectedFiles) => {
      return actualSelectedFiles.map((selectedFile) => ({
        ...selectedFile,
        isUploading:
          file.name === selectedFile.file.name ? false : selectedFile.isUploading
      }))
    })
  }

  const uploadFiles = () => {
    setAllFilesAsUploading()

    return Promise.all(
      selectedFiles.map(({ file }, index) =>
        documents
          .upload({ agendaItemId, file, position: appendToPosition + index })
          .then((response) => {
            const document = response?.data?.document
            const jwt = response?.data?.jwt

            if (!document) {
              throw Error(
                "Oh no, the document was not uploaded. Please try again or contact support."
              )
            }

            setFileAsNotUploading(file)

            appendDocument(camelcaseKeys({ ...document, jwt }))

            return response
          })
          .catch((error) => {
            setFileAsNotUploading(file)

            throw error
          })
      )
    )
  }

  const setSelectedFilesWithMeta = (files) => {
    setSelectedFiles(files.map((file) => ({ file, name: file.name, isUploading: false })))
  }

  return { selectedFiles, setSelectedFiles: setSelectedFilesWithMeta, uploadFiles }
}

export default useFilesUpload
