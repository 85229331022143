import React from "react"
import PropTypes from "prop-types"

import { withDefaultPrevented } from "src/helpers/events"

const ToggleButton = React.forwardRef(({ children, onClick, className }, ref) => (
  <div
    ref={ref}
    aria-hidden="true"
    onClick={withDefaultPrevented(onClick)}
    className={className}
  >
    {children}
  </div>
))

ToggleButton.propTypes = {
  children: PropTypes.element,
  onClick: PropTypes.func,
  className: PropTypes.string
}

export default ToggleButton
