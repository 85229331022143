export const DEFAULT_SCHEME_FILTER = {
  value: "all",
  label: "All Spaces",
  isDefault: true
}

export const SCHEME_MEMBERSHIP_ROLES = {
  guest: "guest",
  member: "member",
  admin: "admin"
}
