import React, { forwardRef } from "react"

import Icon from "src/styles/components/Icon"

const Toggle = (props, ref) => (
  <span ref={ref}>
    <Icon {...props} type="tridot" />
  </span>
)

export default forwardRef(Toggle)
