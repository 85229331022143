import { fullscreenElementClass, fullscreenClass } from "./constants"
// eslint-disable-next-line
import enterFullscreenIcon from "./icons/enterFullscreenIcon.svg?raw"
// eslint-disable-next-line
import exitFullscreenIcon from "./icons/exitFullscreenIcon.svg?raw"

export const getFullscreenElement = () =>
  document.querySelector(`.${fullscreenElementClass}`)

export const getIsFullscreen = () => !!document.querySelector(`.${fullscreenClass}`)

export const toggleFullscreen = () => {
  const fullscreenElement = getFullscreenElement()

  if (getIsFullscreen()) {
    fullscreenElement.classList.remove(fullscreenClass)
    return false
  }

  fullscreenElement.classList.add(fullscreenClass)
  return true
}

export const createFullscreenButton = () => {
  const fullscreenButton = document.createElement("div")
  fullscreenButton.className = "fullscreen-button--icon"

  if (getIsFullscreen()) {
    fullscreenButton.innerHTML = exitFullscreenIcon
  } else {
    fullscreenButton.innerHTML = enterFullscreenIcon
  }

  return fullscreenButton
}
