import { partition } from "lodash"

import * as storedSignaturesApi from "src/api/storedSignatures"

// TODO: update the file when bug with saved signatures list will be fixed
// Bug is when you have two or more saved signatures, it is not possible to delete or apply the second one
// What to update:
//   create -> should add new annotation on backend instead of replacement
//   instance.setStoredSignatures((signatures) => signatures.push(annotation))

const serialized = (signature) => JSON.parse(signature.serializedAnnotation)

const setup = async ({
  instance,
  storedSignatures,
  deleteStoredSignature,
  updateStoredSignatures
}) => {
  if (!instance) return

  if (storedSignatures.length) {
    const [attachmentSignaturesToLoad, loadedSignatures] = partition(
      storedSignatures,
      async (storedSignature) => {
        if (!storedSignature.withAttachment) return false

        const attachmentId = serialized(storedSignature).imageAttachmentId
        if (!attachmentId) return true

        // We should not upload attachment to PSPDFKit more than once but,
        // sometimes, it doesn't upload properly. In this case, we re-upload it
        const attachment = await instance.getAttachment(attachmentId)
        return !!attachment
      }
    )
    let loadedAttachmentSignatures = []

    if (attachmentSignaturesToLoad.length) {
      const blobs = await Promise.all(
        attachmentSignaturesToLoad.map(({ id }) =>
          fetch(`/api/stored_signatures/${id}/attachment`).then((res) => res.blob())
        )
      )

      // create an attachment for each blob
      const imageAttachmentIds = await Promise.all(blobs.map(instance.createAttachment))

      updateStoredSignatures({
        variables: {
          signatures: imageAttachmentIds.map((imageAttachmentId, i) => ({
            id: attachmentSignaturesToLoad[i].id,
            imageAttachmentId
          }))
        }
      })

      loadedAttachmentSignatures = imageAttachmentIds.map((imageAttachmentId, i) => ({
        ...serialized(attachmentSignaturesToLoad[i]),
        imageAttachmentId
      }))
    }

    // Construct annotations from serialized entries and call setStoredSignatures API
    const list = window.PSPDFKit.Immutable.List(
      loadedAttachmentSignatures
        .concat(loadedSignatures.map((signature) => serialized(signature)))
        .map((signature) => window.PSPDFKit.Annotations.fromSerializableObject(signature))
    )

    instance.setStoredSignatures(list)
  }

  instance.addEventListener("storedSignatures.create", async (annotation) => {
    const serializedAnnotation =
      window.PSPDFKit.Annotations.toSerializableObject(annotation)

    const data = { serializedAnnotation }

    if (annotation.imageAttachmentId) {
      const attachment = await instance.getAttachment(annotation.imageAttachmentId)
      data.attachment = attachment
    }

    storedSignaturesApi.create(data)

    instance.setStoredSignatures(() => window.PSPDFKit.Immutable.List([annotation]))
  })

  instance.addEventListener("storedSignatures.delete", (annotation) => {
    deleteStoredSignature({
      variables: {
        serializedAnnotation: JSON.stringify(
          window.PSPDFKit.Annotations.toSerializableObject(annotation)
        )
      }
    })

    instance.setStoredSignatures((signatures) =>
      signatures.filter((signature) => !signature.equals(annotation))
    )
  })

  return instance
}

export default setup
