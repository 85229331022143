import React from "react"

const SignInCard = ({ children }) => {
  return (
    <div className="container pb-3">
      <div className="flex-row align-vertically-center align-horizontally-center mt-5">
        <div className="col4of6">
          <div className="sign-in-container">{children}</div>
        </div>
      </div>
    </div>
  )
}

export default SignInCard
