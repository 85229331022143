import React, { useCallback, useState } from "react"
import { useMutation } from "@apollo/client"
import { useDispatch } from "react-redux"
import { Form } from "react-bootstrap"
import { bool, func, number, string } from "prop-types"

import ModalBody from "src/styles/components/Modal/Body"
import ModalTitle from "src/styles/components/Modal/Title"
import ModalHeader from "src/styles/components/Modal/Header"
import ModalFooter from "src/styles/components/Modal/Footer"
import { StyledModal } from "src/styles/components/Modal"
import { updateDocumentName } from "src/features/meetingPacks/AgendaItem/agendaItemSlice"
import { showCaughtErrorMessage } from "src/helpers/errors"
import { KEY_CODES } from "src/constants"

import renameDocumentMutation from "./renameDocument.gql"

const validateDocumentName = (documentName) => {
  if (!documentName) return "Filename should not be blank."
}

const RenameModal = ({ isOpened, close, name, extension, documentId }) => {
  const dispatch = useDispatch()
  const [renameDocument] = useMutation(renameDocumentMutation)
  const [isNameChanged, setIsNameChanged] = useState(false)
  const [documentName, setDocumentName] = useState(name)
  const filename = [documentName, extension].join(".")

  const invalidMessage = isNameChanged ? validateDocumentName(documentName) : false
  const isInvalid = !!invalidMessage
  const isSubmitDisabled = isNameChanged ? isInvalid : true

  const handleChange = (event) => {
    setIsNameChanged(true)
    setDocumentName(event.target.value.trim())
  }

  const handleClose = () => {
    close()
    setIsNameChanged(false)
  }

  const handleSubmit = useCallback(() => {
    close()
    setIsNameChanged(false)

    renameDocument({
      variables: { id: documentId, filename }
    })
      .then(() => {
        dispatch(updateDocumentName({ id: documentId, filename }))
      })
      .catch((error) => {
        showCaughtErrorMessage(error)
      })
  }, [filename, dispatch])

  const handleKeyDown = useCallback(
    (event) => {
      if (event.keyCode === KEY_CODES.enter) {
        event.preventDefault()

        if (!isSubmitDisabled) handleSubmit()
      }
    },
    [isSubmitDisabled, handleSubmit]
  )

  return (
    <StyledModal size="md" onHide={handleClose} show={isOpened}>
      <ModalHeader>
        <ModalTitle>Rename Document</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Form.Group controlId="documentName">
          <Form.Control
            placeholder="Type a new name for this document"
            autoComplete="off"
            isInvalid={isInvalid}
            defaultValue={name}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
          <Form.Control.Feedback type="invalid">{invalidMessage}</Form.Control.Feedback>
        </Form.Group>
        <ModalFooter noBorder>
          <button className="button is-secondary" onClick={handleClose}>
            Close
          </button>
          <button className="button" disabled={isSubmitDisabled} onClick={handleSubmit}>
            Rename
          </button>
        </ModalFooter>
      </ModalBody>
    </StyledModal>
  )
}

RenameModal.propTypes = {
  isOpened: bool.isRequired,
  close: func.isRequired,
  name: string.isRequired,
  extension: string.isRequired,
  documentId: number.isRequired
}

export default RenameModal
