import React, { memo } from "react"
import { bool, func, shape, string } from "prop-types"
import { isEqual } from "lodash"

import Card, { Mascot } from "src/styles/components/Card/Card"
import { getCurrentUserId } from "src/helpers/user"
import UserAvatar from "src/components/UsersContacts/UserAvatar"
import { Primary, Secondary, Title } from "src/styles/components/Card/Title"
import Auxiliary from "src/styles/components/Card/Auxiliary"
import { ROLES } from "src/features/meetingPacks/MeetingPack/constants"
import AccountRoleBadge from "src/components/AccountRoleBadge"
import Controls from "src/styles/components/Card/Controls"
import MembershipType from "src/styles/components/Card/MembershipType"
import Checkbox from "src/styles/components/Checkbox"

const AttendeeRow = ({ user, role, membershipType, checked, onChange }) => {
  const isCurrentUser = getCurrentUserId() === user.id

  return (
    <Card horizontal className="px-4 my-3" data-testid="user-card" data-id={user.id}>
      <Mascot className="mr-2">
        <UserAvatar user={user} />
      </Mascot>
      <Title>
        <Primary>
          {user.fullName} {isCurrentUser ? "(you)" : ""}
        </Primary>
        <Secondary>{user.jobTitle}</Secondary>
      </Title>
      <Auxiliary>
        {role !== ROLES.member && <AccountRoleBadge role={role} variant="small" />}

        <MembershipType membershipType={membershipType} />
      </Auxiliary>
      <Controls className="ml-3">
        <Checkbox
          id={`user-check-box-${user.id}`}
          checked={checked}
          onChange={() => onChange(user.id)}
        />
      </Controls>
    </Card>
  )
}

AttendeeRow.propTypes = {
  checked: bool.isRequired,
  onChange: func.isRequired,
  user: shape({}).isRequired,
  jobTitle: string,
  role: string,
  membershipType: string
}

export default memo(AttendeeRow, isEqual)
