import React from "react"
import { styled } from "@linaria/react"

import Icon from "./Icon"

const IconAI = ({ className }) => {
  return <Icon type="openai" className={className} />
}

export default styled(IconAI)`
  color: white !important;
  background: #000000;
  border: 1px solid #000000;
  border-radius: 4px;
  padding: 6px 3px;
  font-size: 10px !important;
`
