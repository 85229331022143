import { DEFAULT_SCHEME_FILTER } from "./constants"

export const getSchemeOption = (scheme) => {
  if (!scheme) return

  return {
    ...scheme,
    value: scheme.id,
    label: scheme.nameForUser,
    isScheme: true
  }
}

export const getSchemeOptionId = (option) => {
  if (option?.value === DEFAULT_SCHEME_FILTER.value) return

  return option?.value
}
