import React, { useRef, useState, useEffect, useLayoutEffect, useMemo } from "react"
import { number, string } from "prop-types"
import { useMutation } from "@apollo/client"
import { styled } from "@linaria/react"
import { useSelector } from "react-redux"
import classnames from "classnames"
import { sample } from "lodash"

import ContentLoader from "src/styles/components/ContentLoader"
import { useCommandResult } from "src/features/UniversalAi/store"
import useAblyChannel from "src/hooks/useAblyChannel"
import { CHANNELS } from "src/constants/ably"
import { getCurrentUserId } from "src/helpers/user"
import { scrollDown } from "src/helpers/document"
import { linkActiveColor } from "src/styles/colors"
import { TRANSCRIPT_SCROLL_EVENT } from "src/features/meetingPacks/MeetingPack/Transcript/View/constants"
import { getAgendaItems } from "src/features/meetingPacks/MeetingPack/selectors"
import { generateUniqId } from "src/helpers/string"
import useStreaming from "src/hooks/ai/useStreaming"

import { Base, Content, Container, Footer } from "../Base"
import scrollPositionHandler from "../scrollPositionHandler"

import generateAiMinutesMutation from "./generateAiMinutes.gql"
import OutdatedWarning from "./OutdatedWarning"

const GenerateDraftMinutes = ({ objectId, objectType, className }) => {
  const agendaItem = useSelector(getAgendaItems).find((item) => item.id === objectId)
  const contentRef = useRef()
  const { commandResult, setCommandResult } = useCommandResult()
  const [initialized, setInitialized] = useState(!!commandResult?.draftMinutes)
  const aiRequestId = useMemo(() => generateUniqId(), [])
  const channelName = useMemo(() => sample(CHANNELS.aiAnswer.draftMinutes), [])

  const { content, setContent, finished, setFinished } = useStreaming({
    onChangeContent: () => scrollDown(() => contentRef.current)
  })
  const [draftMinutesOutdated, setDraftMinutesOutdated] = useState(false)
  const [regenerating, setRegenerating] = useState(false)
  const [generateAiMinutes] = useMutation(generateAiMinutesMutation)
  const commandResultExists =
    commandResult?.draftMinutes !== null && commandResult?.draftMinutes !== undefined

  useEffect(() => {
    if (commandResultExists) return

    setContent(null)

    generateAiMinutes({
      variables: { agendaItemId: objectId, aiRequestId, channelName }
    })
  }, [objectId, commandResultExists])

  const regenerateDraftMinutes = () => {
    setContent("", { stream: false })
    setRegenerating(true)
    setDraftMinutesOutdated(false)
    generateAiMinutes({
      variables: { agendaItemId: objectId, aiRequestId, channelName, withCache: false }
    })
  }

  useAblyChannel(channelName, {
    onMessage: ({ data }) => {
      if (
        data.userId === getCurrentUserId() &&
        data.agendaItemId?.toString() === objectId?.toString() &&
        data.aiRequestId === aiRequestId
      ) {
        const result = data.minutes || data.error

        setContent(result, { stream: !data.withCache })
        setDraftMinutesOutdated(!!data.outdated)
        if (result) setRegenerating(false)
        if (data.finished) setFinished(true)
      }
    }
  })

  useAblyChannel(CHANNELS.meetingPacksTranscript, {
    onMessage: ({ data, name }) => {
      if (name === "outdated" && data.agenda_item_ids.includes(objectId)) {
        setDraftMinutesOutdated(true)
      }
    }
  })

  useLayoutEffect(
    () =>
      scrollPositionHandler({
        element: contentRef.current,
        key: `generateDraftMinutes/${objectType}/${objectId}`,
        restorePosition: initialized
      }),
    [!!contentRef.current, initialized, objectType, objectId]
  )

  const commandDraftMinutes = commandResult?.draftMinutes || content

  const scrollTo = (event) => {
    if (!event.target.dataset.source) return

    const source = event.target.dataset.source.split(", ")
    const scrollEvent = new CustomEvent(TRANSCRIPT_SCROLL_EVENT, { detail: { source } })
    window.dispatchEvent(scrollEvent)
  }

  useEffect(() => {
    if (!finished || !content) return

    setCommandResult({ draftMinutes: content })
    setInitialized(true)
  }, [finished, content])

  const commandDraftMinutesWithReferences = commandDraftMinutes?.replace(
    /\s?\[source: (.+?)\]/g,
    "<span class='icon-quote' data-source='$1'></span>"
  )

  return (
    <Base title={`Draft Minutes for <h6>${agendaItem.name}</h6>`} titleVersion="semiBold">
      <Container>
        <Content
          ref={contentRef}
          className={classnames(className, { draftMinutesOutdated })}
        >
          {(!commandDraftMinutes || regenerating) && <ContentLoader />}
          {draftMinutesOutdated && <OutdatedWarning onClick={regenerateDraftMinutes} />}
          {/* eslint-disable react/no-danger */}
          {commandDraftMinutes && !regenerating && (
            <div
              className="minutes-content"
              onClick={scrollTo}
              aria-hidden="true"
              dangerouslySetInnerHTML={{
                __html: commandDraftMinutesWithReferences
              }}
            />
          )}
          {/* eslint-enable react/no-danger */}
        </Content>
      </Container>
      <Footer
        loading={!initialized || regenerating}
        content={commandDraftMinutesWithReferences}
        contentRef={contentRef}
        buttonTitle="Copy Minutes"
      />
    </Base>
  )
}

GenerateDraftMinutes.propTypes = {
  objectId: number.isRequired,
  objectType: string.isRequired
}

export default styled(GenerateDraftMinutes)`
  position: relative;

  &.draftMinutesOutdated {
    margin-top: 40px;
  }

  ${OutdatedWarning} {
    position: absolute;
    top: -40px;
  }

  .icon-quote {
    font-size: 1rem;
    color: ${linkActiveColor};
    margin-left: 5px;
    cursor: pointer;
  }
`
