import React from "react"
import { number, func } from "prop-types"

import ItemsHeader from "./ItemsHeader"
import DocumentsList from "./DocumentsList"

const Documents = ({ appendDocument, agendaItemId, appendToPosition }) => {
  return (
    <div>
      <ItemsHeader
        agendaItemId={agendaItemId}
        appendDocument={appendDocument}
        appendToPosition={appendToPosition}
      />
      <DocumentsList />
    </div>
  )
}

Documents.propTypes = {
  agendaItemId: number.isRequired,
  appendToPosition: number.isRequired,
  appendDocument: func.isRequired
}

export default Documents
