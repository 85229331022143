import { styled } from "@linaria/react"

import { FONT_SIZES, SPACES } from "src/styles/sizes"

export const DatePickersContainer = styled.div`
  .flatpickr-input {
    padding: 0.375rem ${SPACES.xSmall};
    font-size: ${FONT_SIZES.small};
    width: 100%;
  }
`

export const DatePickerContainer = styled.div`
  position: relative;

  .datepicker-placeholder {
    cursor: pointer;
    position: absolute;
    left: ${SPACES.xSmall};
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
  }

  .active + .datepicker-placeholder {
    display: none;
  }
`
