import React from "react"
import { Modal } from "react-bootstrap"
import { arrayOf, bool, func, number, shape } from "prop-types"

import LinkButton from "src/styles/components/Button/Link"
import CardList from "src/styles/components/Card/List"
import useAgendaItemAttendeesChanges from "src/features/meetingPacks/MeetingPack/Attendees/List/useAgendaItemAttendeesChanges"

import { AgendaItem } from "../../types"
import AgendaItemName from "../AgendaItemName"

import UserCard from "./UserCard"

const ViewAttendeesModal = ({
  agendaItem,
  currentUserId,
  openContactDetailsModal,
  agendaItemAttendees = [],
  isOpened,
  openManageAttendees,
  onHide
}) => {
  const { joiningUsers } = useAgendaItemAttendeesChanges(agendaItem.id)

  const manageAttendeesTitle = agendaItem.agendaItemId
    ? "Manage Sub-item Attendees"
    : "Manage Item Attendees"

  const isUserAdded = (user) =>
    !!joiningUsers.find((joiningUser) => joiningUser.id === user.id)

  return (
    <Modal show={isOpened} onHide={onHide}>
      <Modal.Header closeButton>
        <div>
          <AgendaItemName agendaItem={agendaItem} />
          <Modal.Title as="h5" className="d-inline text-center">
            View Attendees
          </Modal.Title>
          <LinkButton
            onClick={openManageAttendees}
            className="d-block d-sm-inline ml-0 ml-sm-2"
          >
            {manageAttendeesTitle}
          </LinkButton>
        </div>
      </Modal.Header>
      <Modal.Body className="allow-scroll">
        <CardList>
          {agendaItemAttendees.map((attendee) => (
            <UserCard
              onClick={() => openContactDetailsModal(attendee)}
              key={attendee.id}
              user={attendee}
              isCurrentUser={attendee.id === currentUserId}
              deletedFromAccount={attendee.deletedFromAccount}
              added={isUserAdded(attendee)}
            />
          ))}

          {agendaItemAttendees.length === 0 && (
            <p className="mtl text-center">There are no attendees for this agenda item</p>
          )}
        </CardList>
      </Modal.Body>
    </Modal>
  )
}

ViewAttendeesModal.propTypes = {
  agendaItem: AgendaItem.isRequired,
  agendaItemAttendees: arrayOf(
    shape({
      id: number.isRequired
    })
  ).isRequired,
  openManageAttendees: func.isRequired,
  onHide: func.isRequired,
  currentUserId: number,
  openContactDetailsModal: func,
  isOpened: bool
}

export default ViewAttendeesModal
