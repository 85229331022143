import React from "react"
import { styled } from "@linaria/react"
import { capitalize } from "lodash"
import { oneOf, string } from "prop-types"

const Auxiliary = ({ className, role = null, status }) => {
  return (
    <div className={className}>
      <div className="role">{role}</div>
      <div className="status-wrapper">
        <div className={`card-status-label ${status}`}>
          <span>{capitalize(status)}</span>
        </div>
      </div>
    </div>
  )
}

Auxiliary.propTypes = {
  status: oneOf(["online", "offline"]).isRequired,
  role: string,
  className: string
}

export default styled(Auxiliary)`
  color: #a8abbb;
  flex: 0 0 auto;
  padding-left: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.75rem;

  .role {
    margin-right: 1rem;
  }

  .status-wrapper {
    text-align: center;
    width: 70px;
  }
`
