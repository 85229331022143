import React from "react"
import { bool, func, number } from "prop-types"

import { Container, Skeleton } from "./styles"
import GeneralActions from "./GeneralActions"
import SelectedFolderItemsActions from "./SelectedFolderItemsActions"

const Toolbar = ({
  folderItemsCount,
  selectedFolderItemsCount,
  loading,
  onNewFolder,
  onUploadFiles,
  onUploadFolders,
  onDelete,
  onDeselectAll,
  onMoveCopy,
  onDownload,
  onRename
}) => {
  if (loading) {
    return (
      <Container>
        <Skeleton />
      </Container>
    )
  }

  return (
    <Container>
      {selectedFolderItemsCount === 0 && (
        <GeneralActions
          folderItemsCount={folderItemsCount}
          onNewFolder={onNewFolder}
          onUploadFiles={onUploadFiles}
          onUploadFolders={onUploadFolders}
          onDownload={onDownload}
        />
      )}

      {selectedFolderItemsCount > 0 && (
        <SelectedFolderItemsActions
          count={selectedFolderItemsCount}
          onDelete={onDelete}
          onDeselectAll={onDeselectAll}
          onMoveCopy={onMoveCopy}
          onDownload={onDownload}
          onRename={onRename}
        />
      )}
    </Container>
  )
}

Toolbar.propTypes = {
  folderItemsCount: number.isRequired,
  selectedFolderItemsCount: number.isRequired,
  loading: bool.isRequired,
  onNewFolder: func.isRequired,
  onUploadFiles: func.isRequired,
  onUploadFolders: func.isRequired,
  onDelete: func.isRequired,
  onDeselectAll: func.isRequired,
  onMoveCopy: func.isRequired,
  onDownload: func.isRequired,
  onRename: func.isRequired
}

export default Toolbar
