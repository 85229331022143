import { useMutation } from "@apollo/client"
import { pick } from "lodash"

import renameFolderItemMutation from "./renameFolderItemMutation.gql"

const useRenameFolderItem = () => {
  const [mutate, { loading }] = useMutation(renameFolderItemMutation, {
    refetchQueries: ["GroupFolderItems"]
  })

  const renameFolderItem = ({ groupId, folderItem, name }) => {
    return mutate({
      variables: { groupId, name, folderItem: pick(folderItem, "id", "type") },
      awaitRefetchQueries: true
    }).then((response) => response.data?.renameGroupFolderItem || {})
  }

  return { renameFolderItem, loading }
}

export default useRenameFolderItem
