import React from "react"
import { Modal } from "react-bootstrap"
import { styled } from "@linaria/react"
import { string, func, instanceOf, oneOf, number, oneOfType } from "prop-types"

import { BREAKPOINTS } from "src/styles/sizes"
import { ACTION_TYPES } from "src/features/Messages/ActionDetails/constants"

import ButtonGroup from "./ButtonGroup"
import DateConfirmation from "./DateConfirmation"
import InstantCloseConfirmation from "./InstantCloseConfirmation"
import NumberConfirmation from "./NumberConfirmation"

const TEXT_BY_MODAL = {
  date: DateConfirmation,
  now: InstantCloseConfirmation,
  number: NumberConfirmation
}

const ConfirmationBody = ({
  modalType,
  actionType,
  value = "",
  className,
  onConfirm,
  onCancel
}) => {
  const TextComponent = TEXT_BY_MODAL[modalType]

  const onSubmit = () => onConfirm(value instanceof Date ? value.toJSON() : String(value))

  return (
    <>
      <Modal.Body>
        <div data-testid="confirmation-text" className={className}>
          <p>
            <TextComponent actionType={actionType} value={value} />
          </p>
          <p className="text-danger">
            <strong>This cannot be undone. Are you sure you wish to continue?</strong>
          </p>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <ButtonGroup onConfirm={onSubmit} onCancel={onCancel} />
      </Modal.Footer>
    </>
  )
}

ConfirmationBody.propTypes = {
  value: oneOfType([string, number, instanceOf(Date)]),
  actionType: oneOf(Object.keys(ACTION_TYPES)).isRequired,
  modalType: oneOf(Object.keys(TEXT_BY_MODAL)).isRequired,
  className: string.isRequired,
  onConfirm: func.isRequired,
  onCancel: func.isRequired
}

export default styled(ConfirmationBody)`
  padding: 20px;

  @media (max-width: calc(${BREAKPOINTS.xSmall} - 1px)) {
    padding: 0px;
  }
`
