@$formattedSumMln = (sum) ->
  millions = Math.round(sum / 1000000)
  $formattedSum(millions)

@$formattedSum = (sum) ->
  formattedSum = sum.toLocaleString(
    'en',
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  )

  "#{formattedSum}m"

@$percent = (number) ->
  "#{number}%"

@$bold = (text, style) ->
  "<b>#{text}</b>"

@$colored = (text, color) ->
  "<span style=\"color: #{color}\">#{text}</span>"
