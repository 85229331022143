import React from "react"
import { func, number } from "prop-types"

import UploadButtonComponent from "./UploadButton"
import useFilesUpload from "./useFilesUpload"
import useBoxFilesUpload from "./useBoxFilesUpload"

const UploadButton = ({ agendaItemId, appendDocument, appendToPosition }) => {
  const { uploadFiles, selectedFiles, setSelectedFiles } = useFilesUpload({
    agendaItemId,
    appendDocument,
    appendToPosition
  })
  const { uploadBoxFiles } = useBoxFilesUpload({
    agendaItemId,
    appendDocument,
    appendToPosition
  })

  return (
    <UploadButtonComponent
      uploadFiles={uploadFiles}
      uploadBoxFiles={uploadBoxFiles}
      selectedFiles={selectedFiles}
      setSelectedFiles={setSelectedFiles}
    />
  )
}

UploadButton.propTypes = {
  agendaItemId: number.isRequired,
  appendToPosition: number.isRequired,
  appendDocument: func.isRequired
}

export default UploadButton
