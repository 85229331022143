import React, { useContext, useEffect, useState } from "react"
import { arrayOf, string, shape, bool } from "prop-types"
import { styled } from "@linaria/react"
import { useMutation } from "@apollo/client"
import { partition } from "lodash"
import { Row } from "react-bootstrap"
import * as Sentry from "@sentry/browser"

import { FONT_SIZES, SPACES } from "src/styles/sizes"
import PreviewLink from "src/components/DocumentPreviewLink"
import RecordContext from "src/features/AirtableApp/Form/RecordContext"
import TwoCirclesLoader from "src/styles/components/TwoCirclesLoader"
import { CHANNELS } from "src/constants/ably"
import useAblyChannel from "src/hooks/useAblyChannel"
import { showFlashMessage } from "src/helpers/flash"
import FieldLabel from "src/features/AirtableApp/Form/FieldLabel"
import { airtableFieldType } from "src/resources/accounts/airtablePropTypes"

import fetchOrUploadAttachmentsMutation from "./fetchOrUploadAppAttachments.gql"

const MultipleAttachmentsField = ({ field, invalid, value, className }) => {
  const { pageId, recordId } = useContext(RecordContext)
  const [fetchOrUploadAttachments] = useMutation(fetchOrUploadAttachmentsMutation)
  const [attachments, setAttachments] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadingAttachmentsCount, setLoadingAttachmentsCount] = useState(0)

  useEffect(() => {
    fetchOrUploadAttachments({
      variables: {
        pageId,
        airtableRecordId: recordId,
        airtableFieldId: field.id,
        attachments: (value || []).map((attachment) => ({
          uid: attachment.id,
          filename: attachment.filename,
          url: attachment.url,
          size: attachment.size
        }))
      }
    })
      .then(({ data: result = {} }) => {
        setAttachments(result.fetchOrUploadAppAttachments?.attachments || [])
        setLoadingAttachmentsCount(result.fetchOrUploadAppAttachments?.loadingCount)
        setLoading(false)
      })
      .catch((e) => {
        Sentry.captureException(e)
        setLoading(false)
      })
  }, [])

  useAblyChannel(CHANNELS.groups.apps, {
    onMessage: ({ data }) => {
      const { airtableRecordId, attachment } = data
      if (airtableRecordId !== recordId) return

      setAttachments((prevAttachments) => [...prevAttachments, attachment])
      setLoadingAttachmentsCount((prevLoadingCount) => prevLoadingCount - 1)
    }
  })

  const [attachmentsWithPreview, attachmentsWithoutPreview] = partition(
    attachments,
    "boxFileId"
  )

  const showTooBigFlashMessage = () =>
    showFlashMessage("warning", "Sorry, file is too big to preview")

  return (
    <div className={className}>
      <FieldLabel field={field} invalid={invalid} />

      <div className="attachmentsList">
        {!attachments.length &&
          !loading &&
          loadingAttachmentsCount === 0 &&
          "No documents"}

        <Row noGutters md={2}>
          {attachmentsWithPreview.map((attachment) => (
            <PreviewLink
              key={attachment.id}
              document={attachment}
              collection={attachmentsWithPreview}
            />
          ))}
          {attachmentsWithoutPreview.map((attachment) => (
            <PreviewLink
              key={attachment.id}
              document={attachment}
              previewable={false}
              onClick={showTooBigFlashMessage}
            />
          ))}
        </Row>
      </div>

      {(loading || loadingAttachmentsCount > 0) && <TwoCirclesLoader />}
    </div>
  )
}

MultipleAttachmentsField.propTypes = {
  field: airtableFieldType.isRequired,
  value: arrayOf(
    shape({
      id: string.isRequired,
      filename: string.isRequired,
      type: string.isRequired,
      url: string.isRequired
    })
  ),
  invalid: bool
}

export default styled(MultipleAttachmentsField)`
  .attachmentsList {
    font-size: ${FONT_SIZES.small};
    margin-left: ${SPACES.xSmall};
  }

  ${TwoCirclesLoader} {
    position: relative;
  }
`
