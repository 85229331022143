import { createSlice } from "@reduxjs/toolkit"
import { orderBy } from "lodash"

import documents from "src/api/meetingPacks/documents"
import { showFlashMessage } from "src/helpers/flash"
import { showCaughtErrorMessage } from "src/helpers/errors"

const agendaItemSlice = createSlice({
  name: "agendaItem",
  initialState: {
    id: 0,
    documents: []
  },
  reducers: {
    setInitialState(state, { payload }) {
      state.id = payload.id
      state.documents = payload.documents
    },
    setDocuments(state, { payload }) {
      state.documents = payload.documents
    },
    appendDocument(state, { payload }) {
      state.documents.push(payload)
      state.documents = orderBy(state.documents, ["position"], ["asc"])
    },
    updateDocumentName(state, { payload: { id, filename } }) {
      const updatingDocument = state.documents.find((document) => document.id === id)
      if (updatingDocument) {
        updatingDocument.filename = filename
      }
    },
    setDocumentIsDeleting(state, { payload: { id, isDeleting = true } }) {
      const deletingDocument = state.documents.find((document) => document.id === id)
      if (deletingDocument) {
        deletingDocument.isDeleting = isDeleting
      }
    },
    removeDocument(state, { payload: { id } }) {
      const documentIndex = state.documents.findIndex((document) => document.id === id)
      if (documentIndex !== -1) {
        state.documents.splice(documentIndex, 1)
      }
    }
  }
})

export const {
  setInitialState,
  setDocuments,
  appendDocument,
  updateDocumentName,
  setDocumentIsDeleting,
  removeDocument
} = agendaItemSlice.actions

export default agendaItemSlice.reducer

export const setInitialStateFromProps = (props, dispatch) => {
  dispatch(setInitialState(props))
}

export const deleteDocument =
  ({ id, confirmationMessage }) =>
  (dispatch) => {
    if (window.confirm(confirmationMessage)) {
      dispatch(setDocumentIsDeleting({ id }))

      return documents
        .destroy({ documentId: id })
        .then(() => dispatch(removeDocument({ id })))
        .catch((error) => {
          dispatch(setDocumentIsDeleting({ id, isDeleting: false }))
          showCaughtErrorMessage(error)
        })
    }
  }

export const updatePosition =
  ({ newPosition, nextDocuments }) =>
  (dispatch, getState) => {
    const prevDocuments = getState().agendaItem.documents

    dispatch(setDocuments({ documents: nextDocuments }))

    return documents
      .updatePosition({
        documentId: newPosition.id,
        position: newPosition.position
      })
      .catch(() => {
        dispatch(setDocuments({ documents: prevDocuments }))
        showFlashMessage("danger", "Failed to change document's position.")
      })
  }
