import { saveAs } from "file-saver"

import { isPWA, isIOS } from "src/helpers/browser"

const useDownloadFileLink = ({ href, fileName, onClick = () => {} }) => {
  const saveUsingBlob = isPWA() && isIOS()

  const handleClick = async (e) => {
    if (!saveUsingBlob) return onClick(e)

    const downloadFile = await fetch(href).then((response) => response.blob())
    saveAs(downloadFile, fileName)
  }

  return { href: saveUsingBlob ? null : href, handleClick }
}

export default useDownloadFileLink
