class @UsersIndexHandler
  constructor: ->
    @userTable = $be('user-management-table')
    @schemeId = window.schemeId

    @addSchemeUsersComponent = new AddSchemeUsersComponent()

    @schemeMembershipService = new SchemeMembershipService()
    @editUserInfoModal = $be('edit-user-info-modal')
    @filterInputComponent = new FilterInputComponent(
      input:        'scheme-member-filter',
      items:        'user-management-table-row',
      emptyMessage: 'scheme-members-filter-empty'
    )

  setup: =>
    @addSchemeUsersComponent.setup()
    @filterInputComponent.setup()

    window.addEventListener('manageGroupClicked', @manageGroupClicked)

  teardown: =>
    window.removeEventListener('manageGroupClicked', @manageGroupClicked)

  onUserGroupMembershipUpdateSuccess: =>
    @editUserInfoModal.modal('hide')
    $showFlashMessage('success', 'User groups updated.')

  manageGroupClicked: (event) =>
    # TODO: move to React
    return if !event.detail.editSchemeUserPath

    $.get(event.detail.editSchemeUserPath).
      done((response) =>
        @editUserInfoModal.html(response.edit_form)
        @editUserInfoModal.modal('show')
        @bindGroupAccessNotificationRelation()
      ).
      fail(->
        $showFlashMessage('danger', 'Failed to edit user info.')
      )

  bindGroupAccessNotificationRelation: () =>
    $be('select-all-groups').click((e) =>
      e.preventDefault()
      $(e.target)
        .parents('table')
        .find('.access-column input[type="checkbox"]')
        .each((_, el) => el.checked = true)
    )

    $be('group-checkbox').on('change', (e) ->
      checkbox = e.target
      groupId = e.target.dataset.id
      notificationCheckbox = document
        .getElementById("group-notification-checkbox-#{groupId}")

      if checkbox.checked
        notificationCheckbox.disabled = false
      else
        notificationCheckbox.disabled = true
        notificationCheckbox.checked = false
    )
