class @DiscussionListComponent
  constructor: (listContainer, options) ->
    @afterUpdate = options.afterUpdate

    @listContainer = listContainer
    @listFetchPath = listContainer.data('fetch-path')
    @listSourceType = listContainer.data('source-type')
    @listSourceIds = listContainer.data('source-ids')

    @updatesChannel = AblyRealtimeClient.channels.get(
      "discussions:updates"
    )
    @discussionsChannel = AblyRealtimeClient.channels.get(
      "groups:discussions"
    )
    @groupMembershipChannel = AblyRealtimeClient.channels.get(
      "groups:membership"
    )

    @channelData = [
      {
        channel: @updatesChannel,
        subscriber: @handleUpdatesChannelMessage
      },
      {
        channel: @discussionsChannel,
        subscriber: @handleDiscussionsChannelMessage
      }
    ]

    if options.refreshOnGroupMembershipChange
      @channelData.push(
        {
          channel: @groupMembershipChannel,
          subscriber: @handleGroupMembershipChange
        }
      )

  setup: =>
    $setupChannelSubscriptions(@channelData)
    updateDiscussionsTimestamps()

    if $isTouchDevice()
      $('.card-link').show()

  teardown: =>
    $teardownChannelSubscriptions(@channelData)

  handleUpdatesChannelMessage: (channelMessage) =>
    return if channelMessage.data.skipUpdate ||
      @listSourceType is not 'group' ||
      !(channelMessage.data.group.id in @listSourceIds)
    $.getScript @listFetchPath

  handleDiscussionsChannelMessage: (channelMessage) =>
    return unless @listFetchPath && @listUpdateNeeded(channelMessage)
    $.getScript @listFetchPath

  handleGroupMembershipChange: (channelMessage) =>
    if $currentUserId() in channelMessage.data.affectedUserIds
      $.getScript @listFetchPath

  update: (listContent) =>
    @listContainer.html(listContent)
    updateDiscussionsTimestamps()
    @afterUpdate() if @afterUpdate

  updateDiscussionsTimestamps = () ->
    for el in $be('last-message-timestamp')
      $setTimestampContent($(el))

  listUpdateNeeded: (channelMessage) =>
    (@listSourceType is 'scheme' && channelMessage.data.schemeId in @listSourceIds) ||
      (@listSourceType is 'group' && channelMessage.data.groupId in @listSourceIds)

