import React, { useState, useEffect } from "react"
import { bool, func } from "prop-types"
import { Formik } from "formik"
import * as Yup from "yup"
import { styled } from "@linaria/react"
import { Button, Form, InputGroup } from "react-bootstrap"
import { uniqBy } from "lodash"

import ModalHeader from "src/styles/components/Modal/Header"
import ModalFooter from "src/styles/components/Modal/Footer"
import { StyledModal } from "src/styles/components/Modal"
import { airtableSectionType } from "src/resources/accounts/airtablePropTypes"
import FiltersList from "src/features/ManageAccount/AirtableApps/EditPage/FiltersList"
import { FONT_WEIGHTS } from "src/styles/sizes"
import FieldsList from "src/features/ManageAccount/AirtableApps/EditPage/FieldsList"
import FieldSearchableSelect from "src/features/ManageAccount/AirtableApps/EditPage/FieldSearchableSelect"
import { BASE_COLORS } from "src/styles/colors"
import Checkbox from "src/styles/components/Checkbox"
import { generateUniqId } from "src/helpers/string"

const SectionFormSchema = Yup.object().shape({
  name: Yup.string().required("Name can't be blank")
})

const EditModal = ({ close, isOpened, section, setSections, className }) => {
  const handleChangeFilters = (values, setFieldValue) => {
    return (setFiltersFunc) => setFieldValue("filters", setFiltersFunc(values.filters))
  }

  const [selectedFieldIds, setSelectedFieldIds] = useState([])
  const initiallySelectedFieldIds = section?.fields?.map((field) => field.id) || []

  useEffect(() => {
    setSelectedFieldIds(initiallySelectedFieldIds)
  }, [section?.fields])

  const removeSection = () =>
    setSections((prevSections) => prevSections.filter((s) => s.id !== section.id))

  const createSection = (values) =>
    setSections((prevSections) => [
      ...prevSections,
      {
        ...values,
        fields: selectedFieldIds.map((id) => ({ id })),
        id: generateUniqId()
      }
    ])

  const updateSection = (values) =>
    setSections((prevSections) =>
      prevSections.map((s) => {
        if (s.id !== values.id) return s

        const newFields = uniqBy(
          [
            ...s.fields.filter((field) => selectedFieldIds.includes(field.id)),
            ...selectedFieldIds.map((id) => ({ id }))
          ],
          "id"
        )
        return { ...values, fields: newFields }
      })
    )

  const saveAndClose = (values) => {
    if (section) updateSection(values)
    else createSection(values)

    close()
  }

  const removeWithConfirmation = () => {
    if (window.confirm("Are you sure you want to delete this section?")) {
      removeSection()
      close()
    }
  }

  return (
    <StyledModal
      size="lg"
      onHide={close}
      show={isOpened}
      className={className}
      backdrop="static"
      keyboard={false}
    >
      <Formik
        initialValues={
          section || {
            name: "",
            filters: []
          }
        }
        validationSchema={SectionFormSchema}
        onSubmit={saveAndClose}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue
        }) => (
          <>
            <ModalHeader className="d-flex justify-content-between">
              <h5 className="flex-grow-1">{section ? "Edit Section" : "New Section"}</h5>
            </ModalHeader>

            <div className="fluid-container">
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="name">
                  <Form.Label>
                    <h5>Name</h5>
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      value={values.name}
                      placeholder="e.g. Compliance"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={errors.name && touched.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Form.Group controlId="filters">
                  <Form.Label>
                    <h6>Filter section based on conditions</h6>
                  </Form.Label>
                  <FiltersList
                    filters={values.filters}
                    setFilters={handleChangeFilters(values, setFieldValue)}
                    onlyPlainTextFields
                    extendedFiltersList
                  />
                </Form.Group>

                <Form.Group controlId="statusFieldId" className="mt-4">
                  <Form.Label>
                    <h5>Section Status</h5>
                    {!values.showQuestionsCount && (
                      <small>Select a field to be displayed on the section sidebar</small>
                    )}
                  </Form.Label>
                  {!values.showQuestionsCount && (
                    <FieldSearchableSelect
                      value={values.statusFieldId}
                      onChange={(option) => setFieldValue("statusFieldId", option.value)}
                      containerClassName="btn-block"
                    />
                  )}
                  <Checkbox
                    id="showQuestionsCount"
                    checked={values.showQuestionsCount || false}
                    className="mt-2"
                    onChange={(e) =>
                      setFieldValue("showQuestionsCount", e.target.checked)
                    }
                  >
                    <span className="pl-2">Show number of questions answered</span>
                  </Checkbox>
                </Form.Group>

                <Form.Group controlId="fields" className="mt-4">
                  <Form.Label>
                    <h5>Section Fields</h5>
                    <small>Select which fields you wish to show in this section</small>
                  </Form.Label>
                  <Checkbox
                    id="automaticNumbering"
                    checked={values.automaticNumbering || false}
                    className="mt-2"
                    onChange={(e) =>
                      setFieldValue("automaticNumbering", e.target.checked)
                    }
                  >
                    <span className="pl-2">Automatic numbering</span>
                  </Checkbox>
                  <Checkbox
                    id="readOnly"
                    checked={values.readOnly || false}
                    className="mt-2"
                    onChange={(e) => setFieldValue("readOnly", e.target.checked)}
                  >
                    <span className="pl-2">Read only</span>
                  </Checkbox>
                  <FieldsList
                    selectedFieldIds={selectedFieldIds}
                    setSelectedFieldIds={setSelectedFieldIds}
                    scrollableFieldsList={false}
                  />
                </Form.Group>
              </Form>
            </div>

            <ModalFooter className="d-flex">
              {section && (
                <div className="flex-grow-1">
                  <Button variant="outline-danger mtm" onClick={removeWithConfirmation}>
                    Delete
                  </Button>
                </div>
              )}
              <div className="form-main-controls actions mtm">
                <button
                  type="submit"
                  className="button"
                  onClick={() => handleSubmit(values)}
                >
                  Apply
                </button>
                <button className="button is-secondary" onClick={close}>
                  Cancel
                </button>
              </div>
            </ModalFooter>
          </>
        )}
      </Formik>
    </StyledModal>
  )
}

EditModal.propTypes = {
  close: func.isRequired,
  isOpened: bool.isRequired,
  section: airtableSectionType,
  setSections: func.isRequired
}

export default styled(EditModal)`
  .modal-content {
    height: 100%;
  }

  .modal-header,
  .fluid-container {
    padding: 1rem 1.5rem;
  }

  label h5 {
    font-weight: ${FONT_WEIGHTS.bold};
    font-size: 1.1rem;
  }

  .fluid-container {
    height: auto;
  }

  .btn.btn-outline-danger {
    border-color: ${BASE_COLORS.red};

    &:hover,
    &:focus {
      background-color: ${BASE_COLORS.red};
    }
  }
`
