import React, { memo } from "react"
import { Col } from "react-bootstrap"
import { arrayOf, shape, number, string, bool } from "prop-types"
import { isEqual, startCase } from "lodash"
import classNames from "classnames"

import useSaveOffline from "src/features/offline/hooks/useSaveOffline"
import TextWithTooltip from "src/styles/components/TextWithTooltip"
import PublicationBadge from "src/components/PublicationBadge"
import Tag from "src/styles/components/Tag"
import { formatDateTime } from "src/helpers/datetime"
import { PUBLICATION_STATUSES } from "src/resources/meetingPacks/constants"
import isOfflineEnabled from "src/features/offline/isOfflineEnabled"
import { canStoreMeetingPack } from "src/features/offline/helpers/meetingPacks"
import { isUserExportEnabled } from "src/helpers/meetingPacks"
import { GUEST_ROLES } from "src/features/meetingPacks/MeetingPack/constants"

import {
  UnreadItemsCount,
  MeetingDetails,
  MeetingCard,
  CardDivider,
  MeetingName,
  DetailsCol,
  DetailsRow,
  Attendance,
  SpaceName,
  CardRow,
  Name
} from "./styles"
import MeetingActions from "./MeetingActions"
import SyncStatus from "./SyncStatus"

const Meeting = ({ meeting }) => {
  const { isSavedOffline, saveMeetingOffline, clearMeetingCache } = useSaveOffline({
    meetingPackId: meeting.id
  })

  const showOfflineActions =
    isOfflineEnabled() && (canStoreMeetingPack(meeting) || isSavedOffline)
  const showSyncStatus = isOfflineEnabled() && isSavedOffline
  const showPublicationStatus =
    meeting.publicationStatus !== PUBLICATION_STATUSES.published
  const startDate = formatDateTime({
    datetime: meeting.startDate,
    timeZone: meeting.timeZone
  })
  const membershipType = startCase(meeting.membership?.membershipType)
  const isExportAllowed = isUserExportEnabled({
    meetingPack: meeting,
    isCurrentUserGuest:
      !meeting.membership?.role || GUEST_ROLES.includes(meeting.membership?.role)
  })

  return (
    <a
      href={`/meeting_packs/${meeting.id}`}
      className={classNames({ "saved-offline": isSavedOffline, "d-block": true })}
    >
      <MeetingCard body>
        {showSyncStatus && <SyncStatus meetingPackId={meeting.id} />}

        {!!meeting.unreadItemsCount && (
          <UnreadItemsCount
            data-behavior="unread-items-counter"
            data-id={meeting.id}
            pill={false}
          >
            {meeting.unreadItemsCount}
          </UnreadItemsCount>
        )}
        <CardRow>
          <Col xs={12} md={7}>
            <SpaceName>
              <div>
                {meeting.account?.name}
                {!!meeting.tags.length && (
                  <Tag className="mhx" color={meeting.tags[0].colorId}>
                    {meeting.tags[0].name}
                  </Tag>
                )}
              </div>
              {showPublicationStatus && (
                <PublicationBadge
                  publicationStatus={meeting.publicationBadge}
                  meetingPackId={meeting.id}
                />
              )}
            </SpaceName>
            <MeetingName>
              <Name>
                <TextWithTooltip text={meeting.name} maxLength={100} />
              </Name>
              <Attendance>{membershipType}</Attendance>
            </MeetingName>
          </Col>
          <CardDivider />
          <MeetingDetails xs={12} md={5}>
            <DetailsRow>
              <DetailsCol>
                <TextWithTooltip text={startDate} maxLength={0} />
              </DetailsCol>
            </DetailsRow>
            <DetailsRow>
              <DetailsCol xs={6} md={12}>
                <TextWithTooltip text={meeting.location} />
              </DetailsCol>
              <DetailsCol xs={6}>
                <Attendance>{membershipType}</Attendance>
              </DetailsCol>
            </DetailsRow>
          </MeetingDetails>
        </CardRow>
        {(showOfflineActions || isExportAllowed) && (
          <MeetingActions
            meetingPackId={meeting.id}
            isSavedOffline={isSavedOffline}
            saveMeetingOffline={saveMeetingOffline}
            clearMeetingCache={clearMeetingCache}
            showOfflineActions={showOfflineActions}
            isExportAllowed={isExportAllowed}
          />
        )}
      </MeetingCard>
    </a>
  )
}

Meeting.propTypes = {
  meeting: shape({
    id: number.isRequired,
    name: string.isRequired,
    publicationStatus: string.isRequired,
    startDate: string.isRequired,
    timeZone: string,
    location: string,
    unreadItemsCount: number.isRequired,
    attendeesAllowedToExport: bool,
    account: shape({
      name: string.isRequired
    }).isRequired,
    membership: shape({
      membershipType: string.isRequired,
      role: string.isRequired
    }).isRequired,
    tags: arrayOf(
      shape({
        name: string.isRequired,
        colorId: number.isRequired
      })
    ).isRequired
  }).isRequired
}

export default memo(Meeting, isEqual)
