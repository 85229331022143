import React, { useEffect, useState } from "react"
import classNames from "classnames"
import { useDispatch } from "react-redux"
import { Card } from "react-bootstrap"

import Filters from "src/features/Home/Messages/Filters"
import FiltersToggle from "src/components/FiltersToggle"
import useMediaQuery from "src/hooks/useMediaQuery"
import useToggle from "src/hooks/useToggle"
import { fetchStarredItemsAccounts } from "src/resources/accounts/thunks"
import { fetchStarredItemsSchemes } from "src/resources/schemes/thunks"
import { getActionType } from "src/resources/messages/helpers"
import { getAccountOptionId } from "src/resources/accounts/helpers"
import { getSchemeOptionId } from "src/resources/schemes/helpers"
import { ContentContainer } from "src/styles/components/ContentComponents"
import { DEFAULT_ACCOUNT_FILTER } from "src/resources/accounts/constants"
import { DEFAULT_SCHEME_FILTER } from "src/resources/schemes/constants"
import { BREAKPOINTS } from "src/styles/sizes"
import {
  DEFAULT_ACTION_TYPE_FILTER,
  STARRED_ACTION_TYPE_OPTIONS
} from "src/resources/messages/constants"
import { configureStoredFilters } from "src/helpers/filters"
import AttachedDocumentPreview from "src/features/groups/discussions/AttachedDocumentPreview"

import StarredItemsList from "./StarredItemsList"

const FILTERS_STORAGE_KEY = "starredItemsFilters"

const StarredItems = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchStarredItemsAccounts())
    dispatch(fetchStarredItemsSchemes())
  }, [dispatch])

  const isMobileScreen = useMediaQuery(`(max-width: calc(${BREAKPOINTS.small} - 1px))`)

  const { storedFilters, storeFilters } = configureStoredFilters(FILTERS_STORAGE_KEY, {
    actionTypeFilter: DEFAULT_ACTION_TYPE_FILTER,
    accountFilter: DEFAULT_ACCOUNT_FILTER,
    schemeFilter: DEFAULT_SCHEME_FILTER
  })

  const [filters, setFilters] = useState(storedFilters)
  const [showFilters, toggleShowFilters] = useToggle("FiltersToggle", true)

  const updateFilters = (newFilters) => {
    storeFilters(newFilters)
    setFilters(newFilters)
  }

  return (
    <ContentContainer>
      <div className="list-heading align-items-baseline">
        <h4 className="hidden-on-large-screens">My Starred Messages</h4>
        {isMobileScreen ? (
          <FiltersToggle
            showFilters={showFilters}
            toggleShowFilters={toggleShowFilters}
          />
        ) : (
          <div className="list-heading-actions justify-content-end">
            <Filters
              actionTypeOptions={STARRED_ACTION_TYPE_OPTIONS}
              filters={filters}
              updateFilters={updateFilters}
            />
          </div>
        )}
      </div>
      {isMobileScreen && (
        <Card className={classNames({ "d-none": !showFilters })}>
          <Filters
            actionTypeOptions={STARRED_ACTION_TYPE_OPTIONS}
            filters={filters}
            updateFilters={updateFilters}
          />
        </Card>
      )}
      <StarredItemsList
        actionType={getActionType(filters.actionTypeFilter)}
        accountId={getAccountOptionId(filters.accountFilter)}
        schemeId={getSchemeOptionId(filters.schemeFilter)}
      />
      <AttachedDocumentPreview />
    </ContentContainer>
  )
}

export default StarredItems
