import React from "react"
import { func } from "prop-types"

import useIsOpened from "src/hooks/useIsOpened"

import UploadModal from "./UploadModal"

const UploadButton = (props) => {
  const { isOpened, open, close } = useIsOpened()

  return (
    <div>
      <button className="button" type="button" onClick={open}>
        <span className="no-wrap">
          <span>Upload Documents</span>
        </span>
      </button>

      <UploadModal isOpened={isOpened} open={open} close={close} {...props} />
    </div>
  )
}

UploadButton.propTypes = {
  uploadFiles: func.isRequired
}

export default UploadButton
