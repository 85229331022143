import Qs from "qs"
import { useQuery } from "@apollo/client"
import { useEffect } from "react"

import { useDocumentPreview } from "src/features/DocumentPreview"

import attachedDocumentQuery from "./attachedDocumentQuery.gql"

const DocumentPreviewFromQuery = () => {
  const { open } = useDocumentPreview()

  const queryParams = Qs.parse(window.location.search, { ignoreQueryPrefix: true })
  const documentId = queryParams.document_id

  const { data, loading } = useQuery(attachedDocumentQuery, {
    variables: { id: documentId },
    skip: !documentId,
    fetchPolicy: "network-only"
  })

  const document = data?.attachedDocument

  useEffect(() => {
    if (loading || !document) {
      return
    }

    open({ document })
  }, [document?.id, loading])

  return null
}

export default DocumentPreviewFromQuery
