import moment from "moment"
import { shape, number, string, oneOfType, bool } from "prop-types"

export const optionType = shape({
  value: oneOfType([number, string]),
  label: string.isRequired,
  colorId: number,
  isDefault: bool
})

export const momentType = function momentType(props, propName, componentName) {
  if (props[propName] === undefined || props[propName] === null) {
    return null
  }

  if (!moment.isMoment(props[propName])) {
    return new Error(
      `Invalid prop \`${propName}\` supplied to` +
        ` \`${componentName}\`. It should be an instance of moment.`
    )
  }

  return null
}
