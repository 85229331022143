class @SortableComponent
  @TIME_FORMAT = 'h:mm a'
  @INDEX_SEPARATOR = '.'
  @DEFAULT_START_INDEX = 1

  constructor: ->
    @agendaItemService  = new AgendaItemService()
    @sortableLists = $be('sortable-list')

  setup: =>
    for list in @sortableLists
      @setupSortable($(list)) unless $(list).data('ui-sortable')
      @setupItems($(list))
      $observe(list, @onListPositionChange)

  teardown: =>
    if @sortableLists.sortable('instance')
      @sortableLists.sortable('destroy')

  setupSortable: (list) =>
    sortableList = list.sortable({
      axis: 'y',
      revert: 0,
      cursor: 'move',
      tolerance: 'pointer',
      containment: $beById('sortable-list', list.data('id')),
      scroll: true,
      scrollSensitivity: 10,
      scrollSpeed: 10,
      handle: $('.card, .break'),
      items: '> .sortable-item',
      create: (e, ui) => @onCreate(list, ui),
      start: (e, ui) => @onStart(list, ui),
      change: (e, ui) => @onChange(list, ui),
      update: @onUpdate,
      stop: @onStop
    })

    sortableList.disableSelection() if sortableList.disableSelection

  onCreate: (list, ui) ->
    list.css('min-height', list.height())

  onStart: (list, ui) ->
    list.height(list.height())
    ui.item.data('ui-item-index', ui.item.index())

  onChange: (list, ui) =>
    @setupItems(list, ui)
    $beById('sortable-item-start-time', ui.item.attr('id')).addClass('text-highlighted')

  onUpdate: (e, ui) =>
    @agendaItemService.updateItemPosition(ui.item)

  onStop: (e, ui) ->
    $be('sortable-item-start-time').removeClass('text-highlighted')

  setupItems: (list, ui) ->
    return unless list.length

    itemIDs = list.sortable('toArray')

    if ui && ui.item # If item selected - inserts it's ID into correct position in itemIDs
      selectedItemID    = ui.item.attr('id')
      selectedItemIndex = ui.placeholder.index()
      selectedItemIndex-- if ui.item.data('ui-item-index') < selectedItemIndex

      itemIDs.splice(selectedItemIndex, 0, selectedItemID)

    startTime  = list.data('start-time')
    startIndex = list.data('start-index')

    [indexPrefix, currentItemIndex] = @splitIndex(startIndex)
    currentItemStartTime = moment(startTime, SortableComponent.TIME_FORMAT)
    listDuration = 0

    for itemID in itemIDs
      subList       = $beById('sortable-list', itemID)
      itemStartTime = $beById('sortable-item-start-time', itemID)
      itemIndex     = $beById('sortable-item-index', itemID)

      formattedIndex = $compact([
        indexPrefix,
        currentItemIndex
      ]).join(SortableComponent.INDEX_SEPARATOR)
      subListStartIndex = $compact([
        formattedIndex,
        SortableComponent.DEFAULT_START_INDEX
      ]).join(SortableComponent.INDEX_SEPARATOR)

      if list.hasClass('sortable-parent-list') || itemStartTime.data('duration')
        itemStartTime.text(currentItemStartTime.format(SortableComponent.TIME_FORMAT))
        itemStartTime.css('z-index', '1')
        currentItemStartTime.add(itemStartTime.data('duration'), 'seconds')
        # increase subList total duration with currentItem duration
        listDuration += itemStartTime.data('duration')

      continue if itemIndex.data('break')

      itemIndex.text(formattedIndex)
      currentItemIndex++

      subListStartTime = currentItemStartTime.format(SortableComponent.TIME_FORMAT)
      if (subListStartTime != subList.data('start-time') ||
          subListStartIndex != subList.data('start-index'))
        subList.data('start-time', subListStartTime)
        subList.data('start-index', subListStartIndex)
        # trigers mutation event, see @onListPositionChange
        subList.attr('data-list-position', formattedIndex)

      @setupSortable(subList) unless subList.data('ui-sortable')
      # recursive call of this function to setup subList items
      @setupItems(subList)

      if subList.data('duration') # adds subList duration to currentItem duration
        currentItemStartTime.add(subList.data('duration'), 'seconds')

    list.data('duration', listDuration) # save subList duration

  onListPositionChange: (mutations) =>
    mutations.forEach (mutation) =>
      if (mutation.type == 'attributes' && mutation.attributeName == 'data-list-position')
        @setupItems($(mutation.target))

  # @returns [prefix, index]
  splitIndex: (string,
               defaultIndex = SortableComponent.DEFAULT_START_INDEX,
               separator = SortableComponent.INDEX_SEPARATOR) ->
    return [null, defaultIndex] unless string || string == 0
    return [null, string] if $isPositiveInteger(string)
    [prefixes..., index] = string.toString().split(separator)
    return [prefixes.join(separator), index] if $isPositiveInteger(index)
    return [string, defaultIndex]
