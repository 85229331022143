import { toSnakeCase } from "src/helpers/string"

const getPageHandlerClass = (pageHandlerName) => {
  let pageHandlerClass = null

  if (!pageHandlerName) {
    return null
  }

  if (typeof window[pageHandlerName] === "function") {
    // find global legacy class
    pageHandlerClass = window[pageHandlerName]
  } else {
    // import es6 class from '.src/page_handlers'
    const fileName = toSnakeCase(pageHandlerName)
    try {
      // eslint-disable-next-line import/no-dynamic-require,global-require
      pageHandlerClass = require(`./src/page_handlers/${fileName}.js`).default
    } catch (e) {
      if (!e.message.includes("Cannot find module")) {
        throw e
      }
    }
  }

  return pageHandlerClass
}

const enableScrollOnTouchDevices = () => {
  $("body").on("touchmove", (e) => {
    e.stopPropagation()
  })
}

const pageHandlerSetup = () => {
  const pageHandlerName = $("body").data("page-handler")
  const PageHandlerClass = getPageHandlerClass(pageHandlerName)

  if (PageHandlerClass) {
    window.pageHandler = new PageHandlerClass()
    window.pageHandler.setup()
  }
}

const pageHandlerTeardown = () => window?.pageHandler?.teardown?.()

document.addEventListener("turbo:load", pageHandlerSetup)
document.addEventListener("turbo:load", enableScrollOnTouchDevices)
document.addEventListener("turbo:before-render", pageHandlerTeardown)
