class @RiskBreakdown
  @BAR_ORDER_COLORS: {
    1: '#6490CB',
    2: '#FFC000',
    3: '#5FA364',
    4: '#726D93',
    5: '#00B0F0',
    6: '#FF9D0F'
  }

  constructor: (params) ->
    @data      = params.data
    @elementId = params.elementId
    @desktopViewFlag = $be('desktop-view-flag')

    @xText = [
      'Pre-diversification VaR<br>(sum of independent VaRs) ',
      'Correlation VaR<br>(estimated diversification benefit)',
      'Post-diversification VaR<br>(net VaR)'
    ]
    @xValues = Object.keys(@xText).map((key) => parseInt(key))

  setup: () ->
    data = @data.map((row, key) => @trace(key))
    data = [@chartAnnotations(), @offsetTrace(), data...]

    if @desktopViewFlag.is(':visible')
      topMargin = 40
    else
      topMargin = 130

    layout = {
      showlegend: true,
      legend: {
        orientation: 'h',
        traceorder: 'normal'
      },
      barmode: 'stack',
      hovermode: 'closest',
      xaxis: {
        tickvals: @xValues,
        ticktext: @xText,
        side: 'top'
      },
      shapes: [
        @preDiversificationBracket()...,
        @correlationBracket()...,
        @postDiversificationBracket()...
      ],
      margin: {
        t: topMargin
      }
    }

    Plotly.newPlot(
      @elementId,
      data,
      layout,
      { displayModeBar: false, responsive: true }
    )


  trace: (dataKey) ->
    {
      x: @xValues,
      y: @yValues(dataKey),
      type: 'bar',
      name: @data[dataKey].name
      hoverinfo: 'text',
      hovertext: @barText(dataKey),
      width: 0.4,
      text: @barText(dataKey),
      textposition: 'auto',
      textfont: {
        size: 10,
        color: '#FFF'
      },
      marker: {
        color: RiskBreakdown.BAR_ORDER_COLORS[@data[dataKey].order],
      }
    }

  barText: (dataKey) ->
    [
      'pre_diversification_var_value',
      'correlation_var_value',
      'post_diversification_var_value'
    ]
      .map((key) => @data[dataKey][key] / @columnTotal(key))
      .map((percent) => $bold($percent(Math.round(percent * 100))))

  offsetTrace: () ->
    offset = @columnTotal('pre_diversification_var_value') - @columnTotal('correlation_var_value')

    {
      x: @xValues,
      y: [0, offset, 0],
      type: 'bar',
      opacity: 0
      showlegend: false,
      hoverinfo: 'none',
    }

  yValues: (key) ->
    [
      @data[key].pre_diversification_var_value,
      @data[key].correlation_var_value,
      @data[key].post_diversification_var_value
    ]

  columnTotal: (columnKey) ->
    @data
      .map((row) => row[columnKey])
      .reduce((value, sum) => sum + value)

  chartAnnotations: () ->
    {
      x: [
        0.4,
        1.4,
        2.4
      ],
      y: [
        @columnTotal('pre_diversification_var_value') / 2,
        @columnTotal('pre_diversification_var_value') - @columnTotal('correlation_var_value') / 2,
        @columnTotal('post_diversification_var_value') / 2
      ],
      hoverinfo: 'none',
      showlegend: false,
      text: [
        $formattedSumMln(@columnTotal('pre_diversification_var_value')),
        $formattedSumMln(@columnTotal('correlation_var_value')),
        $formattedSumMln(@columnTotal('post_diversification_var_value')),
      ],
      mode: 'text'
    }

  preDiversificationBracket: () ->
    barHeight = @columnTotal('pre_diversification_var_value')

    [@line(
      x0: 0.25,
      y0: barHeight,
      x1: 0.25,
      y1: 0
    ), @line(
      x0: 0.22,
      y0: barHeight,
      x1: 0.25,
      y1: barHeight
    ), @line(
      x0: 0.22,
      y0: 0,
      x1: 0.25,
      y1: 0
    ), @line(
      x0: 0.25,
      y0: barHeight / 2,
      x1: 0.28,
      y1: barHeight / 2
    )]

  correlationBracket: () ->
    barHeight = @columnTotal('correlation_var_value')
    barOffset = @columnTotal('pre_diversification_var_value') - barHeight

    [@line(
      x0: 1.25,
      y0: barOffset + barHeight,
      x1: 1.25,
      y1: barOffset
    ), @line(
      x0: 1.22,
      y0: barOffset + barHeight,
      x1: 1.25,
      y1: barOffset + barHeight
    ), @line(
      x0: 1.22,
      y0: barOffset,
      x1: 1.25,
      y1: barOffset
    ), @line(
      x0: 1.25,
      y0: barOffset + barHeight / 2,
      x1: 1.28,
      y1: barOffset + barHeight / 2
    )]

  postDiversificationBracket: () ->
    barHeight = @columnTotal('post_diversification_var_value')

    [@line(
      x0: 2.25,
      y0: barHeight,
      x1: 2.25,
      y1: 0
    ), @line(
      x0: 2.22,
      y0: barHeight,
      x1: 2.25,
      y1: barHeight
    ), @line(
      x0: 2.22,
      y0: 0,
      x1: 2.25,
      y1: 0
    ), @line(
      x0: 2.25,
      y0: barHeight / 2,
      x1: 2.28,
      y1: barHeight / 2
    )]


  line: (params) ->
    defaults = {
      type: 'line',
      line: {
        color: 'rgb(55, 128, 191)',
        width: 2,
      }
    }

    Object.assign({}, defaults, params)
