import { useCallback, useState } from "react"

const useInputFocus = (defaultFocus) => {
  const [isInputFocused, setIsInputFocused] = useState(defaultFocus)
  const setIsBlurred = useCallback(
    // without the timeout the panel will be closed before the click event reaches link
    () => setTimeout(() => setIsInputFocused(false), 200),
    []
  )
  const setIsFocused = useCallback(() => setIsInputFocused(true), [])

  return {
    isInputFocused,
    setIsBlurred,
    setIsFocused
  }
}

export default useInputFocus
