import { useQuery } from "@apollo/client"

import stepOneAccountsQuery from "./stepOneAccountsQuery.gql"

const useAccounts = ({ currentAccountId }) => {
  const { data, loading } = useQuery(stepOneAccountsQuery, {
    variables: { currentAccountId }
  })

  return { accounts: data?.meetingPackStepOneAccounts || [], loading }
}

export default useAccounts
