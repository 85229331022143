import React from "react"
import { string } from "prop-types"
import { styled } from "@linaria/react"

const AgendaItemIndex = ({ index, className }) => {
  return <div className={className}>Item {index}</div>
}

AgendaItemIndex.propTypes = {
  index: string.isRequired
}

export default styled(AgendaItemIndex)`
  position: absolute;
  top: 0;
  right: calc(100% + 10px);
  display: flex;
  align-items: center;
  height: 100%;
  width: 75px;
  flex-direction: column;
  justify-content: center;
`
