import React, { useContext, useState } from "react"
import { Modal } from "react-bootstrap"
import { func, oneOf } from "prop-types"

import ModalContext from "src/features/Messages/ActionDetails/ModalContext"

import { ACTION_TYPES } from "../../constants"
import { NumberInputSection } from "../../Shared"

import ButtonGroup from "./ButtonGroup"

const NumberForm = ({ actionType, onConfirm, onCancel }) => {
  const {
    message: { actionsCount }
  } = useContext(ModalContext)
  const [value, setValue] = useState(actionsCount)
  const valueValid = value && !Number.isNaN(value)

  return (
    <>
      <Modal.Body>
        <NumberInputSection
          actionType={actionType}
          selectedNumber={value}
          setSelectedNumber={setValue}
          maxNumber={actionsCount}
        />
      </Modal.Body>

      <Modal.Footer>
        <ButtonGroup
          confirmDisabled={!valueValid}
          onConfirm={() => onConfirm(value)}
          onCancel={onCancel}
        />
      </Modal.Footer>
    </>
  )
}

NumberForm.propTypes = {
  onConfirm: func.isRequired,
  actionType: oneOf(Object.keys(ACTION_TYPES)).isRequired,
  onCancel: func.isRequired
}

export default NumberForm
