import React from "react"
import { styled } from "@linaria/react"
import { shape, string, func, oneOf, bool } from "prop-types"

import { BREAKPOINTS } from "src/styles/sizes"
import { CardTitle, UserFullName, UserJobTitle } from "src/components/UsersModal/styles"
import UserAvatar from "src/components/UsersContacts/UserAvatar"
import LinkButton from "src/styles/components/Button/Link"
import { ACTION_TYPES } from "src/features/Messages/ActionDetails/constants"

import ActionStatus from "./ActionStatus"

const UserRow = ({ onClick, className, user, action }) => {
  if (!user) {
    return null
  }
  return (
    <LinkButton
      className={className}
      data-testid={`user-${user.id}-contact-details`}
      noFocus
      onClick={() => onClick(user)}
    >
      <UserAvatar className="mr-3" user={user} />
      <CardTitle>
        <UserFullName>{user.fullName}</UserFullName>
        <UserJobTitle>{user.jobTitle}</UserJobTitle>
      </CardTitle>
      <ActionStatus action={action} />
    </LinkButton>
  )
}

UserRow.propTypes = {
  className: string.isRequired,
  user: shape({
    fullName: string,
    jobTitle: string
  }),
  onClick: func.isRequired,
  action: shape({
    type: oneOf(Object.keys(ACTION_TYPES)).isRequired,
    complete: bool.isRequired
  }).isRequired
}

export default styled(UserRow)`
  cursor: pointer;
  padding: 11px 20px;
  padding-left: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-items: stretch;
  align-items: center;

  @media (max-width: ${BREAKPOINTS.small}) {
    padding: 0.75em 0;
  }
`
