export const preventDefault = (e) => {
  e.preventDefault()

  return e
}

export const stopPropagation = (e) => {
  e.stopPropagation()

  return e
}

export const withDefaultPrevented = (callback) => (e) => {
  e.preventDefault()

  return callback(e)
}

export const withPropagationStopped = (callback) => (e) => {
  e.stopPropagation()

  return callback(e)
}
