import { styled } from "@linaria/react"
import { lighten } from "polished"

import { TEXT_COLORS } from "src/styles/colors"
import { FONT_SIZES, SPACES } from "src/styles/sizes"

const lightenTextMutedColor = lighten(0.25, TEXT_COLORS.muted)

export const FilesList = styled.ul`
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
`

export const FileRow = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding: ${SPACES.xSmall} 0;
  overflow: hidden;
  border-bottom: 1px dotted ${lightenTextMutedColor};

  &:last-child {
    border-bottom: none;
  }

  .file-title {
    font-size: ${FONT_SIZES.small};
    max-width: 95%;
    word-break: break-word;
  }
`

export const DropZone = styled.div`
  border: 1px ${TEXT_COLORS.muted} dashed;
  border-radius: 2px;
  color: ${TEXT_COLORS.muted};
  cursor: pointer;
  padding: 6rem 0;
  text-align: center;
  transition: all 0.1s linear;

  &.is-drag-active,
  &:hover {
    border-color: ${lightenTextMutedColor};
    color: ${lightenTextMutedColor};
  }
`
