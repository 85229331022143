import React from "react"
import classNames from "classnames"
import { shape, string, number, bool } from "prop-types"

import useIsOpened from "src/hooks/useIsOpened"

import ContactDetailsModal from "./ContactDetailsModal"
import { UserName } from "./styles"
import { DEFAULT_TYPE } from "./constants"

const UserNameWithDetails = ({ name, user, isCurrentUser, type = DEFAULT_TYPE }) => {
  const { isOpened, open, close } = useIsOpened()

  const showStub = user && (user.avatarColorId || user.avatarColorId === 0)
  const stubClasses = classNames("message-author", {
    [`avatar-modifier-${user?.avatarColorId}`]: showStub
  })

  return (
    <>
      <UserName className={stubClasses} onClick={open}>
        {name || user?.fullName}
      </UserName>
      {isCurrentUser && (
        <span className="text-muted message-owner-flag">&nbsp;(you)</span>
      )}
      {user && (
        <ContactDetailsModal
          isOpened={isOpened}
          close={close}
          user={user}
          isCurrentUser={isCurrentUser}
          type={type}
        />
      )}
    </>
  )
}

UserNameWithDetails.propTypes = {
  name: string.isRequired,
  user: shape({
    fullName: string,
    avatarColorId: number
  }),
  isCurrentUser: bool.isRequired,
  type: string
}

export default UserNameWithDetails
