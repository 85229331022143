import React, { useRef, useState } from "react"

import { splitFilename } from "src/helpers/string"
import CloseButton from "src/components/CloseButton"

import { PreviewComponentPropTypes } from "../propTypes"

import {
  Container,
  BoxPreviewContainer,
  Header,
  TitleContainer,
  Preview,
  Sidebar,
  Title
} from "./styles"
import useCloseButtonDynamicPosition from "./useCloseButtonDynamicPosition"
import useBoxPreview from "./useBoxPreview"

const BOX_PREVIEW_ID = "box-preview"
const BOX_SIDEBAR_ID = "box-sidebar"

const PreviewContainer = ({
  document,
  collection,
  onClose,
  sidebarEnabled = false,
  withinModal = false,
  toolbarButtonsFactory = () => []
}) => {
  const [filename, setFilename] = useState("")
  const closeButtonRef = useRef()

  const sidebarContainer = sidebarEnabled ? `#${BOX_SIDEBAR_ID}` : null

  useBoxPreview({
    document,
    collection,
    toolbarButtonsFactory,
    sidebarContainer,
    previewContainer: `#${BOX_PREVIEW_ID}`,
    onNavigate: (file) => setFilename(file.filename)
  })

  useCloseButtonDynamicPosition({
    closeButton: closeButtonRef.current,
    skip: withinModal
  })

  const { name } = splitFilename(filename)

  return (
    <Container data-behavior="document-preview">
      <Header>
        <TitleContainer>
          <Title>{name}</Title>
        </TitleContainer>
        <CloseButton onClick={onClose} ref={closeButtonRef} />
      </Header>
      <BoxPreviewContainer>
        <Preview id={BOX_PREVIEW_ID} />
        <Sidebar id={BOX_SIDEBAR_ID} />
      </BoxPreviewContainer>
    </Container>
  )
}

PreviewContainer.propTypes = PreviewComponentPropTypes

export default PreviewContainer
