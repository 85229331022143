import React from "react"
import { styled } from "@linaria/react"
import { bool, func, string } from "prop-types"

import Icon from "src/styles/components/Icon"
import UnstyledButton from "src/components/UnstyledButton"

import { FilterOptionsSwitcher } from "../styles"

const ExtendedControl = ({ opened, toggle, clear, className }) => {
  return (
    <div className={`${className} text-muted`}>
      <FilterOptionsSwitcher onClick={() => toggle()}>
        Filtering options
        <Icon type="dropdown-arrow" className={`${opened && "icon-rotated"}`} />
      </FilterOptionsSwitcher>
      <UnstyledButton onClick={clear}>Clear</UnstyledButton>
    </div>
  )
}

ExtendedControl.propTypes = {
  className: string.isRequired,
  opened: bool.isRequired,
  toggle: func.isRequired,
  clear: func.isRequired
}

export default styled(ExtendedControl)`
  margin-top: 5px;
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
`
