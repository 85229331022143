import React, { useState } from "react"
import classNames from "classnames"
import { styled } from "@linaria/react"
import { oneOf, string, number } from "prop-types"
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap"

import useWindowSize from "src/hooks/useWindowSize"
import useAblyChannel from "src/hooks/useAblyChannel"
import { composeVariationsClasses } from "src/helpers/linaria"
import { BREAKPOINTS, FONT_WEIGHTS } from "src/styles/sizes"
import { BASE_COLORS, BACKGROUND_COLORS } from "src/styles/colors"
import { CHANNELS } from "src/constants/ably"

const PublicationBadge = ({ publicationStatus, meetingPackId, className, ...props }) => {
  const { width } = useWindowSize()

  const [status, setStatus] = useState(publicationStatus)
  useAblyChannel(CHANNELS.meetingPacks, {
    onMessage: ({ data }) => {
      if (data.meetingPackId === meetingPackId && data.publicationBadge)
        setStatus(data.publicationBadge)
    }
  })

  const classes = classNames(
    "badge-knowa",
    composeVariationsClasses(className, { color: status })
  )
  const isPillBadge =
    width <= parseInt(BREAKPOINTS.xSmall, 10) ||
    (width >= parseInt(BREAKPOINTS.small, 10) &&
      width <= parseInt(BREAKPOINTS.semiLarge, 10))

  return (
    <OverlayTrigger
      delay={300}
      placement="bottom"
      overlay={
        <Tooltip
          id="tooltip-badge"
          className={classNames("text-uppercase", { "d-none": !isPillBadge })}
        >
          {status}
        </Tooltip>
      }
    >
      <Badge className={classes} {...props} pill={isPillBadge}>
        {isPillBadge ? " " : status}
      </Badge>
    </OverlayTrigger>
  )
}

PublicationBadge.propTypes = {
  publicationStatus: oneOf(["", "preview", "unpublished", "published"]),
  meetingPackId: number.isRequired,
  className: string
}

export default styled(PublicationBadge)`
  &.badge-knowa {
    padding: 8px;
    border-radius: 4px;
    color: ${BASE_COLORS.white};
    font-weight: ${FONT_WEIGHTS.bold};
    font-style: normal;
    cursor: pointer;
  }

  &.badge-pill {
    border-radius: 50%;
  }

  &--color-unpublished.badge-knowa {
    background-color: ${BACKGROUND_COLORS.danger};
  }

  &--color-published.badge-knowa {
    background-color: ${BACKGROUND_COLORS.success};
  }
  &--color-preview.badge-knowa {
    background-color: ${BACKGROUND_COLORS.lightOrange};
  }
`
