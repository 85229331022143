import React from "react"

import { Skeleton } from "src/features/groups/DocumentsExplorer/styles"

import {
  Row,
  SelectionCell,
  IconCell,
  ModifiedCell,
  ModifiedByCell,
  SizeCell,
  NameCell
} from "../styles"

import { SelectionCellSkeleton } from "./styles"

const ROWS_COUNT = 3

const LoadingState = () => {
  const rows = []

  for (let i = 0; i < ROWS_COUNT; i += 1) {
    rows.push(
      <Row key={i} data-testid="loading-row">
        <SelectionCell>
          <SelectionCellSkeleton />
        </SelectionCell>
        <IconCell>
          <Skeleton />
        </IconCell>
        <NameCell>
          <Skeleton />
        </NameCell>
        <ModifiedCell>
          <Skeleton />
        </ModifiedCell>
        <ModifiedByCell>
          <Skeleton />
        </ModifiedByCell>
        <SizeCell>
          <Skeleton />
        </SizeCell>
      </Row>
    )
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{rows}</>
}

export default LoadingState
