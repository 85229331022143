import { SYSTEM_FILES } from "../consts"

const useSystemFilePicker = (handler, { directory = false } = {}) => {
  const input = document.createElement("input")
  input.type = "file"
  input.multiple = true
  input.webkitdirectory = directory

  input.onchange = () => {
    const files = Array.from(input.files)
    const filteredFiles = files.filter((file) => !SYSTEM_FILES.includes(file.name))

    handler(filteredFiles)
  }

  return () => {
    input.click()
  }
}

export default useSystemFilePicker
