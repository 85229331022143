import React from "react"
import { number } from "prop-types"
import pluralize from "pluralize"

import Icon from "src/styles/components/Icon"

import AnnotationsCount from "./AnnotationsCount"

const OtherAnnotationsCount = ({ count }) => {
  return (
    <AnnotationsCount>
      <Icon type="annotation" /> {pluralize("annotation", count, true)}
    </AnnotationsCount>
  )
}

OtherAnnotationsCount.propTypes = {
  count: number.isRequired
}

export default OtherAnnotationsCount
