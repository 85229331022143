import groups from "src/resources/groups/api"
import { showFlashMessage } from "src/helpers/flash"
import { showCaughtErrorMessage } from "src/helpers/errors"

import discussions from "./api"
import {
  fetchDiscussionsFailure,
  fetchDiscussionsRequest,
  fetchDiscussionsSuccess
} from "./slice"

export const fetchGroupDiscussions = (params = {}) => (dispatch) => {
  dispatch(fetchDiscussionsRequest())

  groups
    .discussions(params)
    .then((response) => {
      dispatch(fetchDiscussionsSuccess(response.data))
    })
    .catch((error) => {
      showCaughtErrorMessage(error)
      dispatch(fetchDiscussionsFailure(error))
    })
}

export const moveDiscussion = (data) => () => {
  showFlashMessage("warning", "We are moving the discussion. Please wait.")

  return discussions
    .move(data)
    .then(() => {
      showFlashMessage("info", "Discussion has been moved.")
    })
    .catch((error) => showCaughtErrorMessage(error))
}
