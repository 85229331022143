import { useEffect } from "react"
import { tinykeys } from "tinykeys"

const useCmdKForSearch = (openFunction) => {
  useEffect(() => {
    const shortCutPressed = tinykeys(window, {
      "$mod+KeyK": (event) => {
        event.preventDefault()
        openFunction()
      }
    })
    return shortCutPressed
  }, [])
}

export default useCmdKForSearch
