import React, { useEffect, useState } from "react"
import { arrayOf, bool, func, number, oneOfType, shape, string } from "prop-types"
import { partition } from "lodash"

import SelectTeammates from "src/components/SelectTeammates"

const AccountMembers = ({
  accountSection,
  onChangeSelected,
  setModified,
  usersRoles
}) => {
  const addedTeams = accountSection.teams.added || []
  const possibleTeamsToAdd = accountSection.teams.possible || []

  // "accountSection.users" format: [true, users[{}]], [false, users[{}]]
  const [groupMembers, possibleMembers] = partition(accountSection.users, (e) => e[0])
  const addedUsers = groupMembers.length ? groupMembers[0][1] : []
  const possibleUsers = possibleMembers.length ? possibleMembers[0][1] : []

  const initialSelectedUserIds = addedUsers.map(({ id }) => id)
  const [selectedUserIds, setSelectedUserIds] = useState(initialSelectedUserIds)

  useEffect(() => {
    if (selectedUserIds !== initialSelectedUserIds) setModified(true)
    onChangeSelected(accountSection.accountId, selectedUserIds)
  }, [selectedUserIds])

  return (
    <>
      <h5 className="pvx phs pt-3">
        <span className="prs">{accountSection.accountName}</span>
        {accountSection.isJointAccount && (
          <div className="account-connected-title">Connected organisation</div>
        )}
      </h5>
      <SelectTeammates
        possibleTeams={possibleTeamsToAdd}
        addedTeams={addedTeams}
        addedUsers={addedUsers}
        possibleUsers={possibleUsers}
        title="Group Members"
        selectedUserIds={selectedUserIds}
        setSelectedUserIds={setSelectedUserIds}
        usersRoles={usersRoles}
      />
    </>
  )
}

AccountMembers.propTypes = {
  accountSection: shape({
    teams: shape({
      added: arrayOf(
        shape({
          id: number.isRequired,
          name: string.isRequired
        })
      ),
      possible: arrayOf(
        shape({
          id: number.isRequired,
          name: string.isRequired
        }).isRequired
      )
    }).isRequired,
    users: arrayOf(
      arrayOf(
        oneOfType([
          bool,
          arrayOf(
            shape({
              id: number.isRequired,
              fullName: string.isRequired
            })
          )
        ])
      )
    ).isRequired,
    isJointAccount: bool,
    accountName: string,
    accountId: number
  }).isRequired,
  onChangeSelected: func.isRequired,
  setModified: func.isRequired,
  usersRoles: shape({}).isRequired
}

export default AccountMembers
