import React, { useEffect, useMemo, useState } from "react"
import { styled } from "@linaria/react"
import { arrayOf, number, shape, string } from "prop-types"

import { BREAKPOINTS } from "src/styles/sizes"
import { be } from "src/helpers/document"
import useMobileScreen from "src/hooks/useMobileScreen"

import Content from "./Content"
import Toggler from "./Toggler"
import { Header, CloseButton } from "./styles"
import { ACTIVE_TAB_KEY, BACKLINKS_WIDTH, TABS } from "./constants"
import Tabs from "./Tabs"
import DrawerContext from "./DrawerContext"

const DiscussionDrawer = ({ discussion, participants, className }) => {
  const isMobileScreen = useMobileScreen()
  const isSmallScreen = useMobileScreen(BREAKPOINTS.large)

  const [opened, setOpened] = useState(!isSmallScreen)
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem(ACTIVE_TAB_KEY) || TABS.DOCS
  )

  const open = () => setOpened(true)
  const close = () => setOpened(false)
  const drawerContext = useMemo(() => ({ open, close }), [])

  useEffect(() => {
    if (isSmallScreen) {
      const discussionPage = be("discussion-page")
      if (opened && isMobileScreen) {
        discussionPage.hide()
      } else {
        discussionPage.show()
      }
    }
  }, [opened])

  return (
    <DrawerContext.Provider value={drawerContext}>
      {opened && (
        <aside className={className}>
          <Header>
            <Tabs active={activeTab} setActive={setActiveTab} />
            <CloseButton onClick={close} />
          </Header>
          <Content discussion={discussion} participants={participants} kind={activeTab} />
        </aside>
      )}

      {!opened && <Toggler openFunction={open} />}
    </DrawerContext.Provider>
  )
}

DiscussionDrawer.propTypes = {
  className: string.isRequired,
  discussion: shape({
    id: number.isRequired
  }),
  participants: arrayOf(shape({}))
}

export default styled(DiscussionDrawer)`
  background: white;
  width: ${BACKLINKS_WIDTH};
  height: 100%;
  overflow: hidden;

  @media (max-width: ${BREAKPOINTS.small}) {
    width: 100vw;
    max-width: 100%;
  }

  @media print {
    display: none !important;
  }
`
