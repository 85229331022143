class @NewMessageActionComponent
  ACTION_TYPES_WITH_CLOSING_OPTIONS: ['must_read', 'voting']

  constructor: (actorsPanel, closingOptionsPanel, editingMessageId,
    onActorRemoved = null) ->

    @actorsIds = []
    @actorsPanel = actorsPanel
    @closingOptionsPanel = closingOptionsPanel
    @actorsIdsInput = if editingMessageId
      $beById('edit-mentioned-user-ids-input', editingMessageId) # when edit message
    else
      $be('mentioned-user-ids-input')

    @actionTypeInput = $be('action-type-input')
    @actionTypeDropdown = $be('action-type-dropdown')
    @actionTypeDropdownItems = @actionTypeDropdown
      .next()
      .find('[data-behavior="action-type-dropdown-item"]')

    @bindDropdownHandler()
    @renderActorsPanel()
    @bindActorRemove(onActorRemoved)

    $on('newMessageContentUpdated', (e) =>
      @update(e.mentionIds)
    )

  bindDropdownHandler: =>
    return unless @actorsPanel

    $('[data-behavior="action-type-dropdown-item"]', @actorsPanel).on('click', (e) =>
      actionType = e.target.dataset.value
      @actionTypeInput.val(e.target.dataset.value)
      @actionTypeDropdown.find('span').html(e.target.innerHTML)

      showClosingOptionsPanel = @ACTION_TYPES_WITH_CLOSING_OPTIONS.includes(
        actionType
      )
      @closingOptionsPanel.toggle(showClosingOptionsPanel)

      @publishActionTypeChangedEvent(actionType)
    )

  update: (values) =>
    return if $areArraysEqual(@actorsIds, values)

    @actorsIds = values

    @renderActorsPanel()
    @updateMessageInputs()
    @publishActorsChangedEvent(values)

  renderActorsPanel: () =>
    return unless @actorsPanel
    return @hidePanels() unless @actorsIds.length

    @actorsPanel
      .show()
      .find('[data-behavior="message-actor-badge"]')
      .hide()
      .each((i, badge) =>
        $(badge).show() if @actorsIds.indexOf(Number(badge.dataset.id)) > -1
      )

  hidePanels: () =>
    @actorsPanel.hide()
    @closingOptionsPanel.hide()

  updateMessageInputs: () =>
    if !@actorsIds.length
      @resetActionType()

    @actorsIdsInput.val(@actorsIds.join(' '))

  resetActionType: () =>
    @actionTypeInput.val(null)
    @actionTypeDropdown.find('span').html(@actionTypeDropdownItems.first().html())


  bindActorRemove: (callback) =>
    if @actorsPanel
      $(@actorsPanel).find('[data-behavior=remove-actor]').on('click', (e) =>
        removedActorId = Number(e.target.dataset.actorId)

        actorsIdsWithoutRemoved = @actorsIds.filter((id) ->
          id != removedActorId
        )

        @update(actorsIdsWithoutRemoved)

        if callback
          callback(removedActorId)
      )

  publishActionTypeChangedEvent: (actionType) =>
    @publishWindowEvent("newMessageAction:actionTypeChanged", { actionType })

  publishActorsChangedEvent: (actorIds) =>
    @publishWindowEvent("newMessageAction:actorsChanged", { actorIds })

  publishWindowEvent: (eventName, detail) ->
    window.dispatchEvent(new CustomEvent(eventName, { detail }))
