import accounts from "src/resources/accounts/api"
import mentions from "src/resources/mentions/api"
import starredItems from "src/resources/starredItems/api"
import { showCaughtErrorMessage } from "src/helpers/errors"

import schemes from "./api"
import { fetchSchemesFailure, fetchSchemesRequest, fetchSchemesSuccess } from "./slice"

export const fetchSchemes = (params = {}) => (dispatch) => {
  dispatch(fetchSchemesRequest())

  schemes
    .index(params)
    .then((response) => {
      dispatch(fetchSchemesSuccess(response.data))
    })
    .catch((error) => {
      showCaughtErrorMessage(error)
      dispatch(fetchSchemesFailure(error))
    })
}

export const fetchAccountSchemes = (params = {}) => (dispatch) => {
  dispatch(fetchSchemesRequest())

  accounts
    .schemes(params)
    .then((response) => {
      dispatch(fetchSchemesSuccess(response.data))
    })
    .catch((error) => {
      showCaughtErrorMessage(error)
      dispatch(fetchSchemesFailure(error))
    })
}

export const fetchMentionsSchemes = (params = {}) => (dispatch) => {
  dispatch(fetchSchemesRequest())

  mentions
    .schemes(params)
    .then((response) => {
      dispatch(fetchSchemesSuccess(response.data))
    })
    .catch((error) => {
      showCaughtErrorMessage(error)
      dispatch(fetchSchemesFailure(error))
    })
}

export const fetchStarredItemsSchemes = (params = {}) => (dispatch) => {
  dispatch(fetchSchemesRequest())

  starredItems
    .schemes(params)
    .then((response) => {
      dispatch(fetchSchemesSuccess(response.data))
    })
    .catch((error) => {
      showCaughtErrorMessage(error)
      dispatch(fetchSchemesFailure(error))
    })
}
