import React from "react"
import { shape, oneOf, number, string } from "prop-types"
import { styled } from "@linaria/react"

import { summaryText } from "./helpers"
import { ACTION_TYPES } from "./constants"

const MessageActionSummary = ({ message, className }) => {
  if (message.actionClosed) return null

  const text = summaryText(message, {
    action: message.actionClosedType,
    date: message.actionClosedAt,
    number: message.maxActionsCount
  })

  return <div className={className}>{text}</div>
}

MessageActionSummary.propTypes = {
  message: shape({
    actionsCount: number,
    actionType: oneOf(Object.keys(ACTION_TYPES)),
    actionClosedType: string,
    actionClosedAt: string
  }).isRequired,
  className: string.isRequired
}

export default styled(MessageActionSummary)`
  font-size: 0.8rem;
  font-weight: bold;
  margin-top: 0.5rem;
`
