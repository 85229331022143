import { isIphone, isPWA } from "src/helpers/browser"

document.addEventListener("turbo:load", () => {
  const body = document.querySelector("body")

  if (isPWA()) {
    body.classList.add("is-pwa")
  }

  if (isIphone()) {
    body.classList.add("is-iphone")
  }
})
