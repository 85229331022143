import apiClient from "src/app/apiClient"

const move = ({ groupId, data }) => apiClient.patch(`/api/groups/${groupId}/move`, data)
const discussions = ({ groupId, ...params }) =>
  apiClient.get(`/api/groups/${groupId}/discussions`, { params })

const groups = {
  move,
  discussions
}

export default groups
