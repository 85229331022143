class @AgendaItemMembersModalsComponent
  setup: =>
    @userList = $be('show-members-modal-content')
    @manageUserList = $be('manage-members-modal-content')
    @showMembersLink = $be('show-members-link')
    @showMembersModal = $be('show-members-modal')
    @manageMembersModal = $be('manage-members-modal')

    @showMembersLink.click (e) =>
      @manageMembersModal.modal('hide')
      $.getScript($(e.target).closest('a').attr('href'))

  teardown: =>
    @showMembersLink.off('click')

  reset: =>
    @teardown()
    @setup()

  showManageList: (listContent) =>
    @manageUserList.html(listContent)
    $setupUsersCards(@manageUserList)

    @manageMembersModal.modal('show')
    @showMembersModal.modal('hide')

    $be('manage-attendees-submit').click (e) =>
      e.preventDefault()
      @manageMembersModal.modal('hide')
      window.Rails.fire($be('manage-attendees-form')[0], 'submit')

  showList: (listContent) =>
    @userList.html(listContent)
    $setupUsersCards(@userList)

    @showMembersModal.modal('show')
    @manageMembersModal.modal('hide')

  setAgendaItemMembers: (agendaItemMembersHtml) =>
    $be('agenda-item-members').replaceWith(agendaItemMembersHtml)
    window.layoutHandler.contactDetailsComponent.bindLinks($be('agenda-item-members'))
    @reset()
