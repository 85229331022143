import { useState, useEffect, useMemo } from "react"
import { useApolloClient } from "@apollo/client"
import { sample } from "lodash"

import {
  FAIL_MESSAGE,
  FAIL_AUTHENTICATION_MESSAGE
} from "src/features/UniversalAi/constants"
import { CHANNELS } from "src/constants/ably"
import useAblyChannel from "src/hooks/useAblyChannel"
import { getCurrentUserId } from "src/helpers/user"
import { scrollDown } from "src/helpers/document"
import CacheStore from "src/features/offline/helpers/CacheStore"

import askAiQuestionMutation from "./askAiQuestionMutation.gql"
import useStreaming from "./useStreaming"

const useAskQuestion = ({ objectId, objectType, contentElementFindFunc }) => {
  const apolloClient = useApolloClient()
  const [loading, setLoading] = useState(false)
  const { content, setContent, finished, setFinished } = useStreaming({
    onChangeContent: () => scrollDown(contentElementFindFunc)
  })
  const [prompt, setPrompt] = useState("")
  const cacheStore = new CacheStore()
  const channelName = useMemo(() => sample(CHANNELS.aiAnswer.askQuestion), [])

  const getCachedAnswer = async () => {
    return cacheStore.getCustomPromptAIAnswer(objectType, objectId)
  }

  useEffect(() => {
    getCachedAnswer().then((cachedData) => {
      setContent(cachedData?.answer || "", { stream: false })
      setPrompt(cachedData?.prompt || "")
      setFinished(true)
    })
  }, [objectId, objectType])

  const setCached = (answerText) => {
    cacheStore.saveCustomPromptAIAnswer(objectType, objectId, prompt, answerText)
  }

  const handleAnswer = ({ value, complete, error }) => {
    setContent(value || error)
    setLoading(false)
    if (complete || error) setFinished(true)
    if (complete && value) setCached(value)
  }

  const onSubmit = (values) => {
    setFinished(false)
    setLoading(true)
    setPrompt(values.prompt)
    apolloClient
      .mutate({
        mutation: askAiQuestionMutation,
        variables: {
          question: values.prompt,
          objectId,
          objectType,
          channelName
        }
      })
      .then(({ data }) => {
        if (!data?.askAiQuestion?.success)
          handleAnswer({ error: data?.errors || FAIL_MESSAGE })
      })
      .catch((response) => {
        const error =
          response.graphQLErrors &&
          response.graphQLErrors[0]?.extensions?.code === "unauthorized"
            ? FAIL_AUTHENTICATION_MESSAGE
            : FAIL_MESSAGE
        handleAnswer({ error })
      })
  }

  useAblyChannel(channelName, {
    onMessage: ({ data }) => {
      const isTargetObject =
        data.userId === getCurrentUserId() &&
        data.objectId.toString() === objectId?.toString() &&
        data.objectType === objectType
      if (isTargetObject && data.answer) {
        handleAnswer({ value: data.answer, complete: data.finished })
      } else if (isTargetObject && !data.success) {
        handleAnswer({ error: data.error || FAIL_MESSAGE })
      }
    }
  })

  return { answer: content, onSubmit, loading, finished, prompt }
}

export default useAskQuestion
