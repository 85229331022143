import React, { useEffect } from "react"

import BoxFailedState from "src/components/BoxFailedState"
import useBoxFilePicker from "src/hooks/box/useBoxFilePicker"
import { BOX_COMPONENTS, BOX_ROOT_FOLDER_ID } from "src/constants"
import { renameChooseButton } from "src/helpers/box"

import { FilePickerContainer } from "./styles"

const agendaItemDocumentExtensions = [
  "pdf",
  "doc",
  "docx",
  "xls",
  "xlsx",
  "ppt",
  "pptx",
  "pps",
  "jpg",
  "jpeg",
  "png"
]

const FilePicker = ({ handleChoose, handleCancel }) => {
  try {
    const { open, hide } = useBoxFilePicker({
      folderId: BOX_ROOT_FOLDER_ID,
      handleCancel,
      handleChoose,
      extensions: agendaItemDocumentExtensions
    })

    useEffect(() => {
      open()
      renameChooseButton("Upload")

      return hide
    }, [])

    return <FilePickerContainer data-behavior={BOX_COMPONENTS.filePicker} />
  } catch {
    return <BoxFailedState />
  }
}

FilePicker.propTypes = {}

export default FilePicker
