class @FroalaEmbedComponent
  setup: =>
    @setupPopupTemplate()
    @setupPlugin()
    @defineIcon()
    @registerCommand()

  setupPopupTemplate: () ->
    FroalaEditor.POPUP_TEMPLATES["embed.popup"] = '' +
      '<div class="embed-plugin-popup" data-behavior="embed-popup">' +
        '[_URL_LAYER_][_BUTTONS_]' +
      '</div>'

  setupPlugin: () ->
    extractIframeHTML = @extractIframeHTML

    FroalaEditor.PLUGINS.embed = (editor) ->
      initPopup = (() ->
        embed_button =
          '<button type="button" data-behavior="embed-button" class="btn btn-primary">' +
            'Embed' +
          '</button>'
        cancel_embed_button =
          '<button type="button" data-behavior="cancel-embed-button" class="btn ">' +
            'Cancel' +
          '</button>'
        popup_buttons =
          "<div class='embed-buttons'>#{embed_button}#{cancel_embed_button}</div>"

        iframe_input = '<textarea rows="6" type="text" name="iframe-to-embed"></textarea>'

        template = {
          buttons: popup_buttons,
          url_layer: "<div class='custom-layer'>#{iframe_input}</div>"
        }

        popups = editor.popups.create('embed.popup', template)

        popups.each((i, popup) ->
          popup.addEventListener('click', ((e) ->
            if e.target.dataset.behavior == 'embed-button'
              text_area = popup.querySelector('textarea')

              if text_area.value && iframeHTML = extractIframeHTML(text_area.value)
                editor.html.insert(iframeHTML)
                text_area.value = ''

              editor.embed.hidePopup()
            else if e.target.dataset.behavior == 'cancel-embed-button'
              editor.embed.hidePopup()
          ), false)
        )

        popups
      )

      {
        showPopup: () ->
          popup = editor.popups.get('embed.popup')
          popup = if popup then popup else initPopup()

          editor.popups.setContainer('embed.popup', editor.$tb)

          btn = editor.$tb.find('.fr-command[data-cmd="embed"]')
          left = btn.offset().left + btn.outerWidth() / 2
          top = btn.offset().top + btn.outerHeight()

          editor.popups.show('embed.popup', left, top, btn.outerHeight())

        hidePopup: () ->
          editor.popups.hide('embed.popup')
      }

  defineIcon: () ->
    FroalaEditor.DefineIcon('embed', {NAME: 'window-maximize', template: 'font_awesome'})

  registerCommand: () ->
    FroalaEditor.RegisterCommand('embed', {
      title: 'Embed',
      focus: true,
      undo: true,
      refreshAfterCallback: true,
      callback: (e) ->
        this.embed.showPopup()
    })

  extractIframeHTML: (html) ->
    tempWrapper = document.createElement('div')
    tempWrapper.innerHTML = html
    tempWrapper.querySelector('iframe')?.outerHTML
