class @ZephyrusReportsShowHandler
  @CHART_COMPONENTS: {
    tp_funding_position: TpFundingPositionComponent,
    attribution_of_change_in_deficit: AttributionOfChangeInDeficitComponent,
    risk_analysis: RiskAnalysis,
    market_background: MarketBackgroundComponent
  }

  constructor: ->
    @slides = $be('report-slide')
    @groupId = window.groupId
    @appInstanceId = window.appInstanceId

    @reportContainer = $be('report-container')

    @groupMembershipWatcherComponent = new GroupMembershipWatcherComponent()
    @groupMemberListComponent = new GroupMemberListComponent(
      listContainerBehavior: 'group-participants',
      manageEnabled: false,
      contactDetailsEnabled: true
    )
    @groupLeaveLinkComponent = new GroupLeaveLinkComponent(@groupId)
    @copyLinkComponent = new CopyLinkComponent()

    @focusedSlide = new URL(window.location).searchParams.get('slide')

  setup: =>
    @groupMembershipWatcherComponent.setup()
    @groupMemberListComponent.setup()
    @groupLeaveLinkComponent.setup()
    @copyLinkComponent.setup()

    @enablePopovers()
    @bindCollapsibleSlides()
    @bindDateSelectors()

    if @focusedSlide
      slide = $be('report-slide').filter("[data-name=#{@focusedSlide}]")
      @loadCharts(slide[0])
      storeSlideShown(slide[0])
      @showSlide(slide)
      @scrollToSlide(slide)
    else
      @showOpenedSlides()
      @scrollToStoredPosition()

  teardown: =>
    @groupMembershipWatcherComponent.teardown()
    @groupMemberListComponent.teardown()
    @groupLeaveLinkComponent.teardown()

  enablePopovers: ->
    $('.report-slides [data-toggle="popover"]').popover(
      html: true,
      trigger: 'hover'
    )

  bindCollapsibleSlides: =>
    @slides.on 'shown.bs.collapse', (e) =>
      @loadCharts(e.target)
      storeSlideShown(e.target)
      @scrollToSlide(e.target)

    @slides.on 'hidden.bs.collapse', (e) ->
      storeSlideHidden(e.target)

    $be('report-slide-header').
      find('.report-subheader').click (e) ->
        e.stopPropagation()

  showOpenedSlides: =>
    handler = this
    @slides.each (idx, slideElement) ->
      data = slideElement.dataset
      slide = $(slideElement)
      shown = localStorage.getItem(
        shownSlideKey(data.reportId, data.name)
      )
      if shown
        handler.loadCharts(slideElement)
        handler.showSlide(slide)

  showSlide: (slide) =>
    slide.addClass('show')
    slide.
      siblings('[data-behavior=report-slide-header]').
      removeClass('collapsed')

  loadCharts: (slideElement) =>
    data = slideElement.dataset
    return unless data.url
    $.get(data.url, (response) =>
      chart = new (this.constructor.CHART_COMPONENTS[data.name])({
        data: response,
        elementId: data.name + '-chart'
      })
      chart.setup()
    )

  bindDateSelectors: =>
    $be('report-date-selector').change (e, a) =>
      dateSelector = $(e.target)
      url = new URL(document.location)
      url.searchParams.set(dateSelector.data('name'), dateSelector.val())
      url.searchParams.delete('slide')
      @storeScrollPosition()
      Turbo.visit(url.toString())

  storeScrollPosition: =>
    localStorage.setItem(
      @scrollPositionKey(), @reportContainer.scrollTop()
    )

  scrollToStoredPosition: =>
    position = localStorage.getItem(@scrollPositionKey())
    if position
      @reportContainer.scrollTop(position)
      localStorage.removeItem(@scrollPositionKey())

  scrollToSlide: (slide) =>
    newScrollPostition = @reportContainer.scrollTop() +
      $(slide).parent().position().top -
      @reportContainer.position().top
    @reportContainer.animate({scrollTop: newScrollPostition}, 300)

  storeSlideShown = (slideElement) ->
    data = slideElement.dataset
    localStorage.setItem(
      shownSlideKey(data.reportId, data.name), true
    )

  storeSlideHidden = (slideElement) ->
    data = slideElement.dataset
    localStorage.removeItem(
      shownSlideKey(data.reportId, data.name)
    )

  shownSlideKey = (id, name) ->
    "shown-report-slide-#{id}-#{name}"

  scrollPositionKey: ->
    "scroll-position-#{@groupId}-#{@appInstanceId}"
