import { styled } from "@linaria/react"

import BaseCopyLink from "src/components/CopyLink"
import { BREAKPOINTS } from "src/styles/sizes"
import { buttonStyles } from "src/features/groups/DocumentsExplorer/styles"

export const CopyLink = styled(BaseCopyLink)`
  ${buttonStyles}

  font-size: 16px;

  &:hover {
    color: inherit;
  }

  & span:not(.icon-link) {
    margin-left: 0.5rem;
  }

  @media (max-width: ${BREAKPOINTS.medium}) {
    & span {
      display: none;
    }

    & [class^="icon-"] {
      display: block;
    }
  }
`
