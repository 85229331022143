class @AnalyticsComponent
  constructor: () ->

  setup: =>
    return if !window.analytics or window.analytics.isStubbed

    $be('show-intercom').click (e) ->
      e.preventDefault()

      # re issue page event
      window.analytics.page('Knowa App')

      Intercom('update', {'hide_default_launcher': false})
      Intercom('show')
