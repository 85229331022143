import { styled } from "@linaria/react"

import { TEXT_COLORS, linkHoverColor } from "src/styles/colors"

import UnstyledButton from "./UnstyledButton"

const LinkButton = styled(UnstyledButton)`
  color: ${TEXT_COLORS.link};

  &:hover {
    color: ${linkHoverColor};
  }

  &:focus {
    color: ${linkHoverColor};
  }
`

export default LinkButton
