import React from "react"
import { components } from "react-select"
import { arrayOf, shape, string, number } from "prop-types"

import Tag from "src/styles/components/Tag"
import { optionType } from "src/constants/propTypes"

const SingleValue = ({ children, ...props }) => {
  const tagOption =
    props.options.find(({ value }) => value === props.data.value) || props.data

  return (
    <components.SingleValue {...props}>
      <input
        hidden
        name="meeting_pack[tag][name]"
        value={tagOption.label}
        onChange={() => {}}
      />
      <input
        hidden
        name="meeting_pack[tag][color_id]"
        value={tagOption.colorId}
        onChange={() => {}}
      />
      <Tag color={tagOption.colorId}>{tagOption.label}</Tag>
    </components.SingleValue>
  )
}

SingleValue.propTypes = {
  data: shape({
    label: string.isRequired,
    colorId: number
  }).isRequired,
  options: arrayOf(optionType)
}

export default SingleValue
