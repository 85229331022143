import React, { useState } from "react"
import { arrayOf, number, shape, string } from "prop-types"
import { orderBy } from "lodash"

import ContactDetailsModal from "src/components/UsersContacts/ContactDetailsModal"
import UserAvatar from "src/components/UsersContacts/UserAvatar"
import useIsOpened from "src/hooks/useIsOpened"
import UserBadge from "src/components/UsersContacts/UserBadge"
import UserAvatarContainer from "src/components/UsersContacts/UserAvatarContainer"

import { UserCardContainer } from "./styles"

const FIELD_SIGNS = {
  removed: "-",
  added: "+"
}

const UsersList = ({ currentUserId, users = [], field }) => {
  const [openedUser, setOpenedUser] = useState(users[0])
  const { isOpened, open, close } = useIsOpened()
  const handleOpen = (user) => {
    setOpenedUser(user)
    open()
  }
  const orderedUsers = orderBy(users, [field, "fullName"], ["desc", "asc"])

  return (
    <>
      <ContactDetailsModal
        size="sm"
        isOpened={isOpened}
        close={close}
        user={openedUser}
        isCurrentUser={currentUserId === openedUser?.id}
        type="Contact"
      />
      {orderedUsers.map((user) => (
        <UserCardContainer key={user.id} md={4} onClick={() => handleOpen(user)}>
          <UserAvatarContainer>
            {user[field] && <UserBadge variant={field}>{FIELD_SIGNS[field]}</UserBadge>}
            <UserAvatar variant={user[field] ? field : ""} user={user} />
          </UserAvatarContainer>
          <span className="ml-1">
            {user.fullName}
            {currentUserId === user.id ? <span> (you)</span> : null}
          </span>
        </UserCardContainer>
      ))}
    </>
  )
}

UsersList.propTypes = {
  currentUserId: number.isRequired,
  users: arrayOf(
    shape({
      id: number
    })
  ).isRequired,
  field: string.isRequired
}

export default UsersList
