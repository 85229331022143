import LayoutHandler from "src/page_handlers/layout_handler"

iNoBounce.enable()

const layoutHandlerSetup = function layoutHandlerSetup() {
  if (!$("body").data("skip-layout-handler")) {
    window.layoutHandler = new LayoutHandler()
    return window.layoutHandler.setup()
  }
}

const layoutHandlerTeardown = function layoutHandlerTeardown() {
  if (!window.layoutHandler) {
    return
  }
  return window.layoutHandler.teardown()
}

const autoHideServerFlashMessage = function autoHideServerFlashMessage() {
  if (!$("body").data("persist-flash-message")) {
    const flashMessage = $be("flash-message")
    return $delayedRemove(flashMessage)
  }
}

const showClientFlashMessage = function showClientFlashMessage() {
  const flashJson = sessionStorage.getItem("clientFlashMessage")
  if (!flashJson) {
    return
  }
  const clientFlashMessage = JSON.parse(flashJson)
  const flashMessage = $generateFlashMessage(
    clientFlashMessage.level,
    clientFlashMessage.text
  )
  $delayedRemove(flashMessage)
  return sessionStorage.removeItem("clientFlashMessage")
}

document.addEventListener("turbo:load", layoutHandlerSetup)
document.addEventListener("turbo:load", autoHideServerFlashMessage)
document.addEventListener("turbo:load", showClientFlashMessage)

document.addEventListener("turbo:before-render", layoutHandlerTeardown)
