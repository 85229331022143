import { avatarsColorsPool } from "src/styles/colors"

const annotationAuthorAvatarRenderer = (user) => {
  const element = document.createElement("div")
  element.className = "custom-avatar"

  if (!user) return element

  const { avatarColorId, avatarUrl, initials } = user

  if (avatarColorId) {
    element.style.backgroundColor = avatarsColorsPool[avatarColorId]
  }

  if (avatarUrl) {
    element.style.backgroundImage = `url(${avatarUrl})`
  } else if (initials) {
    element.innerHTML = `<span class="initials">${initials}</span>`
  }

  return element
}

export default annotationAuthorAvatarRenderer
