import * as Sentry from "@sentry/browser"

import { showFlashMessage } from "src/helpers/flash"

const showCaughtErrorMessage = (
  apiError,
  defaultMessage = "Oops, it looks like something went wrong."
) => {
  const data = apiError.response?.data
  let errorMessage = null

  if (data?.error) {
    errorMessage = data?.error
  }

  if (data?.errors) {
    errorMessage = data?.errors.join(". ")
  }

  if (errorMessage) {
    showFlashMessage("danger", errorMessage)
  } else {
    // eslint-disable-next-line no-console
    console.warn(apiError)
    Sentry.captureException(apiError)
    showFlashMessage("danger", defaultMessage)
  }
}

export { showCaughtErrorMessage }
