import React, { useCallback } from "react"
import { bool, func, number, shape, string } from "prop-types"

import TextWithTooltip from "src/styles/components/TextWithTooltip"
import UnreadBadge from "src/styles/components/UnreadBadge"
import { formatDateTime, validateDuration } from "src/helpers/datetime"
import { TIME_FORMAT } from "src/constants/datetimeFormats"
import { MEMBERSHIP_TYPES } from "src/features/meetingPacks/MeetingPack/constants"
import { getCurrentUserId } from "src/helpers/user"

import DocumentsList from "./DocumentsList"
import ContentsRow from "./ContentsRow"
import {
  PrefixCol,
  SuffixCol,
  Index,
  ItemTitle,
  ItemName,
  Duration,
  NotAttendingStatus,
  AccessOnlyStatus
} from "./styles"

const AgendaItemRow = ({ item, active = false, unread = false, timeZone, ...rest }) => {
  const currentUserId = getCurrentUserId()
  const onItemClick = useCallback(() => {
    if (item.attending) {
      rest.setActiveItem(item.id)
    }
  }, [item.id, unread])
  const startTime = formatDateTime({
    timeZone,
    datetime: item.startTime,
    format: TIME_FORMAT
  })

  const isAccessOnly =
    !rest.isCurrentUserAdmin &&
    !!item.memberships.find(
      (membership) =>
        membership.userId === currentUserId &&
        membership.membershipType === MEMBERSHIP_TYPES.accessOnly
    )
  const hasDuration =
    item.attending && !isAccessOnly && validateDuration({ duration: item.duration })

  return (
    <>
      <ContentsRow
        disabled={!item.attending}
        active={active}
        onClick={onItemClick}
        href="#"
        small={!!item.agendaItemId}
      >
        <PrefixCol>
          <Index>{item.index}</Index>
        </PrefixCol>
        <ItemTitle>
          <ItemName data-testid="sidebar-agenda-item" data-id={item.id}>
            <TextWithTooltip text={item.name} maxLength={0} />
          </ItemName>
        </ItemTitle>
        <SuffixCol>
          {hasDuration && <Duration>{startTime}</Duration>}
          {isAccessOnly && <AccessOnlyStatus>Access only</AccessOnlyStatus>}
          {!item.attending && <NotAttendingStatus>Not attending</NotAttendingStatus>}
          {unread && <UnreadBadge />}
        </SuffixCol>
      </ContentsRow>
      {item.attending && <DocumentsList documents={item.documents} />}
    </>
  )
}

AgendaItemRow.propTypes = {
  setActiveItem: func.isRequired,
  item: shape({
    id: number.isRequired,
    name: string.isRequired,
    startTime: string,
    break: bool,
    index: string,
    duration: number
  }).isRequired,
  active: bool,
  unread: bool,
  timeZone: string
}

export default AgendaItemRow
