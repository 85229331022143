import React, { useRef, useState, useCallback } from "react"
import { useMutation } from "@apollo/client"
import { arrayOf, func, string } from "prop-types"
import { pick } from "lodash"
import { useDebounce } from "use-debounce"

import JumpToPanel from "src/features/Search/JumpToPanel"
import Icon from "src/styles/components/Icon"
import useSuggestions from "src/features/Search/SearchPage/Header/SearchInput/useSuggestions"
import useToggle from "src/hooks/useToggle"
import saveJumpToMutation from "src/features/Search/JumpToPanel/Suggestions/saveJumpTo.gql"
import { ClearSearch } from "src/features/Search/SearchPage/Header/SearchInput/styles"
import { buildSearchQueryPath, goTo } from "src/helpers/url"
import { SUGGESTIONS } from "src/features/Search/constants"
import { KEY_CODES } from "src/constants"

import useOutsideClick from "./useOutsideClick"
import { InputContainer } from "./styles"

const jumpToKeys = ["type", "title", "path", "parent"]

const SearchInput = ({ close, whereIds, whereFilter }) => {
  const outsideClickRef = useRef()
  const inputRef = useRef()
  useOutsideClick({ ref: outsideClickRef, onClick: close })

  const [saveJumpTo] = useMutation(saveJumpToMutation)
  const [jumpToQuery, setJumpToQuery] = useState()
  const [debouncedJumpToQuery] = useDebounce(jumpToQuery, 300)
  const [whereFilterChecked, toggleWhereFilterChecked] = useToggle("whereFilterChecked")

  const setJumpToQueryOnChange = (e) => setJumpToQuery(e.target.value)
  const { suggestions, isSearchActive, activeSuggestion, handleKeyboardNavigation } =
    useSuggestions(debouncedJumpToQuery)

  const handleSubmit = useCallback(() => {
    const searchObject = { query: jumpToQuery }
    if (whereFilterChecked) searchObject.whereIds = whereIds

    goTo(buildSearchQueryPath(searchObject))
  }, [jumpToQuery, whereFilterChecked])

  const handleEnter = (e) => {
    if (e.charCode !== KEY_CODES.enter) return
    e.preventDefault()

    if (activeSuggestion) {
      if (activeSuggestion.type !== SUGGESTIONS.search) {
        saveJumpTo({ variables: pick(activeSuggestion, jumpToKeys) })
      }
      goTo(activeSuggestion.path)
    } else {
      handleSubmit()
    }
  }

  const handleWhereFilterChange = useCallback(
    (e) => {
      inputRef.current.focus()
      toggleWhereFilterChecked(e.target.checked)
    },
    [inputRef.current]
  )

  return (
    <InputContainer ref={outsideClickRef}>
      <input
        ref={inputRef}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        autoComplete="off"
        type="search"
        name="search[text]"
        className="form-field"
        placeholder="Type in text to search..."
        value={jumpToQuery || ""}
        onChange={setJumpToQueryOnChange}
        onKeyPress={handleEnter}
        onKeyDown={handleKeyboardNavigation}
        data-testid="search-input"
      />

      <Icon type="search" />

      <ClearSearch onClick={close}>
        <Icon type="cancel" />
      </ClearSearch>

      <JumpToPanel
        isSearchActive={isSearchActive}
        suggestions={suggestions}
        searchText={jumpToQuery}
        onSubmit={handleSubmit}
        whereFilter={whereFilter}
        whereFilterChecked={whereFilterChecked}
        onWhereFilterChange={handleWhereFilterChange}
      />
    </InputContainer>
  )
}

SearchInput.propTypes = {
  close: func.isRequired,
  whereIds: arrayOf(string),
  whereFilter: string
}

export default SearchInput
