import React, { useState } from "react"
import { func } from "prop-types"

import UploadModalContent from "src/components/FilesUpload/ModalContent"

const UploadSection = ({ close, uploadDocument, buildForm, setAttachedDocument }) => {
  const [selectedFile, setSelectedFile] = useState(null)
  const [isUploading, setIsUploading] = useState(false)

  const selectedFiles = selectedFile ? [{ isUploading, file: selectedFile }] : []
  const setSelectedFiles = (files) => {
    setSelectedFile(files[0])
  }

  const uploadDocuments = () => {
    setIsUploading(true)

    uploadDocument({ file: selectedFile })
      .then(({ data }) => {
        setIsUploading(false)
        setAttachedDocument({ ...data, name: selectedFile.name })
        buildForm()
        close()
      })
      .catch(() => {
        setIsUploading(false)
      })
  }

  return (
    <UploadModalContent
      selectedFiles={selectedFiles}
      close={close}
      uploadFiles={uploadDocuments}
      isUploading={isUploading}
      setSelectedFiles={setSelectedFiles}
      multiple
      allowedFiles="documents"
    />
  )
}

UploadSection.propTypes = {
  close: func.isRequired,
  uploadDocument: func.isRequired,
  buildForm: func.isRequired,
  setAttachedDocument: func.isRequired
}

export default UploadSection
