import { omit, size } from "lodash"
import { useMutation } from "@apollo/client"
import update from "immutability-helper"

import { withoutAttributes } from "src/helpers/array"

import updateTranscriptMutation from "./updateTranscript.gql"

const useUpdateTranscript = ({
  transcriptId,
  transcriptItems,
  setTranscriptItems,
  saveToHistory
}) => {
  const [updateTranscript] = useMutation(updateTranscriptMutation)

  const calculateAgendaItemsPosition = (allItems) => {
    const utterances = []
    const agendaItems = []

    allItems.forEach((item) => {
      if (item.uid) utterances.push(item)
      else agendaItems.push({ ...item, utterancesListIndex: utterances.length })
    })

    return agendaItems
  }

  const handleUpdateTranscript = (items) =>
    updateTranscript({
      variables: {
        transcriptId,
        utterances: withoutAttributes(
          items.filter((item) => item.uid),
          ["__typename"]
        ),
        assignedAgendaItems: withoutAttributes(calculateAgendaItemsPosition(items), [
          "__typename",
          "index",
          "initialIndex",
          "agendaItemsListIndex",
          "name"
        ])
      }
    })

  const handleSave = (items) => {
    saveToHistory(items)

    return handleUpdateTranscript(items)
  }

  const restoreState = (items) => {
    setTranscriptItems(items)

    return handleUpdateTranscript(items)
  }

  const addNewUtterance = (prevItems, utteranceData, newIndex) =>
    prevItems.toSpliced(newIndex, 0, utteranceData)

  const updateUtterance = (prevItems, utteranceData) =>
    prevItems.map((u) => {
      const needToChange = utteranceData.uid
        ? u.uid === utteranceData.uid
        : u.speaker === utteranceData.speaker

      return needToChange ? { ...u, ...utteranceData } : u
    })

  const removeUtterance = (prevItems, utteranceUid) =>
    prevItems.filter((u) => u.uid !== utteranceUid)

  const handleSaveUtterances = (utterancesData) => {
    let result = [...transcriptItems]

    utterancesData.forEach((utteranceData) => {
      const cleanedUtteranceData = omit(utteranceData, ["index"])

      if (utteranceData.index)
        result = addNewUtterance(result, cleanedUtteranceData, utteranceData.index)
      else if (size(omit(cleanedUtteranceData, ["uid"])))
        result = updateUtterance(result, cleanedUtteranceData)
      else result = removeUtterance(result, utteranceData.uid)
    })

    setTranscriptItems(result)

    return handleSave(result)
  }

  const utteranceAgendaItem = (index) => {
    const agendaItemsBeforeUtterance = transcriptItems.filter(
      (item, i) => item.agendaItemId && i < index
    )
    return agendaItemsBeforeUtterance.length
      ? agendaItemsBeforeUtterance[agendaItemsBeforeUtterance.length - 1]
      : {}
  }

  const handleAssignAgendaItem = ({ utterance, index, agendaItemId }) => {
    if (utteranceAgendaItem(index).agendaItemId === agendaItemId) return Promise.resolve()

    const agendaItemIndex = transcriptItems.findIndex(
      (item) => item.agendaItemId === agendaItemId
    )
    const nextAgendaItemIndex = transcriptItems
      .slice(agendaItemIndex + 1)
      .findIndex((item) => item.agendaItemId)
    const lastElementIndex =
      nextAgendaItemIndex >= 0
        ? agendaItemIndex + nextAgendaItemIndex
        : transcriptItems.length - 1

    if (index === lastElementIndex) return Promise.resolve()

    const result = update(transcriptItems, {
      $splice: [
        [index, 1],
        [index > lastElementIndex ? lastElementIndex + 1 : lastElementIndex, 0, utterance]
      ]
    })

    setTranscriptItems(result)

    return handleSave(result)
  }

  return {
    handleSave,
    restoreState,
    handleSaveUtterances,
    handleAssignAgendaItem
  }
}

export default useUpdateTranscript
