import { styled } from "@linaria/react"

import Icon from "src/styles/components/Icon"
import UnstyledButton from "src/styles/components/Button/Unstyled"
import { TEXT_COLORS } from "src/styles/colors"
import { BREAKPOINTS, SPACES } from "src/styles/sizes"

export const AppliedFiltersCount = styled.span`
  font-weight: bold;
  color: #46495a;
`

export const MobileFilterToggleButton = styled(UnstyledButton)`
  display: none;
  color: ${TEXT_COLORS.muted};
  margin: 0 auto ${SPACES.small};

  ${Icon} {
    transform: rotate(180deg);
  }

  &.filter-is-shown {
    ${Icon} {
      transform: rotate(0deg);
    }
  }

  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    display: flex;
    align-items: center;
  }
`
