import { useLazyQuery } from "@apollo/client"

import uploadUrlsQuery from "./uploadUrlsQuery.gql"

const useRequestUploadUrls = () => {
  const [executeRequest] = useLazyQuery(uploadUrlsQuery)

  const requestUploadUrls = ({ groupId, uploadFolder }) => {
    const files = uploadFolder.allFiles()
    const filesParams = files.map((file) => ({
      name: file.name,
      path: file.path
    }))

    return executeRequest({ variables: { groupId, files: filesParams } }).then(
      (response) => response.data?.groupUploadUrls || []
    )
  }

  return requestUploadUrls
}

export default useRequestUploadUrls
