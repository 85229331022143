import { styled } from "@linaria/react"

import UnstyledButton from "src/components/UnstyledButton"
import { BREAKPOINTS } from "src/styles/sizes"

export const Container = styled.div`
  padding: 20px;
  min-height: 300px;
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: ${BREAKPOINTS.small}) {
    padding-top: 10px;
    gap: 5px;
  }
`

export const buttonStyles = {
  fontSize: "16px",
  height: "40px",
  padding: "10px",
  lineHeight: 1.2,
  borderRadius: "5px",
  boxSizing: "border-box",
  "&:hover": {
    backgroundColor: "#dcdfe3"
  }
}

export const Button = styled(UnstyledButton)`
  ${buttonStyles}
`

export const Skeleton = styled.div`
  display: inline-block;
  width: 100%;
  background-color: rgb(209 213 219 / 1);
  height: 16px;
  border-radius: 4px;
  flex-shrink: 0;
  user-select: none;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

  @keyframes pulse {
    50% {
      opacity: 0.5;
    }
  }
`
