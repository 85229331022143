import { connect } from "react-redux"

import { getMeetingPack } from "src/features/meetingPacks/MeetingPack/selectors"

import ContentContainer from "./ContentContainer"

const mapState = (state) => ({
  meetingPackId: getMeetingPack(state)?.id
})

export default connect(mapState)(ContentContainer)
