import React from "react"
import classNames from "classnames"
import { components } from "react-select"
import { arrayOf, shape, string, number } from "prop-types"

import Tag from "src/styles/components/Tag"
import BadgeIcon from "src/styles/components/BadgeIcon"
import { optionType } from "src/constants/propTypes"

const SingleValue = ({ children, ...props }) => {
  const badgeClasses = classNames({
    "scheme-badge": props.data.isScheme,
    [`badge-modifier-${props.data.colorId}`]: props.data.isScheme
  })

  const selectedOption =
    props.options.find(({ value }) => value === props.data.value) || props.data

  return (
    <components.SingleValue {...props}>
      {selectedOption.isDefault ? (
        <span className="text-nowrap">{selectedOption.label}</span>
      ) : (
        <Tag className={badgeClasses} color={selectedOption.colorId}>
          {selectedOption.label}
          {selectedOption.joint && <BadgeIcon type="connected" />}
        </Tag>
      )}
    </components.SingleValue>
  )
}

SingleValue.propTypes = {
  data: shape({
    label: string.isRequired,
    colorId: number
  }).isRequired,
  options: arrayOf(optionType)
}

export default SingleValue
