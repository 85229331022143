/* eslint-disable react/no-danger */
import React, { useEffect, useRef, useState } from "react"
import { arrayOf, shape, number, string } from "prop-types"

import { formatDateTime, formatDuration, validateDuration } from "src/helpers/datetime"
import { TIME_FORMAT } from "src/constants/datetimeFormats"
import useScrollOnTouchDevices from "src/hooks/useScrollOnTouchDevices"
import { highlight } from "src/helpers/string"
import { fullscreenButtonClass } from "src/components/pspsdfkitButtons/FullscreenButton/constants"
import {
  getIsFullscreen,
  toggleFullscreen,
  createFullscreenButton
} from "src/components/pspsdfkitButtons/FullscreenButton/helpers"

import { AgendaItemCard, CardTitle, CardSubtitle, FullscreenButton } from "./styles"
import Attendees from "./Attendees"

const AgendaItemSummary = ({ meetingPack, item, highlighted }) => {
  const agendaItemCardRef = useScrollOnTouchDevices()

  if (!item?.attending) {
    return null
  }

  const startTime = formatDateTime({
    datetime: item.startTime,
    timeZone: meetingPack.timeZone,
    format: TIME_FORMAT
  })
  const hasDuration = validateDuration({ duration: item.duration })
  const duration = hasDuration ? formatDuration({ duration: item.duration }) : null

  const fullscreenButtonRef = useRef()
  const [isFullscreen, setFullscreen] = useState(getIsFullscreen())

  useEffect(() => {
    if (!fullscreenButtonRef.current) return

    fullscreenButtonRef.current.appendChild(createFullscreenButton())
  }, [fullscreenButtonRef])

  const disableFullscreen = () => setFullscreen(toggleFullscreen())

  return (
    <AgendaItemCard body ref={agendaItemCardRef}>
      {isFullscreen && (
        <FullscreenButton
          ref={fullscreenButtonRef}
          className={fullscreenButtonClass}
          onClick={disableFullscreen}
        />
      )}
      <CardTitle>
        {item.index}&nbsp;&nbsp;&nbsp;{item.name}
      </CardTitle>
      {hasDuration && (
        <CardSubtitle>
          Starting at <span className="font-weight-500">{startTime}</span>
          {" | "}
          Duration <span className="font-weight-500">{duration}</span>
        </CardSubtitle>
      )}
      <div
        dangerouslySetInnerHTML={{
          __html: highlight({
            text: item.description,
            textToHighlight: highlighted,
            openingTag: '<span class="search-results-highlight">',
            closingTag: "</span>"
          })
        }}
        className="fr-view"
      />

      <Attendees />
    </AgendaItemCard>
  )
}

AgendaItemSummary.propTypes = {
  item: shape({
    id: number.isRequired,
    index: string,
    name: string,
    memberships: arrayOf(
      shape({
        userId: number.isRequired
      })
    ).isRequired
  }),
  meetingPack: shape({
    timeZone: string
  }).isRequired,
  highlighted: string
}

export default AgendaItemSummary
