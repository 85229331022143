import { bool, number, shape, string } from "prop-types"

const Document = shape({
  id: number.isRequired,
  filename: string.isRequired,
  boxFileId: string,
  contentPresent: bool
}).isRequired

export { Document }
