import React, { useEffect, useRef, useState, useLayoutEffect } from "react"
import { number, string } from "prop-types"

import ContentLoader from "src/styles/components/ContentLoader"
import useAiAskQuestion from "src/hooks/ai/useAskQuestion"
import { useCommandResult } from "src/features/UniversalAi/store"
import {
  SIDEBAR_FULLSCREEN_BREAKPOINT,
  OBJECT_TYPES
} from "src/features/UniversalAi/constants"
import useUniversalAi from "src/features/UniversalAi/useUniversalAi"

import { Base, Footer, Content, Container } from "../Base"
import scrollPositionHandler from "../scrollPositionHandler"

import { ContainerClass } from "./styles"
import PromptBox from "./PromptBox"

const OBJECT_TYPE_TO_TITLE = {
  [OBJECT_TYPES.message]: "Message",
  [OBJECT_TYPES.attachedDocument]: "Attached Document",
  [OBJECT_TYPES.discussion]: "Discussion",
  [OBJECT_TYPES.agendaItemDocument]: "Agenda Item Document",
  [OBJECT_TYPES.meetingPack]: "Meeting Pack",
  [OBJECT_TYPES.transcript]: "Meeting Pack Transcript"
}

const Query = ({ objectId, objectType, objectTitle }) => {
  const { close } = useUniversalAi()
  const contentRef = useRef()

  useEffect(() => {
    if (!contentRef.current) return

    const handleClick = (e) => {
      if (window.innerWidth > SIDEBAR_FULLSCREEN_BREAKPOINT) return
      if (e.target?.tagName !== "A") return

      close()
    }

    contentRef.current.addEventListener("click", handleClick)

    return () => contentRef.current.removeEventListener("click", handleClick)
  }, [!!contentRef.current])

  const { answer, onSubmit, loading, finished, prompt } = useAiAskQuestion({
    objectId,
    objectType,
    contentElementFindFunc: () => contentRef.current
  })

  // This is workaround to avoid blinking content of AI sidebar when navigating
  // from page to page. Hopefully, we'll remove this after we rewrite whole app
  // into React
  const { commandResult, setCommandResult } = useCommandResult()
  const [commandLoading, setCommandLoading] = useState(false)
  const [initialized, setInitialized] = useState(
    commandResult?.prompt && commandResult?.answer
  )

  const commandPrompt = initialized
    ? commandResult?.prompt
    : commandResult?.prompt || prompt

  const commandAnswer = initialized
    ? commandResult?.answer
    : commandResult?.answer || answer

  useEffect(() => {
    if (commandLoading) {
      setCommandResult({ prompt, answer })
    }

    if (!finished) return

    setCommandResult({ prompt, answer })
    setCommandLoading(false)
    setInitialized(true)
  }, [finished, prompt, answer])

  // For unknown reason, we can't extract it into custom hook,
  // e.g. useSavedScrollPosition, we have to call useLayoutEffect directly,
  // otherwise scrollbar "flashes" when scroll position is restored.
  useLayoutEffect(
    () =>
      scrollPositionHandler({
        element: contentRef.current,
        key: `query/${objectType}/${objectId}`,
        restorePosition: initialized
      }),
    [!!contentRef.current, initialized, objectType, objectId]
  )

  const title = `Query ${objectTitle || OBJECT_TYPE_TO_TITLE[objectType] || objectType}`

  const handlePromptSubmit = (submittedPrompt) => {
    if (!submittedPrompt) return

    setCommandLoading(true)
    setCommandResult({ prompt: submittedPrompt, answer: null })
    onSubmit({ prompt: submittedPrompt })
  }

  const handlePromptChange = (newPrompt) => {
    setCommandResult({ ...commandResult, prompt: newPrompt })
  }

  const commandAnswerWithReferences = commandAnswer?.replaceAll(
    "***",
    "<span class='icon-quote' />"
  )

  return (
    <Base title={title}>
      <Container className={ContainerClass}>
        <PromptBox
          onSubmit={handlePromptSubmit}
          prompt={commandPrompt}
          onChange={handlePromptChange}
        />
        <Content ref={contentRef}>
          {initialized && loading && <ContentLoader />}
          {!loading && (
            /* eslint-disable-next-line react/no-danger */
            <div dangerouslySetInnerHTML={{ __html: commandAnswerWithReferences }} />
          )}
        </Content>
      </Container>

      <Footer
        loading={commandLoading}
        content={commandAnswerWithReferences}
        contentRef={contentRef}
      />
    </Base>
  )
}

Query.propTypes = {
  objectId: number.isRequired,
  objectType: string.isRequired,
  objectTitle: string
}

export default Query
