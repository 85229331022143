import { useApolloClient } from "@apollo/client"
import { useCallback, useEffect, useState } from "react"

import whoFilterQuery from "./whoFilter.gql"

const normalizeUserIds = (userIds) =>
  userIds ? Object.values(userIds).map((id) => Number(id)) : []

export default function useWhoFilter(defaults) {
  const userIds = normalizeUserIds(defaults.userIds)
  const apolloClient = useApolloClient()

  const [whoFilterState, setWhoFilterState] = useState({
    options: [],
    whoFilter: userIds
  })

  const addWhoFilterValue = useCallback(
    (newValues) => {
      setWhoFilterState((currentState) => {
        const updatedWhoFilter = newValues.map(({ value }) => value)

        return {
          ...currentState,
          whoFilter: updatedWhoFilter
        }
      })
    },
    [setWhoFilterState]
  )

  const removeWhoFilterValue = useCallback(
    (removedValue) => {
      setWhoFilterState((currentState) => {
        const updatedWhoFilter = currentState.whoFilter.filter(
          (value) => value !== removedValue.value
        )

        return {
          ...currentState,
          whoFilter: updatedWhoFilter
        }
      })
    },
    [setWhoFilterState]
  )

  useEffect(() => {
    apolloClient.query({ query: whoFilterQuery }).then(({ data }) => {
      if (!data?.whoFilter?.length) {
        return
      }

      const options = data.whoFilter.map(({ id, fullName }) => ({
        value: id,
        label: fullName
      }))

      setWhoFilterState((state) => ({
        ...state,
        options
      }))
    })
  }, [])

  return {
    whoFilter: whoFilterState.whoFilter,
    whoFilterOptions: whoFilterState.options,
    addWhoFilterValue,
    removeWhoFilterValue
  }
}
