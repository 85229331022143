import { styled } from "@linaria/react"

import { SPACES } from "src/styles/sizes"

export const Logo = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  width: 32px;
  height: 32px;
`

export const Title = styled.div`
  margin-bottom: 24px;
`

export const Card = styled.div`
  position: relative;
  padding: 1.5rem;
  background: #fff;
  border: 3px solid #4a2b83;
  border-radius: 8px;
  min-height: 78px;
`

export const Container = styled.div`
  padding: 0 ${SPACES.medium} 0 ${SPACES.medium};
  margin-bottom: 32px;
`
export const DisplayedText = styled.span`
  *:last-child {
    margin-bottom: 0;
  }
`
