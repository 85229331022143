import { createContext } from "react"

import { ACTION_TYPES } from "./constants"

const ModalContext = createContext({
  message: { actionType: ACTION_TYPES.voting, id: 0 },
  openMainModal: () => {},
  closeMainModal: () => {},
  openContactDetailsModal: () => {},
  closeContactDetailsModal: () => {},
  openCloseActionModal: () => {},
  closeCloseActionModal: () => {},
  closeAllModals: () => {}
})
export default ModalContext
