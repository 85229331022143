import React from "react"
import { shape, string, number } from "prop-types"

import Icon from "src/styles/components/Icon"

import MetadataLink from "../MedatataLink"
import { DrawerItem, DrawerItemWrapper, EntityName } from "../styles"

const EntityRow = ({ forwardingEmail }) => {
  const authorsName = forwardingEmail.author.fullName
  const messageSubject = forwardingEmail.forwardingEmailSubject || "[No Subject]"
  const { threadId, id } = forwardingEmail

  return (
    <DrawerItem data-id={id}>
      <DrawerItemWrapper className="text-link">
        {/* eslint-disable jsx-a11y/control-has-associated-label */}
        <a href={`/discussions/${threadId}?message_id=${id}`} rel="noreferrer">
          <Icon type="email" size="medium" className="mr-2" />
        </a>
        {/* eslint-enable jsx-a11y/control-has-associated-label */}
        <div>
          <a
            href={`/discussions/${threadId}?message_id=${id}`}
            rel="noreferrer"
            className="entity-name"
          >
            <EntityName>{messageSubject}</EntityName>
          </a>
          <MetadataLink
            href={`/discussions/${threadId}?message_id=${id}`}
            createdAt={forwardingEmail.createdAt}
            authorsName={authorsName}
          />
        </div>
      </DrawerItemWrapper>
    </DrawerItem>
  )
}

EntityRow.propTypes = {
  forwardingEmail: shape({
    id: number.isRequired,
    forwardingEmailSubject: string,
    threadId: number.isRequired,
    author: shape({
      fullName: string.isRequired
    }).isRequired,
    createdAt: string.isRequired
  }).isRequired
}

export default EntityRow
