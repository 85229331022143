import React from "react"
import { arrayOf, bool, func } from "prop-types"
import { styled } from "@linaria/react"

import { airtableFilterType } from "src/resources/accounts/airtablePropTypes"
import { BASE_COLORS } from "src/styles/colors"

import Row from "./Row"
import { DEFAULT_FILTER_TYPE } from "./constants"

const FiltersList = ({
  filters,
  setFilters,
  onlyPlainTextFields,
  extendedFiltersList,
  className
}) => {
  const addFilter = () =>
    setFilters((prevFilters) => [
      ...prevFilters,
      { fieldId: "", type: DEFAULT_FILTER_TYPE, value: "" }
    ])

  /* eslint-disable react/no-array-index-key */
  return (
    <div className={className}>
      {filters.map((filter, index) => (
        <Row
          key={index}
          filter={filter}
          setFilters={setFilters}
          onlyPlainTextFields={onlyPlainTextFields}
          extendedFiltersList={extendedFiltersList}
        />
      ))}

      <button type="button" className="btn btn-link" onClick={addFilter}>
        + Add condition
      </button>
    </div>
  )
  /* eslint-enable react/no-array-index-key */
}

FiltersList.propTypes = {
  filters: arrayOf(airtableFilterType),
  setFilters: func.isRequired,
  onlyPlainTextFields: bool,
  extendedFiltersList: bool
}

export default styled(FiltersList)`
  .btn-link {
    border: none;
    color: ${BASE_COLORS.darkViolet};
    padding: 0;
    margin: 0;

    &.active,
    &:hover,
    &:focus {
      background: none;
      box-shadow: none;
    }
  }
`
