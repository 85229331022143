import { createSlice } from "@reduxjs/toolkit"

import * as reducers from "./reducers"
import initialState from "./initialState"

const { actions, reducer } = createSlice({
  name: "universalAi",
  initialState,
  reducers
})

export { actions }
export default reducer
