class @DateTimeZonePickerComponent
  constructor: ->
    @dateTimeZone = $be('date-time-zone')
    @dateTimePicker = $be('date-time-picker')

  setup: =>
    @setupDateTimeZone()
    @setupDateTimePicker()

  setupDateTimeZone: =>
    currentTimeZone = moment.tz.guess()
    @dateTimeZone.val(currentTimeZone) unless @dateTimeZone.val()
    @dateTimeZone.change(@setupDateTimePicker)
    @dateTimePicker.change(@handleDateTimeChange)

  setupDateTimePicker: =>
    @dateTimePicker.flatpickr(
      disableMobile: true,
      enableTime: true,
      altInput: true,
      altFormat: "DD-MM-YYYY hh:mm A",
      dateFormat: moment.defaultFormat,
      locale: { firstDayOfWeek: 1 }, # Monday
      parseDate: @parseDate,
      formatDate: @formatDate
    )

  defaultDate: =>
    @dateTimePicker.val() || @parseDate('9:00', 'H:mm')

  parseDate: (dateString, format) =>
    tzDate = moment.tz(dateString, format, @dateTimeZone.val())

    return new Date(
      tzDate.year(),
      tzDate.month(),
      tzDate.date(),
      tzDate.hour(),
      tzDate.minute(),
      tzDate.second()
    )

  formatDate: (date, format) =>
    return moment.tz([
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    ], @dateTimeZone.val()).format(format)

  handleDateTimeChange: (event) ->
    value = event.currentTarget.value
    dateTimePickerInput = $('.date-time-picker')

    if value
      dateTimePickerInput.removeClass('is-invalid')
    else
      dateTimePickerInput.addClass('is-invalid')
