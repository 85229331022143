import React, { useCallback, useEffect, useMemo, useState } from "react"
import { arrayOf, number, shape, string } from "prop-types"

import AttachedDocuments from "src/components/AttachedDocuments"
import * as pspdfkitDocumentsApi from "src/api/pspdfkitDocuments"
import { MESSAGE_FORM_CLEARED } from "src/constants/events"
import WarningText from "src/styles/components/WarningText"

import SignedDocumentsUploadModal from "./SignedDocumentsUploadModal"
import DocumentFormBuilderModal from "./DocumentFormBuilderModal"
import AssignedUsers from "./AssignedUsers"

const SignedDocuments = ({ groupMembers }) => {
  const [attachedDocument, setAttachedDocument] = useState(null)
  const [formIsBuilding, setFormIsBuilding] = useState(false)
  const [assignedUsersIds, setAssignedUsersIds] = useState([])

  const resetDocument = useCallback(() => setAttachedDocument(null), [])
  const attachedDocuments = useMemo(
    () => (attachedDocument ? [attachedDocument] : []),
    [attachedDocument]
  )

  const assignedUsers = useMemo(
    () => groupMembers.filter((member) => assignedUsersIds.includes(member.id)),
    [groupMembers, assignedUsersIds]
  )

  const buildForm = () => setFormIsBuilding(true)
  const finishFormBuilding = () => setFormIsBuilding(false)

  useEffect(() => {
    window.addEventListener(MESSAGE_FORM_CLEARED, resetDocument)
  }, [])

  const uploadDocument = (document) => {
    return pspdfkitDocumentsApi.upload({ file: document.file })
  }

  const detachDocument = () => {
    if (!attachedDocument) {
      return Promise.resolve()
    }

    return pspdfkitDocumentsApi
      .destroy({ documentId: attachedDocument.id })
      .then(resetDocument)
  }

  return (
    <>
      <SignedDocumentsUploadModal
        uploadDocument={uploadDocument}
        setAttachedDocument={setAttachedDocument}
        buildForm={buildForm}
      />
      {attachedDocument && (
        <>
          <DocumentFormBuilderModal
            id={attachedDocument?.id}
            name={attachedDocument?.name}
            jwt={attachedDocument?.jwt}
            isOpened={formIsBuilding}
            groupMembers={groupMembers}
            finishFormBuilding={finishFormBuilding}
            setAssignedUsersIds={setAssignedUsersIds}
          />
          <AssignedUsers assignedUsers={assignedUsers} />
          {attachedDocuments.length > 0 && assignedUsers.length === 0 && (
            <WarningText
              component="div"
              className="pb-2"
              variant="danger"
              text="You must assign a signature field"
            />
          )}
          <AttachedDocuments
            inputNamePrefix="message[signed_documents]"
            iconType="sign"
            attachedDocuments={attachedDocuments}
            detachDocument={detachDocument}
            onCardClick={buildForm}
          />
        </>
      )}
    </>
  )
}

SignedDocuments.propTypes = {
  groupMembers: arrayOf(
    shape({
      id: number.isRequired,
      fullName: string.isRequired
    })
  )
}

export default SignedDocuments
