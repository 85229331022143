import { createSelector } from "@reduxjs/toolkit"
import { sortBy } from "lodash"

import { getSchemeOption } from "./helpers"

export const getSchemesAllIds = (state) => state.schemes.allIds
export const getSchemesById = (state) => state.schemes.byId

export const selectSchemeOptions = createSelector(
  [getSchemesAllIds, getSchemesById],
  (allIds, byId) =>
    Object.values(
      sortBy(
        allIds.map((id) => getSchemeOption(byId[id])),
        "label"
      )
    )
)
