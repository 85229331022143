export const digitsOnly = (value) => /^\d+$/.test(value)

export const countDown = (seconds, callback, finishCallback) => {
  let timer = seconds
  callback(timer)

  setTimeout(() => {
    timer -= 1
    if (timer > 0) countDown(timer, callback, finishCallback)
    else if (finishCallback) finishCallback()
  }, 1000)
}
