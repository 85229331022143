import { styled } from "@linaria/react"

export const DocumentEditorContainer = styled.div`
  height: 100%;
  width: 100%;
`

export const Loading = styled(DocumentEditorContainer)`
  display: flex;
  align-items: center;
  justify-content: center;
`
