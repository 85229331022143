import React from "react"
import { bool, string } from "prop-types"
import showdown from "showdown"
import DOMPurify from "dompurify"
import { styled } from "@linaria/react"

import { FONT_SIZES, SPACES } from "src/styles/sizes"
import FieldLabel from "src/features/AirtableApp/Form/FieldLabel"
import { airtableFieldType } from "src/resources/accounts/airtablePropTypes"

const RichTextField = ({ value, field, invalid, className }) => {
  const converterToHTML = new showdown.Converter({ strikethrough: true })
  const HTMLvalue = DOMPurify.sanitize(converterToHTML.makeHtml(value || ""))

  return (
    <>
      <FieldLabel field={field} invalid={invalid} />
      {/* eslint-disable-next-line react/no-danger */}
      <div className={className} dangerouslySetInnerHTML={{ __html: HTMLvalue }} />
    </>
  )
}

RichTextField.propTypes = {
  field: airtableFieldType.isRequired,
  invalid: bool,
  value: string
}

export default styled(RichTextField)`
  font-size: ${FONT_SIZES.small};
  margin-left: ${SPACES.xSmall};
  white-space: pre-wrap;
`
