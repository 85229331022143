import React from "react"
import { number, string } from "prop-types"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

import { stopPropagation } from "src/helpers/events"
import useDownloadFileLink from "src/hooks/useDownloadFileLink"

import { StyledIcon, StyledLink } from "./styles"

const ExportAgenda = ({ meetingPackId, meetingPackName }) => {
  const { href, handleClick } = useDownloadFileLink({
    href: `/meeting_packs/${meetingPackId}/agenda`,
    fileName: `${meetingPackName} - Agenda.pdf`,
    onClick: stopPropagation
  })

  return (
    <OverlayTrigger
      delay={300}
      placement="bottom"
      overlay={<Tooltip id="export-agenda">Download Agenda</Tooltip>}
    >
      <StyledLink
        href={href}
        target="_blank"
        onClick={handleClick}
        data-turbo={false}
        data-turbolinks={false}
      >
        <StyledIcon type="agenda-download" />
      </StyledLink>
    </OverlayTrigger>
  )
}

ExportAgenda.propTypes = {
  meetingPackId: number.isRequired,
  meetingPackName: string.isRequired
}

export default ExportAgenda
