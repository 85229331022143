import React, { useContext } from "react"
import { styled } from "@linaria/react"
import { oneOf, shape, bool, objectOf, string } from "prop-types"

import { linkActiveColor, sidebarActiveColor } from "src/styles/colors"
import Icon from "src/styles/components/Icon"
import {
  VOTING_OPTIONS,
  ACTION_TYPES
} from "src/features/Messages/ActionDetails/constants"
import ModalContext from "src/features/Messages/ActionDetails/ModalContext"

import { StatusWithIcon } from "./styles"

const VotingStatus = ({ payload, actionsDisabled }) => {
  if (VOTING_OPTIONS[payload]) {
    return VOTING_OPTIONS[payload]
  }

  if (actionsDisabled) {
    return "Didn't vote"
  }

  return "Hasn't voted"
}

VotingStatus.propTypes = { payload: string, actionsDisabled: bool }

const MustReadStatus = ({ complete, actionsDisabled }) => {
  let text

  if (complete) text = "Has read"
  else if (actionsDisabled) text = "Didn't read"
  else text = "Hasn't read"

  return (
    <StatusWithIcon>
      <Icon type={complete ? "eye" : "eye-crossed"} />
      {text}
    </StatusWithIcon>
  )
}

MustReadStatus.propTypes = { complete: bool.isRequired, actionsDisabled: bool }

const ActionStatus = ({ action, style, className }) => {
  const {
    message: { actionsDisabled }
  } = useContext(ModalContext)

  return (
    <div data-testid="action-status" className={className} style={style}>
      {action.type === ACTION_TYPES.voting ? (
        <VotingStatus payload={action.payload} actionsDisabled={actionsDisabled} />
      ) : (
        <MustReadStatus complete={action.complete} actionsDisabled={actionsDisabled} />
      )}
    </div>
  )
}

ActionStatus.propTypes = {
  action: shape({
    type: oneOf(Object.keys(ACTION_TYPES)).isRequired,
    complete: bool.isRequired
  }),
  className: string.isRequired,
  style: objectOf(string)
}

export default styled(ActionStatus)`
  margin-left: 0.5rem;
  font-size: 1rem;
  flex-shrink: 0;
  color: ${({ action }) => (action.complete ? linkActiveColor : sidebarActiveColor)};
`
