export default class TwoFactorService {
  sendNumber(number) {
    return $.post("/users/profile/two_factor/number_submission", { number })
  }

  sendCode(code) {
    return $.post("/users/profile/two_factor/code_verification", { code })
  }

  disable() {
    return $.post("/users/profile/two_factor/disabling")
  }
}
