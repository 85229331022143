class @GroupMemberListComponent
  constructor: (params) ->
    @listContainerBehavior = params.listContainerBehavior
    @manageEnabled = params.manageEnabled
    @contactDetailsEnabled = params.contactDetailsEnabled

    @avatarListComponent = new AvatarListComponent(@listContainerBehavior)

    @userList = $be('show-members-modal-content')

    if @manageEnabled
      @groupMembershipManageComponent = new GroupMembershipManageComponent()

    groupMembershipChannel = AblyRealtimeClient.channels.get(
      "groups:membership"
    )
    @channelData = [
      {
        channel: groupMembershipChannel,
        subscriber: @handleGroupMembershipChange
      }
    ]

  setup: =>
    @avatarListComponent.setup()
    $setupChannelSubscriptions(@channelData)

    $be('show-members-link').click (e) ->
      $be('manage-members-modal').modal('hide')
      $.getScript($(this).attr('href'))

  teardown: =>
    $teardownChannelSubscriptions(@channelData)

  showList: (listContent) =>
    @userList.html(listContent)
    window.layoutHandler.setAvatarImages(@userList)
    window.layoutHandler.contactDetailsComponent.bindLinks(@userList)
    window.layoutHandler.showCurrentUserLabel(@userList)
    if @manageEnabled
      $be('manage-members-link').show()

  showManageList: (listContent) =>
    @groupMembershipManageComponent.showManageList(listContent)

  handleGroupMembershipChange: (channelMessage) =>
    groupModel = new GroupModel(channelMessage.data.group)
    listContainer = $beById(@listContainerBehavior, groupModel.id)
    return unless listContainer.length
    @avatarListComponent.update(groupModel.id, listContainer, @contactDetailsEnabled)
