import React from "react"
import { func } from "prop-types"
import { Formik } from "formik"
import { Form, InputGroup } from "react-bootstrap"

import ModalHeader from "src/styles/components/Modal/Header"
import ModalBody from "src/styles/components/Modal/Body"
import ModalFooter from "src/styles/components/Modal/Footer"
import { StyledModal } from "src/styles/components/Modal"
import Checkbox from "src/styles/components/Checkbox"
import { airtableSectionFieldType } from "src/resources/accounts/airtablePropTypes"
import FiltersList from "src/features/ManageAccount/AirtableApps/EditPage/FiltersList"

const EditFieldModal = ({ close, field, setField }) => {
  const saveAndClose = (value) => {
    setField(value)
    close()
  }

  const handleChangeFilters = (values, setFieldValue) => {
    return (setFiltersFunc) =>
      setFieldValue("filters", setFiltersFunc(values.filters || []))
  }

  return (
    <StyledModal size="lg" onHide={close} show>
      <Formik initialValues={field} onSubmit={saveAndClose} enableReinitialize>
        {({ values, handleChange, setFieldValue, handleSubmit }) => (
          <>
            <ModalHeader className="d-flex justify-content-between">
              <h5 className="flex-grow-1">{`Edit field "${field.name}"`}</h5>
            </ModalHeader>

            <ModalBody>
              <Form.Group controlId="description">
                <Form.Label>
                  <h6>Description</h6>
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    value={values.description || ""}
                    placeholder="e.g. Compliance"
                    onChange={handleChange}
                  />
                </InputGroup>
              </Form.Group>

              <Form.Group controlId="filters">
                <Form.Label>
                  <h6>Filter field based on conditions</h6>
                </Form.Label>
                <FiltersList
                  filters={values.filters || []}
                  setFilters={handleChangeFilters(values, setFieldValue)}
                  onlyPlainTextFields
                  extendedFiltersList
                />
              </Form.Group>

              <Form.Group controlId="readOnly">
                <Checkbox
                  id={`read-only-${field.id}`}
                  checked={values.readOnly || false}
                  onChange={(e) => setFieldValue("readOnly", e.target.checked)}
                >
                  <span className="pl-2">Read only</span>
                </Checkbox>
              </Form.Group>

              <Form.Group controlId="required">
                <Checkbox
                  id={`required-${field.id}`}
                  checked={values.required || false}
                  onChange={(e) => setFieldValue("required", e.target.checked)}
                >
                  <span className="pl-2">Required</span>
                </Checkbox>
              </Form.Group>
            </ModalBody>

            <ModalFooter className="d-flex">
              <div className="form-main-controls actions mtm">
                <button
                  type="submit"
                  className="button"
                  onClick={() => handleSubmit(values)}
                >
                  Apply
                </button>
                <button className="button is-secondary" onClick={close}>
                  Cancel
                </button>
              </div>
            </ModalFooter>
          </>
        )}
      </Formik>
    </StyledModal>
  )
}

EditFieldModal.propTypes = {
  close: func.isRequired,
  field: airtableSectionFieldType.isRequired,
  setField: func.isRequired
}

export default EditFieldModal
