import React, { useContext } from "react"

import ModalContext from "src/features/Messages/ActionDetails/ModalContext"
import { ACTION_TYPES } from "src/features/Messages/ActionDetails/constants"

import CloseMessageAction from "./CloseMessageAction"
import { ModalHeader, CloseButton } from "./styles"

const TITLE = {
  [ACTION_TYPES.must_read]: "Read details",
  [ACTION_TYPES.voting]: "Vote details"
}

const Header = () => {
  const { closeMainModal, message } = useContext(ModalContext)
  return (
    <ModalHeader>
      <h5 className="modal-title with-actions">
        {TITLE[message.actionType]}
        <CloseMessageAction message={message} />
      </h5>

      <CloseButton
        onClick={closeMainModal}
        aria-label="Close"
        data-dismiss="modal"
        type="button"
      >
        <span aria-hidden> &times; </span>
      </CloseButton>
    </ModalHeader>
  )
}

export default Header
