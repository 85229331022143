import React from "react"

const Intro = () => (
  <p className="text-muted">
    2-factor authentication is an extra layer of security for your Knowa account, designed
    to ensure that you are the only person who can access your account, even if someone
    finds out your password.
  </p>
)

export default Intro
