import { connect } from "react-redux"

import {
  getCurrentUser,
  getAgendaItems,
  getTeams,
  getMeetingPackAttendees
} from "src/features/meetingPacks/MeetingPack/selectors"
import { findAgendaItem } from "src/features/meetingPacks/MeetingPack/helpers"

import List from "./List"

const agendaItemAttendees = (agendaItem, meetingPackAttendees) => {
  const memberships = agendaItem?.memberships || []

  return meetingPackAttendees.reduce((itemAttendees, attendee) => {
    const agendaItemMembership = memberships.find(({ userId }) => userId === attendee.id)

    if (!agendaItemMembership) {
      return itemAttendees
    }

    return [
      ...itemAttendees,
      { ...attendee, agendaItemMembershipType: agendaItemMembership.membershipType }
    ]
  }, [])
}

const possibleAttendees = (agendaItem, agendaItems, meetingPackAttendees) => {
  const isSubItem = !!agendaItem?.agendaItemId

  if (!isSubItem) {
    return meetingPackAttendees
  }

  const parentAgendaItem = findAgendaItem(agendaItems, agendaItem.agendaItemId)

  return agendaItemAttendees(parentAgendaItem, meetingPackAttendees)
}

const mapState = (state, { agendaItemId }) => {
  const agendaItems = getAgendaItems(state)
  const agendaItem = findAgendaItem(agendaItems, agendaItemId)
  const meetingPackAttendees = getMeetingPackAttendees(state)

  return {
    agendaItem,
    possibleAttendees: possibleAttendees(agendaItem, agendaItems, meetingPackAttendees),
    currentUserId: getCurrentUser(state)?.id,
    teams: getTeams(state),
    agendaItemAttendees: agendaItemAttendees(agendaItem, meetingPackAttendees)
  }
}

export default connect(mapState)(List)
