import {
  ALLOW_ATTENDEES_TO_EXPORT_MODES,
  PUBLICATION_STATUSES
} from "src/resources/meetingPacks/constants"

const meetingPackLocationsKey = "meeting-packs-last-visited-pages"

const getAllMeetingPacksLocations = () =>
  JSON.parse(localStorage.getItem(meetingPackLocationsKey) || "{}")

const getLocationGroup = (pathname) => {
  const match = pathname.match(/view|attendees/)
  return match && match[0]
}

export const getMeetingPackLastLocation = (id) => {
  const locations = getAllMeetingPacksLocations()
  const savedLocationGroup = locations[id] ? `/${locations[id]}` : ""
  return `/meeting_packs/${id}${savedLocationGroup}`
}

export const saveMeetingPackLocation = (id) => {
  const data = getAllMeetingPacksLocations()

  localStorage.setItem(
    meetingPackLocationsKey,
    JSON.stringify({ ...data, [id]: getLocationGroup(window.location.pathname) })
  )
}

export const isUserExportEnabled = ({ meetingPack, isCurrentUserGuest }) =>
  meetingPack.publicationStatus === PUBLICATION_STATUSES.published &&
  (meetingPack.attendeesAllowedToExport === ALLOW_ATTENDEES_TO_EXPORT_MODES.all ||
    (meetingPack.attendeesAllowedToExport === ALLOW_ATTENDEES_TO_EXPORT_MODES.members &&
      !isCurrentUserGuest))
