class @SchemeMembershipService
  update: (schemeId, userId, role) ->
    $.ajax({
      url: "/spaces/#{schemeId}/memberships/#{userId}",
      type: 'PUT',
      data: { role: role }
    })

  destroy: (schemeId, userId) ->
    $.ajax({
      url: "/spaces/#{schemeId}/memberships/#{userId}",
      type: 'DELETE'
    })
