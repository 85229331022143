import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { Dropdown } from "react-bootstrap"
import { shape } from "prop-types"

import Icon from "src/styles/components/Icon"
import useIsOpened from "src/hooks/useIsOpened"
import { fetchAccounts } from "src/resources/accounts/thunks"
import { fetchSchemes } from "src/resources/schemes/thunks"
import { normalizeGroup } from "src/resources/groups/helpers"
import { SCHEME_MEMBERSHIP_ROLES } from "src/resources/schemes/constants"

import MoveModal from "./MoveModal"

const MoveGroup = ({ account, group }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchAccounts())
    dispatch(fetchSchemes({ filter: { role: SCHEME_MEMBERSHIP_ROLES.admin } }))
  }, [dispatch])

  const { isOpened, open, close } = useIsOpened()

  return (
    <>
      <Dropdown.Item onClick={open}>
        <div className="dropdown-icon">
          <Icon className="icon-large" type="arrow-right-hollow" />
        </div>
        Move to another space
      </Dropdown.Item>
      <MoveModal
        account={account}
        group={normalizeGroup(group)}
        isOpened={isOpened}
        close={close}
      />
    </>
  )
}

MoveGroup.propTypes = {
  account: shape({}).isRequired,
  group: shape({}).isRequired
}

export default MoveGroup
