import { UploadFile } from "./UploadFile"

const ROOT_FOLDER_NAME = "/"

const FolderItemTypes = {
  Folder: "Folder",
  File: "File"
}

const Status = {
  Pending: "pending",
  Creating: "creating",
  Created: "Created",
  Failed: "failed"
}

export default class UploadFolder {
  name

  files = []

  folders = []

  path = []

  status = Status.Pending

  errorMessage = null

  constructor({ name = ROOT_FOLDER_NAME, path = [] } = {}) {
    this.name = name
    this.path = path
  }

  addFile(file) {
    return new Promise((resolve, reject) => {
      // If file has been dropped into dropzone using drag-and-drop
      if (typeof file.file === "function") {
        file.file(
          (filesystemFile) => resolve(this.#addFile(filesystemFile)),
          (error) => reject(error)
        )
      } else {
        resolve(this.#addFile(file))
      }
    })
  }

  addFolder(name) {
    const folder = new UploadFolder({ name, path: this.#buildPath() })
    this.folders.push(folder)

    return folder
  }

  findOrCreateFolder(name) {
    return this.findFolder([name]) || this.addFolder(name)
  }

  removeFile(uploadFile) {
    this.#removeFolderItem(uploadFile, FolderItemTypes.File)
  }

  removeFolder(uploadFolder) {
    this.#removeFolderItem(uploadFolder, FolderItemTypes.Folder)
  }

  findFolder(path) {
    if (path.length === 0) return this

    const folderName = path[0]
    const folder = this.folders.find((f) => f.name === folderName)
    if (!folder) return null

    return folder.findFolder(path.slice(1))
  }

  copy() {
    const copy = new UploadFolder({ name: this.name, path: this.path })

    // Deliberately copying by reference, not by value
    copy.files = this.files
    copy.folders = this.folders

    return copy
  }

  isRootFolder() {
    return this.name === ROOT_FOLDER_NAME
  }

  markAsCreating() {
    this.status = Status.Creating

    this.files.forEach((file) => file.markAsUploading())
    this.folders.forEach((folder) => folder.markAsCreating())
  }

  isPending() {
    return this.status === Status.Pending
  }

  isCreating() {
    return this.status === Status.Creating
  }

  isUploading() {
    return (
      this.files.some((file) => file.isUploading()) ||
      this.folders.some((folder) => folder.isCreating() || folder.isUploading())
    )
  }

  isCreated() {
    return this.status === Status.Created
  }

  isFailed() {
    return this.status === Status.Failed
  }

  isSomeUploadFailed() {
    return (
      this.files.some((file) => file.isFailed()) ||
      this.folders.some((folder) => folder.isFailed() || folder.isSomeUploadFailed())
    )
  }

  isUploadFinished() {
    return (
      this.files.every((file) => file.isUploaded() || file.isFailed()) &&
      this.folders.every((folder) => folder.isUploadFinished())
    )
  }

  totalFilesCount() {
    return (
      this.files.length +
      this.folders.reduce((sum, folder) => sum + folder.totalFilesCount(), 0)
    )
  }

  totalUploadedFilesCount() {
    return (
      this.files.filter((file) => file.isUploaded()).length +
      this.folders.reduce((sum, folder) => sum + folder.totalUploadedFilesCount(), 0)
    )
  }

  isEmpty() {
    return this.files.length === 0 && this.folders.length === 0
  }

  findFile({ name, path }) {
    const folder = this.findFolder(path)
    return folder.files.find((f) => f.name === name)
  }

  markFileAsUploaded({ name, path }) {
    const file = this.findFile({ name, path })
    if (!file) throw new Error("File not found")

    file.markAsUploaded()
  }

  markFileAsFailed({ name, path, errorMessage }) {
    const file = this.findFile({ name, path })
    if (!file) throw new Error("File not found")

    file.markAsFailed(errorMessage)
  }

  markAsFailed(errorMessage = null) {
    this.status = Status.Failed
    this.errorMessage = errorMessage

    this.files.forEach((file) => file.markAsFailed())
    this.folders.forEach((folder) => folder.markAsFailed())
  }

  markFolderAsFailed({ name, path, errorMessage }) {
    const folder = this.findFolder([...path, name])
    if (!folder) throw new Error("Folder not found")

    folder.markAsFailed(errorMessage)
  }

  markFolderAsCreated({ name, path }) {
    const folder = this.findFolder([...path, name])
    if (!folder) throw new Error("Folder not found")

    folder.markAsCreated()
  }

  markAsCreated() {
    this.status = Status.Created
  }

  markAsPending() {
    this.status = Status.Pending
    this.errorMessage = null

    this.files.forEach((file) => file.markAsPending())
    this.folders.forEach((folder) => folder.markAsPending())
  }

  allFiles() {
    const allFiles = []

    allFiles.push(...this.files)
    this.folders.forEach((folder) => allFiles.push(...folder.allFiles()))

    return allFiles
  }

  #buildPath() {
    if (this.isRootFolder()) return []

    return [...this.path, this.name]
  }

  #removeFolderItem(folderItem, type) {
    const folder = this.findFolder(folderItem.path)
    if (!folder) throw new Error("Folder not found")

    const folderItems = type === FolderItemTypes.Folder ? folder.folders : folder.files

    const index = folderItems.findIndex((f) => f.name === folderItem.name)
    if (index === -1) throw new Error(`${type} not found`)

    folderItems.splice(index, 1)

    if (folder.isEmpty() && !folder.isRootFolder()) {
      this.removeFolder(folder)
    }
  }

  #addFile(file) {
    const uploadFile = new UploadFile({ file, path: this.#buildPath() })
    this.files.push(uploadFile)
    return uploadFile
  }
}
