import React from "react"
import { Provider } from "react-redux"
import { configureStore } from "@reduxjs/toolkit"

import reducer from "src/app/reducer"

const store = configureStore({ reducer })

export const withProvider = (Component) => (props) => {
  return (
    <Provider store={store}>
      <Component {...props} />
    </Provider>
  )
}

export default store
