import React, { useState } from "react"
import { styled } from "@linaria/react"
import { arrayOf, bool, func, number, shape, string } from "prop-types"

import { Header, Title, Body, Footer, FullScreenModal } from "src/styles/components/Modal"
import PrimaryButton from "src/styles/components/Button/Primary"

import DocumentFormBuilder from "./DocumentFormBuilder"

const DocumentFormBuilderModal = ({
  id,
  jwt,
  name,
  isOpened,
  className,
  groupMembers,
  finishFormBuilding,
  setAssignedUsersIds
}) => {
  const [fieldsToSignHaveUsers, setFieldsToSignHaveUsers] = useState(false)

  return (
    <FullScreenModal show={isOpened} className={className} onHide={finishFormBuilding}>
      <Header>
        <Title>{name} - to sign</Title>
      </Header>
      <Body className="m-0 p-0">
        <DocumentFormBuilder
          id={id}
          jwt={jwt}
          documentName={name}
          isOpened={isOpened}
          groupMembers={groupMembers}
          setFieldsToSignHaveUsers={setFieldsToSignHaveUsers}
          setAssignedUsersIds={setAssignedUsersIds}
        />
      </Body>
      <Footer>
        <PrimaryButton
          width="md"
          className="ml-auto"
          onClick={finishFormBuilding}
          disabled={!fieldsToSignHaveUsers}
        >
          Save
        </PrimaryButton>
      </Footer>
    </FullScreenModal>
  )
}

DocumentFormBuilderModal.propTypes = {
  id: string.isRequired,
  jwt: string.isRequired,
  name: string.isRequired,
  isOpened: bool.isRequired,
  finishFormBuilding: func.isRequired,
  setAssignedUsersIds: func.isRequired,
  groupMembers: arrayOf(
    shape({
      id: number.isRequired,
      fullName: string.isRequired
    })
  )
}

export default styled(DocumentFormBuilderModal)`
  .modal-dialog {
    height: 90%;
  }

  .modal-content {
    height: 100%;
  }
`
