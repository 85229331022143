import { startCase } from "lodash"

import { MEMBERSHIP_TYPES } from "src/features/meetingPacks/MeetingPack/constants"

export const displayedMembershipType = ({ membershipType, agendaItemMembershipType }) => {
  const agendaItemAccessOnlyDisplayedMembershipType =
    MEMBERSHIP_TYPES.apologies === membershipType ? "Apologies" : "Access only"

  return agendaItemMembershipType === MEMBERSHIP_TYPES.accessOnly
    ? agendaItemAccessOnlyDisplayedMembershipType
    : startCase(membershipType)
}
