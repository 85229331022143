import React from "react"
import { styled } from "@linaria/react"
import { func, string } from "prop-types"

import { linkActiveColor, linkHoverColor } from "src/styles/colors"

const SelectAllButton = ({ onClick, targetText, className }) => {
  return (
    <button className={className} onClick={onClick}>
      select all {targetText}
    </button>
  )
}

SelectAllButton.propTypes = {
  onClick: func.isRequired,
  targetText: string
}

export default styled(SelectAllButton)`
  right: 2.125rem;
  background: none;
  color: ${linkActiveColor};
  margin-right: 1rem;
  border: none;
  &:focus {
    color: ${linkHoverColor};
  }
`
