class @AutoquoteComponent
  @QUOTE_END_PLACEHOLDER = 'content-end-placeholder'

  constructor: (discussionMessagesComponent, usersAvailable) ->
    @discussionMessagesComponent = discussionMessagesComponent
    @discussionContainer = discussionMessagesComponent.discussionContainer
    @usersAvailable = usersAvailable
    @messageContainerSelector = '[data-behavior="message-container"]'

  setup: (desktopEditor, mobileEditor, openEditor) =>
    @onReplyIconClick(($messageContainer) =>
      quote = @quoteContent($messageContainer)
      contentEndPlaceholder = @endPlaceholder()
      quote = "#{quote}#{contentEndPlaceholder}"

      openEditor()
      @setQuote(desktopEditor, quote)
      @setQuote(mobileEditor, quote)

      @discussionMessagesComponent.scrollToBottom()
    )

  onReplyIconClick: (callback) =>
    @discussionContainer.on('click', (e) =>
      $target = $(e.target)
      $messageContainer = $target.parents(@messageContainerSelector)

      if @isSelectorClicked($target, '[data-behavior="reply-message"]')
        e.preventDefault()
        callback($messageContainer)
    )

  isSelectorClicked: ($target, selector) ->
    $target.is(selector) || $target.parent(selector).length

  setQuote: (editor, quote) ->
    editor.focus()
    editor.setContent(quote)
    editor.setCaretAtEndOf(".#{AutoquoteComponent.QUOTE_END_PLACEHOLDER}")
    editor.cursor('enter')
    editor.scrollViewToBottom()

    $(".#{AutoquoteComponent.QUOTE_END_PLACEHOLDER}")
      .removeClass(AutoquoteComponent.QUOTE_END_PLACEHOLDER)

  quoteContent: ($messageContainer) =>
    messageBody = @messageBody($messageContainer)

    $messageAuthorTag = @messageAuthorTag($messageContainer, @usersAvailable)

    $messageTimeTag = @messageTimeTag($messageContainer)

    $wroteTag = @wroteTag($messageContainer)

    $originalMessageLink = @originalMessageLink($messageContainer)

    $quote = @quote(
      messageBody,
      $messageAuthorTag,
      $messageTimeTag,
      $wroteTag,
      $originalMessageLink
    )

    $quote[0].outerHTML

  messageBody: ($messageContainer) ->
    $messageContainer
      .find('[data-behavior="message-body"]')
      .html()

  messageAuthorTag: ($messageContainer, usersAvailable) ->
    messageAuthorId = $messageContainer.data('author-id')
    messageAuthor = usersAvailable.find((user) -> user.id == messageAuthorId)
    $messageAuthorSpan = $('<span>')
    if messageAuthor
      $messageAuthorSpan
        .addClass('user-mention')
        .html("@#{messageAuthor.inserted_name}")
        .attr({
          'data-behavior': 'user-mention',
          'data-id': messageAuthorId
        })
    else
      $messageAuthorSpan.html('(user deleted)')

  messageTimeTag: ($messageContainer) ->
    messageTime = $messageContainer.data('datetime')
    messageTimeFormatted = moment
      .utc(messageTime)
      .local()
      .format(' MMM Do h:mma')
    $('<span>').html(messageTimeFormatted)

  wroteTag: ($messageContainer) ->
    $('<span>').html(', wrote:')

  originalMessageLink: ($messageContainer) ->
    $('<a class="breadcrumb-link">')
      .text('View original message')
      .attr({
        href: location.protocol + '//' +
          window.location.host +
          window.location.pathname +
          '?message_id=' +
          $messageContainer.data('id')
      })

  quote: (
    messageBody,
    $messageAuthorTag,
    $messageTimeTag,
    $wroteTag,
    $originalMessageLink
  ) ->
    $quoteMeta = $('<p>')
      .prepend($wroteTag)
      .prepend($messageTimeTag)
      .prepend($messageAuthorTag)
    $quote = $('<blockquote>').html(
      window.truncate(messageBody, 50, {
        byWords: true, excludes: ['img', 'video', 'iframe']
      })
    )
    $quote.find('.user-mention').each((i, mention) ->
      $(mention).replaceWith(mention.innerText)
    )
    $quote.prepend($quoteMeta)
    $quote.append($originalMessageLink)
    $quote.find('.edited-label').remove()
    $quote

  endPlaceholder: ->
    "<p class='#{AutoquoteComponent.QUOTE_END_PLACEHOLDER}'></p>"
