import apiClient from "src/app/apiClient"

const subFolders = (params) => apiClient.get("/api/box/sub_folders", { params })
const move = (data) => apiClient.patch("/api/box/move", data)
const copy = (data) => apiClient.patch("/api/box/copy", data)
const copyToDiscussion = (data) => apiClient.patch("/api/box/copy_to_discussion", data)
const deleteFile = (boxFileId) => apiClient.delete(`/api/box/files/${boxFileId}`)

const box = {
  subFolders,
  move,
  copy,
  copyToDiscussion,
  deleteFile
}

export default box
