import { createSlice } from "@reduxjs/toolkit"
import { keyBy, union } from "lodash"

import { PER_PAGE_LIMIT } from "src/constants"
import { setIsLoading, setSuccess, setFailure } from "src/helpers/slice"
import {
  fetchMessageFailure,
  fetchMessageSuccess,
  updateMessageStarredSuccess
} from "src/resources/messages/slice"

const initialState = {
  allIds: [],
  byId: {},
  hasNextPage: true,
  isLoading: false,
  error: null
}

const slice = createSlice({
  name: "starredItems",
  initialState,
  reducers: {
    resetState: () => initialState,
    fetchStarredItemsFailure: (state, action) => {
      setFailure(state, action)

      state.hasNextPage = false
    },
    fetchStarredItemsRequest: setIsLoading,
    fetchStarredItemsSuccess: (state, action) => {
      setSuccess(state)

      const starredItems = action.payload
      const newStarredItemsIds = starredItems.map((starredItem) => starredItem.id)
      const newStarredItemsById = keyBy(starredItems, "id")

      state.hasNextPage = newStarredItemsIds.length === PER_PAGE_LIMIT
      state.allIds = union(state.allIds, newStarredItemsIds)
      state.byId = { ...state.byId, ...newStarredItemsById }
    },
    removeStarredItemSuccess: (state, action) => {
      const { messageId } = action.payload
      const starredItemIndex = state.allIds.findIndex((id) => id === messageId)

      if (starredItemIndex !== -1) state.allIds.splice(starredItemIndex, 1)
      if (state.byId[messageId]) delete state.byId[messageId]
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMessageFailure, setFailure)
    builder.addCase(fetchMessageSuccess, (state, action) => {
      const starredItem = action.payload

      if (starredItem.starred && !state.allIds.includes(starredItem.id)) {
        state.allIds.unshift(starredItem.id)
      }
      state.byId[starredItem.id] = { ...state.byId[starredItem.id], ...starredItem }
    })
    builder.addCase(updateMessageStarredSuccess, (state, action) => {
      const { id, starred } = action.payload

      if (state.byId[id]) {
        state.byId[id].starred = !starred
      }
    })
  }
})

const { actions, reducer } = slice

export const {
  resetState,
  fetchStarredItemsRequest,
  fetchStarredItemsFailure,
  fetchStarredItemsSuccess,
  removeStarredItemSuccess
} = actions

export default reducer
