import React from "react"
import { styled } from "@linaria/react"
import { Dropdown } from "react-bootstrap"
import { bool, number, shape, string } from "prop-types"
import { useMutation } from "@apollo/client"

import { withDefaultPrevented } from "src/helpers/events"
import useIsOpened from "src/hooks/useIsOpened"
import { PUBLICATION_STATUSES } from "src/resources/meetingPacks/constants"
import { Column } from "src/components/AttandeesTable"
import TridotIcon from "src/components/TridotIcon"

import ManageAgendaItemsModal from "./ManageAgendaItemsModal"
import ChangeMembershipTypeModal from "./ChangeMembershipTypeModal"
import removeFromMeetingMutation from "./removeFromMeeting.gql"

const ActionsColumn = ({ className, user, meetingPack }) => {
  const {
    canResendInvitation,
    resendInvitationPath,
    canEditAttendeeType,
    canEditPermission,
    editMembershipPath,
    canGrantPreviewAccess,
    grantPreviewAccessMembershipPath,
    canRevokePreviewAccess,
    revokePreviewAccessMembershipPath,
    canRemoveMembership,
    revokeConfirmationMessage
  } = user
  const isMeetingPackPublished =
    meetingPack.publicationStatus === PUBLICATION_STATUSES.published
  const {
    isOpened: isManageAgendaItemsModalOpened,
    close: closeManageAgendaItemsModal,
    open: openManageAgendaItemsModal
  } = useIsOpened()
  const {
    isOpened: isMembershipTypeModalOpened,
    close: closeMembershipTypeModal,
    open: openMembershipTypeModal
  } = useIsOpened()

  const [removeFromMeeting, { loading }] = useMutation(removeFromMeetingMutation, {
    variables: { meetingPackId: meetingPack.id, userId: user.id },
    refetchQueries: ["meetingPackMemberships"]
  })

  const removeFromMeetingWithConfirmation = () => {
    if (window.confirm(revokeConfirmationMessage)) {
      removeFromMeeting()
    }
  }

  return (
    <Column className={className}>
      <ManageAgendaItemsModal
        show={isManageAgendaItemsModalOpened}
        onHide={closeManageAgendaItemsModal}
        user={user}
        meetingPack={meetingPack}
        isMeetingPackPublished={isMeetingPackPublished}
      />
      <ChangeMembershipTypeModal
        user={user}
        meetingPack={meetingPack}
        show={isMembershipTypeModalOpened}
        onHide={closeMembershipTypeModal}
        isMeetingPackPublished={isMeetingPackPublished}
      />

      <Dropdown>
        <Dropdown.Toggle as={TridotIcon} data-testid="actions-toggle" />

        <Dropdown.Menu alignRight>
          {canResendInvitation && (
            <Dropdown.Item
              as="a"
              data-remote="true"
              data-method="patch"
              data-behavior="reset-invite-link"
              href={resendInvitationPath}
            >
              Resend Invitation
            </Dropdown.Item>
          )}

          <Dropdown.Item
            as="a"
            onClick={withDefaultPrevented(openManageAgendaItemsModal)}
            href="#"
            data-testid="edit-user-agenda-items-link"
          >
            Manage Agenda Items
          </Dropdown.Item>

          <Dropdown.Divider />

          {canEditAttendeeType && (
            <Dropdown.Item
              as="a"
              href="#"
              onClick={withDefaultPrevented(openMembershipTypeModal)}
              data-testid="change-attendee-type"
            >
              Change Attendee Type
            </Dropdown.Item>
          )}

          {canEditPermission && (
            <Dropdown.Item
              as="a"
              data-remote="true"
              data-behavior="change-permission"
              href={editMembershipPath}
            >
              Change Permission
            </Dropdown.Item>
          )}

          {canGrantPreviewAccess && (
            <>
              <Dropdown.Divider />

              <Dropdown.Item
                as="a"
                data-remote="true"
                data-behavior="grant-preview"
                href={grantPreviewAccessMembershipPath}
              >
                Grant Preview
              </Dropdown.Item>
            </>
          )}

          {canRevokePreviewAccess && (
            <>
              <Dropdown.Divider />
              <Dropdown.Item
                as="a"
                data-remote="true"
                data-behavior="remove-preview"
                href={revokePreviewAccessMembershipPath}
              >
                Remove Preview
              </Dropdown.Item>
            </>
          )}

          {canRemoveMembership && (
            <>
              <Dropdown.Divider />
              <Dropdown.Item
                onClick={removeFromMeetingWithConfirmation}
                disabled={loading}
                className="delete text-nowrap"
                variant="danger"
                data-behavior="revoke-permission"
              >
                Remove from meeting
              </Dropdown.Item>
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </Column>
  )
}

ActionsColumn.propTypes = {
  meetingPack: shape({
    id: number.isRequired,
    name: string.isRequired
  }).isRequired,
  user: shape({
    canResendInvitation: bool,
    resendInvitationPath: string,
    canEditAttendeeType: bool,
    canEditPermission: bool,
    editMembershipPath: string,
    canGrantPreviewAccess: bool,
    grantPreviewAccessMembershipPath: string,
    canRevokePreviewAccess: bool,
    revokePreviewAccessMembershipPath: string,
    canRemoveMembership: bool,
    revokeConfirmationMessage: string
  }).isRequired
}

export default styled(ActionsColumn)`
  .dropdown {
    position: static;
  }

  .dropdown-toggle {
    cursor: pointer;
  }

  .dropdown-menu {
    a {
      white-space: nowrap;
    }
  }
`
