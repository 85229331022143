import { useState } from "react"
import { useLazyQuery, useMutation } from "@apollo/client"
import axios from "axios"

import { TRANSCRIPT_STATUSES } from "src/resources/meetingPacks/constants"

import uploadUrlQuery from "./uploadUrl.gql"
import createTranscriptMutation from "./create.gql"

export default function useUpload({ meetingPackId, setStatus }) {
  const [selectedFiles, setSelectedFiles] = useState([])
  const [createTranscript] = useMutation(createTranscriptMutation)

  const setAllFilesAsUploading = (isUploadingStatus) => {
    setSelectedFiles(
      selectedFiles.map((file) => ({ ...file, isUploading: isUploadingStatus }))
    )
  }

  const handleUpload = ({ uploadUrl, audioFilePath }) => {
    axios
      .put(uploadUrl, selectedFiles[0].file)
      .then(() =>
        createTranscript({
          variables: {
            meetingPackId,
            audioFilePath
          }
        })
      )
      .then(({ data: result }) => {
        setAllFilesAsUploading(false)

        if (result.createTranscript.successful) {
          setStatus(TRANSCRIPT_STATUSES.uploaded)
        } else {
          setStatus(TRANSCRIPT_STATUSES.failed)
        }
      })
      .catch(() => {
        setAllFilesAsUploading(false)
        setStatus(TRANSCRIPT_STATUSES.failed)
      })
  }

  const [loadUploadUrl] = useLazyQuery(uploadUrlQuery, {
    onCompleted: (data) => {
      const [uploadUrl, audioFilePath] = data?.meetingPackTranscriptUploadUrl || []
      handleUpload({ uploadUrl, audioFilePath })
    }
  })

  const setSelectedFilesWithMeta = (files) => {
    setSelectedFiles(files.map((file) => ({ file, name: file.name, isUploading: false })))
  }

  const uploadFiles = () => {
    setAllFilesAsUploading(true)
    loadUploadUrl({ variables: { meetingPackId }, fetchPolicy: "network-only" })
  }

  const isUploading = selectedFiles.some((file) => file.isUploading)

  return {
    setSelectedFilesWithMeta,
    selectedFiles,
    uploadFiles,
    isUploading
  }
}
