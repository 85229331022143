import React from "react"
import { func, node, string } from "prop-types"

import { Label, Icon, Button as StyledButton } from "./styles"

const Button = ({ icon, iconType, onClick, children }) => {
  return (
    <StyledButton onClick={onClick}>
      {iconType && <Icon type={iconType} />}
      {icon && icon}
      <Label>{children}</Label>
    </StyledButton>
  )
}

Button.propTypes = {
  icon: node,
  iconType: string,
  onClick: func.isRequired
}

export default Button
