import { bool } from "prop-types"
import React from "react"

import DocumentPreview, { Modes, PropTypes } from "src/features/DocumentPreview"
import { useUniversalAi } from "src/features/UniversalAi"
import { OBJECT_TYPES } from "src/features/UniversalAi/constants"
import { buildOpenaiButtons } from "src/helpers/box"
import { splitFilename } from "src/helpers/string"

const AttachedDocumentPreview = ({
  mode = Modes.Modal,
  aiButtonsEnabled = false,
  sidebarEnabled = false
}) => {
  const { summariseAsTextInSidebar, queryInSidebar } = useUniversalAi()

  const toolbarButtonsFactory = (file) => {
    if (!aiButtonsEnabled || !file.contentPresent) {
      return []
    }

    const { summariseButton, askQuestionButton } = buildOpenaiButtons()

    summariseButton.element.addEventListener("click", () =>
      summariseAsTextInSidebar({
        objectId: file.id,
        objectType: OBJECT_TYPES.attachedDocument
      })
    )

    askQuestionButton.element.addEventListener("click", () =>
      queryInSidebar({
        objectId: file.id,
        objectType: OBJECT_TYPES.attachedDocument,
        objectTitle: splitFilename(file.filename).name
      })
    )

    return [summariseButton, askQuestionButton]
  }

  return (
    <DocumentPreview
      mode={mode}
      toolbarButtonsFactory={toolbarButtonsFactory}
      sidebarEnabled={sidebarEnabled}
    />
  )
}

AttachedDocumentPreview.propTypes = {
  ...PropTypes,
  aiButtonsEnabled: bool
}

export default AttachedDocumentPreview
