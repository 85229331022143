import { styled } from "@linaria/react"

import { BREAKPOINTS, SPACES } from "src/styles/sizes"

export const ResultsContainer = styled.div`
  padding: 0 ${SPACES.medium} 0 ${SPACES.medium};

  @media (max-width: calc(${BREAKPOINTS.medium} - 1px)) {
    height: calc(100vh - 7.5rem);
  }

  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    width: 100%;
    overflow: initial;
  }
`
