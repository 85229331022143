import initialState from "./initialState"

const open = (state, action) => {
  const { document, collection } = action.payload

  state.opened = true
  state.document = document
  state.collection = collection
}

const close = (_state, _action) => initialState

export { open, close }
