import React, { useState, useEffect, useRef } from "react"
import { string, func, shape, number } from "prop-types"
import { styled } from "@linaria/react"

import IconOpacity from "src/styles/components/IconOpacity"
import { BORDER_COLORS } from "src/styles/colors"
import { SPACES } from "src/styles/sizes"

const split = (str, index) => [str.slice(0, index), str.slice(index)]

const EditForm = ({ utterance, index, onSave, close, className }) => {
  const initialValue = utterance.text
  const [text, setText] = useState(initialValue)
  const textareaRef = useRef()

  const setTextareaHeight = (element) => {
    element.style.height = `${element.scrollHeight}px` // eslint-disable-line no-param-reassign
    element.style.overflow = element.scrollHeight < 300 ? "hidden" : "auto" // eslint-disable-line no-param-reassign
  }

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus()
      setTextareaHeight(textareaRef.current)
    }
  }, [textareaRef.current])

  const handleChangeText = (e) => setText(e.target.value)

  const closeEditMode = (updatedText) => {
    if (!updatedText || !updatedText.trim().length) onSave([{ uid: utterance.uid }])
    else close()
  }

  const handleCancel = () => {
    setText(initialValue)
    closeEditMode(initialValue)
  }

  const handleSave = () => {
    closeEditMode(text)

    if (text.trim().length) onSave([{ uid: utterance.uid, text: text.trim() }])
  }

  const splitUtterance = (firstPart, secondPart) => {
    const newUtteranceUid = Date.now().toString(16)

    onSave([
      { uid: utterance.uid, text: firstPart.trim() },
      {
        uid: newUtteranceUid,
        text: secondPart.trim(),
        speaker: utterance.speaker,
        speakerId: utterance.speakerId,
        index: index + 1
      }
    ])
  }

  const handleEnter = (e) => {
    if (e.keyCode !== 13) return

    const [currentUtteranceText, newUtteranceText] = split(text, e.target.selectionStart)
    close()
    splitUtterance(currentUtteranceText, newUtteranceText)
  }

  return (
    <div className={className}>
      <textarea
        rows="1"
        ref={textareaRef}
        onChange={handleChangeText}
        defaultValue={text}
        onKeyDown={handleEnter}
        onKeyUp={(e) => setTextareaHeight(e.target)}
      />
      <div className="text-right">
        <IconOpacity type="done" size="medium" onClick={handleSave} />
        <IconOpacity type="cancel" size="medium" onClick={handleCancel} />
      </div>
    </div>
  )
}

EditForm.propTypes = {
  utterance: shape({
    uid: string.isRequired,
    text: string.isRequired,
    start: number,
    end: number,
    speaker: string,
    speakerId: number
  }).isRequired,
  index: number.isRequired,
  onSave: func.isRequired,
  close: func.isRequired
}

export default styled(EditForm)`
  textarea {
    border: 1px solid ${BORDER_COLORS.control};
    outline-width: 0;
    padding: ${SPACES.small};
    border-radius: ${SPACES.xxSmall};
    min-height: 40px;
    overflow: auto;
    max-height: 300px;
  }
`
