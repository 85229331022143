import React, { useCallback, useEffect, useState } from "react"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import { Form, Modal, Row, Col } from "react-bootstrap"
import { shape, string, bool, func, number } from "prop-types"

import SchemeSelect from "src/components/SchemeSelect"
import Select from "src/styles/components/Select"
import PrimaryButton from "src/styles/components/Button/Primary"
import SecondaryButton from "src/styles/components/Button/Secondary"
import ContentDivider from "src/styles/components/ContentDivider"
import ModalTitle from "src/styles/components/Modal/Title"
import ModalHeader from "src/styles/components/Modal/Header"
import ModalFooter from "src/styles/components/Modal/Footer"
import WarningText from "src/styles/components/WarningText"
import useIsOpened from "src/hooks/useIsOpened"
import { StyledModal } from "src/styles/components/Modal"
import { fetchSchemeGroups } from "src/resources/groups/thunks"
import { selectAccountOptions } from "src/resources/accounts/selectors"
import { selectSchemeOptions } from "src/resources/schemes/selectors"
import { selectGroupOptions } from "src/resources/groups/selectors"
import { SCHEME_MEMBERSHIP_ROLES } from "src/resources/schemes/constants"

import UsersList from "./UsersList"
import ConfirmModal from "./ConfirmModal"
import { GroupHeader, UsersListContainer } from "./styles"

// TODO: add ability to create group
// import CreatableSelect from "react-select/creatable"
// import { isValidNewGroup, noGroupsMessage, formatNewGroupLabel } from "./helpers"

const MoveModal = ({ account, group, discussion, isOpened, close }) => {
  const dispatch = useDispatch()

  const {
    isOpened: isConfirmModalOpened,
    open: openConfirmModal,
    close: closeConfirmModal
  } = useIsOpened()

  const [selectedGroup, setSelectedGroup] = useState()
  const [selectedScheme, setSelectedScheme] = useState()
  const [selectedAccount, setSelectedAccount] = useState()

  const groupOptions = useSelector(selectGroupOptions, shallowEqual)
  const schemeOptions = useSelector(selectSchemeOptions, shallowEqual)
  const accountOptions = useSelector(selectAccountOptions, shallowEqual)

  const currentGroup = groupOptions.find(({ value }) => value === discussion.groupId)
  const currentScheme = schemeOptions.find(({ value }) => value === group.schemeId)
  const currentAccount = accountOptions.find(({ value }) => value === account.id)

  const groupOptionsByScheme = groupOptions.filter(
    (groupOption) =>
      groupOption.settings.discussions && groupOption.schemeId === selectedScheme?.value
  )
  const schemeOptionsByAccount = schemeOptions.filter(
    ({ accountId, accountIds }) =>
      accountId === selectedAccount?.value || accountIds.includes(selectedAccount?.value)
  )
  const accountIdsBySchemes = [
    ...new Set(
      schemeOptions.flatMap(({ accountId, accountIds }) =>
        accountId ? [accountId] : accountIds
      )
    )
  ]
  const accountOptionsBySchemes = accountOptions.filter(({ value }) =>
    accountIdsBySchemes.includes(value)
  )

  const canBeMoved = selectedGroup && selectedGroup.value !== group.id

  const currentUserIds = canBeMoved ? currentGroup.users.map(({ id }) => id) : []
  const newUserIds = canBeMoved ? selectedGroup.users.map(({ id }) => id) : []
  const currentUsers = canBeMoved
    ? currentGroup.users.map((user) => ({
        ...user,
        removed: !newUserIds.includes(user.id)
      }))
    : []
  const newUsers = canBeMoved
    ? selectedGroup.users.map((user) => ({
        ...user,
        added: !currentUserIds.includes(user.id)
      }))
    : []

  useEffect(() => {
    if (currentGroup && !selectedGroup && selectedGroup !== null)
      setSelectedGroup(currentGroup)
    if (currentScheme && !selectedScheme) setSelectedScheme(currentScheme)
    if (currentAccount && !selectedAccount) setSelectedAccount(currentAccount)
  }, [currentGroup, currentScheme, currentAccount])

  useEffect(() => {
    if (selectedScheme) {
      dispatch(
        fetchSchemeGroups({
          schemeId: selectedScheme.value,
          filter: { role: SCHEME_MEMBERSHIP_ROLES.admin }
        })
      )
    }
  }, [dispatch, selectedScheme])

  const handleAccountChange = useCallback(
    (accountOption) => {
      if (selectedAccount !== accountOption) {
        setSelectedAccount(accountOption)
        setSelectedScheme(null)
        setSelectedGroup(null)
      }
    },
    [selectedAccount]
  )

  const handleSchemeChange = useCallback(
    (schemeOption) => {
      if (selectedScheme !== schemeOption) {
        setSelectedScheme(schemeOption)
        setSelectedGroup(null)
      }
    },
    [selectedScheme]
  )

  const handleMove = () => {
    close()
    openConfirmModal()
  }

  const handleClose = () => {
    close()
    closeConfirmModal()
    setSelectedGroup(currentGroup)
    setSelectedScheme(currentScheme)
    setSelectedAccount(currentAccount)
  }

  return (
    <>
      <StyledModal size="md" onHide={handleClose} show={isOpened}>
        <ModalHeader>
          <ModalTitle>Move Discussion to a different Group</ModalTitle>
        </ModalHeader>
        <Modal.Body>
          <Form>
            <Form.Label className="text-muted">Move {discussion.title} to:</Form.Label>
            <Form.Group>
              <Select
                placeholder="Select organisation"
                onChange={handleAccountChange}
                options={accountOptionsBySchemes}
                value={selectedAccount}
              />
            </Form.Group>
            <Form.Group>
              <SchemeSelect
                isDisabled={!selectedAccount}
                placeholder="Select space"
                onChange={handleSchemeChange}
                options={schemeOptionsByAccount}
                value={selectedScheme}
              />
            </Form.Group>
            <Form.Group>
              <Select
                isDisabled={!selectedScheme}
                placeholder="Select group"
                isOptionDisabled={({ value }) => value === currentGroup.value}
                // TODO: add ability to create group
                // formatCreateLabel={formatNewGroupLabel}
                // isValidNewOption={isValidNewGroup}
                // noOptionsMessage={noGroupsMessage}
                // component={CreatableSelect}
                onChange={setSelectedGroup}
                options={groupOptionsByScheme}
                value={selectedGroup}
              />
            </Form.Group>
            {canBeMoved && (
              <>
                <Row className="mt-4">
                  <Col>
                    <GroupHeader>Current Group Members</GroupHeader>
                  </Col>
                  <Col>
                    <GroupHeader>New Group Members</GroupHeader>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <UsersListContainer>
                      <UsersList
                        field="removed"
                        currentUserId={window.$currentUserId()}
                        users={currentUsers}
                      />
                    </UsersListContainer>
                  </Col>
                  <Col>
                    <UsersListContainer>
                      <UsersList
                        field="added"
                        currentUserId={window.$currentUserId()}
                        users={newUsers}
                      />
                    </UsersListContainer>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ContentDivider fullWidth />
                  </Col>
                  <Col>
                    <ContentDivider fullWidth />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <WarningText
                      variant="danger"
                      text={`Highlighted people in ${currentGroup.label} will lose access to this discussion`}
                    />
                  </Col>
                  <Col>
                    <WarningText
                      variant="success"
                      text={`Highlighted people in ${selectedGroup.label} will gain access to this discussion`}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Form>
        </Modal.Body>
        <ModalFooter>
          <PrimaryButton className="mr-3" disabled={!canBeMoved} onClick={handleMove}>
            Move here
          </PrimaryButton>
          <SecondaryButton onClick={handleClose}>Close</SecondaryButton>
        </ModalFooter>
      </StyledModal>
      {canBeMoved && (
        <ConfirmModal
          currentAccount={currentAccount}
          currentScheme={currentScheme}
          currentGroup={currentGroup}
          newAccount={selectedAccount}
          newScheme={selectedScheme}
          newGroup={selectedGroup}
          discussion={discussion}
          isOpened={isConfirmModalOpened}
          close={handleClose}
        />
      )}
    </>
  )
}

MoveModal.propTypes = {
  account: shape({
    id: number.isRequired
  }).isRequired,
  group: shape({
    id: number.isRequired,
    schemeId: number.isRequired
  }).isRequired,
  discussion: shape({
    title: string.isRequired
  }).isRequired,
  isOpened: bool.isRequired,
  close: func.isRequired
}

export default MoveModal
