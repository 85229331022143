import React, { useRef } from "react"
import Flatpickr from "react-flatpickr"
import { oneOf, instanceOf, func } from "prop-types"
import moment from "moment"

import { ACTION_TYPES } from "../constants"
import { DateInputSectionContainer } from "../styles"

const TEXT = {
  [ACTION_TYPES.must_read]: "Close must read on",
  [ACTION_TYPES.voting]: "Close vote on"
}

const DateInputSection = ({ actionType, selectedDate, setSelectedDate }) => {
  const inputRef = useRef(null)

  return (
    <DateInputSectionContainer>
      <span>{TEXT[actionType]}</span>

      <Flatpickr
        value={selectedDate}
        options={{ dateFormat: "d-m-Y", minDate: moment().startOf("day").toDate() }}
        onClose={([date]) => {
          setSelectedDate(moment(date).endOf("day").toDate())
        }}
        ref={inputRef}
        data-testid="message-action-date-input"
      />

      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <i className="fa fa-calendar-o" onClick={() => inputRef.current.node.focus()} />
    </DateInputSectionContainer>
  )
}

DateInputSection.propTypes = {
  actionType: oneOf(Object.keys(ACTION_TYPES)).isRequired,
  selectedDate: instanceOf(Date).isRequired,
  setSelectedDate: func.isRequired
}

export default DateInputSection
