const showIntercom = () => {
  if (!window.Intercom) {
    return
  }

  window.analytics.page()
  window.Intercom("update", { hide_default_launcher: false })
  window.Intercom("show")
}

export { showIntercom }
