import React from "react"
import { startCase } from "lodash"
import { styled } from "@linaria/react"
import { string } from "prop-types"

import { accountAdminColor, accountGuestColor, TEXT_COLORS } from "src/styles/colors"
import { FONT_SIZES, SPACES } from "src/styles/sizes"
import { composeVariationsClasses } from "src/helpers/linaria"

import RoleIcon from "./RoleIcon"

const AccountRoleBadge = ({ role, variant, className }) => {
  const classNameWithVariations = composeVariationsClasses(className, { role, variant })

  return (
    <div role={role} className={classNameWithVariations}>
      <RoleIcon className="mr-1" role={role} />
      {startCase(role)}
    </div>
  )
}

AccountRoleBadge.propTypes = {
  role: string.isRequired,
  variant: string
}

export default styled(AccountRoleBadge)`
  align-items: center;
  border-radius: 5px;
  color: ${TEXT_COLORS.heading};
  display: flex;
  font-size: ${FONT_SIZES.small};
  padding: 4px 6px;
  margin-right: ${SPACES.small};

  &--role-admin {
    background-color: ${accountAdminColor};
  }

  &--role-guest {
    background-color: ${accountGuestColor};
  }

  &--variant-small {
    padding: 3px 4px;
    font-size: ${FONT_SIZES.xSmall};
  }
`
