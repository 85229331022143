import { useEffect, useRef } from "react"
import * as Sentry from "@sentry/browser"

const appFormPositionsKey = "app-form-position"
const appFormLastViewedSectionKey = "app-form-last-viewed-section"

const getAllFormPositions = () =>
  JSON.parse(localStorage.getItem(appFormPositionsKey) || "{}")

export const saveFormPosition = (recordId, sectionId, value) => {
  const data = getAllFormPositions()

  localStorage.setItem(
    appFormPositionsKey,
    JSON.stringify({
      ...data,
      [recordId]: { ...(data[recordId] || {}), [sectionId]: value }
    })
  )
}

export const getFormPosition = (recordId, sectionId) => {
  const data = getAllFormPositions()
  return data[recordId] && data[recordId][sectionId]
}

const getAllFormLastViewedSections = () =>
  JSON.parse(localStorage.getItem(appFormLastViewedSectionKey) || "{}")

export const saveLastViewedSection = (recordId, value) => {
  const data = getAllFormLastViewedSections()

  localStorage.setItem(
    appFormLastViewedSectionKey,
    JSON.stringify({
      ...data,
      [recordId]: value
    })
  )
}

const useSavingFormPosition = ({
  activeSection,
  recordId,
  activeSectionCardRef,
  setActiveSection,
  loadingRecord,
  changeSectionLoading
}) => {
  const scrollRef = useRef()

  const scrollFieldsList = (sectionId) => {
    const scrollTop = getFormPosition(recordId, sectionId)
    if (scrollTop || scrollTop === "0")
      activeSectionCardRef.current.scrollTo(0, scrollTop)
  }

  const setScrollPositionFieldsList = (scrollValue) => {
    if (scrollValue || scrollValue === 0)
      saveFormPosition(recordId, activeSection.id, scrollValue)
  }

  const updateSection = (newSection) => {
    setScrollPositionFieldsList(activeSectionCardRef.current?.scrollTop)

    if (newSection) {
      saveLastViewedSection(recordId, newSection.id)
      setActiveSection(newSection)
    } else {
      Sentry.captureException("Section for update does not exist", {
        extra: { recordId, newSection }
      })
    }
  }

  const getDefaultActiveSection = (sections) => {
    const sectionId = getAllFormLastViewedSections()[recordId]
    return sections.find((s) => s.id === sectionId) || sections[0]
  }

  useEffect(() => {
    if (activeSection?.id && activeSectionCardRef.current)
      scrollFieldsList(activeSection.id)
  }, [
    activeSection?.id,
    activeSectionCardRef.current,
    loadingRecord,
    changeSectionLoading
  ])

  const handleScroll = () => {
    if (activeSectionCardRef.current?.scrollTop)
      scrollRef.current = activeSectionCardRef.current?.scrollTop
  }

  useEffect(() => {
    if (!activeSection?.id || !activeSectionCardRef.current) return

    activeSectionCardRef.current.addEventListener("scroll", handleScroll)

    return () => {
      setScrollPositionFieldsList(scrollRef.current)
      activeSectionCardRef.current?.removeEventListener("scroll", handleScroll)
    }
  }, [activeSection?.id, activeSectionCardRef.current])

  return { updateSection, getDefaultActiveSection }
}

export default useSavingFormPosition
