import React from "react"
import { string, element } from "prop-types"
import { Link } from "react-router-dom"

const Title = ({ text, backButtonRoute, backButtonText, RightComponent }) => (
  <div className="subheader-nav subheader-nav-grid groups-list-nav">
    {backButtonRoute && (
      <div className="subheader-link">
        <Link to={backButtonRoute} className="subheader-control button is-secondary">
          <span className="control-text">{backButtonText}</span>
        </Link>
      </div>
    )}
    <div className="subheader-title">
      <h5 className="text-truncate">{text}</h5>
    </div>
    {RightComponent}
  </div>
)

Title.propTypes = {
  text: string.isRequired,
  backButtonRoute: string,
  backButtonText: string,
  RightComponent: element
}

export default Title
