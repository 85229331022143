import { connect } from "react-redux"

import { getVisibleMeetingPackAttendees } from "src/features/meetingPacks/MeetingPack/selectors"

import Contacts from "./Contacts"

export const mapState = (state) => ({
  attendees: getVisibleMeetingPackAttendees(state),
  currentUser: state.meetingPack.currentUser
})

export default connect(mapState)(Contacts)
