import React, { useContext } from "react"
import { string, oneOf } from "prop-types"
import { styled } from "@linaria/react"

import Radio from "src/styles/components/Radio"

import { ACTION_CLOSED_TYPES } from "../constants"

import Context from "./Context"

const Option = ({ id, label, action, className }) => {
  const { selectedAction, setSelectedAction } = useContext(Context)

  return (
    <Radio
      id={id}
      label={label}
      checked={action === selectedAction}
      onChange={() => setSelectedAction(action)}
      className={className}
    />
  )
}

Option.propTypes = {
  id: string.isRequired,
  label: string.isRequired,
  action: oneOf([ACTION_CLOSED_TYPES.date, ACTION_CLOSED_TYPES.number])
}

export default styled(Option)`
  margin: 1rem 0;
`
