import React from "react"
import { Modal } from "react-bootstrap"
import { bool, func, number, string } from "prop-types"

import DocumentViewer from "./DocumentViewer"

const PreviewModal = ({ isOpened, close, name, documentId, pspdfkitId }) => {
  return (
    <Modal size="xl" show={isOpened} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>{name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DocumentViewer documentId={documentId} pspdfkitId={pspdfkitId} />
      </Modal.Body>
    </Modal>
  )
}

PreviewModal.propTypes = {
  isOpened: bool.isRequired,
  close: func.isRequired,
  name: string.isRequired,
  documentId: number.isRequired,
  pspdfkitId: string.isRequired
}

export default PreviewModal
