import React from "react"
import { Form, Modal } from "react-bootstrap"
import { arrayOf, bool, func, shape, string } from "prop-types"

import ModalHeader from "src/styles/components/Modal/Header"
import ModalTitle from "src/styles/components/Modal/Title"
import Select from "src/styles/components/Select"
import SchemeSelect from "src/components/SchemeSelect"
import ModalFooter from "src/styles/components/Modal/Footer"
import { StyledModal } from "src/styles/components/Modal"
import DropdownTreeSelect from "src/styles/components/DropdownTreeSelect"
import { selectOptionType } from "src/helpers/propTypes"

const SelectFolderModal = ({
  onHide,
  areFoldersLoading,
  show,
  onAccountChange,
  onSchemeChange,
  accounts,
  schemes,
  selectedAccount,
  selectedScheme,
  onNodeToggle,
  onNodeChange,
  folders,
  children
}) => {
  return (
    <StyledModal size="md" onHide={onHide} show={show}>
      <ModalHeader>
        <ModalTitle>Select a destination folder</ModalTitle>
      </ModalHeader>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Select
              placeholder="Select organisation"
              onChange={onAccountChange}
              options={accounts}
              value={selectedAccount}
            />
          </Form.Group>
          <Form.Group>
            <SchemeSelect
              isDisabled={!selectedAccount}
              placeholder="Select space"
              onChange={onSchemeChange}
              options={schemes}
              value={selectedScheme}
            />
          </Form.Group>
          <DropdownTreeSelect
            disabled={!selectedScheme || areFoldersLoading}
            keepTreeOnSearch
            mode="radioSelect"
            texts={{
              placeholder: areFoldersLoading ? "Fetching folders..." : "Select folder"
            }}
            onChange={(current, selected) => onNodeChange(selected[0])}
            onNodeToggle={onNodeToggle}
            data={folders}
          />
        </Form>
      </Modal.Body>
      <ModalFooter>{children}</ModalFooter>
    </StyledModal>
  )
}

SelectFolderModal.propTypes = {
  onHide: func.isRequired,
  onAccountChange: func.isRequired,
  onSchemeChange: func.isRequired,
  onNodeToggle: func.isRequired,
  onNodeChange: func.isRequired,
  accounts: arrayOf(selectOptionType),
  schemes: arrayOf(selectOptionType),
  selectedAccount: selectOptionType,
  selectedScheme: selectOptionType,
  folders: arrayOf(
    shape({
      label: string.isRequired,
      value: string.isRequired
    })
  ),
  show: bool,
  areFoldersLoading: bool
}

export default SelectFolderModal
