import apiClient from "src/app/apiClient"

export const create = ({ serializedAnnotation, attachment }) => {
  const data = new FormData()
  data.append("serialized_annotation", JSON.stringify(serializedAnnotation))

  if (attachment) {
    data.append("attachment", attachment, attachment.name)
  }

  return apiClient.postForm("/api/stored_signatures", data)
}
