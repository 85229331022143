import React from "react"
import { styled } from "@linaria/react"

import { SPACES, BREAKPOINTS } from "src/styles/sizes"
import useMobileScreen from "src/hooks/useMobileScreen"

const KnowaLogoLoader = ({ className }) => {
  const isMobileScreen = useMobileScreen()
  let size = 80
  if (isMobileScreen) size = 40

  return (
    <div className={className}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M37.169 10.5658L50.72 24.1168L71.7083 3.13141C74.5538 0.283018 79.2127 0.283018 82.0582 3.13141L97.8687 18.9419C100.714 21.7845 100.714 26.4434 97.8687 29.2889L76.8833 50.2714L97.8687 71.2568C100.714 74.1023 100.714 78.7612 97.8687 81.6066L82.0582 97.4171C79.2098 100.263 74.5538 100.263 71.7083 97.4171L50.7229 76.4317L76.8804 50.2743L50.7229 24.1168L32.6996 42.1431C28.2273 46.6154 28.2273 53.9361 32.6996 58.4084L50.7215 76.4303L37.169 89.9856C31.3789 95.7757 28.4838 96.5075 18.8424 96.5075H6.02903C2.71125 96.5075 -0.00012207 93.7932 -0.00012207 90.4784V10.0731C-0.00012207 6.75824 2.71416 4.04395 6.02903 4.04395H18.8424C28.4868 4.04395 31.3818 4.77573 37.169 10.5658Z"
          fill="#9647D7"
        />
      </svg>
    </div>
  )
}

export default styled(KnowaLogoLoader)`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  margin: ${SPACES.small};

  @media (max-width: ${BREAKPOINTS.small}) {
    span {
      display: none;
    }

    [class^="icon-"] {
      display: block;
    }
  }

  @keyframes colorChange {
    0% {
      fill: #9647d7;
    }
    17% {
      fill: #197ef3;
    }
    34% {
      fill: #00cc7e;
    }
    51% {
      fill: #4fdfff;
    }
    68% {
      fill: #ff4e83;
    }
    85% {
      fill: #ffa24e;
    }
    100% {
      fill: #ff4e83;
    }
  }

  path {
    animation: colorChange 4s infinite;
  }
`
