import React from "react"
import { func, shape } from "prop-types"
import { useQuery } from "@apollo/client"

import SearchableSelect from "src/components/SearchableSelect"
import SchemeOption from "src/components/SchemeSelect/Option"
import { DEFAULT_ACCOUNT_FILTER } from "src/resources/accounts/constants"
import { DEFAULT_TAG_FILTER } from "src/resources/tags/constants"
import { DEFAULT_YEAR_FILTER } from "src/resources/years/constants"
import { getAccountOption, getAccountOptionId } from "src/resources/accounts/helpers"
import { getTagOption, getTagId } from "src/resources/tags/helpers"
import { getYearOption } from "src/resources/years/helpers"
import { optionType } from "src/constants/propTypes"

import meetingPacksAccounts from "./meetingPacksAccounts.gql"
import meetingPacksTags from "./meetingPacksTags.gql"
import meetingPacksYears from "./meetingPacksYears.gql"

const Filters = ({ filters, updateFilters }) => {
  const accounts = useQuery(meetingPacksAccounts)
  const tags = useQuery(meetingPacksTags)
  const years = useQuery(meetingPacksYears, {
    variables: {
      tagId: getTagId(filters.tagFilter),
      accountId: getAccountOptionId(filters.accountFilter)
    }
  })

  const accountOptions = (accounts?.data?.meetingPacksAccounts || []).map(
    getAccountOption
  )
  const tagOptions = (tags?.data?.meetingPacksTags || []).map(getTagOption)
  const yearOptions = (years?.data?.meetingPacksYears || []).map(getYearOption)

  const tagsByAccount =
    filters.accountFilter.value === DEFAULT_ACCOUNT_FILTER.value
      ? tagOptions
      : tagOptions.filter(({ account }) => account?.id === filters.accountFilter.value)

  const handleAccountSelect = (accountOption) =>
    updateFilters({
      ...filters,
      accountFilter: accountOption,
      tagFilter: DEFAULT_TAG_FILTER
    })

  const handleTagSelect = (tagOption) => {
    const accountOption = accountOptions.find(
      ({ value }) => tagOption.account?.id === value
    )

    updateFilters({
      ...filters,
      accountFilter: accountOption || DEFAULT_ACCOUNT_FILTER,
      tagFilter: tagOption
    })
  }

  const handleYearSelect = (yearOption) => {
    updateFilters({ ...filters, yearFilter: yearOption })
  }

  const showAccountsFilter = accountOptions.length > 1
  const showTagsFilter = tagOptions.length > 0

  return (
    <>
      {showAccountsFilter && (
        <SearchableSelect
          prefix="In"
          options={[DEFAULT_ACCOUNT_FILTER, ...accountOptions]}
          selected={filters.accountFilter}
          setSelected={handleAccountSelect}
          data-testid="account-filter"
        />
      )}
      {showTagsFilter && (
        <SearchableSelect
          components={{ Option: SchemeOption }}
          prefix="Tag"
          options={[DEFAULT_TAG_FILTER, ...tagsByAccount]}
          selected={filters.tagFilter}
          setSelected={handleTagSelect}
          data-testid="tag-filter"
        />
      )}
      <SearchableSelect
        prefix="Year"
        options={[DEFAULT_YEAR_FILTER, ...yearOptions]}
        selected={filters.yearFilter}
        setSelected={handleYearSelect}
        data-testid="year-filter"
      />
    </>
  )
}

Filters.propTypes = {
  filters: shape({
    accountFilter: optionType,
    tagFilter: optionType,
    yearFilter: optionType
  }).isRequired,
  updateFilters: func.isRequired
}

export default Filters
