import { useState } from "react"
import { orderBy } from "lodash"

export default function useSorting({
  items,
  sortBy,
  sortDirection,
  sortingColumnsRewrite = {}
}) {
  const [sorting, setSorting] = useState({ sortBy, sortDirection })

  const sort = ({ sortBy: newSortBy, sortDirection: newSortDirection }) => {
    setSorting({ sortBy: newSortBy, sortDirection: newSortDirection })
  }

  const sortedItems = orderBy(
    items,
    [
      sortingColumnsRewrite[sorting.sortBy]
        ? sortingColumnsRewrite[sorting.sortBy]
        : sorting.sortBy
    ],
    [sorting.sortDirection]
  )

  return { ...sorting, sort, sortedItems }
}
