import { showFlashMessage } from "src/helpers/flash"
import { FROALA_AI_ANSWER_READY, FROALA_AI_BUTTON_CLICKED } from "src/constants/events"

const FAIL_MESSAGE =
  "Apologies, Knowa is momentarily busy on a date with its AI companions. Please try again shortly."

const scrollDownFroalaContainer = () => {
  const froalaContainer = document.getElementsByClassName("fr-wrapper")[0]
  froalaContainer.scrollTop = froalaContainer.scrollHeight
}

export class FroalaOpenaiButtonComponent {
  constructor(analyticsContext = {}) {
    this.analyticsContext = analyticsContext
  }

  setup = () => {
    this.setupPlugin()
    this.defineIcon()
    this.registerCommand()
  }

  setupPlugin = () => {
    window.FroalaEditor.PLUGINS.aiButton = (editor) => ({
      transformText: (instruction) => {
        const message = editor.html.get()

        const answerReadyListener = (event) => {
          if (event.detail?.success && event.detail?.answer) {
            editor.html.set(`${message}<br/>${event.detail?.answer}`)
            scrollDownFroalaContainer()
          } else if (!event.detail?.success)
            showFlashMessage("danger", event.detail?.error || FAIL_MESSAGE)

          editor.edit.on()

          if (event.detail?.finished)
            window.removeEventListener(FROALA_AI_ANSWER_READY, answerReadyListener)
        }

        window.dispatchEvent(
          new CustomEvent(FROALA_AI_BUTTON_CLICKED, {
            detail: { instruction, message, analyticsContext: this.analyticsContext }
          })
        )

        window.addEventListener(FROALA_AI_ANSWER_READY, answerReadyListener)

        editor.edit.off()
      }
    })
  }

  defineIcon = () => {
    window.FroalaEditor.DefineIcon("openaiButton", {
      NAME: "openai",
      template: "knowa"
    })
  }

  registerCommand = () => {
    window.FroalaEditor.RegisterCommand("openaiButton", {
      title: "Knowa AI",
      type: "dropdown",
      focus: false,
      undo: false,
      refreshAfterCallback: true,
      options: {
        "Summarise as text": "Summarise as text",
        "Summarise as bullets": "Summarise as bullets",
        "Expand text": "Expand text",
        "Custom prompt": "Custom prompt"
      },
      callback(cmd, val) {
        this.aiButton.transformText(val)
      }
    })
  }
}
