import { getFullscreenElement, getIsFullscreen } from "./helpers"
import { fullscreenClass } from "./constants"
// eslint-disable-next-line
import enterFullscreenIcon from "./icons/enterFullscreenIcon.svg?raw"
// eslint-disable-next-line
import exitFullscreenIcon from "./icons/exitFullscreenIcon.svg?raw"

const toggleFullscreenMode = ({ instance, buttonClass }) => {
  const fullscreenElement = getFullscreenElement()

  const fullscreenButtonIcon = instance.contentDocument.querySelector(
    `div.${buttonClass}--icon`
  )

  if (getIsFullscreen()) {
    fullscreenElement.classList.remove(fullscreenClass)
    fullscreenButtonIcon.innerHTML = enterFullscreenIcon
  } else {
    fullscreenElement.classList.add(fullscreenClass)
    fullscreenButtonIcon.innerHTML = exitFullscreenIcon
  }
}

export default toggleFullscreenMode
