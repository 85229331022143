class @FakeAblyRealtime
  constructor: ->
    @channels = new FakeAblyRealtimeChannelsCollection()

class FakeAblyRealtimeChannel
  subscribe: ->
    console.log('FAKE ABLY: subscribed to channel')

  unsubscribe: ->
    console.log('FAKE ABLY: unsubscribed from channel')

class FakeAblyRealtimeChannelsCollection
  get: ->
    return new FakeAblyRealtimeChannel()
