import React from "react"
import { styled } from "@linaria/react"
import { startCase } from "lodash"
import { bool, oneOf } from "prop-types"

import { MEMBERSHIP_TYPES } from "src/features/meetingPacks/MeetingPack/constants"
import { getMembershipTypeAffix } from "src/features/meetingPacks/helpers"

const MembershipType = ({
  className,
  membershipType,
  hasLimitedVisibilityItems = false
}) => {
  const membershipTypeAffix = getMembershipTypeAffix({
    membershipType,
    hasLimitedVisibilityItems
  })

  return (
    <div className={className}>
      {startCase(membershipType)}
      {membershipTypeAffix && (
        <>
          <br />
          {membershipTypeAffix}
        </>
      )}
    </div>
  )
}

MembershipType.propTypes = {
  membershipType: oneOf(Object.values(MEMBERSHIP_TYPES)).isRequired,
  hasLimitedVisibilityItems: bool
}

export default styled(MembershipType)`
  text-align: right;
  margin-right: 0;
  margin-left: auto;
`
