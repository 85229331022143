import React from "react"
import { Dropdown } from "react-bootstrap"
import { number } from "prop-types"

import { beById, be } from "src/helpers/document"
import Icon from "src/styles/components/Icon"

import { openPrintWindow } from "./helpers"

const PrintMessage = ({ messageId }) => {
  const printMessage = (id) => {
    const selectedInBulkMode = be("message-container").filter(".printable")
    selectedInBulkMode.removeClass("printable")

    const messageContainer = beById("message-container", id)
    messageContainer.addClass("printable")
    messageContainer
      .find(".message-share.message-block__minimizable")
      .removeClass("message-block__minimized")

    openPrintWindow()

    messageContainer.removeClass("printable")
    selectedInBulkMode.addClass("printable")
  }

  return (
    <Dropdown.Item onClick={() => printMessage(messageId)}>
      <div className="dropdown-icon">
        <Icon type="print" />
      </div>
      Print this message
    </Dropdown.Item>
  )
}

PrintMessage.propTypes = {
  messageId: number.isRequired
}

export default PrintMessage
