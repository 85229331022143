import React from "react"
import { bool, func, oneOf, string } from "prop-types"

import { Alert, MessageWrapper, UndoButtton } from "./styles"

const MoveFolderItemFlashMessage = ({ level, message, withUndo, onClose, onUndo }) => {
  return (
    <div className="flash-container">
      <Alert className={`alert is-${level}`}>
        <MessageWrapper>
          <span>{message}</span>

          {withUndo && onUndo && (
            <UndoButtton type="button" aria-label="Undo" onClick={onUndo}>
              Undo
            </UndoButtton>
          )}
        </MessageWrapper>

        <button type="button" className="close" aria-label="Close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
      </Alert>
    </div>
  )
}

MoveFolderItemFlashMessage.propTypes = {
  level: oneOf(["info", "success", "warning", "danger"]),
  message: string,
  withUndo: bool,
  onClose: func.isRequired,
  onUndo: func
}

export default MoveFolderItemFlashMessage
