import React from "react"
import { number } from "prop-types"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { styled } from "@linaria/react"

import Icon from "src/styles/components/Icon"
import UnstyledButton from "src/styles/components/Button/Unstyled"
import { SPACES } from "src/styles/sizes"
import { BASE_COLORS } from "src/styles/colors"
import useExportMeetingForUser from "src/features/meetingPacks/useExportMeetingForUser"

const ExportMeetingPack = ({ meetingPackId, className }) => {
  const exportMeeting = useExportMeetingForUser({ meetingPackId })

  return (
    <OverlayTrigger
      delay={300}
      placement="bottom"
      overlay={<Tooltip id="export-meeting-pack">Export PDF of pack</Tooltip>}
    >
      <UnstyledButton onClick={exportMeeting} className={className}>
        <Icon type="pdf-download" data-testid="export-meeting-pdf" />
      </UnstyledButton>
    </OverlayTrigger>
  )
}

ExportMeetingPack.propTypes = {
  meetingPackId: number.isRequired
}

export default styled(ExportMeetingPack)`
  ${Icon} {
    color: ${BASE_COLORS.darkViolet};
    margin-left: ${SPACES.xSmall};
    margin-top: 0.3rem;
    font-size: 1.75rem;

    &:hover {
      opacity: 0.75;
    }
  }
`
