import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { Dropdown } from "react-bootstrap"
import { shape, number } from "prop-types"

import Icon from "src/styles/components/Icon"
import useIsOpened from "src/hooks/useIsOpened"
import { fetchAccounts } from "src/resources/accounts/thunks"
import { fetchSchemes } from "src/resources/schemes/thunks"
import { fetchSchemeGroups } from "src/resources/groups/thunks"
import { SCHEME_MEMBERSHIP_ROLES } from "src/resources/schemes/constants"

import MoveModal from "./MoveModal"

const MoveDiscussion = ({ account, group, discussion }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchAccounts())
    dispatch(fetchSchemes({ filter: { role: SCHEME_MEMBERSHIP_ROLES.admin } }))
    dispatch(
      fetchSchemeGroups({
        schemeId: group.schemeId,
        filter: { role: SCHEME_MEMBERSHIP_ROLES.admin }
      })
    )
  }, [dispatch])

  const { isOpened, open, close } = useIsOpened()

  return (
    <>
      <Dropdown.Item onClick={open}>
        <div className="dropdown-icon">
          <Icon className="icon-large" type="arrow-right-hollow" />
        </div>
        Move to another group
      </Dropdown.Item>
      <MoveModal
        account={account}
        group={group}
        discussion={discussion}
        isOpened={isOpened}
        close={close}
      />
    </>
  )
}

MoveDiscussion.propTypes = {
  account: shape({}).isRequired,
  group: shape({
    schemeId: number.isRequired
  }),
  discussion: shape({}).isRequired
}

export default MoveDiscussion
