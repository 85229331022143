import React from "react"
import { useDropzone } from "react-dropzone"
import { func, bool, oneOf, number } from "prop-types"
import classNames from "classnames"

import { DropZone } from "./styles"

const ALLOWED_MIME_TYPES_DOCUMENTS = {
  "application/pdf": [".pdf"],
  "application/msword": [".doc"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
  "application/vnd.ms-excel": [".xls"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
  "application/vnd.ms-powerpoint": [".ppt"],
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow": [".ppsx"],
  "image/png": [".png"],
  "image/jpeg": [".jpg", ".jpeg"]
}

const ALLOWED_MIME_TYPES_AUDIO = {
  "audio/mpeg": [".mp3"],
  "audio/wav": [".wav"],
  "audio/ogg": [".ogg", ".oga"],
  "audio/flac": [".flac"],
  "audio/amr": [".amr"],
  "audio/x-ms-wma": [".wma"],
  "audio/x-ms-wav": [".wav"],
  "audio/aiff": [".aiff", ".aif"],
  "audio/aac": [".aac"],
  "audio/x-aiff": [".aiff", ".aif"],
  "audio/x-flac": [".flac"],
  "audio/mp4": [".mp4a", ".m4a"],
  "audio/webm": [".webm"]
}

const ALLOWED_MIME_TYPES_VIDEO = {
  "application/x-mpegURL": ["..m3u8"],
  "video/x-msvideo": [".avi"],
  "video/mpeg": [".mpeg"],
  "video/mp2t": [".ts"],
  "video/mp4": [".mp4"],
  "video/ogg": [".ogv"],
  "video/quicktime": [".mov", ".qt"],
  "video/webm": [".webm"],
  "video/3gpp": [".3gp"],
  "video/3gpp2": [".3g2"]
}

export const FILE_TYPES = {
  documents: ALLOWED_MIME_TYPES_DOCUMENTS,
  audio_and_video: { ...ALLOWED_MIME_TYPES_AUDIO, ...ALLOWED_MIME_TYPES_VIDEO }
}

const UploadZone = ({
  setSelectedFiles,
  multiple,
  allowedFiles,
  onDropRejected = () => {},
  maxSize
}) => {
  const acceptTypes = FILE_TYPES[allowedFiles]
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: setSelectedFiles,
    multiple,
    accept: acceptTypes,
    maxSize,
    onDropRejected
  })

  const placeholderText = `Drop ${
    multiple ? "some files" : "a file"
  } here, or click to select ${multiple ? "files" : "a file"}`

  return (
    <DropZone
      data-testid="drop-zone"
      {...getRootProps({ className: classNames({ "is-drag-active": isDragActive }) })}
    >
      <input {...getInputProps()} />
      {placeholderText}
    </DropZone>
  )
}

UploadZone.propTypes = {
  setSelectedFiles: func.isRequired,
  multiple: bool.isRequired,
  allowedFiles: oneOf(Object.keys(FILE_TYPES)).isRequired,
  maxSize: number,
  onDropRejected: func
}

export default UploadZone
