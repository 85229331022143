import schemes from "src/resources/schemes/api"
import { showFlashMessage } from "src/helpers/flash"
import { showCaughtErrorMessage } from "src/helpers/errors"

import groups from "./api"
import { fetchGroupsFailure, fetchGroupsRequest, fetchGroupsSuccess } from "./slice"

export const fetchSchemeGroups = (params = {}) => (dispatch) => {
  dispatch(fetchGroupsRequest())

  schemes
    .groups(params)
    .then((response) => {
      dispatch(fetchGroupsSuccess(response.data))
    })
    .catch((error) => {
      showCaughtErrorMessage(error)
      dispatch(fetchGroupsFailure(error))
    })
}

export const moveGroup = (data) => () => {
  showFlashMessage("warning", "We are moving the group. Please wait.")

  return groups
    .move(data)
    .then(() => {
      showFlashMessage("info", "Group has been moved.")
    })
    .catch((error) => {
      showCaughtErrorMessage(error)
      throw error
    })
}
