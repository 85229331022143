function importAll(resolve) {
  resolve.keys().forEach((filePath) => {
    const fileClasses = resolve(filePath)
    const fileClassKeys = Object.keys(fileClasses)
    fileClassKeys.forEach((key) => {
      window[key] = fileClasses[key]
    })
  })
}

importAll(require.context("../src/helpers", true, /parseLinks\.js$/))
importAll(require.context("../src/helpers", true, /froalaPasteLinksIssues\.js$/))
importAll(require.context("./services", true, /\.coffee$/))
importAll(require.context("./components", true, /\.coffee$/))
importAll(require.context("./components", true, /\.js$/))
importAll(require.context("./helpers", true, /\.coffee$/))
importAll(require.context("./models", true, /\.coffee$/))
importAll(require.context("./page_handlers", true, /\.coffee$/))
