import React, { useCallback } from "react"
import { components } from "react-select"

import Tag from "src/styles/components/Tag"

import { MenuIcon } from "./styles"

export const useOption = ({ newColorId, handleMenuIconClick }) =>
  useCallback(
    ({ children, ...props }) => {
      const { __isNew__: isNew, colorId } = props.data
      const color = isNew ? newColorId : colorId

      return (
        <components.Option {...props}>
          <div>
            {isNew && <span className="mrx">Create</span>}
            <Tag color={color}>{children}</Tag>
          </div>
          {props.isFocused && !isNew && (
            <MenuIcon type="tridot" onClick={(e) => handleMenuIconClick(e, props.data)} />
          )}
        </components.Option>
      )
    },
    [newColorId, handleMenuIconClick]
  )
