import React from "react"
import { Dropdown } from "react-bootstrap"
import { number, string } from "prop-types"

import useIsOpened from "src/hooks/useIsOpened"

import ExportModal from "./ExportModal"

const Export = ({ id, name }) => {
  const { open, close, isOpened } = useIsOpened(false)

  return (
    <>
      <Dropdown.Item onClick={open}>Export as pdf</Dropdown.Item>
      <ExportModal isOpened={isOpened} id={id} name={name} close={close} />
    </>
  )
}

Export.propTypes = {
  id: number.isRequired,
  name: string.isRequired
}

export default Export
