import { styled } from "@linaria/react"

import { BASE_COLORS, ICON_COLORS } from "src/styles/colors"

export const CardTitle = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
  align-items: flex-start;
  font-size: 1.1rem;
`

export const UserFullName = styled.h6`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${BASE_COLORS.darkViolet};
`

export const UserJobTitle = styled.div`
  color: ${ICON_COLORS.header};
  font-size: 0.75rem;
  text-align: left;
`
