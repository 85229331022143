import React from "react"
import { Form, InputGroup } from "react-bootstrap"
import { useFormikContext } from "formik"
import { func, string } from "prop-types"
import { styled } from "@linaria/react"

import LinkButton from "src/styles/components/Button/Link"
import { FONT_WEIGHTS } from "src/styles/sizes"

const numberShortcut = (number) => `***${number.slice(-3)}`

const CodeInput = ({ moveToFirstStep, error, className }) => {
  const { handleChange, handleBlur, values, errors, touched } = useFormikContext()

  return (
    <div className={className}>
      <h5 className="mt-4 text-muted">Step 2</h5>
      <p className="text-muted">
        Enter the 6-digit code that has been messaged to your phone number ending{" "}
        {numberShortcut(values.phone)}
      </p>

      <Form.Group controlId="code">
        <Form.Label className="text-muted small mb-1">6-digit code</Form.Label>
        <InputGroup>
          <Form.Control
            value={values.code}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            isInvalid={error || (errors.code && touched.code)}
          />
          <Form.Control.Feedback type="invalid">
            {error || errors.code}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>

      <small className="font-weight-bold text-muted">No code</small>
      <p className="text-muted">
        If you haven’t received the code, please check your number and resend:
      </p>
      <LinkButton onClick={moveToFirstStep}>Check mobile number</LinkButton>
    </div>
  )
}

CodeInput.propTypes = {
  moveToFirstStep: func.isRequired,
  error: string
}

export default styled(CodeInput)`
  label {
    font-weight: ${FONT_WEIGHTS.bold};
  }
`
