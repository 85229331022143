import React, { useState } from "react"
import { bool, func, number, shape, string } from "prop-types"
import { styled } from "@linaria/react"
import { Spinner } from "react-bootstrap"

import { getContainerId } from "src/features/signedDocuments/helpers"
import { composeVariationsClasses } from "src/helpers/linaria"

import { useSignedDocumentPSPDFKit } from "./useSignedDocumentPSPDFKit"

const SignedDocument = ({
  className,
  isCurrentUserAuthor,
  document,
  updateDocument,
  close,
  setIsSavingChanges
}) => {
  const documentId = document.id
  const isSigned = Boolean(document.signedAt)
  const containerId = getContainerId(documentId)
  const [isSigning, setIsSigning] = useState(false)
  const documentName = `${isSigned ? "[SIGNED]" : ""} ${document.name}.pdf`
  const classNameWithVariations = composeVariationsClasses(className, { isSigning })

  useSignedDocumentPSPDFKit({
    documentId,
    containerId,
    isSigned,
    close,
    setIsSavingChanges,
    updateDocument,
    setIsSigning,
    isCurrentUserAuthor,
    documentName,
    pspdfkitId: document.signedPSPDFKitId || document.pspdfkitId,
    jwt: document.signedDocumentJwt || document.jwt
  })

  return (
    <div className={classNameWithVariations}>
      <div id={containerId} className="h-100" />
      <div className="is-signing-shade">
        <Spinner animation="border" variant="secondary" />
      </div>
    </div>
  )
}

SignedDocument.propTypes = {
  isCurrentUserAuthor: bool.isRequired,
  document: shape({
    id: number.isRequired,
    jwt: string,
    signedPSPDFKitId: string,
    pspdfkitId: string,
    signedDocumentJwt: string,
    signedAt: string
  }).isRequired,
  setIsSavingChanges: func.isRequired,
  updateDocument: func.isRequired,
  close: func.isRequired
}

export default styled(SignedDocument)`
  height: 100%;
  position: relative;

  .is-signing-shade {
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    left: 0;
    top: 0;
    display: none;
    height: 100%;
    width: 100%;
  }

  &--is-signing {
    .is-signing-shade {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`
