import React from "react"

import { MessageContainer } from "./styles"

const Message = ({ children }) => {
  return (
    <MessageContainer>
      <span>{children}</span>
    </MessageContainer>
  )
}

export default Message
