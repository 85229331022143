import React, { useRef, useState, useEffect, useCallback, useLayoutEffect } from "react"
import { number, string } from "prop-types"

import ContentLoader from "src/styles/components/ContentLoader"
import useAiGenerateText from "src/hooks/ai/useGenerateText"
import { useCommandResult } from "src/features/UniversalAi/store"

import { Base, Content, Container, Footer } from "../Base"
import scrollPositionHandler from "../scrollPositionHandler"

const TITLE_TAG = "h6"

const Summarise = ({ objectId, objectType, command }) => {
  const contentRef = useRef()

  // This is workaround to avoid blinking content of AI sidebar when navigating
  // from page to page. Hopefully, we'll remove this after we rewrite whole app
  // into React
  const { commandResult, setCommandResult } = useCommandResult()
  const [initialized, setInitialized] = useState(!!commandResult?.summarisedContent)

  const contentElementFindFunc = useCallback(() => {
    if (initialized) {
      return null
    }

    return contentRef.current
  }, [initialized])

  const { summarisedContent, isLoading, finished } = useAiGenerateText({
    prompt: command,
    objectId,
    objectType,
    contentElementFindFunc
  })

  // For unknown reason, we can't extract it into custom hook,
  // e.g. useSavedScrollPosition, we have to call useLayoutEffect directly,
  // otherwise scrollbar "flashes" when scroll position is restored.
  useLayoutEffect(
    () =>
      scrollPositionHandler({
        element: contentRef.current,
        key: `${command}/${objectType}/${objectId}`,
        restorePosition: initialized
      }),
    [!!contentRef.current, initialized, command, objectType, objectId]
  )

  const commandSummarisedContent = commandResult?.summarisedContent || summarisedContent

  useEffect(() => {
    if (!finished) return

    setCommandResult({ summarisedContent })
    setInitialized(true)
  }, [finished, summarisedContent])

  const title =
    commandSummarisedContent && commandSummarisedContent.includes(`<${TITLE_TAG}>`)
      ? commandSummarisedContent
      : command

  return (
    <Base title={title}>
      <Container>
        <Content ref={contentRef}>
          {!commandSummarisedContent && isLoading && <ContentLoader />}
          {commandSummarisedContent && (
            /* eslint-disable-next-line react/no-danger */
            <div dangerouslySetInnerHTML={{ __html: commandSummarisedContent }} />
          )}
        </Content>
      </Container>
      <Footer
        loading={!initialized && !finished}
        content={commandSummarisedContent}
        contentRef={contentRef}
      />
    </Base>
  )
}

Summarise.propTypes = {
  objectId: number.isRequired,
  objectType: string.isRequired,
  command: string.isRequired
}

export default Summarise
