import React from "react"
import { bool, func, number } from "prop-types"

import Icon from "src/styles/components/Icon"

import { MobileFilterToggleButton, AppliedFiltersCount } from "./styles"

const MobileFilterToggle = ({
  isFilterShown,
  toggleFilterShown,
  appliedFiltersCount
}) => {
  return (
    <MobileFilterToggleButton
      onClick={toggleFilterShown}
      className={isFilterShown ? "filter-is-shown" : ""}
    >
      {isFilterShown ? "Hide " : "Show "}
      filtering options&nbsp;
      {!isFilterShown && appliedFiltersCount > 0 && (
        <AppliedFiltersCount>({appliedFiltersCount})</AppliedFiltersCount>
      )}
      <Icon type="dropdown-arrow" />
    </MobileFilterToggleButton>
  )
}

MobileFilterToggle.propTypes = {
  isFilterShown: bool.isRequired,
  toggleFilterShown: func.isRequired,
  appliedFiltersCount: number.isRequired
}

export default MobileFilterToggle
