import { styled } from "@linaria/react"
import { lighten } from "polished"

import AvatarImageContainer from "src/components/UsersContacts/AvatarImageContainer"
import { BOX_SHADOWS } from "src/styles/shadows"
import { FONT_SIZES, FONT_WEIGHTS, SPACES } from "src/styles/sizes"
import { BASE_COLORS, BORDER_COLORS, TEXT_COLORS } from "src/styles/colors"

const lightenTextMutedColor = lighten(0.25, TEXT_COLORS.muted)
const avatarWidth = "1.75rem"

export const ResultCardContainer = styled.div`
  background: ${BASE_COLORS.white};
  box-shadow: ${BOX_SHADOWS.standardBlock};
  padding: ${SPACES.small} ${SPACES.medium} ${SPACES.small};
  border-radius: ${SPACES.xxSmall};
  border: none;
  flex: 0 0 auto;
  margin: 0 0 ${SPACES.medium} 0;
  position: relative;
  width: 100%;

  .highlight {
    background-color: yellow;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  & .round-toggle,
  & ${AvatarImageContainer} {
    font-size: 0.6rem;
    height: ${avatarWidth};
    min-width: ${avatarWidth};
    width: ${avatarWidth};
    margin-right: ${SPACES.xSmall};
  }
`

export const TitleText = styled.span`
  font-weight: ${FONT_WEIGHTS.bold};
  font-size: ${FONT_SIZES.medium};

  &.user-full-name {
    color: #1a3c29;
  }
`

export const TitleDatetime = styled.span`
  color: ${TEXT_COLORS.muted};
  font-size: ${FONT_SIZES.small};
  margin-left: ${SPACES.xSmall};
  white-space: nowrap;
`

export const Answer = styled.h2`
  font-size: ${FONT_SIZES.xLarge};
  color: ${TEXT_COLORS.heading};
  margin-top: ${SPACES.xSmall};
  margin-bottom: ${SPACES.small};
`

export const BadgeContainer = styled.div`
  background: ${BASE_COLORS.white};
  border-radius: 100%;
  border: 1px solid ${BORDER_COLORS.searchResult};
  color: ${BORDER_COLORS.searchResult};
  height: ${SPACES.semiLarge};
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  transform: translate(-50%, -50%);
  width: ${SPACES.semiLarge};
`

export const ResultText = styled.div`
  color: ${BASE_COLORS.darkViolet};
  margin: ${SPACES.xSmall} 0 ${SPACES.small} 0;
  word-break: break-word;
`

export const FooterContainer = styled.div`
  font-size: ${FONT_SIZES.small};
  color: ${TEXT_COLORS.muted};
`

export const FooterBreadcrumbsLink = styled.a`
  color: ${TEXT_COLORS.muted};

  &:not([href]) {
    pointer-events: none;
  }

  &:after {
    color: ${TEXT_COLORS.muted};
    content: " > ";
  }

  &:last-child:after {
    display: none;
  }

  &:hover,
  &:active {
    color: ${lightenTextMutedColor};
  }
`
