import React from "react"

import {
  ListHeader,
  ListHeaderContainer,
  ListHeaderWrapper,
  ListHeaderActions
} from "src/styles/components/ListHeader"

import UploadButton from "./UploadButton"

const ItemsHeader = (props) => {
  return (
    <ListHeaderWrapper>
      <div />
      <ListHeaderContainer>
        <ListHeader className="elevated-on-mobile">Documents</ListHeader>
        <ListHeaderActions>
          <UploadButton {...props} />
        </ListHeaderActions>
      </ListHeaderContainer>
    </ListHeaderWrapper>
  )
}

export default ItemsHeader
