import React from "react"
import { string } from "prop-types"

import { ICON_TYPES } from "src/features/Search/constants"
import Icon from "src/styles/components/Icon"

const IconLabel = ({ value, label }) => {
  const [type] = value?.split("/") || [null]
  const iconType = ICON_TYPES[type]

  return (
    <span className="d-flex align-items-center">
      {iconType && <Icon type={iconType} className="mr-1" />} {label}
    </span>
  )
}

IconLabel.propTypes = {
  label: string.isRequired,
  value: string
}

export default IconLabel
