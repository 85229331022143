import React from "react"
import classNames from "classnames"
import { components } from "react-select"
import { bool, number, shape } from "prop-types"

import BadgeIcon from "src/styles/components/BadgeIcon"
import Tag from "src/styles/components/Tag"

const Option = ({ children, ...props }) => {
  const badgeClasses = classNames({
    "scheme-badge": props.data.isScheme,
    [`badge-modifier-${props.data.colorId}`]: props.data.isScheme
  })

  return (
    <components.Option {...props}>
      {props.data.isDefault ? (
        <span className="text-nowrap">{children}</span>
      ) : (
        <Tag className={badgeClasses} color={props.data.colorId}>
          {children}
          {props.data.joint && <BadgeIcon type="connected" />}
        </Tag>
      )}
    </components.Option>
  )
}

Option.propTypes = {
  data: shape({
    colorId: number,
    isScheme: bool,
    isDefault: bool,
    joint: bool
  }).isRequired
}

export default Option
