import React from "react"
import { Form } from "react-bootstrap"
import { oneOf, oneOfType, number, func } from "prop-types"
import pluralize from "pluralize"

import { ACTION_TYPES } from "../constants"
import { NumberInputSectionContainer } from "../styles"

const startText = (actionType) => {
  if (actionType === ACTION_TYPES.must_read) return "Close after"
  if (actionType === ACTION_TYPES.voting) return "Resolve vote after"
}

const endText = (actionType, selectedNumber) => {
  if (actionType === ACTION_TYPES.must_read)
    return `${pluralize("has", selectedNumber)} marked as read`
  if (actionType === ACTION_TYPES.voting)
    return selectedNumber === 1 ? "votes" : "votes in agreement"
}

const NumberInputSection = ({
  actionType,
  selectedNumber,
  setSelectedNumber,
  maxNumber
}) => {
  const minNumber = 1

  const onChange = (e) => {
    if (e.target.value === "") {
      return setSelectedNumber(e.target.value)
    }

    const count = parseInt(e.target.value, 10)
    if (!Number.isInteger(count)) return

    if (count < minNumber || count > maxNumber) {
      return setSelectedNumber(selectedNumber)
    }

    setSelectedNumber(count)
  }
  const valueValid = selectedNumber && !Number.isNaN(selectedNumber)

  return (
    <NumberInputSectionContainer>
      {startText(actionType)}
      <Form.Control
        value={selectedNumber}
        type="number"
        size="sm"
        min={1}
        max={maxNumber}
        isInvalid={!valueValid}
        step={1}
        pattern="[0-9]+"
        onChange={onChange}
      />
      of {maxNumber} {endText(actionType, selectedNumber)}
    </NumberInputSectionContainer>
  )
}

NumberInputSection.propTypes = {
  actionType: oneOf(Object.keys(ACTION_TYPES)).isRequired,
  selectedNumber: oneOfType([number, oneOf([""])]).isRequired,
  setSelectedNumber: func.isRequired,
  maxNumber: number.isRequired
}

export default NumberInputSection
