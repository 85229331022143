import React, { useState } from "react"
import { func } from "prop-types"
import { Form, InputGroup } from "react-bootstrap"
import { useMutation } from "@apollo/client"

import { StyledModal } from "src/styles/components/Modal"
import ModalTitle from "src/styles/components/Modal/Title"
import ModalHeader from "src/styles/components/Modal/Header"
import ModalBody from "src/styles/components/Modal/Body"
import ModalFooter from "src/styles/components/Modal/Footer"
import { getCurrentUser } from "src/helpers/user"
import LinkButton from "src/components/LinkButton"

import { addPasskeyDevice } from "./actions"
import { storePasskeyDeviceId } from "./storage"
import createPasskeyUserMutation from "./createPasskeyUser.gql"
import sendPasskeyOtpMutation from "./sendPasskeyOtp.gql"
import checkPasskeyOtpMutation from "./checkPasskeyOtp.gql"

const EnableModal = ({ close, setDeviceAdded }) => {
  const { email } = getCurrentUser()
  const [otpId, setOtpId] = useState()
  const [otpCode, setOtpCode] = useState("")
  const [waitingForOtp, setWaitingForOtp] = useState(false)
  const [otpCodeError, setOtpCodeError] = useState()
  const [createPasskeyUser] = useMutation(createPasskeyUserMutation)
  const [sendPasskeyOtp] = useMutation(sendPasskeyOtpMutation)
  const [checkPasskeyOtp] = useMutation(checkPasskeyOtpMutation)
  const emailShortcut = `${email[0]}***@${email.split("@").slice(-1)}`
  const [wasResent, setWasResent] = useState(false)

  const handleOtpChange = (e) => {
    setOtpCode(e.target.value)
    setOtpCodeError(null)
  }

  const sendOTP = () =>
    createPasskeyUser()
      .then(sendPasskeyOtp)
      .then(({ data = {} }) => {
        setOtpId(data.sendPasskeyOtp?.otpId)
        setWaitingForOtp(true)
      })

  const resendOTP = () => {
    sendOTP()
    setWasResent(true)
  }

  const addDevice = async () => {
    checkPasskeyOtp({ variables: { otpCode, otpId } }).then(({ data = {} }) => {
      if (data.checkPasskeyOtp?.successful)
        addPasskeyDevice(data.checkPasskeyOtp?.authToken)
          .then((response) => storePasskeyDeviceId(email, response.id))
          .then(() => {
            setDeviceAdded(true)
            setWaitingForOtp(false)
            setOtpCode("")
            close()
          })
      else setOtpCodeError(data.checkPasskeyOtp?.error)
    })
  }

  return (
    <StyledModal size="md" onHide={close} show>
      <ModalHeader>
        <ModalTitle>Enable Passkey Login</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <p className="text-muted">
          Click below to send a temporary code to your email to enable Passkey login for
          this device.
        </p>
        {waitingForOtp ? (
          <Form.Group controlId="otpCode">
            <p className="text-muted">
              Enter the 6-digit code that has been sent to your email {emailShortcut}
            </p>

            <InputGroup className="mb-2">
              <Form.Control
                value={otpCode}
                placeholder=""
                onChange={handleOtpChange}
                isInvalid={!!otpCodeError}
              />
              <Form.Control.Feedback type="invalid">{otpCodeError}</Form.Control.Feedback>
            </InputGroup>

            <small className="font-weight-bold text-muted">No code</small>
            <p className="text-muted">
              {wasResent
                ? "If you haven’t received the code, please check your Spam folder"
                : "If you haven’t received the code, please check your Spam folder or click resend."}
            </p>
            {!wasResent && <LinkButton onClick={resendOTP}>Resend</LinkButton>}
          </Form.Group>
        ) : (
          <button type="button" className="button" onClick={sendOTP}>
            Send code
          </button>
        )}
      </ModalBody>
      <ModalFooter>
        <button className="button is-secondary" type="button" onClick={close}>
          Cancel
        </button>
        <button
          className="button is-primary"
          type="button"
          onClick={addDevice}
          disabled={!otpCode}
        >
          Activate
        </button>
      </ModalFooter>
    </StyledModal>
  )
}

EnableModal.propTypes = {
  close: func.isRequired,
  setDeviceAdded: func.isRequired
}

export default EnableModal
