import { useMutation, useQuery } from "@apollo/client"

import storedSignaturesQuery from "./storedSignatures.gql"
import deleteStoredSignatureMutation from "./delete.gql"
import updateStoredSignaturesMutation from "./update.gql"

const useStoredSignatures = () => {
  const storedSignaturesData = useQuery(storedSignaturesQuery)
  const [deleteStoredSignature] = useMutation(deleteStoredSignatureMutation)
  const [updateStoredSignatures] = useMutation(updateStoredSignaturesMutation)

  return {
    storedSignatures: storedSignaturesData?.data?.storedSignatures || [],
    deleteStoredSignature,
    updateStoredSignatures
  }
}

export default useStoredSignatures
