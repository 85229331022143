import React, { useCallback, useContext, useEffect } from "react"
import { bool, number } from "prop-types"

import isOfflineEnabled from "src/features/offline/isOfflineEnabled"
import MeetingPackOfflineContext from "src/features/meetingPacks/MeetingPack/View/MeetingPackOfflineContext"

import { IconDownload, OfflineToggleButton, OfflineStatusText } from "./styles"
import SyncStatus from "./SyncStatus"

const OfflineToggle = ({ meetingPackId, canSaveOffline, className }) => {
  const { isSavedOffline, saveMeetingOffline } = useContext(MeetingPackOfflineContext)

  useEffect(() => {
    if (canSaveOffline && isOfflineEnabled() && isSavedOffline === false) {
      saveMeetingOffline()
    }
  }, [isSavedOffline])

  const toggleOfflineState = useCallback(
    (e) => {
      e.stopPropagation()

      if (!isSavedOffline) {
        saveMeetingOffline()
      }
    },
    [isSavedOffline, meetingPackId]
  )

  if (isSavedOffline === undefined) {
    return null
  }

  return (
    canSaveOffline && (
      <OfflineToggleButton
        savedOffline={isSavedOffline}
        onClick={toggleOfflineState}
        className={className}
      >
        {isOfflineEnabled() &&
          (isSavedOffline ? (
            <SyncStatus meetingPackId={meetingPackId} />
          ) : (
            <>
              <OfflineStatusText>Make available offline</OfflineStatusText>
              <IconDownload />
            </>
          ))}
      </OfflineToggleButton>
    )
  )
}

OfflineToggle.propTypes = {
  canSaveOffline: bool.isRequired,
  meetingPackId: number.isRequired
}

export default OfflineToggle
