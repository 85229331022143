import { SchemeMembershipWatcherComponent } from "legacy/components/scheme_membership_watcher_component.js.coffee"

export default class ContactsIndexHandler {
  constructor() {
    this.membershipWatcher = new SchemeMembershipWatcherComponent()
  }

  setup() {
    this.membershipWatcher.setup()
  }

  teardown() {
    this.membershipWatcher.teardown()
  }
}
