import { useSelector } from "react-redux"

const useParentAgendaItemMemberships = (agendaItem) => {
  const agendaItems = useSelector((state) => state.meetingPack.agendaItems)

  const parentAgendaItem = agendaItems.find((item) => item.id === agendaItem.agendaItemId)
  return parentAgendaItem?.memberships || []
}

export default useParentAgendaItemMemberships
