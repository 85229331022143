import { useMutation } from "@apollo/client"

import uploadFolderItemsMutation from "./uploadFolderItemsMutation.gql"

const fileToParams = (file) => {
  return {
    name: file.name,
    path: file.path,
    storagePath: file.storagePath
  }
}

const filesToParams = (folder) => {
  const storedFiles = folder.files.filter((file) => !!file.storagePath)
  return storedFiles.map(fileToParams)
}

const folderToParams = (folder) => {
  return {
    name: folder.name,
    path: folder.path,
    files: filesToParams(folder),
    folders: folder.folders.map(folderToParams)
  }
}

const useUploadFolderItems = () => {
  const [mutate] = useMutation(uploadFolderItemsMutation)

  const uploadFolderItems = ({ groupId, folderId, uploadFolder }) => {
    return mutate({
      variables: {
        groupId,
        boxFolderId: folderId,
        ...folderToParams(uploadFolder)
      }
    }).then((response) => response.data?.uploadFolderItemsIntoGroup)
  }

  return uploadFolderItems
}

export default useUploadFolderItems
