import React, { useEffect, useRef, useState } from "react"
import { styled } from "@linaria/react"
import { Modal } from "react-bootstrap"
import { arrayOf, bool, func, number, string, shape } from "prop-types"

import apiClient from "src/app/apiClient"
import { be } from "src/helpers/document"
import LinkButton from "src/components/LinkButton"
import { showFlashMessage } from "src/helpers/flash"

import Filter from "./Filter"
import SelectedUsersCount from "./SelectedUsersCount"
import UserCard from "./UserCard"
import TeamCard from "./TeamCard"

const AddNewPeopleBtn = styled(LinkButton)`
  margin-left: 1rem;
`

const SelectedTeamsDivider = styled.div`
  position: relative;
  &:after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #e4e7ec;
    width: 93%;
    transform: translateX(-50%);
    bottom: 0;
    left: 50%;
  }
`

const AddNonAttendeesModal = ({
  meetingPackId,
  nonAttendees,
  teams,
  isOpened,
  close
}) => {
  const [filteredUsers, setFilteredUsers] = useState([])
  const [selectedUsers, setSelectedUsers] = useState([])
  const [filteredTeams, setFilteredTeams] = useState([])
  const [selectedTeams, setSelectedTeams] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const modalContainerRef = useRef(null)

  useEffect(() => {
    setFilteredUsers(nonAttendees)
    setFilteredTeams(teams)
  }, [
    nonAttendees
      .map(({ id }) => id)
      .sort()
      .join(","),
    teams
      .map(({ id }) => id)
      .sort()
      .join(",")
  ])

  const usersComparator = (userA, userB) =>
    userA.lastName.toLowerCase().localeCompare(userB.lastName.toLowerCase())

  const filterUsers = (term) => {
    const selectedUsersIds = selectedUsers.map(({ id }) => id)
    const notSelectedUsers = nonAttendees.filter(
      (user) => !selectedUsersIds.includes(user.id)
    )

    if (!term) return setFilteredUsers(notSelectedUsers)

    const lowercasedTerm = term.toLowerCase()

    setFilteredUsers(
      notSelectedUsers.filter(
        (user) =>
          (user.fullName || "").toLowerCase().includes(lowercasedTerm) ||
          user.email.toLowerCase().includes(lowercasedTerm) ||
          (user.jobTitle || "").toLowerCase().includes(lowercasedTerm)
      )
    )
  }

  const filterTeams = (term) => {
    const selectedTeamsIds = selectedTeams.map(({ id }) => id)
    const notSelectedTeams = teams.filter((team) => !selectedTeamsIds.includes(team.id))

    if (!term) return setFilteredTeams(notSelectedTeams)

    const lowercasedTerm = term.toLowerCase()

    setFilteredTeams(
      notSelectedTeams.filter((team) => team.name.toLowerCase().includes(lowercasedTerm))
    )
  }

  const filter = (term) => {
    filterUsers(term)
    filterTeams(term)
  }

  const selectUser = (user, role) => {
    if (!role) return

    setSelectedUsers([...selectedUsers, { ...user, role }].sort(usersComparator))
    setFilteredUsers(filteredUsers.filter((filteredUser) => filteredUser.id !== user.id))
  }

  const deselectUser = (user) => {
    setSelectedUsers(selectedUsers.filter((selectedUser) => selectedUser.id !== user.id))

    setFilteredUsers(
      [...filteredUsers, { ...user, role: "", membershipType: "" }].sort(usersComparator)
    )
  }

  const updateUserRole = (user, role) => {
    if (!role) {
      deselectUser(user)
      return
    }

    const index = selectedUsers.findIndex((selectedUser) => selectedUser.id === user.id)
    selectedUsers[index].role = role
    setSelectedUsers([...selectedUsers])
  }

  const selectTeam = (team, role) => {
    if (!role) return

    const teamMembersIds = team.teamMemberships.map(({ userId }) => userId)
    const selectedUsersIds = selectedUsers.map(({ id }) => id)
    const teamMembers = nonAttendees
      .filter(
        (user) => teamMembersIds.includes(user.id) && !selectedUsersIds.includes(user.id)
      )
      .map((member) => ({ ...member, role }))

    setSelectedTeams([...selectedTeams, { ...team, role }])
    setFilteredTeams(filteredTeams.filter((filteredTeam) => filteredTeam.id !== team.id))
    setSelectedUsers([...selectedUsers, ...teamMembers].sort(usersComparator))
    setFilteredUsers(
      filteredUsers.filter((filteredUser) => !teamMembersIds.includes(filteredUser.id))
    )
  }

  const deselectTeam = (team) => {
    const teamMembersIds = team.teamMemberships.map(({ userId }) => userId)
    const selectedTeamMembers = selectedUsers
      .filter((user) => teamMembersIds.includes(user.id))
      .map((user) => ({ ...user, role: "", membershipType: "" }))

    setSelectedTeams(selectedTeams.filter((selectedTeam) => selectedTeam.id !== team.id))
    setSelectedUsers(selectedUsers.filter((user) => !teamMembersIds.includes(user.id)))
    setFilteredTeams([...filteredTeams, { ...team, role: "", membershipType: "" }])
    setFilteredUsers([...filteredUsers, ...selectedTeamMembers].sort(usersComparator))
  }

  const updateTeamRole = (team, role) => {
    if (!role) {
      deselectTeam(team)
      return
    }

    const teamMembersIds = team.teamMemberships.map(({ userId }) => userId)
    const index = selectedTeams.findIndex((selectedTeam) => selectedTeam.id === team.id)
    selectedTeams[index].role = role
    setSelectedTeams([...selectedTeams])
    setSelectedUsers(
      selectedUsers.map((user) =>
        teamMembersIds.includes(user.id) ? { ...user, role } : user
      )
    )
  }

  const updateTeamMembershipType = (team, membershipType) => {
    const teamMembersIds = team.teamMemberships.map(({ userId }) => userId)

    const index = selectedTeams.findIndex((selectedTeam) => selectedTeam.id === team.id)
    selectedTeams[index].membershipType = membershipType
    setSelectedTeams([...selectedTeams])

    setSelectedUsers(
      selectedUsers.map((user) =>
        teamMembersIds.includes(user.id) ? { ...user, membershipType } : user
      )
    )
  }

  const openAddNewUsersModal = () => {
    close()
    be("add-members-modal").modal("show")
  }

  const scrollModalContainerTop = () =>
    modalContainerRef.current.scrollTo({ top: 0, behavior: "smooth" })

  const updateUserMembershipType = (user, membershipType) => {
    const index = selectedUsers.findIndex((selectedUser) => selectedUser.id === user.id)
    selectedUsers[index].membershipType = membershipType
    setSelectedUsers([...selectedUsers])
  }

  const resetState = () => {
    setIsSubmitting(false)
    setSelectedUsers([])
    setFilteredUsers(nonAttendees)
    setSelectedTeams([])
    setFilteredTeams(teams)
  }

  const addUsersToMeetingPack = () => {
    const submitParams = selectedUsers.reduce(
      (params, user) => ({
        ...params,
        [user.id]: {
          email: user.email,
          role: user.role,
          membership_type: user.membershipType
        }
      }),
      {}
    )

    setIsSubmitting(true)

    apiClient
      .post(`/meeting_packs/${meetingPackId}/memberships`, { users: submitParams })
      .then(() => {
        resetState()
        close()
        window.dispatchEvent(new Event("meetingMembershipsUpdated"))
        showFlashMessage("success", "Users have been invited to meeting pack.")
      })
      .catch(() => {
        close()

        showFlashMessage("danger", "Could not invite users")
      })
  }

  const closeModal = () => {
    resetState()
    close()
  }

  const anyResults =
    filteredUsers.length > 0 ||
    selectedUsers.length > 0 ||
    filteredTeams.length > 0 ||
    selectedTeams.length > 0

  const isSelectedUsersValid =
    selectedUsers.length > 0 &&
    selectedUsers.every((user) => user.role && user.membershipType)

  return (
    <Modal
      show={isOpened}
      onHide={close}
      data-behavior="add-account-members-modal"
      className="add-account-members-modal"
      scrollable
    >
      <Modal.Header closeButton>
        <h5
          className="modal-title"
          style={{ display: "inline" }}
          data-behavior="modal-title"
        >
          Add from other meeting packs
        </h5>
        <AddNewPeopleBtn onClick={openAddNewUsersModal}>Add new people</AddNewPeopleBtn>
      </Modal.Header>
      <div className="modal-content-subheader pbn">
        <Filter onChange={filter} />
        <SelectedUsersCount
          count={selectedUsers.length}
          onClick={scrollModalContainerTop}
        />
      </div>
      <div className="modal-content-container" ref={modalContainerRef}>
        <div
          className="card-list account-member-list"
          data-behavior="selected-users-list"
        >
          {selectedTeams.map((team) => (
            <TeamCard
              key={team.id}
              team={team}
              onRoleChange={(role) => updateTeamRole(team, role)}
              onMembershipTypeChange={(membershipType) =>
                updateTeamMembershipType(team, membershipType)
              }
              onDeselect={() => deselectTeam(team)}
              selected
            />
          ))}
          {selectedTeams.length > 0 && <SelectedTeamsDivider />}
          {selectedUsers.map((user) => (
            <UserCard
              key={user.id}
              user={user}
              onRoleChange={(role) => updateUserRole(user, role)}
              onMembershipTypeChange={(membershipType) =>
                updateUserMembershipType(user, membershipType)
              }
              onDeselect={() => deselectUser(user)}
              selected
            />
          ))}
        </div>
        <div className="text-center ptx pbx">All people</div>
        <div className="card-list account-member-list">
          {!anyResults && <div className="empty pam">No results</div>}
          {filteredTeams.map((team) => (
            <TeamCard
              key={team.id}
              team={team}
              onRoleChange={(role) => selectTeam(team, role)}
            />
          ))}
          {filteredTeams.length > 0 && filteredUsers.length > 0 && (
            <hr className="team-divider" />
          )}
          {filteredUsers.map((user) => (
            <UserCard
              key={user.id}
              user={user}
              onRoleChange={(role) => selectUser(user, role)}
            />
          ))}
        </div>
      </div>
      <Modal.Footer className="align-horizontally-center">
        <button
          type="submit"
          className="button"
          disabled={isSubmitting || !isSelectedUsersValid}
          onClick={addUsersToMeetingPack}
        >
          Confirm
        </button>
        <button className="button is-secondary" onClick={closeModal}>
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  )
}

AddNonAttendeesModal.propTypes = {
  meetingPackId: number.isRequired,
  nonAttendees: arrayOf(
    shape({
      id: number.isRequired,
      email: string.isRequired,
      initials: string.isRequired,
      avatarColorId: number.isRequired,
      avatarUrl: string,
      fullName: string,
      lastName: string,
      jobTitle: string
    })
  ),
  teams: arrayOf(shape({ id: number.isRequired, name: string.isRequired })),
  isOpened: bool,
  close: func.isRequired
}

export default AddNonAttendeesModal
