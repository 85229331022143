import React from "react"
import classNames from "classnames"
import { number, string, func, bool } from "prop-types"

import FileIcon from "src/styles/components/FileIcon"
import { splitFilename } from "src/helpers/string"
import { ItemsListRowActions } from "src/styles/components/ItemsList"

import DocumentActions from "./DocumentActions"
import {
  DocumentsListRow,
  DocumentsListCard,
  RearrangeIcon,
  PreviewButton
} from "./styles"

const DocumentRow = ({
  isDeleting,
  filename,
  pagesCount,
  documentId,
  rawDownloadUrl,
  deleteDocument,
  openPreviewModal,
  openRenameModal,
  openCopyToFolderModal
}) => {
  const { name, extension } = splitFilename(filename)

  const deleteDocumentById = () => {
    deleteDocument({
      id: documentId,
      confirmationMessage: `Are you sure you want to delete document "${name}"?`
    })
  }

  return (
    <DocumentsListRow className={classNames({ "is-deleting": isDeleting })}>
      <DocumentsListCard>
        <div className="file-name">
          <FileIcon extension={extension} />
          <PreviewButton
            variant="link"
            className="text-truncate"
            onClick={openPreviewModal}
          >
            {name}
          </PreviewButton>
        </div>
        <div className="text-muted no-wrap">{pagesCount} pages</div>
      </DocumentsListCard>
      <ItemsListRowActions>
        <RearrangeIcon type="burger-menu" />
        <DocumentActions
          rawDownloadUrl={rawDownloadUrl}
          isDeleting={isDeleting}
          deleteDocument={deleteDocumentById}
          openRenameModal={openRenameModal}
          openCopyToFolderModal={openCopyToFolderModal}
        />
      </ItemsListRowActions>
    </DocumentsListRow>
  )
}

DocumentRow.propTypes = {
  documentId: number.isRequired,
  deleteDocument: func.isRequired,
  openPreviewModal: func.isRequired,
  openRenameModal: func.isRequired,
  filename: string.isRequired,
  rawDownloadUrl: string.isRequired,
  pagesCount: number.isRequired,
  openCopyToFolderModal: func.isRequired,
  isDeleting: bool
}

DocumentRow.defaultProps = {
  isDeleting: false
}

export default DocumentRow
