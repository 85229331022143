import React from "react"
import { shape, number, string, func, bool } from "prop-types"
import { styled } from "@linaria/react"
import { cx } from "@linaria/core"

import BaseUserAvatar from "src/components/UsersContacts/UserAvatar"

import RoleSelect from "./RoleSelect"
import MembershipTypeSelect from "./MembershipTypeSelect"

const UserAvatar = styled(BaseUserAvatar)`
  margin-right: 0.7rem;
`

const UserCard = ({
  user,
  onRoleChange,
  onMembershipTypeChange,
  onDeselect,
  selected = false
}) => {
  return (
    <div className={cx("card horizontal", selected ? "is-selected" : null)}>
      <div className="card-mascot">
        <UserAvatar user={user} />
      </div>
      <div className="card-title">
        <h6 className="card-title-primary">{user.fullName}</h6>
        {user.jobTitle && <div className="card-title-secondary">{user.jobTitle}</div>}
      </div>
      <div className="card-controls">
        <RoleSelect
          onChange={onRoleChange}
          value={user.role}
          data-type="user"
          data-id={user.id}
          id={`users_${user.id}_role`}
        />
        {selected && (
          <>
            <MembershipTypeSelect
              value={user.membershipType}
              onChange={onMembershipTypeChange}
              data-type="user"
              data-id={user.id}
              id={`users_${user.id}_membership_type`}
            />
            <div className="checkbox">
              <input
                type="checkbox"
                id={`member-pick-checkbox-user-${user.id}`}
                defaultChecked={selected}
                onClick={onDeselect}
                data-behavior="member-pick-checkbox"
                data-type="user"
                data-id={user.id}
              />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor={`member-pick-checkbox-user-${user.id}`} />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

UserCard.propTypes = {
  user: shape({
    id: number.isRequired,
    email: string.isRequired,
    initials: string.isRequired,
    avatarColorId: number.isRequired,
    avatarUrl: string,
    fullName: string.isRequired,
    jobTitle: string
  }),
  onRoleChange: func.isRequired,
  onMembershipTypeChange: func,
  onDeselect: func,
  selected: bool
}

export default UserCard
