import { uniq } from "lodash"

import { be, trigger } from "src/helpers/document"
import { isSafari } from "src/helpers/browser"
import { isAvailable as isBoxAvailable } from "src/helpers/box"
import { replaceContentLinkText } from "src/helpers/parseLinks"
import { replaceMention } from "src/helpers/string"
import {
  cleanupSafariLink,
  cleanupLinkWithAmpersand
} from "src/helpers/froalaPasteLinksIssues"

const ALL_MENTION_FULL_NAME = "all"
const ENTITY_ALL = "all"
const ENTITY_TEAM = "team"
const ENTITY_USER = "user"

export class MessageEditorComponent {
  constructor(selector, options) {
    this.teardown = this.teardown.bind(this)
    this.setup = this.setup.bind(this)
    this.editorEventHandlers = this.editorEventHandlers.bind(this)
    this.defineAddDocumentButton = this.defineAddDocumentButton.bind(this)
    this.clear = this.clear.bind(this)
    this.focus = this.focus.bind(this)
    this.setCaretAtEndOf = this.setCaretAtEndOf.bind(this)
    this.scrollViewToBottom = this.scrollViewToBottom.bind(this)
    this.cursor = this.cursor.bind(this)
    this.refreshPlaceholder = this.refreshPlaceholder.bind(this)
    this.getContent = this.getContent.bind(this)
    this.getWrappedContent = this.getWrappedContent.bind(this)
    this.setContent = this.setContent.bind(this)
    this.resetCache = this.resetCache.bind(this)
    this.contentIsEmpty = this.contentIsEmpty.bind(this)
    this.updateMessageActors = this.updateMessageActors.bind(this)
    this.extractActorIds = this.extractActorIds.bind(this)
    this.processMentions = this.processMentions.bind(this)
    this.removeIncorrectMentions = this.removeIncorrectMentions.bind(this)
    this.updateMentionsWithoutTribute = this.updateMentionsWithoutTribute.bind(this)
    this.selectMentionTemplate = this.selectMentionTemplate.bind(this)
    this.eachMention = this.eachMention.bind(this)
    this.editorViewElement = this.editorViewElement.bind(this)
    this.editorWrapperElement = this.editorWrapperElement.bind(this)
    this.isMobile = this.isMobile.bind(this)
    this.handleActorRemove = this.handleActorRemove.bind(this)
    this.autosaveContent = this.autosaveContent.bind(this)
    this.editorElement = selector
    this.options = options
    this.editor = null

    this.editorElementSelector = this.editorElement.data("behavior")
      ? `[data-behavior='${this.editorElement.data("behavior")}']`
      : `#${this.editorElement.attr("id")}`

    let actorsPanel
    let closingOptionsPanel

    if (selector.is('[data-behavior="message-edit-input"]')) {
      actorsPanel = null
      closingOptionsPanel = null
    } else if (this.isMobile()) {
      actorsPanel = be("mobile-message-actors")
      closingOptionsPanel = be("mobile-message-closing-options")
    } else {
      actorsPanel = be("message-actors")
      closingOptionsPanel = be("message-closing-options")
    }

    this.newMessageActionComponent = new window.NewMessageActionComponent(
      actorsPanel,
      closingOptionsPanel,
      selector.data("id"),
      this.handleActorRemove
    )

    if (options.autosaveContentId) {
      this.autosavedMessageComponent = new window.AutosavedMessageComponent(
        options.autosaveContentId
      )
    }
  }

  teardown() {
    try {
      if (this.editor && this.editor.destroy) this.editor.destroy()
      this.unbindMobileEditorResize()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  setup() {
    // Before Froala initialization
    this.defineAddDocumentButton()
    const toolbarButtons = this.options.toolbarButtons || {}
    const defaultParams = {
      key: window.FroalaKey,
      toolbarButtons,
      toolbarButtonsMD: this.options.toolbarButtonsMD || toolbarButtons,
      toolbarButtonsSM: this.options.toolbarButtonsSM || toolbarButtons,
      toolbarButtonsXS: this.options.toolbarButtonsXS || toolbarButtons,
      quickInsertButtons: this.options.quickInsertButtons || false,
      events: this.options.events || this.editorEventHandlers(),
      placeholderText: this.editorElement.data("placeholder"),
      attribution: false,
      charCounterCount: false,
      linkAlwaysBlank: true,
      pasteAllowedStyleProps: [
        "background",
        "background-color",
        "box-sizing",
        "border",
        "border-color",
        "border-collapse",
        "border-style",
        "border-width",
        "color",
        "display",
        "font-family",
        "font-size",
        "font-style",
        "font-variant-ligatures",
        "font-weight",
        "letter-spacing",
        "text-align",
        "text-decoration-thickness",
        "text-decoration-style",
        "text-decoration-color",
        "text-transform",
        "white-space",
        "word-spacing"
      ]
    }

    window.FroalaEditor.DefineIcon("moreClearformatting", {
      NAME: "ellipsis-v",
      SVG_KEY: "insertMore"
    })

    window.FroalaEditor.RegisterCommand("moreClearformatting", {
      icon: "moreClearformatting",
      title: "More Clear Formatting",
      undo: false,
      more_btn: true
    })

    window.FroalaEditor.DefineIcon("moreButtons", {
      NAME: "ellipsis-v",
      SVG_KEY: "insertMore"
    })

    window.FroalaEditor.RegisterCommand("moreButtons", {
      icon: "moreButtons",
      title: "More Buttons",
      undo: false,
      more_btn: true
    })

    if (this.options.mentions) {
      const { selectMentionTemplate } = this

      let prevEntity
      this.tribute = new window.Tribute({
        values: this.options.mentions.data,
        lookup: "full_name",
        selectTemplate(item) {
          return selectMentionTemplate({
            id: item.original.id,
            entity: item.original.entity,
            name: item.original.inserted_name,
            full: item.original.full
          })
        },
        menuItemTemplate(item) {
          const firstOfType = prevEntity === item.original.entity ? "" : "firstOfType"
          prevEntity = item.original.entity
          const partialBlock =
            item.original.entity === ENTITY_TEAM && !item.original.full
              ? '<span class="partial">(partial)</span>'
              : ""
          return `<span data-id="${item.original.id}" class="user-mention-option ${item.original.entity} ${firstOfType}" data-behavior="user-mention-option">${item.original.full_name}${partialBlock}</span>`
        }
      })
    }

    const editorParams = jQuery.extend(true, {}, defaultParams, this.options.params)

    // Froala initialization
    this.editor = new window.FroalaEditor(this.editorElementSelector, editorParams)

    this.bindMobileEditorResize()
  }

  selectMentionTemplate({ id, entity, name, full }) {
    if (entity === "team" && !full) {
      return id
        .toString()
        .split(" ")
        .map((userId) => {
          const userName = this.options.mentions.data.find(
            (e) => e.entity === ENTITY_USER && e.id.toString() === userId
          ).inserted_name
          return (
            '<span class="fr-deletable fr-tribute user-mention" data-behavior="user-mention" ' +
            `data-entity="${ENTITY_USER}" data-id="${userId}">@${userName}</span>`
          )
        })
        .join(" ")
    }
    return (
      '<span class="fr-deletable fr-tribute user-mention" data-behavior="user-mention" ' +
      `data-entity="${entity}" data-id="${id}">@${name}</span>`
    )
  }

  editorEventHandlers() {
    const component = this

    return {
      initialized() {
        const editor = this
        const { tribute } = component

        if (tribute) {
          tribute.attach(editor.el)
        }

        editor.events.on(
          "keydown",
          (e) => {
            if (e.which === window.FroalaEditor.KEYCODE.ENTER && tribute.isActive) {
              return false
            }
          },
          true
        )

        if (component.autosavedMessageComponent) {
          const cachedMessage = component.autosavedMessageComponent.get()
          if (cachedMessage) {
            component.setContent(cachedMessage)
          }

          return editor.events.on("contentChanged", () =>
            component.autosavedMessageComponent.set(editor.html.get())
          )
        }
      },
      keyup() {
        component.updateMessageActors()
      },
      keydown(e) {
        const editor = this

        if (component.options.onCtlrEnter) {
          if (e.keyCode === 13 && e.ctrlKey) {
            e.preventDefault()
            e.stopPropagation()
            if (editor.fullscreen.isActive()) {
              editor.fullscreen.toggle()
            }
            component.options.onCtlrEnter()
            return false
          }
        }
      },
      contentChanged() {
        return trigger("newMessageContentUpdated", {
          content: this.html.get(),
          mentionIds: component.extractActorIds()
        })
      },
      focus: () => {
        if (this.options.onFocus && !this.options.skipCallback) {
          return this.options.onFocus()
        }
      },
      "commands.before": function applyAfterClearFormatting(cmd) {
        if (cmd === "clearFormatting") {
          return this.paragraphFormat.apply("p")
        }
      },
      "commands.after": (cmd) => {
        if (cmd === "fullscreen") {
          const { tribute } = component

          if (tribute.isActive && tribute.menu) {
            tribute.menu.style.display = "none"
          }

          if (component.options.onFullScreen) {
            return component.options.onFullScreen()
          }
        }
      },
      "paste.beforeCleanup": (html) => {
        const parser = new DOMParser()
        const doc = parser.parseFromString(html, "text/html")
        doc.querySelectorAll(".user-mention").forEach((element) => {
          element.setAttribute("data-keep-class", "user-mention")
        })
        return doc.body.innerHTML
      },
      "paste.afterCleanup": (html) => {
        const parser = new DOMParser()
        const doc = parser.parseFromString(html, "text/html")
        doc.querySelectorAll("[data-keep-class]").forEach((element) => {
          element.classList.add("user-mention")
        })
        const updatedHtml = doc.body.innerHTML
        updatedHtml.replaceAll(/ color:\stransparent/gi, " color: #302e67")
        return replaceContentLinkText(
          cleanupLinkWithAmpersand(cleanupSafariLink(updatedHtml)),
          window.availableLinks
        )
      }
    }
  }

  defineAddDocumentButton() {
    if (this.options.onAddDocument) {
      window.FroalaEditor.DefineIcon("addDocument", {
        NAME: "paperclip",
        template: "font_awesome"
      })

      const commandOptions = isBoxAvailable()
        ? {
            title: "Add document",
            focus: true,
            undo: true,
            refreshAfterCallback: true,
            callback: () => this.options.onAddDocument()
          }
        : { title: "Attachments are not available. There is a problem with Box service." }

      return window.FroalaEditor.RegisterCommand("addDocument", commandOptions)
    }
  }

  clear() {
    return this.setContent("")
  }

  focus(options) {
    return this.editor.events.trigger("focus", [options == null ? {} : options])
  }

  setCaretAtEndOf(selector) {
    const el =
      $('[data-behavior="new-message-form"] .fr-element.fr-view:visible')[0] ||
      $('[data-behavior="new-message-modal"] .fr-element.fr-view')[0]

    const placeholder = el.querySelector(selector)

    if (placeholder) {
      this.editor.selection.setAtEnd(placeholder)
      return this.editor.selection.restore()
    }
  }

  scrollViewToBottom() {
    // Safari restores scrollTop untill modal is totally opened
    const timeout = isSafari() && this.isMobile() ? 300 : 1
    // On mobile form .fr-view is scrollable. On desktop - .fr-wrapper
    const el = this.isMobile() ? this.editorViewElement() : this.editorWrapperElement()

    return setTimeout(() => {
      el.scrollTop = el.scrollHeight
    }, timeout)
  }

  cursor(action, shiftKey) {
    return this.editor.cursor[action](shiftKey == null ? false : shiftKey)
  }

  refreshPlaceholder() {
    return this.editor.placeholder.refresh()
  }

  getContent() {
    return this.editor.html.get()
  }

  getWrappedContent() {
    // In some cases wrapping tag is ommited
    // wrap in span to avoid errors on html traversing
    return `<span>${this.editor.html.get()}</span>`
  }

  setContent(content) {
    this.editor.html.set(content)
    this.processMentions()

    return trigger("newMessageContentUpdated", {
      content,
      mentionIds: this.extractActorIds()
    })
  }

  resetCache() {
    return this.autosavedMessageComponent.reset()
  }

  contentIsEmpty() {
    const content = this.getWrappedContent()
    return !(
      $.trim($(content).text()).length ||
      content.includes("img") ||
      content.includes("iframe")
    )
  }

  updateMessageActors() {
    this.processMentions()
    return this.newMessageActionComponent.update(this.extractActorIds())
  }

  // @example
  // @input   ["1 2",3,4]
  // @output  [1,2,3,4]
  normalizeActorIds(actorsIds) {
    return actorsIds
      .join(" ")
      .split(" ")
      .filter((word) => word)
      .map((key) => Number(key))
  }

  extractActorIds() {
    const content = this.getWrappedContent()
    const ids = []

    $(content)
      .find("[data-behavior=user-mention]")
      .each((i, el) => ids.push($(el).data("id")))

    return uniq(this.normalizeActorIds(ids))
  }

  processMentions() {
    this.removeIncorrectMentions()
    this.updateMentionsWithoutTribute()
  }

  removeIncorrectMentions() {
    const allowedMentionContents = this.options.mentions.data.map(
      (mention) => `@${mention.inserted_name}`
    )

    return this.eachMention((element) => {
      const mentionContent = element.text().trim()

      if (!Array.from(allowedMentionContents).includes(mentionContent)) {
        return element.remove()
      }
    })
  }

  updateMentionsWithoutTribute() {
    let currentHTML = this.editor.html.get()
    const actorIds = this.extractActorIds()

    const html = $($.parseHTML(currentHTML))
    $("[data-behavior=user-mention]", html).remove()
    const text = html.text().toLowerCase()
    const mentionsToAdd = []

    this.options.mentions.data.forEach((item) => {
      if (
        item.inserted_name &&
        !mentionsToAdd.find((mention) => mention.inserted_name === item.inserted_name) &&
        text.includes(`@${item.inserted_name.toLowerCase()}`) &&
        !actorIds.includes(item.id)
      )
        mentionsToAdd.push(item)
    })

    if (!mentionsToAdd.length) return

    this.editor.selection.save()

    currentHTML = this.editor.html.get(true)

    mentionsToAdd.every((item) => {
      const mentionHTML = this.selectMentionTemplate({
        id: item.id,
        entity: item.entity,
        name: item.inserted_name,
        full: item.full
      })
      this.editor.html.set(replaceMention(currentHTML, item.inserted_name, mentionHTML))

      return false
    })

    return this.editor.selection.restore()
  }

  eachMention(callback) {
    return $(".fr-wrapper")
      .find("[data-behavior=user-mention]")
      .each((i, el) => callback($(el)))
  }

  editorViewElement() {
    return this.editorWrapperElement().querySelector(".fr-element.fr-view")
  }

  editorWrapperElement() {
    if (this.isMobile()) {
      return document.querySelector('[data-behavior="new-message-modal"] .fr-wrapper')
    }
    return document.querySelector('[data-behavior="new-message-form"] .fr-wrapper')
  }

  isMobile() {
    return this.editorElement.data("behavior") === "mobile-new-message-input"
  }

  autosaveContent() {
    if (this.autosavedMessageComponent) {
      this.autosavedMessageComponent.set(this.editor.html.get())
    }
  }

  handleActorRemove(removedActorId) {
    const expandedFromTeamsUserIds = []

    this.eachMention((currentMention) => {
      if (currentMention.data("entity") === ENTITY_ALL) {
        const mentionsData = this.options.mentions.data
        const filteredMentionsData = mentionsData.filter(
          (oneMentionData) =>
            oneMentionData.full_name !== ALL_MENTION_FULL_NAME &&
            oneMentionData.id !== removedActorId &&
            oneMentionData.entity === ENTITY_USER
        )

        const templatedMentions = filteredMentionsData.map((oneMentionData) => {
          return this.selectMentionTemplate({
            id: oneMentionData.id,
            entity: oneMentionData.entity,
            name: oneMentionData.inserted_name,
            full: oneMentionData.full
          })
        })

        currentMention.after(templatedMentions.join(" "))
        currentMention.remove()
      }

      if (currentMention.data("entity") === ENTITY_TEAM) {
        const mentionsData = this.options.mentions.data
        const teamMembers = currentMention.data("id").toString().split(" ")

        const filteredMentionsData = mentionsData.filter(
          (oneMentionData) =>
            teamMembers.includes(oneMentionData.id.toString()) &&
            oneMentionData.id !== removedActorId &&
            oneMentionData.entity === ENTITY_USER &&
            !expandedFromTeamsUserIds.includes(oneMentionData.id)
        )

        const templatedMentions = filteredMentionsData.map((oneMentionData) => {
          expandedFromTeamsUserIds.push(oneMentionData.id)
          return this.selectMentionTemplate({
            id: oneMentionData.id,
            entity: oneMentionData.entity,
            name: oneMentionData.inserted_name,
            full: oneMentionData.full
          })
        })

        currentMention.after(templatedMentions.join(" "))
        currentMention.remove()
      }

      if (
        currentMention.data("entity") === ENTITY_USER &&
        removedActorId === currentMention.data("id")
      ) {
        currentMention.remove()
      }

      this.autosaveContent()
    })
  }

  bindMobileEditorResize() {
    if (!this.isMobile() || !window.visualViewport) return

    window.visualViewport.addEventListener("resize", this.resizeMobileEditor)
  }

  resizeMobileEditor() {
    const modalContent = document.querySelector("#new-message-modal .modal-content")

    if (!modalContent) return

    modalContent.style.height = `${window.visualViewport.height - 16}px`
  }

  unbindMobileEditorResize() {
    if (!this.isMobile() || !window.visualViewport) return

    window.visualViewport.removeEventListener("resize", this.resizeMobileEditor)
  }
}
