import React from "react"
import { shape, string } from "prop-types"
import { styled } from "@linaria/react"

const Container = styled.div`
  font-style: italic;
  line-height: 1.2;
  font-size: 14px;
  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const Index = styled.span`
  margin-right: 0.5rem;
`

const AgendaItemName = ({ agendaItem }) => (
  <Container>
    <Index>{agendaItem.index}</Index>
    {agendaItem.name}
  </Container>
)

AgendaItemName.propTypes = {
  agendaItem: shape({
    name: string.isRequired,
    index: string.isRequired
  }).isRequired
}

export default AgendaItemName
