import React from "react"
import { Form } from "react-bootstrap"
import { arrayOf, shape, string, func, bool } from "prop-types"
import { styled } from "@linaria/react"
import classNames from "classnames"
import { compact } from "lodash"

import { SPACES, FONT_SIZES } from "src/styles/sizes"
import { airtableSectionFieldType } from "src/resources/accounts/airtablePropTypes"

import FieldInput from "./FieldInput"
import ReadOnlyField from "./ReadOnlyField"
import { fieldIsInvalid, sectionInProgress } from "./helpers"

const ActiveSectionForm = ({
  section,
  allFields,
  editableFieldIds,
  record,
  savedRecord,
  setRecord,
  className
}) => {
  const { fields, automaticNumbering } = section
  let indexToShow = 0
  const fieldsWithInfo = compact(
    fields.map((field) => {
      const fieldInfo = allFields[field.id]
      if (fieldInfo) indexToShow += 1

      return fieldInfo
        ? { ...fieldInfo, ...field, indexToShow: automaticNumbering ? indexToShow : null }
        : null
    })
  )

  const inProgress = sectionInProgress(fields, editableFieldIds, savedRecord)

  return (
    <Form className={classNames("mt-4", className)}>
      {fieldsWithInfo.map((field) => {
        const value = record[field.id]
        const invalid = inProgress && fieldIsInvalid(field, value)

        return (
          <Form.Group key={field.id} controlId={field.id}>
            {editableFieldIds.includes(field.id) ? (
              <FieldInput
                field={field}
                value={value}
                setRecord={setRecord}
                sectionInProgress={inProgress}
                invalid={invalid}
              />
            ) : (
              <ReadOnlyField field={field} value={value} invalid={invalid} />
            )}
          </Form.Group>
        )
      })}
    </Form>
  )
}

ActiveSectionForm.propTypes = {
  section: shape({
    fields: arrayOf(airtableSectionFieldType).isRequired,
    automaticNumbering: bool
  }).isRequired,
  allFields: shape({}).isRequired,
  editableFieldIds: arrayOf(string).isRequired,
  record: shape({}).isRequired,
  savedRecord: shape({}).isRequired,
  setRecord: func.isRequired
}

export default styled(ActiveSectionForm)`
  .form-group {
    margin-bottom: ${SPACES.semiLarge};
  }

  input,
  textarea {
    font-size: ${FONT_SIZES.small};
  }
`
