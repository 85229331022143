export const addBadgeToTheThread = (thread, stats) => {
  let badge = ""

  if (thread.__typename === "ChatThread") {
    if (stats?.unread?.chats?.[thread.id]) {
      badge = "unread"
    }
  }

  if (thread.__typename === "DiscussionThread") {
    if (stats?.unread?.discussions?.[thread.id]) {
      badge = "unread"
    }

    if (stats?.mentions?.discussions?.[thread.id]) {
      badge = "mention"
    }

    if (stats?.actions?.discussions?.[thread.id]) {
      badge = "mention"
    }

    if (stats?.unsigned?.discussions?.[thread.id]) {
      badge = "mention"
    }
  }

  return { ...thread, badge }
}

export const hasDraft = ({ typename, id }) => {
  const storageKey = `autosave-${typename.replace("Thread", "").toLowerCase()}-${id}`

  const draft = localStorage.getItem(storageKey)

  return !!draft
}

export const isCurrentThread = (thread, currentThread) =>
  !!currentThread &&
  thread.id.toString() === currentThread.id.toString() &&
  thread.__typename.toLowerCase() === currentThread.type.toLowerCase()
