import React, { useEffect } from "react"
import { debounce } from "lodash"
import { bool, number, objectOf, shape, string } from "prop-types"

import { getCurrentUser } from "src/helpers/user"
import {
  createNotification,
  deleteNotification,
  updateAnnotationsCount
} from "src/api/collaborativeDocuments"
import buildAnnotationButton from "src/components/pspsdfkitButtons/AnnotationButton/buildAnnotationButton"
import buildThumbnailsButton from "src/components/pspsdfkitButtons/ThumbnailsButton/buildThumbnailsButton"
import { handlePSPDFKitInitError } from "src/helpers/pspdfkit"

import annotationAuthorRenderer from "./annotationAuthorRenderer"
import annotationAuthorAvatarRenderer from "./annotationAuthorAvatarRenderer"

const toolbarItems = [
  { type: "pager" },
  { type: "zoom-out" },
  { type: "zoom-in" },
  { type: "zoom-mode" },
  { type: "annotate", mediaQueries: ["(max-width: 1px)"] },
  { type: "ink" },
  { type: "highlighter" },
  { type: "text-highlighter" },
  { type: "ink-eraser" },
  { type: "comment" }
]

const setToolbarItems = (instance) => {
  instance.setToolbarItems((items) => {
    return [
      ...items,
      buildAnnotationButton(instance),
      buildThumbnailsButton(instance),
      { type: "export-pdf" },
      { type: "search" }
    ]
  })
}

const getContainerId = (pspdfkitId) => `collaborative-document-${pspdfkitId}`

const DocumentPreview = ({ id: documentId, pspdfkitId, isOpened, jwt, users }) => {
  const container = getContainerId(pspdfkitId)
  const currentUser = getCurrentUser()
  const { PSPDFKit } = window

  const hideAllAnnotationAuthors = (instance, options = {}) => {
    Array.from(
      instance.contentDocument.getElementsByClassName(
        "PSPDFKit-annotation-author-container"
      )
    ).forEach((element) => {
      if (element.parentNode.dataset.annotationId !== options.exceptFor) {
        // eslint-disable-next-line no-param-reassign
        element.style.display = "none"
      }
    })
  }

  const setUserId = (instance, createdAnnotations) => {
    createdAnnotations.forEach((annotation) => {
      // Annotation created by current user doesn't have customData
      // Annotation created in real-time has
      if (!annotation.customData) {
        const updatedAnnotation = annotation.set("customData", {
          userId: currentUser.id
        })
        instance.update(updatedAnnotation)
      }
    })
  }

  useEffect(() => {
    if (isOpened) {
      const debouncedAnnotationsCountUpdate = debounce(
        () => updateAnnotationsCount({ documentId }),
        3000
      )

      PSPDFKit.load({
        toolbarItems,
        serverUrl: window.pspdfkitServerUrl,
        styleSheets: [window.pspdfkitCollaborativeDocumentsStylesheetPath],
        container: `#${container}`,
        documentId: pspdfkitId,
        authPayload: { jwt },
        instant: true,
        initialViewState: new PSPDFKit.ViewState({
          sidebarOptions: {
            [PSPDFKit.SidebarMode.ANNOTATIONS]: {
              includeContent: [
                ...PSPDFKit.defaultAnnotationsSidebarContent,
                PSPDFKit.Comment
              ]
            }
          }
        }),
        editableAnnotationTypes: [
          PSPDFKit.Annotations.StrikeOutAnnotation,
          PSPDFKit.Annotations.HighlightAnnotation,
          PSPDFKit.Annotations.UnderlineAnnotation,
          PSPDFKit.Annotations.SquiggleAnnotation,
          PSPDFKit.Annotations.InkAnnotation,
          PSPDFKit.Annotations.CommentMarkerAnnotation
        ],
        isEditableAnnotation: (annotation) => {
          if (annotation.isCommentThreadRoot) return true

          return (
            !annotation?.customData?.userId ||
            annotation?.customData?.userId === currentUser.id
          )
        },
        isEditableComment: (comment) => {
          return !comment.text?.value || comment?.customData?.userId === currentUser.id
        },
        customRenderers: {
          CommentAvatar: ({ comment }) => {
            const user = users[comment?.customData?.userId.toString()]
            const element = annotationAuthorAvatarRenderer(user)

            return {
              node: element,
              append: false // This should always be `false` in this case.
            }
          },
          Annotation: ({ annotation }) => {
            const node = annotationAuthorRenderer({ annotation, users, currentUser })
            if (node)
              return {
                node,
                append: true
              }
          }
        }
      })
        .then((instance) => {
          setToolbarItems(instance)
          instance.setAnnotationCreatorName(currentUser.full_name)

          instance.setViewState((viewState) => {
            return viewState.set("sidebarPlacement", PSPDFKit.SidebarPlacement.END)
          })

          instance.addEventListener("comments.create", (createdComments) => {
            setUserId(instance, createdComments)
            createdComments.forEach((comment) => {
              createNotification({
                documentId,
                userId: currentUser.id,
                pspdfkitId: comment.id,
                comment: true
              })
            })
          })

          instance.addEventListener("comments.delete", (deletedComments) => {
            deletedComments.forEach((comment) => {
              deleteNotification({ documentId, pspdfkitId: comment.id })
            })
          })

          instance.addEventListener("comments.didSave", debouncedAnnotationsCountUpdate)

          instance.addEventListener("annotations.create", (createdAnnotations) => {
            setUserId(instance, createdAnnotations)
            createdAnnotations.forEach((annotation) => {
              if (!annotation.isCommentThreadRoot) {
                createNotification({
                  documentId,
                  userId: currentUser.id,
                  pspdfkitId: annotation.id
                })
              }
            })
          })

          instance.addEventListener("annotations.delete", (deletedAnnotations) => {
            deletedAnnotations.forEach((annotation) => {
              deleteNotification({ documentId, pspdfkitId: annotation.id })
            })
          })

          instance.addEventListener(
            "annotations.didSave",
            debouncedAnnotationsCountUpdate
          )

          instance.contentDocument.addEventListener("click", (event) => {
            hideAllAnnotationAuthors(instance, {
              exceptFor: event.target.dataset.annotationId
            })

            if (!event.target.dataset.annotationId) return

            const { annotationId } = event.target.dataset
            const annotation = instance.contentDocument.querySelector(
              `.PSPDFKit-Annotation[data-annotation-id="${annotationId}"]`
            )
            if (
              annotation
                .getAttribute("class")
                .includes("PSPDFKit-Text-Markup-Comment-Annotation") ||
              annotation
                .getAttribute("class")
                .includes("PSPDFKit-Comment-Marker-Annotation")
            )
              return

            // eslint-disable-next-line no-param-reassign
            event.target.firstChild.style.display = "flex"
            // eslint-disable-next-line no-param-reassign
            event.target.parentNode.style.zIndex = "1"
          })
        })
        .catch(handlePSPDFKitInitError)
    }

    return () => {
      try {
        PSPDFKit.unload(`#${container}`)
        // eslint-disable-next-line no-empty
      } catch {}
    }
  }, [documentId, container, isOpened])

  return <div id={container} className="document-preview" />
}

DocumentPreview.propTypes = {
  id: number.isRequired,
  pspdfkitId: string.isRequired,
  jwt: string.isRequired,
  isOpened: bool,
  users: objectOf(
    shape({
      avatarColorId: number,
      avatarUrl: string,
      initials: string
    })
  )
}

export default DocumentPreview
