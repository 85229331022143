import React, { useMemo } from "react"
import { arrayOf, number, shape, func, string } from "prop-types"
import { useSelector } from "react-redux"

import { getAgendaItems } from "src/features/meetingPacks/MeetingPack/selectors"
import { useDropOut } from "src/features/meetingPacks/MeetingPack/Transcript/View/useDragDrop"

import AgendaItem from "./AgendaItem"
import AssignedAgendaItem from "./AssignedAgendaItem"

const AgendaItemsSidebar = ({ transcriptItems, setTranscriptItems, handleSave }) => {
  const agendaItems = useSelector(getAgendaItems).filter((item) => !item.break)

  if (!agendaItems?.length) return null

  const transcriptAgendaItems = useMemo(
    () =>
      transcriptItems.reduce(
        (o, item) => (item.agendaItemId ? { ...o, [item.agendaItemId]: item } : o),
        {}
      ),
    [transcriptItems]
  )

  const { ref, handlerId } = useDropOut({
    transcriptItems,
    setTranscriptItems,
    handleSave
  })

  return (
    <div ref={ref} data-handler-id={handlerId} className="fluid-container card">
      {agendaItems.map((agendaItem) =>
        transcriptAgendaItems[agendaItem.id] ? (
          <AssignedAgendaItem
            key={agendaItem.id}
            agendaItem={agendaItem}
            assignedAgendaItem={transcriptAgendaItems[agendaItem.id]}
          />
        ) : (
          <AgendaItem key={agendaItem.id} agendaItem={agendaItem} />
        )
      )}
    </div>
  )
}

AgendaItemsSidebar.propTypes = {
  transcriptItems: arrayOf(shape({ id: number, uid: string })).isRequired,
  setTranscriptItems: func.isRequired,
  handleSave: func.isRequired
}

export default AgendaItemsSidebar
