@$copyPlainText = (text) ->
  temp = $("<input>")
  $("body").append(temp)
  temp.val(text)
  $copyInputText(temp)
  temp.remove()

@$copyInputText = ($input) ->
  if $isIOS()
    $iosCopyInputText($input)
  else
    $defaultCopyInputText($input)

@$defaultCopyInputText = ($input) ->
  $input.select()
  document.execCommand('copy')
  $input.blur()

@$iosCopyInputText = ($input) ->
  el = $input[0]
  return unless el

  oldContentEditable = el.contentEditable
  oldReadOnly = el.readOnly

  el.contentEditable = true
  el.readOnly = false

  range = document.createRange()
  range.selectNodeContents(el)

  selection = window.getSelection()
  selection.removeAllRanges()
  selection.addRange(range)

  el.setSelectionRange(0, 999999)

  document.execCommand('copy')

  el.contentEditable = oldContentEditable
  el.readOnly = oldReadOnly

  $input.blur()
