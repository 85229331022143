import { styled } from "@linaria/react"

import {
  BACKGROUND_COLORS,
  BASE_COLORS,
  TEXT_COLORS,
  ICON_COLORS
} from "src/styles/colors"
import { BREAKPOINTS, SPACES } from "src/styles/sizes"
import Unstyled from "src/styles/components/Button/Unstyled"
import Icon from "src/styles/components/Icon"

export const Container = styled.div`
  background: ${BASE_COLORS.white};
  box-sizing: border-box;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: ${SPACES.xSmall} ${SPACES.small};
  position: absolute;
  margin-top: 0.1rem;
  max-height: calc(100vh - 125px);
  width: 100%;
  z-index: 2;
`

export const SearchButton = styled(Unstyled)`
  box-sizing: content-box;
  width: 100%;
  height: ${SPACES.medium};
  position: relative;
  padding: ${SPACES.xSmall} ${SPACES.small};
  margin-left: -${SPACES.small};
  margin-right: -${SPACES.small};

  &.active,
  &:hover,
  &:focus,
  &:active {
    color: ${TEXT_COLORS.heading};
    background: ${BACKGROUND_COLORS.selected};
  }
`

export const SearchIcon = styled(Icon)`
  ${SearchButton} & {
    position: static;
    margin-top: 0;
    color: ${ICON_COLORS.search};
  }
`

export const SearchAll = styled.div`
  button [class^="icon-"] + & {
    display: flex;
    margin-left: ${SPACES.xSmall};
    width: calc(100% - ${SPACES.xSmall} * 2);
  }
`

export const SearchText = styled.span`
  font-weight: 500;
`

export const SubText = styled.span`
  color: ${TEXT_COLORS.muted};
  padding-left: ${SPACES.xSmall};
  flex-shrink: 100;

  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    display: none;
  }
`
