import moment from "moment/moment"
import { difference } from "lodash"

import IdbStore from "src/features/offline/helpers/IdbStore"

const STORE_NAME = "TRANSCRIPT_HISTORY"
const LAST_CLEAN_UP_KEY = "LAST_CLEAN_UP"
const CLEAN_UP_PERIOD = 24 // hours
const MAX_LENGTH = 3

export default class Store {
  constructor() {
    this.store = new IdbStore({ storeName: STORE_NAME })
  }

  deleteTranscriptHistory = (transcriptId) => {
    this.store.delete(transcriptId)
  }

  cleanUp = async (checkTranscriptsAvailability) => {
    this.store
      .get(LAST_CLEAN_UP_KEY)
      .then((lastCleanUpAt) => {
        if (
          !lastCleanUpAt ||
          moment.duration(moment().diff(moment(lastCleanUpAt))).asHours() >
            CLEAN_UP_PERIOD
        )
          return this.store.getAllKeys()

        return Promise.resolve([])
      })
      .then((allKeys) => allKeys.filter((key) => key !== LAST_CLEAN_UP_KEY))
      .then((transcriptIds) => {
        if (!transcriptIds.length) return

        this.store.set(LAST_CLEAN_UP_KEY, moment().format())

        checkTranscriptsAvailability(transcriptIds).then((availableTranscriptIds) =>
          difference(transcriptIds, availableTranscriptIds).forEach((transcriptId) =>
            this.deleteTranscriptHistory(transcriptId)
          )
        )
      })
  }

  getTranscriptHistory = async (transcriptId) => {
    return this.store
      .get(transcriptId)
      .then((transcriptHistory) =>
        transcriptHistory ? JSON.parse(transcriptHistory) : []
      )
  }

  saveAction = async (transcriptId, newState) => {
    this.getTranscriptHistory(transcriptId).then((transcriptHistory) => {
      let newHistory = [newState, ...transcriptHistory]
      if (newHistory.length > MAX_LENGTH) newHistory = newHistory.slice(0, -1)

      return this.store.set(transcriptId, JSON.stringify(newHistory))
    })
  }
}
