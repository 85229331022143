import { useState } from "react"

import { SortingFields, SortingDirections } from "../consts"

import useFolderItemsQuery from "./useFolderItemsQuery"

const useFolderItems = ({ groupId, folderId }) => {
  const [selectedFolderItems, setSelectedFolderItems] = useState([])

  const [sorting, setSorting] = useState({
    field: SortingFields.Name,
    direction: SortingDirections.Asc
  })

  const { folderItems, error, loading, refetch } = useFolderItemsQuery({
    folderId,
    groupId,
    sortBy: sorting
  })

  const selectFolderItem = (folderItem) => {
    setSelectedFolderItems([...selectedFolderItems, folderItem])
  }

  const deselectFolderItem = (folderItem) => {
    setSelectedFolderItems(
      selectedFolderItems.filter(
        (item) => !(item.id === folderItem.id && item.type === folderItem.type)
      )
    )
  }

  const selectAllFolderItems = () => {
    setSelectedFolderItems(folderItems)
  }

  const deselectAllFolderItems = () => {
    setSelectedFolderItems([])
  }

  const toggleSorting = (field) => {
    if (sorting.field === field) {
      if (sorting.direction === SortingDirections.Asc) {
        setSorting({ ...sorting, direction: SortingDirections.Desc })
      } else {
        setSorting({ ...sorting, direction: SortingDirections.Asc })
      }
    } else {
      setSorting({ field, direction: SortingDirections.Asc })
    }
  }

  return {
    folderItems,
    selectedFolderItems,
    error,
    loading,
    sorting,
    refetch,
    selectFolderItem,
    deselectFolderItem,
    selectAllFolderItems,
    deselectAllFolderItems,
    toggleSorting
  }
}

export default useFolderItems
