import apiClient from "src/app/apiClient"

const index = (params) => apiClient.get(`/api/accounts`, { params })
const schemes = ({ accountId, ...params }) =>
  apiClient.get(`/api/accounts/${accountId}/schemes`, { params })

const accounts = {
  index,
  schemes
}

export default accounts
