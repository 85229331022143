import React from "react"
import { func, string } from "prop-types"

const RoleSelect = ({ onChange, value, ...rest }) => {
  return (
    <select
      className="form-field"
      onChange={(e) => onChange(e.target.value)}
      value={value}
      data-behavior="member-role-select"
      {...rest}
    >
      <option value="">Choose</option>
      <option value="guest">Guest</option>
      <option value="member">Member</option>
      <option value="admin">Admin</option>
    </select>
  )
}

RoleSelect.propTypes = {
  onChange: func.isRequired,
  value: string,
  type: string
}

export default RoleSelect
