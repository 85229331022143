import { styled } from "@linaria/react"

import { BOX_SHADOWS } from "src/styles/shadows"
import { BREAKPOINTS, cardBorderRadius } from "src/styles/sizes"

export const ItemsList = styled.ul`
  transition: all 0.2s;
  padding: 0;
  margin: 0;

  .ghost {
    cursor: pointer;
    opacity: 0;
  }
`

export const ItemsListCard = styled.div`
  background-color: white;
  box-shadow: ${BOX_SHADOWS.standardBlock};
  border-radius: ${cardBorderRadius};
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
`

export const ItemsListRowActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  opacity: 0;
  transition: opacity 0.2s ease-in;
  position: absolute;
  top: 0;
  left: 100%;
  height: 100%;
  cursor: pointer;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    display: none;
  }
`

export const ItemsListRow = styled.li`
  list-style: none;
  position: relative;

  &:hover ${ItemsListRowActions} {
    pointer-events: initial;
    opacity: 1;
  }
`
