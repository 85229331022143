import { styled } from "@linaria/react"

import { TEXT_COLORS } from "src/styles/colors"
import { BREAKPOINTS, FONT_SIZES, SPACES } from "src/styles/sizes"
import Icon from "src/styles/components/Icon"

export const AgendaItemDescriptionIcon = styled(Icon)`
  color: ${TEXT_COLORS.muted};
  font-size: ${FONT_SIZES.medium};
  margin-left: ${SPACES.xxSmall};
  cursor: pointer;

  @media (max-width: ${BREAKPOINTS.xSmall}) {
    display: none;
  }
`

export const ItemHeader = styled.h5`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${BREAKPOINTS.xSmall}) {
    width: 100%;
  }
`
