import { styled } from "@linaria/react"

import { Body as BaseBody } from "../Modal"

export const Body = styled(BaseBody)`
  width: 500px;
  min-height: 50px;
  max-height: 250px;
  overflow-y: auto;
`

export const SpinnerContainer = styled.div`
  text-align: center;
`
