import React from "react"
import { styled } from "@linaria/react"

import Icon from "src/styles/components/Icon"

const IconOpacity = ({ ...props }) => {
  return <Icon {...props} />
}

export default styled(IconOpacity)`
  cursor: pointer;
  opacity: 0.25;
  transition: opacity 0.5s ease;
  &:hover {
    opacity: 0.75;
  }
`
