import { styled } from "@linaria/react"

import SidebarToggleBase from "src/features/Header/SidebarToggle"
import { BREAKPOINTS, SPACES, headerSize, headerMobileSize } from "src/styles/sizes"
import { contentsSidebarToggleBackground } from "src/styles/colors"

import { InputContainer } from "./SearchInput/styles"

export const SidebarToggle = styled(SidebarToggleBase)`
  border-right: 1px solid ${contentsSidebarToggleBackground};
  order: initial;
  height: ${headerSize};

  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    order: 3;
  }
`

export const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${headerSize};
  position: relative;
  background-color: white;
  border-bottom: 1px solid #dbe1e7;
  box-shadow: 0 19px 19px rgb(133 167 184 / 6%);

  .header-nav {
    display: none;
  }

  .header-space-name {
    display: none;
  }

  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    .header-nav {
      display: flex;
    }

    .header-space-name {
      display: flex;
    }

    ${InputContainer} {
      display: none;
    }
  }
`

export const MobileInputContainer = styled.div`
  display: none;

  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    display: block;
    width: 100%;
    padding: ${SPACES.small};

    ${InputContainer} {
      max-width: 100%;
      padding: 0;

      input[type="search"] {
        padding: 0.75rem ${SPACES.large};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`

export const GoBackLink = styled.a`
  display: none;
  align-items: center;
  border-right: 1px solid ${contentsSidebarToggleBackground};
  height: ${headerMobileSize};
  padding: 0 0.75rem;
  margin-right: ${SPACES.small};
  width: initial;

  [class*="icon-"] {
    font-weight: 600;
  }

  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    display: flex;
  }
`
