import React, { useCallback } from "react"

import Modal, { Body, Header, Title, Footer } from "src/styles/components/Modal"
import useIsOpened from "src/hooks/useIsOpened"
import PrimaryButton from "src/styles/components/Button/Primary"
import SecondaryButton from "src/styles/components/Button/Secondary"

export default function useConfirmationModal({
  onConfirm,
  isConfirming = false,
  onCancel = () => {},
  title = "",
  message = "",
  confirmLabel = "Confirm",
  cancelLabel = "Cancel"
}) {
  const { isOpened, open, close } = useIsOpened(false)

  const confirm = useCallback(() => {
    const confirmResult = onConfirm()

    if (typeof confirmResult?.then === "function") {
      confirmResult.then(close)
    } else {
      close()
    }
  }, [close, onConfirm])

  const cancel = useCallback(() => {
    onCancel()
    close()
  }, [close, onCancel])

  const modal = (
    <Modal data-testid="confirmation-modal" show={isOpened} onHide={cancel}>
      {title && (
        <Header>
          <Title>{title}</Title>
        </Header>
      )}
      {message && <Body className="m-0">{message}</Body>}
      <Footer>
        <PrimaryButton className="mr-3" onClick={confirm} disabled={isConfirming}>
          {confirmLabel}
        </PrimaryButton>
        <SecondaryButton onClick={cancel}>{cancelLabel}</SecondaryButton>
      </Footer>
    </Modal>
  )

  return {
    open,
    modal
  }
}
