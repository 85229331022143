import React from "react"
import { string, number } from "prop-types"
import { Form } from "react-bootstrap"
import { useFormikContext } from "formik"
import { styled } from "@linaria/react"
import classNames from "classnames"
import { useQuery } from "@apollo/client"

import Select from "src/styles/components/Select"
import { TEXT_COLORS } from "src/styles/colors"

import airtableTablesListQuery from "./airtableTablesList.gql"

const AirtableTableSelect = ({ className, instanceId, baseId, accountId }) => {
  const { values, setFieldValue, handleBlur, errors, touched } = useFormikContext()

  const { data } = useQuery(airtableTablesListQuery, {
    variables: { oauthUserId: values.oauthUserId, instanceId, baseId, accountId }
  })

  const tableOptions =
    data?.airtableTablesList?.map((table) => ({
      value: table.id,
      label: table.name
    })) || []

  const tableSelectValue = () => {
    if (!values.baseId || !values.tableId) return ""

    return tableOptions.find((option) => option.value === values.tableId)
  }

  return (
    <Form.Group
      className={className}
      controlId="tableId"
      data-testid="airtable-table-select"
    >
      <Form.Label>Airtable table</Form.Label>
      <Select
        name="tableId"
        className={classNames("w-100", errors.tableId && touched.tableId && "is-invalid")}
        options={tableOptions}
        value={tableSelectValue()}
        onChange={({ value }) => setFieldValue("tableId", value)}
        onBlur={handleBlur}
        maxMenuHeight={200}
      />
      <Form.Control.Feedback type="invalid">{errors.tableId}</Form.Control.Feedback>
    </Form.Group>
  )
}

AirtableTableSelect.propTypes = {
  accountId: number.isRequired,
  baseId: string.isRequired,
  instanceId: string
}

export default styled(AirtableTableSelect)`
  .is-invalid .form-field {
    border-color: ${TEXT_COLORS.danger};
  }
`
