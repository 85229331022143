import { connect } from "react-redux"

import { getIsCurrentUserAdmin } from "src/features/meetingPacks/MeetingPack/selectors"

import RightSide from "./RightSide"

const mapState = (state) => ({
  meetingPack: state.meetingPack.meetingPack,
  isCurrentUserAdmin: getIsCurrentUserAdmin(state)
})

export default connect(mapState)(RightSide)
