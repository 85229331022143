import React, { useState } from "react"
import { Dropdown } from "react-bootstrap"
import { number, shape, string } from "prop-types"
import { styled } from "@linaria/react"

import useAblyChannel from "src/hooks/useAblyChannel"
import { CHANNELS } from "src/constants/ably"
import TridotIcon from "src/components/TridotIcon"
import { FONT_SIZES } from "src/styles/sizes"
import IconAI from "src/styles/components/IconAI"
import { OBJECT_TYPES } from "src/features/UniversalAi/constants"
import { useUniversalAi } from "src/features/UniversalAi"
import IconPencilPlus from "src/styles/components/IconPencilPlus"

const DropdownActions = ({ agendaItem, initialAssignedAgendaItemId, className }) => {
  const [assignedAgendaItemId, setAssignedAgendaItemId] = useState(
    initialAssignedAgendaItemId
  )
  const { generateDraftMinutesInSidebar, queryInSidebar } = useUniversalAi()

  const queryAgendaItem = () =>
    queryInSidebar({
      objectId: assignedAgendaItemId,
      objectType: OBJECT_TYPES.assignedTranscriptAgendaItem,
      objectTitle: `${agendaItem.name} Transcript`
    })

  useAblyChannel(CHANNELS.meetingPacksTranscript, {
    onMessage: ({ data, name }) => {
      if (name === "publish_assigned_agenda_items") {
        const assignedItem = data.find((item) => item.agenda_item_id === agendaItem.id)

        if (assignedItem) setAssignedAgendaItemId(assignedItem.id)
      }
    }
  })

  return (
    <Dropdown className={className}>
      <Dropdown.Toggle as={TridotIcon} data-testid="actions-toggle" />

      <Dropdown.Menu alignRight>
        <Dropdown.Item
          onClick={() =>
            generateDraftMinutesInSidebar({
              objectId: agendaItem.id,
              objectType: OBJECT_TYPES.agendaItem
            })
          }
          className="no-wrap"
        >
          <div className="dropdown-icon">
            <IconAI />
          </div>
          Draft Minutes
        </Dropdown.Item>
        {assignedAgendaItemId && (
          <Dropdown.Item onClick={queryAgendaItem}>
            <div className="dropdown-icon">
              <IconPencilPlus variant="without-background" />
            </div>
            Query Item
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

DropdownActions.propTypes = {
  agendaItem: shape({
    id: number.isRequired,
    name: string.isRequired
  }),
  initialAssignedAgendaItemId: number
}

export default styled(DropdownActions)`
  position: absolute;
  right: 10px;
  top: 10px;

  .dropdown-toggle {
    font-size: ${FONT_SIZES.medium};
    cursor: pointer;
  }
`
