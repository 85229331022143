import React, { forwardRef } from "react"
import { cx, css } from "@linaria/core"

const btnStyles = css`
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  padding: 0;
  margin: 0;
  vertical-align: baseline;

  &:hover {
    color: inherit;
    background-color: transparent;
  }

  &:focus {
    color: inherit;
    box-shadow: none;
  }
`

const UnstyledButton = forwardRef((props, ref) => {
  const { children, className, ...rest } = props

  return (
    <button ref={ref} className={cx(btnStyles, className)} {...rest}>
      {children}
    </button>
  )
})

export default UnstyledButton
