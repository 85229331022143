import apiClient from "src/app/apiClient"

const index = (params) => apiClient.get(`/api/meeting_packs`, { params })
const show = ({ meetingPackId }) => apiClient.get(`/api/meeting_packs/${meetingPackId}`)
const accounts = (params) => apiClient.get(`/api/meeting_packs/accounts`, { params })

const meetingPacks = {
  index,
  show,
  accounts
}

export default meetingPacks
