import apiClient from "src/app/apiClient"

const show = ({ messageId }) => apiClient.get(`/api/messages/${messageId}`)
const star = (data) => apiClient.post(`/starred`, data)
const unstar = (params) => apiClient.delete(`/starred`, { params })
const share = ({ messageId, data }) =>
  apiClient.post(`/api/messages/${messageId}/share`, data)

const closeAction = ({ messageId, value, behaviour }) =>
  apiClient.post(`/api/messages/${messageId}/close_action`, { value, behaviour })

const fetchActions = ({ messageId }) =>
  apiClient.get(`/api/messages/${messageId}/actions`)

const messages = {
  show,
  star,
  unstar,
  share,
  closeAction,
  fetchActions
}

export default messages
