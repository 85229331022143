import Flatpickr from "react-flatpickr"
import React from "react"
import { styled } from "@linaria/react"
import { string, func } from "prop-types"
import moment from "moment"

import Icon from "src/styles/components/Icon"
import useMobileScreen from "src/hooks/useMobileScreen"

const DateContainer = ({ className, value, setValue, placeholder }) => {
  const clear = () => setValue(null)
  const handleChange = (v) => {
    const dateObj = v[0]
    const date = moment(dateObj).format("YYYY-MM-DD")
    setValue(date) // '2023-10-28'
  }
  const isMobileScreen = useMobileScreen()
  const flatpikrOptions = { disableMobile: true, locale: { firstDayOfWeek: 1 } }

  return (
    <div className={className}>
      <Flatpickr
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        options={flatpikrOptions}
      />
      {!isMobileScreen && (
        <Icon type="cancel" className="icon" onClick={clear} size="medium" />
      )}
    </div>
  )
}

DateContainer.propTypes = {
  className: string.isRequired,
  value: string,
  setValue: func.isRequired,
  placeholder: string
}

export default styled(DateContainer)`
  width: 48%;
  display: flex;
  height: 36px;

  .icon {
    width: 0;
    right: 28px;
    top: 6px;
    cursor: pointer;
    position: relative;
  }

  input {
    width: 100%;
  }
`
