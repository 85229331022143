import { find } from "lodash"

export const getFolderOption = (folder) => {
  if (!folder) return

  return {
    ...folder,
    value: folder.id,
    label: folder.name
  }
}

/**
 * @param folders Object[]
 * @param rootFolderId String
 * @param rootGroupId Number
 * @param modifyChildren Function
 * Builds tree structure from flat array of objects with "id" and "parentId"
 * [{id: '1', parentId: null}, {id: '2', parentId: '1'}]
 */
export const buildFoldersTree = ({
  folders,
  rootFolderId,
  modifyChildren,
  rootGroupId = null
}) => {
  return folders.reduce((treeFolders, folder) => {
    if (folder.parentId !== rootFolderId) {
      return treeFolders
    }

    const groupId = folder.groupId || rootGroupId

    return [
      ...treeFolders,
      {
        ...folder,
        groupId,
        children: modifyChildren(
          folder,
          buildFoldersTree({
            folders,
            rootFolderId: folder.id,
            rootGroupId: groupId,
            modifyChildren
          })
        )
      }
    ]
  }, [])
}

export const getNormalizedFolderOptions = (
  folders,
  checkedFolderId,
  disabledFolderIds = [],
  excludedGroupIds = []
) => {
  return folders
    .filter(({ groupId }) => !excludedGroupIds.includes(groupId))
    .map((folder) => {
      const disabled = folder.disabled || disabledFolderIds.includes(folder.id)
      let children = disabled ? [] : [{ disabled: true, label: "", className: "d-none" }]

      if (folder.children) {
        children = getNormalizedFolderOptions(
          folder.children,
          checkedFolderId,
          disabledFolderIds
        )
      }

      return {
        ...getFolderOption(folder),
        checked: folder.id === checkedFolderId,
        expanded: folder.expanded || find(folder.children, { id: checkedFolderId }),
        disabled,
        children
      }
    })
}

export const getFolderId = (option) => option?.value

export const addChildFolders = (folders, folder, childFolders = null) => {
  const parentFolder = find(folders, { id: getFolderId(folder) })

  if (parentFolder) {
    if (childFolders)
      parentFolder.children = childFolders.map((childFolder) => ({
        ...childFolder,
        groupId: parentFolder.groupId
      }))
    parentFolder.expanded = folder.expanded
  } else {
    folders.forEach(({ children }) => {
      if (children?.length) addChildFolders(children, folder, childFolders)
    })
  }

  return folders
}
