import React from "react"
import classNames from "classnames"
import { styled } from "@linaria/react"
import { arrayOf, shape, func, string } from "prop-types"

import { BREAKPOINTS, FONT_WEIGHTS, SPACES } from "src/styles/sizes"
import { airtableSectionType } from "src/resources/accounts/airtablePropTypes"
import { BASE_COLORS } from "src/styles/colors"

import { sectionInProgress, sectionIsInvalid } from "./helpers"

const Sidebar = React.forwardRef(
  (
    {
      sections,
      activeSection,
      setActiveSection,
      setChangeSectionLoading,
      record,
      savedRecord,
      handleSave,
      editableFieldIds,
      className
    },
    ref
  ) => {
    const handleChangeSection = (section) => {
      setChangeSectionLoading(true)
      handleSave({
        onSuccess: (newSections) => {
          setChangeSectionLoading(false)
          setActiveSection(newSections.find((s) => s.id === section.id))
        }
      })
    }

    const isActiveSection = (section) => activeSection?.id === section.id

    const allEditableFields = (section) =>
      section.fields.filter(
        (field) => !field.readOnly && editableFieldIds.includes(field.id)
      )

    const allAnsweredFields = (section) =>
      allEditableFields(section).filter((field) => !!record[field.id])

    return (
      <div
        className={classNames("flex-column", className)}
        ref={ref}
        data-behavior="sections-list"
      >
        {sections.map((section) => (
          <div
            key={section.id}
            className={classNames("card p-3 mb-2", {
              active: isActiveSection(section),
              invalid:
                sectionInProgress(section.fields, editableFieldIds, savedRecord) &&
                sectionIsInvalid(section.fields, record)
            })}
            onClick={() => handleChangeSection(section)}
            aria-hidden="true"
            role="button"
          >
            <div className="d-flex justify-content-between">
              <div className="section-name">{section.name}</div>
              {section.statusFieldId && !section.showQuestionsCount && (
                <div className="pl-1">{record[section.statusFieldId]}</div>
              )}
              {section.showQuestionsCount && (
                <div className="pl-1 questions-count">
                  {allAnsweredFields(section).length} /{" "}
                  {allEditableFields(section).length}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    )
  }
)

Sidebar.propTypes = {
  sections: arrayOf(airtableSectionType).isRequired,
  activeSection: airtableSectionType,
  setActiveSection: func.isRequired,
  setChangeSectionLoading: func.isRequired,
  record: shape({}).isRequired,
  savedRecord: shape({}).isRequired,
  editableFieldIds: arrayOf(string).isRequired,
  handleSave: func.isRequired
}

export default styled(Sidebar)`
  width: 100%;
  overflow-y: auto;

  .card {
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 10px rgb(0 0 0 / 10%);
    }

    &.active {
      background-color: #cbcdd6;

      .section-name {
        font-weight: ${FONT_WEIGHTS.bold};
      }
    }

    .section-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: ${FONT_WEIGHTS.semiBold};
    }
  }

  .invalid .questions-count {
    color: ${BASE_COLORS.red};
  }

  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    height: 30%;
    margin-bottom: ${SPACES.large};
  }
`
