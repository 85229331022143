import meetingPacks from "src/resources/meetingPacks/api"
import mentions from "src/resources/mentions/api"
import starredItems from "src/resources/starredItems/api"
import { showCaughtErrorMessage } from "src/helpers/errors"

import accounts from "./api"
import { fetchAccountsFailure, fetchAccountsRequest, fetchAccountsSuccess } from "./slice"

const createApiErrorHandler = (dispatch) => (error) => {
  if (error.message === "Network Error") {
    showCaughtErrorMessage(error)
    dispatch(fetchAccountsFailure(error))
  } else {
    throw error
  }
}

export const fetchAccounts = (params = {}) => (dispatch) => {
  dispatch(fetchAccountsRequest())

  accounts
    .index(params)
    .then((response) => {
      dispatch(fetchAccountsSuccess(response.data))
    })
    .catch(createApiErrorHandler(dispatch))
}

export const fetchMeetingsAccounts = (params = {}) => (dispatch) => {
  dispatch(fetchAccountsRequest())

  meetingPacks
    .accounts(params)
    .then((response) => {
      dispatch(fetchAccountsSuccess(response.data))
    })
    .catch(createApiErrorHandler(dispatch))
}

export const fetchMentionsAccounts = (params = {}) => (dispatch) => {
  dispatch(fetchAccountsRequest())

  mentions
    .accounts(params)
    .then((response) => {
      dispatch(fetchAccountsSuccess(response.data))
    })
    .catch(createApiErrorHandler(dispatch))
}

export const fetchStarredItemsAccounts = (params = {}) => (dispatch) => {
  dispatch(fetchAccountsRequest())

  starredItems
    .accounts(params)
    .then((response) => {
      dispatch(fetchAccountsSuccess(response.data))
    })
    .catch(createApiErrorHandler(dispatch))
}
