import React, { useState, useContext } from "react"
import { string, number, oneOf, bool } from "prop-types"
import { Modal } from "react-bootstrap"

import messages from "src/resources/messages/api"

import ModalContext from "../ModalContext"
import { ACTION_TYPES } from "../constants"

import ModalBody from "./Body"

const TITLE = {
  must_read: {
    default: "Close Must Read"
  },
  voting: {
    default: "Close vote",
    number: "Resolve after number of votes",
    now: "Close vote immediately"
  }
}

const turboRefresh = (messageId) => {
  const url = new URL(window.location)
  const searchParams = new URLSearchParams(url.search)
  searchParams.set("message_id", messageId)
  url.search = searchParams
  window.Turbo.visit(url)
}

const CloseActionModal = ({ show, modalType, messageId, actionType }) => {
  const [value, setValue] = useState()
  const { closeCloseActionModal, closeAllModals } = useContext(ModalContext)

  const onCancel = () => {
    setValue()
    closeCloseActionModal()
  }
  const onSubmit = (confirmedValue) => {
    messages.closeAction({ messageId, value: confirmedValue, behaviour: modalType })
    closeAllModals()
    turboRefresh(messageId)
  }

  return (
    <Modal show={show} keyboard onHide={onCancel}>
      <Modal.Header closeButton onHide={closeAllModals}>
        <Modal.Title as="h5">
          {TITLE[actionType][modalType] || TITLE[actionType].default}
        </Modal.Title>
      </Modal.Header>

      <ModalBody
        actionType={actionType}
        value={value}
        onConfirm={setValue}
        onSubmit={onSubmit}
        onCancel={onCancel}
        modalType={modalType}
      />
    </Modal>
  )
}

CloseActionModal.propTypes = {
  show: bool.isRequired,
  modalType: string,
  actionType: oneOf(Object.keys(ACTION_TYPES)).isRequired,
  messageId: number.isRequired
}

export default CloseActionModal
