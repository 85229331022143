import React, { useContext } from "react"

import { ACTION_TYPES } from "./constants"
import { additionalText, summaryText } from "./helpers"
import { NewMessageActionTextContainer, NewMessageActionTextButton } from "./styles"
import NewMessageActionContext from "./NewMessageActionContext"

const ACTION = {
  [ACTION_TYPES.voting]: "vote",
  [ACTION_TYPES.must_read]: "must read"
}

const NewMessageActionText = () => {
  const { message, optionConfirmed, optionAttributes, openActionsModal } = useContext(
    NewMessageActionContext
  )

  if (!message.actionType) return null

  const onClick = (event) => {
    event.preventDefault()
    openActionsModal()
  }

  const action = ACTION[message.actionType]

  if (optionConfirmed) {
    return (
      <NewMessageActionTextContainer>
        <NewMessageActionTextButton
          className="new-message-action-modal-mobile-button float-right d-inline-block d-sm-none"
          onClick={onClick}
        >
          Edit
        </NewMessageActionTextButton>

        <span>{summaryText(message, optionAttributes)}</span>

        <NewMessageActionTextButton
          className="new-message-action-modal-desktop-button d-none d-sm-inline-block mls"
          onClick={onClick}
        >
          Edit {action} closing options
        </NewMessageActionTextButton>

        <div>{additionalText(message, optionAttributes)}</div>
      </NewMessageActionTextContainer>
    )
  }

  return (
    <NewMessageActionTextContainer>
      <NewMessageActionTextButton onClick={onClick}>
        Set {action} closing options
        <span className="required">*</span>
      </NewMessageActionTextButton>
    </NewMessageActionTextContainer>
  )
}

export default NewMessageActionText
