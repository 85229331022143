import { useDispatch } from "react-redux"

import { actions } from "./store/slice"

const useDocumentPreview = () => {
  const dispatch = useDispatch()

  const open = ({ document, collection = [] }) =>
    dispatch(actions.open({ document, collection }))
  const close = () => dispatch(actions.close())

  return { open, close }
}

export default useDocumentPreview
