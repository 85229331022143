import { connect } from "react-redux"

import LeftSide from "./LeftSide"
import { selectMeetingPackTagOption } from "./selectors"

const mapState = (state) => ({
  meetingPack: state.meetingPack.meetingPack,
  tagOption: selectMeetingPackTagOption(state)
})

export default connect(mapState)(LeftSide)
