import React, { useState } from "react"
import { useQuery } from "@apollo/client"
import { bool, number, shape, string } from "prop-types"

import { DrawerList } from "../styles"
import usePagination from "../usePagination"
import LoadMore from "../LoadMore"
import NotFound from "../NotFound"

import emailsQuery from "./discussionEmails.gql"
import EntityRow from "./EntityRow"

const EmailsList = ({ className, discussionId, filters, visible, filtersOpened }) => {
  const [entities, setEntities] = useState([])
  const [page, setPage] = useState(1)

  const { data, loading } = useQuery(emailsQuery, {
    variables: {
      discussionId,
      page,
      filters
    }
  })

  const rawEntities = data?.discussionForwardingEmails

  const { refPaginationTrigger } = usePagination(
    rawEntities,
    setEntities,
    filters,
    page,
    setPage,
    loading
  )
  const entitiesPresence = !!entities.length

  return (
    <DrawerList
      className={`${className} ${filtersOpened && "filter-opened"} ${
        visible ? "" : "hidden"
      }`}
    >
      {entities.map((forwardingEmail) => (
        <EntityRow
          key={forwardingEmail.id}
          forwardingEmail={forwardingEmail}
          discussionId={discussionId}
        />
      ))}
      <NotFound
        entitiesPresence={entitiesPresence}
        loading={loading}
        text="No emails found"
      />
      <LoadMore
        entitiesPresence={entitiesPresence}
        loading={loading}
        refPaginationTrigger={refPaginationTrigger}
      />
    </DrawerList>
  )
}

EmailsList.propTypes = {
  discussionId: number.isRequired,
  visible: bool.isRequired,
  filtersOpened: bool.isRequired,
  filters: shape({
    text: string,
    authorId: number,
    fromDate: string,
    toDate: string
  })
}

export default EmailsList
