import { useCallback, useState, useEffect } from "react"
import { useQuery } from "@apollo/client"
import { groupBy, orderBy, take } from "lodash"

import { buildSearchQueryPath } from "src/helpers/url"
import { SUGGESTIONS } from "src/features/Search/constants"
import { KEY_CODES } from "src/constants"

import recentSearchesQuery from "./recentSearches.gql"
import recentJumpTosQuery from "./recentJumpTos.gql"
import searchSuggestionsQuery from "./searchSuggestions.gql"
import searchFoldersSuggestionsQuery from "./searchFoldersSuggestions.gql"

const recentSearchesCount = 7

const sortedSuggestionTypes = [
  SUGGESTIONS.search,
  SUGGESTIONS.meeting,
  "scheme",
  SUGGESTIONS.group,
  SUGGESTIONS.discussion,
  SUGGESTIONS.app,
  SUGGESTIONS.folder
]

const schemeSuggestionTypes = [SUGGESTIONS.jointScheme, SUGGESTIONS.privateScheme]

const normalizeRecentSearch = (search) => ({
  ...search,
  type: SUGGESTIONS.search,
  title: search.query,
  path: buildSearchQueryPath(search)
})

const getRecentSearches = (recentSearchesData) => {
  if (!recentSearchesData?.recentSearches) return []

  return recentSearchesData.recentSearches.map(normalizeRecentSearch)
}

const mergeRecentData = (recentSearchesData, recentJumpTosData) => [
  ...getRecentSearches(recentSearchesData),
  ...(recentJumpTosData?.recentJumpTos || [])
]

const mergeSuggestionsData = (data, foldersData) => [
  ...(data?.searchSuggestions || []),
  ...(foldersData?.searchFoldersSuggestions || [])
]

const addActiveFlagToSuggestion = (activeIndex) => (suggestion, index) => ({
  ...suggestion,
  active: index + 1 === activeIndex
})

const sortSuggestions = (suggestion1, suggestion2) =>
  sortedSuggestionTypes.indexOf(suggestion1.type) -
  sortedSuggestionTypes.indexOf(suggestion2.type)

const createKeyboardNavigationHandler = ({
  firstIndex,
  activeIndex,
  setActiveIndex,
  allSuggestions
}) => (e) => {
  if (!allSuggestions.length) return

  if (e.keyCode === KEY_CODES.downArrow) {
    e.preventDefault()
    if (activeIndex === allSuggestions.length) {
      setActiveIndex(firstIndex)
    } else {
      setActiveIndex(activeIndex + 1)
    }
  } else if (e.keyCode === KEY_CODES.upArrow) {
    e.preventDefault()
    if (activeIndex === firstIndex) {
      setActiveIndex(allSuggestions.length)
    } else {
      setActiveIndex(activeIndex - 1)
    }
  }
}

const useSuggestions = (searchText) => {
  const firstIndex = searchText ? 0 : 1
  const [activeIndex, setActiveIndex] = useState(firstIndex)

  useEffect(() => setActiveIndex(firstIndex), [searchText])

  const { data: recentSearchesData } = useQuery(recentSearchesQuery, {
    skip: (searchText?.length || 0) > 0
  })
  const { data: recentJumpTosData } = useQuery(recentJumpTosQuery, {
    skip: (searchText?.length || 0) > 0
  })
  const { data, previousData } = useQuery(searchSuggestionsQuery, {
    variables: { query: searchText },
    skip: (searchText?.length || 0) < 3
  })
  const { data: foldersData, previousData: previousFoldersData } = useQuery(
    searchFoldersSuggestionsQuery,
    {
      variables: { query: searchText },
      skip: (searchText?.length || 0) < 3
    }
  )

  let allSuggestions = searchText
    ? mergeSuggestionsData(data || previousData, foldersData || previousFoldersData)
    : mergeRecentData(recentSearchesData, recentJumpTosData)
  allSuggestions = searchText
    ? allSuggestions.sort(sortSuggestions)
    : take(orderBy(allSuggestions, ["updatedAt"], ["desc"]), recentSearchesCount)
  allSuggestions = allSuggestions.map(addActiveFlagToSuggestion(activeIndex))

  const activeSuggestion = allSuggestions[activeIndex - 1]

  const handleKeyboardNavigation = useCallback(
    createKeyboardNavigationHandler({
      firstIndex,
      activeIndex,
      setActiveIndex,
      allSuggestions
    }),
    [activeIndex, JSON.stringify(allSuggestions)]
  )

  return {
    isSearchActive: activeIndex === 0,
    activeSuggestion,
    handleKeyboardNavigation,
    suggestions: searchText
      ? Object.entries(
          groupBy(allSuggestions, (suggestion) =>
            schemeSuggestionTypes.includes(suggestion.type) ? "scheme" : suggestion.type
          )
        )
      : Object.entries({ recent: allSuggestions })
  }
}

export default useSuggestions
