import { showCaughtErrorMessage } from "src/helpers/errors"

import starredItems from "./api"
import {
  fetchStarredItemsFailure,
  fetchStarredItemsRequest,
  fetchStarredItemsSuccess
} from "./slice"

export const fetchStarredItems = (params) => (dispatch) => {
  dispatch(fetchStarredItemsRequest())

  starredItems
    .index(params)
    .then((response) => dispatch(fetchStarredItemsSuccess(response.data)))
    .catch((error) => {
      showCaughtErrorMessage(error)
      dispatch(fetchStarredItemsFailure(error))
    })
}
