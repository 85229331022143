import React from "react"
import { styled } from "@linaria/react"
import { string } from "prop-types"
import { Badge } from "react-bootstrap"

import { FONT_WEIGHTS } from "src/styles/sizes"
import { composeVariationsClasses } from "src/helpers/linaria"

const DeletedFromAccountBadge = ({ className, variant, ...props }) => {
  const classNameWithVariations = composeVariationsClasses(className, { variant })

  return (
    <Badge className={classNameWithVariations} {...props}>
      Removed from Organisation
    </Badge>
  )
}

DeletedFromAccountBadge.propTypes = {
  className: string,
  variant: string
}

export default styled(DeletedFromAccountBadge)`
  color: black;
  padding: 4px;
  border-radius: 4px;
  font-size: 10px;
  font-weight: ${FONT_WEIGHTS.bold};
  font-style: normal;
  cursor: pointer;
  text-transform: none;

  &--variant-multiline {
    white-space: normal;
    font-weight: ${FONT_WEIGHTS.semiBold};
    line-height: 1.2;
    padding: 6px;
  }
`
