import React, { useState } from "react"
import { styled } from "@linaria/react"
import { Dropdown } from "react-bootstrap"
import { number, shape, string } from "prop-types"
import { useMutation } from "@apollo/client"

import { Column } from "src/components/AttandeesTable"
import TridotIcon from "src/components/TridotIcon"
import useConfirmationModal from "src/hooks/useConfirmationModal"
import useIsOpened from "src/hooks/useIsOpened"

import removeFromAccountMutation from "./removeFromAccount.gql"
import updateAccountRoleMutation from "./updateAccountRole.gql"
import EditUserModal from "./EditUserModal"

const ROLE_LABELS = {
  admin: "an Organisation Admin",
  meeting_pack_creator: "a Meeting Pack Creator",
  member: "an Organisation Member"
}

const ActionsColumn = ({ className, account, user }) => {
  const [selectedRole, selectRole] = useState(null)

  const [removeFromAccount, { loading: removeFromAccountLoading }] = useMutation(
    removeFromAccountMutation,
    {
      variables: { accountId: account.id, userId: user.id },
      refetchQueries: ["accountMemberships"]
    }
  )

  const [updateAccountRole, { loading: updateAccountRoleLoading }] = useMutation(
    updateAccountRoleMutation,
    {
      variables: { accountId: account.id, userId: user.id, role: selectedRole },
      refetchQueries: ["accountMemberships"]
    }
  )

  const { modal: updateAccountRoleModal, open: openUpdateAccountRoleModal } =
    useConfirmationModal({
      title: "Confirmation",
      message: `Are you sure you want to make ${user.fullName || user.email} ${
        ROLE_LABELS[selectedRole]
      }?`,
      isConfirming: updateAccountRoleLoading,
      onConfirm: updateAccountRole
    })

  const selectRoleAndOpenUpdateRoleModal = (role) => {
    selectRole(role)
    openUpdateAccountRoleModal()
  }

  const { modal: removeFromAccountModal, open: openRemoveFromAccountModal } =
    useConfirmationModal({
      title: "Confirmation",
      message: (
        <>
          <div className="mb-3">
            Are you sure you want to remove {user.fullName || user.email} from the{" "}
            {account.name} organisation?
          </div>
          <div>
            They will lose access to all {account.name} Collaboration Spaces and Meeting
            Packs.
          </div>
        </>
      ),
      isConfirming: removeFromAccountLoading,
      onConfirm: removeFromAccount
    })

  const {
    isOpened: isOpenedEditUserModal,
    open: openEditUserModal,
    close: closeEditUserModal
  } = useIsOpened()

  return (
    <>
      {updateAccountRoleModal}
      {removeFromAccountModal}
      <EditUserModal
        accountId={account.id}
        userId={user.id}
        jobTitle={user.jobTitle}
        isOpened={isOpenedEditUserModal}
        close={closeEditUserModal}
      />
      <Column className={className}>
        <Dropdown>
          <Dropdown.Toggle as={TridotIcon} data-testid="actions-toggle" />

          <Dropdown.Menu alignRight>
            <Dropdown.Item onClick={openEditUserModal}>Edit role</Dropdown.Item>
            <Dropdown.Divider />

            {Object.entries(ROLE_LABELS).map(
              ([role, label]) =>
                user.role !== role && (
                  <Dropdown.Item
                    key={role}
                    disabled={updateAccountRoleLoading}
                    onClick={() => selectRoleAndOpenUpdateRoleModal(role)}
                  >
                    {`Make ${label}`}
                  </Dropdown.Item>
                )
            )}

            <Dropdown.Divider />

            <Dropdown.Item
              disabled={removeFromAccountLoading}
              className="delete text-nowrap"
              variant="danger"
              onClick={openRemoveFromAccountModal}
            >
              Remove from Organisation
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Column>
    </>
  )
}

ActionsColumn.propTypes = {
  account: shape({
    id: number.isRequired,
    name: string.isRequired
  }).isRequired,
  user: shape({
    id: number.isRequired,
    fullName: string.isRequired,
    jobTitle: string,
    role: string.isRequired
  }).isRequired
}

export default styled(ActionsColumn)`
  .dropdown {
    position: static;
  }

  .dropdown-toggle {
    cursor: pointer;
  }

  .dropdown-menu {
    a {
      white-space: nowrap;
    }
  }
`
