export const FIELD_TYPES = {
  string: "singleLineText",
  text: "multilineText",
  select: "singleSelect",
  multiselect: "multipleSelects",
  checkbox: "checkbox",
  richText: "richText"
}

export const NON_EDITABLE_FIELD_TYPES = {
  multipleAttachments: "multipleAttachments"
}
