import { connect } from "react-redux"

import { withErrorBoundary } from "src/app/ErrorBoundary"
import { goBack, goForward } from "src/features/meetingPacks/MeetingPack/meetingPackSlice"
import {
  isPrevButtonDisabled,
  isNextButtonDisabled,
  getHighlighted,
  getActiveItemId,
  getActiveDocumentId
} from "src/features/meetingPacks/MeetingPack/selectors"

import Content from "./Content"

const mapState = (state) => ({
  highlighted: getHighlighted(state),
  activeItemId: getActiveItemId(state),
  activeDocumentId: getActiveDocumentId(state),
  isPrevButtonDisabled: isPrevButtonDisabled(state),
  isNextButtonDisabled: isNextButtonDisabled(state)
})

const mapDispatch = {
  goBack,
  goForward
}

export default withErrorBoundary(connect(mapState, mapDispatch)(Content))
