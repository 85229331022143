import React from "react"
import { shallowEqual, useSelector } from "react-redux"
import { arrayOf, func, shape } from "prop-types"

import SearchableSelect from "src/components/SearchableSelect"
import SchemeOption from "src/components/SchemeSelect/Option"
import { selectAccountOptions } from "src/resources/accounts/selectors"
import { optionType } from "src/constants/propTypes"
import { selectSchemeOptions } from "src/resources/schemes/selectors"
import { DEFAULT_ACCOUNT_FILTER } from "src/resources/accounts/constants"
import { DEFAULT_SCHEME_FILTER } from "src/resources/schemes/constants"
import {
  ACTION_TYPE_OPTIONS,
  DEFAULT_ACTION_TYPE_FILTER
} from "src/resources/messages/constants"

const MessagesFilters = ({
  actionTypeOptions = ACTION_TYPE_OPTIONS,
  filters,
  updateFilters
}) => {
  const accountOptions = useSelector(selectAccountOptions, shallowEqual)
  const schemeOptions = useSelector(selectSchemeOptions, shallowEqual)
  const schemesByAccount =
    filters.accountFilter.value === DEFAULT_ACCOUNT_FILTER.value
      ? schemeOptions
      : schemeOptions.filter(
          ({ accountId, accountIds }) =>
            accountId === filters.accountFilter.value ||
            accountIds.includes(filters.accountFilter.value)
        )

  const handleActionTypeSelect = (actionTypeOption) => {
    updateFilters({
      ...filters,
      actionTypeFilter: actionTypeOption
    })
  }

  const handleAccountSelect = (accountOption) => {
    updateFilters({
      ...filters,
      accountFilter: accountOption,
      schemeFilter: DEFAULT_SCHEME_FILTER
    })
  }

  const handleSchemeSelect = (schemeOption) => {
    const accountOption =
      schemeOption.value === DEFAULT_SCHEME_FILTER.value
        ? DEFAULT_ACCOUNT_FILTER
        : accountOptions.find(
            ({ value }) =>
              schemeOption.accountId === value || schemeOption.accountIds.includes(value)
          )

    updateFilters({
      ...filters,
      accountFilter: accountOption,
      schemeFilter: schemeOption
    })
  }

  const showAccountFilter = accountOptions.length > 1
  const showSchemeFilter = schemeOptions.length > 1

  return (
    <>
      <SearchableSelect
        prefix="Type"
        searchable={false}
        options={[DEFAULT_ACTION_TYPE_FILTER, ...actionTypeOptions]}
        selected={filters.actionTypeFilter}
        setSelected={handleActionTypeSelect}
      />
      {showAccountFilter && (
        <SearchableSelect
          prefix="In"
          options={[DEFAULT_ACCOUNT_FILTER, ...accountOptions]}
          selected={filters.accountFilter}
          setSelected={handleAccountSelect}
        />
      )}
      {showSchemeFilter && (
        <SearchableSelect
          components={{ Option: SchemeOption }}
          prefix="In"
          options={[DEFAULT_SCHEME_FILTER, ...schemesByAccount]}
          selected={filters.schemeFilter}
          setSelected={handleSchemeSelect}
        />
      )}
    </>
  )
}

MessagesFilters.propTypes = {
  actionTypeOptions: arrayOf(optionType),
  filters: shape({
    actionTypeFilter: optionType,
    accountFilter: optionType,
    schemeFilter: optionType
  }).isRequired,
  updateFilters: func.isRequired
}

export default MessagesFilters
