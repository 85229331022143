class @ChangePasswordComponent
  constructor: ->
    @passwordInput = $be('password-input')
    @passwordConfirmationInput = $be('password-confirmation-input')
    @passwordConfirmationError = $be('password-confirmation-error')

  setup: =>
    @passwordInput.password(
      minimumLength: 10,
      shortPass: 'Password too short (10 minimum)'
    )

    @passwordInput.on 'keyup', =>
      @checkPasswordConfirmationMatch()

    @passwordConfirmationInput.on 'keyup', =>
      @checkPasswordConfirmationMatch()

    @passwordInput.on 'password.score', (e, score) =>
      scoreThreshold = 35
      if score < scoreThreshold
        @passwordInput[0].setCustomValidity('Password is too weak.')
      else
        @passwordInput[0].setCustomValidity('')

  checkPasswordConfirmationMatch: =>
    if @passwordInput.val() is @passwordConfirmationInput.val()
      @passwordConfirmationError.html('')
      @passwordConfirmationInput[0].setCustomValidity('')
    else
      @passwordConfirmationError.html('No match')
      @passwordConfirmationInput[0].setCustomValidity('No match')


