import { styled } from "@linaria/react"

export default styled.div`
  color: #a8abbb;
  width: 184px;
  display: flex;
  align-items: center;

  .text-right {
    margin-right: 0;
    margin-left: auto;
  }
`
