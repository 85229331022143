import React from "react"
import { styled } from "@linaria/react"
import { arrayOf, number, shape, string } from "prop-types"
import { sortBy, partition, map } from "lodash"

import UsersContacts from "src/components/UsersContacts"
import useIsOpened from "src/hooks/useIsOpened"
import { getCurrentUser } from "src/helpers/user"

import ManageTeamMembersModal from "./ManageTeamMembersModal"
import ThreeDotActions from "./ThreeDotActions"

const Team = ({ team, accountId, accountMemberships, possibleTeams }) => {
  const currentUser = getCurrentUser()

  const teamUserIds = team.teamMemberships.map((teamMembership) => teamMembership.userId)

  const [teamMembers, possibleMembers] = map(
    partition(accountMemberships, (accountMembership) =>
      teamUserIds.includes(accountMembership.user.id)
    ),
    (part) => {
      return part.map((accountMembership) => {
        if (accountMembership.userJobTitle?.length) {
          return { ...accountMembership.user, jobTitle: accountMembership.userJobTitle }
        }
        return accountMembership.user
      })
    }
  )

  const {
    isOpened: isTeamMembersModalOpened,
    open: openTeamMembersModal,
    close: closeTeamMembersModal
  } = useIsOpened()

  return (
    <div className="card mb-3" key={team.id}>
      <div className="d-flex justify-content-between">
        <h5>{team.name}</h5>
        <ThreeDotActions
          team={team}
          accountId={accountId}
          openTeamMembersModal={openTeamMembersModal}
        />
        <ManageTeamMembersModal
          team={team}
          isOpened={isTeamMembersModalOpened}
          onHide={closeTeamMembersModal}
          possibleUsers={sortBy(possibleMembers, "lastName")}
          teamMembers={sortBy(teamMembers, "lastName")}
          possibleTeams={possibleTeams}
        />
      </div>
      {team.teamMemberships.length ? (
        <UsersContacts
          attendees={sortBy(teamMembers, "lastName")}
          currentUser={currentUser}
        />
      ) : (
        <div className="text-center py-4">
          <button className="button is-secondary" onClick={openTeamMembersModal}>
            Add team members
          </button>
        </div>
      )}
    </div>
  )
}

Team.propTypes = {
  accountId: number.isRequired,
  accountMemberships: arrayOf(shape({})),
  possibleTeams: arrayOf(
    shape({
      id: number.isRequired,
      name: string.isRequired
    })
  ),
  team: shape({
    id: number.isRequired,
    name: string.isRequired,
    teamMemberships: arrayOf(shape({})).isRequired
  }).isRequired
}

export default styled(Team)`
  .dropdown {
    position: static;
  }
`
