const Modes = {
  Sidebar: "sidebar",
  Modal: "modal"
}

const Commands = {
  SummariseAsText: "Summarise as text",
  SummariseAsBullets: "Summarise as bullets",
  GenerateDraftMinutes: "Draft Minutes",
  Query: "Query"
}

const SIDEBAR_OPENED_EVENT = "universal-ai:sidebar-opened"
const SIDEBAR_CLOSED_EVENT = "universal-ai:sidebar-closed"

const SIDEBAR_FULLSCREEN_BREAKPOINT = 768
const SIDEBAR_OVERLAY_BREAKPOINT = 1388

const OBJECT_TYPES = {
  message: "Message",
  attachedDocument: "AttachedDocument",
  discussion: "Discussion",
  agendaItem: "MeetingPacks::AgendaItem",
  agendaItemDocument: "MeetingPacks::AgendaItemDocument",
  meetingPack: "MeetingPacks::MeetingPack",
  transcript: "MeetingPacks::Transcript",
  assignedTranscriptAgendaItem: "MeetingPacks::AssignedTranscriptAgendaItem",
  iframeContent: "Apps::Content",
  contentCloudFile: "ContentCloud::File"
}

const FAIL_MESSAGE =
  "Apologies, Knowa is momentarily busy on a date with its AI companions. Please try again shortly."

const FAIL_AUTHENTICATION_MESSAGE =
  "You are not authorized. Please try to reload the page."

export {
  Modes,
  Commands,
  SIDEBAR_OPENED_EVENT,
  SIDEBAR_CLOSED_EVENT,
  SIDEBAR_FULLSCREEN_BREAKPOINT,
  SIDEBAR_OVERLAY_BREAKPOINT,
  OBJECT_TYPES,
  FAIL_MESSAGE,
  FAIL_AUTHENTICATION_MESSAGE
}
