import { get, merge } from "lodash"

import { be } from "src/helpers/document"
import { isOnDiscussionPage } from "src/helpers/url"

const userAssignedAction = ({ actions, userId }) =>
  actions?.find((action) => action.user_id === userId)

const messageCreateStatus = ({
  memberIds,
  mutedUsersIds,
  mentionedUsersIds,
  authorId,
  actions,
  userId
}) => {
  if (!memberIds.includes(userId)) {
    return null
  }

  const assignedAction = userAssignedAction({ actions, userId })

  if (authorId === userId) {
    if (assignedAction) {
      return "actions"
    }

    return null
  }

  if (mentionedUsersIds.includes(userId) && assignedAction) {
    return "actions"
  }

  if (mentionedUsersIds.includes(userId)) {
    return "mentions"
  }

  if (!mutedUsersIds.includes(userId)) {
    return "unread"
  }
}

export const userAblyMessageInfo = ({ name, data, userId }) => {
  const {
    actions,
    authorId,
    discussionId,
    mentionedUsersIds,
    newMentionedUserIds,
    removedMentionedUserIds,
    group: { jointSchemeId, mainSchemeIds, memberIds, mutedUsersIds } = {}
  } = data

  const info = {
    authorId,
    discussionId,
    jointSchemeId,
    mainSchemeIds,
    add: true,
    status: null
  }

  if (name === "message-create") {
    info.status = messageCreateStatus({
      memberIds,
      mutedUsersIds,
      mentionedUsersIds,
      actions,
      authorId,
      userId
    })
  } else if (name === "message-update") {
    const action = userAssignedAction({ actions, userId })

    if (action) {
      info.status = "actions"

      if (action.complete) info.add = -1
      else if (newMentionedUserIds?.includes(userId)) info.add = 1
      else info.add = 0 // NOTE: no ability to differentiate if user uncompletes the action
    } else if (removedMentionedUserIds?.includes(userId)) {
      info.status = "actions"
      info.add = -1
    }
  }

  return info
}

export const userAblyDestroyedMessageInfo = ({ data, status }) => {
  const { thread, group: { jointSchemeId, mainSchemeIds } = {} } = data

  return {
    jointSchemeId,
    mainSchemeIds,
    status,
    discussionId: thread.id,
    add: false
  }
}

export const updateChatsStats = ({ threadId, isMention, stats }) => {
  const chatUnreadCount = get(stats, ["unread", "chats", threadId], 0) + 1
  let chatMentionsCount = get(stats, ["mentions", "chats", threadId], 0)
  if (isMention) chatMentionsCount += 1

  const updatedStats = {
    unread: {
      chats: { [threadId]: chatUnreadCount }
    },
    mentions: {
      chats: { [threadId]: chatMentionsCount }
    }
  }

  return merge({}, stats, updatedStats)
}

export const updateDiscussionsStats = ({ messageInfo, stats }) => {
  if (!stats[messageInfo.status]) {
    return stats
  }

  const discussionCount = get(
    stats,
    [messageInfo.status, "discussions", messageInfo.discussionId],
    0
  )
  const jointSchemeCount = get(
    stats,
    [messageInfo.status, "jointSchemes", messageInfo.jointSchemeId],
    0
  )
  const firstMainSchemeCount = get(
    stats,
    [messageInfo.status, "mainScheme", messageInfo.mainSchemeIds[0]],
    0
  )
  const secondMainSchemeCount = get(
    stats,
    [messageInfo.status, "mainScheme", messageInfo.mainSchemeIds[1]],
    0
  )
  let addedValue = messageInfo.add
  if (typeof messageInfo.add !== "number") {
    addedValue = messageInfo.add ? 1 : -1
  }
  let updatedStats = {}

  if (messageInfo.jointSchemeId) {
    updatedStats = {
      [messageInfo.status]: {
        discussions: {
          [messageInfo.discussionId]: Math.max(discussionCount + addedValue, 0)
        },
        mainScheme: {
          [messageInfo.mainSchemeIds[0]]: Math.max(firstMainSchemeCount + addedValue, 0),
          [messageInfo.mainSchemeIds[1]]: Math.max(secondMainSchemeCount + addedValue, 0)
        },
        jointSchemes: {
          [messageInfo.jointSchemeId]: Math.max(jointSchemeCount + addedValue, 0)
        }
      }
    }
  } else {
    updatedStats = {
      [messageInfo.status]: {
        discussions: {
          [messageInfo.discussionId]: Math.max(discussionCount + addedValue, 0)
        },
        mainScheme: {
          [messageInfo.mainSchemeIds[0]]: Math.max(firstMainSchemeCount + addedValue, 0)
        },
        privateSchemes: {
          [messageInfo.mainSchemeIds[0]]: Math.max(firstMainSchemeCount + addedValue, 0)
        }
      }
    }
  }

  return merge({}, stats, updatedStats)
}

export const scrollToCurrentAccountItem = () => {
  const currentSidebarItem = be("accounts-sidebar-section").find(".current")[0]

  const url = new URL(window.location)

  // If "focus" query param is "recent" then current page was navigated from
  // recent section and we should keep scroll bar at the top of the page.
  if (url.searchParams.get("focus") === "recent") {
    return
  }

  if (currentSidebarItem && currentSidebarItem.offsetTop) {
    be("scheme-menu-section").scrollTop(currentSidebarItem.offsetTop - 110)
  }
}

export const shouldSkipStatsUpdate = (messageData, status, currentUserId) => {
  const { discussionId, skipUpdate, usersToSkipUpdate } = messageData

  return (
    (["unread", "mentions"].includes(status) && isOnDiscussionPage(discussionId)) ||
    skipUpdate ||
    usersToSkipUpdate?.includes(currentUserId)
  )
}
