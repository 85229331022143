class @StarMessageComponent
  constructor: (itemsContainer) ->
    @itemsContainer = itemsContainer
    @starredItemsService = new StarredItemsService()
    @starMessageSelector = '[data-behavior="star-message"]'
    @unstarMessageSelector = '[data-behavior="unstar-message"]'
    @messageContainerSelector = '[data-behavior="message-container"]'

  setup: () ->
    @itemsContainer.on('click', (e) =>
      $target = $(e.target)
      $messageContainer = $target.parents(@messageContainerSelector)

      if @isSelectorClicked($target, @starMessageSelector)
        e.preventDefault()
        @starMessage($messageContainer)

      if @isSelectorClicked($target, @unstarMessageSelector)
        e.preventDefault()
        @unstarMessage($messageContainer)
    )

  isSelectorClicked: ($target, selector) ->
    $target.is(selector) || $target.parent(selector).length

  starMessage: ($messageContainer) =>
    @starredItemsService
      .starMessage($messageContainer.data('id'))
      .done((response) =>
        @setContainerStarred($messageContainer, response.starred_item_id)
      )

  unstarMessage: ($messageContainer) ->
    @starredItemsService
      .unstarMessage($messageContainer.data('id'))
      .done(() =>
        @setContainerUnstarred($messageContainer)
      )

  setContainerStarred: ($messageContainer, starredItemId) ->
    $messageContainer.addClass('message-block__starred')

  setContainerUnstarred: ($messageContainer) ->
    $messageContainer.removeClass('message-block__starred')
