import React, { useState } from "react"
import { arrayOf, func, number, shape } from "prop-types"

import useIsOpened from "src/hooks/useIsOpened"
import ContactDetailsModal from "src/components/UsersContacts/ContactDetailsModal"

import { AgendaItem } from "../types"

import ViewAttendeesModal from "./ViewAttendeesModal"
import ManageAttendeesModal from "./ManageAttendeesModal"

const ManageUsers = ({
  agendaItem,
  currentUserId,
  teams,
  agendaItemAttendees,
  possibleAttendees,
  children
}) => {
  const [openedUser, setOpenedUser] = useState(agendaItemAttendees[0])
  const {
    isOpened: isContactModalOpened,
    open: openContactModal,
    close: closeContactModal
  } = useIsOpened()

  const {
    isOpened: isViewModalOpened,
    open: openViewModal,
    close: closeViewModal
  } = useIsOpened()

  const {
    isOpened: isManageModalOpened,
    open: openManageModal,
    close: closeManageModal
  } = useIsOpened()

  const openContactDetailsModal = (user) => {
    setOpenedUser(user)
    closeViewModal()
    openContactModal()
  }

  const closeContactDetailsModal = () => {
    closeContactModal()
    openViewModal()
  }

  const switchToViewModal = () => {
    closeManageModal()
    openViewModal()
  }

  const switchToManageModal = () => {
    closeViewModal()
    openManageModal()
  }

  return (
    <>
      {children(openViewModal)}

      <ContactDetailsModal
        isOpened={isContactModalOpened}
        close={closeContactDetailsModal}
        user={openedUser}
        isCurrentUser={currentUserId === openedUser?.id}
      />

      <ViewAttendeesModal
        agendaItem={agendaItem}
        isOpened={isViewModalOpened}
        currentUserId={currentUserId}
        onHide={closeViewModal}
        openContactDetailsModal={openContactDetailsModal}
        openManageAttendees={switchToManageModal}
        closeContactDetailsModal={closeContactDetailsModal}
        agendaItemAttendees={agendaItemAttendees}
      />

      <ManageAttendeesModal
        agendaItem={agendaItem}
        teams={teams}
        isOpened={isManageModalOpened}
        currentUserId={currentUserId}
        onHide={closeManageModal}
        openViewAttendees={switchToViewModal}
        agendaItemAttendees={agendaItemAttendees}
        possibleAttendees={possibleAttendees}
      />
    </>
  )
}

ManageUsers.propTypes = {
  agendaItem: AgendaItem.isRequired,
  children: func,
  currentUserId: number,
  teams: arrayOf(shape),
  agendaItemAttendees: arrayOf(shape),
  possibleAttendees: arrayOf(shape)
}

export default ManageUsers
