class @ChatsShowHandler
  constructor: ->
    chatPage = $be('chat-page')
    @chatId = chatPage.data('id')

    @userMentionOptions = window.userMentionOptions

    @discussionMessagesComponent = new DiscussionMessagesComponent(
      thread:                    { type: 'Chat', id: @chatId },
      editDesktopMessageConfig:  @editDesktopMessageConfig(),
      editMobileMessageConfig:   @editMobileMessageConfig(),
      messageChannelName:        "chats:updates",
      messagePathTemplate:       "/chats/#{@chatId}/messages/MESSAGE_ID",
      unreadMessagePathTemplate: "/chats/#{@chatId}/unread_messages/MESSAGE_ID"
    )

    @autosaveContentId = "chat-#{@chatId}"
    @autosavedMessageComponent = new AutosavedMessageComponent(
      @autosaveContentId
    )

    @fieldEncodingComponent = new FieldEncodingComponent()
    @newMessageForm   = $be('new-message-form')
    @newMessageSubmit = $be('new-message-submit')
    @newMessageEditor = new MessageEditorComponent(
      $be('new-message-input'),
      jQuery.extend({}, @editDesktopMessageConfig(), {
        onFocus: => @discussionMessagesComponent.scrollToBottom(),
        onCtlrEnter: -> $be('new-message-submit').click(),
        autosaveContentId: @autosaveContentId,
      })
    )

    @userStatusLabelComponent = new UserStatusLabelComponent()
    @chatMembersDialogComponent = new ChatMembersDialogComponent()

    @attachDocumentComponent = window.boxComponentsFactory.attachDocumentComponent({
      thread: {
        id: @chatId,
        type: 'Chat'
      },
      autosavedMessageComponent: @autosavedMessageComponent,
      attachCallback: @discussionMessagesComponent.scrollToBottom
    })

    @showChatMemebrsModal = $be('chat-show-members-modal')

    @starMessageComponent = new StarMessageComponent(chatPage)

  setup: =>
    @discussionMessagesComponent.setup()
    @userStatusLabelComponent.setup()
    @newMessageEditor.setup()
    @chatMembersDialogComponent.setup()
    @bindNewMessageFormActions()

    @attachDocumentComponent.setup()
    @starMessageComponent.setup()

    @discussionMessagesComponent.bindSendingMessageHandler(
      @newMessageForm,
      () => @newMessageEditor.getWrappedContent()
    )

    @attachDocumentComponent.loadAutosavedDocuments()

    $be('show-manage-members-modal-link').click =>
      @showChatMemebrsModal.modal('hide')

    window.layoutHandler.showCurrentUserLabel(@showChatMemebrsModal)

    setTimeout(
      () => @newMessageEditor.focus(skipCallback: true),
      100
    )

  teardown: =>
    @discussionMessagesComponent.teardown()
    @userStatusLabelComponent.teardown()

  clearMessageForm: =>
    @newMessageEditor.clear()
    @newMessageEditor.resetCache()
    @autosavedMessageComponent.reset()
    @attachDocumentComponent.clearAttachedDocuments()

  bindNewMessageFormActions: =>
    @newMessageForm.on('ajax:before', (e) =>
      unless !@newMessageEditor.contentIsEmpty() || @attachDocumentComponent.documentsAreAttached()
        return e.preventDefault()

      @fieldEncodingComponent.replaceMessageBody(@newMessageForm[0])
    )
    @newMessageForm.on('ajax:success', () =>
      @clearMessageForm()
    )

  insertPage: (pageNumber, messagesPage) =>
    @discussionMessagesComponent.insertPage(pageNumber, messagesPage)

  addNewMessage: (messageId, authorId, messageHtml) =>
    @discussionMessagesComponent.addNewMessage(
      messageId, authorId, messageHtml
    )
    @newMessageEditor.focus()

  removeMessage: (messageId) =>
    @discussionMessagesComponent.removeMessage(messageId)

  updateMessage: (messageId, messageHtml) =>
    @discussionMessagesComponent.updateMessage(
      messageId,
      messageHtml
    )

  editDesktopMessageConfig: =>
    mentions: {
      data: @userMentionOptions
    }
    params: {
      quickInsertEnabled: false
      toolbarSticky: false
      placeholderText: 'Type a message'
      heightMin: '2rem'
      heightMax: '8rem'
    }

  editMobileMessageConfig: =>
    {
      mentions: {
        data: @userMentionOptions
      }
      params: {
        quickInsertEnabled: false
        placeholderText: ''
        height: "16vh"
      }
    }
