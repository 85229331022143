import { styled } from "@linaria/react"

import { FONT_SIZES, SPACES } from "src/styles/sizes"
import { TEXT_COLORS } from "src/styles/colors"

const AnnotationsCount = styled.div`
  font-size: ${FONT_SIZES.small};
  color: ${TEXT_COLORS.muted};
  display: flex;
  align-items: center;

  [class^="icon"] {
    margin-right: ${SPACES.xSmall};
  }
`

export default AnnotationsCount
