import React from "react"
import { orderBy } from "lodash"
import { arrayOf, number, shape } from "prop-types"

import Account from "./Account"

const Accounts = ({ accounts }) => {
  if (!accounts) {
    return null
  }

  return (
    <div data-behavior="accounts-sidebar-section" className="sidebar-section">
      <h5 className="sidebar-section-title">Organisations</h5>

      <ul className="sidebar-menu">
        {orderBy(accounts, ["name"], ["asc"]).map((account) => (
          <Account key={account.id} account={account} />
        ))}
      </ul>
    </div>
  )
}

Accounts.propTypes = {
  accounts: arrayOf(
    shape({
      id: number.isRequired
    })
  )
}

export default Accounts
