import React, { useState, useCallback } from "react"
import { bool, func, number, arrayOf, shape, string } from "prop-types"
import { Form, Modal } from "react-bootstrap"
import { css } from "@linaria/core"

import WarningText from "src/styles/components/WarningText"

import { ModalFooter } from "./styles"
import NotAssignedAttendees from "./NotAssignedAttendees"

const modalContentClassName = css`
  overflow-y: auto;
`

const PublishModal = ({
  isOpened,
  onClose,
  onPublish,
  onCustomMessageChange,
  notAssignedAttendees
}) => {
  const [isPublishing, setPublishing] = useState(false)

  const handlePublish = useCallback(() => {
    setPublishing(true)
    onPublish().finally(() => setPublishing(false))
  }, [setPublishing, onPublish])

  return (
    <Modal
      centered
      size="md"
      onHide={onClose}
      show={isOpened}
      data-testid="publish-modal"
      contentClassName={modalContentClassName}
    >
      <Modal.Header closeButton>
        <Modal.Title>Publish Meeting Pack</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formCustomMessage">
            <Form.Label className="text-muted">
              Include a message for attendees
            </Form.Label>
            <Form.Control
              as="textarea"
              rows="4"
              placeholder="Type something"
              onChange={onCustomMessageChange}
            />
          </Form.Group>
        </Form>
        <WarningText
          className="mbx"
          variant="success"
          text={
            "Attendees will be notified by email. " +
            "The meeting pack will immediately be accessible via their Knowa account."
          }
        />
        {notAssignedAttendees.length > 0 && (
          <NotAssignedAttendees attendees={notAssignedAttendees} />
        )}
      </Modal.Body>
      <ModalFooter>
        <div>
          <p>
            <WarningText
              component="a"
              href="//help.knowa.co/en/articles/4515352-meeting-packs-pre-publishing-checklist"
              target="_blank"
              rel="noreferrer"
              className="mbx"
              text="Have you reviewed the pre-publish checklist?"
            />
          </p>
          <div className="d-flex justify-content-center">
            <button className="button" disabled={isPublishing} onClick={handlePublish}>
              {isPublishing ? "Publishing..." : "Publish"}
            </button>
            <button className="button is-secondary" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  )
}

PublishModal.propTypes = {
  isOpened: bool.isRequired,
  onClose: func.isRequired,
  onPublish: func.isRequired,
  onCustomMessageChange: func.isRequired,
  notAssignedAttendees: arrayOf(
    shape({
      fullName: string.isRequired,
      avatarUrl: string,
      avatarColorId: number,
      initials: string
    })
  )
}

export default PublishModal
