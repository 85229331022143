import { bool, func } from "prop-types"
import React from "react"

const CancelButton = ({ children, onClick, disabled = false }) => {
  return (
    <button className="button is-secondary" onClick={onClick} disabled={disabled}>
      {children}
    </button>
  )
}

CancelButton.propTypes = {
  onClick: func.isRequired,
  disabled: bool
}

export default CancelButton
