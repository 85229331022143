class @NewMessagesWatcher
  constructor: (messageUrlTemplate) ->
    @messageUrlTemplate = messageUrlTemplate
    @windowFocusComponent = new WindowFocusComponent(
      onfocus: [
        => @markPendingMessagesAsRead()
      ]
    )
    @pendingReadMessages = []

  setup: =>
    @windowFocusComponent.setup()

  markMessageAsRead: (messageId) =>
    if @windowFocusComponent.windowIsFocused()
      @sendMessageReadRequest(messageId)
    else
      @pendingReadMessages.push(messageId)

  markPendingMessagesAsRead: =>
    while @pendingReadMessages.length > 0
      messageId = @pendingReadMessages.pop()
      @sendMessageReadRequest(messageId)

  sendMessageReadRequest: (messageId) =>
    url = @messageUrlTemplate.replace('MESSAGE_ID', messageId)
    $.ajax(
      url: url,
      method: 'DELETE',
      dataType: 'json'
    )
