import React, { useEffect, useState } from "react"
import classNames from "classnames"
import { bool, shape, number, oneOf, string } from "prop-types"

import { getMeetingPackLastLocation } from "src/helpers/meetingPacks"

import { hasDraft } from "./helpers"

const Thread = ({
  current,
  thread: { id, title, badge, path, __typename: typename }
}) => {
  const [showDraft, setShowDraft] = useState(hasDraft({ typename, id }) && !badge)

  useEffect(() => {
    const onAutoSaveChange = () => setShowDraft(hasDraft({ typename, id }) && !badge)
    window.addEventListener("autosaveChange", onAutoSaveChange)

    return () => {
      window.removeEventListener("autosaveChange", onAutoSaveChange)
    }
  })

  const threadPath =
    typename === "MeetingPackThread" ? getMeetingPackLastLocation(id) || path : path

  return (
    <li
      key={threadPath}
      data-testid={`recent-thread-${id}`}
      data-behavior="recent-thread"
      className={classNames(badge, { current, draft: showDraft })}
    >
      <a href={`${threadPath}?focus=recent`} className="menu-link">
        {title}
      </a>
    </li>
  )
}

Thread.propTypes = {
  thread: shape({
    id: number.isRequired,
    title: string.isRequired,
    path: string.isRequired,
    badge: oneOf(["", "unread", "mention"])
  }).isRequired,
  current: bool.isRequired
}

export default Thread
