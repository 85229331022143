import React, { useState } from "react"
import { styled } from "@linaria/react"
import { bool, func, shape } from "prop-types"

import { FONT_SIZES, SPACES } from "src/styles/sizes"
import Button from "src/styles/components/Button/Link"
import Checkbox from "src/styles/components/Checkbox"

const Container = styled.div`
  padding: ${SPACES.small} ${SPACES.medium};
  border-top: 1px solid #dee2e6;
`

const Row = styled.label`
  display: flex;
  justify-content: space-between;
  padding: ${SPACES.xSmall} 0;

  &:first-child {
    margin-top: ${SPACES.small};
  }
`

const Hint = styled.div`
  font-size: ${FONT_SIZES.xSmall};
  font-style: italic;
  line-height: 1;
`

const ExportSettings = ({ settings, onChange, expanded: initiallyExpanded = false }) => {
  const [expanded, setExpanded] = useState(initiallyExpanded)

  const handleChange = (settingName, value) => {
    onChange({ ...settings, [settingName]: value })
  }

  const toggleText = expanded ? "Close" : "Open"
  const showHint = !Object.keys(settings).some((key) => settings[key])

  return (
    <Container>
      <div>
        <div>
          <Button onClick={() => setExpanded(!expanded)}>
            {toggleText} export settings
          </Button>
        </div>
        {showHint && (
          <Hint className="text-secondary">Please, select at least one option</Hint>
        )}
      </div>
      {expanded && (
        <div>
          <Row>
            <div>Cover page</div>
            <Checkbox
              id="include-cover-page"
              checked={settings.includeCoverPage}
              onChange={(e) => handleChange("includeCoverPage", e.target.checked)}
            />
          </Row>
          <Row>
            <div>Agenda page</div>
            <Checkbox
              id="include-agenda-page"
              checked={settings.includeAgendaPage}
              onChange={(e) => handleChange("includeAgendaPage", e.target.checked)}
            />
          </Row>
          <Row>
            <div>Attendees page</div>
            <Checkbox
              id="include-attendees-page"
              checked={settings.includeAttendeesPage}
              onChange={(e) => handleChange("includeAttendeesPage", e.target.checked)}
            />
          </Row>
          <Row>
            <div>Agenda item title pages</div>
            <Checkbox
              id="include-agenda-item-title-pages"
              checked={settings.includeAgendaItemTitlePages}
              onChange={(e) =>
                handleChange("includeAgendaItemTitlePages", e.target.checked)
              }
            />
          </Row>
          <Row>
            <div>Sub-item title pages</div>
            <Checkbox
              id="include-sub-item-title-pages"
              checked={settings.includeSubItemTitlePages}
              onChange={(e) => handleChange("includeSubItemTitlePages", e.target.checked)}
            />
          </Row>
          <Row>
            <div>Documents</div>
            <Checkbox
              id="include-documents"
              checked={settings.includeDocuments}
              onChange={(e) => handleChange("includeDocuments", e.target.checked)}
            />
          </Row>
        </div>
      )}
    </Container>
  )
}

ExportSettings.propTypes = {
  settings: shape({
    includeCoverPage: bool.isRequired,
    includeAgendaPage: bool.isRequired,
    includeAttendeesPage: bool.isRequired,
    includeAgendaItemTitlePages: bool.isRequired,
    includeSubItemTitlePages: bool.isRequired,
    includeDocuments: bool.isRequired
  }).isRequired,
  onChange: func.isRequired,
  expanded: bool
}

export default ExportSettings
