import React from "react"
import { func, oneOf } from "prop-types"

import { TABS } from "./constants"
import Tab from "./Tab"

const Tabs = ({ active, setActive }) => {
  return (
    <>
      {Object.values(TABS).map((kind) => (
        <Tab key={kind} kind={kind} activeTab={active} setActive={setActive} />
      ))}
    </>
  )
}

Tabs.propTypes = {
  setActive: func.isRequired,
  active: oneOf([...Object.values(TABS)]).isRequired
}

export default Tabs
