import React from "react"
import { func, instanceOf } from "prop-types"

import UploadFolder from "../UploadFolder"

import { Container, Footer } from "./styles"
import FolderItemsList from "./FolderItemsList"

const UploadPanel = ({ uploadFolder, onClose, onSubmit, onRemove }) => {
  const uploading = uploadFolder.isUploading()
  const uploadFinished = uploadFolder.isUploadFinished()

  return (
    <Container>
      <FolderItemsList folder={uploadFolder} onRemove={onRemove} />

      <Footer>
        <button className="button is-secondary" onClick={onClose} disabled={uploading}>
          Cancel
        </button>
        <button
          type="submit"
          className="button"
          onClick={onSubmit}
          disabled={uploading || uploadFinished}
        >
          {!uploading && "Upload"}
          {uploading && "Uploading..."}
        </button>
      </Footer>
    </Container>
  )
}

UploadPanel.propTypes = {
  uploadFolder: instanceOf(UploadFolder).isRequired,
  onClose: func.isRequired,
  onSubmit: func.isRequired,
  onRemove: func.isRequired
}

export default UploadPanel
