import React from "react"
import { Dropdown } from "react-bootstrap"
import classNames from "classnames"
import { bool, func, shape, string } from "prop-types"
import { styled } from "@linaria/react"

import Unstyled from "src/styles/components/Button/Unstyled"

import DropdownToggle from "./DropdownToggle"

const AccountName = ({ account, className, onClick, isAccountOpened }) => {
  const hasItems = account.meetingPacks.length > 0 || account.schemes.length > 0

  return (
    // eslint-disable-next-line
    <span
      className={classNames(className, "sidebar-subsection-name", {
        collapsed: !isAccountOpened
      })}
      onClick={onClick}
    >
      <Unstyled className="sidebar-organization-name">{account.name}</Unstyled>

      {(account.canManage || account.canCreateMeetingPack) && (
        <Dropdown>
          <Dropdown.Toggle as={DropdownToggle} />
          <Dropdown.Menu popperConfig={{ placement: "bottom-start" }}>
            {account.canManage && (
              <Dropdown.Item href={account.managePath}>Manage Organisation</Dropdown.Item>
            )}
            {account.canManage && account.canCreateMeetingPack && <Dropdown.Divider />}
            {account.canCreateMeetingPack && (
              <Dropdown.Item href={account.newMeetingPath}>
                Add Meeting Pack
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      )}

      {hasItems && <div className="icon-dropdown-arrow no-transition" />}
    </span>
  )
}

AccountName.propTypes = {
  account: shape({
    name: string.isRequired,
    newMeetingPath: string.isRequired,
    managePath: string.isRequired,
    canManage: bool.isRequired,
    canCreateMeetingPack: bool.isRequired
  }).isRequired,
  onClick: func.isRequired,
  isAccountOpened: bool.isRequired
}

export default styled(AccountName)`
  .dropdown-menu {
    width: 200px;
  }
`
