class @MeetingPacksShowHandler
  constructor: ->
    @sortable = new SortableComponent()
    @agendaNumberingFormSubmit = $be('agenda-numbering-form-submit')
    @changeAgendaNumberingModal = $be('change-agenda-numbering-modal')
    @agendaItemMembersModalsComponent = new AgendaItemMembersModalsComponent()
    @agendaScrollPosition = new AgendaScrollPosition()

  setup: =>
    @sortable.setup()
    @agendaNumberingFormSubmit.click (e) =>
      @changeAgendaNumberingModal.modal('hide')

    @agendaItemMembersModalsComponent.setup()
    @addScrollListener()

    if $isIpad()
      $be('agenda-item-card').on('mouseenter', (e) ->
        e.target.click()
      )

    $be('agenda-item-card').each((i, card) =>
      @updateMembersCounter($(card))
    )

    @agendaScrollPosition.setupList()

  teardown: =>
    @sortable.teardown()
    @agendaNumberingFormSubmit.off('click')
    @agendaItemMembersModalsComponent.teardown()
    @agendaScrollPosition.teardownList()

  showManageList: (listContent) =>
    @agendaItemMembersModalsComponent.showManageList(listContent)

  showList: (listContent) =>
    @agendaItemMembersModalsComponent.showList(listContent)

  setAgendaItemMembers: () =>
    Turbo.visit(window.location)

  addScrollListener: ->
    header = $be('agenda-items-section')

    $('.fluid-container').scroll((event) =>
      scroll = event.target.scrollTop

      if scroll > 0
        header.addClass('scrolling')
      else
        header.removeClass('scrolling')
    )

  updateMembersCounter: (listContainer) ->
    nonVisibleCount = (
      listContainer.find(
        '[data-behavior="member-avatar"]'
      ).length -
        listContainer.find(
          '[data-behavior="member-avatar"]:visible'
        ).length
    )
    membersCounter = listContainer.find("[data-behavior=members-counter]")
    if nonVisibleCount > 0
      membersCounter.show()
    else
      membersCounter.hide()
    membersCounter.find(".counter-text").text($userCountView(nonVisibleCount))
