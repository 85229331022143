export const SortingFields = {
  Name: "name",
  Date: "date",
  Size: "size"
}

export const SORTING_FIELDS_VALUES = Object.values(SortingFields)

export const SortingDirections = {
  Asc: "ASC",
  Desc: "DESC"
}

export const SORTING_DIRECTIONS_VALUES = Object.values(SortingDirections)

export const SYSTEM_FILES = [".DS_Store", "Thumbs.db"]

export const FolderItemTypes = {
  Folder: "folder",
  File: "file"
}

export const DropTypes = {
  FolderItem: "folderItem"
}
