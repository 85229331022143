import React from "react"
import { arrayOf, number, shape, string } from "prop-types"

import DocumentsListItem from "./DocumentsListItem"

const DocumentsList = ({ documents, activeDocumentId, unreadDocumentIds, ...rest }) => {
  return (
    <div>
      {documents.map((document) => (
        <DocumentsListItem
          key={document.id}
          document={document}
          active={activeDocumentId === document.id}
          unread={unreadDocumentIds.includes(document.id)}
          {...rest}
        />
      ))}
    </div>
  )
}

DocumentsList.propTypes = {
  activeDocumentId: number,
  documents: arrayOf(
    shape({
      filename: string.isRequired
    })
  ).isRequired,
  unreadDocumentIds: arrayOf(number).isRequired
}

export default DocumentsList
