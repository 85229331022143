import { useEffect, useMemo, useState } from "react"
import { useApolloClient } from "@apollo/client"
import { invert, sample } from "lodash"

import { CHANNELS } from "src/constants/ably"
import { FAIL_MESSAGE, OBJECT_TYPES } from "src/features/UniversalAi/constants"
import { summarizeViewed } from "src/constants/storage"
import { getCurrentUserId } from "src/helpers/user"
import { scrollDown } from "src/helpers/document"
import useAblyChannel from "src/hooks/useAblyChannel"

import generateAiTextMutation from "./generateAiTextMutation.gql"
import useStreaming from "./useStreaming"

const useGenerateText = ({ prompt, objectId, objectType, contentElementFindFunc }) => {
  const apolloClient = useApolloClient()

  const [isLoading, setIsLoading] = useState(false)
  const { content, setContent, finished, setFinished } = useStreaming({
    onChangeContent: () => scrollDown(contentElementFindFunc)
  })
  const channelName = useMemo(
    () => sample(CHANNELS.aiAnswer[invert(OBJECT_TYPES)[objectType]]),
    []
  )

  useEffect(() => {
    if (objectId && !content) setIsLoading(true)
  }, [objectId, content])

  useEffect(() => {
    if (finished) localStorage.setItem(summarizeViewed(objectType, objectId), "t")
  }, [finished])

  useEffect(() => {
    if (prompt && objectId)
      apolloClient
        .mutate({
          mutation: generateAiTextMutation,
          variables: {
            instruction: prompt,
            objectId,
            objectType,
            channelName
          }
        })
        .then(({ data }) => {
          if (!data?.generateAiText?.success) setContent("")
        })
        .catch(() => setContent(""))
    else {
      setContent("")
      setFinished(false)
    }
  }, [prompt, objectId])

  useAblyChannel(channelName, {
    onMessage: ({ data }) => {
      const currentObjectMessage =
        data.userId === getCurrentUserId() &&
        data.objectId?.toString() === objectId?.toString() &&
        data.objectType === objectType

      if (!currentObjectMessage) return

      setIsLoading(false)

      if (
        data.streamAnswer &&
        localStorage.getItem(summarizeViewed(objectType, objectId))
      ) {
        setContent(data.answer, { stream: false })
        setFinished(true)
      } else if (data.answer) {
        setContent(data.answer)
        if (data.finished) setFinished(true)
      } else if (!data.success) {
        setContent(data.error || FAIL_MESSAGE)
        setFinished(true)
      }
    }
  })

  return { summarisedContent: content, isLoading, finished }
}

export default useGenerateText
