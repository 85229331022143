import { omit } from "lodash"

export const toggleArrayValue = (array, givenValue) => {
  if (array.includes(givenValue)) {
    return array.filter((value) => givenValue !== value)
  }

  return [...array, givenValue]
}

export const withoutAttributes = (array, attrNames) =>
  array?.map((item) =>
    attrNames.reduce((strippedItem, attrName) => {
      if (attrName.includes(".")) {
        const [mainAttrName, ...subAttrName] = attrName.split(".")
        return {
          ...strippedItem,
          [mainAttrName]: withoutAttributes(strippedItem[mainAttrName], [
            subAttrName.join(".")
          ])
        }
      }

      return omit(strippedItem, [attrName])
    }, item)
  )
