import toggleFullscreenMode from "./toggleFullscreenMode"
import { createFullscreenButton } from "./helpers"
import { fullscreenButtonClass } from "./constants"

const buildFullscreenButton = (instance) => ({
  type: "custom",
  className: `custom-button ${fullscreenButtonClass}`,
  id: fullscreenButtonClass,
  node: createFullscreenButton(),
  title: "Fullscreen",
  onPress() {
    toggleFullscreenMode({ instance, buttonClass: fullscreenButtonClass })
  }
})

export default buildFullscreenButton
