import React from "react"
import { styled } from "@linaria/react"

import Icon from "src/styles/components/Icon"
import { BASE_COLORS } from "src/styles/colors"
import { cardBorderRadius } from "src/styles/sizes"

const MustSignLabel = ({ className }) => (
  <div className={className}>
    <Icon type="sign" />
    Must sign
  </div>
)

export default styled(MustSignLabel)`
  display: flex;
  align-items: center;
  border-radius: ${cardBorderRadius};
  border: 1px solid ${BASE_COLORS.orange};
  color: ${BASE_COLORS.orange};
  padding: 0.4rem;
  height: 26px;
`
