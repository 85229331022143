import { styled } from "@linaria/react"
import React, { forwardRef } from "react"

import Icon from "src/styles/components/Icon"

export default styled(
  forwardRef((props, ref) => <Icon type="tridot" ref={ref} {...props} />)
)`
  &.dropdown-toggle:after {
    display: none;
  }
`
