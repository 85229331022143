import { Notyf } from "notyf"

import { BACKGROUND_COLORS } from "src/styles/colors"

const toastDefaults = {
  duration: 7000,
  dismissible: true,
  position: {
    x: "right",
    y: "top"
  }
}

const toastTypeColors = {
  error: BACKGROUND_COLORS.danger,
  success: BACKGROUND_COLORS.success
}

window.notyf = null

window.addEventListener("load", () => {
  window.notyf = new Notyf(toastDefaults)
})

window.addEventListener("turbo:load", () => {
  window.notyf = new Notyf(toastDefaults)
})

const showToastMessage = (options) => {
  const background = toastTypeColors[options.type]

  return window.notyf.open({ background, ...options })
}

const showErrorMessage = (options) => {
  showToastMessage({ type: "error", ...options })
}

const dismissToastMessage = (message) => {
  window.notyf.dismiss(message)
}

const dismissAllMessages = () => {
  window.notyf.dismissAll()
}

export { showToastMessage, showErrorMessage, dismissToastMessage, dismissAllMessages }
