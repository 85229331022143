import { styled } from "@linaria/react"

import {
  TEXT_COLORS,
  contentsSidebarBackground,
  contentsSidebarToggleBackground
} from "src/styles/colors"
import { BREAKPOINTS, SPACES, contentsSidebarToggleBorderRadius } from "src/styles/sizes"

export const SidebarWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
`

export const Sidebar = styled.aside`
  background-color: ${contentsSidebarBackground};
  border-radius: 0 ${contentsSidebarToggleBorderRadius}
    ${contentsSidebarToggleBorderRadius} 0;
  flex-grow: 2;
  overflow-y: auto;

  @media (max-width: ${BREAKPOINTS.xSmall}) {
    display: none;
  }
`

export const SidebarHeader = styled.h4`
  background-color: ${contentsSidebarToggleBackground};
  border-radius: 0 ${contentsSidebarToggleBorderRadius}
    ${contentsSidebarToggleBorderRadius} 0;
  display: flex;
  justify-content: space-between;
`

export const SidebarHeaderActions = styled.div`
  align-items: center;
  display: flex;
  padding-left: ${SPACES.small};
`

export const SidebarContainer = styled.ul`
  color: ${TEXT_COLORS.heading};
  list-style: none;
  padding: ${SPACES.small};
`
