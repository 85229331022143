class @AgendaItemService
  updateItemPosition: (item) ->
    $.ajax(
      url: item.data('url'),
      type: 'PUT',
      data: {
        'agenda_item': {
          'new_position': item.index() + 1
        }
      }
    )
