import apiClient from "src/app/apiClient"

const index = (params) => apiClient.get(`/api/schemes`, { params })
const groups = ({ schemeId, ...params }) =>
  apiClient.get(`/api/schemes/${schemeId}/groups`, { params })

const schemes = {
  index,
  groups
}

export default schemes
