class @MarketBackgroundComponent
  @BAR_ORDER_COLORS: {
    1: '#6490CB',
    2: '#6490CB',
    3: '#6490CB',
    4: '#5FA364',
    5: '#5FA364',
    6: '#5FA364',
    7: '#5FA364',
    8: '#5FA364',
    9: '#5FA364',
    10: '#5FA364',
    11: '#5FA364',
    12: '#FF9900',
    13: '#FF9900',
    14: '#FF9900',
    15: '#FF9900',
    16: '#726D93',
    17: '#726D93',
    18: '#726D93'
  }

  constructor: (params) ->
    @data = params.data
    @elementId = params.elementId

  setup: () ->
    xValues = @data.map((row) -> row.difference)
    yValues = Object.keys(xValues)
    yText   = @data.map((row) -> row.name)

    barText   = xValues.map((value) -> $percent(value))
    barColors = @data.map((row) -> MarketBackgroundComponent.BAR_ORDER_COLORS[row.order])

    data = [{
      x: xValues,
      y: yValues,
      orientation: 'h',
      type: 'bar',
      hoverinfo: 'none'
      text: barText
      textposition: 'outside',
      marker: {
        color: barColors
      }
    }]

    layout = {
      height: 800,
      barmode: 'stack',
      showlegend: false,
      yaxis: {
        tickvals: yValues,
        ticktext: yText,
        autorange: 'reversed',
        automargin: true
      },
      xaxis: {
        range: @xRange(),
        tickangle: 0,
      },
      font: {
        30
      },
      margin: {
        t: 10
        b: 20
        pad: 5
      }
    }

    Plotly.newPlot(
      @elementId,
      data,
      layout,
      { displayModeBar: false, responsive: true }
    )

  xRange: () ->
    maxValue = Math.max(@data.map((row) -> Math.abs(row.difference))...)
    rangeBorder = maxValue - (maxValue % 5) + 12 # some space for markers
    [-rangeBorder, rangeBorder]
