import { useCallback, useEffect } from "react"

import { getCurrentUserId } from "src/helpers/user"

// TODO: replace with out-of-the-box Ably React hooks
// https://github.com/ably/ably-js/blob/main/docs/react.md#L1
const useAblyChannel = (channelName, { onMessage, filterByUsersId = true }) => {
  const handleMessage = useCallback(
    (message) => {
      const { userIds } = message.data
      if (filterByUsersId && userIds && !userIds.includes(getCurrentUserId())) return

      onMessage(message)
    },
    [onMessage]
  )

  useEffect(() => {
    const channel = window.AblyRealtimeClient?.channels?.get(channelName)

    if (channel) channel.subscribe(handleMessage)

    return () => {
      if (channel) channel.unsubscribe(handleMessage)
    }
  }, [channelName, handleMessage])
}

export default useAblyChannel
