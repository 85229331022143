import React from "react"
import { string, number, oneOf, func, instanceOf, oneOfType } from "prop-types"

import { MODAL_TYPES, ACTION_TYPES } from "src/features/Messages/ActionDetails/constants"

import ConfirmationBody from "./ConfirmationBody"
import NumberForm from "./NumberForm"
import DateForm from "./DateForm"

const ModalBody = ({ value, actionType, modalType, onSubmit, onConfirm, onCancel }) => {
  if (!modalType) {
    return null
  }
  if (value || modalType === MODAL_TYPES.now) {
    return (
      <ConfirmationBody
        modalType={modalType}
        actionType={actionType}
        value={value}
        onConfirm={onSubmit}
        onCancel={onCancel}
      />
    )
  }
  if (modalType === MODAL_TYPES.number) {
    return (
      <NumberForm actionType={actionType} onConfirm={onConfirm} onCancel={onCancel} />
    )
  }
  if (modalType === MODAL_TYPES.date) {
    return (
      <DateForm
        actionType={actionType}
        value={value}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    )
  }
}

ModalBody.propTypes = {
  value: oneOfType([string, number, instanceOf(Date)]),
  actionType: oneOf(Object.keys(ACTION_TYPES)).isRequired,
  modalType: string,
  onConfirm: func.isRequired,
  onCancel: func.isRequired
}

export default ModalBody
