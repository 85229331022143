class @ContactDetailsComponent
  constructor: () ->
    @contactModal = $be('contact-details-modal')

  setup: =>
    @resetContactModal()
    @bindLinks($be('app-layout'))

  resetContactModal: =>
    @contactModal.unbind()
    @contactModal.on 'hide.bs.modal', ->
      $be('contact-details-content').html('')

  bindLinks: (contextElement) =>
    component = this
    contextElement.find(
      "[data-behavior=show-contact-details-link]"
    ).click (e) ->
      e.preventDefault()
      link = $(this)
      fromModalBehavior = link.data('in-modal')
      if fromModalBehavior
        $be(fromModalBehavior).modal('hide')
        component.contactModal.on 'hide.bs.modal', ->
          $be(fromModalBehavior).modal('show')
          component.resetContactModal()
      component.contactModal.modal('show')
      $.getScript(link.attr('href'))

  show: (content) =>
    details = $be('contact-details-content')
    details.html(content)
    window.layoutHandler.setAvatarImages(details)
    @adjustOwnDetails()

  adjustOwnDetails: =>
    $beById('start-chat-from-contact-link', $currentUserId()).hide()
    fillNameWrapper = $beById('contact-details-full-name', $currentUserId())
    fillNameWrapper.html(fillNameWrapper.html() + ' (you)')

