import { bodyData } from "src/helpers/document"

const toolbarItems = [
  { type: "pager" },
  { type: "zoom-out" },
  { type: "zoom-in" },
  { type: "zoom-mode" },
  { type: "spacer" },
  { type: "annotate", mediaQueries: ["(max-width: 1px)"] },
  { type: "ink" },
  { type: "highlighter" },
  { type: "text-highlighter" },
  { type: "ink-eraser" },
  { type: "note", mediaQueries: ["(min-width: 1px)"] }
]

const getBasicConfiguration = () => ({
  toolbarItems,
  licenseKey: window.PSPDFKitKey,
  styleSheets: [bodyData("pspdfkitEditorPath")],
  instant: false,
  autoSaveMode: window.PSPDFKit.AutoSaveMode.INTELLIGENT,
  enableAutomaticLinkExtraction: false,
  enableServiceWorkerSupport: true,
  printOptions: {
    mode: window.PSPDFKit.PrintMode.EXPORT_PDF,
    quality: window.PSPDFKit.PrintQuality.HIGH
  }
})

export default getBasicConfiguration
