import { css } from "@linaria/core"

import { linkActiveColor } from "src/styles/colors"

export const ContainerClass = css`
  gap: 8px;

  .icon-quote {
    font-size: 1rem;
    color: ${linkActiveColor};
    margin-left: 5px;
    cursor: pointer;
  }
`
