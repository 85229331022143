import React from "react"
import * as Yup from "yup"
import { Formik } from "formik"
import { func } from "prop-types"

import { digitsOnly } from "src/features/TwoFactor/helpers"
import { sendCode } from "src/api/twoFactorAuth"

const CodeFormSchema = Yup.object().shape({
  code: Yup.string()
    .test("Digits only", "Code is invalid", digitsOnly)
    .required("Code is invalid")
})

const Form = ({ setError, onSuccess, children }) => {
  const enableTwoFactor = (values, { setSubmitting }) =>
    sendCode({ code: values.code, byApp: true })
      .then(onSuccess)
      .catch((e) => {
        setError(e.response?.data?.error)
        setSubmitting(false)
      })

  return (
    <Formik
      initialValues={{ code: "" }}
      validationSchema={CodeFormSchema}
      onSubmit={enableTwoFactor}
    >
      {children}
    </Formik>
  )
}

Form.propTypes = {
  setError: func.isRequired,
  onSuccess: func.isRequired
}

export default Form
