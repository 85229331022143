import { styled } from "@linaria/react"

import { FONT_SIZES, SPACES } from "src/styles/sizes"
import { BASE_COLORS, TEXT_COLORS } from "src/styles/colors"

export const OrderSelectContainer = styled.div`
  align-items: center;
  color: ${TEXT_COLORS.muted};
  display: flex;
  margin-bottom: ${SPACES.medium};
  font-size: ${FONT_SIZES.small};

  [class*="-container"] {
    width: 10rem;
    margin-left: ${SPACES.xSmall};
  }

  [class*="-control"] {
    border-color: #ccc;
    box-shadow: none;
    color: ${BASE_COLORS.darkViolet};
    min-height: 32px;
    outline: none;

    &:hover {
      border-color: #ccc;
      box-shadow: none;
    }
  }

  [class*="-option"] {
    color: ${BASE_COLORS.darkViolet};
    background-color: #fff;

    &:hover {
      background-color: rgba(218, 218, 218, 0.19);
    }
  }
`
