import { styled } from "@linaria/react"

import BaseLinkButton from "src/components/LinkButton"

const FileDetails = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`

const FileName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const LinkButton = styled(BaseLinkButton)`
  max-width: 100%;
  display: inline;
`

const FileCard = styled.div`
  margin: 0.5rem 0 0.25rem;
  width: 100%;
  font-size: 0.9rem;
`

export { FileDetails, FileName, LinkButton, FileCard }
