import React from "react"
import Select from "react-select"
import { func } from "prop-types"

import { optionType } from "src/constants/propTypes"
import { ORDER_OPTIONS } from "src/features/Search/constants"

import { OrderSelectContainer } from "./styles"

const components = {
  DropdownIndicator: null,
  IndicatorSeparator: null
}

const OrderSelect = ({ order, setOrder }) => {
  return (
    <OrderSelectContainer>
      <span>sort by</span>
      <Select
        options={ORDER_OPTIONS}
        components={components}
        value={order}
        onChange={setOrder}
        isSearchable={false}
      />
    </OrderSelectContainer>
  )
}

OrderSelect.propTypes = {
  order: optionType,
  setOrder: func.isRequired
}

export default OrderSelect
