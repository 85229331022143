import { useEffect, useState } from "react"
import { useLazyQuery } from "@apollo/client"

import Store from "./Store"
import transcriptsAvailabilityQuery from "./transcriptsAvailability.gql"

const useHistory = ({ transcriptId, transcriptItems, loaded }) => {
  const store = new Store()
  const [historyPointer, setHistoryPointer] = useState(0)
  const [undoDisabled, setUndoDisabled] = useState(true)
  const [redoDisabled, setRedoDisabled] = useState(true)
  const [checkTranscriptsAvailability] = useLazyQuery(transcriptsAvailabilityQuery)

  useEffect(() => {
    store.cleanUp((transcriptIds) =>
      checkTranscriptsAvailability({ variables: { transcriptIds } }).then(
        ({ data }) => data.meetingPackTranscriptsAvailability
      )
    )
  }, [])

  useEffect(() => {
    if (!loaded) return

    setRedoDisabled(historyPointer === 0)

    store.getTranscriptHistory(transcriptId).then((transcriptHistory) => {
      if (transcriptHistory?.length)
        setUndoDisabled(transcriptHistory.length <= historyPointer + 1)
      else store.saveAction(transcriptId, transcriptItems)
    })
  }, [historyPointer, loaded])

  const saveToHistory = (items) => {
    store.saveAction(transcriptId, items)
    setHistoryPointer(0)
    setUndoDisabled(false)
  }

  const handleUndo = (restoreState) => () => {
    store.getTranscriptHistory(transcriptId).then((transcriptHistory) => {
      const lastState = transcriptHistory[historyPointer + 1]
      restoreState(lastState)
      setHistoryPointer(historyPointer + 1)
    })
  }

  const handleRedo = (restoreState) => () => {
    store.getTranscriptHistory(transcriptId).then((transcriptHistory) => {
      const nextState = transcriptHistory[historyPointer - 1]
      restoreState(nextState)
      setHistoryPointer(historyPointer - 1)
    })
  }

  return {
    saveToHistory,
    undoDisabled,
    redoDisabled,
    handleUndo,
    handleRedo
  }
}

export default useHistory
