export const BASE_COLORS = {
  darkViolet: "#2F3063",
  gray: "rgba(0,0,0,0.54)",
  darkGray: "rgba(0,0,0,0.1)",
  midGray: "#9E9EB0",
  lightGray: "#d4d4d4",
  white: "#FFFFFF",
  beige: "#F6D39E",
  lightRed: "#E97B7B",
  red: "#E84C51",
  green: "#79C26C",
  lightGreen: "#AECC45",
  orange: "#E0A650",
  lightOrange: "#F8CB53",
  lightBlue: "#0095ED",
  darkBlue: "#302E67"
}

export const TEXT_COLORS = {
  link: "#0095ED",
  muted: "#7D8498",
  sidebar: "#9E9EB0",
  separator: "#A7A7A7",
  heading: BASE_COLORS.darkViolet,
  avatar: BASE_COLORS.darkViolet,
  danger: "#DC3545"
}

export const BACKGROUND_COLORS = {
  success: BASE_COLORS.lightGreen,
  danger: BASE_COLORS.lightRed,
  warning: BASE_COLORS.orange,
  button: TEXT_COLORS.link,
  lightOrange: BASE_COLORS.lightOrange,
  main: "#F3F6F9",
  selected: "rgba(218, 218, 218, 0.38)",
  focused: "rgba(218, 218, 218, 0.19)",
  dark: "#3d3d4b",
  disabled: "#CED4DA",
  lightBlue: "#f2f8ff",
  lightGray: "#f6f6f6"
}

export const BADGE_COLORS = {
  unread: BASE_COLORS.red,
  mention: BASE_COLORS.orange
}

export const BORDER_COLORS = {
  button: "#007BFF",
  inputHover: "#80BDFF",
  control: "#CED4DA",
  separator: "#E3E7ED",
  searchResult: "#9E9EB0"
}

export const BOX_SHADOW_COLORS = {
  standardBlock: "rgba(115,131,158,0.15)",
  innerInput: "rgba(115,131,158,0.22)",
  outerInput: "rgba(0,123,255,0.25)",
  header: "rgba(133,167,184,0.06)",
  selectMenu: BASE_COLORS.darkGray
}

export const ICON_COLORS = {
  attachedDocument: TEXT_COLORS.muted,
  collaborationDocument: BASE_COLORS.midGray,
  header: "#A8ABBB",
  search: "#9E9EB0",
  searchClear: BASE_COLORS.darkViolet,
  cardCloseButton: BASE_COLORS.darkViolet,
  menuIconHover: "#333333",
  menuIcon: "#666666",
  starred: BASE_COLORS.lightOrange
}

export const TAG_COLORS = {
  0: "#EC407A",
  1: "#AB47BC",
  2: "#7E57C2",
  3: "#5C6BC0",
  4: "#26C6DA",
  5: "#26A69A",
  6: "#66BB6A",
  7: "#9CCC65",
  8: "#D4E157",
  9: "#FFCA28",
  10: "#FFA726",
  11: "#FF7043",
  12: "#8D6E63",
  13: "#BDBDBD",
  14: "#78909C"
}

export const avatarsColorsPool = {
  0: "#1997BF",
  1: "#0A50A1",
  2: "#0E1877",
  3: "#63B6CF",
  4: "#426EA1",
  5: "#909090",
  6: "#6E6B6A",
  7: "#4B4A48",
  8: "#7C6AAE",
  9: "#68549F",
  10: "#74328E",
  11: "#922D8E",
  12: "#5CAEA3",
  13: "#358F8A",
  14: "#075F69"
}

export const TAG_COLOR_STYLES = Object.keys(TAG_COLORS).reduce(
  (result, key) => `
     ${result}
     &--color-${key} {
      background-color: ${TAG_COLORS[key]};
     }
   `,
  ""
)

export const TAG_COLORS_COUNT = Object.keys(TAG_COLORS).length

export const inputPlaceholderColor = "rgba(125,132,152,0.4)"
export const linkActiveColor = "#2981CE"
export const systemAvatarColor = "#2F2482"
export const inputTextColor = BASE_COLORS.darkBlue
export const cardTextColor = "#323232"
export const sidebarBackground = BACKGROUND_COLORS.dark
export const mutedBadgeColor = "#46485A"
export const messageSystemBorderColor = "#46ABED"
export const contentsSidebarToggleColor = "#4A4B5B"
export const searchResultAuthorColor = "#4C8D8A"
export const sidebarHighlightColor = "#4F4F60"
export const linkHoverColor = "#53A9F5"
export const sidebarActiveColor = "#6F6F80"
export const successColor = "#82CA4C"
export const iconColor = "#B4B9C7"
export const badgeColor = "#C4C4C4"
export const avatarColor = "#CBCDD6"
export const highlightedMessageBackgroundColor = "#D7FFFC"
export const sharedSpaceTextColor = "#DEFDE5"
export const accountAdminColor = "#E2F2C5"
export const accountGuestColor = "#FDE4E5"
export const meetingPreviewColor = BASE_COLORS.lightOrange
export const notificationBadgeColor = "#EDEDED"
export const contentsSidebarBackground = "#F8F8F8"
export const starredMessageStarColor = "#F9CC46"
export const starredMessageBackgroundColor = "#FFFCD7"

export const buttonOutlineColor = TEXT_COLORS.link
export const cardBackgroundColor = BASE_COLORS.white
export const cardMetaColor = ICON_COLORS.header
export const contentsSidebarToggleBackground = avatarColor
export const meetingPackBreakLineColor = TEXT_COLORS.sidebar
export const meetingPackDisabledSidebarItemColor = TEXT_COLORS.sidebar
export const meetingPackMutedTextColor = ICON_COLORS.header
export const messageActionableBorderColor = BASE_COLORS.beige
export const messageActionableIconColor = BASE_COLORS.beige
export const messageTextColor = inputTextColor
export const navigationButtonColor = BASE_COLORS.lightOrange
export const notificationIconColor = ICON_COLORS.header
export const sharedSpaceColor = BASE_COLORS.green
export const sidebarSeparator = mutedBadgeColor
export const subheaderLinkColor = TEXT_COLORS.muted
