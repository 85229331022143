import { MODAL_TOGGLE_EVENT } from "src/features/collaborativeDocuments/constants"

export class FroalaCollaborativeDocumentComponent {
  setup = () => {
    const { FroalaEditor } = window

    FroalaEditor.DefineIcon("collaborativeDocument", {
      NAME: "collaborative-document",
      template: "knowa"
    })

    FroalaEditor.RegisterCommand("collaborativeDocument", {
      title: "Collaborative document",
      focus: true,
      undo: true,
      refreshAfterCallback: true,
      callback: () => {
        window.dispatchEvent(new Event(MODAL_TOGGLE_EVENT))
      }
    })
  }
}
