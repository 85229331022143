import { styled } from "@linaria/react"

import { TEXT_COLORS } from "src/styles/colors"
import { SPACES } from "src/styles/sizes"

export const ListHeaderWrapper = styled.div`
  margin-bottom: ${SPACES.small};
`

export const ListHeaderContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: ${SPACES.small};
`

export const ListHeader = styled.h4`
  margin-right: ${SPACES.small};
  color: ${TEXT_COLORS.muted};
  font-weight: 400;
`

export const ListHeaderActions = styled.div`
  display: flex;
  flex: 1 1;
  justify-content: flex-end !important;
`
