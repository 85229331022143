import React from "react"
import { styled } from "@linaria/react"
import { Dropdown } from "react-bootstrap"
import { capitalize } from "lodash"
import { func, number, shape, string } from "prop-types"

import * as schemeMembershipsApi from "src/api/schemeMemberships"
import { Column } from "src/components/AttandeesTable"
import TridotIcon from "src/components/TridotIcon"
import { SCHEME_MEMBERSHIP_ROLES } from "src/resources/schemes/constants"
import { showErrorMessage } from "src/helpers/toast"

const ActionsColumn = ({ className, user, schemeId, setUserRole, deleteUser }) => {
  const { revokeConfirmationMessage, schemeRole } = user

  const openManageGroupModal = () => {
    window.dispatchEvent(
      new CustomEvent("manageGroupClicked", {
        detail: { editSchemeUserPath: user.editSchemeUserPath }
      })
    )
  }

  const changeRole = (role) => {
    schemeMembershipsApi
      .update({ schemeId, role, userId: user.id })
      .then(() => {
        setUserRole({ id: user.id, role })
      })
      .catch(() => {
        showErrorMessage({ message: "Can't change user's role" })
      })
  }

  const deleteFromScheme = () => {
    if (window.confirm(revokeConfirmationMessage)) {
      schemeMembershipsApi
        .destroy({ schemeId, userId: user.id })
        .then(() => {
          deleteUser({ id: user.id })
        })
        .catch(() => {
          showErrorMessage({ message: "Can't change user's role" })
        })
    }
  }

  return (
    <Column className={className}>
      <Dropdown>
        <Dropdown.Toggle as={TridotIcon} data-testid="actions-toggle" />

        <Dropdown.Menu alignRight>
          {user.invitationAccepted || (
            <>
              <Dropdown.Item
                className="text-nowrap"
                as="a"
                data-remote="true"
                data-method="patch"
                href={user.resendInvitationPath}
              >
                Resend Invite
              </Dropdown.Item>
              <Dropdown.Divider />
            </>
          )}

          <Dropdown.Item onClick={openManageGroupModal}>Manage groups</Dropdown.Item>
          <Dropdown.Divider />

          {Object.keys(SCHEME_MEMBERSHIP_ROLES).map((role) =>
            role === schemeRole ? null : (
              <Dropdown.Item key={role} onClick={() => changeRole(role)}>
                Change to {capitalize(role)}
              </Dropdown.Item>
            )
          )}

          <Dropdown.Divider />

          <Dropdown.Item
            className="delete text-nowrap"
            variant="danger"
            onClick={deleteFromScheme}
          >
            Remove from space
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Column>
  )
}

ActionsColumn.propTypes = {
  schemeId: number.isRequired,
  setUserRole: func.isRequired,
  deleteUser: func.isRequired,
  user: shape({
    id: number.isRequired,
    resendInvitationPath: string.isRequired,
    revokeConfirmationMessage: string.isRequired,
    schemeRole: string.isRequired
  }).isRequired
}

export default styled(ActionsColumn)`
  .dropdown {
    position: static;
  }

  .dropdown-toggle {
    cursor: pointer;
  }

  .dropdown-menu {
    a {
      white-space: nowrap;
    }
  }
`
