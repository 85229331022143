import React from "react"
import { func, number, shape, string } from "prop-types"

import { CATEGORIES } from "src/features/Search/constants"
import Icon from "src/styles/components/Icon"

import { CategoriesContainer, CategoriesList, GoBackLink } from "./styles"
import Category from "./Category"

const Categories = ({
  categoriesCount,
  exitLink,
  selectedCategory,
  setSelectedCategory
}) => {
  return (
    <CategoriesContainer>
      <GoBackLink href={exitLink || "/"}>
        <Icon type="arrow-left" size="medium" className="mr-2" />
        <span className="desktop-text">Go back</span>
      </GoBackLink>
      <CategoriesList>
        {Object.values(CATEGORIES).map((category) => (
          <Category
            key={category}
            selected={category === selectedCategory}
            setSelectedCategory={setSelectedCategory}
            name={category}
            count={categoriesCount[category]}
          />
        ))}
      </CategoriesList>
    </CategoriesContainer>
  )
}

Categories.propTypes = {
  categoriesCount: shape({
    [CATEGORIES.everything]: number,
    [CATEGORIES.meetings]: number,
    [CATEGORIES.discussions]: number,
    [CATEGORIES.documents]: number
  }).isRequired,
  exitLink: string,
  setSelectedCategory: func.isRequired,
  selectedCategory: string
}

export default Categories
