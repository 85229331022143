import axios from "axios"

export const downloadFile = ({ authenticatedDownloadUrl }) => {
  return axios
    .get("/box_auth", { headers: { Accept: "*/*" } })
    .then(({ data: { box_access_token: boxAccessToken } }) =>
      axios.get(`${authenticatedDownloadUrl}?access_token=${boxAccessToken}`, {
        responseType: "blob"
      })
    )
}
