import React, { useMemo } from "react"
import { useSelector } from "react-redux"

import TextWithTooltip from "src/styles/components/TextWithTooltip"
import IconWithTooltip from "src/styles/components/IconWithTooltip"
import { formatDateTime } from "src/helpers/datetime"
import { splitFilename } from "src/helpers/string"
import useIsOpened from "src/hooks/useIsOpened"
import CopyLink from "src/components/CopyLink"
import {
  getActiveItem,
  getActiveDocument,
  getActiveDocumentPage
} from "src/features/meetingPacks/MeetingPack/selectors"

import { AgendaItemDescriptionIcon, ItemHeader } from "./styles"
import DescriptionModal from "./DescriptionModal"
import usePagesCounter from "./usePagesCounter"

const nbsp = "\xa0"

const SubHeader = () => {
  const meetingPack = useSelector((state) => state.meetingPack.meetingPack)
  const activeItem = useSelector((state) => getActiveItem(state))
  const activeDocument = useSelector((state) => getActiveDocument(state))
  const activeDocumentPage = useSelector((state) => getActiveDocumentPage(state))
  const { currentPage, totalPages, loading: pageNumbersLoading } = usePagesCounter()
  const { isOpened, open, close } = useIsOpened(false)

  const startDate = formatDateTime({
    datetime: meetingPack.startDate,
    timeZone: meetingPack.timeZone
  })

  let itemHeader = activeItem?.name || meetingPack?.name
  let activeDocumentName = null

  if (activeItem?.index === 0) {
    itemHeader = activeItem.index.toString() + nbsp + nbsp + itemHeader
  }

  if (activeDocument) {
    activeDocumentName = splitFilename(activeDocument.filename).name
  }

  const urlForCopy = useMemo(() => {
    const url = new URL(window.location.href)
    if (activeItem && !activeDocument)
      url.searchParams.set("agenda_item_id", activeItem.id)
    if (activeDocument) url.searchParams.set("document_id", activeDocument.id)
    if (activeDocumentPage) url.searchParams.set("page", activeDocumentPage)
    return url.href
  }, [activeItem, activeDocument, activeDocumentPage])

  const description = activeItem?.description && (
    <>
      <AgendaItemDescriptionIcon onClick={open} type="info" />
      <DescriptionModal
        show={isOpened}
        onHide={close}
        title={itemHeader}
        description={activeItem.description}
      />
    </>
  )

  return (
    <div className="meeting-pack-subheader subheader-nav subheader-nav-component subheader-nav-grid">
      <div className="d-flex align-vertically-center">
        <div className="meeting-date">
          <p className="mb-0 font-weight-500 text-x-small text-nowrap">{startDate}</p>
          <TextWithTooltip
            recogniseLinks
            className="mb-0 font-weight-500 text-x-small text-nowrap"
            text={meetingPack.location}
          />
        </div>
        <IconWithTooltip
          text={`${startDate}. ${meetingPack.location}`}
          className="mb-0 font-weight-500 text-small meeting-icon-tooltip"
          icon="icon-info"
        />
      </div>
      <div className="meeting-details">
        {activeItem && (
          <div className="d-flex justify-content-center align-items-center w-100">
            <ItemHeader>
              <span className="d-none d-sm-block">{activeItem.index}</span>&nbsp;
              <TextWithTooltip text={itemHeader} />
            </ItemHeader>
            {description}
          </div>
        )}
        {activeDocumentName && (
          <TextWithTooltip
            component="p"
            className="mb-0 text-center text-small"
            text={activeDocumentName}
          />
        )}
      </div>
      <div data-testid="pages-counter">
        {!pageNumbersLoading && totalPages > 0 && (
          <>
            <p className="mb-0 text-right pages-counter-text">
              <span className="d-none d-md-inline">Page</span> {currentPage} of{" "}
              {totalPages}
            </p>
            <p className="mb-0 text-right">
              <CopyLink url={urlForCopy} />
            </p>
          </>
        )}
      </div>
    </div>
  )
}

export default SubHeader
