import { useMutation } from "@apollo/client"

import createFolderMutation from "./createFolderMutation.gql"

const useCreateFolder = () => {
  const [mutate, { loading }] = useMutation(createFolderMutation, {
    refetchQueries: ["GroupFolderItems"]
  })

  const createFolder = ({ groupId, folderId, name }) => {
    return mutate({
      variables: { groupId, folderId, name },
      awaitRefetchQueries: true
    }).then((response) => response.data?.createFolderInGroup || {})
  }

  return { createFolder, loading }
}

export default useCreateFolder
