import { useEffect } from "react"

const useResizeObserver = ({ selector, handler, deps = [], cleanupFunc = null }) => {
  useEffect(() => {
    const element = document.querySelector(selector)
    if (!element) return

    const resizeObserver = new ResizeObserver((entries) => {
      handler(entries[0])
    })

    resizeObserver.observe(element)

    return () => {
      if (cleanupFunc) cleanupFunc()
      resizeObserver.disconnect()
    }
  }, deps)
}

export default useResizeObserver
