import { createSelector } from "@reduxjs/toolkit"

import { getTagOption } from "src/resources/tags/helpers"

export const getTagsById = (state) => state.tags.byId
export const getMeetingPackTags = (state) => state.meetingPack.tags

export const selectMeetingPackTagOption = createSelector(
  getTagsById,
  getMeetingPackTags,
  (tagsById, meetingPackTags) => {
    if (!meetingPackTags.length) return

    return getTagOption(tagsById[meetingPackTags[0].id] || meetingPackTags[0])
  }
)
