import React, { createContext, useContext } from "react"
import { string } from "prop-types"

const Context = createContext(null)

export const useFileId = () => useContext(Context)

const FileIdContext = ({ fileId, children }) => {
  return <Context.Provider value={fileId}>{children}</Context.Provider>
}

FileIdContext.propTypes = {
  fileId: string
}

export default FileIdContext
