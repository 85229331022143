import React from "react"
import { arrayOf, func, string } from "prop-types"

import SearchableSelect from "src/components/SearchableSelect"
import { optionType } from "src/constants/propTypes"

import SelectedValues from "../SelectedValues"
import { FilterContainer, FilterTitle } from "../styles"
import { filterOption } from "../helpers"

import Option from "./Option"

const components = {
  Option,
  NoOptionsMessage: () => null,
  IndicatorsContainer: () => null
}

const WhereFilter = ({
  whereFilter,
  addWhereFilterValue,
  removeWhereFilterValue,
  whereFilterOptions
}) => {
  const label = whereFilter.length ? "Select" : "Everywhere"
  const selectedValues = whereFilterOptions.filter(({ value }) =>
    whereFilter.includes(value)
  )

  return (
    <FilterContainer>
      <FilterTitle>Where</FilterTitle>
      <SelectedValues deselect={removeWhereFilterValue} selectedValues={selectedValues} />
      <SearchableSelect
        isMulti
        useSelectedLabel={false}
        label={label}
        options={whereFilterOptions}
        selected={selectedValues}
        setSelected={addWhereFilterValue}
        components={components}
        filterOption={filterOption}
      />
    </FilterContainer>
  )
}

WhereFilter.propTypes = {
  addWhereFilterValue: func.isRequired,
  removeWhereFilterValue: func.isRequired,
  whereFilter: arrayOf(string),
  whereFilterOptions: arrayOf(optionType)
}

export default WhereFilter
