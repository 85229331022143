import React, { useRef } from "react"
import classNames from "classnames"
import { arrayOf, string } from "prop-types"

import useIsOpened from "src/hooks/useIsOpened"
import useMediaQuery from "src/hooks/useMediaQuery"
import useCmdKForSearch from "src/hooks/useCmdKForSearch"
import { closeSidebar } from "src/helpers/document"
import { BREAKPOINTS } from "src/styles/sizes"
import useFeatureFlag from "src/hooks/useFeatureFlag"

import IconAI from "./IconAI"
import SearchInput from "./SearchInput"
import { NavLink } from "./styles"

const SearchNavIcon = ({ whereIds, whereFilter }) => {
  const { isOpened, open, close } = useIsOpened()
  const { flagValue: pineconeSearchEnabled } = useFeatureFlag("pinecone-search-enabled")

  const isSmallScreen = useMediaQuery(`(max-width: calc(${BREAKPOINTS.small} - 1px))`)
  const navLinkRef = useRef()

  const handleOpen = () => {
    if (isSmallScreen) closeSidebar()
    open()
  }

  useCmdKForSearch(() => navLinkRef.current.click())

  return (
    <>
      <NavLink
        className={classNames("header-nav-item search-link", {
          highlighted: isOpened
        })}
        data-testid="search-navbar-link"
        onClick={pineconeSearchEnabled ? handleOpen : undefined}
        ref={navLinkRef}
      >
        {pineconeSearchEnabled && <IconAI />}
      </NavLink>
      {isOpened && (
        <SearchInput close={close} whereIds={whereIds} whereFilter={whereFilter} />
      )}
    </>
  )
}

SearchNavIcon.propTypes = {
  whereIds: arrayOf(string),
  whereFilter: string
}

export default SearchNavIcon
